import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'banca-footer',
  templateUrl: './banca-footer.component.html',
  styleUrls: ['./banca-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BancaFooterComponent implements OnInit {
  @Input() leftColumnTitle: string;
  @Input() leftColumnFirstHint: string;
  @Input() leftColumnSecondHint: string;
  @Input() rightColumnTitle: string;
  @Input() rightColumnFirstHint: string;
  @Input() rightColumnSecondHint: string;
  @Input() supportMail: string;
  @Input() showCompanyRow: boolean;
  @Input() companyLogoSrc: string;
  @Input() companyLogoTitle: string;
  @Input() companyLogoHeight: number; 
  @Input() isPostepayFooter: boolean;
  @Input() isBancaBbp: boolean;
  @Input() supportUrl1: string;
  @Input() supportUrl2: string;
  @Input() supportUrl3: string;
  @Input() supportUrl4: string;
  @Input() supportUrl5: string;
  @Input() supportUrl6: string;
  @Input() supportUrl7: string;
  @Input() supportUrl8: string;
  @Input() supportUrlHint3: string;
  @Input() supportUrlHint4: string;
  @Input() supportUrlHint5: string;
  @Input() supportUrlHint6: string;
  @Input() supportUrlHint7: string;
  @Input() supportUrlHint8: string;
  @Input() supportUrlHint9: string;
  @Input() support1: string;
  @Input() support1B: string;
  @Input() support2: string;
  @Input() mail: string;
  @Input() tSupport: string;
  @Input() supportUrlSubHint: string;
  

  @Output() onLogoClicked: EventEmitter<any> = new EventEmitter();

  constructor() { }
  

  ngOnInit() {
  }

  emitOnLogoClicked(data?) {
    this.onLogoClicked.emit(data);
  }
  
}
