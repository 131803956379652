import { Component, Input } from '@angular/core';

@Component({
  selector: 'small-widget-placeholder',
  templateUrl: './small-widget-placeholder.component.html',
  styleUrls: ['./small-widget-placeholder.component.scss']
})
export class SmallWidgetPlaceholderComponent {
  @Input() numberOfData: number;

  constructor() {
  }
}
