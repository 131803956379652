<label *ngIf="title" class="maps-label margin-bottom8">{{ title }}</label>

<div class="maps-text-input-wrapper" [ngClass]="{
    'disabled': _inputValue && _inputValue.disabled,
    'invalid': _inputValue && _inputValue.invalid && !_inputValue.pristine }">

  <!-- Maps Autocomplete -->
  <input ngxAutocomPlace [debounceTime]="debounceTime" [options]="mapsOptions"
    (selectedPlace)="placeChangedCallback($event)" [placeholder]="placeholder" [attr.aria-label]="attrAriaLabel"
    [type]="_type" [id]="_id" [attr.name]="attrName" [formControl]="_inputValue" [tabindex]="_tabIndex">

</div>
