import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'big-widget',
  templateUrl: './big-widget.component.html',
  styleUrls: ['./big-widget.component.scss']
})
export class BigWidgetComponent {
  @Input() indicator;
  @Input() acquiredCompetencesList?: boolean;
  @Input() desiredCompetencesList?: boolean;
  @Input() areTeachersList?:boolean;
  @Input() areUsersList?: boolean;
  @Input() areObjectsList?: boolean
  @Output() onMainButtonClick = new EventEmitter<any>();
  @Output() onInfoClick = new EventEmitter<any>();

  constructor() {
  }

  // Emette l'evento al click sul pulsante principale
  emitOnMainButtonClick() {
    this.onMainButtonClick.emit(this.indicator.id);
  }

  // Torna l'avatar di un utente
  getAvatarUrl(user) {
    let avatar = (user && user.userOptions && user.userOptions.avatarImage)
      || (user && (!user.chiaveSesso || user.chiaveSesso == "M") ? 'assets/img/placeholder_male.svg' : 'assets/img/placeholder_female.svg');
    return avatar;
  }

  // Emette l'evento al click sull'icona delle info
  emitOnInfoClick() {
    this.onInfoClick.emit(this.indicator.id);
  }

  // In base ai parametri calcola la corretta classe che deve avere il secondo div principale
  calculateClassSecondContainerDiv(): string {
    if (this.areUsersList) {
      return "number-wrapper";
    } else if (this.areObjectsList) {
      return "number-wrapper objects";
    } else {
      return "number-wrapper";
    }
  }
}
