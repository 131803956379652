<div class="item-list-card-wrapper" (click)="onShowPropedeuticalItemToThis()"
  [ngClass]="{ 'cursor-pointer' : isItemDisabled || isSectionDisabled, 'item-child-separator': !lastElement }"
  fxLayout.gt-xs="row" fxLayout.xs="column">

  <div class="item-list-card" fxLayout="row">
    <div class="item-list-card-img"
      [ngStyle]="{ 'background-image': cardCover ? 'url(' + cardCover + ')' : '../../../assets/img/Banner_Home.jpeg' }">
      <!-- Immagine card (se l'item è stato completato oppure bloccato) -->
      <div class="centered-label" fxLayout="column" fxLayoutAlign="center center" fxFill
        *ngIf="isCompleted || isItemDisabled || isSectionDisabled || isItemAlreadyCertified"
        [ngClass]="{'item-locked' : isItemDisabled || isSectionDisabled, 'item-completed': (!isItemDisabled && isCompleted && !isItemAlreadyCertified) || !isItemDisabled && isItemAlreadyCertified}">
        <p *ngIf="!isItemDisabled && isItemAlreadyCertified" class="margin0" translate="card.COMPLETED"></p>
        <svg-icon *ngIf="!isItemDisabled && isItemAlreadyCertified" src="../../assets/icons/icon-check-circle-full.svg">
        </svg-icon>
        <svg-icon *ngIf="!isItemDisabled && isCompleted && !isItemAlreadyCertified"
          src="../../assets/icons/icon-check-circle-full.svg">
        </svg-icon>
        <p *ngIf="!isItemDisabled && isCompleted && !isItemAlreadyCertified" class="margin0" translate="card.COMPLETED">
        </p>
        <!-- Item disabilitato poiché contiene prerequisiti -->
        <svg-icon *ngIf="isItemDisabled || isSectionDisabled" src="../../assets/icons/icon-lock.svg">
        </svg-icon>
        <p *ngIf="isItemDisabled || isSectionDisabled" class="prerequisites-label" translate="card.PREREQUISITES">
        </p>
      </div>
      <!-- Etichetta che indica che l'item è obbligatorio -->
      <div class="mandatory" *ngIf="isRequired">
        <p translate="card.MANDATORY"></p>
      </div>
      <!-- Etichetta che indica che l'item è propedeutico per un altro item -->
      <div class="propedeutical" *ngIf="isNotRequired">
        <p translate="card.PROPEDEUTIC"></p>
      </div>
      <div *ngIf="adminMode" class="img-item">
        <!-- Seleziona oggetto -->
        <div *ngIf="isLearningPlanEditingMode && item" style="width: 35px !important;" role="button"
          class="margin0 md-checkbox">
          <input [disabled]="isInPreview" id="{{ item.itemId }}" type="checkbox"
            [checked]="isItemChecked(fullItem || card)" (change)="emitItemChecked(fullItem || card)">
          <label for="{{ item.itemId }}"></label>
        </div>
      </div>
    </div>
    <!-- Info a lato dell'immagine card -->
    <div class="card-info" fxLayout="column" fxLayoutAlign="center center">
      <!-- Pallino colorato in base alla tipologia. Corso online: (.online) corso in aula: (.classroom) -->
      <div class="type-color online" [ngClass]="{ 'classroom' : !isItemOnline() }"></div>
      <!-- Tipologia (online/aula) -->
      <p><strong>{{ isItemOnline() ? 'Online' : 'In aula' }}</strong></p>
      <p class="type-label" *ngIf="item.itemTypeLabel">{{item.itemTypeLabel}}</p>
      <!-- Monte ore -->
      <p *ngIf="isHourValueValorised" class="to-lowercase">
        {{hourValue.hours ? (hourValue.hours + ('hourValue.H' | translate) ) : ''}}
        {{ hourValue.minutes ? (hourValue.minutes + ('hourValue.M' | translate) ) : '' }}</p>
      <!-- Percentuale di avanzamento -->
      <p *ngIf="!adminMode && percentageItem">{{ percentageItem}}</p>
    </div>
  </div>
  <!-- Titolo sottotiolo pulsante di dettaglio -->
  <div class="item-list-card-info" fxLayout="column">
    <!-- Titolo -->
    <h3>{{card?.extendedItem?.title || card.name || card.title || ''}}</h3>
    <!-- Sottotitolo -->
    <h4 *ngIf="card.subTitle && card.subTitle.length" [innerHTML]="card.subTitle"></h4>

    <!-- Informazioni sulle edizioni del corso (cm2)-->
    <ng-container *ngIf="item && item.userEnrolledToEditions">
      <!-- Prima edizione in cui l'utente è confermato o presente -->
      <ng-container *ngIf="item.userEnrolledToEditions[0] && item.userEnrolledToEditions[0].itemId">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="course-subscription-label subscribed" fxLayout="row" fxLayoutAlign="start center">
            <svg-icon src="../../assets/icons/check-squared.svg">
            </svg-icon>
            <span *ngIf="!item.userEnrolledToEditions[0].isPresent" class="word-status"
              translate="generic.SUBSCRIBED"></span>
            <span *ngIf="item.userEnrolledToEditions[0].isPresent" class="word-status"
              translate="calendar.userStatusesForUser.COURSE.USER_STATUS_PRESENT"></span>
          </div>
          <!-- Etichetta con la data della prima giornata disponibile -->
          <div class="course-subscription-label editions" fxLayout="row" fxLayoutAlign="start center">
            <svg-icon src="../../assets/icons/alert-circle.svg">
            </svg-icon>
            <span class="label-wrapper"><span translate="generic.EDITION_OF"></span><span
                class="margin-left4 editions-number">{{
                item.userEnrolledToEditions[0].formattedDate || ''}}</span></span>
          </div>

          <!-- Pulsante per aprire la lista completa delle edizioni in cui l'utente è confermato o presente -->
          <svg-icon class="margin-bottom6 cursor-pointer" *ngIf="item.userEnrolledToEditions.length > 1"
            (click)="subscribedEditionExpanded=!subscribedEditionExpanded"
            [src]="subscribedEditionExpanded ? 'assets/icons/icon-angle-up.svg' : 'assets/icons/icon-angle-down.svg'">
          </svg-icon>
        </div>
      </ng-container>

      <!-- Altre edizioni (dalla seconda, quindi questo è l'elenco espanso) in cui l'utente è confermato o presente -->
      <ng-container *ngIf="subscribedEditionExpanded && item.userEnrolledToEditions.length">
        <div
          *ngFor="let edition of item.userEnrolledToEditions | slice:1:item.userEnrolledToEditions.length; trackBy: trackByItemId"
          fxLayout="row" fxLayoutAlign="start center">
          <div class="course-subscription-label subscribed" fxLayout="row" fxLayoutAlign="start center">
            <svg-icon src="../../assets/icons/check-squared.svg">
            </svg-icon>
            <span *ngIf="!item.userEnrolledToEditions[0].isPresent" class="word-status"
              translate="generic.SUBSCRIBED"></span>
            <span *ngIf="item.userEnrolledToEditions[0].isPresent" class="word-status"
              translate="calendar.userStatusesForUser.COURSE.USER_STATUS_PRESENT"></span>
          </div>
          <!-- Etichetta con la data della prima giornata disponibile -->
          <div class="course-subscription-label editions" fxLayout="row" fxLayoutAlign="start center">
            <svg-icon src="../../assets/icons/alert-circle.svg">
            </svg-icon>
            <span class="label-wrapper"><span translate="generic.EDITION_OF"></span><span
                class="margin-left4 editions-number">{{
                edition.formattedDate || ''}}</span></span>
          </div>
        </div>
      </ng-container>

      <!-- Edizioni a cui l'utente può iscriversi -->
      <div *ngIf="!isSchoolPage && !item.userEnrolledToEditions.length || item.availableEditionsCount" fxLayout="row"
        fxLayoutAlign="start center">
        <!-- Etichetta non iscritto -->
        <div class="course-subscription-label" fxLayout="row" fxLayoutAlign="start center">
          <svg-icon src="../../assets/icons/white-squared.svg">
          </svg-icon>
          <span class="word-status" translate="generic.NOT_SUBSCRIBED"></span>
        </div>
        <!-- Etichetta con il numero di edizioni disponibili -->
        <div class="course-subscription-label editions" fxLayout="row" fxLayoutAlign="start center">
          <svg-icon src="../../assets/icons/alert-circle.svg">
          </svg-icon>
          <span class="label-wrapper"><span class="margin-right4 editions-number">{{ item.availableEditionsCount
              }}</span>{{
            item.availableEditionsCount === 1 ? ('generic.AVAILABLE_EDITION' | translate) :
            ('generic.AVAILABLE_EDITIONS' | translate) }}</span>
        </div>
      </div>
    </ng-container>
    <!-- Descrizione -->
    <p class="item-description" *ngIf="card.description" [innerHTML]="card.description | safeHtml: 'html'"></p>
    <!-- Pulsante per entrare nel dettaglio dell'item *ngIf="!isSchoolPage"-->
    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="!hideDetails">
      <!-- TODO -->
      <lib-alloy-button *ngIf="!isInPreview && !isFirstCheckboxVisible && isDetailBtnVisible"
        class="margin-left0 white-background" importance="secondary" dimension="btn-small" [disabled]="isLpItemDisabled"
        (onButtonClicked)="goToItemDetail(fullItem.itemId || item.itemId)" [text]="getButtonTextForSurvey()"
        [attr.aria-label]="getButtonTextForSurvey()">
      </lib-alloy-button>
      <!--Logo / Categoria-->
      <div *ngIf="category" class="logo-wrapper-card-list" fxLayout="row" fxLayoutAlign="start center">
        <div class="logo-card-list">
        </div>
        <h4>Titolo categoria</h4>
      </div>
    </div>
  </div>

  <!-- Checkbox
    <div fxLayout="column">
        <div *ngIf="isFirstCheckboxVisible">
            <md-checkbox aria-label="Check element" ng-checked="suggestedPersonExists(fullItem)"
                (click)="toggleSuggestedPerson(fullItem)" md-no-ink class="md-primary check-item no-margin">
            </md-checkbox>
        </div>
    </div> -->
</div>

<div *ngIf="isLearningPlanEditingMode" class="full-width" fxLayout="column" fxLayoutAlign="start start">
  <!-- Pannelo card Admin -->
  <div class="full-width margin-bottom15" fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign="center center">
    <!-- Switch propedeuticità (se switch sull'on, arpe una modale per scegliere l'oggetto propedeutico per questo) -->
    <div fxLayout="column" fxLayoutAlign="start center" fxFlex>
      <p class="bold-text" translate="generic.IS_ITEM_PROPEDEUTIC"></p>
      <div fxLayout="row" fxLayoutAlign="center center">
        <p class="margin-right10" translate="generic.NO"></p>
        <div fxLayout="row" class="margin-right10">
          <label class="switch" role="button">
            <input type="checkbox" title="{{'card.PREREQUISITE' | translate}}" [disabled]="isInPreview"
              attr.aria-label="{{'card.PREREQUISITE' | translate}}" (click)="onManagePrerequisites($event)"
              [checked]="prerequisites.hasPrerequisites">
            <div class="slider round">
            </div>
          </label>
        </div>
        <p class="margin-left10" translate="generic.YES"></p>
      </div>
    </div>
    <div hide.gt-sx class="space25"></div>
    <!-- Switch obbligatorietà -->
    <div fxLayout="column" fxLayoutAlign="start center" fxFlex>
      <p class="bold-text" translate="generic.IS_ITEM_MANDATORY"></p>
      <div fxLayout="row" fxLayoutAlign="center center">
        <p class="margin-right10" translate="generic.NO"></p>
        <div fxLayout="row" class="margin-right10">
          <label class="switch" role="button">
            <input type="checkbox" title="{{'card.MANDATORY' | translate}}"
              attr.aria-label="{{'card.MANDATORY' | translate}}" (ngModelChange)="changeMandatory()"
              [(ngModel)]="isMandatory">
            <div class="slider round">
            </div>
          </label>
        </div>
        <p class="margin-left10" translate="generic.YES"></p>
      </div>
    </div>
  </div>
  <!-- Oggetto propedeutico per questo -->
  <div class="margin-top-10" *ngIf="propedeuticReferenceId">
    <hr>
    <p class="no-margin"><span translate="sectionProject.ITEM_PREREQUISITE"></span>: {{ propedeuticTitle }}</p>
  </div>
</div>