<!-- Sfondo neutro bg-item - sfondo online bg-item-online - sfondo in aula bg-item-classroom -->
<div>
  <div [ngClass]="(isSyllabusCourse && !isOnlineCourse && !isWebinar)? 'bg-item-classroom' : 'bg-item-online'"
    class="item-details-content">
    <!-- Breadcrumbs -->
    <div
      [ngClass]="{ 'min' : (itemDetails && !itemDetails.isLearningPlan && !itemDetails.isProject && !itemDetails.isBlendedProject) }"
      class="itemDetails more-z-index">
      <div class="padding-top45 padding-bottom15" fxLayout="row" layout-xs="column">
        <div fxLayout="row" fxLayoutAlign="start start">
          <ol *ngIf="isGettingBreadcrumbs" class="breadcrumb-alloy text-uppercase">
            <li>
              <a *ngIf="!adminMode && !isInLibrary && !isInCatalog && !isInTrainingBooklet"
                (click)="goToHome()">Home</a>
              <a *ngIf="!adminMode && isInLibrary" (click)="goToHome()">Library</a>
              <a *ngIf="!adminMode && isInTrainingBooklet" (click)="goToHome()"
                translate="routing.TRAINING_BOOKLET_MIN"></a>
              <a *ngIf="!adminMode && isInCatalog" translate="routing.CATALOG" (click)="goToHome()"></a>
              <a *ngIf="isItemsAdminPreview" (click)="goToListOfItems()" translate="editItem.LIST_OF_ITEMS"></a>
              <a *ngIf="isInitiativesAdminPreview" (click)="goToInitiatives()" translate="routing.INITIATIVES"></a>
              <a *ngIf="isInitiativesAdminFromHomePreview" (click)="goToHomeAdmin()"
                translate="routing.INITIATIVES"></a>
            </li>
            <li>
            </li>
          </ol>
          <ol *ngIf="!isGettingBreadcrumbs" class="breadcrumb-alloy text-uppercase">
            <li>
              <a *ngIf="!adminMode && !isManagerPreview && !isInLibrary && !isInCatalog && !isInTrainingBooklet"
                (click)="goToHome()">Home</a>
              <a *ngIf="!adminMode && isInLibrary" (click)="goToHome()">Library</a>
              <!--a *ngIf="!adminMode && isInTrainingBooklet" (click)="goToHome()"
                translate="routing.TRAINING_BOOKLET_MIN"></a-->
              <a *ngIf="!adminMode && isInCatalog" translate="routing.CATALOG" (click)="goToHome()"></a>
              <a *ngIf="!adminMode && isInTrainingBooklet" (click)="goToHome()"
                translate="routing.TRAINING_BOOKLET_MIN"></a>
              <a *ngIf="isItemsAdminPreview" (click)="goToListOfItems()" translate="editItem.LIST_OF_ITEMS"></a>
              <a *ngIf="isInitiativesAdminPreview" (click)="goToInitiatives()" translate="routing.INITIATIVES"></a>
              <a *ngIf="isInitiativesAdminFromHomePreview" (click)="goToHomeAdmin()"
                translate="routing.INITIATIVES"></a>
              <a *ngIf="isManagerPreview" (click)="goToManagerHome()">Home</a>
            </li>
            <li *ngFor="let breadcrumb of breadcrumbs">
              <a (click)="onBreadcrumbClick(breadcrumb)" [ngClass]="{ 'current' : breadcrumb.isActive }"
                [innerHTML]="breadcrumb.label | safeHtml: 'html'"></a>
            </li>
          </ol>
        </div>
      </div>
    </div>

    <div class="itemDetails more-z-index" fxLayout="column">
      <div fxLayout="column">
        <!-- Titolo -->
        <h2 *ngIf="!isGettingItemDetails && itemDetails && itemDetails.name"
          [innerHTML]="(syllabusTitle || itemDetails.name)  | safeHtml: 'html'"></h2>
        <h2 *ngIf="isGettingItemDetails">
          <div class="line width300 height6"></div>
          <div class="line width250 height6"></div>
        </h2>
        <!-- Sottotitolo -->
        <div *ngIf="itemDetails">
          <h4 *ngIf="itemDetails.subTitle; else extendedItem" [innerHTML]="itemDetails.subTitle"
            [ngClass]="{ 'margin-bottom0' : !itemDetails.subTitle }"></h4>
          <ng-template #extendedItem>
            <h4 *ngIf="itemDetails.extendedItem && itemDetails.extendedItem.subTitle"
              [innerHTML]="itemDetails.extendedItem.subTitle"
              [ngClass]="{ 'margin-bottom0' : !itemDetails.extendedItem.subTitle }">
            </h4>
          </ng-template>
        </div>
        <!--Logo / Categoria -->
        <div *ngIf="itemDetails && itemDetails.categoryTitle" class="logo-wrapper-container" fxLayout="row"
          fxLayoutAlign="start end">
          <div class="logo-wrapper">
          </div>
          <h4>{{ itemDetails.categoryTitle }}</h4>
        </div>
        <div class="stars-wrapper" fxLayout="row" fxLayoutAlign="start start">
          <span *ngIf="isGettingItemDetails">
            <img *ngFor="let star of [0,1,2,3,4]" src="../../../assets/icons/icon-star-o.svg">
          </span>
          <span *ngIf="!isGettingItemDetails && itemDetails && itemDetails.stars && itemDetails.stars.length">
            <img *ngFor="let star of itemDetails.stars" [src]="star.customIcon">
          </span>
        </div>
      </div>

      <div id="learning-plan-tracker" class="lp-tracker">
        <learning-plan-tracker [isExternal]="isExternalObj" [isLoading]="isLoadingEditions"
          [itemDetails]="itemDetailsForPlanTracker$ | async">
        </learning-plan-tracker>
      </div>

      <!-- Box centrale -->
      <div fxLayout="row" fxFill>
        <!-- Box centrale con l'azione principale e l'immagine (opzionale) di copertina -->
        <div class="player-wrapper scale-up-hor-center" fxLayout="row" fxLayout.xs="column"
          fxLayoutAlign="center center" *ngIf="!isPlayerBoxOpened">
          <!-- Box che si vede inizialmente, con a sinistra l'immagine dell'item e a destra le azioni principali -->
          <div fxFlex.gt-xs class="card-img-wrapper" fxLayout="row" fxLayoutAlign="center center">
            <!-- Immagine-->
            <div
              [ngStyle]="{ 'background-image': itemDetails && itemDetails.cardCover ? 'url(' + itemDetails.cardCover + ')' : '' }"
              fxFlex class="card-img"></div>
          </div>
          <div fxFlex.gt-xs fxLayout="row" class="info-player-wrapper-container" fxLayoutAlign="center center">
            <div class="info-player-wrapper">
              <div fxLayout="column" fxFill fxLayoutAlign="space-between center">
                <!-- Tipologia di oggetto-->
                <div
                  *ngIf="isGettingItemDetails || isLoadingEditions || isGettingBreadcrumbs || isLoadingEditionsOfWrappedContainer"
                  class="full-width" fxLayout="column" fxLayoutAlign="space-between start">
                  <div>
                    <div class="line width300 height6"></div>
                    <div class="space80"></div>
                  </div>
                  <div>
                    <div class="line width300 height6"></div>
                    <div class="line width250 height6"></div>
                    <div class="line width300 height6"></div>
                  </div>
                </div>
                <div
                  *ngIf="!isGettingBreadcrumbs && !isLoadingEditionsOfWrappedContainer && !isGettingItemDetails && !isLoadingEditions"
                  class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
                  <p *ngIf="!objectNotFound">
                    <span *ngIf="!isBlendedStage"
                      [ngClass]="(isSyllabusCourse && !isOnlineCourse && !isWebinar)? 'course-bg-class' : 'online-bg-class'"
                      class="skills-legend"></span>
                    <span *ngIf="isBlendedStage" class="skills-legend blended-bg-class"></span>
                    <span *ngIf="itemDetails" class="bold-text">{{ isSyllabusCourse ? itemDetails.itemTypeLabel :
                      itemDetails.cardTypeLabel }}</span>
                    <!-- Tipologia specifica dell'oggetto (solo per gli oggetti library) -->
                    <span *ngIf="!isSyllabusCourse">{{ ' ' + (subtypeLabel || (itemDetails && itemDetails.itemTypeLabel
                      )) }}</span>
                  </p>
                  <!-- Informazioni sullo stato dell'oggetto -->
                  <p *ngIf="getItemCompletitionStatus()">
                    {{ getItemCompletitionStatus() }}</p>
                </div>

                <!-- Etichetta per aprire la lista di contenitori blended al cui interno si trova l'item corrente -->
                <div class="full-width"
                  *ngIf="!isGettingBreadcrumbs && !isLoadingEditionsOfWrappedContainer && !isLoadingEditions && !isGettingItemDetails
                  && itemDetails && ((itemDetails.parentBlendedItems && itemDetails.parentBlendedItems.length) || (itemDetails.parentBlendedStages && itemDetails.parentBlendedStages.length))"
                  fxLayout="column" fxLayoutAlign="center center">
                  <div [ngClass]="{'disable-action': isInPreview}" (click)="openBlendedParentLibraryItems()"
                    fxLayout="row" fxLayoutAlign="center center" class="item-contained-in-label">
                    <div fxLayout="row" fxLayoutAlign="center center">
                      <svg-icon src="'../../assets/icons/alert-circle.svg"></svg-icon>
                    </div>
                    <p translate="generic.ITEM_CONTAINED_IN_"></p>
                  </div>
                </div>

                <!-- Intestazione sopra al pulsante principale (ad esempio la percentuale di avanzamento) -->
                <div class="full-width"
                  *ngIf="!isInPreview && !isGettingBreadcrumbs && !isLoadingEditionsOfWrappedContainer && !isLoadingEditions && !isGettingItemDetails && (!isSyllabusCourse || itemLinkedToCourse)"
                  fxLayout="column" fxLayoutAlign="center center">
                  <p class="margin0 itemDetailMainTextBox full-width"
                    *ngIf="showCompletionPercentage() && itemDetails && (itemDetails && itemDetails.existsItemTakerVisibility)">
                    <span *ngIf="itemType !== 'EXTERNAL_ITEM'" translate="card.types.ADVANCEMENT"></span>
                    <span *ngIf="itemType === 'EXTERNAL_ITEM'"
                      translate="items.AT_BUTTON_CLICK_YOU_WILL_BE_REDIRECT"></span>
                  </p>
                  <div class="itemDetailMainTextBox"
                    *ngIf="!showNoAuthToOperateMessage() && showCompletionPercentage() && itemDetails && (itemDetails && itemDetails.existsItemTakerVisibility)">
                    <p class="itemDetailMainTextBox" *ngIf="!itemLinkedToCourse && itemType !== 'EXTERNAL_ITEM'"><span>
                        {{ itemDetails && itemDetails.completionPercentage }}</span></p>
                    <p *ngIf="itemLinkedToCourse">
                      <span>{{ itemLinkedToCourse && itemLinkedToCourse.completionPercentage }}</span>
                    </p>
                  </div>
                  <p class="itemDetailMainTextBox"
                    *ngIf="showNoAuthToOperateMessage() || showAskPartecipationMessage()">
                    <span *ngIf="showNoAuthToOperateMessage()" translate="errors.CANT_DO_SUGGESTED_ITEM"></span>
                    <span *ngIf="showAskPartecipationMessage()"
                      translate="errors.ASK_PARTECIPATION_TO_OPEN_ITEM"></span>
                  </p>
                  <h3 *ngIf="objectNotFound" translate="errors.OBJECT_NOT_FOUND"></h3>
                </div>
                <!-- Intestazione sopra al pulsante principale qualora fossi in visualizzazione di una iniziativa/corso-->
                <div class="full-dimension"
                  *ngIf="!isInPreview && !isGettingBreadcrumbs && !isLoadingEditionsOfWrappedContainer && !isLoadingEditions && !isGettingItemDetails && isSyllabusCourse && (!itemLinkedToCourse || itemDetails?.presubscriptionEndedNoEdition)">
                  <p class="itemDetailMainTextBox">{{ getMainTextOfMainBox() }}</p>
                </div>
                <!-- Azione principale -->
                <div
                  *ngIf="!isInPreview && !isGettingBreadcrumbs && !isLoadingEditionsOfWrappedContainer && !isLoadingEditions && !isGettingItemDetails"
                  fxLayout="column" fxLayoutAlign="center center">
                  <lib-alloy-button
                    *ngIf="getMainButtonText() && (itemDetails || itemLinkedToCourse) && (itemDetails && (itemDetails.existsItemTakerVisibility || canRequestFromCatalog))"
                    importance="secondary" dimension="btn-regular" [text]="getMainButtonText()"
                    [disabled]="isMainButtonDisabled()" (onButtonClicked)="onMainButtonClicked($event)">
                  </lib-alloy-button>
                  <!-- Azione secondaria -->
                  <p *ngIf="itemDetails && getSecondaryActionText()" (click)="onSecondaryActionClicked()"
                    class="secondary-action cursor-pointer">
                    {{ getSecondaryActionText() }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Box centrale con il player di azure o lo scorm -->
        <div *ngIf="!isInPreview && isPlayerBoxOpened" class="player-wrapper" fxLayout="row"
          fxLayoutAlign="center center">
          <!-- Pulsante per chiudere il box -->
          <lib-alloy-button class="top-position" importance="secondary" dimension="btn-small"
            text="{{'generic.CLOSE' | translate}}" (onButtonClicked)="switchPlayerBoxOpened()">
          </lib-alloy-button>

          <!-- Azure player per gli oggetti Library (quindi se sono in visualizzazione di un oggetto atomico) -->
          <div class="azure-wrapper scale-up-hor-center"
            *ngIf="isAtomicItem && damPlayerVisible && itemDetails.damObjectUrl">
            <azure-player
              *ngIf="!itemDetails.azureImage && !(itemDetails.damObjectUrl && itemDetails.damObjectUrl.endsWith('.mpd'))"
              [itemId]="itemDetails.itemId" [playerVisible]="damPlayerVisible" [seekTo]="damPlayerSeekTo"
              (itemConsumed)="itemConsumed($event)" (itemInProgress)="itemInProgress($event)"
              [objectUrl]="itemDetails.damObjectUrl" [subtitles]="itemDetails.damVideoSubtitle" [adminMode]="adminMode"
              [mimeType]="itemDetails.mimeType" [preventUserControl]="isTraditionalPlayer">
            </azure-player>
            <bitmovin-player
              *ngIf="!itemDetails.azureImage && (itemDetails.damObjectUrl && itemDetails.damObjectUrl.endsWith('.mpd'))"
              [itemId]="itemDetails.itemId" [playerVisible]="damPlayerVisible"
              [seekTo]="damPlayerSeekTo" (itemConsumed)="itemConsumed($event)"
              (itemInProgress)="itemInProgress($event)" [objectUrl]="itemDetails.damObjectUrl"
              [subtitles]="itemDetails.damVideoSubtitle" [adminMode]="adminMode" [mimeType]="itemDetails.mimeType"
              [preventUserControl]="isTraditionalPlayer">
            </bitmovin-player>
            <!-- Box con l'immagine -->
            <div *ngIf="itemDetails.azureImage" fxFlex class="card-img">
              <img [src]="itemDetails.azureImage" alt="image" title="image">
            </div>
          </div>

          <!-- Scorm player -->
          <div *ngIf="scormPlayerVisibile && isAtomicItem" class="full-width-height">
            <scorm-player [projectId]="projectId" [editions]="editionsOfWrappedContainer" [itemWrapper]="itemDetails"
              [playerVisible]="scormPlayerVisibile" (checkEnableCertificaion)="checkEnableCertificaion()">
            </scorm-player>
          </div>

          <!-- Azure player per gli oggetti Library collegati ad un corso online -->
          <div class="azure-wrapper scale-up-hor-center"
            *ngIf="itemLinkedToCourse && isOnlineCourse && itemLinkedToCourse.damPlayerVisible && itemLinkedToCourse.damObjectUrl">
            <azure-player
              *ngIf="!itemLinkedToCourse.azureImage  && (itemLinkedToCourse.damObjectUrl && !itemLinkedToCourse.damObjectUrl.endsWith('.mpd'))"
              [itemId]="itemLinkedToCourse.itemId" [playerVisible]="itemLinkedToCourse.damPlayerVisible"
              [seekTo]="itemLinkedToCourse.damPlayerSeekTo" (itemConsumed)="itemConsumed($event)"
              (itemInProgress)="itemInProgress($event)" [objectUrl]="itemLinkedToCourse.damObjectUrl"
              [subtitles]="itemLinkedToCourse.damVideoSubtitle" [adminMode]="adminMode"
              [mimeType]="itemLinkedToCourse.mimeType" [preventUserControl]="isTraditionalPlayer">
            </azure-player>
            <bitmovin-player
              *ngIf="!itemLinkedToCourse.azureImage && (itemLinkedToCourse.damObjectUrl && itemLinkedToCourse.damObjectUrl.endsWith('.mpd'))"
              [itemId]="itemLinkedToCourse.itemId" [playerVisible]="itemLinkedToCourse.damPlayerVisible"
              [seekTo]="itemLinkedToCourse.damPlayerSeekTo" (itemConsumed)="itemConsumed($event)"
              (itemInProgress)="itemInProgress($event)" [objectUrl]="itemLinkedToCourse.damObjectUrl"
              [subtitles]="itemLinkedToCourse.damVideoSubtitle" [adminMode]="adminMode"
              [mimeType]="itemLinkedToCourse.mimeType" [preventUserControl]="isTraditionalPlayer">
            </bitmovin-player>
            <!-- Box con l'immagine -->
            <div *ngIf="itemLinkedToCourse.azureImage" fxFlex class="card-img">
              <img [src]="itemLinkedToCourse.azureImage" alt="image" title="image">
            </div>
          </div>

          <!-- Scorm player -->
          <div *ngIf="itemDetails && itemLinkedToCourse && itemLinkedToCourse.scormPlayerVisibile && isOnlineCourse"
            class="full-width-height">
            <scorm-player [stageId]="itemDetails.itemId" [editions]="editions" [itemWrapper]="itemLinkedToCourse"
              [playerVisible]="itemLinkedToCourse.scormPlayerVisibile"
              (checkEnableCertificaion)="checkEnableCertificaion()"></scorm-player>
          </div>
        </div>
      </div>

      <!-- Azioni con icone (come like, dislike, condivisione) *ngIf="!isLearningPlan && !isProject && !isBlendedProject" -->
      <div *ngIf="showSocialActionBar()" class="details-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center">
          <!-- Pulsante like -->
          <lib-alloy-button [iconActive]="iLikeThis" *ngIf="!adminMode" [disabled]="isGettingLikes" importance="menu"
            attr.aria-label="{{'JAWSLabels.Buttons.LIKE'|translate}}" dimension="btn-square"
            text="{{'itemDetail.LIKE' | translate}}" icon="assets/icons/icon-thumbs-up.svg" (onButtonClicked)="like()">
          </lib-alloy-button>
          <!-- Pulsante dislike -->
          <!-- <lib-al [iconActive]="iDislikeThis" *ngIf="!adminMode" [disabled]="isGettingLikes" importance="menu"
            dimension="btn-square" text="{{'itemDetail.DISLIKE' | translate}}" icon="assets/icons/icon-thumbs-down.svg"
            (onButtonClicked)="dislike()">
          </lib-al  loy-button> -->
          <!-- Pulsante Condividi
          <lib-alloy-button importance="menu" dimension="btn-square" text="{{'itemDetail.SHARE' | translate}}"
            icon="assets/icons/icon-share.svg">
          </lib-alloy-button> -->
          <!-- Pulsante Wishlist
        <lib-alloy-button importance="menu" dimension="btn-square" text="{{'itemDetail.WISHLIST' | translate}}"
          icon="assets/icons/icon-heart.svg">
        </lib-alloy-button> -->
          <!-- Pulsante Preferiti -->
          <lib-alloy-button [disabled]="isSettingBookmarks" *ngIf="!adminMode && isSyllabusCourse"
            attr.aria-label="{{'JAWSLabels.Buttons.FAVOURITE'|translate}}"
            [iconActive]="syllabus && syllabus.isBookmarked" (onButtonClicked)="addToFavourites()" importance="menu"
            dimension="btn-square" text="{{'itemDetail.FAVOURITE' | translate}}" icon="assets/icons/icon-star-o.svg">
          </lib-alloy-button>
          <!-- Pulsante Aggiungi a Learning Plan -->
          <lib-alloy-button *ngIf="!adminMode && isAtomicItem && canManageLearningPlan()" (click)="addItemToPlaylist()"
            attr.aria-label="{{'JAWSLabels.Buttons.ADD_LP'|translate}}" importance="menu" dimension="btn-square"
            text="{{'itemDetail.ADD_LP' | translate}}" icon="assets/icons/icon-plus-squares.svg">
          </lib-alloy-button>
          <!-- Pulsante per suggerire l'ogetto ad una persona -->
          <lib-alloy-button *ngIf="!adminMode" (click)="openModalForShareItem()" importance="menu"
            attr.aria-label="{{'JAWSLabels.Buttons.SUGGEST'|translate}}" dimension="btn-square"
            text="{{'itemDetail.SUGGEST' | translate}}" icon="assets/icons/icon-share.svg">
          </lib-alloy-button>
        </div>

        <!-- Contatore totale dei like -->
        <div class="like-wrapper" *ngIf="likeCounter" fxLayout="column" fxLayoutAlign="center end">
          <p class="margin-bottom0">
            <strong>{{likeCounter.likes || 0}}</strong>{{ ' ' + ('itemDetail.LIKE' | translate).toLowerCase() }}
          </p>
        </div>
      </div>
    </div>
  </div>


  <div fxLayout="column">
    <!-- Loader delle tab -->
    <div *ngIf="isLoadingTabs" fxLayout="column">
      <div class="lib-alloy-tabs padding0" style="height:63px" fxLayout="row" fxLayoutAlign="start center">
        <div class="customTab-wrapper" fxLayout="row" fxLayoutAlign="start center" fxFlexFill>
          <div class="line width300 height6"></div>
        </div>
      </div>
    </div>
    <!-- Tab -->
    <div id="alloy-tabs-maker"></div>
    <lib-alloy-tabs class="lib-alloy-tabs"
      [ngClass]="{ 'tabsSticky' : areTabsSticky, 'tabsStickyForIE' : areTabsSticky && isUsingInternetExplorer }"
      id="tabs" *ngIf="tabs && tabs.length && !isLoadingTabs" height="50" [tabs]="tabs"
      (onTabClicked)="selectTab($event)" [currentTabId]="currentTab" [activeTabClass]="'customTabActive'">
    </lib-alloy-tabs>

    <!-- Descrizione -->
    <div class="section-detail-wrapper" *ngIf="isDescriptionSectionVisible()" id="description">
      <h2 translate="generic.DESCRIPTION" role="region" attr.aria-label=" "></h2>

      <div fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex>
          <div fxLayout="column" class="description-inner-html">
            <p *ngIf="!descriptionExpanded && itemDetails.description.length >= 1400"
              [innerHTML]="(itemDetails.description.substr(0, 755)) | safeHtml: 'html'"></p>
            <p *ngIf="descriptionExpanded || itemDetails.description.length < 1400"
              [innerHTML]="itemDetails.description | safeHtml: 'html'"></p>

            <!-- Pulsante per espandere/chiudere la descrizione -->
            <lib-alloy-button *ngIf="itemDetails.description.length >= 1400" importance="primary" dimension="btn-small"
              [text]="descriptionExpanded ? ('generic.CLOSE' | translate) : ('itemDetail.EXPAND'  | translate)"
              (onButtonClicked)="descriptionExpanded=!descriptionExpanded"></lib-alloy-button>
          </div>
        </div>

        <!-- Informazioni dell'item (come la durata e la scadenza) -->
        <div *ngIf="isItemInfoPanelVisible()" fxFlex class="best-info-wrapper" fxLayout="column"
          layoutAlign="start start">
          <!-- Durata -->
          <div *ngIf="isHourValueInfoPanelVisible()" class="info-item" fxLayout="row">
            <svg-icon class="icon-detail" src="../../../assets/icons/icon-time.svg"></svg-icon>
            <div class="margin-right15" fxLayout="column">
              <h4><strong translate="items.DURATION"></strong></h4>
              <p *ngIf="!durationSyllabusFilled()">
                {{ hourValueText || (itemLinkedToCourse && itemLinkedToCourse.hourValueText) }}</p>
              <p *ngIf="durationSyllabusFilled()"
                [innerHTML]="frontEndSyllabus.DURATION.data.description | safeHtml: 'html'"></p>
            </div>
          </div>

          <!-- Scadenza -->
          <div *ngIf="courseEndDate" class="info-item" fxLayout="row">
            <svg-icon class="icon-detail" src="../../../assets/icons/icon-date.svg"></svg-icon>
            <div class="margin-right15" fxLayout="column">
              <h4><strong
                  [translate]="(itemDetails?.subtype == 'ONLINE_COURSE' ? 'generic.AVAILABILITY' : 'awWizard.datesAndPlaces.STOP_DATE') | translate"></strong>
              </h4>
              <p>{{ courseEndDate }}</p>
            </div>
          </div>

          <!-- Fruizione -->
          <div *ngIf="isFruitionInfoPanelVisible()" class="info-item" fxLayout="row">
            <svg-icon class="icon-detail" src="../../../assets/icons/icon-pause.svg"></svg-icon>
            <div class="margin-right15" fxLayout="column">
              <h4><strong translate="items.FRUITION"></strong></h4>
              <p translate="items.FRUITION_INFO"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Placeholder scheda corso -->
    <div *ngIf="isGettingItemDetails" class="section-detail-wrapper padding-right0 padding-left0">
      <div>
        <pending-initiatives-placeholder *ngFor="let number of [0,1,2,3]"></pending-initiatives-placeholder>
      </div>
    </div>

    <!-- Syllabus (si vede se l'oggetto è un corso, di qualsiasi tipologia )-->
    <div class="section-detail-wrapper" *ngIf="isSyllabusSectionVisible()" id="syllabus">
      <h2 translate="generic.SYLLABUS_FIX" role="region" attr.aria-label="{{'JAWSLabels.Initiative.COURSE'|translate}}">
      </h2>
      <div fxLayout="row" fxLayoutAlign="start start">

        <div *ngIf="showSyllabusBodyContent()" class="bodyContent" fxLayout="column" fxFlex>
          <!-- Obiettivi -->
          <h3 class="margin-top15" *ngIf="aimsSyllabusFilled()"
            translate="awWizard.generalInfo.syllabusTabs.titles.AIMS">
          </h3>
          <p *ngIf="aimsSyllabusFilled()" [innerHTML]="frontEndSyllabus.AIMS.data.description | safeHtml: 'html'"></p>
          <!-- Programma -->
          <h3 class="margin-top15" *ngIf="agendaSyllabusFilled()"
            translate="awWizard.generalInfo.syllabusTabs.titles.AGENDA"></h3>
          <p *ngIf="agendaSyllabusFilled()" [innerHTML]="frontEndSyllabus.AGENDA.data.description | safeHtml: 'html'">
          </p>
          <!-- Durata -->
          <h3 class="margin-top15" *ngIf="durationSyllabusFilled() && !isOnlineCourse"
            translate="awWizard.generalInfo.syllabusTabs.titles.DURATION"></h3>
          <p *ngIf="durationSyllabusFilled() && !isOnlineCourse"
            [innerHTML]="frontEndSyllabus.DURATION.data.description | safeHtml: 'html'"></p>

          <!--Lingua-->
          <h3 class="margin-top15" *ngIf="hasLanguage" translate="awWizard.generalInfo.syllabusTabs.titles.LANGUAGE">
          </h3>
          <p *ngIf="hasLanguage" [innerHTML]="selectedLanguage | safeHtml: 'html'"></p>


          <!-- A chi si rivolge -->
          <h3 class="margin-top15" *ngIf="prereqsSyllabusFilled()"
            translate="awWizard.generalInfo.syllabusTabs.titles.PREREQS"></h3>
          <p *ngIf="prereqsSyllabusFilled()" [innerHTML]="frontEndSyllabus.PREREQS.data.description | safeHtml: 'html'">
          </p>
          <!-- Metodologia e approccio
          <h3 class="margin-top15" *ngIf="approachSyllabusFilled()"
            translate="awWizard.generalInfo.syllabusTabs.titles.APPROACH"></h3>
          <p *ngIf="approachSyllabusFilled()"
            [innerHTML]="frontEndSyllabus.APPROACH.data.description | safeHtml: 'html'"></p> -->
          <!-- Partner (con chi) -->
          <h3 class="margin-top15" *ngIf="partnerSyllabusFilled()"
            translate="awWizard.generalInfo.syllabusTabs.titles.PARTNER"></h3>
          <p *ngIf="partnerSyllabusFilled()" [innerHTML]="frontEndSyllabus.PARTNER.data.description | safeHtml: 'html'">
          </p>
          <!-- Tech skills
          <h3 class="margin-top15" *ngIf="techCompetencesSyllabusFilled()"
            translate="awWizard.generalInfo.syllabusTabs.titles.TECH_COMPETENCES"></h3>
          <div *ngIf="techCompetencesSyllabusFilled()" class="item-content" fxLayout="row wrap">
            <p *ngFor="let techCompetence of frontEndSyllabus.TECH_COMPETENCES.data" class="label-competences">
              {{techCompetence.title}}
            </p>
          </div>
          Soft skills
          <h3 class="margin-top15" *ngIf="softCompetencesSyllabusFilled()"
            translate="awWizard.generalInfo.syllabusTabs.titles.SOFT_COMPETENCES"></h3>
          <div *ngIf="softCompetencesSyllabusFilled()" class="item-content" fxLayout="row wrap">
            <p *ngFor="let softCompetence of frontEndSyllabus.SOFT_COMPETENCES.data" class="label-competences">
              {{softCompetence.title}}
            </p>
          </div> -->
          <!-- Contatto tutor -->
          <h3 class="margin-top15" *ngIf="tutorContactSyllabusFilled()"
            translate="awWizard.generalInfo.syllabusTabs.titles.TUTOR_CONTACT"></h3>
          <p *ngIf="tutorContactSyllabusFilled()"
            [innerHTML]="frontEndSyllabus.TUTOR_CONTACT.data.description | safeHtml: 'html'"></p>
          <!-- Contatto docente -->
          <h3 class="margin-top15" *ngIf="teacherContactSyllabusFilled()"
            translate="awWizard.generalInfo.syllabusTabs.titles.TEACHER_CONTACT"></h3>
          <p *ngIf="teacherContactSyllabusFilled()"
            [innerHTML]="frontEndSyllabus.TEACHER_CONTACT.data.description | safeHtml: 'html'"></p>
          <!-- Contatto supporto -->
          <h3 class="margin-top15" *ngIf="supportContactSyllabusFilled()"
            translate="awWizard.generalInfo.syllabusTabs.titles.SUPPORT_CONTACT"></h3>
          <p *ngIf="supportContactSyllabusFilled()"
            [innerHTML]="frontEndSyllabus.SUPPORT_CONTACT.data.description | safeHtml: 'html'"></p>
          <!-- Edizione associata all'assessment che sto guardando -->
          <span *ngIf="isAssessment && editions && editions.length">
            <h3 class="margin-top15" translate="generic.LINKED_EDITION"></h3>
            <div *ngFor="let edition of editions">
              <div
                *ngIf="getEditionOfAssessment(edition) && (getEditionOfAssessment(edition).customPlace || getEditionOfAssessment(edition).onlineEventLinkTitle)">
                <p>
                  <strong>{{ ('generic.PLACE' | translate) + ': '
                    }}</strong>{{getEditionOfAssessment(edition).customPlace ||
                  getEditionOfAssessment(edition).onlineEventLinkTitle}}
                </p>
              </div>
              <div *ngIf="getEditionOfAssessment(edition) && getEditionOfAssessment(edition).days">
                <div>
                  <p>
                    <strong>{{ getEditionOfAssessment(edition).days.length === 1 ? (('generic.DATE' | translate) + ': ')
                      : (('generic.DATES' | translate) + ': ') }}</strong>
                    <span *ngFor="let day of getEditionOfAssessment(edition).days; let lastDay = last">
                      <span class="margin0">{{ day.formattedFullDate | amLocale:'it' | amDateFormat: ('ll')}}</span>
                      <!-- Orari della singola giornata -->
                      <span class="margin-bottom6">
                        <span [hidden]="!schedule.startTime || !schedule.endTime"
                          *ngFor="let schedule of day.timeSchedules; let first = first; let last = last">
                          {{ (schedule.startTime | amLocale:'it' | amDateFormat: ('generic.TIME_FORMAT' | translate))
                          + ' - ' + (schedule.endTime | amLocale:'it' | amDateFormat: ('generic.TIME_FORMAT' |
                          translate))
                          + ( !last && day.timeSchedules.length > 1 ? ' / ' : '' ) + ( !lastDay ? ', ' : '' ) }}
                        </span>
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </span>
        </div>

        <!-- Informazioni dell'item (come la durata e la scadenza) -->
        <div *ngIf="isItemInfoPanelVisible()" fxFlex class="best-info-wrapper" fxLayout="column"
          layoutAlign="start start">
          <!-- Durata -->
          <div *ngIf="isHourValueInfoPanelVisible()" class="info-item" fxLayout="row">
            <svg-icon class="icon-detail" src="../../../assets/icons/icon-time.svg"></svg-icon>
            <div class="margin-right15" fxLayout="column">
              <h4><strong translate="items.DURATION"></strong></h4>
              <p *ngIf="!durationSyllabusFilled()">
                {{ hourValueText || (itemLinkedToCourse && itemLinkedToCourse.hourValueText) }}</p>
              <p *ngIf="durationSyllabusFilled()"
                [innerHTML]="frontEndSyllabus.DURATION.data.description | safeHtml: 'html'"></p>
            </div>
          </div>

          <!-- Scadenza -->
          <div *ngIf="courseEndDate" class="info-item" fxLayout="row">
            <svg-icon class="icon-detail" src="../../../assets/icons/icon-date.svg"></svg-icon>
            <div class="margin-right15" fxLayout="column">
              <h4><strong
                  [translate]="(itemDetails?.subtype == 'ONLINE_COURSE' ? 'generic.AVAILABILITY' : 'awWizard.datesAndPlaces.STOP_DATE') | translate"></strong>
              </h4>
              <p>{{ courseEndDate }}</p>
            </div>
          </div>

          <!-- Fruizione -->
          <div *ngIf="isFruitionInfoPanelVisible()" class="info-item" fxLayout="row">
            <img class="icon-detail" src="../../../assets/icons/icon-pause.svg">
            <div class="margin-right15" fxLayout="column">
              <h4><strong translate="items.FRUITION"></strong></h4>
              <p translate="items.FRUITION_INFO"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Competenze -->
    <div class="section-detail-wrapper" *ngIf="isExternalCredentialsVisible()" id="externalCredentials">
      <h2 translate="generic.EXTERNAL_CREDENTIALS"></h2>
      <div *ngFor="let taker of itemDetails.itemTakers">
        <p><b><span translate="generic.USERNAME"></span>:</b> {{externalCredentials.externalLogin}}</p>
        <p><b><span translate="generic.PASSWORD"></span>:</b> {{externalCredentials.externalPsw}}</p>
      </div>
    </div>

    <!-- Materiali (iniziativa coursemanager); si vede solo se è un corso del CM, di qualsiasi tipo -->
    <div class="section-detail-wrapper" *ngIf="isCourseMaterialsSectionVisible()" id="initiativeMaterials">
      <div>
        <div>
          <h2 translate="materials.MATERIALS" role="region"
            attr.aria-label="{{'JAWSLabels.Initiative.MATERIALS'|translate}}"></h2>
          <h3 translate="materials.MATERIAL_LIST_SUBTITLE"></h3>
        </div>

        <!-- Tab per lo switch della categoria dei materiali -->
        <div fxLayout="column">
          <div fxLayout="row wrap">
            <!-- Pre work -->
            <lib-alloy-button importance="bordless" dimension="btn-regular" text="{{ ('awWizard.materials.categoryTypes.PREWORK_MATERIAL' | translate) + ' (' +
            preworkMaterialsCounter + ')' }}" [disabled]="isFetchingMaterialsFiles"
              [isActive]="selectedMaterialTab && selectedMaterialTab == 'MATERIAL_PRE_WORK'"
              (onButtonClicked)="loadUserMaterialList('MATERIAL_PRE_WORK')">
            </lib-alloy-button>
            <!-- Materiali d'aula -->
            <lib-alloy-button importance="bordless" dimension="btn-regular" text="{{ ('awWizard.materials.categoryTypes.ROOM_MATERIAL' | translate) + ' (' +
            classroomMaterialsCounter + ')' }}" [disabled]="isFetchingMaterialsFiles"
              [isActive]="selectedMaterialTab && selectedMaterialTab == 'MATERIAL_CLASSROOM'"
              (onButtonClicked)="loadUserMaterialList('MATERIAL_CLASSROOM')">
            </lib-alloy-button>
            <!-- Materiali post aula -->
            <lib-alloy-button importance="bordless" dimension="btn-regular"
              text="{{ ('awWizard.materials.categoryTypes.POST_ROOM_MATERIAL' | translate) + ' (' + postworkMaterialsCounter + ')' }}"
              [disabled]="isFetchingMaterialsFiles"
              [isActive]="selectedMaterialTab && selectedMaterialTab == 'MATERIAL_POST_WORK'"
              (onButtonClicked)="loadUserMaterialList('MATERIAL_POST_WORK')">
            </lib-alloy-button>
          </div>
        </div>
      </div>

      <!-- Loader dei materiali -->
      <div *ngIf="isFetchingMaterialsFiles">
        <pending-initiatives-placeholder *ngFor="let number of [0,1,2,3]"></pending-initiatives-placeholder>
      </div>

      <div class="scrollable-table-container" *ngIf="!isFetchingMaterialsFiles && !areMissingMaterialsInSelectedTab()">
        <table class="theme-primary-table" role="grid">
          <thead>
            <tr>
              <th role="columnheader">
                <h5 class="bold-text" translate="materials.MATERIAL_TITLE"></h5>
              </th>
              <th role="columnheader">
                <h5 class="bold-text" translate="materials.OBJECT_TYPE"></h5>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let materialItem of materials | slice:0:materialListNumRecords | paginate: { id: 'materialFiles',
            itemsPerPage: materialListNumRecords,
            currentPage: currentMaterialListPage,
            totalItems: currentMaterialListCounter }">
              <td role="cell">
                <div>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxLayout="column">
                      <h5>{{ materialItem && materialItem.title }}</h5>
                      <h5 *ngIf="materialItem && materialItem.parentId && materialItem.parentId !== itemDetails.itemId"
                        (click)="openCourseEditionDetails(materialItem, materialItem.parentId)" class="edition-details"
                        translate="survey.EDITION_DETAILS"></h5>
                    </div>
                  </div>
                </div>
              </td>
              <td role="cell">
                <div fxLayout="column">
                  <h5 *ngIf="materialItem && materialItem.typeTranslation"> {{ materialItem.typeTranslation }}</h5>
                </div>
              </td>
              <td class="survey-table-actions-container" role="cell">
                <div *ngIf="!isInPreview" fxLayout="column" fxLayoutAlign="center center">
                  <a *ngIf="isMaterialToOpen(materialItem)" class="link-action margin0" translate="generic.SHOW"
                    (click)="toggleAzurePlayerModal(materialItem)"></a>
                  <a *ngIf="isMaterialToDownload(materialItem)" class="link-action margin0" translate="generic.DOWNLOAD"
                    (click)="downloadMultimediaItem(materialItem)"></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Footer con la paginazione -->
      <div *ngIf="!isFetchingMaterialsFiles && !areMissingMaterialsInSelectedTab()" fxLayout="row"
        fxLayoutAlign="center center">
        <table-pagination [id]="'materialFiles'" (pageChanged)="loadUserMaterialList(selectedMaterialTab, $event)">
        </table-pagination>
      </div>

      <!-- Etichetta che indica che non sono stati ancora aggiunti file -->
      <div fxLayout="row" fxLayoutAlign="start center" class="min-height224" *ngIf="showNoMaterialFoundLabel()">
        <div fxLayout="row" class="full-width" fxLayoutAlign="start center">
          <div class="gray-info-row-container">
            <div fxLayout="row" class="btn-container">
              <p *ngIf="selectedMaterialTab == 'MATERIAL_PRE_WORK'" class="button-label margin-bottom0"
                translate="materials.NO_FILE_AVAILABLE_YET_PREWORK"></p>
              <p *ngIf="selectedMaterialTab == 'MATERIAL_CLASSROOM'" class="button-label margin-bottom0"
                translate="materials.NO_FILE_AVAILABLE_YET_CLASSROOM"></p>
              <p *ngIf="selectedMaterialTab == 'MATERIAL_POST_WORK'" class="button-label margin-bottom0"
                translate="materials.NO_FILE_AVAILABLE_YET_POSTWORK"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Competenze -->
    <div class="section-detail-wrapper"
      *ngIf="isCompetencesSectionVisible() || (searchableTags && searchableTags.length)" id="competences">
      <h2 translate="generic.TAXONOMY" role="region" attr.aria-label="{{'JAWSLabels.Initiative.TAXONOMY'|translate}}">
      </h2>
      <div fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="isCompetencesSectionVisible()">
        <p *ngFor="let competence of competences" class="label-competences">{{competence}}</p>
      </div>
      <!-- Placeholder di competenze non trovate -->
      <div fxLayout="row" fxLayoutAlign="start center" class="min-height224" fxFlexFill
        *ngIf="!competences || !competences.length && isCompetencesSectionVisible()">
        <div fxLayout="row" class="full-width" fxLayoutAlign="start center">
          <div class="gray-info-row-container">
            <div fxLayout="row" class="btn-container">
              <p class="button-label margin-bottom0" translate="generic.NO_COMPETENCES_FOUND">
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="space25"></div>

      <div *ngIf="searchableTags">
        <div *ngFor="let tag of searchableTags" fxLayout="row" class="btn-container">
          <p style="font-weight: bold" class="button-label margin-bottom0"
            translate="tags.tagTypes.{{tag.attributeType}}"></p>
          <p class="button-label margin-bottom0">: {{tag.crossReferenceObject.title}}</p>
        </div>
      </div>

    </div>

    <!-- Links oggetto -->
    <div class="section-detail-wrapper" *ngIf="isLinksSectionVisible()" id="linkUrls">
      <div fxLayout="column" fxLayoutAlign="start start">
        <h2 translate="card.skills.LINKS" style="margin-bottom: 16px;" role="region"
          attr.aria-label="{{'JAWSLabels.Initiative.LINKS'|translate}}"></h2>
        <div *ngFor="let link of linkUrls; let index = index" fxFill>
          <div fxLayout="row" fxLayoutAlign="space-between center" fxFill>
            <div fxFlex class="url-container-section">
              <a *ngIf="!isInPreview" (click)="openLink(link)" class="link">{{link}}</a>
              <p *ngIf="isInPreview" class="link">{{link}}</p>
            </div>
            <lib-alloy-button *ngIf="!isInPreview" importance="primary left-icon" class="button-for-links"
              dimension="btn-small" [disabled]="isDownloadingExternalResource"
              text="{{ 'generic.COPY_LINK' | translate }}" (onButtonClicked)="copyLinkToClipboard(link)"
              attr.aria-label="{{ 'generic.COPY_LINK' | translate }}">
            </lib-alloy-button>
          </div>
          <hr *ngIf="(linkUrls.length -1) > index" class="hr-separator">
        </div>
      </div>

      <!-- Placeholder di links non trovati -->
      <div fxLayout="row" fxLayoutAlign="start center" class="min-height224" fxFlexFill
        *ngIf="!linkUrls || !linkUrls.length">
        <div fxLayout="row" class="full-width" fxLayoutAlign="start center">
          <div class="gray-info-row-container">
            <div fxLayout="row" class="btn-container">
              <p class="button-label margin-bottom0" translate="generic.NO_LINKS_FOUND">
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Allegati (solo se è un oggetto Library, oppure è un corso online con associato un item della library) -->
    <div class="section-detail-wrapper" *ngIf="showAttachmentsTabContent()" id="attachments">
      <div fxLayout="column" fxLayoutAlign="start start">
        <h2 translate="editItem.ATTACHMENTS" role="region" attr.aria-label="{{'JAWSLabels.Initiative.ATTACHED'}}"></h2>
        <!-- Lista dei materiali -->
        <div *ngFor="let attachments of attachmentList" class="attachment-row" fxLayout="row"
          fxLayoutAlign="space-between center" fxFill>
          <!-- Titolo del file -->
          <p class="padding-right15" *ngIf="attachments && attachments.childObject">
            "{{attachments.childObject.title || attachments.childObject.name}}"</p>
          <lib-alloy-button importance="primary left-icon" dimension="btn-small"
            [disabled]="isDownloadingExternalResource || isInPreview" icon="../../assets/icons/icon-download.svg"
            text="{{ 'generic.DOWNLOAD' | translate }}" (onButtonClicked)="getAttachmentUrl(attachments)"
            attr.aria-label="{{ 'generic.DOWNLOAD' | translate }}">
          </lib-alloy-button>
        </div>
      </div>
    </div>

    <!-- Elementi aggiunti al learnign plan [chiamato playlist] -->
    <div *ngIf="areLearningPlanChildsVisible()" class="section-detail-wrapper" id="itemsOfLP">
      <h2 translate="generic.MODULES" role="region" attr.aria-label="{{'JAWSLabels.Initiative.MODULES' | translate}}">
      </h2>
      <!-- Numero di oggetti complessivi (equivale alla somma di tutti gli oggetti dentro le sezioni) -->
      <div class="project-modules-contents">
        <p *ngIf="childsOfModules !== 1">{{ childsOfModules + ' ' + ('sectionProject.CONTENTS' | translate) }}</p>
        <p *ngIf="childsOfModules === 1">{{ '1 ' + ('sectionProject.CONTENT' | translate) }}</p>
      </div>
      <div
        *ngIf="!isGettingChilds && !itemLinkedToCourse && itemDetails && itemDetails.itemChilds && itemDetails.itemChilds.length"
        fxLayout="column" class="full-width">

        <div class="margin-bottom15" *ngFor="let item of itemDetails.itemChilds; let last = last;"
          [ngClass]="{ 'cursor-default' : !item.isItemDisabled && !isItemDisabled, 'cursor-pointer' : item.isItemDisabled || isItemDisabled }">
          <item-in-learning-plan [lastElement]="last" [mandatory]="item.mandatory"
            [propedeuticReferenceId]="item.propedeuticReferenceId" [propedeuticReferenceIds]="propedeuticReferenceIds"
            [isNotDisabled]="item.isNotDisabled" [allParentChilds]="itemDetails.itemChilds"
            [loggedUserId]="loggedUser.user.userId" [isInPreview]="isInPreview" [item]="item"
            [currentItemType]="itemType" (showPropedeuticalItemToThis)="openShowPropedeuticalItemToThisModal($event)">
          </item-in-learning-plan>
        </div>
      </div>
      <!-- Oggetto library associato al corso online -->
      <div
        *ngIf="!isGettingChilds && itemLinkedToCourse && itemLinkedToCourse.itemChilds && itemLinkedToCourse.itemChilds.length"
        fxLayout="column" class="full-width">
        <div class="margin-bottom15" *ngFor="let item of itemLinkedToCourse.itemChilds; let last = last;"
          [ngClass]="{ 'cursor-default' : !item.isItemDisabled && !isItemDisabled, 'cursor-pointer' : item.isItemDisabled || isItemDisabled }">
          <item-in-learning-plan [lastElement]="last" [mandatory]="item.mandatory"
            [propedeuticReferenceId]="item.propedeuticReferenceId" [propedeuticReferenceIds]="propedeuticReferenceIds"
            [isNotDisabled]="item.isNotDisabled" [allParentChilds]="itemLinkedToCourse.itemChilds"
            [loggedUserId]="loggedUser.user.userId" [isInPreview]="isInPreview"
            (showPropedeuticalItemToThis)="openShowPropedeuticalItemToThisModal($event)" [item]="item"
            [currentItemType]="itemType" [survey]="surveyMapForPlaylist[item.referenceId]"></item-in-learning-plan>
        </div>
      </div>
    </div>

    <!-- Lista Sezioni aggiunte al progetto [chiamato learning plan] -->
    <div class="section-detail-wrapper section-wrapper-lp" *ngIf="areProjectChildsVisible()" id="sectionsOfItem">
      <h2 translate="generic.MODULES" role="region" attr.aria-label="{{'JAWSLabels.Initiative.MODULES' | translate}}">
      </h2>
      <!-- Numero di oggetti complessivi (equivale alla somma di tutti gli oggetti dentro le sezioni) -->
      <div class="project-modules-contents">
        <p *ngIf="childsOfModules !== 1">{{ childsOfModules + ' ' + ('sectionProject.CONTENTS' | translate) }}</p>
        <p *ngIf="childsOfModules === 1">{{ '1 ' + ('sectionProject.CONTENT' | translate) }}</p>
      </div>
      <div *ngIf="!isGettingChilds && childObjects && childObjects.length">
        <div *ngFor="let section of childObjects; let index = index">
          <section [item]="section" [page]="'projectDetails'"
            [searchMandatoryIn]="itemLinkedToCourse && itemLinkedToCourse.itemChilds ? itemLinkedToCourse.itemChilds : itemDetails.itemChilds"
            [searchPropedeuticReferenceIdIn]="itemLinkedToCourse && itemLinkedToCourse.itemChilds ? itemLinkedToCourse.itemChilds : itemDetails.itemChilds"
            [sectionList]="childObjects" [index]="index" [fullItem]="section" [isInPreview]="isInPreview"
            [propedeuticReferenceIds]="propedeuticReferenceIds" [areIDataItems]="true"
            (showPropedeuticalItemToThis)="showPropedeuticalItemToThis($event)"
            (showPropedeuticalItemToThisChild)="showPropedeuticalItemToThisChild($event)"
            [currentProject]="itemLinkedToCourse ? itemLinkedToCourse : itemDetails"
            [loggedUserId]="loggedUser.user.userId"
            [disableActionForLpItemsForInvalidStatus]="disableLpItemsForInvalidStatus || !isTodayInAnyEditionForObjects() || !isTakerValidForLPB()">
          </section>
        </div>
      </div>
    </div>

    <!-- Placeholder edizioni -->
    <div class="section-detail-wrapper  padding-right0 padding-left0" *ngIf="isLoadingEditions">
      <div>
        <h2 class="margin-top20" translate="generic.EDITIONS"></h2>
      </div>
      <div>
        <pending-initiatives-placeholder *ngFor="let number of [0,1,2,3]"></pending-initiatives-placeholder>
      </div>
    </div>

    <!-- Lista edizioni di un corso (solo se il corso non ha un oggetto Library associato) -->
    <div class="section-detail-wrapper"
      *ngIf="isEditionsSectionVisible() && (itemDetails && (itemDetails.existsItemTakerVisibility || canRequestFromCatalog))"
      id="editionsList">
      <div>
        <h2 class="margin-top20" translate="generic.EDITIONS" role="region"
          attr.aria-label="{{'JAWSLabels.Initiative.EDITIONS'|translate}}"></h2>
      </div>
      <!-- Informativa -->
      <div *ngIf="getEditionsDescription()">
        <p [innerHTML]="getEditionsDescription() | safeHtml: 'html'"></p>
      </div>
      <!-- Edizioni per luogo -->
      <table class="theme-primary-table" role="grid">
        <thead>
          <tr>
            <th role="columnheader" translate="generic.STATUS"></th>
            <th *ngIf="!isWebinar && (!isOnlineCourse || (isOnlineCourse && isExternalEventOnline))" role="columnheader"
              translate="generic.PLACE"></th>
            <th *ngIf="!isOnlineCourse" role="columnheader" translate="generic.DATE"></th>
            <th role="columnheader"
              [translate]="(itemDetails?.subtype == 'ONLINE_COURSE' ? 'generic.AVAILABILITY' : 'awWizard.datesAndPlaces.STOP_DATE') | translate">
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr role=" row" *ngFor="let edition of editions | orderByUserStatus">
            <td role="cell">
              <p [ngClass]="{ 'subscribed-status-label' : edition.isConfirmed || edition.isPresent }">
                {{edition.formattedStatus}}</p>
            </td>
            <td *ngIf="!isWebinar && (!isOnlineCourse || (isOnlineCourse && isExternalEventOnline))" role="cell">
              <p>{{edition.customPlace || edition.onlineEventLinkTitle}}</p>
            </td>
            <td *ngIf="!isOnlineCourse" role="cell">
              <!-- Giornate dell'edizione-->
              <div *ngFor="let day of edition.days | orderByDate">
                <p class="margin0">{{ day.formattedFullDate | amLocale:'it' | amDateFormat: ('ll')}}</p>
                <!-- Orari della singola giornata -->
                <h5 class="margin-bottom6">
                  <span [hidden]="!schedule.startTime || !schedule.endTime"
                    *ngFor="let schedule of day.timeSchedules; let first = first; let last = last">
                    {{ (schedule.startTime | amLocale:'it' | amDateFormat: ('generic.TIME_FORMAT' | translate))
                    + ' - ' + (schedule.endTime | amLocale:'it' | amDateFormat: ('generic.TIME_FORMAT' | translate))
                    + ( !last && day.timeSchedules.length > 1 ? ' / ' : '' ) }}
                  </span>
                </h5>
              </div>
            </td>
            <td role="cell">
              <p>{{edition.formattedStopDate}}</p>
              <h5></h5>
            </td>
            <!-- Cella disabilitata per informazioni edizione degli eventi online -->
            <ng-container *ngIf="editionActionDisabledForOnlineCourse">
              <td role="cell"></td>
            </ng-container>
            <!-- Altrimenti apro la modale con i dettagli dell'edizione-->
            <ng-container *ngIf="!editionActionDisabledForOnlineCourse">
              <td [ngClass]="{'disable-action': isInPreview}" (click)="onEditionClicked(edition)" role="cell"
                class="cursor-pointer">
                <svg-icon role="button" attr.aria-label="{{'JAWSLabels.Initiative.OPEN_EDITION'|translate}}"
                  src="assets/icons/icon-angle-right.svg"></svg-icon>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Placeholder docenti -->
    <div class="section-detail-wrapper padding-right0 padding-left0" *ngIf="isLoadingEditions">
      <div>
        <h2 class="margin-top20" translate="generic.TEACHER_TUTOR"></h2>
      </div>
      <div>
        <pending-initiatives-placeholder *ngFor="let number of [0,1,2,3]"></pending-initiatives-placeholder>
      </div>
    </div>

    <div class="section-detail-wrapper" *ngIf="!isLoadingEditions && teachersTutorsList && teachersTutorsList.length"
      id="teacherTutor">
      <div>
        <h2 class="margin-top20" translate="generic.TEACHER_TUTOR" role="region"
          attr.aria-label="{{'JAWSLabels.Initiative.TEACHERS'|translate}}"></h2>
      </div>
      <!-- Docenti e relatori (tutor) -->
      <table class="theme-primary-table" role="grid">
        <thead>
          <tr>
            <th role="columnheader" translate="generic.SURNAME"></th>
            <th role="columnheader" translate="generic.FORENAME"></th>
            <th role="columnheader" translate="generic.ROLE"></th>
            <th role="columnheader" translate="suppliers.BIO" style="text-align: left;"></th>
            <th role="columnheader" translate="suppliers.CV" style="text-align: center;"></th>
          </tr>
        </thead>
        <tbody>
          <tr role="row" *ngFor="let person of teachersTutorsList">
            <td role="cell">
              <p>{{person && person.surname}}</p>
            </td>
            <td role="cell">
              <p>{{person && person.forename}}</p>
            </td>
            <td role="cell">
              <p *ngIf="person.isTeacher" translate="generic.TEACHER"></p>
              <p *ngIf="!person.isTeacher" translate="generic.TUTO"></p>
            </td>
            <td role="cell" style="text-align: left;">
              <p *ngIf="person.description && person.description.length" (click)="openBiographyModal(person)"
                class="biography-label" translate="generic.SHOW"></p>
              <p *ngIf="!person.description || !person.description.length" translate="generic.NA"></p>
            </td>
            <td role="cell">
              <div *ngIf="person.curriculum && !isInPreview">
                <lib-alloy-button importance="primary left-icon" dimension="btn-small"
                  [disabled]="isDownloadingExternalResource || !person.curriculum"
                  icon="../../assets/icons/icon-download.svg" text="{{ 'generic.DOWNLOAD' | translate }}"
                  (onButtonClicked)="getCvUrl(person.curriculum && person.curriculum.referenceId)"
                  attr.aria-label="{{ 'generic.DOWNLOAD' | translate }}" style="display: table; margin: 0 auto;">
                </lib-alloy-button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Sessioni di un corso (rilevazioni/survey) -->
    <div class="section-detail-wrapper" *ngIf="!isLoadingEditions && isSessionSectionVisible()" id="editionsSurvey">
      <div>
        <h2 class="margin-top20" translate="generic.SURVEYS"></h2>
      </div>
      <table *ngIf="editions && editionsSurvey.length" class="theme-primary-table" role="grid">
        <thead>
          <tr>
            <th role="columnheader" translate="generic.STATUS"></th>
            <th role="columnheader" translate="generic.TYPOLOGY"></th>
            <th role="columnheader" translate="awWizard.datesAndPlaces.STOP_DATE"></th>
            <th
              *ngIf="!isWebinar && (!isOnlineCourse  || (isOnlineCourse && isExternalEventOnline) ) && !isExternalCourseOnline"
              role="columnheader" translate="generic.LINKED_EDITION"></th>
            <!-- Redirect alla rilevazione -->
            <th *ngIf="!isAssessment"></th>
          </tr>
        </thead>
        <tbody>
          <tr role="row" *ngFor="let edition of editionsSurvey">
            <!-- Stato dell'assessment -->
            <td *ngIf="getEditionOfAssessment(edition)" role="cell">
              <p [ngClass]="{ 'subscribed-status-label' : getEditionOfAssessment(edition).isSurveyPassed }">
                {{ translateAssessmentStatus(edition)}}
                <!-- {{ getAssessmentOfEditionCompletitionStatus(getEditionOfAssessment(edition)) }}</p> -->
            </td>
            <td role="cell">
              <p *ngIf="edition.assessmentType" tabindex="0">
                {{ edition.assessmentType | translate }}</p>
              <p *ngIf="!edition.assessmentType" tabindex="0">
                {{ 'survey.STANDARD' | translate }}</p>
            </td>
            <td role="cell">
              <p>{{edition.formattedStopDate}}</p>
            </td>
            <td role="cell"
              *ngIf="getEditionOfAssessment(edition) && (!isWebinar && (!isOnlineCourse || (isOnlineCourse && isExternalEventOnline))) && !isExternalCourseOnline">
              <!-- Luogo dell'edizione associata -->
              <div *ngIf="!isAssessment">
                <p class="bold-text">
                  {{getEditionOfAssessment(edition).customPlace ||
                  getEditionOfAssessment(edition).onlineEventLinkTitle}}
                </p>
              </div>
              <!-- Giornata dell'edizione associata -->
              <div *ngFor="let day of getEditionOfAssessment(edition).days | orderByDate">
                <p class="margin0">{{ day.formattedFullDate | amLocale:'it' | amDateFormat: ('ll') }}</p>
                <!-- Orari della singola giornata -->
                <h5 class="margin-bottom6">
                  <span [hidden]="!schedule.startTime || !schedule.endTime"
                    *ngFor="let schedule of day.timeSchedules; let first = first; let last = last">
                    {{ (schedule.startTime | amLocale:'it' | amDateFormat: ('generic.TIME_FORMAT' | translate))
                    + ' - ' + (schedule.endTime | amLocale:'it' | amDateFormat: ('generic.TIME_FORMAT' | translate))
                    + ( !last && day.timeSchedules.length > 1 ? ' / ' : '' ) }}
                  </span>
                </h5>
              </div>
            </td>
            <!-- Redirect alla rilevazione -->
            <td *ngIf="!isAssessment" (click)="goToCourseSurvey(edition)" role="cell" class="cursor-pointer">
              <svg-icon *ngIf="!isInPreview" src="assets/icons/icon-angle-right.svg"></svg-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Attestati delle edizioni all'interno dell'iniziativa -->
    <div *ngIf="isCertificationsOfEditionsSectionVisible()" id="certifications" class="section-detail-wrapper">
      <div fxLayoutAlign="space-between center">
        <h2 translate="generic.CERTIFICATIONS"></h2>
      </div>
      <table *ngIf="editions && certifiedEditions.length" class="theme-primary-table" role="grid">
        <thead>
          <tr>
            <th *ngIf="!isWebinar && (!isOnlineCourse || (isOnlineCourse && isExternalEventOnline))" role="columnheader"
              translate="generic.PLACE"></th>
            <th *ngIf="!isOnlineCourse" role="columnheader" translate="generic.DATE"></th>
            <th translate="generic.ACTIONS"></th>
          </tr>
        </thead>
        <tbody>
          <tr role="row" *ngFor="let edition of certifiedEditions">
            <td *ngIf="!isWebinar && (!isOnlineCourse || (isOnlineCourse && isExternalEventOnline))" role="cell">
              <p>{{edition.customPlace || edition.onlineEventLinkTitle}}</p>
            </td>
            <td *ngIf="!isOnlineCourse" role="cell">
              <!-- Giornata dell'edizione associata -->
              <div *ngFor="let day of edition.days | orderByDate">
                <p class="margin0">{{ day.formattedFullDate | amLocale:'it' | amDateFormat: ('ll')}}</p>
                <!-- Orari della singola giornata -->
                <h5 class="margin-bottom6">
                  <span [hidden]="!schedule.startTime || !schedule.endTime"
                    *ngFor="let schedule of day.timeSchedules; let first = first; let last = last">
                    {{ (schedule.startTime | amLocale:'it' | amDateFormat: ('generic.TIME_FORMAT' | translate))
                    + ' - ' + (schedule.endTime | amLocale:'it' | amDateFormat: ('generic.TIME_FORMAT' | translate))
                    + ( !last && day.timeSchedules.length > 1 ? ' / ' : '' ) }}
                  </span>
                </h5>
              </div>
            </td>
            <!-- Certificati/Attestati-->
            <td class="disable-action" (click)="downloadEditionCertification(edition)" role="cell"
              [ngClass]="{'cursor-pointer': !isInPreview, 'disable-action': isInPreview}">
              <lib-alloy-button importance="bordless left-icon" dimension="btn-small"
                text="{{ 'generic.DOWNLOAD_CERTIFICATE' | translate }}"
                attr.aria-controls="{{ 'generic.DOWNLOAD_CERTIFICATE' | translate}}"
                attr.aria-label="{{ 'generic.DOWNLOAD_CERTIFICATE' | translate}}">
              </lib-alloy-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Attestato di un corso library -->
    <div *ngIf="isCertificationSectionVisible()" id="certification" class="section-detail-wrapper">
      <div fxLayoutAlign="space-between center">
        <h2 translate="generic.CERTIFICATION"></h2>
        <img *ngIf="canShareCertificationOnLinkedin" src="../../../assets/img/logo_linkedin.png" height="31px">
      </div>
      <h3 *ngIf="canShareCertificationOnLinkedin && loggedUser && loggedUser.user"
        class="margin-bottom25  margin-top25">
        {{ loggedUser.user.forename + ' ' + ('generic.CERTIFICATION_LINKEDIN_HINT' | translate).toLowerCase() + '
        Linkedin' }}
      </h3>
      <!-- Sezione certificato -->
      <div *ngIf="showItemCertificationLoader" class="full-width" fxLayout="column" fxLayoutAlign="space-between start">
        <div>
          <div class="line width300 height6"></div>
          <div class="line width300 height6"></div>
          <div class="line width250 height6"></div>
          <div class="line width300 height6"></div>
        </div>
      </div>
      <div *ngIf="!showItemCertificationLoader && certificationImageUrl" fxLayout.gt-xs="row" fxLayout="column"
        fxLayout="start center">
        <div fxFlex class="certificate-img-wrapper">
          <img class="margin-bottom10" [src]="certificationImageUrl">
        </div>
        <div fxFlex class="certificate-call-to-action" fxLayout="column" fxLayoutAlign="center start">
          <div>
            <img class="margin-bottom10" src="../../../assets/img/certificate-icon-img.png" height="52px;"
              width="52px;">
            <div class="margin-bottom16">
              <h4 class="text-uppercase" translate="generic.CERTIFICATION"><br><span *ngIf="!isCertificationObtained()"
                  class="text-lowercase" translate="generic.NOT_RELEASED"></span><span *ngIf="isCertificationObtained()"
                  class="text-lowercase" translate="generic.RELEASED"></span></h4>
            </div>
            <lib-alloy-button *ngIf="!isInPreview" importance="primary" (onButtonClicked)="onDownloadCertificate();"
              dimension="btn-regular"
              [text]="isCertificationObtained() ? ('generic.DOWNLOAD_CERTIFICATE' | translate) : ('generic.CONTINUE_COURSE' | translate)">
            </lib-alloy-button>
            <lib-alloy-button linkedinShare [linkedinShareUrl]="linkedinUrl"
              *ngIf="!isInPreview && canShareCertificationOnLinkedin && linkedinUrl && isCertificationObtained()"
              importance="secondary" dimension="btn-regular" [text]="'generic.SHARE_ON_LINKEDIN' | translate">
            </lib-alloy-button>
          </div>
        </div>
      </div>
    </div>

    <!-- Lista delle persone che mi hanno suggerito l'oggetto -->
    <div class="section-detail-wrapper section-wrapper-lp" *ngIf="hasPrompter" id="prompterList">
      <h2 translate="generic.PROMPTER_LIST_BY" class="margin-bottom25"></h2>

      <div *ngFor="let prompter of prompterList" class="prompter-chip-element">
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <img [src]="getUserAvatar(prompter)" alt="{{'accessibilityAttributes.AVATAR_IMAGE_USER' | translate}}"
            width="96" height="96" title="{{'accessibilityAttributes.AVATAR_IMAGE_USER' | translate}}">
          <p>
            {{ prompter.surname + ' ' + prompter.forename }}</p>
        </div>
      </div>
    </div>

    <!-- Competenze acquisibili -->
    <div class="section-detail-wrapper"
      *ngIf="itemDetails && itemDetails.competences && itemDetails.competences.earnableCompetences && itemDetails.competences.earnableCompetences.length"
      id="acquisibleCompetences">
      <div>
        <h2 class="margin-top20" translate="generic.ACQUISIBLE_COMPETENCES_CLAIMS" role="region"
          attr.aria-label="{{'JAWSLabels.Initiative.NEW_COMPETENCES'|translate}}"></h2>
      </div>
      <table class="theme-primary-table" role="grid">
        <thead>
          <tr>
            <th role="columnheader" translate="initiatives.tableHeaders.COMPETENCE_NAME"></th>
            <th role="columnheader" translate="initiatives.tableHeaders.COMPETENCE_LEVEL"></th>
          </tr>
        </thead>
        <tbody>
          <tr role="row" *ngFor="let acquisibleCompetence of itemDetails.competences.earnableCompetences">
            <td role="cell">
              <p tabindex="0">
                {{ acquisibleCompetence.competenceName || '-' }}</p>
            </td>
            <td role="cell">
              <p tabindex="0">
                {{ acquisibleCompetence.scaleLevelName || '-' }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Competenze necessarie -->
    <div class="section-detail-wrapper"
      *ngIf="itemDetails && itemDetails.competences && itemDetails.competences.requiredCompetences && itemDetails.competences.requiredCompetences.length"
      id="requiredCompetences">
      <div>
        <h2 class="margin-top20" translate="generic.REQUIRED_COMPETENCES_CLAIMS" role="region"
          attr.aria-label="{{'JAWSLabels.Initiative.NEEDED_COMPETENCES'|translate}}"></h2>
      </div>
      <table class="theme-primary-table" role="grid">
        <thead>
          <tr>
            <th role="columnheader" translate="initiatives.tableHeaders.COMPETENCE_NAME"></th>
            <th role="columnheader" translate="initiatives.tableHeaders.COMPETENCE_LEVEL"></th>
          </tr>
        </thead>
        <tbody>
          <tr role="row" *ngFor="let requiredCompetence of itemDetails.competences.requiredCompetences">
            <td role="cell">
              <p tabindex="0">
                {{ requiredCompetence.competenceName || '-' }}</p>
            </td>
            <td role="cell">
              <p tabindex="0">
                {{ requiredCompetence.scaleLevelName || '-' }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</div>

<!-- Modale che avverte l'utente che l'oggetto non è disponibile -->
<lib-alloy-modal class="informative-modal" id="itemNotAvailable" role="dialog" aria-modal="true" tabindex="0"
  aria-labelledby="unavailable-object-title">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h2 class="custom-modal-title" translate="generic.WARNING" id="unavailable-object-title"></h2>
    <svg-icon class="cursor-pointer" (click)="closeItemNotAvailableModal()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <div class="content-modal" fxLayout="column" fxLayoutAlign="start start">
    <h5 translate="generic.ITEM_NOT_AVAILABLE"></h5>
  </div>
  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <div></div>
    <lib-alloy-button importance="primary" dimension="btn-small" [text]="'generic.OK' | translate"
      (onButtonClicked)="closeItemNotAvailableModal()"></lib-alloy-button>
  </div>
</lib-alloy-modal>

<!-- Modale che mostra l'oggetto propedeutico a quello selezionato -->
<lib-alloy-modal class="informative-modal" id="showPropedeuticalItemToThisModal" role="dialog" aria-modal="true"
  tabindex="0" aria-labelledby="propedeutical-to-this-title">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h2 class="custom-modal-title" translate="card.modal.PREREQUISITE" id="propedeutical-to-this-title"></h2>
    <svg-icon class="cursor-pointer" (click)="closeShowPropedeuticalItemToThisModal()"
      src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <div class="content-modal" fxLayout="column" fxLayoutAlign="start start">
    <h5><span translate="card.modal.PREREQUITE_INFO"></span>: </h5>
    <div class="space15"></div>
    <h4 class="text-uppercase">
      {{ showPropedeuticalItemToThisModal && showPropedeuticalItemToThisModal.propedeuticalObject &&
      showPropedeuticalItemToThisModal.propedeuticalObject.childObject &&
      (showPropedeuticalItemToThisModal.propedeuticalObject.childObject.title ||
      showPropedeuticalItemToThisModal.propedeuticalObject.childObject.name) }}
    </h4>
  </div>
  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <div></div>
    <lib-alloy-button importance="primary" dimension="btn-small" [text]="'generic.OK' | translate"
      (onButtonClicked)="closeShowPropedeuticalItemToThisModal()"></lib-alloy-button>
  </div>
</lib-alloy-modal>

<!--  Modale che mostra l'oggetto propedeucito a quello selezionato -->
<lib-alloy-modal class="informative-modal" id="showPropedeuticalToThis" role="dialog" aria-modal="true" tabindex="0"
  aria-labelledby="propedeutical-title">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h2 class="custom-modal-title" translate="card.modal.PREREQUISITE" id="propedeutical-title"></h2>
    <svg-icon class="cursor-pointer" (click)="closePropedeuticalModal()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <div class="content-modal" fxLayout="column" fxLayoutAlign="start start">
    <h5><span translate="card.modal.PREREQUITE_INFO"></span>: </h5>
    <div class="space15"></div>
    <h4 class="text-uppercase">
      {{ propedeuticalObject && propedeuticalObject.childObject && (propedeuticalObject.childObject.title ||
      propedeuticalObject.childObject.name) }}
    </h4>
  </div>
  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <div></div>
    <!-- Chiude la modale -->
    <lib-alloy-button importance="primary" dimension="btn-regular" text="{{'generic.OK' | translate}}"
      (onButtonClicked)="closePropedeuticalModal();">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>

<!--  Modale che mostra l'oggetto propedeucito a quello selezionato -->
<lib-alloy-modal class="informative-modal" id="azurePlayerModal" aria-labelledby="azure-title" tabindex="0"
  role="dialog" aria-modal="true">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h2 class="custom-modal-title" *ngIf="selectedMaterialForModal" id="azure-title">
      {{ selectedMaterialForModal.title || ('modals.kalturaPlayerModal.TITLE' | translate) }}</h2>
    <svg-icon class="cursor-pointer" (click)="toggleAzurePlayerModal()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <div *ngIf="selectedMaterialForModal && selectedMaterialForModal.itemId" class="content-modal" fxLayout="column"
    fxLayoutAlign="start start">
    <azure-player
      *ngIf="!selectedMaterialForModal.azureImage && (selectedMaterialForModal.damObjectUrl && !selectedMaterialForModal.damObjectUrl.endsWith('.mpd'))"
      playerVisible="true" [itemId]="selectedMaterialForModal.itemId"
      [objectUrl]="selectedMaterialForModal.damObjectUrl" [subtitles]="selectedMaterialForModal.damVideoSubtitle"
      [mimeType]="selectedMaterialForModal.mimeType" class="full-width" [preventUserControl]="isTraditionalPlayer">
    </azure-player>
    <bitmovin-player
      *ngIf="!selectedMaterialForModal.azureImage && (selectedMaterialForModal.damObjectUrl && selectedMaterialForModal.damObjectUrl.endsWith('.mpd'))"
      [itemId]="selectedMaterialForModal.itemId" [playerVisible]="true"
      (itemConsumed)="itemConsumed($event)"
      (itemInProgress)="itemInProgress($event)" [objectUrl]="selectedMaterialForModal.damObjectUrl"
      [subtitles]="selectedMaterialForModal.damVideoSubtitle"
      [mimeType]="selectedMaterialForModal.mimeType" [preventUserControl]="isTraditionalPlayer">
    </bitmovin-player>
    <!-- Box con l'immagine -->
    <div *ngIf="selectedMaterialForModal && selectedMaterialForModal.azureImage" fxFlex class="card-img">
      <img [src]="selectedMaterialForModal.azureImage" alt="image" title="image">
    </div>
  </div>
  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <div></div>
    <!-- Chiude la modale -->
    <lib-alloy-button importance="primary" dimension="btn-regular" text="{{'generic.CLOSE' | translate}}"
      (onButtonClicked)="toggleAzurePlayerModal();">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>

<!-- Modale che permette di cercare una playlist a cui aggiungere l'item -->
<lib-alloy-modal class="not-destructive-modal" id="addItemToPlaylist" tabindex="0" role="dialog" aria-modal="true">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h2 class="custom-modal-title" translate="generic.itemTypes.LEARNING_PLAN" id="learning-plan-title" tabindex="0"
      attr.aria-label="{{'JAWSLabels.+playlist.MESSAGE'|translate}}"></h2>
    <svg-icon class="cursor-pointer" (click)="closeAddContentModal()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <div *ngIf="itemDetails" class="content-modal" id="add-item-to-playlist-content-modal" fxLayout="column"
    fxLayoutAlign="start start">
    <!-- Ricerca delle playlist già create  -->
    <div [hidden]="isCreatingNewPlaylist" class="margin-bottom10 margin-top10 full-width" fxLayout="column">
      <p translate="itemDetail.CHOOSE_YOUR_PLAYLIST"></p>
    </div>
    <div [hidden]="isCreatingNewPlaylist" fxLayout="column" class="full-width">
      <!-- Testo di ricerca per gli item-->
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.sm="center center" fxLayoutAlign.xs="center center"
        class="full-width choose-new-supplier-modal-container">
        <form #learningPlanSearchedText="ngForm" class="full-width new-suppliers-to-choose-text-filter-form">
          <lib-alloy-input *ngIf="libraryItemsData" id="learningPlanSearchedTextId" [type]="'text'"
            [bindValue]="libraryItemsData.searchedText" (onModelChanged)="onGetLibraryItems($event, true)"
            [placeholder]="'awWizard.generalInfo.TYPE_ITEM_NAME' | translate"
            [attrAriaLabel]="'awWizard.generalInfo.TYPE_ITEM_NAME' | translate" inputContainerClass="regular-dimension">
          </lib-alloy-input>
        </form>
      </div>

      <div fxLayout="column" role="radiogroup">
        <div *ngIf="isFetchingLibraryItems">
          <pending-initiatives-placeholder *ngFor="let number of [0,1,2,3,4,5,6]">
          </pending-initiatives-placeholder>
        </div>

        <!-- Item selezionato -->
        <!-- Lista degli oggetti (se non ho ancora selezionato un Item) -->
        <div
          *ngIf="!isFetchingLibraryItems && libraryItemsData && libraryItemsData.libraryItems && libraryItemsData.libraryItems.length">

          <table class="theme-primary-table" aria-label="Tabella Selezione Playlist">
            <thead>
              <tr>
                <th role="columnheader" class="select-syllabus-column" translate="generic.SELECT"></th>
                <th role="columnheader" translate="generic.TITLE"></th>
                <th role="columnheader" class="creation-date-syllabus-column" translate="generic.CREATION_DATE"></th>
              </tr>
            </thead>
            <tbody>
              <tr (click)="selectedItemOfOnlineCourseChanged(item, 0 )" class="radio-row" role="row" *ngFor="let item of libraryItemsData.libraryItems | slice:0:libraryItemsData.libraryItemsNumRecords | paginate: { id: 'libraryItems',
                itemsPerPage: libraryItemsData.libraryItemsNumRecords,
                currentPage: libraryItemsData.libraryItemsCurrentPage,
                totalItems: libraryItemsData.libraryItemsCounter }; let i = index">
                <td tabindex="0" *ngIf="libraryItemsData">
                  <div>
                    <input type="radio" name="learningPlans" id="{{'learningPlans' + i}}"
                      [ngModel]="libraryItemsData.selectedItemId" value="{{item.itemId}}">
                    <span aria-hidden="true"></span>
                  </div>
                </td>
                <td>
                  <label for="{{'learningPlans' + i}}"> {{ item.title }}</label>
                </td>
                <td>
                  {{ item.creationDate | amLocale:'it' | amUtc | amDateFormat: ('generic.DATE_FORMAT_FULL' | translate)
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Etichetta che indica che non sono stati trovati item -->
        <div fxLayout="row"
          *ngIf="!isFetchingLibraryItems && (!libraryItemsData || !libraryItemsData.libraryItems || !libraryItemsData.libraryItems.length)">
          <div class="gray-info-row-container">
            <div fxLayout="row" class="btn-container">
              <p class="button-label margin-bottom0" translate="generic.NO_DATA_FOUND">
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer con la paginazione -->
      <div *ngIf="libraryItemsData && libraryItemsData.libraryItems && libraryItemsData.libraryItems.length"
        fxLayout="row" fxLayoutAlign="center center">
        <table-pagination [forceDisabled]="isFetchingLibraryItems" [id]="'libraryItems'"
          (pageChanged)="paginationLibraryItemsChanged($event)"></table-pagination>
      </div>
      <div class="space20"></div>
    </div>

    <!-- Form di creazione nuova playlist -->
    <div *ngIf="isCreatingNewPlaylist" class="margin-bottom10 margin-top10 full-width" fxLayout="column">
      <p translate="itemDetail.CREATE_PLAYLIST_USER_HINT"></p>
    </div>

    <!-- Titolo -->
    <div *ngIf="isCreatingNewPlaylist" fxLayout="column" fxFill fxLayoutAlign="space-between center"
      class="playlist-new-title-container">
      <div fxLayout="row" fxFill>
        <input-placeholder class="margin-bottom20" *ngIf="isCreatingPlaylist"></input-placeholder>
        <div class="full-width" *ngIf="!isCreatingPlaylist && isCurrentItemLangDefaultLang() && currentItemLang">
          <lib-alloy-input id="itemLangTitle" [type]="'text'" [bindValue]="currentItemLang.title"
            title="{{ 'generic.TITLE' | translate }}" (onModelChanged)="onCurrentItemLangTitleChanged($event)"
            [attrName]="'itemLangTitle'" name="itemLangTitle" [required]="true"
            [placeholder]="'generic.TITLE' | translate" [attrAriaLabel]="'generic.TITLE' | translate"
            inputContainerClass="regular-dimension" [subtitle]="getItemLangInputSubtitle()">
          </lib-alloy-input>
        </div>
        <div class="full-width"
          *ngIf="!isCreatingPlaylist && !isCurrentItemLangDefaultLang() && !currentItemLang && defaultItemLang">
          <lib-alloy-input id="itemTitle" [type]="'text'" [bindValue]="defaultItemLang.title"
            title="{{ 'generic.TITLE' | translate }}" (onModelChanged)="onDefaultItemLangTitleChanged($event)"
            [attrName]="'itemTitle'" name="itemTitle" [required]="true" [placeholder]="'generic.TITLE' | translate"
            [attrAriaLabel]="'generic.TITLE' | translate" inputContainerClass="regular-dimension"
            [subtitle]="getItemLangInputSubtitle()">
          </lib-alloy-input>
        </div>
      </div>
    </div>

    <!-- Sottotitolo -->
    <div *ngIf="isCreatingNewPlaylist && isCreatingPlaylist" fxFill fxLayout="row"
      class="playlist-new-subtitle-container">
      <input-placeholder class="margin-bottom20"></input-placeholder>
    </div>

    <!-- Sottotitolo -->
    <div *ngIf="isCreatingNewPlaylist && !isCreatingPlaylist" fxFill fxLayout="row"
      class="playlist-new-subtitle-container">
      <div class="full-width" *ngIf="isCurrentItemLangDefaultLang() || (currentItemLang && currentItemLang.subTitle)">
        <lib-alloy-textarea [bindValue]="currentItemLang.subTitle" title="{{ 'generic.SUBTITLE' | translate }}"
          (onModelChanged)="onCurrentItemLangChanged($event)" [placeholder]="'generic.SUBTITLE' | translate"
          [attrAriaLabel]="'generic.SUBTITLE' | translate" inputContainerClass="regular-dimension"
          [subtitle]="getCurrentItemLangDescSubtitle()">
        </lib-alloy-textarea>
      </div>
      <div class="full-width" *ngIf="!isCurrentItemLangDefaultLang() && currentItemLang && !currentItemLang.subTitle">
        <lib-alloy-textarea [bindValue]="defaultItemLang.subTitle" title="{{ 'generic.SUBTITLE' | translate }}"
          (onModelChanged)="onDefaultItemLangChanged($event)" [placeholder]="'generic.SUBTITLE' | translate"
          [attrAriaLabel]="'generic.SUBTITLE' | translate" inputContainerClass="regular-dimension"
          [subtitle]="getCurrentItemLangDescSubtitle()">
        </lib-alloy-textarea>
      </div>
    </div>

    <!-- Descrizione -->
    <div *ngIf="isCreatingNewPlaylist" fxFill fxLayout="column" fxLayoutAlign="start start"
      class="playlist-new-description-container">
      <div class="title-input">
        <p *ngIf="!isCreatingPlaylist" class="margin-bottom8" translate="generic.DESCRIPTION"></p>
      </div>
      <input-placeholder class="margin-bottom20" *ngIf="isCreatingPlaylist"></input-placeholder>
      <div fxFill fxLayout="row" *ngIf="ckeConfig">
        <div class="regular-dimension"
          *ngIf="!isCreatingPlaylist && isCurrentItemLangDefaultLang() || (currentItemLang && currentItemLang.description)">
          <ckeditor #ckeditor [(ngModel)]="currentItemLang.description" [config]="ckeConfig">
          </ckeditor>
        </div>
        <div class="regular-dimension"
          *ngIf="!isCreatingPlaylist && !isCurrentItemLangDefaultLang() && currentItemLang && !currentItemLang.description">
          <ckeditor #ckeditor [(ngModel)]="currentItemLang.description" [config]="ckeConfig">
          </ckeditor>
        </div>
      </div>
      <div *ngIf="!isCreatingPlaylist" fxLayout="column" fxFill class="bottom-description-container">
        <p>
          {{ ('newContainer.placeholders.INSERT_LEARNING_PLAN_DESCRIPTION' | translate) + (isDescriptionValorized() ? ''
          : '*') }}
        </p>
      </div>
    </div>

    <!-- Pulsante per iniziare il processo di creazione di una nuova Playlist -->
    <div *ngIf="!isCreatingNewPlaylist" fxLayout="column" class="full-width margin-bottom30">
      <lib-alloy-button text="{{'itemDetail.OR_CREATE_A_NEW_PLAYLIST' | translate}}" (click)="goToCreateNewPlaylist()">
      </lib-alloy-button>
    </div>

  </div>
  <div class="actions" role="region" aria-label="Annulla" fxLayout.gt-xs="row" fxLayout="column"
    fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center">
    <!-- Annulla l'operazione -->
    <lib-alloy-button importance="bordless" dimension="btn-regular" text="{{'generic.CANCEL' | translate}}"
      (onButtonClicked)="closeAddContentModal()">
    </lib-alloy-button>
    <div fxHide.gt-xs class="space15"></div>
    <!-- Conferma l'operazione di aggiunta item alla Plyalist selezionata -->
    <lib-alloy-button *ngIf="!isCreatingNewPlaylist" importance="primary" dimension="btn-regular"
      text="{{'generic.CONFIRM' | translate}}" [disabled]="!libraryItemsData || !libraryItemsData.selectedItemId"
      (onButtonClicked)="closeAddContentModal(true);">
    </lib-alloy-button>
    <!-- Conferma la creazione di una nuova Playlist -->
    <lib-alloy-button *ngIf="isCreatingNewPlaylist" importance="primary" dimension="btn-regular"
      text="{{'itemDetail.CREATE' | translate}}"
      [disabled]="isCreatingPlaylist || isItemLangTitlesEmpty()|| !isDescriptionValorized() || isGettingItemDetails"
      (onButtonClicked)="closeCreateNewPlaylist(true);">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>

<!-- Modale che permette di cercare una persona a cui suggerire l'oggetto -->
<lib-alloy-modal class="not-destructive-modal" id="suggestItem" role="dialog" aria-modal="true" tabindex="0"
  aria-labelledby="suggest-person-title">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h2 class="custom-modal-title" translate="itemDetail.SUGGEST" id="suggest-person-title"></h2>
    <svg-icon class="cursor-pointer" (click)="closeModalForShareItem()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <div *ngIf="itemDetails" class="content-modal" fxLayout="row" fxLayoutAlign="start start">
    <div fxLayout="column" class="full-width">
      <!-- Testo di ricerca per gli utenti -->
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.sm="center center" fxLayoutAlign.xs="center center"
        class="full-width choose-new-supplier-modal-container">
        <form #usersToSuggestSearchedText="ngForm" class="full-width new-suppliers-to-choose-text-filter-form">
          <lib-alloy-input *ngIf="usersToSuggestData" id="usersToSuggestSearchedText" [type]="'text'"
            [bindValue]="usersToSuggestData.searchedText" (onModelChanged)="onGetUsersToSuggest($event, true)"
            [placeholder]="'itemDetail.TYPE_USER_NAME_TO_SUGGEST' | translate"
            [attrAriaLabel]="'itemDetail.TYPE_USER_NAME_TO_SUGGEST' | translate"
            inputContainerClass="regular-dimension">
          </lib-alloy-input>
        </form>
      </div>

      <div fxLayout="column">
        <div *ngIf="isFetchingUsersToSuggest">
          <pending-initiatives-placeholder *ngFor="let number of [0,1,2,3,4,5,6]">
          </pending-initiatives-placeholder>
        </div>

        <!-- Lista delle persone -->
        <div
          *ngIf="!isFetchingUsersToSuggest && usersToSuggestData && usersToSuggestData.usersToSuggest && usersToSuggestData.usersToSuggest.length">
          <table role="grid" class="theme-primary-table">
            <thead>
              <tr>
                <th role="columnheader" class="select-syllabus-column" translate="generic.SELECT"></th>
                <th role="columnheader" class="creation-date-syllabus-column" translate="itemDetail.PERSON"></th>
              </tr>
            </thead>
            <tbody>
              <tr role="row" *ngFor="let userToSuggest of usersToSuggestData.usersToSuggest | slice:0:usersToSuggestData.usersToSuggestNumRecords | paginate: { id: 'usersToSuggest',
        itemsPerPage: usersToSuggestData.usersToSuggestNumRecords,
        currentPage: usersToSuggestData.usersToSuggestCurrentPage,
        totalItems: usersToSuggestData.usersToSuggestCounter }; let i = index">
                <td role="cell">
                  <div *ngIf="usersToSuggestData" class="md-radio">
                    <input
                      [disabled]="userToSuggest && (userToSuggest.itemAlreadySuggested || !userToSuggest.haveTakerVisibility)"
                      type="radio" name="{{'userToSuggest' + i}}" [ngModel]="usersToSuggestData.selectedUserId"
                      value="{{userToSuggest.userId}}">
                    <label for="{{'userToSuggest' + i}}" (click)="selectedUserToSuggestChanged(userToSuggest)"></label>
                  </div>
                </td>
                <td role="cell">
                  <p class="bold-text">
                    {{ (userToSuggest && userToSuggest.surname) + ' ' + (userToSuggest && userToSuggest.forename) }}
                  </p>
                  <h5><a *ngIf="userToSuggest" href="mailto:{{ userToSuggest.email }}">{{ userToSuggest.email }}</a>
                  </h5>
                  <div *ngIf="userToSuggest && userToSuggest.itemAlreadySuggested">
                    <p style="font-style: italic; margin-top: 5px;" translate="itemDetail.ALREADY_SUGGESTED"></p>
                  </div>
                  <div *ngIf="userToSuggest && !userToSuggest.haveTakerVisibility">
                    <p style="font-style: italic; margin-top: 5px;"
                      translate="itemDetail.TAKER_WITHOUT_TAKER_VISIBILITY"></p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Etichetta che indica che non sono stati trovati utenti -->
        <div fxLayout="row" class="margin-bottom30"
          *ngIf="(!isFetchingUsersToSuggest && (!usersToSuggestData || !usersToSuggestData.usersToSuggest || !usersToSuggestData.usersToSuggest.length)) && usersToSuggestData.searchedText">
          <div class="gray-info-row-container">
            <div fxLayout="row" class="btn-container">
              <p class="button-label margin-bottom0" translate="itemDetail.NO_PERSON_FOUND">
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="row" class="margin-bottom30"
          *ngIf="(!isFetchingUsersToSuggest && (!usersToSuggestData || !usersToSuggestData.usersToSuggest || !usersToSuggestData.usersToSuggest.length)) && !usersToSuggestData.searchedText">
          <div class="gray-info-row-container">
            <div fxLayout="row" class="btn-container">
              <p class="button-label margin-bottom0" translate="itemDetail.FIND_USERS_BY_NAME">
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer con la paginazione -->
      <div *ngIf="usersToSuggestData && usersToSuggestData.usersToSuggest && usersToSuggestData.usersToSuggest.length"
        fxLayout="row" fxLayoutAlign="center center">
        <table-pagination [forceDisabled]="isFetchingUsersToSuggest" [id]="'usersToSuggest'"
          (pageChanged)="paginationUsersToSuggestItemChanged($event, usersToSuggestData.searchedText)">
        </table-pagination>
      </div>
      <div class="space20"></div>
    </div>
  </div>
  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- Annulla l'operazione -->
    <lib-alloy-button importance="bordless" dimension="btn-regular" text="{{'generic.CANCEL' | translate}}"
      (onButtonClicked)="closeModalForShareItem()">
    </lib-alloy-button>
    <div fxHide.gt-xs class="space15"></div>
    <!-- Conferma l'operazione -->
    <lib-alloy-button importance="primary" dimension="btn-regular" text="{{'generic.CONFIRM' | translate}}"
      [disabled]="!usersToSuggestData || !usersToSuggestData.selectedUserId"
      (onButtonClicked)="closeModalForShareItem(true);">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>

<!-- Conferma iscrizione -->
<lib-alloy-modal class="not-destructive-modal" id="confirmCourseSubscription" role="dialog" aria-modal="true"
  tabindex="0" aria-labelledby="confirm-subscription-title">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h2 tabindex="0" class="custom-modal-title" translate="takers.courseSubscription.modal.confirmSubscription.TITLE"
      id="confirm-subscription-title"></h2>
    <svg-icon *ngIf="selectedEditionForConfirmSubscription && selectedEditionForConfirmSubscription.cancelBtnLabel"
      class="cursor-pointer" (click)="dismissConfirmCourseSubscription()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <div class="content-modal" fxLayout="row" fxLayoutAlign="start start">
    <div *ngIf="selectedEditionForConfirmSubscription && !selectedEditionForConfirmSubscription.isOnlineCourse"
      class="md-dialog-content full-width">

      <!-- Dettaglio edizione -->
      <div *ngIf="!selectedEditionForConfirmSubscription.showMaps && selectedEditionForConfirmSubscription"
        class="full-width">
        <h3 translate="generic.SUMMARY" tabindex="0"></h3>
        <!-- Titolo custom -->
        <h3 *ngIf="selectedEditionForConfirmSubscription.customTitle" tabindex="0">
          {{selectedEditionForConfirmSubscription.customTitle}}</h3>
        <h5 *ngIf="selectedEditionForConfirmSubscription.customSubTitle" tabindex="0">
          {{selectedEditionForConfirmSubscription.customSubTitle}}</h5>
        <h5 *ngIf="selectedEditionForConfirmSubscription.description" tabindex="0">
          <b>{{('generic.DESCRIPTION' | translate) + ': '}}</b>
          <div [innerHTML]="selectedEditionForConfirmSubscription.description" style="display: inline-block;"></div>
        </h5>
        <h5 *ngIf="selectedEditionForConfirmSubscription.availableSeats" tabindex="0">
          <b>{{ ('generic.NUMBER_AVAILABLE_SEATS' | translate) + ': ' }}</b>
          {{ selectedEditionForConfirmSubscription.availableSeats }}
        </h5>
        <h5 tabindex="0"
          *ngIf="!selectedEditionForConfirmSubscription.availableSeats || selectedEditionForConfirmSubscription.availableSeats == 0">
          <b class="text-primary-1" translate="generic.NO_AVAILABLE_SEAT"></b>
        </h5>
        <h5 *ngIf="selectedEditionForConfirmSubscription.durationDaysLabel" tabindex="0">
          <b>{{ ('awWizard.generalInfo.syllabusTabs.titles.DURATION' | translate) + ': ' }}</b>
          {{ selectedEditionForConfirmSubscription.durationDaysLabel }}
        </h5>
        <h5 tabindex="0"
          *ngIf="selectedEditionForConfirmSubscription.teachers && selectedEditionForConfirmSubscription.teachers.length">
          <b>{{ ('generic.TEACHERS' | translate) + ': ' }}</b>
          <span *ngFor="let teacher of selectedEditionForConfirmSubscription.teachers" style="margin-left: 5px">{{
            teacher.title }}</span>
        </h5>
        <h5 *ngIf="selectedEditionForConfirmSubscription.tutors && selectedEditionForConfirmSubscription.tutors.length"
          tabindex="0">
          <b>{{ ('generic.TUTORS' | translate) + ': ' }}</b><span
            *ngFor="let tutor of selectedEditionForConfirmSubscription.tutors" style="margin-left: 5px">{{ tutor.title
            }}</span>
        </h5>
        <h5 *ngIf="selectedEditionForConfirmSubscription.stopDate" tabindex="0">
          <b>{{ ('generic.END_DATE_SUBSCRIPTION' | translate) + ': ' }}</b>{{
          selectedEditionForConfirmSubscription.stopDate }}
        </h5>
        <h5 *ngIf="selectedEditionForConfirmSubscription.subscriptionCustomLink" tabindex="0">
          <b *ngIf="isWebinar || isWebinarSpecialization; else isNotWebinar">{{('generic.SUBSCRIPTION_WEBINAR' |
            translate) + ': ' }}</b>
          <ng-template #isNotWebinar><b>{{('generic.SUBSCRIPTION_LINK' | translate) + ': ' }}</b></ng-template>
          <a class="link-action black" style="word-break: break-all" target="_blank"
            href="{{ selectedEditionForConfirmSubscription.subscriptionCustomLink }}">
            {{ selectedEditionForConfirmSubscription.subscriptionCustomLink }}</a>
        </h5>
        <h5 *ngIf="selectedEditionForConfirmSubscription.subscriptionCustomMail" tabindex="0"><b>Email: </b>
          <a class="link-action black" style="word-break: break-all" target="_blank"
            href="{{ selectedEditionForConfirmSubscription.subscriptionCustomMail }}">{{
            selectedEditionForConfirmSubscription.subscriptionCustomMail }}</a>
        </h5>
        <div *ngFor="let courseDay of selectedEditionForConfirmSubscription.days; let i = index" class="dayDetail"
          fxLayout="column" fxLayoutAlign="start start">
          <div *ngIf="!isWebinar" fxFill fxLayout="row" fxLayoutAlign="space-between center">
            <h4 tabindex="0"
              *ngIf="selectedEditionForConfirmSubscription.days && selectedEditionForConfirmSubscription.days.length && selectedEditionForConfirmSubscription.days.length > 1">
              {{ ('awWizard.datesAndPlaces.DAY_DATE' | translate) }} {{ i + 1 }}</h4> <a
              *ngIf="isMapsEnabled(selectedEditionForConfirmSubscription, i)"
              (click)="openMaps(selectedEditionForConfirmSubscription, i)">{{ 'generic.SHOW_MAP' | translate}}</a>
          </div>
          <hr>
          <div class="dayDetailWrapper" fxFill fxLayout="column" fxLayoutAlign="start start">
            <h5 tabindex="0"><b>{{ courseDay.dayDate | amLocale:'it' | amDateFormat: stopDateCourseSubscriptionFormat
                }}</b></h5>
            <h4 tabindex="0"
              *ngIf="!isWebinar && courseDay.location && courseDay.location.address && courseDay.location.address.id">
              {{ courseDay.location.address.id }}</h4>
            <h5 tabindex="0" [hidden]="!schedule.startTime || !schedule.endTime"
              *ngFor="let schedule of courseDay.timeSchedules">
              <span>{{ schedule.startTime | amLocale:'it' | amDateFormat: timeFormat }} - {{ schedule.endTime |
                amLocale:'it' | amDateFormat: timeFormat }}</span>
            </h5>
            <h5 tabindex="0"
              *ngIf="!isWebinar && courseDay.location && courseDay.location.streetNumber && courseDay.location.streetNumber.title">
              {{ courseDay.location.streetNumber.title }}</h5>
            <h5 tabindex="0" *ngIf="!isWebinar">{{ (
              'awWizard.datesAndPlaces.placeholders.ROOM' |
              translate) + " " + (courseDay.location.room && courseDay.location.room.title || '') }}</h5>
            <h5 tabindex="0"
              *ngIf="!isWebinar && courseDay && courseDay.location && courseDay.location.building && courseDay.location.building.title">
              {{ courseDay.location.building.title }}</h5>
          </div>
        </div>
      </div>
      <div *ngIf="selectedEditionForConfirmSubscription.showMaps" fxFill fxLayout="column" tabindex="0">
        <div class="margin-bottom20" fxLayout="column" fxLayoutAlign="start start">
          <h5 tabindex="0" (click)="selectedEditionForConfirmSubscription.showMaps = false">
            <a class="link-action black" style="word-break: break-all">{{'< ' + (' generic.BACK' | translate)}}</a>
          </h5>
        </div>
        <div *ngIf="!!selectedEditionForConfirmSubscription.days[itemId]" class="space20"></div>
        <div class="margin-bottom20" fxLayout="column" fxLayoutAlign="start start">
          <div *ngIf="!!selectedEditionForConfirmSubscription.days[itemId]" fxFill fxLayout="column"
            fxLayoutAlign="start start">
            <!-- Titolo custom -->
            <h3 tabindex="0" *ngIf="selectedEditionForConfirmSubscription.customTitle">
              {{selectedEditionForConfirmSubscription.customTitle}}</h3>
            <h5 tabindex="0" *ngIf="selectedEditionForConfirmSubscription.customSubTitle">
              {{selectedEditionForConfirmSubscription.customSubTitle }}</h5>
            <h4 tabindex="0">{{ selectedEditionForConfirmSubscription.days[itemId].dayDate | amLocale:'it' |
              amDateFormat:
              stopDateCourseSubscriptionFormat }}</h4>
            <h5 tabindex="0"><b>{{ selectedEditionForConfirmSubscription.days[itemId].location.address.id }}</b></h5>
            <h5 tabindex="0" [hidden]="!schedule.startTime || !schedule.endTime"
              *ngFor="let schedule of selectedEditionForConfirmSubscription.days[itemId].timeSchedules">
              <span>{{ schedule.startTime | amLocale:'it' | amDateFormat: timeFormat }} - {{ schedule.endTime |
                amLocale:'it' | amDateFormat: timeFormat }}</span>
            </h5>
            <h5 tabindex="0">{{ selectedEditionForConfirmSubscription.days[itemId].location.streetNumber &&
              selectedEditionForConfirmSubscription.days[itemId].location.streetNumber.title }}</h5>
            <h5 tabindex="0">{{ (
              'awWizard.datesAndPlaces.placeholders.ROOM' |
              translate) + " " + (selectedEditionForConfirmSubscription.days[itemId].location.room &&
              selectedEditionForConfirmSubscription.days[itemId].location.room.title) }}</h5>
            <h5 tabindex="0">{{ selectedEditionForConfirmSubscription.days[itemId].location.building &&
              selectedEditionForConfirmSubscription.days[itemId].location.building.title }}</h5>
          </div>
        </div>
        <agm-map *ngIf="selectedEditionForConfirmSubscription.mapData != null" class="course-subscription-map"
          [latitude]="selectedEditionForConfirmSubscription.mapData.lat"
          [longitude]="selectedEditionForConfirmSubscription.mapData.lng" [disableDefaultUI]="false"
          [zoomControl]="false">
          <agm-marker [latitude]="selectedEditionForConfirmSubscription.mapData.lat"
            [longitude]="selectedEditionForConfirmSubscription.mapData.lng" label="A" [markerDraggable]="true">
          </agm-marker>
        </agm-map>

        <p *ngIf="selectedEditionForConfirmSubscription.mapData == null"
          translate="generic.DATA_FOR_GOOGLE_MAPS_NOT_AVAILABLE"></p>
      </div>

      <!-- Riserva posti -->
      <div fxFill *ngIf="selectedEditionForConfirmSubscription && selectedEditionForConfirmSubscription.maxUsableTakes && !selectedEditionForConfirmSubscription.isOnlineCourse && isSubscribeEnabled(selectedEditionForConfirmSubscription)
    && !selectedEditionForConfirmSubscription.showMaps" fxLayout="column" fxLayoutAlign="center center"
        class="modal-body-container margin-top20">
        <div class="full-width">
          <div fxLayoutAlign="start center" fxLayout="row" class="margin-bottom5">
            <h5>
              {{selectedEditionForConfirmSubscription.enableUsableTakesHint ||
              ('awWizard.datesAndPlaces.ENABLE_MAX_USABLE_TAKES_DEFAULT_HINT' | translate)}}
            </h5>
          </div>
          <div fxLayoutAlign="start center" fxLayout="row">
            <svg-icon (click)="changeTakesValue('decrement', selectedEditionForConfirmSubscription)" class="zoom-icon"
              src="assets/icons/icon-remove.svg">
            </svg-icon>
            <h5 class="margin-right10 margin-left10">{{ selectedEditionForConfirmSubscription.usedTakes }}</h5>
            <svg-icon (click)="changeTakesValue('increment', selectedEditionForConfirmSubscription)" class="zoom-icon"
              src="assets/icons/icon-add.svg">
            </svg-icon>
          </div>
        </div>
      </div>

      <!-- Accetta Privacy -->
      <div fxFill
        *ngIf="selectedEditionForConfirmSubscription && isSubscribeEnabled(selectedEditionForConfirmSubscription) && selectedEditionForConfirmSubscription.hasToAcceptPrivacy && !selectedEditionForConfirmSubscription.showMaps"
        fxLayout="column" fxLayoutAlign="center center" class="modal-body-container margin-top20 margin-bottom20">
        <div class="full-width" fxLayout="column">
          <div fxLayoutAlign="start center" fxLayout="row">
            <lib-alloy-input id="acceptCoursePrivacy" [type]="'checkbox'"
              [bindValue]="selectedEditionForConfirmSubscription.coursePrivacyAccepted"
              (onModelChanged)="coursePrivacyAcceptedChanged(selectedEditionForConfirmSubscription)"
              [attrName]="'acceptCoursePrivacy'" name="acceptCoursePrivacy" [attrAriaLabel]="'' | translate"
              checkboxText="{{ 'takers.courseSubscription.ACCEPT_PRIVACY' | translate }}">
            </lib-alloy-input>
          </div>
          <div (click)="openCoursePrivacy(selectedEditionForConfirmSubscription)" fxLayoutAlign="start center"
            fxLayout="row">
            <span class="italic underline cursor-pointer"
              translate="takers.courseSubscription.OPEN_PRIVACY_PAGE"></span>
          </div>
        </div>
      </div>

    </div>

    <!-- Dettaglio online -->
    <div fxFill *ngIf="selectedEditionForConfirmSubscription && selectedEditionForConfirmSubscription.isOnlineCourse"
      fxLayout="column" fxLayoutAlign="start start" class="modal-body-container">
      <!-- Dettaglio edizione -->
      <div class="full-width">
        <h3 translate="generic.SUMMARY"></h3>
        <h5
          *ngIf="selectedEditionForConfirmSubscription.teachers && selectedEditionForConfirmSubscription.teachers.length">
          <b>{{ ('generic.TEACHERS' | translate) + ': ' }}</b>
          <span *ngFor="let teacher of selectedEditionForConfirmSubscription.teachers" style="margin-left: 5px;">{{
            teacher.title }}</span>
        </h5>
        <h5 *ngIf="selectedEditionForConfirmSubscription.tutors && selectedEditionForConfirmSubscription.tutors.length">
          <b>{{ ('generic.TUTORS' | translate) + ': ' }}</b><span
            *ngFor="let tutor of selectedEditionForConfirmSubscription.tutors" style="margin-left: 5px;">{{ tutor.title
            }}</span>
        </h5>
        <h5 *ngIf="selectedEditionForConfirmSubscription.stopDate">
          <b>{{ ('generic.END_DATE_SUBSCRIPTION' | translate) + ': ' }}</b>{{
          selectedEditionForConfirmSubscription.stopDate }}
        </h5>
        <h5
          *ngIf="selectedEditionForConfirmSubscription.onlineEventLink && selectedEditionForConfirmSubscription.onlineEventLinkTitle">
          <b>{{ selectedEditionForConfirmSubscription.onlineEventLinkTitle + ': ' }}</b> <a class="link-action black"
            style="word-break: break-all" target="_blank"
            href="{{ selectedEditionForConfirmSubscription.onlineEventLink }}">{{
            selectedEditionForConfirmSubscription.onlineEventLink }}</a>
        </h5>
        <h5 *ngIf="selectedEditionForConfirmSubscription.subscriptionCustomLink">
          <b>{{ ('generic.SUBSCRIPTION_LINK' | translate) + ': ' }}</b>
          {{ selectedEditionForConfirmSubscription.subscriptionCustomLink }}
        </h5>
        <h5 *ngIf="selectedEditionForConfirmSubscription.subscriptionCustomMail"><b>Email: </b>
          <a class="link-action black" style="word-break: break-all" target="_blank"
            href="{{ selectedEditionForConfirmSubscription.subscriptionCustomMail }}">{{
            selectedEditionForConfirmSubscription.subscriptionCustomMail }}</a>
        </h5>
      </div>

      <!-- Accetta Privacy -->
      <div fxFill
        *ngIf="selectedEditionForConfirmSubscription && isSubscribeEnabled(selectedEditionForConfirmSubscription) && selectedEditionForConfirmSubscription.hasToAcceptPrivacy && !selectedEditionForConfirmSubscription.showMaps"
        fxLayout="column" fxLayoutAlign="center center" class="modal-body-container margin-top20 margin-bottom20">
        <div class="full-width" fxLayout="column">
          <div fxLayoutAlign="start center" fxLayout="row">
            <lib-alloy-input id="acceptCoursePrivacy" [type]="'checkbox'"
              [bindValue]="selectedEditionForConfirmSubscription.coursePrivacyAccepted"
              (onModelChanged)="coursePrivacyAcceptedChanged(selectedEditionForConfirmSubscription)"
              [attrName]="'acceptCoursePrivacy'" name="acceptCoursePrivacy" [attrAriaLabel]="'' | translate"
              checkboxText="{{ 'takers.courseSubscription.ACCEPT_PRIVACY' | translate }}">
            </lib-alloy-input>
          </div>
          <div (click)="openCoursePrivacy(selectedEditionForConfirmSubscription)" fxLayoutAlign="start center"
            fxLayout="row">
            <span class="italic underline cursor-pointer"
              translate="takers.courseSubscription.OPEN_PRIVACY_PAGE"></span>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- Annulla -->
    <lib-alloy-button
      *ngIf="selectedEditionForConfirmSubscription && selectedEditionForConfirmSubscription.cancelBtnLabel"
      importance="bordless" dimension="btn-regular" [text]="selectedEditionForConfirmSubscription.cancelBtnLabel"
      (onButtonClicked)="dismissConfirmCourseSubscription()">
    </lib-alloy-button>

    <!-- Conferma -->
    <lib-alloy-button
      *ngIf="selectedEditionForConfirmSubscription && (selectedEditionForConfirmSubscription.isOnlineCourse
            || (selectedEditionForConfirmSubscription ||
            (selectedEditionForConfirmSubscription.availableSeats && selectedEditionForConfirmSubscription.availableSeats != 0)))
            && selectedEditionForConfirmSubscription.confirmBtnLabel || (selectedEditionForConfirmSubscription && selectedEditionForConfirmSubscription.seats && selectedEditionForConfirmSubscription.seats[0].obTotalSeats > selectedEditionForConfirmSubscription.seats[0].obUsedSeats)"
      importance="primary" dimension="btn-regular" [text]="selectedEditionForConfirmSubscription.confirmBtnLabel"
      [disabled]="
      (!selectedEditionForConfirmSubscription.isOnlineCourse && !isMaxUsableTakesValid(selectedEditionForConfirmSubscription))
      || (selectedEditionForConfirmSubscription.hasToAcceptPrivacy && !selectedEditionForConfirmSubscription.coursePrivacyAccepted)
      || (this.isPreregisterEnabled(selectedEditionForConfirmSubscription) && selectedEditionForConfirmSubscription.availableSeats <= 0)"
      (onButtonClicked)="confirmCourseSubscriptionModal()">
    </lib-alloy-button>

    <!-- Secondo tasto di Conferma (opzionale) -->
    <lib-alloy-button
      *ngIf="selectedEditionForConfirmSubscription && (selectedEditionForConfirmSubscription.isOnlineCourse || (selectedEditionForConfirmSubscription && selectedEditionForConfirmSubscription.availableSeats && selectedEditionForConfirmSubscription.availableSeats != 0)) && selectedEditionForConfirmSubscription.confirmSecondBtnLabel"
      importance="bordless" dimension="btn-regular" [text]="selectedEditionForConfirmSubscription.confirmSecondBtnLabel"
      [disabled]="!selectedEditionForConfirmSubscription.isOnlineCourse && !isMaxUsableTakesValid(selectedEditionForConfirmSubscription)"
      (onButtonClicked)="secondConfirmationCourseSubscriptionModal()">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>

<!--  Modale di cancellazione iscrizione -->
<lib-alloy-modal class="not-destructive-modal" id="cancelCourseSubscription" role="dialog" aria-modal="true"
  tabindex="0" aria-labelledby="cancel-subscription-title">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h2 class="custom-modal-title" [translate]="getCancelSubscriptionTitle()" id="cancel-subscription-title"></h2>
    <svg-icon class="cursor-pointer" (click)="dismissCancelSubscriptionModal()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <div *ngIf="selectedEditionForCancelSubscription" class="content-modal" fxLayout="row" fxLayoutAlign="start start">
    <div class="md-dialog-content full-width">
      <!-- Se sono iscritto -->
      <h3
        *ngIf="selectedEditionForCancelSubscription.isInvited && !selectedEditionForCancelSubscription.isConfirmed; else defaultCancelText"
        translate="takers.courseSubscription.modal.cancelSubscription.DO_YOU_WANT_TO_REFUSE_SUBSCRIPTION"></h3>
      <!-- Se sono confermato -->
      <ng-template #defaultCancelText>
        <h3 [translate]="getCancelSubscriptionText()"></h3>
      </ng-template>
      <!--h3 *ngIf="selectedEditionForCancelSubscription.isConfirmed"
        translate="takers.courseSubscription.modal.cancelSubscription.DO_YOU_WANT_TO_CANCEL_SUBSCRIPTION"></h3-->
      <lib-alloy-textarea [bindValue]="selectedEditionForCancelSubscription.cancelMessage"
        title="{{ 'takers.courseSubscription.modal.cancelSubscription.REFUSE_EXPLANATION' | translate }}"
        (onModelChanged)="onCancelMessageChanged($event)"
        [placeholder]="'takers.courseSubscription.modal.cancelSubscription.CANCELLATION_MESSAGE' | translate"
        [attrAriaLabel]="'takers.courseSubscription.modal.cancelSubscription.CANCELLATION_MESSAGE' | translate"
        inputContainerClass="regular-dimension">
      </lib-alloy-textarea>
    </div>
  </div>
  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- Annulla -->
    <lib-alloy-button importance="bordless" dimension="btn-regular" [text]="'generic.CANCEL' | translate"
      (onButtonClicked)="dismissCancelSubscriptionModal()">
    </lib-alloy-button>
    <!-- Conferma -->
    <lib-alloy-button importance="primary" dimension="btn-regular" [text]="'generic.CONFIRM' | translate"
      (onButtonClicked)="confirmCancelSubscriptionModal()">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>

<!--  Modale di conferma richiesta catalogo -->
<lib-alloy-modal class="not-destructive-modal" id="confirmCatalogRequestModal" role="dialog" aria-modal="true"
  tabindex="0" aria-labelledby="catalog-request-title">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h2 class="custom-modal-title" translate="generic.PARTECIPATION" id="catalog-request-title"></h2>
    <svg-icon class="cursor-pointer" (click)="closeConfirmCatalogRequestModal()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <div class="content-modal" fxLayout="row" fxLayoutAlign="start start">
    <div class="md-dialog-content full-width">
      <p translate="generic.REQUEST_CATALOG_CONFIRM"></p>
    </div>
  </div>
  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- Annulla -->
    <lib-alloy-button importance="bordless" dimension="btn-regular" [text]="'generic.CANCEL' | translate"
      (onButtonClicked)="closeConfirmCatalogRequestModal()">
    </lib-alloy-button>
    <!-- Conferma -->
    <lib-alloy-button importance="primary" dimension="btn-regular" [text]="'generic.CONFIRM' | translate"
      (onButtonClicked)="closeConfirmCatalogRequestModal(true)">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>

<!--  Modale di visualizzazione della biografia -->
<lib-alloy-modal class="not-destructive-modal" id="biographyModal" role="dialog" aria-modal="true" tabindex="0"
  aria-labelledby="bio-title">
  <div class="bottom-border" fxLayout="row" fxLayoutAlign="space-between start">
    <h2 translate="suppliers.BIO" style="margin-bottom: 10px;" id="bio-title"></h2>
  </div>
  <div class="content-modal" fxLayout="column" fxLayoutAlign="start start">
    <div class="biography-subtitle to-uppercase" fxLayout="row">
      <h4 *ngIf="selectedPerson && selectedPerson.isTeacher" translate="generic.TEACHER"></h4>
      <h4 *ngIf="selectedPerson && !selectedPerson.isTeacher" translate="generic.TUTO"></h4>
    </div>
    <div class="biography-content" fxLayout="row" fxLayoutAlign="start start">
      <div class="full-width" *ngIf="selectedPerson">
        {{ selectedPerson.forename }} {{ selectedPerson.surname }}
      </div>
    </div>
    <div class="biography-subtitle to-uppercase" fxLayout="row">
      <h4 translate="suppliers.BIO"></h4>
    </div>
    <div class="biography-content" fxLayout="row" fxLayoutAlign="start start">
      <div class="full-width" style="overflow-y: auto;" *ngIf="selectedPerson" [innerHTML]="selectedPerson.description">
      </div>
    </div>
  </div>
  <div class="actions full-width" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="end end"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- Conferma -->
    <lib-alloy-button importance="primary" dimension="btn-regular" [text]="'generic.CLOSE' | translate"
      (onButtonClicked)="closeBiographyModal()">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>

<!-- Modale che mostra gli oggetti (iniziative e oggetti library) blended che contengono l'oggetto -->
<lib-alloy-modal class="informative-modal" id="blendedParentLibraryItems" role="dialog" aria-modal="true" tabindex="0"
  aria-labelledby="blended-parent-title">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h1 class="custom-modal-title" translate="generic.ITEM_CONTAINED_IN_" id="blended-parent-title"></h1>
    <svg-icon class="cursor-pointer" (click)="closeBlendedParentLibraryItems()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <div class="content-modal" fxLayout="column" fxLayoutAlign="start start">
    <!-- Lista di iniziative blended -->
    <div *ngIf="itemDetails && itemDetails.parentBlendedStages && itemDetails.parentBlendedStages.length"
      class="blended-parent-title" fxLayout="row">
      <h4 translate="generic.INITIATIVES"></h4>
    </div>
    <div *ngIf="itemDetails && itemDetails.parentBlendedStages && itemDetails.parentBlendedStages.length"
      class="blended-parent-container" fxLayout="row" fxLayoutAlign="start start">
      <ul class="full-width">
        <li *ngFor="let parentBlendedStage of itemDetails.parentBlendedStages">{{ parentBlendedStage.title || '' }}</li>
      </ul>
    </div>

    <!-- Lista di oggetti library blended -->
    <div *ngIf="itemDetails && itemDetails.parentBlendedItems && itemDetails.parentBlendedItems.length"
      class="blended-parent-title" fxLayout="row">
      <h4 translate="generic.LIBRARY_ITEMS"></h4>
    </div>
    <div *ngIf="itemDetails && itemDetails.parentBlendedItems && itemDetails.parentBlendedItems.length"
      class="blended-parent-container" fxLayout="row" fxLayoutAlign="start start">
      <ul class="full-width">
        <li *ngFor="let parentBlendedItem of itemDetails.parentBlendedItems">{{ parentBlendedItem.title || '' }}</li>
      </ul>
    </div>

  </div>

  <div class="actions full-width" fxLayout="row" fxLayoutAlign="end end">
    <!-- Conferma -->
    <lib-alloy-button importance="primary" dimension="btn-regular" [text]="'generic.CLOSE' | translate"
      (onButtonClicked)="closeBlendedParentLibraryItems()">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>

<!-- Modale utente non compatibile col target dell'iniziativa -->
<lib-alloy-modal class="informative-modal" id="userNotMatchTarget" role="dialog" aria-modal="true" tabindex="0"
  aria-labelledby="cant-subscribe-title">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h2 class="custom-modal-title" translate="generic.modals.CANT_SUBSCRIBE" id="cant-subscribe-title"></h2>
    <svg-icon class="cursor-pointer" (click)="closeUserNotMatchTarget()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <h3 translate="generic.modals.USER_NOT_MATCH_TARGET"></h3><br>
  <p translate="generic.modals.USER_NOT_MATCH_TARGET_TEXT"></p>

  <div class="actions full-width" fxLayout="row" fxLayoutAlign="end end">
    <!-- Annulla -->
    <lib-alloy-button importance="primary" dimension="btn-regular" [text]="'generic.CLOSE' | translate"
      (onButtonClicked)="closeUserNotMatchTarget()">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>

<!-- Modale utente non disponibile per un'edizione -->
<lib-alloy-modal class="informative-modal" id="notAvailableUserModal" role="dialog" aria-modal="true" tabindex="0"
  aria-labelledby="unavailable-modal-title">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h1 class="custom-modal-title" translate="generic.modals.USER_AVAILABILITY" id="unavailable-modal-title"></h1>
    <svg-icon class="cursor-pointer" (click)="closeNotAvailableUserModal()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>

  <p translate="generic.modals.NOT_AVAILABLE_USER_TEXT"></p>

  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- Annulla -->
    <lib-alloy-button importance="bordless" dimension="btn-regular" [text]="'generic.CANCEL' | translate"
      (onButtonClicked)="closeNotAvailableUserModal()">
    </lib-alloy-button>
    <!-- Iscriviti -->
    <lib-alloy-button importance="primary" dimension="btn-regular"
      [text]="'awWizard.datesAndPlaces.SUBSCRIBE' | translate" (onButtonClicked)="subscribeNotAvailableUser()">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>

<!-- Modale che chiede la conferma per la preiscrizione -->
<lib-alloy-modal class="informative-modal" id="confirmPreRegistrationModal" tabindex="0"
  aria-labelledby="preregistration-title" aria-modal="true" role="dialog">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h1 tabindex="0" id="preregistration-title" class="custom-modal-title" translate="generic.modals.PREREGISTRATION">
    </h1>
    <svg-icon class="cursor-pointer" (click)="closeConfirmPreRegistrationModal()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>

  <p tabindex="0" translate="generic.modals.CONFIRM_CONTINUE_PREREGISTRATION"></p>

  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- Annulla -->
    <lib-alloy-button importance="bordless" dimension="btn-regular" [text]="'generic.CANCEL' | translate"
      (onButtonClicked)="closeConfirmPreRegistrationModal()">
    </lib-alloy-button>
    <!-- Iscriviti -->
    <lib-alloy-button importance="primary" dimension="btn-regular" [text]="'generic.CONFIRM' | translate"
      (onButtonClicked)="closeConfirmPreRegistrationModal(true)">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>

<!-- Conferma presenza al corso -->
<lib-alloy-modal class="not-destructive-modal" id="confirmCoursePresenceModal">
  <div *ngIf="CanViewPresenceAbsence" fxLayout="row" fxLayoutAlign="space-between start">
    <h2 class="custom-modal-title" translate="itemDetail.CONFIRM_PRESENCE_OR_ABSENCE_TITLE"></h2>
    <svg-icon class="cursor-pointer" (click)="dismissConfirmPresenceModal()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div> <!-- Se non può settare assenza o presenza, ritornano solo i dettagli dell'edizione -->
  <div *ngIf="!CanViewPresenceAbsence" fxLayout="row" fxLayoutAlign="space-between start">
    <h2 class="custom-modal-title" translate="itemDetail.DETAILS"></h2>
    <svg-icon class="cursor-pointer" (click)="dismissConfirmPresenceModal()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <div class="content-modal" fxLayout="row" fxLayoutAlign="start start">
    <div *ngIf="selectedEditionForConfirmPresence && !selectedEditionForConfirmPresence.isOnlineCourse"
      class="md-dialog-content full-width">

      <!-- Informazione testuale all'utente -->
      <div *ngIf="CanViewPresenceAbsence" fxLayout="column" class="full-width margin-bottom20">
        <p translate="itemDetail.CONFIRM_PRESENCE_OR_ABSENCE_BODY"></p>
      </div>

      <!-- Dettaglio edizione -->
      <div *ngIf="!selectedEditionForConfirmPresence.showMaps && selectedEditionForConfirmPresence" class="full-width">
        <h3 translate="generic.SUMMARY"></h3>
        <!-- Titolo custom -->
        <h3 *ngIf="selectedEditionForConfirmPresence.customTitle">
          {{selectedEditionForConfirmPresence.customTitle}}</h3>
        <h5 *ngIf="selectedEditionForConfirmPresence.customSubTitle">
          {{selectedEditionForConfirmPresence.customSubTitle}}</h5>
        <h5 *ngIf="selectedEditionForConfirmPresence.availableSeats">
          <b>{{ ('generic.NUMBER_AVAILABLE_SEATS' | translate) + ': ' }}</b>
          {{ selectedEditionForConfirmPresence.availableSeats }}
        </h5>
        <h5
          *ngIf="!selectedEditionForConfirmPresence.availableSeats || selectedEditionForConfirmPresence.availableSeats == 0">
          <b class="text-primary-1" translate="generic.NO_AVAILABLE_SEAT"></b>
        </h5>
        <h5 *ngIf="selectedEditionForConfirmPresence.durationDaysLabel">
          <b>{{ ('awWizard.generalInfo.syllabusTabs.titles.DURATION' | translate) + ': ' }}</b>
          {{ selectedEditionForConfirmPresence.durationDaysLabel }}
        </h5>
        <h5 *ngIf="selectedEditionForConfirmPresence.teachers && selectedEditionForConfirmPresence.teachers.length">
          <b>{{ ('generic.TEACHERS' | translate) + ': ' }}</b>
          <span *ngFor="let teacher of selectedEditionForConfirmPresence.teachers" style="margin-left: 5px">{{
            teacher.title }}</span>
        </h5>
        <h5 *ngIf="selectedEditionForConfirmPresence.tutors && selectedEditionForConfirmPresence.tutors.length">
          <b>{{ ('generic.TUTORS' | translate) + ': ' }}</b><span
            *ngFor="let tutor of selectedEditionForConfirmPresence.tutors" style="margin-left: 5px">{{ tutor.title
            }}</span>
        </h5>
        <h5 *ngIf="selectedEditionForConfirmPresence.stopDate">
          <b>{{ ('generic.END_DATE_SUBSCRIPTION' | translate) + ': ' }}</b>{{
          selectedEditionForConfirmPresence.stopDate }}
        </h5>
        <h5 *ngIf="selectedEditionForConfirmPresence.subscriptionCustomLink">
          <b>{{ ('generic.SUBSCRIPTION_LINK' | translate) + ': ' }}</b>
          {{ selectedEditionForConfirmPresence.subscriptionCustomLink }}
        </h5>
        <h5 *ngIf="selectedEditionForConfirmPresence.subscriptionCustomMail"><b>Email: </b>
          <a class="link-action black" style="word-break: break-all" target="_blank"
            href="{{ selectedEditionForConfirmPresence.subscriptionCustomMail }}">{{
            selectedEditionForConfirmPresence.subscriptionCustomMail }}</a>
        </h5>
        <div *ngFor="let courseDay of selectedEditionForConfirmPresence.days; let i = index" class="dayDetail"
          fxLayout="column" fxLayoutAlign="start start">
          <div *ngIf="!isWebinar" fxFill fxLayout="row" fxLayoutAlign="space-between center">
            <h4
              *ngIf="selectedEditionForConfirmPresence.days && selectedEditionForConfirmPresence.days.length && selectedEditionForConfirmPresence.days.length > 1">
              {{ ('awWizard.datesAndPlaces.DAY_DATE' | translate) }} {{ i + 1 }}</h4> <a
              *ngIf="isMapsEnabled(selectedEditionForConfirmPresence, i)"
              (click)="openMaps(selectedEditionForConfirmPresence, i)">{{ 'generic.SHOW_MAP' | translate}}</a>
          </div>
          <hr>
          <div class="dayDetailWrapper" fxFill fxLayout="column" fxLayoutAlign="start start">
            <h5><b>{{ courseDay.dayDate | amLocale:'it' | amDateFormat: stopDateCourseSubscriptionFormat }}</b></h5>
            <h4 *ngIf="!isWebinar && courseDay.location && courseDay.location.address && courseDay.location.address.id">
              {{ courseDay.location.address.id }}</h4>
            <h5 [hidden]="!schedule.startTime || !schedule.endTime" *ngFor="let schedule of courseDay.timeSchedules">
              <span>{{ schedule.startTime | amLocale:'it' | amDateFormat: timeFormat }} - {{ schedule.endTime |
                amLocale:'it' | amDateFormat: timeFormat }}</span>
            </h5>
            <h5
              *ngIf="!isWebinar && courseDay.location && courseDay.location.streetNumber && courseDay.location.streetNumber.title">
              {{ courseDay.location.streetNumber.title }}</h5>
            <h5 *ngIf="!isWebinar">{{ (
              'awWizard.datesAndPlaces.placeholders.ROOM' |
              translate) + " " + (courseDay.location.room && courseDay.location.room.title || '') }}</h5>
            <h5
              *ngIf="!isWebinar && courseDay && courseDay.location && courseDay.location.building && courseDay.location.building.title">
              {{ courseDay.location.building.title }}</h5>
          </div>
        </div>
      </div>
      <div *ngIf="selectedEditionForConfirmPresence.showMaps" fxFill fxLayout="column">
        <div class="margin-bottom20" fxLayout="column" fxLayoutAlign="start start">
          <h5 (click)="selectedEditionForConfirmPresence.showMaps = false">
            <a class="link-action black" style="word-break: break-all">{{'< ' + (' generic.BACK' | translate)}}</a>
          </h5>
        </div>
        <div *ngIf="!!selectedEditionForConfirmPresence.days[itemId]" class="space20"></div>
        <div class="margin-bottom20" fxLayout="column" fxLayoutAlign="start start">
          <div *ngIf="!!selectedEditionForConfirmPresence.days[itemId]" fxFill fxLayout="column"
            fxLayoutAlign="start start">
            <!-- Titolo custom -->
            <h3 *ngIf="selectedEditionForConfirmPresence.customTitle">
              {{selectedEditionForConfirmPresence.customTitle}}</h3>
            <h5 *ngIf="selectedEditionForConfirmPresence.customSubTitle">
              {{selectedEditionForConfirmPresence.customSubTitle }}</h5>
            <h4>{{ selectedEditionForConfirmPresence.days[itemId].dayDate | amLocale:'it' | amDateFormat:
              stopDateCourseSubscriptionFormat }}</h4>
            <h5><b>{{ selectedEditionForConfirmPresence.days[itemId].location.address.id }}</b></h5>
            <h5 [hidden]="!schedule.startTime || !schedule.endTime"
              *ngFor="let schedule of selectedEditionForConfirmPresence.days[itemId].timeSchedules">
              <span>{{ schedule.startTime | amLocale:'it' | amDateFormat: timeFormat }} - {{ schedule.endTime |
                amLocale:'it' | amDateFormat: timeFormat }}</span>
            </h5>
            <h5>{{ selectedEditionForConfirmPresence.days[itemId].location.streetNumber &&
              selectedEditionForConfirmPresence.days[itemId].location.streetNumber.title }}</h5>
            <h5>{{ (
              'awWizard.datesAndPlaces.placeholders.ROOM' |
              translate) + " " + (selectedEditionForConfirmPresence.days[itemId].location.room &&
              selectedEditionForConfirmPresence.days[itemId].location.room.title) }}</h5>
            <h5>{{ selectedEditionForConfirmPresence.days[itemId].location.building &&
              selectedEditionForConfirmPresence.days[itemId].location.building.title }}</h5>
          </div>
        </div>
        <agm-map *ngIf="selectedEditionForConfirmPresence.mapData != null" class="course-subscription-map"
          [latitude]="selectedEditionForConfirmPresence.mapData.lat"
          [longitude]="selectedEditionForConfirmPresence.mapData.lng" [disableDefaultUI]="false" [zoomControl]="false">
          <agm-marker [latitude]="selectedEditionForConfirmPresence.mapData.lat"
            [longitude]="selectedEditionForConfirmPresence.mapData.lng" label="A" [markerDraggable]="true">
          </agm-marker>
        </agm-map>

        <p *ngIf="selectedEditionForConfirmPresence.mapData == null"
          translate="generic.DATA_FOR_GOOGLE_MAPS_NOT_AVAILABLE"></p>
      </div>
    </div>

    <!-- Dettaglio online -->
    <div fxFill *ngIf="selectedEditionForConfirmPresence && selectedEditionForConfirmPresence.isOnlineCourse"
      fxLayout="column" fxLayoutAlign="start start" class="modal-body-container">

      <!-- Informazione testuale all'utente -->
      <div fxLayout="column" class="full-width margin-bottom20">
        <p translate="itemDetail.CONFIRM_PRESENCE_OR_ABSENCE_BODY"></p>
      </div>

      <!-- Dettaglio edizione -->
      <div class="full-width">
        <h3 translate="generic.SUMMARY"></h3>
        <h5 *ngIf="selectedEditionForConfirmPresence.teachers && selectedEditionForConfirmPresence.teachers.length">
          <b>{{ ('generic.TEACHERS' | translate) + ': ' }}</b>
          <span *ngFor="let teacher of selectedEditionForConfirmPresence.teachers" style="margin-left: 5px;">{{
            teacher.title }}</span>
        </h5>
        <h5 *ngIf="selectedEditionForConfirmPresence.tutors && selectedEditionForConfirmPresence.tutors.length">
          <b>{{ ('generic.TUTORS' | translate) + ': ' }}</b><span
            *ngFor="let tutor of selectedEditionForConfirmPresence.tutors" style="margin-left: 5px;">{{ tutor.title
            }}</span>
        </h5>
        <h5 *ngIf="selectedEditionForConfirmPresence.stopDate">
          <b>{{ ('generic.END_DATE_SUBSCRIPTION' | translate) + ': ' }}</b>{{
          selectedEditionForConfirmPresence.stopDate }}
        </h5>
        <h5
          *ngIf="selectedEditionForConfirmPresence.onlineEventLink && selectedEditionForConfirmPresence.onlineEventLinkTitle">
          <b>{{ selectedEditionForConfirmPresence.onlineEventLinkTitle + ': ' }}</b> <a class="link-action black"
            style="word-break: break-all" target="_blank"
            href="{{ selectedEditionForConfirmPresence.onlineEventLink }}">{{
            selectedEditionForConfirmPresence.onlineEventLink }}</a>
        </h5>
        <h5 *ngIf="selectedEditionForConfirmPresence.subscriptionCustomLink">
          <b>{{ ('generic.SUBSCRIPTION_LINK' | translate) + ': ' }}</b>
          {{ selectedEditionForConfirmPresence.subscriptionCustomLink }}
        </h5>
        <h5 *ngIf="selectedEditionForConfirmPresence.subscriptionCustomMail"><b>Email: </b>
          <a class="link-action black" style="word-break: break-all" target="_blank"
            href="{{ selectedEditionForConfirmPresence.subscriptionCustomMail }}">{{
            selectedEditionForConfirmPresence.subscriptionCustomMail }}</a>
        </h5>
      </div>
    </div>
  </div>

  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- Annulla -->
    <lib-alloy-button *ngIf="selectedEditionForConfirmPresence && selectedEditionForConfirmPresence.cancelBtnLabel"
      importance="bordless" dimension="btn-regular" [text]="selectedEditionForConfirmPresence.cancelBtnLabel"
      (onButtonClicked)="dismissConfirmPresenceModal()">
    </lib-alloy-button>

    <!-- Presente -->
    <lib-alloy-button *ngIf="CanViewPresenceAbsence" importance="primary" dimension="btn-regular"
      [text]="'calendar.userStatusesForUser.COURSE.USER_STATUS_PRESENT' | translate"
      (onButtonClicked)="confirmConfirmPresenceModal()">
    </lib-alloy-button>

    <!-- Assente -->
    <lib-alloy-button *ngIf="CanViewPresenceAbsence" importance="bordless" dimension="btn-regular"
      [text]="'calendar.userStatusesForUser.COURSE.USER_STATUS_ABSENT' | translate"
      (onButtonClicked)="secondConfirmConfirmPresenceModal()">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>

<!--  Modale di conferma nuovo stato in "Presente" -->
<lib-alloy-modal class="not-destructive-modal" id="presenceConfirmation" role="dialog" tabindex="0" aria-modal="true"
  aria-labelledby="confirm-title">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h2 class="custom-modal-title" translate="itemDetail.CONFIRM_PRESENCE_OR_ABSENCE_TITLE" id="confirm-title"></h2>
    <svg-icon class="cursor-pointer" (click)="closePresenceConfirmationModal()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <div class="content-modal" fxLayout="row" fxLayoutAlign="start start">
    <div class="md-dialog-content full-width">
      <p translate="itemDetail.CONFIRM_COURSE_PRESENCE"></p>

      <!-- Riserva posti -->
      <div fxFill
        *ngIf="selectedEditionForConfirmPresence && selectedEditionForConfirmPresence.maxUsableTakes && !selectedEditionForConfirmPresence.isOnlineCourse"
        fxLayout="column" fxLayoutAlign="center center" class="modal-body-container margin-top20">
        <div class="full-width">
          <div fxLayoutAlign="start center" fxLayout="row" class="margin-bottom5">
            <h5>
              {{selectedEditionForConfirmPresence.enableUsableTakesHint ||
              ('awWizard.datesAndPlaces.ENABLE_MAX_USABLE_TAKES_DEFAULT_HINT' | translate)}}
            </h5>
          </div>
          <div fxLayoutAlign="start center" fxLayout="row">
            <svg-icon (click)="changeTakesValue('decrement', selectedEditionForConfirmPresence)" class="zoom-icon"
              src="assets/icons/icon-remove.svg">
            </svg-icon>
            <h5 class="margin-right10 margin-left10">{{ selectedEditionForConfirmPresence.usedTakes }}</h5>
            <svg-icon (click)="changeTakesValue('increment', selectedEditionForConfirmPresence)" class="zoom-icon"
              src="assets/icons/icon-add.svg">
            </svg-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- Annulla -->
    <lib-alloy-button importance="bordless" dimension="btn-regular" [text]="'generic.CANCEL' | translate"
      (onButtonClicked)="closePresenceConfirmationModal()">
    </lib-alloy-button>
    <!-- Conferma -->
    <lib-alloy-button
      [disabled]="selectedEditionForConfirmPresence && !selectedEditionForConfirmPresence.isOnlineCourse && !isMaxUsableTakesValidInEditionForConfirmPresence(selectedEditionForConfirmPresence)"
      importance="primary" dimension="btn-regular" [text]="'generic.CONFIRM' | translate"
      (onButtonClicked)="closePresenceConfirmationModal(true)">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>

<!--  Modale di conferma nuovo stato in "Assente" -->
<lib-alloy-modal class="not-destructive-modal" id="absenceConfirmation" role="dialog" aria-modal="true" tabindex="0"
  aria-labelledby="confirm-abscence-title">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h2 class="custom-modal-title" translate="itemDetail.CONFIRM_PRESENCE_OR_ABSENCE_TITLE" id="confirm-abscence-title">
    </h2>
    <svg-icon class="cursor-pointer" (click)="closeAbsenceConfirmationModal()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <div class="content-modal" fxLayout="row" fxLayoutAlign="start start">
    <div class="md-dialog-content full-width">
      <p translate="itemDetail.CONFIRM_COURSE_ABSENCE"></p>
    </div>
  </div>
  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- Annulla -->
    <lib-alloy-button importance="bordless" dimension="btn-regular" [text]="'generic.CANCEL' | translate"
      (onButtonClicked)="closeAbsenceConfirmationModal()">
    </lib-alloy-button>
    <!-- Conferma -->
    <lib-alloy-button importance="primary" dimension="btn-regular" [text]="'generic.CONFIRM' | translate"
      (onButtonClicked)="closeAbsenceConfirmationModal(true)">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>

<!-- Modale conflitto docenze -->
<lib-alloy-modal class="informative-modal" id="teacherNAModal" role="dialog" aria-modal="true" tabindex="0"
  aria-labelledby="conflict-title">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h2 class="custom-modal-title" translate="itemDetail.TEACHER_AVAILABILITY_CONFLICT" id="conflict-title"></h2>
    <svg-icon class="cursor-pointer" (click)="closeTeacherAvailabilityConflictModal()"
      src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <div class="content-modal" fxLayout="row" fxLayoutAlign="start start">
    <div class="md-dialog-content full-width">
      <p translate="itemDetail.TEACHER_AVAILABILITY_CONFLICT_DESC"></p>
      <div class="space20"></div>
      <ul class="full-width" id="teacherNotAvailable" style="list-style-type:none;"></ul>
    </div>
  </div>
  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- Conferma -->
    <lib-alloy-button importance="primary" dimension="btn-regular" [text]="'generic.OK' | translate"
      (onButtonClicked)="closeTeacherAvailabilityConflictModal()">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>

<!--  Modale conflitto disponibilità -->
<lib-alloy-modal class="informative-modal" id="availabilityConflictModal" role="dialog" aria-modal="true" tabindex="0"
  aria-labelledby="conflict-title">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h2 class="custom-modal-title" translate="itemDetail.AVAILABILITY_CONFLICT_MODAL_TITLE" id="conflict-title"></h2>
    <svg-icon class="cursor-pointer" (click)="closeAvailabilityConflictModal()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <div class="content-modal" fxLayout="row" fxLayoutAlign="start start">
    <div class="md-dialog-content full-width">
      <p translate="itemDetail.AVAILABILITY_CONFLICT_MODAL_DESC"></p>
      <div class="space20"></div>

      <div *ngIf="editionsInConflict">
        <div *ngFor="let edition of editionsInConflict">
          <div *ngFor="let ed of edition.conflictedEditions">
            <h4>{{ed.title}}</h4>

            <div *ngFor="let date of ed.days">
              {{'itemDetail.FROM' | translate}} {{date.dayStart | date: 'dd/MM/yyyy HH:mm' }} {{'itemDetail.TO' |
              translate}} {{date.dayEnd | date: 'dd/MM/yyyy HH:mm'}}
            </div>
            <div class="space20"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- Conferma -->
    <lib-alloy-button importance="primary" dimension="btn-regular" [text]="'generic.OK' | translate"
      (onButtonClicked)="closeAvailabilityConflictModal()">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>

<!--  Modale VPL -->
<lib-alloy-modal class="informative-modal" id="vplModal" role="dialog" aria-modal="true" tabindex="0"
  aria-labelledby="vpl-modal-title">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h2 class="custom-modal-title" translate="itemDetail.VPL_ERROR" id="vpl-modal-title"></h2>
    <svg-icon class="cursor-pointer" (click)="closeVplModal()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <div class="content-modal" fxLayout="row" fxLayoutAlign="start start">
    <div class="md-dialog-content full-width">
      <p translate="itemDetail.USER_NOT_AVAILABLE_VPL"></p>
      <!-- <div *ngIf="errorMessageVPL">
        {{errorMessageVPL}}
      </div> -->
      <div *ngIf="errorCodesVPL">
        <p *ngFor="let code of errorCodesVPL">
          {{ code.message }}<br>
          {{ code.status }}
        </p>
      </div>
    </div>
  </div>
  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- Conferma -->
    <lib-alloy-button importance="primary" dimension="btn-regular" [text]="'generic.OK' | translate"
      (onButtonClicked)="closeVplModal()">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>