/*
*  Lista di azioni della dashboard
*/

import { Action } from '@ngrx/store';
import { SmallWidgetIndicator, BigWidgetIndicator, CreditWidgetIndicator } from 'src/cm2-commonclasses';

// Aggiorna un indicatore
export const UPDATE_INDICATOR = 'UPDATE_INDICATOR';

// Rimuove tutti gli indicatori
export const DELETE_INDICATORS = 'DELETE_INDICATORS';

export class UpdateIndicator implements Action {
  readonly type = UPDATE_INDICATOR;

  constructor(public payload: { key: string, indicator: SmallWidgetIndicator | BigWidgetIndicator | CreditWidgetIndicator }) { }
}

export class DeleteIndicators implements Action {
  readonly type = DELETE_INDICATORS;

  constructor() { }
}

export type DashboardActions =
  UpdateIndicator |
  DeleteIndicators;
