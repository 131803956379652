/*
* Componente che crea un placeholder per la lista di tappe all'interno di un percorso
*/

import { Component } from '@angular/core';
@Component({
  selector: 'stages-placeholder',
  templateUrl: './stages-placeholder.component.html',
  styleUrls: ['./stages-placeholder.component.scss']
})

export class StagesPlaceholderComponent {
  constructor() {
  }
}