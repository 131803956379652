import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

// Import dello State dell'applicativo
import * as fromApp from '../../ngrx/app.reducers';
// Import delle azioni del core
import { RequiredAuth } from 'src/cm2-commonclasses';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class GroupsAdminGuard implements CanActivate {

  constructor(private store: Store<fromApp.AppState>,
    private authService: AuthService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return combineLatest(
      this.store.pipe(select(fromApp.getLoggedUser)),
      this.store.pipe(select(fromApp.isFetchingLangs)),
      this.store.pipe(select(fromApp.getApplicationLang))
    )
      .pipe(
        map(([loggedUser, isFetchingLangs, applicationLang]) => {
          if (environment.canAccessManageGroups && loggedUser && loggedUser.auths && loggedUser.auths.length
            && this.authService.isUserAuthorized(RequiredAuth.CORPORATEACADEMY_MANAGE_GROUPS, loggedUser.auths)) {
            return true;
          } else {
            if (isFetchingLangs || !loggedUser || !applicationLang) {
              return false;
            }
            return this.router.parseUrl('/403');
          }
        })
      );
  }
}
