/*
* Pipe per filtrare i tipi di proposta dei fornitori
*/

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'orderByAttributeOrder',
  pure: false
})
export class OrderByAttributeOrder implements PipeTransform {
  transform(array?: Array<any>, args?: string): Array<any> {
    if (!array || array === undefined || array.length === 0) return null;
    array.sort((a: any, b: any) => {
      if ((a.attributeOrder && b.attributeOrder) && a.attributeOrder < b.attributeOrder) {
        return -1;
      } else {
        return 0;
      }
    });
    return array;
  }
}