<div class="learning-plan-wrapper" *ngIf="_isVisible">
    
    <div class="learning__progress">
        <p>{{ "learningTracker.PROGRESS" | translate }} {{ _completitionRate || 0 }}%</p>
        <div class="learning__progress-bar" style="--val: {{ _completitionRate || 0 }}%"></div>
    </div>

    <div class="learning__info">
        <div class="learning__info-detail" >
            <p>{{ "learningTracker.LP_DETAIL" | translate }}</p>
            <p>
                <span [innerHTML]="'learningTracker.OBJ_COMPLETED' | translate: { total: _total, completed: _completed }"></span>
                <span class="accent" (click)="onNavBack()">{{ 'learningTracker.DETAIL' | translate }}</span>
            </p>
        </div>
        <div class="learning__info-action">
            <lib-alloy-button
                importance="secondary"
                text="{{ (_isEverithingComplete ? 'generic.GO_BACK' : 'learningTracker.NEXT_MODULE') | translate }}"
                [disabled]="!_canGoNextStep && !_isEverithingComplete"
                (onButtonClicked)="goToNextElement()">
            </lib-alloy-button>
        </div>
    </div>
</div>