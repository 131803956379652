/*
*  Lista di azioni riguardanti le news
*/

import { Action } from '@ngrx/store';
import { Announcement } from 'atfcore-commonclasses';

// Salva la lista delle news recuperati
export const SET_NEWS = 'SET_NEWS';

// Cancella un gruppo
export const DELETE_NEWS = 'DELETE_NEW';

// Recupera le news da remoto
export const GET_NEWS = 'GET_NEWS';

// Recupera le news da remoto
export const GET_USER_NEWS = 'GET_NEWS';

// Setta il contatore dele news recuperati da remoto
export const SET_NEWS_COUNT = 'SET_NEWS_COUNT';

// Setta la paginazione
export const SET_PAGINATION_NEWS = 'SET_PAGINATION_NEWS';

// Setta la pagina corrente
export const SET_PAGE_NEWS = 'SET_PAGE_NEWS';

// Setta il testo (searchedText)
export const SET_NEWS_TEXT_FILTER = 'SET_NEWS_TEXT_FILTER';

// Setta la direzione di ordinamento delle news utente
export const SET_SORTING_DIR = 'SET_ORDER_DIR';

//Setta la colonna di ordinamento delle news utente
export const SET_SORTING_COL = 'SET_SORTING_COL';

// Setta una variabile con il numero di news totali per il banner
export const SET_NEWS_TOTAL = 'SET_NEWS_TOTAL';

// Recupera il numero di news totali per il banner
export const GET_NEWS_TOTAL = 'GET_NEWS_TOTAL';

// Setta il numero di news non lette
export const SET_BANNER_UNREAD = 'SET_BANNER_UNREAD';

// Recupera il numero di news non lette
export const GET_BANNER_UNREAD = 'GET_BANNER_UNREAD';

// Inizia il polling per il count delle notifiche delle news
export const START_COUNT_NOTIFICATIONS_NEWS = 'START_COUNT_NOTIFICATIONS_NEWS';

// Recupera numero di news unread al login, per fare la differenza con quelle nuove
export const GET_OLD_UNREAD = 'GET_OLD_UNREAD';

// Setta il numero di news lette dal login
export const SET_OLD_UNREAD = 'SET_OLD_UNREAD';

export class SetNews implements Action {
  readonly type = SET_NEWS;

  constructor(public payload: Announcement[]) { }
}

export class DeleteNews implements Action {
  readonly type = DELETE_NEWS;

  constructor(public payload: string) { }
}

export class GetNews implements Action {
  readonly type = GET_NEWS;

  constructor(public payload: boolean) { }
}

export class SetNewsCount implements Action {
  readonly type = SET_NEWS_COUNT;

  constructor(public payload: number) { }
}

export class SetPageNews implements Action {
  readonly type = SET_PAGE_NEWS;

  constructor(public payload: number) { }
}

export class SetPaginationNews implements Action {
  readonly type = SET_PAGINATION_NEWS;

  constructor(public payload: { fromRecord: number, numRecords: number }) { }
}

export class SetNewsTextFilter implements Action {
  readonly type = SET_NEWS_TEXT_FILTER;

  constructor(public payload: string) { }
}

export class SetSortingCol implements Action {
  readonly type = SET_SORTING_COL;

  constructor(public payload: string) { }
}

export class SetSortingDir implements Action {
  readonly type = SET_SORTING_DIR;

  constructor(public payload: string) { }
}

export class SetNewsTotal implements Action {
  readonly type = SET_NEWS_TOTAL;

  constructor(public payload: number) { }
}

export class SetBannerUnread implements Action {
  readonly type = SET_BANNER_UNREAD;

  constructor(public payload: number) { }
}

export class GetNewsTotal implements Action {
  readonly type = GET_NEWS_TOTAL;

  constructor(public payload: number) { }
}

export class GetBannerUnread implements Action {
  readonly type = GET_BANNER_UNREAD;

  constructor(public payload: number) { }
}

export class GetOldUnread implements Action {
  readonly type = GET_OLD_UNREAD;

  constructor(public payload: string[]) { }
}

export class SetOldUnread implements Action {
  readonly type = SET_OLD_UNREAD;

  constructor(public payload: string[]) { }
}

export class StartCountNotificationsNewsPolling implements Action {
  readonly type = START_COUNT_NOTIFICATIONS_NEWS;
}

export type NewsActions = SetNews |
  DeleteNews |
  SetNewsCount |
  SetPaginationNews |
  SetPageNews |
  SetNewsTextFilter |
  GetNews |
  SetSortingDir |
  SetSortingCol |
  SetNewsTotal |
  GetNewsTotal |
  SetBannerUnread |
  GetBannerUnread |
  StartCountNotificationsNewsPolling |
  SetOldUnread |
  GetOldUnread;