import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'filterSharedFile',
    pure: false
})
export class FilterSharedFilePipe implements PipeTransform {
    transform(array: Array<any>, args?: string): Array<string> {
        if (!array || array === undefined || array.length === 0) return null;

        if (args && args.length) {
            let newArray = array.filter((x: any) => {
                return x.itemId == args;
            })
            return newArray;
        } else {
            return array;
        }
    }
}