import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Store, StoreModule } from '@ngrx/store';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module'
import { CoreModule } from './core/core.module';
import { reducers } from './ngrx/app.reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { AuthEffects } from './auth/ngrx/auth.effects';
import { CoreEffects } from './core/ngrx/core.effects';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { SocialLoginConfig } from './social-login-config';
import { ScormService } from './core/scorm-player/scorm.service';
import { AzurePlayerService } from './core/azure-player/azure-player.service';
import { SessionStorageService } from './libraryHome/home/SessionStorageService';
import { SessionStorageCatalogService } from './catalogHome/home/SessionStorageCatalogService';
import { UsersAdminService } from './usersAdmin/services/usersAdmin.service';
import { TooltipModule } from 'ng2-tooltip-directive';
import { BitmovinPlayerService } from './core/bitmovin-player/bitmovin-player.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ preventDuplicates: true }),
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    AuthModule,
    CoreModule,
    TooltipModule,
    DeviceDetectorModule.forRoot(),
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([AuthEffects, CoreEffects]),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [] // Il plugin per lo Store (che funzione con l'estensione di Chrome) lo aggiungiamo solo se non stiamo facendo il build per la produzione
  ],
  providers: [
    UsersAdminService,
    AzurePlayerService,
    BitmovinPlayerService,
    ScormService,
    SocialLoginConfig,
    SessionStorageService,
    SessionStorageCatalogService,
    GoogleMapsAPIWrapper,
    Store,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
