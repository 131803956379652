import { Component } from '@angular/core';

@Component({
  selector: 'big-widget-placeholder',
  templateUrl: './big-widget-placeholder.component.html',
  styleUrls: ['./big-widget-placeholder.component.scss']
})
export class BigWidgetPlaceholderComponent {

  constructor() {
  }
}
