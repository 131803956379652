<div class="timeline-item no-overflow" [ngClass]="containerClass + (_hasGreyBackground ? ' grey-background' : '')">
  <div class="animated-background facebook">
    <div class="background-masker header-top"></div>
    <div class="background-masker header-left"></div>
    <div class="background-masker header-right"></div>
    <div class="background-masker header-bottom"></div>
    <div class="background-masker subheader-left"></div>
    <div class="background-masker subheader-right"></div>
    <div class="background-masker subheader-bottom"></div>
  </div>
</div>
