import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'active-filters-button',
  templateUrl: './active-filters-button.component.html',
  styleUrls: ['./active-filters-button.component.scss']
})
export class ActiveFiltersButtonComponent implements OnInit {
  @Input() text?: string;
  @Input() ariaLabel?: string;
  @Input() icon?: string;
  @Input() disabled?: boolean;
  @Output() onIconClicked: EventEmitter<string> = new EventEmitter();
  @Input() filterType;
  svgIconUrl: string;

  constructor() {
  }

  ngOnInit() {
  }

  emitOnIconClicked() {
    this.onIconClicked.emit(this.filterType);
  }
}
