/*
* Componente che crea lo step contente le informazioni generali dell'iniziativa
*/
//var cloneDeep = require('lodash.clonedeep');
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { CommonConstants, Item, ItemAttribute, ItemAttributeStatusTypes, ItemAttributeTypes, Lang, ReferenceTypes, SenecaResponse, SupplierTypes, TagTypes, User } from "../../../../../../cm2-commonclasses";
import { LangsService } from '../../../../../core/services/langs.service';
import { BaseSubscriberComponent } from '../../../../../shared/components/base-subscriber.component';
import { ExtendedTag } from '../../../../../shared/models/extended-tag.model';
import { CKEditorConfig } from '../../../../../shared/models/initiatives.model';
import { ItemUtil } from '../../../../../shared/models/item.model';
import { Syllabus, SyllabusUtil } from '../../../../../shared/models/syllabus.model';
import { InitiativeService } from '../../../../services/initiative.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'library-alloy/dist/library-alloy';
import { Observable, Subject } from 'rxjs';
import { SupplierService } from 'src/app/suppliers/services/supplier.service';
import { CategoryService } from 'src/app/manageTags/services/category.service';


class FormGroupWrapper {
  formGroup: FormGroup;
  isRequiredForSupplier: boolean;
}

const MANDATORY: string[] = [
  ItemAttributeTypes.TITLE,
  ItemAttributeTypes.AGENDA,
  ItemAttributeTypes.AIMS,
  ItemAttributeTypes.PREREQS,
  ItemAttributeTypes.SAP_CODICE_TIPO_EVENTO
]

@Component({
  selector: 'card-syllabus',
  templateUrl: './card-syllabus.component.html',
  styleUrls: ['../../../initiative-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardSyllabusComponent extends BaseSubscriberComponent implements OnInit, OnChanges, OnDestroy {

  static textFieldsMapping = [
    'TITLE',
    'SUBTITLE',
    ItemAttributeTypes.ARGUMENTS,
    ItemAttributeTypes.AIMS,
    ItemAttributeTypes.AGENDA,
    ItemAttributeTypes.DURATION,
    ItemAttributeTypes.PREREQS,
    ItemAttributeTypes.APPROACH,
    ItemAttributeTypes.USE_SUPPLIER_PARTNER,
    ItemAttributeTypes.PARTNER,
    ItemAttributeTypes.SUPPLIER_PARTNER,
    ItemAttributeTypes.TUTOR_CONTACT,
    ItemAttributeTypes.TEACHER_CONTACT,
    ItemAttributeTypes.SUPPORT_CONTACT,
    ItemAttributeTypes.CUSTOM_ARGUMENT,
    ItemAttributeTypes.PRICE,
    ItemAttributeTypes.STREAMS,
    ItemAttributeTypes.ARGUMENTS,
    ItemAttributeTypes.PROTOCOL_NUMBER,
    ItemAttributeTypes.PROTOCOL_DATE,
    ItemAttributeTypes.MANDATORY_COURSE,
    ItemAttributeTypes.THEMATIC_AREA_CODE
  ];

  @Input() usableTags: string[];
  @Input() tagVariables;
  @Input() tagListIsChanged: boolean;
  @Input() cardTitle: string;
  @Input() cardClaim: string;
  @Input() reviewMode: boolean;
  @Input() frontEndSyllabus: Syllabus;
  @Input() syllabus: Item;
  @Input() currentTitle: string;
  //@Input() courseValue: string;
  @Input() supplierCourseModules: any[];
  @Input() currentLang: Lang;
  @Input() selectedItemTypeCode: string;
  @Input() creationPath: string;
  @Input() isInitiativeArchived: boolean;
  @Input() canManageEditionsApprovationsWorkflowEnvironment: boolean = false;
  @Input() canManageEditionsApprovationsWorkflow: boolean = false;
  @Input() currentInitiativeStatus: any;
  @Output() frontEndSyllabusChange = new EventEmitter<Syllabus>();
  @Output() syllabusChange = new EventEmitter<any>();
  @Output() confirmSyllabus = new EventEmitter<any>();
  @Output() updateSyllabus = new EventEmitter<any>();
  @Output() toggleSyllabusAdvancedSidebar = new EventEmitter<any>();
  @Output() fetchMoreGenericTags = new EventEmitter<any>();
  @Output() onEditSyllabusBtnClick = new EventEmitter<any>();
  @Output() togglePreviewSyllabusSidebar = new EventEmitter<any>();
  @Output() nextStep = new EventEmitter<any>();
  //@Output() onEditionCourseValueChanged = new EventEmitter<any>();
  @Output() changeTagListIsChanged = new EventEmitter<any>();
  @Output() openConfirmAskInitiativeApprovationModal = new EventEmitter<any>();

  editMode = false;
  currentItemLang: string;

  // item
  @Input() isUpdatingSyllabus: boolean;
  @Input() isExternalCourse: boolean;

  competences = [];
  competenceLevel = [];
  clusters = [];
  selectedCompetence;
  requiredCompetences = [];
  syllabusRequiredCompetences = [];
  addCompetence: boolean = false;
  requiredCompetenceId;
  requiredLevelId;
  requiredCompetence;

  syllabusAdvancedSidebar: any;
  defaultLang: string;
  availableLangs: Lang[];
  langList: Lang[];
  langMap: { [key: string]: Lang };

  // Definizione Reactive Forms divisi in form groups (un form group per tab)
  agendaForm: FormGroupWrapper;
  aimsForm: FormGroupWrapper;
  prereqsForm: FormGroupWrapper;
  partenersForm: FormGroupWrapper;
  trainingForm: FormGroupWrapper;
  protocolForm: FormGroupWrapper;
  mandatoryCourseForm: FormGroupWrapper;
  ckeConfig: any;

  newCertificateArgumentTmp: string = '';
  isUpdatingArguments: boolean = false;

  //#region Tipo formazione

  selectedNewTagToAddWeight;
  activeTabId: number = 0;

  //#region ORGANIZER

  getSupplierListInput$ = new Subject<string>();
  supplierList: User[] = [];
  suppliersLoaded: number = 0;
  suppliersToRetrieve: number = 10;
  isFetchingSuppliers: boolean;
  searchedSupplierText: string;
  resetSupplierList: boolean;


  languageList
  selectedLanguage: string;


  isLoadingSupplier: boolean;
  organizerSelected: any;

  //#endregion ORGANIZER

  //#endregion Tipo formazione

  areRequiredCompetencesFilled: boolean = false;

  constructor(
    private initiativeService: InitiativeService,
    private langsService: LangsService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private modalService: ModalService,
    private supplierService: SupplierService,
    private cdRef: ChangeDetectorRef,
    private categoryService: CategoryService
  ) {
    super();
    this.ckeConfig = CKEditorConfig;
    this.syllabusAdvancedSidebar = {
      isSyllabusAdvancedSibarOpened: false
    };
  }

  ngOnInit() {
    this.ckeConfig.readOnly = this.isInitiativeArchived;
    this.reviewMode = this.readBooleanInput(this.reviewMode);
    this.refreshSyllabus(true);
    this.createSyllabusForms();
    if (this.reviewMode) {
      this.initiativeService.getCurrenWizardStep()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((currenWizardStep) => {
          if (this.syllabus) {
            this.createOriginalAttributes();
          }
        });
    }

    this.updateSyllabus
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.syllabusChange.emit(this.syllabus);
        this.frontEndSyllabusChange.emit(this.frontEndSyllabus);
      });

    this.confirmSyllabus
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.syllabusChange.emit(this.syllabus);
        this.frontEndSyllabusChange.emit(this.frontEndSyllabus);
      });

    this.getAttributesRequiredCompetences();


    this.translate.get([
      'awWizard.generalInfo.syllabusPlaceholders.ITALIAN',
      'awWizard.generalInfo.syllabusPlaceholders.ENGLISH'
    ]).subscribe(translations => {
      this.languageList = [
        { name: translations['awWizard.generalInfo.syllabusPlaceholders.ITALIAN'], code: 'it' },
        { name: translations['awWizard.generalInfo.syllabusPlaceholders.ENGLISH'], code: 'en' }
      ];

      if (ItemUtil.getAttributeByKey(this.syllabus, 'LANGUAGE')) {
        if (ItemUtil.getAttributeByKey(this.syllabus, 'LANGUAGE').attributeValue == 'it') {
          this.selectedLanguage = this.languageList[0].name
        } else {
          this.selectedLanguage = this.languageList[1].name
        }
      }
    });
  }

  isPriceVisible() {
    return environment.canAccessManageTeachersAccounting;
  }

  refreshSyllabus(recoverSupplierIfExists?: boolean) {
    SyllabusUtil.formatSyllabus(this.syllabus, this.frontEndSyllabus, this.currentLang);
    this.formatSyllabusForFrontEnd(this.frontEndSyllabus, this.syllabus, this.currentLang, recoverSupplierIfExists);
    this.refreshSyllabusForms();
    setTimeout(() => this.cdRef.detectChanges(), 200);
  }

  // Emette un evento per aprire l'anteprima del Syllabus
  ontogglePreviewSyllabusSidebar(previewAllSyllabus?: boolean): void {
    if (previewAllSyllabus) {
      //     this.togglePreviewSyllabusSidebar.emit([null, this.suppliersSyllabus, true]);
    } else {
      let selectedSyllabus = {
        frontEndSyllabus: this.frontEndSyllabus
      }
      this.togglePreviewSyllabusSidebar.emit([selectedSyllabus]);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.frontEndSyllabus && changes.frontEndSyllabus.currentValue)
      || (changes.syllabus && changes.syllabus.currentValue)) {
      this.refreshSyllabus();
    }
    if (changes.tagListIsChanged && changes.tagListIsChanged.currentValue == true) {
      this.tagListIsChanged = false;
      this.tagVariables = this.tagVariables;
      this.changeTagListIsChanged.emit();
    }
    setTimeout(() => {
      this.cdRef.detectChanges();
    }, 300);
  }

  // Formatta il Syllabus per il front end
  formatSyllabusForFrontEnd(frontEndSyllabus: Syllabus, syllabus: Item, lang: Lang, recoverSupplierIfExists?: boolean) {
    let syllabusIndex: number = this.langsService.findItemLangIndex(lang.langCode, syllabus);
    let selectedItemLang = syllabus.itemLangs[syllabusIndex];
    frontEndSyllabus.TITLE.data = selectedItemLang;
    frontEndSyllabus.SUBTITLE.data = { ...selectedItemLang };

    // Recupero dal syllabus alcune parti del programma, qualora fossero state precedentemente salvatate, oppure le preparo per i futuri salvataggi (linkandole correttamente all'oggetto che sarà in binding e aggiungendo la lingua agli attributi del Syllabus)
    for (let key in frontEndSyllabus) {
      if (frontEndSyllabus.hasOwnProperty(key)) {
        if (SyllabusUtil.isAttributeWithLang(key) && syllabus.itemAttributes) {
          let langFoundInFrontEndSyllabus = false;
          const langCode = lang.langCode;
          const currentAttributeLang = ItemUtil.getAttributeLangByKey(syllabus, key, langCode);
          if (currentAttributeLang) {
            frontEndSyllabus[key].data = currentAttributeLang;
            langFoundInFrontEndSyllabus = true;
          }
          // Se non ho trovato la lingua, l'aggiungo all'oggetto di front end affinché venga eseguito correttamente il binding nella View
          if (!langFoundInFrontEndSyllabus) {
            let newLangAttribute = ItemUtil.createAttributeWithLang(syllabus.itemId, key, langCode, null);
            // syllabus.itemAttributes.push(newLangAttribute);
            frontEndSyllabus[key].data = newLangAttribute.attributeLangs[0];
          }
        }
      }
    }

    if (recoverSupplierIfExists && !this.organizerSelected && frontEndSyllabus.SUPPLIER_PARTNER && frontEndSyllabus.SUPPLIER_PARTNER.data && frontEndSyllabus.SUPPLIER_PARTNER.data.description && frontEndSyllabus.SUPPLIER_PARTNER.data.description.length) {
      this.getSupplierById(frontEndSyllabus.SUPPLIER_PARTNER.data.description);
    }
    //recupero la lingua selezionata del corso se presente
    /*   if(ItemUtil.getAttributeByKey(this.syllabus,'LANGUAGE'))
     { 
       if(ItemUtil.getAttributeByKey(this.syllabus,'LANGUAGE').attributeValue=='Italiano')
        {
        }
      }  */
  }

  // Recupera il supplier dal suo id
  getSupplierById(supplierId: string) {
    this.isLoadingSupplier = true;

    this.supplierService.getSupplierById(supplierId).subscribe(
      (supplierData: SenecaResponse<any>) => {
        if (supplierData.error) {
          // Vedo se c'è la traduzione dell'errore
          this.toastr.error(this.translate.instant('errors.' + supplierData.error));
        } else {
          this.organizerSelected = supplierData.response;
        }
        this.isLoadingSupplier = false;
      })
  }

  onSyllabusFieldChanged(newVal?, fieldName?: string, dataField?: string) {
    if (fieldName == 'SUPPLIER_PARTNER') {
      this.frontEndSyllabus[fieldName].data[dataField] = newVal.supplierId;
    } else {
      this.frontEndSyllabus[fieldName].data[dataField] = newVal;
    }
  }

  createOriginalAttributes() {
    let originalKey = '';
    let updateSyllabus = false;
    // controllo per ogni campo se ho l'attributo ORIGINAL, altrimenti lo creo
    for (const key in this.frontEndSyllabus) {
      if (this.frontEndSyllabus.hasOwnProperty(key)) {
        originalKey = key + '_ORIGINAL';
        const originalAttrs = ItemUtil.getAttributesByKey(this.syllabus, originalKey);
        if (!originalAttrs || !originalAttrs.length) {
          const isTagField = SyllabusUtil.tagsFieldsMapping.includes(key);
          // se è un tag creo n attributi ORIGINAL
          if (isTagField) {
            // copio gli attributi attuali AS-IS e imposto attrbiuteType con _ORIGINAL finale
            const currentAttrs = ItemUtil.getAttributesByKey(this.syllabus, key);
            currentAttrs.forEach((attr: ItemAttribute) => {
              const newOrginalAttr: any = {
                attributeId: null,
                itemId: this.syllabus.itemId,
                attributeOrder: attr.attributeOrder,
                attributeType: originalKey,
                attributeValue: attr.attributeValue,
                attributeWeight: attr.attributeWeight,
                crossReferenceObject: {
                  tagLangs: [],
                  tagTenants: [],
                  title: null
                },
                attributeLangs: [],
                referenceType: attr.referenceType,
                referenceApplicationName: attr.referenceApplicationName,
                referenceId: attr.referenceId
              };
              this.syllabus.itemAttributes.push(newOrginalAttr);
            });

          } else {
            let originalLangValue = null;
            const langCode = this.currentLang.langCode;

            if (key === 'TITLE') {
              originalLangValue = {
                attributeId: null,
                description: null,
                langCode: langCode,
                subTitle: null,
                title: this.syllabus.title
              };
            } else if (key === 'SUBTITLE') {
              originalLangValue = {
                attributeId: null,
                description: null,
                langCode: langCode,
                subTitle: null,
                title: this.syllabus.subTitle
              };
            } else {
              // recupero il valore corrente in lingua e creo l'original
              originalLangValue = ItemUtil.getAttributeLangByKey(this.syllabus, key, langCode);
            }

            updateSyllabus = true;
            if (originalLangValue && originalLangValue.langCode) {
              originalLangValue = { ...originalLangValue };
              originalLangValue.attributeId = null;
            } else {
              originalLangValue = {
                attributeId: null,
                description: null,
                langCode: langCode,
                subTitle: null,
                title: ''
              };
            }

            const newOrginalAttr: any = {
              attributeId: null,
              itemId: this.syllabus.itemId,
              attributeOrder: 0,
              attributeType: originalKey,
              attributeValue: null,
              attributeWeight: null,
              crossReferenceObject: {
                tagLangs: [],
                tagTenants: [],
                title: null
              },
              attributeLangs: [originalLangValue],
              referenceType: null,
              referenceApplicationName: null,
              referenceId: null
            };
            this.syllabus.itemAttributes.push(newOrginalAttr);
          }
        }
      }
    }
    if (updateSyllabus) {
      this.updateSyllabus.emit(this.syllabus);
    }
  }

  /* emitOnEditionCourseValueChanged(newVal?) {
    this.onEditionCourseValueChanged.emit(newVal);
  } */

  addFormControl(formGroup: FormGroup, controlName: string, attributeKeyPrefix: string, value: any, required?: boolean) {
    const formGroupWrapper = new FormGroupWrapper();
    formGroup.addControl(controlName, new FormControl(value, []));
    formGroupWrapper.formGroup = formGroup;
    return formGroupWrapper;
  }

  createSyllabusForms() {
    this.agendaForm = this.addFormControl(new FormGroup({}), 'agenda', 'AGENDA',
      this.frontEndSyllabus.AGENDA.data.description);

    this.aimsForm = this.addFormControl(new FormGroup({}), 'aims', 'AIMS',
      this.frontEndSyllabus.AIMS.data.description);

    this.prereqsForm = this.addFormControl(new FormGroup({}), 'prereqs', 'PREREQS',
      this.frontEndSyllabus.PREREQS.data.description);

    // Per il partner ci sono più form - addFormControl non è applicabile quindi
    this.partenersForm = new FormGroupWrapper();
    this.partenersForm.formGroup = new FormGroup({
      'useSupplierPartner': new FormControl(this.frontEndSyllabus.USE_SUPPLIER_PARTNER.data.description || false),
      'partner': new FormControl(this.frontEndSyllabus.PARTNER.data.description),
      'supplierPartner': new FormControl(this.frontEndSyllabus.SUPPLIER_PARTNER.data.description)
    });

    this.trainingForm = new FormGroupWrapper();
    this.trainingForm.formGroup = new FormGroup({
      'trainingType': new FormControl(this.frontEndSyllabus.STREAMS.data),
      'trainingCategory': new FormControl(this.frontEndSyllabus.ARGUMENTS.data)
    });

    this.protocolForm = new FormGroupWrapper();
    this.protocolForm.formGroup = new FormGroup({
      'protocolNumber': new FormControl(this.frontEndSyllabus.PROTOCOL_NUMBER.data.description),
      'protocolDate': new FormControl(this.frontEndSyllabus.PROTOCOL_DATE.data.description)
    });

    this.mandatoryCourseForm = new FormGroupWrapper();
    this.mandatoryCourseForm.formGroup = new FormGroup({
      'mandatoryCourse': new FormControl(this.frontEndSyllabus.MANDATORY_COURSE.data.description)
    });

  }

  parseBoolean(val: string | boolean | number): boolean {
    return !!(val && (val === true || val === "true" || val === "1" || val === 1));
  }

  refreshSyllabusForms() {
    if (!!this.agenda) this.agenda.setValue(this.frontEndSyllabus.AGENDA.data.description);
    if (!!this.aims) this.aims.setValue(this.frontEndSyllabus.AIMS.data.description);
    if (!!this.prereqs) this.prereqs.setValue(this.frontEndSyllabus.PREREQS.data.description);
    if (!!this.trainingType) this.trainingType.setValue(this.frontEndSyllabus.STREAMS.data);
    if (!!this.trainingCategory) this.trainingCategory.setValue(this.frontEndSyllabus.ARGUMENTS.data);
    if (!!this.protocolNumber) this.protocolNumber.setValue(this.frontEndSyllabus.PROTOCOL_NUMBER.data.description);
    if (!!this.protocolDate) this.protocolDate.setValue(this.frontEndSyllabus.PROTOCOL_DATE.data.description);
    if (!!this.mandatoryCourse) this.mandatoryCourse.setValue(this.frontEndSyllabus.MANDATORY_COURSE.data.description);
    if (!!this.useSupplierPartner) this.useSupplierPartner.setValue(this.frontEndSyllabus.USE_SUPPLIER_PARTNER.data.description);
    if (!!this.partner) this.partner.setValue(this.frontEndSyllabus.PARTNER.data.description);
    if (!!this.supplierPartner) {
      this.supplierPartner.setValue(this.frontEndSyllabus.SUPPLIER_PARTNER.data.description);
    };
  }

  getFormFlagByFormValidation(formGroupWrap?: FormGroupWrapper): string {
    if (!this.reviewMode) {
      return null;
    }
    return 'OPTIONAL';
  }

  getDefaultFormControlInCase(formWrapper: FormGroupWrapper, formControlName: string) {
    return formWrapper && formWrapper.formGroup && formWrapper.formGroup.get(formControlName);
  }

  // getters di utilità per i formControls
  get agenda() { return this.getDefaultFormControlInCase(this.agendaForm, 'agenda'); }
  get aims() { return this.getDefaultFormControlInCase(this.aimsForm, 'aims'); }
  get prereqs() { return this.getDefaultFormControlInCase(this.prereqsForm, 'prereqs'); }
  get trainingType() { return this.getDefaultFormControlInCase(this.trainingForm, 'trainingType'); }
  get trainingCategory() { return this.getDefaultFormControlInCase(this.trainingForm, 'trainingCategory'); }
  get protocolNumber() { return this.getDefaultFormControlInCase(this.protocolForm, 'protocolNumber'); }
  get protocolDate() { return this.getDefaultFormControlInCase(this.protocolForm, 'protocolDate'); }
  get mandatoryCourse() { return this.getDefaultFormControlInCase(this.mandatoryCourseForm, 'mandatoryCourse'); }
  get useSupplierPartner() { return this.getDefaultFormControlInCase(this.partenersForm, 'useSupplierPartner'); }
  get partner() { return this.getDefaultFormControlInCase(this.partenersForm, 'partner'); }
  get supplierPartner() { return this.getDefaultFormControlInCase(this.partenersForm, 'supplierPartner'); }

  // Se cambiassero dinamicamente le options potrei perdere la referenza degli oggetti,
  //  quindi per evitare questo problema dico ad ancular (attraverso il [compareWith]) come comparare gli elementi
  compareItemTypes(optionOne, optionTwo): boolean {
    return optionOne && optionTwo ? optionOne.id === optionTwo.id : optionOne === optionTwo;
  }

  // Aggiunge un argomento al certificato
  addNewCertificateArgument(): void {
    if (this.syllabus && this.newCertificateArgumentTmp && this.newCertificateArgumentTmp.length) {
      this.isUpdatingArguments = true;
      // Prima verifico di non aver già aggiunto questo argomento
      let alreadyAdded;
      if (this.frontEndSyllabus.CUSTOM_ARGUMENT.data && this.frontEndSyllabus.CUSTOM_ARGUMENT.data.length) {
        for (let m = 0, argumentsLength = this.frontEndSyllabus.CUSTOM_ARGUMENT.data.length; m < argumentsLength; m++) {
          const addedArgument = this.frontEndSyllabus.CUSTOM_ARGUMENT.data[m];
          if (addedArgument && addedArgument.description && addedArgument.description === this.newCertificateArgumentTmp) {
            alreadyAdded = true;
            break;
          }
        }
      }

      if (alreadyAdded) {
        this.isUpdatingArguments = false;
        return;
      }

      if (!this.syllabus.itemAttributes) {
        this.syllabus.itemAttributes = [];
      }

      if (!this.frontEndSyllabus[ItemAttributeTypes.CUSTOM_ARGUMENT].data) {
        this.frontEndSyllabus[ItemAttributeTypes.CUSTOM_ARGUMENT].data = [];
      }

      // Aggiungo il nuovo attributo sia all'item che all'oggetto del syllabus (usato nel bind html)
      const langCode = this.currentLang.langCode;

      // Per questo attributo, trovo l'attirbuteOrder maggiore (quindi l'ultimo argomento che è stato aggiunto)
      let customAttributeOrder = 0;
      if (this.syllabus && this.syllabus.itemAttributes && this.syllabus.itemAttributes.length) {
        for (let n = 0, attrsLength = this.syllabus.itemAttributes.length; n < attrsLength; n++) {
          const addedAttr = this.syllabus.itemAttributes[n];
          if (addedAttr && addedAttr.attributeType && addedAttr.attributeType === ItemAttributeTypes.CUSTOM_ARGUMENT
            && addedAttr.attributeOrder && addedAttr.attributeOrder > customAttributeOrder) {
            customAttributeOrder = addedAttr.attributeOrder;
          }
        }
      };

      customAttributeOrder = customAttributeOrder + 1;

      const newArgument = ItemUtil.createAttributeWithLang(this.syllabus.itemId, ItemAttributeTypes.CUSTOM_ARGUMENT, langCode, this.newCertificateArgumentTmp, null, customAttributeOrder);
      this.syllabus.itemAttributes.push(newArgument);

      let attrLang;
      for (let q = 0, langsLength = newArgument.attributeLangs.length; q < langsLength; q++) {
        if (newArgument.attributeLangs[q].langCode === langCode) {
          attrLang = newArgument.attributeLangs[q];
          break;
        }
      }
      this.frontEndSyllabus[ItemAttributeTypes.CUSTOM_ARGUMENT].data.push(attrLang);
      this.newCertificateArgumentTmp = null;
      this.isUpdatingArguments = false;
    }
  }

  // Rimuove un argomento dal certificato
  removeCertiticateArgument(selectedArgument): void {
    if (selectedArgument && this.syllabus && this.syllabus.itemAttributes && this.syllabus.itemAttributes.length) {
      this.isUpdatingArguments = true;
      // Rimuovo l'attributo, sia dal syllabus sia dall'oggetto del syllabus
      if (selectedArgument.attributeId) {
        ItemUtil.removeItemAttributeById(this.syllabus, selectedArgument);
      } else {
        if (this.syllabus && this.syllabus.itemAttributes && this.syllabus.itemAttributes.length) {
          let attrRemove;
          for (let n = 0, attrsLength = this.syllabus.itemAttributes.length; n < attrsLength; n++) {
            const addedAttr = this.syllabus.itemAttributes[n];
            if (!attrRemove && addedAttr && addedAttr.attributeType && addedAttr.attributeType === ItemAttributeTypes.CUSTOM_ARGUMENT
              && addedAttr.attributeLangs && addedAttr.attributeLangs.length) {
              for (let k = 0, langsLength = addedAttr.attributeLangs.length; k < langsLength; k++) {
                const currentAttributeLang = addedAttr.attributeLangs[k];
                if (currentAttributeLang.langCode === this.currentLang.langCode && currentAttributeLang.description === selectedArgument.description) {
                  this.syllabus.itemAttributes.splice(n, 1);
                  attrRemove = true;
                  break;
                }
              }
            }

            if (attrRemove) {
              break;
            }
          }
        };
      }

      for (let i = 0, tmpArgumentsLength = this.frontEndSyllabus[ItemAttributeTypes.CUSTOM_ARGUMENT].data.length; i < tmpArgumentsLength; i++) {
        let currentTmpArgument = this.frontEndSyllabus[ItemAttributeTypes.CUSTOM_ARGUMENT].data[i];
        if (currentTmpArgument.description === selectedArgument.description) {
          this.frontEndSyllabus[ItemAttributeTypes.CUSTOM_ARGUMENT].data.splice(i, 1);
          break;
        }
      }
      this.isUpdatingArguments = false;
    }
  }

  // Aggiorna il valore del nuovo argomento
  onNewCertificateArgumentTmpChanged(newVal?) {
    this.newCertificateArgumentTmp = newVal;
  }

  // Aggiunge un tag generico all'Item
  addGeneralTagToItem(item, selectedTag, type?: string) {
    if (type == ItemAttributeTypes.LEVELS || type == ItemAttributeTypes.ARGUMENTS || type == ItemAttributeTypes.SAP_CODICE_TIPO_EVENTO
      || type == ItemAttributeTypes.FUNCTIONAL_AREAS || type == ItemAttributeTypes.CLUSTERS || type == ItemAttributeTypes.STREAMS) {
      if (item && item.itemAttributes && item.itemAttributes.length) {
        for (let i = 0; i < item.itemAttributes.length; i++) {
          let attribute = item.itemAttributes[i];
          if (attribute.attributeType == type) {
            item.itemAttributes.splice(i, 1);
          }
        }
      }
      this.frontEndSyllabus[type].data = null;
    }
    if (selectedTag) {
      if (item && item.itemAttributes && item.itemAttributes.length) {
        for (let i = 0; i < item.itemAttributes.length; i++) {
          let itemLangType = item.itemAttributes[i];
          if (itemLangType.referenceId == selectedTag.tagId) {
            this.removeTagFromItem(item, selectedTag);
            break;
          }
        }
      }
      // Aggiungo il nuovo tag all'Item
      this.addTagToItem(item, selectedTag);
    }
    this.cdRef.detectChanges();
  }

  // Aggiunge un Tag al Syllabus
  addTagToItem(item: Item, selectedTag?: ExtendedTag, removeTagFromItem?: boolean): void {
    if (item) {
      if (!item.itemAttributes) {
        item.itemAttributes = [];
      }

      if (removeTagFromItem) {
        this.removeTagFromItem(item, null, true, selectedTag.tagType);
      } else {
        this.frontEndSyllabus[selectedTag.tagType].data = selectedTag;
      }

      const lastAttr = ItemUtil.getLastAttributeByOrderAndKey(item, selectedTag.tagType);
      const newAttributeOrder = lastAttr ? (lastAttr.attributeOrder + 1) : 0;

      // Preparo il nuovo attributo
      const newTagObj: ItemAttribute = <ItemAttribute>{
        attributeId: null,
        itemId: item.itemId,
        attributeOrder: newAttributeOrder,
        attributeType: selectedTag.tagType,
        attributeValue: selectedTag.title,
        attributeWeight: parseInt(selectedTag.attributeWeight, 10),
        crossReferenceObject: {
          tagLangs: selectedTag.tagLangs,
          tagTenants: selectedTag.tagTenants,
          title: selectedTag.title
        },
        referenceType: TagTypes.TAG,
        referenceApplicationName: CommonConstants.APPLICATION_CORPORATE_ACADEMY,
        referenceId: selectedTag.tagId
      };

      item.itemAttributes.push(newTagObj);
    }
  }

  // Rimuove un tag dal Syllabus
  removeTagFromItem(item: Item, selectedTag: any, removeCluster?: boolean, tagType?: string): void {
    if (item && item.itemAttributes && item.itemAttributes.length) {
      ItemUtil.removeTagFromItem(item, selectedTag, removeCluster, tagType);

      this.refreshSyllabus();
    }
  }

  addLanguageToItem(item: Item, tag: string, value?: any) {
    this.selectedLanguage = value.name;

    if (item) {
      if (!item.itemAttributes) {
        item.itemAttributes = [];
      }
    }

    ItemUtil.removeItemAttributeByKey(item, tag);
    const lastAttr = ItemUtil.getLastAttributeByOrderAndKey(item, tag);
    const newAttributeOrder = lastAttr ? (lastAttr.attributeOrder + 1) : 0;

    // Preparo il nuovo attributo
    const newTagObj: ItemAttribute = <ItemAttribute>{
      attributeId: null,
      itemId: item.itemId,
      attributeOrder: newAttributeOrder,
      attributeType: tag,
      attributeValue: value.code,
      attributeWeight: null,
      crossReferenceObject: {
        tagLangs: null,
        tagTenants: null,
        title: null
      },
      referenceType: null,
      referenceApplicationName: null,
      referenceId: null
    };

    item.itemAttributes.push(newTagObj);

  }

  getUsableTags() {
    return environment.usableTags.filter(tag => tag != TagTypes.FUNCTIONAL_AREAS);
    // this.usableTags.filter(tag => tag != TagTypes.FUNCTIONAL_AREAS && tag != TagTypes.STREAMS && tag != TagTypes.COURSE_CATEGORY)
  }

  emitToggleSyllabusAdvancedSidebar() {
    this.toggleSyllabusAdvancedSidebar.emit(this.syllabus);
  }

  // Gestisce l'azione al click sul pulsante per modificare il Syllabus (qualora questo sia la referenza ad un syllabus già creato per un'altra iniziativa)
  editSyllabusBtnClick() {
    this.onEditSyllabusBtnClick.emit();
  }

  emitConfirmSyllabus(): any {
    this.confirmSyllabus.emit(this.syllabus);
  }

  emitUpdateSyllabus(): any {
    this.updateSyllabus.emit(this.syllabus);
  }

  getAttributeOriginalValue(key: string) {
    // recupero tutti gli original
    const originalAttrs = ItemUtil.getAttributesByKey(this.syllabus, (key + '_ORIGINAL'));

    const originalValues = originalAttrs && originalAttrs.map((originalAttr: ItemAttribute) => {
      // se è un TAG mappo
      if (originalAttr.referenceType === ReferenceTypes.TAG) {
        return originalAttr && originalAttr.crossReferenceObject.title;

      } else if (originalAttr && originalAttr.attributeLangs
        && originalAttr.attributeLangs.length) {
        for (let k = 0, langsLength = originalAttr.attributeLangs.length; k < langsLength; k++) {
          const currentAttributeLang = originalAttr.attributeLangs[k];
          if (currentAttributeLang.langCode === this.currentLang.langCode) {
            return currentAttributeLang.title;
          }
        }
      }

      // se non è nessuno dei casi gestiti mappo direttamente l'attributeValue
      return null;
    });

    return this.getProposedValueByFieldType(key, originalValues);
  }

  getProposedValueByFieldType(key: string, value?: any) {
    const isTextField = CardSyllabusComponent.textFieldsMapping.includes(key);
    const isTagField = !isTextField && SyllabusUtil.tagsFieldsMapping.includes(key);
    let proposedValue;

    if (value) {
      if (isTagField) {
        proposedValue = Array.isArray(value) ? value : [value];
      } else {
        proposedValue = Array.isArray(value) ? value[0] : value;
      }
    } else {
      if (key === 'TITLE') {
        proposedValue = this.frontEndSyllabus[key].data.title;
      } else if (key === 'SUBTITLE') {
        proposedValue = this.frontEndSyllabus[key].data.subTitle;
      } else if (isTagField && this.frontEndSyllabus[key].data) {
        proposedValue = Array.isArray(this.frontEndSyllabus[key].data) ?
          this.frontEndSyllabus[key].data.map((elem) => elem.title) :
          [this.frontEndSyllabus[key].data.title];
      } else if (isTextField) {
        proposedValue = this.frontEndSyllabus[key].data.description;
      }
    }
    return proposedValue;
  }

  isConfirmSyllabusDisabled() {
    for (let i = 0; i < MANDATORY.length; i++) {
      if (MANDATORY[i] == 'TITLE' || MANDATORY[i] == 'SAP_CODICE_TIPO_EVENTO') {
        if (!this.isSyllabusFilled(MANDATORY[i], 'title')) {
          return true;
        }
      } else {
        if (!this.isSyllabusFilled(MANDATORY[i], 'description')) {
          return true;
        }
      }
    }
    return false;
    // if (this.isExternalCourse &&
    //   (!this.isSyllabusFilled('TITLE', 'title')
    //     || !this.isSyllabusFilled('AIMS', 'description')
    //     || !this.isSyllabusFilled('AGENDA', 'description'))) {
    //   return true;
    // } else if (!this.isExternalCourse &&
    //   (!this.isSyllabusFilled('TITLE', 'title')
    //     || !this.isSyllabusFilled('AIMS', 'description')
    //     || !this.isSyllabusFilled('AGENDA', 'description')
    //     || (this.frontEndSyllabus['DURATION']?.active && !this.isSyllabusFilled('DURATION', 'description')))) {
    //   return true;
    // }
    // // else 
    // if (!this.frontEndSyllabus.PREREQS.data.description || !this.frontEndSyllabus.CLUSTERS.data) {
    //   return true;
    // }
    // return false;
  }

  isSyllabusFilled(fieldName: string, dataField: string) {
    if (fieldName == ItemAttributeTypes.SAP_CODICE_TIPO_EVENTO) {
      dataField = 'title';
    }
    if (this.frontEndSyllabus
      && this.frontEndSyllabus[fieldName]
      && this.frontEndSyllabus[fieldName].data) {

      if (this.frontEndSyllabus[fieldName].data.length) {
        return this.frontEndSyllabus[fieldName].data[0]?.[dataField];
      } else {
        return this.frontEndSyllabus[fieldName].data[dataField]
      }
    }
  }

  isRequired(fieldName: string) {
    if (fieldName == ItemAttributeTypes.SAP_CODICE_TIPO_EVENTO) {
      return true;
    }
    return false;
  }

  toggleChooseMandatoryCourse = () => {
    if (this.mandatoryCourse.value) {
      this.mandatoryCourse.setValue(false);
    } else {
      this.mandatoryCourse.setValue(true);
    }
    this.onSyllabusFieldChanged(this.mandatoryCourse.value, 'MANDATORY_COURSE', 'description');
  }
  checkWeightSelectionTags(tag: string) {
    if (tag == ItemAttributeTypes.TECH_COMPETENCES || tag == ItemAttributeTypes.SOFT_COMPETENCES) {
      return true;
    } else {
      return false;
    }
  }

  // Conferma l'operazione dell'aggiunta del peso al Tag
  confirmTagWeightAction(weight: string) {
    this.selectedNewTagToAddWeight.attributeWeight = weight;
    // Aggiungo il nuovo tag all'Item
    this.addTagToItem(this.syllabus, this.selectedNewTagToAddWeight);
    this.cdRef.detectChanges();
  }

  confirmOnSkillAdded(weight: string) {
    this.modalService.close('addWeightToTagModal');
    this.confirmTagWeightAction(weight);
  }

  onSkillAdded(selectedTag) {
    this.selectedNewTagToAddWeight = selectedTag;
    this.modalService.open('addWeightToTagModal');
  }

  onActivateTab(tabId: number) {
    this.activeTabId = tabId;
    // Tab Organizzatore per caricare correttamente syllabus-basic-editor
    if (this.activeTabId === 8) {
      setTimeout(() => {
        this.cdRef.detectChanges();
        this.getSupplierList();
      }, 0);
    }
  }

  checkAddItemGenericTag(event, tag) {
    if (tag == ItemAttributeTypes.TECH_COMPETENCES || tag == ItemAttributeTypes.SOFT_COMPETENCES) {
      this.onSkillAdded(event);
    } else {
      this.addGeneralTagToItem(event, tag);
    }
  }

  fetchMoreGenericTagsFunction(event, tag) {
    this.fetchMoreGenericTags.emit({ event: event, tag: tag });
  }

  // toggleChoosePartner = () => {
  //   if (this.useSupplierPartner.value) {
  //     this.useSupplierPartner.setValue(false);
  //   } else {
  //     this.useSupplierPartner.setValue(true);
  //   }
  //   this.onSyllabusFieldChanged(this.useSupplierPartner.value, 'USE_SUPPLIER_PARTNER', 'description');
  // }

  // Infinite scroll per i Supplier. Questa chiamata viene chiamata quando raggiungo il fondo della lista
  fetchMoreSuppliers() {
    this.getSupplierListInput$.next();
  }

  // Resetta la lista di Supplier
  clearSupplierList(): void {
    this.suppliersLoaded = 0;
    this.supplierList = [];
  }

  // Recupera una lista di Supplier (persone nello stesso tenant dell'utente loggato)
  getSupplierList() {
    this.getSupplierListInput$.pipe(
      switchMap(
        (text) => {
          if (!text && !this.searchedSupplierText) {
            // Se non c'è il filtro di ricerca testuale non avvio nessuna ricerva per evitare il 'missing required data'
            // Torno un observable simulando una senecaResponse
            let newSenecaresponse = { response: 0 };
            this.resetSupplierList = true;
            return new Observable(obs => {
              obs.next(newSenecaresponse);
              obs.complete();
            });
          } else {
            // Se richiesto, resetto i dati
            if (text && text !== this.searchedSupplierText) {
              this.resetSupplierList = true;
            } else {
              this.resetSupplierList = false;
            }

            // Salvo il valore della ricerca testuale
            this.searchedSupplierText = text ? text : this.searchedSupplierText;
            this.isFetchingSuppliers = true;
            return this.initiativeService.countSuppliers(this.searchedSupplierText, [SupplierTypes.COURSE_SUPPLIER]);
          }
        }
      ),
      switchMap(
        (pmCounter: SenecaResponse<number>) => {
          // Se richiesto, cancello di risultati precedenti in quanto sto eseguendo una nuova ricerca
          if (this.resetSupplierList) {
            this.clearSupplierList();
          }
          // Continuo solo se ho altri valori da recuperare
          if (this.suppliersLoaded < pmCounter.response) {
            return this.initiativeService.getSuppliers(this.suppliersLoaded, this.suppliersToRetrieve, this.searchedSupplierText, [SupplierTypes.COURSE_SUPPLIER]);
          } else {
            // Torno un observable simulando una senecaResponse
            let newSenecaresponse = { response: [] };
            return new Observable(obs => {
              obs.next(newSenecaresponse);
              obs.complete();
            });
          }
        }
      )
      , catchError((err, caught) => {
        // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
        return caught;
      })
    )
      .subscribe(
        (pmData: SenecaResponse<User[]>) => {
          this.isFetchingSuppliers = false;
          if (pmData.error) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + pmData.error));
          } else {
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            this.suppliersLoaded += pmData.response.length;
            this.supplierList = this.supplierList.concat(pmData.response);
            this.cdRef.detectChanges();
          }
        }
        , (err) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
          this.clearSupplierList();
          this.isFetchingSuppliers = false;
        }
      );
  }

  closeAddCompetences() {
    this.selectedCompetence = null;
    this.competenceLevel = [];
    this.addCompetence = false;
  }

  getAttributesRequiredCompetences() {
    this.areRequiredCompetencesFilled = false;
    this.syllabusRequiredCompetences = [];
    this.syllabus.itemAttributes.forEach(element => {
      if (element.attributeType == 'COMPETENCE_LEVEL_REQUIRED') {
        this.syllabusRequiredCompetences.push(element);
        this.areRequiredCompetencesFilled = true;
      }
    });

    this.getAllCompetences(true);
  }

  async getAllCompetences(getRequiredCompetences: boolean) {
    try {
      let res = await this.initiativeService.getAllCompetences(true).toPromise();
      if (res.error) {
        this.toastr.error(this.translate.instant('errors.' + res.error));
      } else {
        this.competences = res.response.rows;
        this.competences.forEach(element => {
          element.name = element.competenceName;
        });
        if (getRequiredCompetences == true) {
          this.getRequiredCompetences();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getLevels(competenceId, idlevel?, competenceName?) {
    try {

      let res = await this.initiativeService.getLevels(competenceId).toPromise();
      if (res.error) {
        this.toastr.error(this.translate.instant('errors.' + res.error));
      } else {
        this.competenceLevel = res.response.rows;
        this.competenceLevel.forEach(element => {
          element.name = element.levelName;
        });

        if (idlevel && competenceName) {
          this.competenceLevel.forEach(level => {
            if (level.levelId == idlevel) {
              this.requiredCompetences.push(
                {
                  competenceName: competenceName,
                  competenceId: competenceId,
                  levelName: level.levelName,
                  levelId: idlevel
                }
              );
              this.areRequiredCompetencesFilled = true;
            }
          });
        }
      }

    } catch (error) {
      console.log(error);
    }
  }

  async getClusters() {
    try {
      const res = await this.categoryService.searchCategories(null, TagTypes.CLUSTERS, null, null, true).toPromise();
      if (res.error) {
        this.toastr.error(this.translate.instant('errors.' + res.error));
      } else {
        this.clusters = res.response.map(c => ({
          ...c,
          name: c.title
        }));
        
      }

    } catch (error) {
      console.log(error);
    }
  }

  onNewRequiredCompetenceSelected(competence) {
    this.selectedCompetence = competence;
    this.getLevels(competence.competenceTagId);
  }

  onNewRequiredCompetenceLevelSelected(level) {
    this.selectedCompetence.scaleLevelId = level.levelId;
    this.selectedCompetence.scaleLevelName = level.levelName;
    this.selectedCompetence.scaleLevelWeight = level.levelWeight;
  }

  canAddRequiredCompetence() {
    let canAdd = true;
    this.requiredCompetences.forEach(element => {
      if (element.competenceId == this.selectedCompetence.competenceTagId && element.levelId == this.selectedCompetence.scaleLevelId) {
        canAdd = false;
      }
    });

    if (canAdd == true) {
      this.addRequiredCompetence();
    } else {
      this.closeAddCompetences();
      this.modalService.open('cantAddRequiredCompetence');
    }
  }

  closeCantAddRequiredCompetence() {
    this.modalService.close('cantAddRequiredCompetence');
  }

  getRequiredCompetences() {

    this.syllabusRequiredCompetences.forEach(element => {
      this.requiredCompetenceId = element.referenceId;
      this.requiredLevelId = element.attributeValue;

      this.competences.forEach(el => {
        if (el.competenceTagId == this.requiredCompetenceId) {
          this.requiredCompetence = el;
          let competenceName = el.competenceName;
          this.getLevels(this.requiredCompetenceId, this.requiredLevelId, competenceName);
        }
      });
    });

    this.competenceLevel = [];
    this.requiredLevelId = '';
    this.requiredCompetenceId = '';
    this.requiredCompetence = '';

  }

  addRequiredCompetence() {
    const attribute: any = {
      itemId: this.syllabus.itemId,
      attributeId: null,
      attributeType: 'COMPETENCE_LEVEL_REQUIRED',
      attributeValue: this.selectedCompetence.scaleLevelId,
      attributeWeight: this.selectedCompetence.scaleLevelWeight,
      referenceId: this.selectedCompetence.competenceTagId,
      referenceType: 'TAG'
    };

    this.syllabus.itemAttributes.push(attribute);
    this.syllabusRequiredCompetences.push(attribute);
    this.requiredCompetences.push(
      {
        competenceName: this.selectedCompetence.competenceName,
        competenceId: this.selectedCompetence.competenceTagId,
        levelName: this.selectedCompetence.scaleLevelName,
        levelId: this.selectedCompetence.scaleLevelId
      }
    );
    if (this.requiredCompetences && this.requiredCompetences.length) {
      this.areRequiredCompetencesFilled = true;
    }
    this.closeAddCompetences();
  }

  deleteRequiredCompetence(competence) {
    this.requiredCompetences.forEach(element => {
      if (element.competenceId == competence.competenceId && element.levelId == competence.levelId) {
        this.requiredCompetences.splice(this.requiredCompetences.indexOf(element), 1);
      }
    });

    if (!this.requiredCompetence || !this.requiredCompetence.length) {
      this.areRequiredCompetencesFilled = false;
    }

    this.syllabusRequiredCompetences.forEach(element => {
      if (element.referenceId == competence.competenceId && element.attributeValue == competence.levelId) {
        this.syllabusRequiredCompetences.splice(this.syllabusRequiredCompetences.indexOf(element), 1);
      }
    });


    this.syllabus.itemAttributes.forEach(element => {
      if (element.referenceId == competence.competenceId && element.attributeValue == competence.levelId) {
        this.syllabus.itemAttributes.splice(this.syllabus.itemAttributes.indexOf(element), 1);
      }
    });
  }

  // Verifica se mostrare il pulsante di "Conferma e richiedi approvazione" nel primo step.
  // Si vde solo se il workflow è previsto, l'utente non ha l'auth per approvare,
  // è in creazione oppure lo stage è creato ed è o in bozza o negato
  showConfirmAndAskApprovationBtn() {
    return this.canManageEditionsApprovationsWorkflowEnvironment && !this.canManageEditionsApprovationsWorkflow
      && (!this.editMode ||
        (this.currentInitiativeStatus && this.currentInitiativeStatus.attributeValue &&
          (this.currentInitiativeStatus.attributeValue === ItemAttributeStatusTypes.NOT_APPROVED || this.currentInitiativeStatus.attributeValue === ItemAttributeStatusTypes.DRAFT)));
  }

  // Controllo se l'iniziativa è in attesa di approvazione
  isWatingApproval() {
    return this.currentInitiativeStatus && this.currentInitiativeStatus.attributeValue && this.currentInitiativeStatus.attributeValue === ItemAttributeStatusTypes.APPROVAL_PENDING;
  }

  // Controllo se l'iniziativa è già stata approvata
  alreadyApproved() {
    return this.currentInitiativeStatus && this.currentInitiativeStatus.attributeValue &&
      (this.currentInitiativeStatus.attributeValue === ItemAttributeStatusTypes.CONFIRMED || this.currentInitiativeStatus.attributeValue === ItemAttributeStatusTypes.IN_PROGRESS);
  }

  emitOpenConfirmAskInitiativeApprovationModal(event: any) {
    this.openConfirmAskInitiativeApprovationModal.emit(event);
  }

  ngOnDestroy(): void {
    if (this.getSupplierListInput$) {
      this.getSupplierListInput$.unsubscribe();
    }
  }

}
