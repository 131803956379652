/*
*  Componente che mostra le informazioni sull'utente loggato
*/

import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { JwtPayload, SenecaResponse, Lang } from 'src/cm2-commonclasses';
import * as ProfileActions from '../ngrx/profile.actions';
import * as fromApp from '../../ngrx/app.reducers';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { HttpResponse } from '@angular/common/http';
import { AuthService } from 'src/app/auth/services/auth.service';
import * as CoreActions from '../../core/ngrx/core.actions';
import * as jwtDecode from 'jwt-decode';
import * as momentTimezone from 'moment-timezone';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { UserService } from 'src/app/shared/services/user.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-profileDetails',
  templateUrl: './profileDetails.component.html',
  styleUrls: ['./profileDetails.component.scss']
})
export class ProfileDetailsComponent implements OnInit {
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  imageChangedEvent: any = '';
  showCropper = false;
  result$: Subscription;
  loggedUser: JwtPayload;
  avatar: string;
  uploadAvatarVisible: boolean;
  avatarImg: {
    file: any,
    uploader: FileUploader,
    title: string
  } = {
      file: null,
      uploader: new FileUploader({ url: null }),
      title: ''
    };;
  croppedImage;
  avatarImgErrorText: string;
  createAttachment$: Subscription;
  availableLangs: Lang[];
  applicationLang: string;
  loaderActive: boolean;
  selectedLang: Lang;
  timezoneList;
  userTimezone;
  ivassCredit: number;
  mifidCredit: number;
  efaaCredit: number;
  efabCredit: number;
  efpCredit: number;
  ivassCreditLIMIT: number;
  mifidCreditLIMIT: number;
  efaaCreditLIMIT: number;
  efabCreditLIMIT: number;
  efpCreditLIMIT: number;
  currentBiennium;
  currentBienniumRequiredCredits;
  currentYear: number;
  oldPassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  passwordPattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@!%*?&£\._:\-\+])[A-Za-z\d$@!%*?&£\._:\-\+]{8,}/g;
  isExternal;
  isInternalBDI: boolean;

  constructor(private store: Store<fromApp.AppState>,
    private toastr: ToastrService,
    public authService: AuthService,
    private userService: UserService,
    public redirectService: RedirectService,
    private titleService:Title,
    private translate: TranslateService) {
       // titolo necessario per jaws
    titleService.setTitle("Profilo");
    this.ivassCredit = 0;
    this.mifidCredit = 0;
    this.efaaCredit = 0;
    this.efabCredit = 0;
    this.efpCredit = 0;
    this.ivassCreditLIMIT = 0;
    this.mifidCreditLIMIT = 0;
    this.efaaCreditLIMIT = 0;
    this.efabCreditLIMIT = 0;
    this.efpCreditLIMIT = 0;
    this.currentYear = new Date().getFullYear();

    // Recupero le informazioni sull'utente loggato dallo Store applicativo
    let loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const availableLangs$: Observable<Lang[]> = this.store.select(fromApp.getAvailableLangs);
    const applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang);
    const isExternal$ = this.store.select(fromApp.getIsExternal);
    // Sto in ascolto dei vari cambiamenti
    const combinedSelectes$ = combineLatest(loggedUser$, availableLangs$, applicationLang$, isExternal$);
    this.result$ = combinedSelectes$.subscribe(
      ([loggedUser, availableLangs, applicationLang, isExternal]) => {
        this.loggedUser = loggedUser;
        this.availableLangs = availableLangs;
        this.applicationLang = applicationLang;
        this.isExternal = isExternal;
        if (this.applicationLang && this.availableLangs && this.availableLangs.length) {
          for (let i = 0, availableLangsLength = this.availableLangs.length; i < availableLangsLength; i++) {
            let currentAvailableLang = this.availableLangs[i];
            if (currentAvailableLang.langCode.toLowerCase() === this.applicationLang.toLowerCase()) {
              this.selectedLang = currentAvailableLang;
              break;
            }
          }
        };
        if (this.loggedUser && !this.userTimezone) {
          this.userTimezone = this.loggedUser && this.loggedUser.user && this.loggedUser.user.userOptions && this.loggedUser.user.userOptions.timezone ? this.loggedUser.user.userOptions.timezone : "Europe/Rome";
        }

        if (this.loggedUser?.user.socDistacco == "Banca d'Italia") {
          this.isInternalBDI = true;
        }
        // Recupero l'avatar dell'utente loggato dal suo payload, con una foto di default qualora mancasse
        this.avatar = (this.loggedUser && this.loggedUser.user && this.loggedUser.user.userOptions && this.loggedUser.user.userOptions.avatarImage)
          || (this.loggedUser && this.loggedUser.user && (!this.loggedUser.user.chiaveSesso || this.loggedUser.user.chiaveSesso == "M") ? 'assets/img/placeholder_male.svg' : 'assets/img/placeholder_female.svg');
      });
    this.uploadAvatarVisible = false;
    this.resetNewAvatarData();
  }

  ngOnInit() {
    this.timezoneList = momentTimezone.tz.names();
    if (!this.authService.isTeacherMode(true)) {
      this.getCreditsCounter();
      this.getCurrentBiennium();
    }
    let appInitiatives = document.getElementsByClassName("ng-sidebar__content") && document.getElementsByClassName("ng-sidebar__content")[0];
    if (appInitiatives) {
      appInitiatives.scrollTop = 0;
    }
  }

  onOldPasswordChanged(newValue?: string) {
    this.oldPassword = newValue;
  }

  onNewPasswordChanged(newValue?: string) {
    this.newPassword = newValue;
  }

  onConfirmPasswordChanged(newValue?: string) {
    this.confirmPassword = newValue;
  }

  arePswMatchingPattern() {
    if (this.newPassword
      && this.newPassword.match(this.passwordPattern)
      && this.confirmPassword
      && this.confirmPassword.match(this.passwordPattern)) {
      return true;
    }

    return false;
  }

  newLangSelected(newLang) {
    this.loaderActive = true;
    this.loggedUser.user.userOptions.langCode = newLang.langCode;
    let updateUserOptionsPromise = this.updateUserOptions();
    updateUserOptionsPromise.then((newFullToken: string) => {
      this.toastr.success(this.translate.instant(this.translate.instant('generic.DATA_SAVED')));
      this.store.dispatch(new ProfileActions.DecodeToken(newFullToken));
      let decodedJwt = jwtDecode(newFullToken)
      this.store.dispatch(new ProfileActions.UpdateUser(decodedJwt));
      this.store.dispatch(new CoreActions.StartRenewTokenPolling({ forceRefreshUser: true }));
      this.loaderActive = false;
    })
      .catch(() => {
        this.loaderActive = false;
      })
  }

  // Imposta una nuova password
  submitNewPassword() {
    this.loaderActive = true;
    let setNewPasswordPromise = this.setNewPassword();
    setNewPasswordPromise.then(() => {
      this.toastr.success(this.translate.instant(this.translate.instant('generic.DATA_SAVED')));
      this.oldPassword = null;
      this.newPassword = null;
      this.confirmPassword = null;
      this.loaderActive = false;
      this.ngOnInit();
    })
      .catch(() => {
        this.loaderActive = false;
      })
  }

  // Effettua il conteggio degli elementi trovati con la ricerca
  getCreditsCounter() {
    this.userService.getCreditsCounterSplitted()
      .subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            //     this.toastr.error(this.translate.instant('errors.' + data.error));
          } else {
            this.ivassCredit = data.response.ivass || 0;
            this.efaaCredit = data.response.efaa || 0;
            this.efabCredit = data.response.efab || 0;
            this.efpCredit = data.response.efp || 0;
            this.mifidCredit = data.response.mifid || 0;
          }
        }
        , (err) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
        }
      );

    this.userService.getCreditsCounterSplitted()
      .subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + data.error));
          } else {
            this.ivassCreditLIMIT = data.response && data.response.ivass || 0;
            this.efaaCreditLIMIT = data.response && data.response.efaa || 0;
            this.efabCreditLIMIT = data.response && data.response.efab || 0;
            this.efpCreditLIMIT = data.response && data.response.efp || 0;
            this.mifidCreditLIMIT = data.response && data.response.mifid || 0;
          }
        }
        , (err) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
        }
      );
  }

  // Effettua il conteggio degli elementi trovati con la ricerca
  getCurrentBiennium() {
    this.userService.getCurrentBiennium()
      .subscribe(
        (data: SenecaResponse<any[]>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + data.error));
          } else {
            if (data.response && data.response.length && data.response.length > 0) {
              this.currentBiennium = data.response[0];
              this.currentBienniumRequiredCredits = data.response[1] || "-";
            }
          }
        }
        , (err) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
        }
      );
  }

  newTimezoneSelected(newTimezone) {
    this.loaderActive = true;
    this.loggedUser.user.userOptions.timezone = newTimezone;
    let updateUserOptionsPromise = this.updateUserOptions();
    updateUserOptionsPromise.then((newFullToken: string) => {
      this.toastr.success(this.translate.instant(this.translate.instant('generic.DATA_SAVED')));
      this.store.dispatch(new ProfileActions.DecodeToken(newFullToken));
      let decodedJwt = jwtDecode(newFullToken)
      this.store.dispatch(new ProfileActions.UpdateUser(decodedJwt));
      this.store.dispatch(new CoreActions.StartRenewTokenPolling({ forceRefreshUser: true }));
      this.loaderActive = false;
    })
      .catch(() => {
        this.loaderActive = false;
      })
  }

  // Pulisce i dati del nuovo avatar
  resetNewAvatarData() {
    // Immagine tagliata
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.avatarImg.file = null;
    this.avatarImg.title = '';
    this.avatarImgErrorText = null;
    this.showCropper = false;
    this.avatarImg.uploader = null;
    this.avatarImg.uploader = new FileUploader({ url: null });
    this.linkNewAvatarListeners();
  }

  // Collega gli eventi al file allegato da caricare
  linkNewAvatarListeners() {
    this.avatarImg.uploader.onAfterAddingFile = (file: any) => {
      // Se il file supera la grandezza limite, lo segnalo
      if (file && file._file && file._file.size && file._file.size) {
        let byte = file._file.size;
        // Li converto in MB
        let mb = (byte / (1024 * 1024)).toFixed(2);
        // Se il file super il limite previsto, blocco l'upload e segnalo l'errore
        if (parseFloat(mb) > 20) {
          this.avatarImgErrorText = this.translate.instant("errors.FILE_EXCEEDS_LIMIT");
        } else {
          // Salvo il file
          this.avatarImg.file = file;
          this.avatarImgErrorText = null;
        }
      }
    };
  }

  expandUploadAvatar(save?: boolean) {
    this.uploadAvatarVisible = !this.uploadAvatarVisible;
    if (!this.uploadAvatarVisible) {
      if (save) {
        this.cropAndUploadNewAvatar();
      } else {
        this.resetNewAvatarData();
      }
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  // Caricamento dell'immagine fallito
  loadImageFailed() {
    this.toastr.error(this.translate.instant('errors.CANNOT_LOAD_IMAGE'));
  }

  // Rotazioni dell'immagine
  rotateLeft() {
    this.imageCropper.rotateLeft();
  }
  rotateRight() {
    this.imageCropper.rotateRight();
  }
  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }
  flipVertical() {
    this.imageCropper.flipVertical();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  // Taglia ed esegue l'upload dell'immagine
  cropAndUploadNewAvatar() {
    this.loaderActive = true;

    // Creo il blob dell'immagine tagliata
    let newBlob = this.dataURItoBlob(this.croppedImage);

    // Che sostituisco a quello dell'immagine integrale
    //   this.avatarImg.fileToUpload[0].lfFile = newBlob;

    // Preparo il file da mandare al server
    let uploadObj: any = {
      file: newBlob
    };

    this.createAttachment$ = this.authService.uploadAvatar(uploadObj)
      .subscribe(
        (req: HttpResponse<SenecaResponse<any>>) => {
          if (req && req.body) {
            if (req.body.error) {
              this.loaderActive = false;
              this.toastr.error(this.translate.instant('errors.' + req.body.error));
            } else {
              // Aggiorno l'avatar
              this.loggedUser.user.userOptions.avatarImage = req.body.response.url;
              let updateUserOptionsPromise = this.updateUserOptions();
              updateUserOptionsPromise.then((newFullToken: string) => {
                this.toastr.success(this.translate.instant(this.translate.instant('generic.DATA_SAVED')));
                this.store.dispatch(new ProfileActions.DecodeToken(newFullToken));
                let decodedJwt = jwtDecode(newFullToken)
                this.store.dispatch(new ProfileActions.UpdateUser(decodedJwt));
                this.store.dispatch(new CoreActions.StartRenewTokenPolling({ forceRefreshUser: true }));
                this.loaderActive = false;
              })
                .catch(() => {
                  this.loaderActive = false;
                })
            }
          }
        },
        (err) => {
          this.loaderActive = false;
          this.toastr.error(this.translate.instant('errors.' + err.message));
        });
  }

  // Aggiorna le informazioni dell'utente
  updateUserOptions() {
    return new Promise((resolve, reject) => {
      // Creo il collegamento tra l'iniziativa e la (non) scelta della proposta
      this.authService.updateUserOptions(this.loggedUser.user.userOptions)
        .subscribe(
          (senecaResponse: SenecaResponse<string>) => {
            if (senecaResponse.error) {
              reject();
              // Vedo se c'è la traduzione dell'errore
              this.toastr.error(this.translate.instant('errors.' + senecaResponse.error));
            } else {
              resolve(senecaResponse.response);
            }
          },
          (err) => {
            this.toastr.error(this.translate.instant('errors.' + err.message));
            reject();
          });
    })
  }

  // Aggiorna la password
  setNewPassword() {
    return new Promise((resolve, reject) => {
      // Creo il collegamento tra l'iniziativa e la (non) scelta della proposta
      this.authService.setNewPassword(this.oldPassword, this.newPassword)
        .subscribe(
          (senecaResponse: SenecaResponse<string>) => {
            if (senecaResponse.error) {
              reject();
              // Vedo se c'è la traduzione dell'errore
              this.toastr.error(this.translate.instant('errors.' + senecaResponse.error));
            } else {
              this.loaderActive = true;
              let updateUserOptionsPromise = this.updateUserOptions();
              updateUserOptionsPromise.then((newFullToken: string) => {
                this.toastr.success(this.translate.instant(this.translate.instant('generic.DATA_SAVED')));
                this.store.dispatch(new ProfileActions.DecodeToken(newFullToken));
                let decodedJwt = jwtDecode(newFullToken)
                this.store.dispatch(new ProfileActions.UpdateUser(decodedJwt));
                this.store.dispatch(new CoreActions.StartRenewTokenPolling({ forceRefreshUser: true }));
                this.loaderActive = false;
              })
              resolve(senecaResponse.response);
            }
          },
          (err) => {
            this.toastr.error(this.translate.instant('errors.' + err.message));
            reject();
          });
    })
  }

  // Crea un blob dato un URI
  dataURItoBlob(dataURI: string) {
    let byteString = atob(dataURI.split(',')[1]);

    // Separo i componenti mime
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // salvo i bytes di ogni stringa
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // e con essi creo un Blob
    let blob = new Blob([ab], { type: mimeString });

    // Aggiorno l'url del file caricato con quello del nuovo blob
    let newUrl = URL.createObjectURL(blob);
    this.avatarImg.file = newUrl;
    return blob;
  }

  goToHome() {
    const isTeacherMode = this.authService.isTeacherMode(true);
    this.redirectService.goToHome(!this.authService.isAdminMode() && !isTeacherMode, isTeacherMode);
  }

  ngOnDestroy() {
    if (this.result$) {
      this.result$.unsubscribe();
    }
    if (this.createAttachment$) {
      this.createAttachment$.unsubscribe();
    }
  }
}
