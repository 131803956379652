<div class="lp-section" fxLayoutAlign="start center" [ngClass]="{ 'cursor-default' : !isItemDisabled }"
  fxLayout="column" (click)="onShowPropedeuticalItemToThis()">
  <div class="section-wrapper" fxLayout="row" fxLayoutAlign="start center">
    <div class="full-width" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="start center">
        <p class="section-number">{{ index + 1 }}</p>

        <!-- Seleziona oggetto -->
        <div *ngIf="editMode">
          <lib-alloy-input [disabled]="isInPreview" id="{{ item.itemId }}" [type]="'checkbox'"
            [checked]="isItemChecked(sectionItemChild)" (onModelChanged)="emitItemChecked(sectionItemChild)"
            attrName="{{ item.itemId }}" [attrAriaLabel]="'accessibilityAttributes.SELECT_ITEM' | translate"
            inputContainerClass="">
          </lib-alloy-input>
        </div>

        <!-- Titolo -->
        <h1 class="margin-bottom0">{{ item.title || item.name }}</h1>
      </div>

      <!-- Completamento degli oggetti dentro la sezione ed etichette di propedeuticità e obbligatorietà -->
      <div class="padding-left45 full-width" fxLayout="column">
        <!-- Sottotitolo -->
        <h2 *ngIf="item.subTitle" [innerHTML]="item.subTitle" class="color-for-subtitle"></h2>

        <div class="margin-bottom10 margin-top5" fxLayout="row">
          <!-- Sezione obbligatoria -->
          <div *ngIf="isMandatory || (isMandatory && isPropedeutic)" fxLayout="row" fxLayoutAlign="start start">
            <p class="mandatory-label" translate="card.obligatoriness.REQUIRED"></p>
          </div>

          <!-- Sezione propedeutica -->
          <div *ngIf="isPropedeutic || (isMandatory && isPropedeutic)" fxLayout="row" fxLayoutAlign="start start">
            <p class="preparatory-label" translate="card.obligatoriness.NOT_REQUIRED"></p>
          </div>

          <!-- Oggetto propedeutico per questo -->
          <div class="margin-left10" fxLayout="row" fxLayoutAlign="start start" *ngIf="propedeuticTitle">
            <p class="no-margin"><span translate="sectionProject.ITEM_PREREQUISITE"></span>: {{ propedeuticTitle }}</p>
          </div>
        </div>
        <!-- Conteggi per il completamento della sezione -->
        <h5 *ngIf="!editMode"><strong translate="sectionProject.COMPLETION"></strong></h5>
        <div fxLayout="column" class="full-width" fxLayoutAlign="start start">
          <div fxLayout="column" class="full-width" fxLayout.gt-xs="row wrap" fxLayoutAlign="start start">
            <div *ngIf="!editMode" fxLayout="column" fxLayoutAlign="start start">
              <p class="no-margin section-description"><span class="bold-text">{{ totalConsumedItemChilds }}/{{
                  (item && item.itemChilds && item.itemChilds.length || 0) }}</span>&nbsp;<span
                  translate="sectionProject.TOTAL_ITEMS_COMPLETED"></span>
              </p>
            </div>
            <div class="margin-left10" *ngIf="!editMode" fxLayout="column" fxLayoutAlign="start start">
              <p class="no-margin section-description"><span class="bold-text">{{ consumedMandatorItemChilds }}/{{
                  mandatoryItemChilds }}</span>&nbsp;<span translate="sectionProject.MANDATORY_ITEMS_COMPLETED"></span>
              </p>
            </div>
          </div>
          <div *ngIf="!editMode && totalSurveyMandatoryInSection != 0" fxLayout="column" fxLayoutAlign="start start">
            <p class="no-margin section-description"><span class="bold-text">{{ completedSurvey || 0 }}/{{
                totalSurveyMandatoryInSection || 0 }}</span>&nbsp;<span
                translate="sectionProject.MANDATORY_SURVEY_COMPLETED"></span></p>
          </div>
        </div>

        <!-- Contenuti formativi della sezione (se sono in modalità di edit) -->
        <div *ngIf="editMode" fxLayout="row" fxLayoutAlign="space-between center" fxFill>
          <h3>{{ ((item && item.itemChilds && item.itemChilds.length) || numberOfChilds || 0) + ' ' }}
            <span>{{ item.title || item.name }}</span>
            <!-- <span>{{ (item && item.itemChilds && item.itemChilds.length != 1 ? ('sectionProject.CONTENTS' | translate) :
              ('sectionProject.CONTENT' | translate)) + ' ' }}</span> -->
            <!-- <span>{{ item && item.itemChilds && item.itemChilds.length != 1 ? ('sectionProject.TRAININGS' | translate) :
              ('sectionProject.TRAINING' | translate) }}</span> -->
          </h3>
          <!-- Porta alla pagina di edit -->
          <lib-alloy-button [disabled]="isItemDisabled || isInPreview" importance="primary" dimension="btn-regular"
            text="{{'sectionProject.EDIT' | translate}}" (onButtonClicked)="goToEditSection();">
          </lib-alloy-button>
        </div>
        <div *ngIf="editMode" class="switch-section-wrapper" fxLayout.gt-xs="row" fxLayout.xs="column"
          fxLayoutAlign="center center">
          <!-- Switch propedeuticità (se switch sull'on, arpe una modale per scegliere l'oggetto propedeutico per questo) -->
          <div fxLayout="column" fxLayoutAlign="start center" fxFlex>
            <p class="bold-text" translate="generic.IS_SECTION_PROPEDEUTIC"></p>
            <div fxLayout="row" fxLayoutAlign="center center">
              <p class="margin-right10" translate="generic.NO"></p>
              <div fxLayout="row" class="margin-right10">
                <label class="switch" role="button">
                  <input type="checkbox" title="{{'card.PREREQUISITE' | translate}}" [disabled]="isInPreview"
                    attr.aria-label="{{'card.PREREQUISITE' | translate}}" (click)="onManagePrerequisites($event)"
                    [checked]="prerequisites.hasPrerequisites">
                  <div class="slider round">
                  </div>
                </label>
              </div>
              <p class="margin-left10" translate="generic.YES"></p>
            </div>
          </div>
          <div hide.gt-sx class="space25"></div>
          <!-- Switch obbligatorietà -->
          <div fxLayout="column" fxLayoutAlign="start center" fxFlex>
            <p class="bold-text" translate="generic.IS_SECTION_MANDATORY"></p>
            <div fxLayout="row" fxLayoutAlign="center center">
              <p class="margin-right10" translate="generic.NO"></p>
              <div fxLayout="row" class="margin-right10">
                <label class="switch" role="button">
                  <input type="checkbox" title="{{'card.MANDATORY' | translate}}" [disabled]="isInPreview"
                    attr.aria-label="{{'card.MANDATORY' | translate}}" (ngModelChange)="changeMandatory()"
                    [(ngModel)]="isMandatory">
                  <div class="slider round">
                  </div>
                </label>
              </div>
              <p class="margin-left10" translate="generic.YES"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Lista degli oggetti aggiunti alla sezione (se non sono in modalità di edit) -->
  <div fxLayout="column" *ngIf="!editMode && item && item.itemChilds && item.itemChilds.length"
    class="items-of-section-container">
    <div *ngFor="let itemChild of item.itemChilds; let last = last;" class="center-item"
      [ngClass]="{ 'cursor-default' : !itemChild.isItemDisabled && !isItemDisabled, 'cursor-pointer' : itemChild.isItemDisabled || isItemDisabled }">
      <item-in-learning-plan [lastElement]="last" [loggedUserId]="loggedUserId" [mandatory]="itemChild.mandatory"
        [isSectionDisabled]="isItemDisabled" [propedeuticReferenceId]="itemChild.propedeuticReferenceId"
        [propedeuticReferenceIds]="propedeuticReferenceIdsOfItemsInSection" [isNotDisabled]="itemChild.isNotDisabled"
        [allParentChilds]="item.itemChilds" (showPropedeuticalItemToThis)="onShowPropedeuticalItemToThisChild($event)"
        [item]="itemChild" [currentItemType]="itemType" [survey]="surveyMap[itemChild.referenceId]"
        [isInPreview]="isInPreview" [hideDetails]="hideDetails"
        [isLpItemDisabled]="disableActionForLpItemsForInvalidStatus">
      </item-in-learning-plan>
    </div>
  </div>
</div>