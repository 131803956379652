/*
*  Lista di azioni riguardanti una Edizione
*/

import { Action } from '@ngrx/store';
import { CourseEditionOptions, CourseEdition } from '../../shared/models/course.model';


export const EDIT_COURSE_EDITION = 'EDIT_COURSE_EDITION';

export const NEW_COURSE_EDITION = 'NEW_COURSE_EDITION';

export const PUBLISH = 'PUBLISH';

export const ADD_DAY = 'ADD_DAY';

export const DELETE_COURSE_EDITION = 'CREATE_COURSE_EDIITON';

export const GET_COURSE_EDITION = 'GET_COURSE_EDITION';

export const SET_COURSE_EDITION = 'SET_COURSE_EDITION';

export const CLEAR_COURSE_EDITION_STATE = 'CLEAR_COURSE_EDITION_STATE';

export const SET_COURSE_EDITION_OPITONS = 'SET_COURSE_EDITION_OPITONS';

export const EDIT_COURSE_EDITION_OPTIONS = 'EDIT_COURSE_EDITION_OPTIONS';

export const CONFIRM_COURSE_EDITION_OPTIONS = 'CONFIRM_COURSE_EDITION_OPTIONS';

export class EditCourseEditionOptions implements Action {
  readonly type = EDIT_COURSE_EDITION_OPTIONS;

  constructor(public payload: CourseEditionOptions) { }
}

export class ConfirmCourseEditionOptions implements Action {
  readonly type = CONFIRM_COURSE_EDITION_OPTIONS;

  constructor(public payload: CourseEditionOptions) { }
}

export class EditCourseEdition implements Action {
  readonly type = EDIT_COURSE_EDITION;

  constructor(public payload: CourseEdition) { }
}

export class NewCourseEdition implements Action {
  readonly type = NEW_COURSE_EDITION;

  constructor(public payload: CourseEdition) { }
}

export class SetCourseEdition implements Action {
  readonly type = SET_COURSE_EDITION;

  constructor(public payload: CourseEdition) { }
}

export class SetCourseEditionOptions implements Action {
  readonly type = SET_COURSE_EDITION_OPITONS;

  constructor(public payload: CourseEditionOptions) { }
}

export class ClearCourseEditionState implements Action {
  readonly type = CLEAR_COURSE_EDITION_STATE;

  constructor() { }
}

export class GetCourseEdition implements Action {
  readonly type = GET_COURSE_EDITION;

  constructor(public payload: string) { }
}

export class DeleteCourseEdition implements Action {
  readonly type = DELETE_COURSE_EDITION;

  constructor(public payload: string) { }
}

export class PublishCourseEdition implements Action {
  readonly type = PUBLISH;

  constructor(public payload: string) { }
}

export type CourseEditionActions = EditCourseEditionOptions |
  ConfirmCourseEditionOptions |
  NewCourseEdition |
  EditCourseEdition |
  SetCourseEdition |
  SetCourseEditionOptions |
  ClearCourseEditionState |
  GetCourseEdition |
  PublishCourseEdition;
