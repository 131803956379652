<div>
  <!-- Tipologia e durata (o numero di oggetti contenuti, qualora fosse un contenitore) -->
  <div class="typology-wrapper-container" style="height: 30px;">
  </div>
  <!-- Immagine Placeholder -->
  <div class="img-background-card-wrapper placeholder"></div>
  <!-- Percentuale di avanzamento -->
  <div class="percentage-wrapper">
    <div fxLayout="row" style="padding: 10px 0;" fxLayoutAlign="center center">
      <!-- Barra di progressione -->
      <div class="progress-bar">
        <div class="progress-bar-bar"></div>
      </div>
    </div>
  </div>

  <!-- Titolo -->
  <div class="title-card">
    <div class="line animation170"></div>
    <div class="line animation90"></div>
  </div>
  <div class="call-to-actions" fxLayout="row" fxLayoutAlign="center center">
    <lib-alloy-button noMargin="true" importance="bordless" dimension="btn-small"
      [text]="'generic.SHOW_DETAILS' | translate" [attr.aria-label]="'generic.SHOW_DETAILS' | translate">
    </lib-alloy-button>
  </div>
</div>