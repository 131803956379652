<div style="height: 100vh" fxLayout="row">
  <div fxLayout="column" fxLayoutAlign="center center" fxFill>
    <div>
      <img src="../../../assets/icons/logo.svg" height="100px" width="160px">
    </div>
    <h2>
      Portale Formazione
    </h2>
    <div fxLayout="row" fxLayoutAlign="center center">
      <h1 class="error_404 text-inline">403</h1>
    </div>
    <h2 class="no-margin">FORBIDDEN!</h2>
    <h4 class="text-center"><span translate="errors.403"></span>
    </h4>
  </div>
</div>