import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'minToHours'
})
export class MinutesToHours implements PipeTransform {
  transform(value: number): string {
    let hours = Math.floor(value / 60);
    let minutes = Math.floor(value % 60);
    let result = '';
    if (hours > 0) {
      result += hours + 'h ';
    }
    if (minutes > 0) {
      result += minutes + ' m';
    }
    return result;
  }
}
