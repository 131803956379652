import { Component } from '@angular/core';

@Component({
  selector: 'lib-alloy-library-card-placeholder',
  templateUrl: './library-card-placeholder.component.html',
  styleUrls: ['./library-card-placeholder.component.scss']
})
export class LibraryCardPlaceholderComponent {

  constructor() {
  }
}
