/*
* Componente per la paginazione
*/

import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from "@angular/core"
@Component({
  selector: 'table-pagination',
  templateUrl: './pagination.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent {
  @Input() id: string;
  @Input() forceDisabled: boolean;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter();
  constructor() {
  }

  // Alza l'evento per il componente padre
  onPageChanged(event: number) {
    this.pageChanged.emit(event);
  }
}