// Login dei docenti

import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, Subscription } from 'rxjs';
import * as fromApp from '../../ngrx/app.reducers';
import * as AuthActions from '../ngrx/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Lang } from "../../../cm2-commonclasses";
import { BaseSubscriberComponent } from 'src/app/shared/components/base-subscriber.component';
import { AuthService } from '../services/auth.service';
import { ModalService } from 'library-alloy/dist/library-alloy';
import * as CoreActions from '../../core/ngrx/core.actions';

@Component({
  selector: 'app-teacherLogin',
  templateUrl: './teacherLogin.component.html',
  styleUrls: ['./teacherLogin.component.scss']
})
export class TeacherLoginComponent extends BaseSubscriberComponent implements OnInit, OnDestroy {
  isFetchingLangs: boolean;
  langs: Lang[];
  result$;
  subscriptionFormVisible: boolean;
  resetPasswordFormVisible: boolean;
  userId: string;
  token: string;
  showLoader: boolean;
  translatedModalText: string;
  translatedModalTitle: string;
  isEmailConfirmation: boolean;
  emailConfirmation$: Subscription;
  isManagingUser$: Subscription;
  resetPswEmail;
  newValidationToken: string;
  passwordPattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@!%*?&£\._:\-\+])[A-Za-z\d$@!%*?&£\._:\-\+]{8,}/g;

  constructor(private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private modalService: ModalService,
    private router: Router) {
    super();

    // Sto in ascolto di quando il globalApplicationData cambia, così da sapere quando ci sono le lingue disponibili poiché, se non ci fossero, il pulsante per il login sarebbe disabilitato
    let availableLangs$: Observable<Lang[]> = this.store.select(fromApp.getAvailableLangs);
    let isFetchingLangs$: Observable<boolean> = this.store.select(fromApp.isFetchingLangs);
    const combinedSelectes$ = combineLatest(availableLangs$, isFetchingLangs$);
    this.result$ = combinedSelectes$.subscribe(
      ([langs, isFetchingLangs]) => {
        this.langs = langs;
        this.isFetchingLangs = isFetchingLangs;
      });
  }

  ngOnInit() {
    this.route.params
      .subscribe(
        (params: Params) => {
          this.userId = params['userId'];
          this.token = params['token'];
          this.newValidationToken = null;
          if (this.router.url.indexOf('emailConfirmation') !== -1) {
            this.isEmailConfirmation = true;
            // Chiamo subito il servizio per la conferma dell'utente
            this.emailConfirmation$ = this.authService.confirmUserEmail(this.userId, this.token, true)
              .subscribe(data => {
                // Se ci sono errori, li mostor e torno alla lista dei template
                if (data.error) {
                  this.openSuccessModal('errors.' + data.error, 'generic.ALERT');
                } else {
                  // Salvo il nuovo validation Token per il settaggio della nuova password
                  this.newValidationToken = data.response && data.response.validationToken;
                }
                this.isEmailConfirmation = false;
              });
          } else {
            this.isEmailConfirmation = false;
          }
        });

    this.route.queryParamMap.subscribe((queryParams) => {
      const token = queryParams && queryParams.get('token');
      if (token && token.length) {
        this.showLoader = true;
        // Sto impersonificando un utente, quindi faccio il login automatico
        //   const getTokenFromKeyPromise = this.getTokenFromSsortkqp(queryParams.get('token'));
        //   getTokenFromKeyPromise.then((token: string) => {

          sessionStorage.setItem('isTeacher', "true");
        sessionStorage.setItem("token", token);

        this.store.dispatch(new CoreActions.SetImpersonificationBanner(true));
        this.store.dispatch(AuthActions.SetUserAuthenticated({}));
        this.store.dispatch(AuthActions.SetToken({ payload: token }));
        this.store.dispatch(new CoreActions.StartRenewTokenPolling({ redirectUrl: '/teachers/home' }));
        this.showLoader = false;
        //   })
      }
    })

    this.subscriptionFormVisible = false;
    this.resetPasswordFormVisible = false;
    this.translatedModalTitle = null;
    this.translatedModalText = null;
  }

  arePswMatchingPattern(val?) {
    if (val && val.match(this.passwordPattern)) {
      return true;
    }
    return false;
  }

  // Toggle del form per l'iscrizione
  toggleSubscriptionFormVisible() {
    this.subscriptionFormVisible = !this.subscriptionFormVisible;
  }

  // Toggle del form per il reset della password
  toggleResetPasswordFormVisible() {
    this.resetPasswordFormVisible = !this.resetPasswordFormVisible;
  }

  // Dal form passato come parametro, ricavo i dati immessi dall'utente per il recupero della password
  onRecoverPassword(form: NgForm) {
    this.showLoader = true;
    if (this.userId && (this.token || this.newValidationToken)) {
      // Chiamo il servizio per inviare la mail
      this.isManagingUser$ = this.authService.recoverUserPassword(form.value.repeatPassword, this.userId, this.newValidationToken || this.token)
        .subscribe(data => {
          this.showLoader = false;
          // Se ci sono errori, li mostor e torno alla lista dei template
          if (data.error) {
            this.openSuccessModal('errors.' + data.error, 'generic.ALERT');
          } else {
            // Mostro il messaggio di avviso
            this.openSuccessModal('generic.modals.PASSWORD_SET', 'generic.modals.PASSWORD_SET_TITLE');
            this.isEmailConfirmation = false;
            this.token = null;
            this.userId = null;
            this.newValidationToken = null;
          }
        });
    } else {
      // Altrimenti significa che devo inviare la mail all'utente
      this.isManagingUser$ = this.authService.initPasswordRecovery(form.value.resetPswEmail)
        .subscribe(data => {
          this.showLoader = false;
          // Se ci sono errori, li mostro e torno alla lista dei template
          if (data.error) {
            if (data.error === 'USER_NOT_FOUND') {
              this.openSuccessModal('generic.modals.ERROR_RETRIEVE_PASSWORD_BODY', 'generic.modals.ERROR_RETRIEVE_PASSWORD');
            } else {
              this.openSuccessModal('errors.' + data.error, 'generic.ALERT');
            }
            // Eseguo il toggle
            this.toggleResetPasswordFormVisible();
          } else {
            // Mostro il messaggio di avviso
            this.openSuccessModal('generic.modals.EMAIL_FOR_CHANGE_PSW_SENT_BODY', 'generic.modals.EMAIL_FOR_CHANGE_PSW_SENT');
            // Eseguo il toggle
            this.toggleResetPasswordFormVisible();
          }
        });
    }
  }

  // Apre la modale di operazione avvenuta
  openSuccessModal(textToTranslate?: string, title?: string) {
    this.translatedModalText = null;
    this.translatedModalTitle = null;
    if (textToTranslate) {
      this.translatedModalTitle = this.translate.instant(title);
      this.translatedModalText = this.translate.instant(textToTranslate);
    } else {
      this.translatedModalTitle = this.translate.instant('generic.ALERT');
      this.translatedModalText = this.translate.instant('generic.DATA_SAVED');
    }

    this.modalService.open('successModal');
  }

  // Chiude la modale di operazione avvenuta
  closeSuccessModal() {
    this.translatedModalText = null;
    this.translatedModalTitle = null;
    this.modalService.close('successModal');
  }

  // Chiude la modale che avverte l'utente che deve confermare la sua utenza
  closeUserToBeConfirmedModal() {
    this.translatedModalText = null;
    this.translatedModalTitle = null;
    this.modalService.close('userToBeConfirmed');
  }

  // Dal form passato come parametro, controllo se le due password nuove sono uguali
  areNewPswSame(form: NgForm) {
    const newPsw = form && form.value && form.value.newPsw;
    const newPswRepeated = form && form.value && form.value.repeatPassword;
    if (newPsw && newPswRepeated && newPsw === newPswRepeated) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    if (this.emailConfirmation$) {
      this.emailConfirmation$.unsubscribe();
    }
    if (this.result$) {
      this.result$.unsubscribe();
    }
    if (this.isManagingUser$) {
      this.isManagingUser$.unsubscribe();
    }
  }

  // Dal form passato come parametro, ricavo i dati immessi dall'utente per inserirli nel dispatch dell'action che tenterà il login
  onLogin(form: NgForm) {
    this.showLoader = true;
    const email = form.value.email;
    const password = form.value.password;
    // Di default entro sempre come utente normale
    this.store.dispatch(AuthActions.DoTeacherLogin({ email: email, password: password }));
    this.showLoader = false;
  }
}
