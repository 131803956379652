import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from '../../ngrx/app.reducers';
import { first, switchMap } from "rxjs/operators";
import { combineLatest, Observable, of } from "rxjs";
import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import { TranslateService } from "@ngx-translate/core";
import { SenecaResponse } from "atfcore-commonclasses";

@Injectable({
    providedIn: 'root'
})
export class AvailableCreditTypesService {
    private applicationContext$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);

    constructor(
        private http: HttpClient,
        private store: Store<fromApp.AppState>,
    ) { }

    // this method is called when the application context is loaded and store the available credit types
    // in the store. 
    // If you need to get the list of available credit types, you can 
    // 1. use the store.select(fromApp.getAvailableCreditTypes) or
    // 2. call getTranslatedAvailableCreditTypes()
    getAvailableCreditTypes(): Observable<string[]> {

        const userToken$ = this.store.select(fromApp.getToken);

        return combineLatest([
            this.applicationContext$.pipe(first(context => !!context)),
            userToken$.pipe(first(token => token !== null))
        ]).pipe(
            switchMap(([globalApplicationData, userToken]) => {
                return this.http.get<SenecaResponse<string[]>>(
                    `${globalApplicationData?.applicationContext}rest-api/corporateacademy-mediator/get-all-available-credit-types`,
                    {
                        headers: {
                            Authorization: `Bearer ${userToken}`
                        }
                    }
                )
            }),
            switchMap(({ response, error }) => {
                if (error) {
                    console.error('Error while getting available credit types', error);
                    return of([]);
                }
                return of(response);
            })
        );
    }

    getTranslatedAvailableCreditTypes(translateSvc: TranslateService): Observable<{ [key: string]: string }> {
        return this.store.select(fromApp.getAvailableCreditTypes).pipe(
            first(creditTypes => creditTypes !== null),
            switchMap(creditTypes => {
                const prefix = 'creditTypes';

                if (creditTypes.length === 0) {
                    return of({});
                }

                return translateSvc.get(
                    creditTypes.map(creditType => `${prefix}.${creditType}`)
                )
            })
        )
    }

    getOnlyUsableTags(): Observable<string[]> {

        const userToken$ = this.store.select(fromApp.getToken);

        return combineLatest([
            this.applicationContext$.pipe(first(context => !!context)),
            userToken$.pipe(first(token => token !== null))
        ]).pipe(
            switchMap(([globalApplicationData, userToken]) => {
                return this.http.get<SenecaResponse<string[]>>(
                    `${globalApplicationData?.applicationContext}rest-api/corporateacademy-mediator/get-all-searchable-tagtypes`,
                    {
                        headers: {
                            Authorization: `Bearer ${userToken}`
                        }
                    }
                )
            }),
            switchMap(({ response, error }) => {
                if (error) {
                    console.error('Error while getting usable tags', error);
                    return of([]);
                }
                return of(response);
            })
        );
    }
}