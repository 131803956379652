/*
*  Lista di azioni riguardanti i template mail
*/

import { Action } from '@ngrx/store';
import { TextTemplate } from 'atfcore-commonclasses';

// Salva la lista di template recuperati
export const SET_TEMPLATES = 'SET_TEMPLATES';

// Aggiunge un nuovo template
export const ADD_TEMPLATE = 'ADD_TEMPLATE';

// Aggiorna un template
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';

// Cancella un template
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';

// Salva il template
export const STORE_TEMPLATE = 'STORE_TEMPLATE';

// Recupera i template da remoto
export const GET_TEMPLATES = 'GET_TEMPLATES';

// Setta il filtro per la tipologia
export const SET_TEMPLATES_TYPE = 'SET_TEMPLATES_TYPE';

// Setta il contatore dei template recuperato da remoto
export const SET_TEMPLATES_COUNT = 'SET_TEMPLATES_COUNT';

// Setta la paginazione
export const SET_PAGINATION = 'SET_PAGINATION';

// Setta la pagina corrente
export const SET_PAGE = 'SET_PAGE';

export class SetTemplates implements Action {
  readonly type = SET_TEMPLATES;

  constructor(public payload: TextTemplate[]) { }
}

export class AddTemplate implements Action {
  readonly type = ADD_TEMPLATE;

  constructor(public payload: TextTemplate) { }
}

export class UpdateTemplate implements Action {
  readonly type = UPDATE_TEMPLATE;

  constructor(public payload: { index: number, updatedTemplate: TextTemplate }) { }
}

export class DeleteTemplate implements Action {
  readonly type = DELETE_TEMPLATE;

  constructor(public payload: string) { }
}

export class StoreTemplate implements Action {
  readonly type = STORE_TEMPLATE;
}

export class GetTemplates implements Action {
  readonly type = GET_TEMPLATES;

  constructor(public payload?: boolean) { }
}

export class SetTemplatesCount implements Action {
  readonly type = SET_TEMPLATES_COUNT;

  constructor(public payload: number) { }
}

export class SetTemplatesType implements Action {
  readonly type = SET_TEMPLATES_TYPE;

  constructor(public payload: string) { }
}

export class SetPage implements Action {
  readonly type = SET_PAGE;

  constructor(public payload: number) { }
}

export class SetPagination implements Action {
  readonly type = SET_PAGINATION;

  constructor(public payload: { fromRecord: number, numRecords: number }) { }
}

export type TemplateActions = SetTemplates |
  AddTemplate |
  UpdateTemplate |
  DeleteTemplate |
  StoreTemplate |
  SetTemplatesCount |
  SetPagination |
  SetPage |
  SetTemplatesType |
  GetTemplates;