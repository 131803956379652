/*
* Pipe per filtrare i tipi di proposta dei fornitori
*/

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'orderByDate',
  pure: false
})
export class OrderByDatePipe implements PipeTransform {
  transform(array?: Array<string>, args?: string): Array<string> {
    if (!array || array === undefined || array.length === 0) return null;
    array.sort((a: any, b: any) => {
      if ((a.dayDate && b.dayDate) && a.dayDate < b.dayDate) {
        return -1;
      } else if ((a.dayDate && b.dayDate) && a.dayDate > b.dayDate) {
        return 1;
      } else if ((a.date && b.date) && a.date < b.date) {
        return -1;
      } else if ((a.date && b.date) && a.date > b.date) {
        return 1;
      } else if ((a.yearMonth && b.yearMonth) && a.yearMonth < b.yearMonth) {
        return -1;
      } else if ((a.yearMonth && b.yearMonth) && a.yearMonth > b.yearMonth) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}