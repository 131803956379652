/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante le iniziative (corsi)
*/

import * as InitiativeActions from './initiative.actions';
import * as fromApp from '../../ngrx/app.reducers';
import { Item, CMUtils } from "../../../cm2-commonclasses";

// Le iniziative sono una feature dell'applicazione, infatti è un modulo caricato in maniera lazy (e quindi anche lo Store).
// Creo un'interfaccia, FeatureState, in cui mappo le iniziative dello state InitiativeState
// Estendo fromApp.AppState così per ereditare tutte le proprietà dello Store principale
export interface FeatureState extends fromApp.AppState {
  initative: InitiativeState;
}

// Interfaccia dello Store
export interface InitiativeState {
  selectedInitiativeId: string;
  selectedInitiativeData: { currentWizardStep: string, initiativeItem: Item, pathItem: Item };
  initiatives: any[];
  count: number;
  fromRecord: number;
  numRecords: number;
  page: number;
  isFetchingInitiatives: boolean;
  selectedInitiativeType: string;
  initiativesTextFilter: string;
  locationAttributeRefIds: string[];
  argumentsTagAttributeRefIds: string[];
  streamsTagAttributeRefIds: string[];
  adminTagAttributeRefIds: string[];
  sapCodeTextFilter: string;
  selectedInitiativeStatus: string;
  vplErrorMessage: any;
}

// Store iniziale
const initialState: InitiativeState = {
  selectedInitiativeId: null,
  selectedInitiativeData: {
    currentWizardStep: null,
    initiativeItem: null,
    pathItem: null
  }, // dati relativi all'iniziativa
  initiatives: [], // lista delle iniziative
  count: 0, // contatore totale delle iniziative
  fromRecord: 0, // numero da cui recuperare gli elementi
  numRecords: 20, // elementi da recuperare
  page: 1, // pagina della tabella visualizzata
  isFetchingInitiatives: false, // verifica se sto eseguendo una chiamata per recuperare le iniziative
  selectedInitiativeType: null,
  initiativesTextFilter: null,
  locationAttributeRefIds: null,
  argumentsTagAttributeRefIds: null,
  streamsTagAttributeRefIds: null,
  adminTagAttributeRefIds: null,
  sapCodeTextFilter: null,
  selectedInitiativeStatus: null,
  vplErrorMessage: null
};

export function initiativeReducer(state = initialState, action: InitiativeActions.InitiativeActions) {
  switch (action.type) {
    case InitiativeActions.SET_INITIATIVES:
      // return {
      //     ...state,
      //     templates: [...state.templates, ...action.payload] // sovrascrivo le iniziative
      // };
      return {
        ...state,
        isFetchingInitiatives: false,
        initiatives: [...action.payload] // sovrascrivo le iniziative
      };
    case InitiativeActions.SET_PAGINATION:
      return {
        ...state,
        fromRecord: action.payload.fromRecord,
        numRecords: action.payload.numRecords
      };
    case InitiativeActions.GET_INITIATIVES:
      return {
        ...state,
        isFetchingInitiatives: true
      };
    case InitiativeActions.SET_INITIATIVES_TYPE:
      return {
        ...state,
        selectedInitiativeType: action.payload
      };
    case InitiativeActions.SET_INITIATIVES_STATUS:
      return {
        ...state,
        selectedInitiativeStatus: action.payload
      };
    case InitiativeActions.SET_INITIATIVES_LOCATIONS:
      return {
        ...state,
        locationAttributeRefIds: action.payload
      };
    case InitiativeActions.SET_INITIATIVES_ARGUMENTS:
      return {
        ...state,
        argumentsTagAttributeRefIds: action.payload
      };
    case InitiativeActions.SET_INITIATIVES_STREAMS:
      return {
        ...state,
        streamsTagAttributeRefIds: action.payload
      };
    case InitiativeActions.SET_INITIATIVES_ADMIN:
      return {
        ...state,
        adminTagAttributeRefIds: action.payload
      };
    case InitiativeActions.SET_INITIATIVES_TEXT_FILTER:
      return {
        ...state,
        initiativesTextFilter: action.payload
      };
    case InitiativeActions.SET_PAGE:
      return {
        ...state,
        page: action.payload
      };
    case InitiativeActions.SET_INITIATIVES_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    case (InitiativeActions.ADD_INITIATIVE):
      return {
        ...state,
        initiatives: [action.payload, ...state.initiatives]
      };
    case (InitiativeActions.UPDATE_INITIATIVE):
      // Torneremo sempre lo stato aggiornato, ma prima devo modificare l'iniziativa. Quindi procedo recuperandola
      const initiative = state.initiatives && state.initiatives.find((item: any) => {
        return item.itemId === action.payload.updatedInitiative.itemId;
      });
      const index = state.initiatives.indexOf(initiative);

      // state.initiatives[action.payload.updatedInitiative.itemId];
      const updatedInitiative = {
        ...initiative,
        ...action.payload.updatedInitiative
      };
      // con lo spread operator tiro fuori tutti gli elementi dell'array state.initiatives per aggiungerli nel nuovo array.
      //  Così, li ho aggiunti in maniera immutabile
      let initiatives = [...state.initiatives];

      if (index >= 0) {
        initiatives[index] = updatedInitiative; // ora posso aggiornare l'iniziativa
      } else {
        // se sto aggiorando ma non trovo l'iniziativa l'aggiungo,
        //  questo significa che è stata appena creata dalla prima conferma del primo step
        if (updatedInitiative && CMUtils.STAGE_ITEM_TYPES.includes(updatedInitiative.itemType)) {
          initiatives = [updatedInitiative, ...initiatives];
        }
      }
      initiatives = initiatives.length === 0 ? [updatedInitiative] : initiatives;

      if (state.selectedInitiativeData && state.selectedInitiativeData.initiativeItem
        && state.selectedInitiativeData.initiativeItem === updatedInitiative.itemId) {
        return {
          ...state,
          initiatives: initiatives,
          selectedInitiativeData: {
            ...state.selectedInitiativeData,
            initiativeItem: updatedInitiative
          }
        };
      } else {
        return {
          ...state,
          initiatives: initiatives
        };
      }
    case (InitiativeActions.SELECT_INITIATIVE):
      // aggiorno una iniziativa nello state
      // imposto l'id dell'iniziativa selezionata e come effetto l'aggiorno recuperandola dai servizi remoti
      return {
        ...state,
        selectedInitiativeId: action.payload
      };
    case (InitiativeActions.SET_SELECTED_INITIATIVE_ITEM):
      // Salva l'oggetto intero dell'iniziativa selezionata
      return {
        ...state,
        selectedInitiativeData: {
          ...state.selectedInitiativeData,
          initiativeItem: action.payload
        }
      };
    case (InitiativeActions.SET_PATH_ITEM_OF_SELECTED_INITIATIVE):
      // Salva l'intero oggetto padre (quindi il percorso) dell'iniziativa scelta
      return {
        ...state,
        selectedInitiativeData: {
          ...state.selectedInitiativeData,
          pathItem: action.payload
        }
      };
    case (InitiativeActions.DELETE_SELECTED_INITIATIVE_DATA):
      return {
        ...state,
        selectedInitiativeData: {
          initiativeItem: null,
          pathItem: null
        }
      };
    case (InitiativeActions.DELETE_INITIATIVE):
      const oldInitiatives = [...state.initiatives];
      for (let i = 0, initiativesLength = oldInitiatives.length; i < initiativesLength; i++) {
        const currentInitiative = oldInitiatives[i];
        if (currentInitiative.itemId === action.payload) {
          oldInitiatives.splice(i, 1);
          break;
        }
      }
      return {
        ...state,
        initiatives: oldInitiatives
      };
    case (InitiativeActions.SET_CURRENT_WIZARD_STEP):
      // Salva l'oggetto intero dell'iniziativa selezionata
      return {
        ...state,
        selectedInitiativeData: {
          ...state.selectedInitiativeData,
          currentWizardStep: action.payload
        }
      };
    case InitiativeActions.SET_SAP_CODE_FILTER:
      return {
        ...state,
        sapCodeTextFilter: action.payload
      };
    case InitiativeActions.SET_VPL_MODAL_ERROR:
      return {
        ...state,
        vplErrorMessage: action.payload
      };
    default:
      return state;
  }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getInitiatives = (state: InitiativeState) => state.initiatives;
export const getInitiativesCount = (state: InitiativeState) => state.count;
export const getInitiativesFromRecord = (state: InitiativeState) => (state && state.fromRecord) || 0;
export const getInitiativesNumRecords = (state: InitiativeState) => (state && state.numRecords) || 20;
export const getInitiativesPage = (state: InitiativeState) => (state && state.page) || 1;
export const getSelectedInitiativeItem = (state: InitiativeState) => {
  return state.selectedInitiativeData && state.selectedInitiativeData.initiativeItem;
};
export const getPathItemOfSelectedInitiative = (state: InitiativeState) => {
  return state.selectedInitiativeData && state.selectedInitiativeData.pathItem;
};
export const isFetchingInitiatives = (state: InitiativeState) => state.isFetchingInitiatives;
export const getWizardCurrentStep = (state: InitiativeState) => {
  return state.selectedInitiativeData && state.selectedInitiativeData.currentWizardStep;
};
export const getInitiativesFilterType = (state: InitiativeState) => (state && state.selectedInitiativeType) || null;
export const getInitiativesStatusType = (state: InitiativeState) => (state && state.selectedInitiativeStatus) || null;
export const getInitiativesTextFilter = (state: InitiativeState) => (state && state.initiativesTextFilter) || null;
export const getInitiativesLocations = (state: InitiativeState) => (state && state.locationAttributeRefIds) || null;
export const getInitiativesArguments = (state: InitiativeState) => (state && state.argumentsTagAttributeRefIds) || null;
export const getInitiativesStreams = (state: InitiativeState) => (state && state.streamsTagAttributeRefIds) || null;
export const getInitiativesAdmin = (state: InitiativeState) => (state && state.adminTagAttributeRefIds) || null;
export const getInitiativesSapCode = (state: InitiativeState) => (state && state.sapCodeTextFilter) || null;
export const getVplErrorMessage = (state: InitiativeState) => (state && state.vplErrorMessage) || null;

