import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "formatFileSize",
    pure: false
})
export class FormatFileSizePipe implements PipeTransform {
    transform(size: number): any {
        if (isNaN(size))
            size = 0;

        if (size < 1024)
            if (size > 0) {
                return size + ' BYTE';
            } else {
                return '-';
            }

        size /= 1024;

        if (size < 1024)
            return size.toFixed(2) + ' KB';

        size /= 1024;

        if (size < 1024)
            return size.toFixed(2) + ' MB';

        size /= 1024;

        if (size < 1024)
            return size.toFixed(2) + ' GB';

        size /= 1024;

        return size.toFixed(2) + ' TB';
    }
}