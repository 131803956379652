import { ItemTypes, Item, ItemAttributeTypes, parseBoolean, TagAttributeTypes, User } from '../../../cm2-commonclasses';
import { WorkflowInstance } from 'atfcore-commonclasses/bin/classes/workflow';

/*
 * Modello che dinisce le classi utili per la gestione delle iniziative
*/

export class InitiativeType {
  public id?: string;
  public name: string;
  public code: string;
  public disabled?: boolean;

  constructor(name: string, code: string, disabled: boolean, id?: string) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.disabled = disabled;
  }
}

export class LibraryType {
  public id?: string;
  public name: string;
  public code: string;
  public disabled?: boolean;

  constructor(name: string, code: string, disabled: boolean, id?: string) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.disabled = disabled;
  }
}

export const CKEditorConfig = {
  allowedContent: true,
  autoParagraph: false,
  extraPlugins: 'richcombo,placeholder_select,doNothing,divarea',
  removePlugins: 'exportpdf',
  height: 317,
  enterMode: 2,
  toolbar: [
    {
      name: 'document',
      groups: ['mode', 'document', 'doctools', 'undo', 'basicstyles', 'cleanup', 'list', 'indent', 'blocks', 'align'],
      items: [
        'Source', '-', 'Preview', 'Undo', 'Redo', 'Bold', 'Italic', 'Underline',
        'Strike', 'Subscript', 'Superscript', 'RemoveFormat', 'NumberedList', 'BulletedList', 'Outdent',
        'Indent', 'Blockquote', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'Image', 'Table',
        'Link', 'Unlink', 'Smiley', 'SpecialChar', 'TextColor', 'BGColor', 'Maximize'
      ]
    },
    { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] }
  ]
};

const markers = [
  { text: 'ora fine prima giornata (day.endTime)', textEditor: 'day.endTime' },
  { text: 'data inizio prima giornata (day.startDate)', textEditor: 'day.startDate' },
  { text: 'giorno inizio prima giornata (day.startDateDay)', textEditor: 'day.startDateDay' },
  { text: 'mese fine prima giornata (day.startDateMonth)', textEditor: 'day.startDateMonth' },
  { text: 'ora inizio prima giornata (day.startTime)', textEditor: 'day.startTime' },
  { text: 'data fine edizione (edition.endDate)', textEditor: 'edition.endDate' },
  { text: 'data inizio edizione (edition.startDate)', textEditor: 'edition.startDate' },
  { text: 'giorno inizio edizione (edition.startDateDay)', textEditor: 'edition.startDateDay' },
  { text: 'mese inizio edizione (edition.startDateMonth)', textEditor: 'edition.startDateMonth' },
  { text: 'elenco giornate edizione (edition.allDaysAsList)', textEditor: 'edition.allDaysAsList' },
  { text: 'link alla rilevazione (links.assessment)', textEditor: 'links.assessment' },
  { text: 'link per iscrizione (links.confirmation)', textEditor: 'links.confirmation' },
  { text: 'indirizzo luogo del corso (location.fullAddress)', textEditor: 'location.fullAddress' },
  { text: 'stanza luogo del corso (location.room)', textEditor: 'location.room' },
  { text: 'piano luogo del corso (location.floor)', textEditor: 'location.floor' },
  { text: 'struttura luogo del corso (location.building)', textEditor: 'location.building' },
  { text: 'città luogo del corso (location.city)', textEditor: 'location.city' },
  { text: 'paese luogo del corso (location.country)', textEditor: 'location.country' },
  { text: 'nome luogo del corso (location.fullName)', textEditor: 'location.fullName' },
  { text: 'posto luogo del corso (location.place)', textEditor: 'location.place' },
  { text: 'zip luogo del corso (location.zip)', textEditor: 'location.zip' },
  { text: 'email del manager (manager.email)', textEditor: 'manager.email' },
  { text: 'nome del manager (manager.forename)', textEditor: 'manager.forename' },
  { text: 'num. telefono del manager (manager.primoTelUff)', textEditor: 'manager.primoTelUff' },
  { text: 'cognome del manager (manager.surname)', textEditor: 'manager.surname' },
  { text: 'titolo iniziativa (syllabus.title)', textEditor: 'syllabus.title' },
  { text: 'anno corrente (time.year)', textEditor: 'time.year' },
  { text: 'nome utente (user.forename)', textEditor: 'user.forename' },
  { text: 'cognome utente (user.surname)', textEditor: 'user.surname' },
  { text: 'firma (userInput.signature)', textEditor: 'userInput.signature' }
];

const mailTemplateMarkers = [
  ...markers,
  { text: 'conferma registrazione utente (links.emailConfirmation)', textEditor: 'links.emailConfirmation' }
];

export const CKEditorConfigInitiativeTemplateEdit = {
  allowedContent: true,
  autoParagraph: false,
  extraPlugins: 'richcombo,placeholder_select,doNothing',
  enterMode: 2,
  format_tags: 'p;gen_box;gen_bold',
  format_p: { element: 'p', name: 'p', styles: { 'font-size': '13px', color: '#333', 'background-color': 'white', 'line-height': '1.6' } },
  format_gen_box: { element: 'p', name: 'Box Generali', styles: { color: 'white', 'background-color': '#c5281c', 'font-weight': 'bold', 'padding': '10px' } },
  format_gen_bold: { element: 'p', name: 'Bold Generali', styles: { color: '#c5281c', 'font-weight': 'bold' } },
  fillEmptyBlocks: false,
  placeholder_select: {
    placeholders: markers,
    format: '${%placeholder%}'
  },
  toolbar: [
    {
      name: 'document',
      groups: ['mode', 'document', 'doctools', 'undo', 'basicstyles', 'cleanup', 'list', 'indent', 'blocks', 'align'],
      items: [
        '-', 'Undo', 'Redo', 'placeholder_select', 'Bold', 'Italic', 'Underline',
        'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList',
        'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
        'Link', 'Unlink', 'TextColor', 'BGColor'
      ]
    },
    { name: 'styles', items: ['Format', 'FontSize'] }
  ]
};

export const CKEditorConfigTemplatesPreview = {
  allowedContent: true,
  autoParagraph: false,
  extraPlugins: 'richcombo,placeholder_select,doNothing',
  enterMode: 2,
  fillEmptyBlocks: false,
  placeholder_select: {
    placeholders: mailTemplateMarkers,
    format: '${%placeholder%}'
  },
  toolbar: [
    { name: 'document', groups: ['mode', 'document', 'doctools'], items: ['Source', '-', 'Preview', 'Print', '-', 'Templates'] },
    {
      name: 'document',
      groups: ['mode', 'document', 'doctools', 'undo', 'basicstyles', 'cleanup', 'list', 'indent', 'blocks', 'align'],
      items: [
        '-', 'Undo', 'Redo', 'placeholder_select', 'Bold', 'Italic', 'Underline',
        'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList',
        'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
        'Link', 'Unlink', 'TextColor', 'BGColor'
      ]
    },
    { name: 'assets', items: ['Image', 'Table', 'Smiley'] },
    { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
    { name: 'styles', items: ['Styles', 'Font', 'FontSize'] }
  ]
};

export const CKEditorConfigTemplates = {
  allowedContent: true,
  autoParagraph: false,
  extraPlugins: 'richcombo,placeholder_select,doNothing',
  enterMode: 2,
  fillEmptyBlocks: false,
  placeholder_select: {
    placeholders: mailTemplateMarkers,
    format: '${%placeholder%}'
  },
  toolbar: [
    { name: 'document', groups: ['mode', 'document', 'doctools'], items: ['Source', '-', 'Preview', 'Print', '-', 'Templates'] },
    {
      name: 'document',
      groups: ['mode', 'document', 'doctools', 'undo', 'basicstyles', 'cleanup', 'list', 'indent', 'blocks', 'align'],
      items: [
        '-', 'Undo', 'Redo', 'placeholder_select', 'Bold', 'Italic', 'Underline',
        'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList',
        'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
        'Link', 'Unlink', 'TextColor', 'BGColor'
      ]
    },
    { name: 'assets', items: ['Image', 'Table', 'Smiley'] },
    { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
    { name: 'styles', items: ['Styles', 'Font', 'FontSize'] }
  ]
};

export const CKEditorConfigLibraryObjects = {
  allowedContent: true,
  autoParagraph: false,
  height: 317,
  enterMode: 2,
  toolbar: [
    {
      name: 'document',
      groups: ['mode', 'document', 'doctools', 'undo', 'basicstyles', 'cleanup', 'list', 'indent', 'blocks', 'align'],
      items: [
        'Undo', 'Redo', 'Bold', 'Italic', 'Underline'
      ]
    }
  ]
};

export const CKEditorConfigCoursesIdentificationCodeDesc = {
  allowedContent: true,
  autoParagraph: false,
  height: 170,
  enterMode: 2,
  toolbar: [
    {
      name: 'document',
      groups: ['mode', 'document', 'doctools', 'undo', 'basicstyles', 'cleanup', 'list', 'indent', 'blocks', 'align'],
      items: [
        'Undo', 'Redo', 'Bold', 'Italic', 'Underline'
      ]
    }
  ]
};

export class ChooseFieldProposalsSidebar {
  isOpened: boolean;
  initiativeName?: any;
  fieldName?: any;
  title: string;
  proposalId?: any;
  fieldProposalsData: any;
  confirmAction?: Function;
  currentValue?: any;
  originalValue?: any;
  get proposalsLength() {
    return this.fieldProposalsData && this.fieldProposalsData.data && this.fieldProposalsData.data.length > 2 ?
      this.fieldProposalsData.data.length - 2 : 0;
  }
  constructor() {
    this.fieldProposalsData = {
      data: []
    };
    this.isOpened = false;
  }
}

export class InitiativeUtils {
  static isOnlineInitiative(initiativeType: string): boolean {
    return this.isOnlineCourseOrEvent(initiativeType) || initiativeType === ItemTypes.ASSESSMENT;
  }

  static isWebinar(initiativeType: string): boolean {
    return initiativeType === ItemTypes.WEBINAR;
  }

  static isOnlineEvent(initiativeType: string): boolean {
    return initiativeType === ItemTypes.EVENT_ONLINE_STAGE;
  }

  static isOnlineCourseOrEvent(initiativeType: string): boolean {
    return initiativeType === ItemTypes.EVENT_ONLINE_STAGE || initiativeType === ItemTypes.COURSE_ONLINE_STAGE || initiativeType === ItemTypes.BLENDED_STAGE || initiativeType === ItemTypes.EXTERNAL_ONLINE_STAGE;
  }

  static hasLinkedLibraryItem(initiativeType: string): boolean {
    return initiativeType === ItemTypes.EVENT_ONLINE_STAGE || initiativeType === ItemTypes.COURSE_ONLINE_STAGE || initiativeType === ItemTypes.BLENDED_STAGE;
  }

  static isAssessment(initiativeType: string): boolean {
    return initiativeType === ItemTypes.ASSESSMENT;
  }

  static isBlendedStage(initiativeType: string): boolean {
    return initiativeType === ItemTypes.BLENDED_STAGE;
  }

  static isEvent(initiativeType: string): boolean {
    return initiativeType === ItemTypes.EVENT_ONLINE_STAGE || initiativeType === ItemTypes.EVENT_CLASS_STAGE;
  }

  static isCourse(initiativeType: string): boolean {
    return initiativeType === ItemTypes.COURSE_ONLINE_STAGE || initiativeType === ItemTypes.BLENDED_STAGE || initiativeType === ItemTypes.COURSE_CLASS_STAGE || initiativeType === ItemTypes.EXTERNAL_ONLINE_STAGE || initiativeType === ItemTypes.EXTERNAL_COURSE_CLASS_STAGE;
  }

  static isStage(initiativeType: string): boolean {
    return this.isAssessment(initiativeType) || this.isCourse(initiativeType) || this.isEvent(initiativeType) || this.isWebinar(initiativeType);
  }

  static isPath(initiativeType: string): boolean {
    return initiativeType === ItemTypes.COURSE_PATH;
  }

  static isCourseClassStage(initiativeType: string): boolean {
    return initiativeType === ItemTypes.COURSE_CLASS_STAGE || initiativeType === ItemTypes.EVENT_CLASS_STAGE;
  }

  static isExternalCourseClassStage(initiativeType: string): boolean {
    return initiativeType === ItemTypes.EXTERNAL_COURSE_CLASS_STAGE;
  }

  static isCourseOnlineStage(initiativeType: string): boolean {
    return initiativeType === ItemTypes.COURSE_ONLINE_STAGE || initiativeType === ItemTypes.EVENT_ONLINE_STAGE;
  }

  static isExternalOnlineStage(initiativeType: string): boolean {
    return initiativeType === ItemTypes.EXTERNAL_ONLINE_STAGE;
  }


}

export function loadInitiativeAttributes(_context: any, initiative: any) {
  _context.initiativeAdvancedSidebar = !!_context.initiativeAdvancedSidebar ? _context.initiativeAdvancedSidebar : {};

  initiative.itemAttributes.forEach((attr) => {
    if (attr.attributeType === ItemAttributeTypes.FUNDING_TYPE) {
      // Attributo relativo alla tipologia del finanziamento
      _context.initiativeAdvancedSidebar.selectedFunding = attr.attributeValue;
      initiative.fundingType = attr.attributeValue;
    } else if (attr.attributeType === TagAttributeTypes.FUNDING_CODE) {
      // Attributo relativo al codice del finanziamento
      _context.initiativeAdvancedSidebar.selectedNotice = {
        id: attr.referenceId,
        title: attr.attributeValue,
        tagType: attr.referenceType,
        originApplicationName: attr.referenceApplicationName
      }
    } else if (attr.attributeType === ItemAttributeTypes.FUNDING_MODULE_NAME && attr.attributeValue && attr.attributeValue.length) {
      // Attributo relativo al nome modulo
      _context.initiativeAdvancedSidebar.selectedModuleName = {
        id: attr.referenceId,
        title: attr.attributeValue,
        tagType: attr.referenceType,
        originApplicationName: attr.referenceApplicationName
      };
    } else if (attr.attributeType === 'OLD_SYLLABUS_REFERENCE') {
      // Verifico se l'iniziativa che sto modificando possiede la referenza ad un Syllabus vecchio, già creato, quindi facente parte di altre iniziative
      _context.isLinkedToOldSyllabus = true;
      _context.enableEditOfSyllabus = false;
    } else if (attr.attributeType === 'CREATION_PATH') {
      _context.creationPath = attr.attributeValue;
    } else if (attr.attributeType === 'ENABLE_COMMUNICATIONS_KIT_STEP') {
      // Verifico se far mostrare lo step delle comunicazioni
      _context.isCommunicationsKitEnabled = parseBoolean(attr.attributeValue);
    } else if (attr.attributeType === ItemAttributeTypes.ENABLE_REPEAT_COURSE_FOR_PRESENTS) {
      // Verifico se c'è il check su "Ripeti corso per presenti"
      _context.repeatCoursesForPresentInMonth = true;
      // Recupero il valore dei mesi
      _context.repeatCoursesForPresentAfterMonth = attr.attributeValue;
    } else if (attr.attributeType === ItemAttributeTypes.ENABLE_INVITE_THE_ABSENT_AGAIN) {
      // Verifico se c'è il check su "Invita di nuovo gli assenti"
      _context.inviteTheAbsentAgain = true;
      // Recupero il valore dei giorni
      _context.inviteTheAbsentAgainAfterDay = attr.attributeValue;
    } else if (attr.attributeType === ItemAttributeTypes.ENABLE_COURSE_CERTIFICATION) {
      // Verifico se far mostrare lo step delle comunicazioni
      _context.isCourseCertificationEnabled = parseBoolean(attr.attributeValue);
    } else if (attr.attributeType === 'ENABLE_SURVEY_STEP') {
      // Verifico se far mostrare lo step della survey
      _context.isSurveyStepEnabled = parseBoolean(attr.attributeValue);
    } else if (attr.attributeType === 'EXTERNAL_OBJECT_ID') {
      // Verifico se, al corso online, è collegato ed è stato aggiunto un oggetto Library
      if (!!_context.selectedItem) {
        _context.selectedItem.itemId = attr.attributeValue;
      }
    } else if (attr.attributeType === ItemAttributeTypes.DEFAULT_MAIL_SENDER) {
      // Verifico gli attr DEFAULT_MAIL_SENDER, DEFAULT_MAIL_SIGNATURE per pre-selezionare mittente e firma
      initiative.defaultMailSenderId = attr.referenceId;
    } else if (attr.attributeType === ItemAttributeTypes.DEFAULT_MAIL_SIGNATURE) {
      initiative.defaultSingatureTexttemplateId = attr.referenceId;
    } else if (attr.attributeType === ItemAttributeTypes.PUBLIC_EDITION) {
      initiative.isPublicEdition = parseBoolean(attr.attributeValue);
    } else if (attr.attributeType === ItemAttributeTypes.PREREGISTRATION_REQUIRED) {
      // Verifico il flag per la preiscrizione
      initiative.preRegistrationRequired = parseBoolean(attr.attributeValue);
    } else if (attr.attributeType === ItemAttributeTypes.AVAILABLE_IN_CATALOG) {
      // Verifico il flag per il catalogo
      initiative.addedToCatalog = parseBoolean(attr.attributeValue);
    } else if (attr.attributeType === ItemAttributeTypes.PRE_REGISTRATION_ENABLED) {
      // Verifico il flag per la preiscrizione
      initiative.activePreiscrition = parseBoolean(attr.attributeValue);
    } else if (attr.attributeType === ItemAttributeTypes.PRE_REGISTRATION_START_DATE) {
      // Verifico il flag per la preiscrizione data inizio
      initiative.startDatePreiscrition = attr.attributeValue;
    } else if (attr.attributeType === ItemAttributeTypes.PRE_REGISTRATION_END_DATE) {
      // Verifico il flag per la preiscrizione data fine
      initiative.endDatePreiscrition = attr.attributeValue;
    } else if (attr.attributeType === ItemAttributeTypes.PRE_REGISTRATION_WORKFLOW) {
      // Verifico il flag per la preiscrizione sul workflow
      initiative.workflowPreiscrition = attr.attributeValue;
    }
  })
}

export interface PreregistrationWorkflow extends WorkflowInstance {
  course?: Item;
  courseDuration?: number;
  user?: User;
  outcomeUser?: User;
}