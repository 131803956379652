/*
*  Lista di azioni riguardanti le blogs
*/

import { Action } from '@ngrx/store';


// Salva la lista delle COMPETENCES recuperati
export const SET_COMPETENCES = 'SET_COMPETENCES';

// Cancella un gruppo
export const DELETE_COMPETENCES = 'DELETE_NEW';

// Recupera le blogs da remoto
export const GET_COMPETENCES = 'GET_COMPETENCES';

// Recupera le blogs da remoto
export const GET_USER_COMPETENCES = 'GET_COMPETENCES';

// Setta il contatore dele blogs recuperati da remoto
export const SET_COMPETENCES_COUNT = 'SET_COMPETENCES_COUNT';

// Setta la paginazione
export const SET_PAGINATION_COMPETENCES = 'SET_PAGINATION_COMPETENCES';

// Setta la pagina corrente
export const SET_PAGE_COMPETENCES = 'SET_PAGE_COMPETENCES';

// Setta il testo (searchedText)
export const SET_COMPETENCES_TEXT_FILTER = 'SET_COMPETENCES_TEXT_FILTER';

// Setta la direzione di ordinamento delle blogs utente
export const SET_SORTING_DIR = 'SET_ORDER_DIR';

//Setta la colonna di ordinamento delle blogs utente
export const SET_SORTING_COL = 'SET_SORTING_COL';

// Setta una variabile con il numero di blogs totali per il banner
export const SET_COMPETENCES_TOTAL = 'SET_COMPETENCES_TOTAL';

// Recupera il numero di blogs totali per il banner
export const GET_COMPETENCES_TOTAL = 'GET_COMPETENCES_TOTAL';


// utenti associati ai profili di compentenza

// Salva la lista degli utenti recuperati
export const SET_USERS_IN_PROFILE = 'SET_USERS_IN_PROFILE';

// Recupera gli utenti da remoto
export const GET_USERS_IN_PROFILE = 'GET_USERS_IN_PROFILE';

// Setta il contatore degli utenti recuperati
export const SET_USERS_IN_PROFILE_COUNT = 'SET_USERS_IN_PROFILE_COUNT';

// Setta la pagina corrente
export const SET_USERS_IN_PROFILE_PAGE = 'SET_USERS__IN_PROFILEPAGE';

// Setta la paginazione
export const SET_PAGINATION_USERS_IN_PROFILE = 'SET_PAGINATION_USERS_IN_PROFILE';

// Salva l'id del profilo di competenza'
export const SET_PROFILE_ID = 'SET_PROFILE_ID';

export class SetCompetences implements Action {
  readonly type = SET_COMPETENCES;

  constructor(public payload: any[]) { }
}

export class SetUsersInProfile implements Action {
  readonly type = SET_USERS_IN_PROFILE;

  constructor(public payload: any[]) { }
}

export class DeleteCompetences implements Action {
  readonly type = DELETE_COMPETENCES;

  constructor(public payload: string) { }
}

export class GetCompetences implements Action {
  readonly type = GET_COMPETENCES;

  constructor() { }
}

export class GetUsersInProfile implements Action {
  readonly type = GET_USERS_IN_PROFILE;

  constructor() { }
}

export class SetCompetencesCount implements Action {
  readonly type = SET_COMPETENCES_COUNT;

  constructor(public payload: number) { }
}

export class SetUsersInProfileCount implements Action {
  readonly type = SET_USERS_IN_PROFILE_COUNT;

  constructor(public payload: number) { }
}

export class SetPageCompetences implements Action {
  readonly type = SET_PAGE_COMPETENCES;

  constructor(public payload: number) { }
}

export class SetUsersInProfilePage implements Action {
  readonly type = SET_USERS_IN_PROFILE_PAGE;

  constructor(public payload: number) { }
}

export class SetPaginationCompetences implements Action {
  readonly type = SET_PAGINATION_COMPETENCES;

  constructor(public payload: { fromRecord: number, numRecords: number }) { }
}

export class SetUsersInProfilePagination implements Action {
  readonly type = SET_PAGINATION_USERS_IN_PROFILE;

  constructor(public payload: { fromRecord: number, numRecords: number }) { }
}

export class SetCompetencesTextFilter implements Action {
  readonly type = SET_COMPETENCES_TEXT_FILTER;

  constructor(public payload: string) { }
}

export class SetSortingCol implements Action {
  readonly type = SET_SORTING_COL;

  constructor(public payload: string) { }
}

export class SetSortingDir implements Action {
  readonly type = SET_SORTING_DIR;

  constructor(public payload: string) { }
}

export class SetCompetencesTotal implements Action {
  readonly type = SET_COMPETENCES_TOTAL;

  constructor(public payload: number) { }
}

export class SetUsersInProfileProfileId implements Action {
  readonly type = SET_PROFILE_ID;

  constructor(public payload: string) { }
}

export type CompetencesActions = SetCompetences |
  DeleteCompetences |
  SetCompetencesCount |
  SetPaginationCompetences |
  SetPageCompetences |
  SetCompetencesTextFilter |
  GetCompetences |
  SetSortingDir |
  SetSortingCol |
  SetCompetencesTotal | SetUsersInProfile | GetUsersInProfile | SetUsersInProfileCount | SetUsersInProfileProfileId | SetUsersInProfilePage | SetUsersInProfilePagination;
