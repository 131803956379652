import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export interface CertificateItem {
  competenceId: string,
  imageSrc: string,
  title: string,
  duration: string,
  date: Date,
  dateEnd?: Date,
  level: string,
  entity: string,
  editable: boolean,
  deletable: boolean,
  engagementId?: string,
  tipology?: string,
  competenceSource?: string
}

@Component({
  selector: 'item-certificate',
  templateUrl: './item-certificate.component.html',
  styleUrls: ['./item-certificate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemCertificateComponent implements OnInit {

  _adminMode: boolean = false;
  @Input() set adminMood(value: string | boolean) {
    this._adminMode = coerceBooleanProperty(value);
  }

  @Input() certificateObject: CertificateItem;
  @Output() onShowDetail = new EventEmitter();
  @Output() onEditClicked = new EventEmitter();
  @Output() onDeleteClicked = new EventEmitter();
  @Output() onDownloadClicked = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  emitOnShowDetail() {
    this.onShowDetail.emit();
  }

  emitOnEditClicked(certificate) {
    this.onEditClicked.emit(certificate);
  }

  emitOnDeleteClicked(certificate) {
    this.onDeleteClicked.emit(certificate);
  }

  emitOnDownloadClicked(engagementId: string) {
    this.onDownloadClicked.emit(engagementId);
  }
}
