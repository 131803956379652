/*
* Wrapper di tutti i reducer dell'applicazione, ossia funzioni pure che trasformano il nostro Stato applicativo
*/

import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromProfile from '../profile/ngrx/profile.reducers';
import * as fromAuth from '../auth/ngrx/auth.reducers';
import * as fromCore from '../core/ngrx/core.reducers';
import * as fromTemplate from '../templates/ngrx/template.reducers';
import * as fromDashboard from '../dashboard/ngrx/dashboard.reducers';
import * as fromInitiative from '../initiatives/ngrx/initiative.reducers';
import * as fromLocation from '../locations/ngrx/location.reducers';
import * as fromCourseEdition from '../initiatives/ngrx/course-edition.reducers';
import * as fromGroups from '../groups/ngrx/groups.reducers';
import * as fromTeachersAccounting from '../manageTeachersAccounting/ngrx/teachers-accounting.reducers';
import * as fromTeachersUsers from '../teachers/ngrx/teachers-users.reducers';
import * as fromUsersAdmin from '../usersAdmin/ngrx/usersAdmin.reducers';
import * as fromItems from '../itemsAdmin/ngrx/items.reducers';
import * as fromSupplier from '../suppliers/ngrx/supplier.reducers';
import * as fromNews from '../news/ngrx/news.reducers';
import * as fromCompetences from '../competences/ngrx/competences.reducers';

export interface AppState {
  user: fromProfile.ProfileState;
  auth: fromAuth.AuthState;
  core: fromCore.CoreState;
}

export const reducers: ActionReducerMap<AppState> = {
  user: fromProfile.profileReducer,
  auth: fromAuth.authReducer,
  core: fromCore.coreReducer
};

// Grazie al metodo createFeatureSelector, siamo in grado di ottenere facilmente un riferimento ad una proprietà dello State. Una volta ottenuto tale riferimento,
// possiamo quindi comporlo con altri selettori che puntano a proprietà dello State di livello più basso attraversando in modo efficace il tree dello State.
// E' un feature selector, che ci torna uno slice dello State corrispondente ad AppState
export const getAuthAppState = createFeatureSelector<fromAuth.AuthState>('auth');
export const getCoreAppState = createFeatureSelector<fromCore.CoreState>('core');
export const getProfileAppState = createFeatureSelector<fromProfile.ProfileState>('user');
export const getTemplateAppState = createFeatureSelector<fromTemplate.TemplateState>('template');
export const getDashboardAppState = createFeatureSelector<fromDashboard.DashboardState>('dashboard');
export const getInitiativeAppState = createFeatureSelector<fromInitiative.InitiativeState>('initiative');
export const getLocationAppState = createFeatureSelector<fromLocation.LocationState>('location');
export const getCourseEditionAppState = createFeatureSelector<fromCourseEdition.CourseEditionState>('courseEdition');
export const getGroupsAppState = createFeatureSelector<fromGroups.GroupState>('group');
export const getTeachersAppState = createFeatureSelector<fromTeachersAccounting.ManageTeacherAccountingState>('manageTeachersAccounting');
export const getTeachersUsersAppState = createFeatureSelector<fromTeachersUsers.TeachersUsersState>('teachersUsers');
export const getNewsAppState = createFeatureSelector<fromNews.NewsState>('news');
export const getFromUsersAdminAppState = createFeatureSelector<fromUsersAdmin.UsersAdminState>('usersAdmin');
export const getItemsAppState = createFeatureSelector<fromItems.ItemsState>('itemsAdmin');
export const getCompetencesAppState = createFeatureSelector<fromCompetences.CompetencesState>('competences');
export const getSupplierAppState = createFeatureSelector<fromSupplier.SupplierState>('supplier');


// Per ottenere riferimenti alle proprietà più in basso nel tree dello State, utilizziamo il createSelector
// Selettori relativi allo state dell'auth
export const getAuthState = createSelector(
  getAuthAppState, // Passato come primo parametro affinché possiamo iniziare la nostra ricerca nello State da questo punto. Possiamo passare più State
  (state: fromAuth.AuthState) => state // Projector function è passata come argomento ai valori restituiti dei selettori indicati e nell'ordine in cui sono stato fatto
);
export const getToken = createSelector(
  getAuthState,
  fromAuth.getToken
);
// export const isAuthenticated = createSelector(
//     getAuthState,
//     fromAuth.isAuthenticated
// );

// Selettori relativi allo state dei supplier
export const getSuppliers = createSelector(
  getSupplierAppState,
  fromSupplier.getSuppliers
);
export const isFetchingSuppliers = createSelector(
  getSupplierAppState,
  fromSupplier.isFetchingSuppliers
);
export const getSuppliersCount = createSelector(
  getSupplierAppState,
  fromSupplier.getSuppliersCount
);
export const getSuppliersFromRecord = createSelector(
  getSupplierAppState,
  fromSupplier.getSuppliersFromRecord
);
export const getSuppliersNumRecords = createSelector(
  getSupplierAppState,
  fromSupplier.getSuppliersNumRecords
);
export const getSuppliersPage = createSelector(
  getSupplierAppState,
  fromSupplier.getSuppliersPage
);
export const getSupplierFilterType = createSelector(
  getSupplierAppState,
  fromSupplier.getSupplierFilterType
);
export const getSupplierTextFilter = createSelector(
  getSupplierAppState,
  fromSupplier.getSupplierTextFilter
);


// Selettori relativi allo state del core
export const getCoreState = createSelector(
  getCoreAppState,
  (state: fromCore.CoreState) => state
);

export const getAvailableCreditTypes = createSelector(
  getCoreState,
  (state: fromCore.CoreState) => state.availableCreditTypes
);

export const isAuthenticated = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.authenticated
);
export const isTaker = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.isTaker
);
export const isTeacher = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.isTeacher
);
export const isFetchingLangs = createSelector(
  getCoreAppState,
  fromCore.isFetchingLangs
);
export const areInitiativeStepsDisabled = createSelector(
  getCoreAppState,
  fromCore.areInitiativeStepsDisabled
);
export const getRedirectUrl = createSelector(
  getCoreAppState,
  fromCore.getRedirectUrl
);
export const getNotificationsCounter = createSelector(
  getCoreAppState,
  fromCore.getNotificationsCounter
);
export const getApplicationContext = createSelector(
  getCoreAppState,
  fromCore.getApplicationContext
);
export const getGlobalApplicationData = createSelector(
  getCoreAppState,
  fromCore.getGlobalApplicationData
);
export const showApplicationLoader = createSelector(
  getCoreAppState,
  fromCore.showApplicationLoader
);
export const isMainMenuSidebarOpened = createSelector(
  getCoreAppState,
  fromCore.isMainMenuSidebarOpened
);
export const getActiveTab = (tabName: string) =>
  createSelector(
    getCoreAppState,
    (state: fromCore.CoreState) => state[tabName].activeTabId
  );
export const getApplicationLang = createSelector(
  getCoreAppState,
  fromCore.getApplicationLang
);
export const getDefaultLang = createSelector(
  getCoreAppState,
  fromCore.getDefaultLang
);
export const isProfileMenuSidebarOpened = createSelector(
  getCoreAppState,
  fromCore.isProfileMenuSidebarOpened
);
export const getIsImpersonification = createSelector(
  getCoreAppState,
  fromCore.getIsImpersonification
);
export const getDebounceTime = createSelector(
  getCoreAppState,
  fromCore.getDebounceTime
);


// Selettori relativi allo state dei gruppi formazione obbligatoria
export const getGroups = createSelector(
  getGroupsAppState,
  fromGroups.getGroups
);
export const isFetchingGroups = createSelector(
  getGroupsAppState,
  fromGroups.isFetchingGroups
);
export const getGroupsCount = createSelector(
  getGroupsAppState,
  fromGroups.getGroupsCount
);
export const getGroupsFromRecord = createSelector(
  getGroupsAppState,
  fromGroups.getGroupsFromRecord
);
export const getGroupsNumRecords = createSelector(
  getGroupsAppState,
  fromGroups.getGroupsNumRecords
);
export const getGroupsPage = createSelector(
  getGroupsAppState,
  fromGroups.getGroupsPage
);
export const getGroupsTextFilter = createSelector(
  getGroupsAppState,
  fromGroups.getGroupsTextFilter
);

// Selettori relativi allo state dei docenti
export const getTeachers = createSelector(
  getTeachersAppState,
  fromTeachersAccounting.getTeachers
);
export const isFetchingTeachers = createSelector(
  getTeachersAppState,
  fromTeachersAccounting.isFetchingTeachers
);
export const getTeachersCount = createSelector(
  getTeachersAppState,
  fromTeachersAccounting.getTeachersCount
);
export const getTeachersFromRecord = createSelector(
  getTeachersAppState,
  fromTeachersAccounting.getTeachersFromRecord
);
export const getTeachersNumRecords = createSelector(
  getTeachersAppState,
  fromTeachersAccounting.getTeachersNumRecords
);
export const getTeachersPage = createSelector(
  getTeachersAppState,
  fromTeachersAccounting.getTeachersPage
);
export const getTeachersTextFilter = createSelector(
  getTeachersAppState,
  fromTeachersAccounting.getTeachersTextFilter
);
export const getTeachersSortingDir = createSelector(
  getTeachersAppState,
  fromTeachersAccounting.getSortingDir
)
export const getTeachersSortingCol = createSelector(
  getTeachersAppState,
  fromTeachersAccounting.getSortingCol
)

// Selettori relativi allo state dei docenti-utente
export const getTeacherActivitiesStartDateTime = createSelector(
  getTeachersUsersAppState,
  fromTeachersUsers.getActivitiesStartDateTime
);
export const getTeacherActivitiesEndDateTime = createSelector(
  getTeachersUsersAppState,
  fromTeachersUsers.getActivitiesEndDateTime
);
export const getTeacherActivities = createSelector(
  getTeachersUsersAppState,
  fromTeachersUsers.getActivities
);
export const isFetchingTeacherActivities = createSelector(
  getTeachersUsersAppState,
  fromTeachersUsers.isFetchingActivities
);
export const getTeacherActivitiesCount = createSelector(
  getTeachersUsersAppState,
  fromTeachersUsers.getActivitiesCount
);
export const getTeacherActivitiesFromRecord = createSelector(
  getTeachersUsersAppState,
  fromTeachersUsers.getActivitiesFromRecord
);
export const getTeacherActivitiesNumRecords = createSelector(
  getTeachersUsersAppState,
  fromTeachersUsers.getActivitiesNumRecords
);
export const getTeacherActivitiesPage = createSelector(
  getTeachersUsersAppState,
  fromTeachersUsers.getActivitiesPage
);
export const getTeacherActivitiesTab = createSelector(
  getTeachersUsersAppState,
  fromTeachersUsers.getActivitiesTab
);
export const getTeacherActivitiesSortingDir = createSelector(
  getTeachersUsersAppState,
  fromTeachersUsers.getSortingDir
)
export const getTeacherActivitiesSortingCol = createSelector(
  getTeachersUsersAppState,
  fromTeachersUsers.getSortingCol
)
// Selettori relativi allo state delle news
export const getNews = createSelector(
  getNewsAppState,
  fromNews.getNews
);
export const isFetchingNews = createSelector(
  getNewsAppState,
  fromNews.isFetchingNews
);
export const getNewsCount = createSelector(
  getNewsAppState,
  fromNews.getNewsCount
);
export const getNewsFromRecord = createSelector(
  getNewsAppState,
  fromNews.getNewsFromRecord
);
export const getNewsNumRecords = createSelector(
  getNewsAppState,
  fromNews.getNewsNumRecords
);
export const getNewsPage = createSelector(
  getNewsAppState,
  fromNews.getNewsPage
);
export const getNewsTextFilter = createSelector(
  getNewsAppState,
  fromNews.getNewsTextFilter
);
export const getSortingDir = createSelector(
  getNewsAppState,
  fromNews.getSortingDir
)
export const getSortingCol = createSelector(
  getNewsAppState,
  fromNews.getSortingCol
)
export const getNewsTotal = createSelector(
  getNewsAppState,
  fromNews.getNewsTotal
)
export const getBannerUnreadCount = createSelector(
  getNewsAppState,
  fromNews.getBannerUnreadCount
)
export const getOldUnread = createSelector(
  getNewsAppState,
  fromNews.getOldUnread
)

// Selettori relativi allo state delle persone in anagrafica
export const getUsersAdmin = createSelector(
  getFromUsersAdminAppState,
  fromUsersAdmin.getUsersAdmin
);
export const isFetchingUsersAdmin = createSelector(
  getFromUsersAdminAppState,
  fromUsersAdmin.isFetchingUsersAdmin
);
export const getUsersAdminCount = createSelector(
  getFromUsersAdminAppState,
  fromUsersAdmin.getUsersAdminCount
);
export const getUsersAdminFromRecord = createSelector(
  getFromUsersAdminAppState,
  fromUsersAdmin.getUsersAdminFromRecord
);
export const getUsersAdminNumRecords = createSelector(
  getFromUsersAdminAppState,
  fromUsersAdmin.getUsersAdminNumRecords
);
export const getUsersAdminPage = createSelector(
  getFromUsersAdminAppState,
  fromUsersAdmin.getUsersAdminPage
);
export const getUsersAdminTextFilter = createSelector(
  getFromUsersAdminAppState,
  fromUsersAdmin.getUsersAdminTextFilter
);

export const getFilteredUserAdmin = createSelector(
  getFromUsersAdminAppState,
  fromUsersAdmin.getFilteredUserAdmin
);

export const getFilteredUsersCount = createSelector(
  getFromUsersAdminAppState,
  fromUsersAdmin.getUsersAdminCount
);

export const getToBeConfirmed = createSelector(
  getFromUsersAdminAppState,
  fromUsersAdmin.getToBeConfirmed
);

export const getIsExternal = createSelector(
  getFromUsersAdminAppState,
  fromUsersAdmin.getIsExternal
);

export const getIsNotExternal = createSelector(
  getFromUsersAdminAppState,
  fromUsersAdmin.getIsNotExternal
);
export const getSocDistacco = createSelector(
  getFromUsersAdminAppState,
  fromUsersAdmin.getSocDistacco
);


// Selettori relativi allo state della dashboard
export const getDashboardIndicators = createSelector(
  getDashboardAppState,
  fromDashboard.getIndicators
);

// Selettori relativi allo state dei template
export const getTemplates = createSelector(
  getTemplateAppState,
  fromTemplate.getTemplates
);
export const isFetchingTemplates = createSelector(
  getTemplateAppState,
  fromTemplate.isFetchingTemplates
);
export const getTemplatesCount = createSelector(
  getTemplateAppState,
  fromTemplate.getTemplatesCount
);
export const getTemplatesFromRecord = createSelector(
  getTemplateAppState,
  fromTemplate.getTemplatesFromRecord
);
export const getTemplatesNumRecords = createSelector(
  getTemplateAppState,
  fromTemplate.getTemplatesNumRecords
);
export const getTemplatesPage = createSelector(
  getTemplateAppState,
  fromTemplate.getTemplatesPage
);
export const getTemplateFilterType = createSelector(
  getTemplateAppState,
  fromTemplate.getTemplateFilterType
);

// Selettori relativi allo state delle iniziative
export const getInitiatives = createSelector(
  getInitiativeAppState,
  fromInitiative.getInitiatives
);
export const isFetchingInitiatives = createSelector(
  getInitiativeAppState,
  fromInitiative.isFetchingInitiatives
);
export const getInitiativesCount = createSelector(
  getInitiativeAppState,
  fromInitiative.getInitiativesCount
);
export const getInitiativesFromRecord = createSelector(
  getInitiativeAppState,
  fromInitiative.getInitiativesFromRecord
);
export const getInitiativesNumRecords = createSelector(
  getInitiativeAppState,
  fromInitiative.getInitiativesNumRecords
);
export const getInitiativesPage = createSelector(
  getInitiativeAppState,
  fromInitiative.getInitiativesPage
);
export const getSelectedInitiativeItem = createSelector(
  getInitiativeAppState,
  fromInitiative.getSelectedInitiativeItem
);
export const getPathItemOfSelectedInitiative = createSelector(
  getInitiativeAppState,
  fromInitiative.getPathItemOfSelectedInitiative
);
export const getWizardStepOfSelectedInitiative = createSelector(
  getInitiativeAppState,
  fromInitiative.getWizardCurrentStep
);
export const getInitiativeFilterType = createSelector(
  getInitiativeAppState,
  fromInitiative.getInitiativesFilterType
);
export const getInitiativeStatusType = createSelector(
  getInitiativeAppState,
  fromInitiative.getInitiativesStatusType
);
export const getInitiativesTextFilter = createSelector(
  getInitiativeAppState,
  fromInitiative.getInitiativesTextFilter
);
export const getInitiativesLocations = createSelector(
  getInitiativeAppState,
  fromInitiative.getInitiativesLocations
);
export const getInitiativesArguments = createSelector(
  getInitiativeAppState,
  fromInitiative.getInitiativesArguments
);
export const getInitiativesStreams = createSelector(
  getInitiativeAppState,
  fromInitiative.getInitiativesStreams
);

export const getInitiativesAdmin = createSelector(
  getInitiativeAppState,
  fromInitiative.getInitiativesAdmin
);

export const getInitiativesSapCode = createSelector(
  getInitiativeAppState,
  fromInitiative.getInitiativesSapCode
);

export const getVplErrorMessage = createSelector(
  getInitiativeAppState,
  fromInitiative.getVplErrorMessage
);

// Selettori relativi allo state del profilo
export const getProfileState = createSelector(
  getProfileAppState,
  (state: fromProfile.ProfileState) => state
);
export const getLoggedUser = createSelector(
  getProfileState,
  fromProfile.getLoggedUser
);
export const getUserCredits = createSelector(
  getProfileState,
  fromProfile.getUserCredits
);
export const getBienniumData = createSelector(
  getProfileState,
  fromProfile.getBienniumData
);
export const getAvailableLangs = createSelector(
  getProfileState,
  fromProfile.getAvailableLangs
);

// Selettori relativi allo state di una edizione
export const getCourseEdition = createSelector(
  getCourseEditionAppState,
  fromCourseEdition.getCourseEdition
);
export const getCourseEditionOptions = createSelector(
  getCourseEditionAppState,
  fromCourseEdition.getCourseEditionOptions
);

// Selettori relativi allo state delle Locations
export const getLocations = createSelector(
  getLocationAppState,
  fromLocation.getLocations
);
export const isFetchingLocations = createSelector(
  getLocationAppState,
  fromLocation.isFetchingLocations
);
export const getLocationsCount = createSelector(
  getLocationAppState,
  fromLocation.getLocationsCount
);
export const getLocationsFromRecord = createSelector(
  getLocationAppState,
  fromLocation.getLocationsFromRecord
);
export const getLocationsNumRecords = createSelector(
  getLocationAppState,
  fromLocation.getLocationsNumRecords
);
export const getLocationsPage = createSelector(
  getLocationAppState,
  fromLocation.getLocationsPage
);
export const getLocationFilterText = createSelector(
  getLocationAppState,
  fromLocation.getLocationFilterText
);



// Selettori relativi allo state degli item
export const getItemList = createSelector(
  getItemsAppState,
  fromItems.getItemList
);
export const isFetchingItemList = createSelector(
  getItemsAppState,
  fromItems.isFetchingItemList
);
export const excludeContainerInSearch = createSelector(
  getItemsAppState,
  fromItems.excludeContainerInSearch
);
export const getItemListCount = createSelector(
  getItemsAppState,
  fromItems.getItemListCount
);
export const getItemListFromRecord = createSelector(
  getItemsAppState,
  fromItems.getItemListFromRecord
);
export const getItemListNumRecords = createSelector(
  getItemsAppState,
  fromItems.getItemListNumRecords
);
export const getCardCovers = createSelector(
  getItemsAppState,
  fromItems.getCardCovers
);
export const getItemListPage = createSelector(
  getItemsAppState,
  fromItems.getItemListPage
);
export const getItemListFilterType = createSelector(
  getItemsAppState,
  fromItems.getItemListFilterType
);
export const getItemListTextFilter = createSelector(
  getItemsAppState,
  fromItems.getItemListTextFilter
);
export const getAddPublicVisibilityOnCreation = createSelector(
  getItemsAppState,
  fromItems.getAddPublicVisibilityOnCreation
);
export const getItemListShowFor = createSelector(
  getItemsAppState,
  fromItems.getItemListShowFor
);



//selettori relativi ai profili di competenze (solo admin)
export const getCompetences = createSelector(
  getCompetencesAppState,
  fromCompetences.getCompetences
);
export const isFetchingCompetences = createSelector(
  getCompetencesAppState,
  fromCompetences.isFetchingCompetences
);
export const getCompetencesCount = createSelector(
  getCompetencesAppState,
  fromCompetences.getCompetencesCount
);
export const getCompetencesFromRecord = createSelector(
  getCompetencesAppState,
  fromCompetences.getCompetencesFromRecord
);
export const getCompetencesNumRecords = createSelector(
  getCompetencesAppState,
  fromCompetences.getCompetencesNumRecords
);
export const getCompetencesPage = createSelector(
  getCompetencesAppState,
  fromCompetences.getCompetencesPage
);
export const getCompetencesTextFilter = createSelector(
  getCompetencesAppState,
  fromCompetences.getCompetencesTextFilter
);


//selettori relativi agli utenti associati ai profili di competenze
export const getUsersInProfile = createSelector(
  getCompetencesAppState,
  fromCompetences.getUsersInProfile
);
export const getUsersInProfileCount = createSelector(
  getCompetencesAppState,
  fromCompetences.getUsersInProfileCount
);
export const getUsersInProfileFromRecord = createSelector(
  getCompetencesAppState,
  fromCompetences.getUsersInProfileFromRecord
);
export const getUsersInProfileNumRecords = createSelector(
  getCompetencesAppState,
  fromCompetences.getUsersInProfileNumRecords
);
export const getUsersInProfilePage = createSelector(
  getCompetencesAppState,
  fromCompetences.getUsersInProfilePage
);
export const getUsersInProfileProfileId = createSelector(
  getCompetencesAppState,
  fromCompetences.getUsersInProfileProfileId
);
export const isFetchingUsersInProfileCompetence = createSelector(
  getCompetencesAppState,
  fromCompetences.isFetchingUsersInProfileCompetence
);
