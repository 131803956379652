import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { switchMap, catchError, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DashboardService } from '../../services/dashboard-home.service';
import { KeyValue, SenecaResponse } from 'src/cm2-commonclasses';
import { BaseSubscriberComponent } from 'src/app/shared/components/base-subscriber.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AvailableCreditTypesService } from 'src/app/core/services/available-credit-types.service';

@Component({
  selector: 'section-filters',
  templateUrl: './section-filters.component.html',
  styleUrls: ['./section-filters.component.scss']
})
export class SectionFiltersButtonComponent extends BaseSubscriberComponent implements OnInit {
  @Input() dashboardFilters;
  @Input() isManager?: boolean;
  @Input() doneItemsDateRafunctionListnges;
  @Input() isFiltersSectionVisible;
  @Input() dashboardFiltersTmp;
  @Input() sessionStorageFilters;
  @Input() workflowFilters: boolean = false;
  @Input() creditsFilters: boolean = false;
  @Input() surveyFilters: boolean = false;

  @Output() onItemsDateRangeChanged: EventEmitter<any> = new EventEmitter();
  @Output() onFunctionSelected: EventEmitter<any> = new EventEmitter();
  @Output() onCompanySelected: EventEmitter<any> = new EventEmitter();

  @Output() onDepartmentSelected: EventEmitter<any> = new EventEmitter();
  @Output() onServizioSelected: EventEmitter<any> = new EventEmitter();
  @Output() onDivisionSelected: EventEmitter<any> = new EventEmitter();
  @Output() onNucleoSelected: EventEmitter<any> = new EventEmitter();
  @Output() onSegretarialeSelected: EventEmitter<any> = new EventEmitter();
  @Output() onOrganizzativaSelected: EventEmitter<any> = new EventEmitter();
  @Output() onCreditsSelected: EventEmitter<any> = new EventEmitter();
  @Output() onUserSelected: EventEmitter<any> = new EventEmitter();
  @Output() onInitiativeSelected: EventEmitter<any> = new EventEmitter();
  @Output() onStartDateChange: EventEmitter<any> = new EventEmitter();
  @Output() onEndDateChange: EventEmitter<any> = new EventEmitter();

  @Output() toggleFiltersSection: EventEmitter<any> = new EventEmitter();
  @Output() applyFilters: EventEmitter<any> = new EventEmitter();

  functionList: any[];
  isLoadingFunctionList: boolean;
  functionsLoaded: number;
  functionsSrvSubject;
  companyList: any[];
  isLoadingCompanyList: boolean;
  companiesLoaded: number;
  companiesSrvSubject;
  doneItemsDateRanges;
  // controlli permessi filtri
  canFilterByChief: boolean = false;
  canFilterBySocDistacco: boolean = false;
  canFilterByDipartimento: boolean = false;
  canFilterByServizio: boolean = false;
  canFilterByDivisione: boolean = false;
  canFilterByNucleo: boolean = false;
  canFilterByUnitaSegretariale: boolean = false;
  canFilterByUnitaOrganizzativa: boolean = false;
  canFilterByCrediti: boolean = false;


  departmentList: any[];
  isLoadingDepartmentList: boolean;
  departmentsLoaded: number;
  departmentsSrvSubject;

  serviziLoaded: number;
  serviziList: any[];
  serviziSrvSubject;
  isLoadingServiziList: boolean;

  divisionLoaded: number;
  divisionList: any[];
  divisionSrvSubject;
  isLoadingDivisionList: boolean;

  nucleoLoaded: number;
  nucleoList: any[];
  nucleoSrvSubject;
  isLoadingNucleoList: boolean;

  segretarialeLoaded: number;
  segretarialeList: any[];
  segretarialeSrvSubject;
  isLoadingSegretarialeList: boolean;
  organizzativaLoaded: any;
  orgenizzativaList: any;
  isLoadingOrganizzativa: boolean;
  organizzativaSrvSubject: any;
  departmentsSearchedText: string;
  resetDepartmentsResultList: boolean;
  serviziSearchedText: string;
  resetServiziResultList: boolean;
  divisionSearchedText: string;
  resetDivisionResultList: boolean;
  nucleoSearchedText: string;
  resetNucleoResultList: boolean;
  segreterialeSearchedText: string;
  resetSegreterialeResultList: boolean;
  organizzativaSearchedText: string;
  resetOrganizzativaResultList: boolean;

  isLoadingUserList: boolean;
  userList: any[];
  userLoaded: number;
  userSrvSubject: Subject<any>;

  isLoadingInitiativeList: boolean;
  initiativeList: any[];
  initiativeSrvSubject: Subject<any>;
  initiativeLoaded: number;
  initiativeText: string;

  isLoadingCredits: boolean;
  creditsList: any;

  teamLoaded: number;
  teamList: any[];
  teamSrvSubject;
  isLoadingTeamList: boolean;
  teamSearchedText: string = '';
  resetTeamList: boolean;
  @Output() onTeamSelected: EventEmitter<any> = new EventEmitter();

  tipologiaContrattoLoaded: number;
  tipologiaContrattoList: any[];
  tipologiaContrattoSrvSubject;
  isLoadingTipologiaContrattoList: boolean;
  tipologiaContrattoSearchedText: string = '';
  resetTipologiaContrattoList: boolean;
  @Output() onTipologiaContrattoSelected: EventEmitter<any> = new EventEmitter();

  industryLoaded: number;
  industryList: any[];
  industrySrvSubject;
  industrySearchedText: string = '';
  isLoadingIndustryList: boolean;
  resetIndustryList: boolean;
  @Output() onIndustrySelected: EventEmitter<any> = new EventEmitter();

  constructor(
    private dashboardService: DashboardService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private availableCreditTypes: AvailableCreditTypesService
  ) {
    super();
    this.canFilterByChief = environment.adminDashboardFilterChief;
    this.canFilterBySocDistacco = environment.adminDashboardFilterSocDistacco;
    this.canFilterByDipartimento = environment.adminDashboardFilterDipartimento;
    // Specifici di bdi
    // this.canFilterByServizio = environment.adminDashboardFilterServizio;
    // this.canFilterByDivisione = environment.adminDashboardFilterDivisione;
    this.canFilterByServizio = false;
    this.canFilterByDivisione = false;
    this.canFilterByNucleo = environment.adminDashboardFilterNucleo;
    this.canFilterByUnitaSegretariale = environment.adminDashboardFilterSegretariale;
    this.canFilterByUnitaOrganizzativa = environment.adminDashboardFilterOrganizzativa;
    this.canFilterByCrediti = environment.adminDashboardFilterCrediti;
  }

  clearDepartmentsResults() {
    this.departmentsLoaded = 0;
    this.departmentList = [];
  }

  clearServiziResults() {
    this.serviziLoaded = 0;
    this.serviziList = [];
  }

  clearDivisionResults() {
    this.divisionLoaded = 0;
    this.divisionList = [];
  }

  clearNucleoResults() {
    this.nucleoLoaded = 0;
    this.nucleoList = [];
  }

  clearSegreterialeResults() {
    this.segretarialeLoaded = 0;
    this.segretarialeList = [];
  }

  clearOrganizzativaResults() {
    this.organizzativaLoaded = 0;
    this.orgenizzativaList = [];
  }

  ngOnInit() {
    this.functionsLoaded = 0;
    this.functionList = this.functionList || [];
    this.functionsSrvSubject = new Subject<any>();

    this.companiesLoaded = 0;
    this.companyList = this.companyList || [];
    this.companiesSrvSubject = new Subject<any>();

    this.departmentsLoaded = 0;
    this.departmentList = this.departmentList || [];
    this.departmentsSrvSubject = new Subject<any>();

    this.serviziLoaded = 0;
    this.serviziList = this.serviziList || [];
    this.serviziSrvSubject = new Subject<any>();

    // this.divisionLoaded = 0;
    // this.divisionList = this.divisionList || [];
    // this.divisionSrvSubject = new Subject<any>();

    this.nucleoLoaded = 0;
    this.nucleoList = this.nucleoList || [];
    this.nucleoSrvSubject = new Subject<any>();

    this.segretarialeLoaded = 0;
    this.segretarialeList = this.segretarialeList || [];
    this.segretarialeSrvSubject = new Subject<any>();

    this.organizzativaLoaded = 0;
    this.orgenizzativaList = this.orgenizzativaList || [];
    this.organizzativaSrvSubject = new Subject<any>();

    this.teamLoaded = 0;
    this.teamList = this.teamList || [];
    this.teamSrvSubject = new Subject<any>();

    this.tipologiaContrattoLoaded = 0;
    this.tipologiaContrattoList = this.teamList || [];
    this.tipologiaContrattoSrvSubject = new Subject<any>();

    this.industryLoaded = 0;
    this.industryList = this.teamList || [];
    this.industrySrvSubject = new Subject<any>();

    // Controlla i permessi per filtrare per chief (o Funzione)
    if (this.canFilterByChief) {
      this.functionsSrvSubject.pipe(
        switchMap(
          (text: string) => {
            // Se richiesto, resetto i dati
            this.isLoadingFunctionList = true;
            return this.dashboardService.listDistinctUsersField(this.functionsLoaded, 10, 'chief', null, null, this.isManager);
          }
        )
        , catchError((err, caught) => {
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return caught;
        })
        , takeUntil(this.unsubscribe$)
      )
        .subscribe({
          next: (eventData: SenecaResponse<Array<string | KeyValue<string>>>) => {
            if (eventData && eventData.response && eventData.response.length) {
              this.functionsLoaded += eventData.response.length;
              this.functionList = this.functionList.concat(eventData.response);
            }

            this.isLoadingFunctionList = false;
          },
          error: (err: any) => {
            this.isLoadingFunctionList = false;
          }
        });
      this.functionsSrvSubject.next('');
    }

    // Recupero le società di distacco se posso usarlo come filtro
    if (this.canFilterBySocDistacco) {
      this.companiesSrvSubject.pipe(
        switchMap(
          (text: string) => {
            // Se richiesto, resetto i dati
            this.isLoadingCompanyList = true;
            return this.dashboardService.listDistinctUsersField(this.companiesLoaded, 10, 'socDistacco', null, null, this.isManager);
          }
        )
        , catchError((err, caught) => {
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return caught;
        })
        , takeUntil(this.unsubscribe$)
      )
        .subscribe({
          next: (eventData: SenecaResponse<Array<string | KeyValue<string>>>) => {
            if (eventData && eventData.response && eventData.response.length) {
              this.companiesLoaded += eventData.response.length;
              this.companyList = this.companyList.concat(eventData.response);
            }
            this.isLoadingCompanyList = false;
          },
          error: (err: any) => {
            this.isLoadingCompanyList = false;
          }
        });

      this.companiesSrvSubject.next('');
    }

    // Filtri per dipartimento
    if (this.canFilterByDipartimento) {
      this.departmentsSrvSubject.pipe(
        switchMap(
          (text: string) => {
            // Se richiesto, resetto i dati
            if (text !== this.departmentsSearchedText) {
              this.resetDepartmentsResultList = true;
              this.departmentsLoaded = 0;
            } else {
              this.resetDepartmentsResultList = false;
            }
            // Salvo il valore della ricerca testuale
            this.departmentsSearchedText = text || '';
            this.isLoadingDepartmentList = true;
            return this.dashboardService.listDistinctUsersField(this.departmentsLoaded, 10, 'ramo', null, text, this.isManager);
          }
        )
        , catchError((err, caught) => {
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return caught;
        })
        , takeUntil(this.unsubscribe$)
      )
        .subscribe({
          next: (eventData: SenecaResponse<Array<string | KeyValue<string>>>) => {
            if (this.resetDepartmentsResultList) {
              this.clearDepartmentsResults();
            }
            if (eventData && eventData.response && eventData.response.length) {
              this.departmentsLoaded += eventData.response.length;
              this.departmentList = this.departmentList.concat(eventData.response);
            }
            this.isLoadingDepartmentList = false;
          },
          error: (err: any) => {
            this.isLoadingDepartmentList = false;
          }
        });

      this.departmentsSrvSubject.next('');
    }

    // Filtri per servizio
    if (this.canFilterByServizio) {
      this.serviziSrvSubject.pipe(
        switchMap(
          (text: string) => {
            // Se richiesto, resetto i dati
            if (text !== this.serviziSearchedText) {
              this.resetServiziResultList = true;
              this.serviziLoaded = 0;
            } else {
              this.resetServiziResultList = false;
            }
            // Salvo il valore della ricerca testuale
            this.serviziSearchedText = text || '';
            this.isLoadingServiziList = true;
            return this.dashboardService.listDistinctUsersField(this.serviziLoaded, 10, 'nomeServizio', null, text, this.isManager);
          }
        )
        , catchError((err, caught) => {
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return caught;
        })
        , takeUntil(this.unsubscribe$)
      )
        .subscribe({
          next: (eventData: SenecaResponse<Array<string | KeyValue<string>>>) => {
            if (this.resetServiziResultList) {
              this.clearServiziResults();
            }
            if (eventData && eventData.response && eventData.response.length) {
              this.serviziLoaded += eventData.response.length;
              this.serviziList = this.serviziList.concat(eventData.response);
            }
            this.isLoadingServiziList = false;
          },
          error: (err: any) => {
            this.isLoadingServiziList = false;
          }
        });

      this.serviziSrvSubject.next('');
    }

    // Filtri per divisione
    if (this.canFilterByDivisione) {
      this.divisionSrvSubject.pipe(
        switchMap(
          (text: string) => {
            // Se richiesto, resetto i dati
            if (text !== this.divisionSearchedText) {
              this.resetDivisionResultList = true;
              this.divisionLoaded = 0;
            } else {
              this.resetDivisionResultList = false;
            }
            // Salvo il valore della ricerca testuale
            this.divisionSearchedText = text || '';
            this.isLoadingDivisionList = true;
            return this.dashboardService.listDistinctUsersField(this.divisionLoaded, 10, 'nomeDivisione', null, text, this.isManager);
          }
        )
        , catchError((err, caught) => {
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return caught;
        })
        , takeUntil(this.unsubscribe$)
      )
        .subscribe({
          next: (eventData: SenecaResponse<Array<string | KeyValue<string>>>) => {
            if (this.resetDivisionResultList) {
              this.clearDivisionResults();
            }
            if (eventData && eventData.response && eventData.response.length) {
              this.divisionLoaded += eventData.response.length;
              this.divisionList = this.divisionList.concat(eventData.response);
            }
            this.isLoadingDivisionList = false;
          },
          error: (err: any) => {
            this.isLoadingDivisionList = false;
          }
        });

      this.divisionSrvSubject.next('');
    }

    // Filtri per nucleo
    if (false) { // forzata per facile this.canFilterByNucleo
      this.nucleoSrvSubject.pipe(
        switchMap(
          (text: string) => {
            // Se richiesto, resetto i dati
            if (text !== this.nucleoSearchedText) {
              this.resetNucleoResultList = true;
              this.nucleoLoaded = 0;
            } else {
              this.resetNucleoResultList = false;
            }
            // Salvo il valore della ricerca testuale
            this.nucleoSearchedText = text || '';
            this.isLoadingNucleoList = true;
            return this.dashboardService.listDistinctUsersField(this.nucleoLoaded, 10, 'nomeAreaRepartoSettore', null, text, this.isManager);
          }
        )
        , catchError((err, caught) => {
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return caught;
        })
        , takeUntil(this.unsubscribe$)
      )
        .subscribe({
          next: (eventData: SenecaResponse<Array<string | KeyValue<string>>>) => {
            if (this.resetNucleoResultList) {
              this.clearNucleoResults();
            }
            if (eventData && eventData.response && eventData.response.length) {
              this.nucleoLoaded += eventData.response.length;
              this.nucleoList = this.nucleoList.concat(eventData.response);
            }
            this.isLoadingNucleoList = false;
          },
          error: (err: any) => {
            this.isLoadingNucleoList = false;
          }
        });

      this.nucleoSrvSubject.next('');
    }

    // Filtri per unità segretariale
    if (false) { // forzata per facile this.canFilterByUnitaSegretariale
      this.segretarialeSrvSubject.pipe(
        switchMap(
          (text: string) => {
            // Se richiesto, resetto i dati
            if (text !== this.segreterialeSearchedText) {
              this.resetSegreterialeResultList = true;
              this.segretarialeLoaded = 0;
            } else {
              this.resetSegreterialeResultList = false;
            }
            // Salvo il valore della ricerca testuale
            this.segreterialeSearchedText = text || '';
            this.isLoadingSegretarialeList = true;
            return this.dashboardService.listDistinctUsersField(this.segretarialeLoaded, 10, 'nomeUnitaSegretariale', 'codiceUnitaSegretariale', text, this.isManager);
          }
        )
        , catchError((err, caught) => {
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return caught;
        })
        , takeUntil(this.unsubscribe$)
      )
        .subscribe({
          next: (eventData: SenecaResponse<Array<string | KeyValue<string>>>) => {
            if (this.resetSegreterialeResultList) {
              this.clearSegreterialeResults();
            }
            if (eventData && eventData.response && eventData.response.length) {
              this.segretarialeLoaded += eventData.response.length;
              this.segretarialeList = this.segretarialeList.concat(eventData.response);
            }
            this.isLoadingSegretarialeList = false;
          },
          error: (err: any) => {
            this.isLoadingSegretarialeList = false;
          }
        });

      this.segretarialeSrvSubject.next('');
    }

    // Filtri per dipartimento
    if (false) { // forzata per facile this.canFilterByUnitaOrganizzativa
      this.organizzativaSrvSubject.pipe(
        switchMap(
          (text: string) => {
            // Se richiesto, resetto i dati
            if (text !== this.organizzativaSearchedText) {
              this.resetDepartmentsResultList = true;
              this.organizzativaLoaded = 0;
            } else {
              this.resetOrganizzativaResultList = false;
            }
            // Salvo il valore della ricerca testuale
            this.organizzativaSearchedText = text || '';
            this.isLoadingOrganizzativa = true;
            return this.dashboardService.listDistinctUsersField(this.organizzativaLoaded, 10, 'nomeUnitaOrganizzativa', 'codiceUnitaOrganizzativa', text, this.isManager);
          }
        )
        , catchError((err, caught) => {
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return caught;
        })
        , takeUntil(this.unsubscribe$)
      )
        .subscribe({
          next: (eventData: SenecaResponse<any>) => {
            if (this.resetOrganizzativaResultList) {
              this.clearOrganizzativaResults();
            }
            if (eventData && eventData.response && eventData.response.length) {
              this.organizzativaLoaded += eventData.response.length;
              this.orgenizzativaList = eventData.response;
            }
            this.isLoadingOrganizzativa = false;
          },
          error: (err: any) => {
            this.isLoadingOrganizzativa = false;
          }
        });

      this.organizzativaSrvSubject.next('');
    }

    // Filtri crediti
    if (this.canFilterByCrediti && this.creditsFilters) {
      this.isLoadingCredits = true;
      this.availableCreditTypes.getTranslatedAvailableCreditTypes(this.translate).pipe().subscribe(translatedCreditTypeList => {
        let idx = 0;
        const creditList = [];
        for (const [key, translation] of Object.entries(translatedCreditTypeList)) {
          const translationToken = key.split('.').pop();
          creditList.push({
            id: idx++,
            name: translationToken,
            label: translation
          })
        }
        this.creditsList = creditList;
        this.isLoadingCredits = false;
      })
    }


    // Controlla i permessi per filtrare per chief (o Funzione)
    if (this.workflowFilters || this.surveyFilters) {

      if (this.workflowFilters) {
        this.userLoaded = 0;
        this.userList = this.userList || [];
        this.userSrvSubject = new Subject<any>();

        this.userSrvSubject.pipe(
          switchMap(
            (text: string) => {
              // Se richiesto, resetto i dati
              this.isLoadingUserList = true;
              if (text != '') {
                this.userLoaded = 0;
              }
              return this.dashboardService.getFilteredUserList(this.userLoaded, 10, text || '');
            }
          )
          , catchError((err, caught) => {
            // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
            return caught;
          })
          , takeUntil(this.unsubscribe$)
        )
          .subscribe({
            next: (eventData: SenecaResponse<any>) => {
              if (eventData && eventData.response && eventData.response.length) {
                this.userLoaded += eventData.response.length;
                let tmpList = [];
                if (eventData.response && eventData.response.length) {
                  for (let i = 0; i < eventData.response.length; i++) {
                    tmpList.push({
                      key: eventData.response[i].userId,
                      value: eventData.response[i].surname + ' ' + eventData.response[i].forename
                    })
                  }
                  this.userList = this.userList.concat(eventData.response);
                }
                this.userList = tmpList;
              }

              this.isLoadingUserList = false;
            },
            error: (err: any) => {
              this.isLoadingUserList = false;
            }
          });
        this.userSrvSubject.next('');
      }


      this.initiativeLoaded = 10;
      this.initiativeList = this.initiativeList || [];
      this.initiativeSrvSubject = new Subject<any>();

      // Controlla i permessi per filtrare per chief (o Funzione)
      this.initiativeSrvSubject.pipe(
        switchMap(
          (text: string) => {
            // Se richiesto, resetto i dati
            this.isLoadingInitiativeList = true;
            if (text) {
              this.initiativeText = text
            }
            else if (text === '') {
              this.initiativeText = text
              this.initiativeLoaded = 10
            }
            return this.dashboardService.getLastLastInitiatives(0, this.initiativeLoaded, this.initiativeText || '', true);
          }
        )
        , catchError((err, caught) => {
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return caught;
        })
        , takeUntil(this.unsubscribe$)
      )
        .subscribe({
          next: (eventData: SenecaResponse<any>) => {
            if (eventData && eventData.response && eventData.response.totalRowsCount) {
              let tmpList = [];
              if (eventData.response && eventData.response.rows && eventData.response.rows.length) {
                for (let i = 0; i < eventData.response.rows.length; i++) {
                  tmpList.push({
                    key: eventData.response.rows[i].itemId,
                    value: eventData.response.rows[i].title
                  })
                }
                this.initiativeList = this.initiativeList.concat(eventData.response.rows);
              }
              this.initiativeList = tmpList;
            }

            this.isLoadingInitiativeList = false;
          },
          error: (err: any) => {
            this.isLoadingInitiativeList = false;
          }
        });
      this.initiativeSrvSubject.next();

    }

    // Filtri per team
    this.teamSrvSubject.pipe(
      switchMap(
        (text: string) => {
          // Se richiesto, resetto i dati
          if (text !== this.teamSearchedText) {
            this.resetTeamList = true;
            this.teamLoaded = 0;
          } else {
            this.resetTeamList = false;
          }
          // Salvo il valore della ricerca testuale
          this.teamSearchedText = text || '';
          this.isLoadingTeamList = true;
          return this.dashboardService.listDistinctUsersField(this.teamLoaded, 10, 'team', null, text, this.isManager);
        }
      )
      , catchError((err, caught) => {
        // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
        return caught;
      })
      , takeUntil(this.unsubscribe$)
    ).subscribe({
      next: (eventData: SenecaResponse<Array<string | KeyValue<string>>>) => {
        if (this.resetTeamList) {
          this.clearTeamResults();
        }
        if (eventData && eventData.response && eventData.response.length) {
          this.teamLoaded += eventData.response.length;
          this.teamList = this.teamList.concat(eventData.response);
        }
        this.isLoadingTeamList = false;
      },
      error: (err: any) => {
        this.isLoadingTeamList = false;
      }
    });

    this.teamSrvSubject.next('');

    // Filtri per tipologiaContratto
    this.tipologiaContrattoSrvSubject.pipe(
      switchMap(
        (text: string) => {
          // Se richiesto, resetto i dati
          if (text !== this.teamSearchedText) {
            this.resetTipologiaContrattoList = true;
            this.tipologiaContrattoLoaded = 0;
          } else {
            this.resetTipologiaContrattoList = false;
          }
          // Salvo il valore della ricerca testuale
          this.tipologiaContrattoSearchedText = text || '';
          this.isLoadingTipologiaContrattoList = true;
          return this.dashboardService.listDistinctUsersField(this.tipologiaContrattoLoaded, 10, 'tipologiaContratto', null, text, this.isManager);
        }
      )
      , catchError((err, caught) => {
        // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
        return caught;
      })
      , takeUntil(this.unsubscribe$)
    ).subscribe({
      next: (eventData: SenecaResponse<Array<string | KeyValue<string>>>) => {
        if (this.resetTipologiaContrattoList) {
          this.clearTipologiaContrattoResults();
        }
        if (eventData && eventData.response && eventData.response.length) {
          this.tipologiaContrattoLoaded += eventData.response.length;
          this.tipologiaContrattoList = this.tipologiaContrattoList.concat(eventData.response);
        }
        this.isLoadingTipologiaContrattoList = false;
      },
      error: (err: any) => {
        this.isLoadingTipologiaContrattoList = false;
      }
    });

    this.tipologiaContrattoSrvSubject.next('');


    // Filtri industry
    this.industrySrvSubject.pipe(
      switchMap(
        (text: string) => {
          // Se richiesto, resetto i dati
          if (text !== this.teamSearchedText) {
            this.resetIndustryList = true;
            this.industryLoaded = 0;
          } else {
            this.resetIndustryList = false;
          }
          // Salvo il valore della ricerca testuale
          this.industrySearchedText = text || '';
          this.isLoadingIndustryList = true;
          return this.dashboardService.listDistinctUsersField(this.industryLoaded, 10, 'industry', null, text, this.isManager);
        }
      )
      , catchError((err, caught) => {
        // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
        return caught;
      })
      , takeUntil(this.unsubscribe$)
    ).subscribe({
      next: (eventData: SenecaResponse<Array<string | KeyValue<string>>>) => {
        if (this.resetIndustryList) {
          this.clearIndustryResults();
        }
        if (eventData && eventData.response && eventData.response.length) {
          this.industryLoaded += eventData.response.length;
          this.industryList = this.industryList.concat(eventData.response);
        }
        this.isLoadingIndustryList = false;
      },
      error: (err: any) => {
        this.isLoadingIndustryList = false;
      }
    });

    this.industrySrvSubject.next('');

    this.translate.get([
      'userHome.dateRanges.SIX_MONTHS',
      'userHome.dateRanges.TWELVE_MONTHS',
      'dashboard.dateRangesSummary.SIX_MONTHS',
      'dashboard.dateRangesSummary.TWELVE_MONTHS',
      'dashboard.dateRangesSummary.YEAR'
    ]).subscribe(translations => {
      this.doneItemsDateRanges = [
        { name: translations['userHome.dateRanges.SIX_MONTHS'], code: 6, nameForSummary: translations['dashboard.dateRangesSummary.SIX_MONTHS'] },
        { name: translations['userHome.dateRanges.TWELVE_MONTHS'], code: 12, nameForSummary: translations['dashboard.dateRangesSummary.TWELVE_MONTHS'] }
      ];
      // Aggiungo le annate
      for (let currentYear = new Date().getFullYear(); currentYear > 2013; currentYear--) {
        this.doneItemsDateRanges.push({ name: currentYear, code: currentYear, nameForSummary: (translations['dashboard.dateRangesSummary.YEAR'] + ' ' + currentYear), isYear: true });
      };
      // Se non ho un periodo già preimpostato, lo setto
      // if (this.dashboardFilters && !this.sessionStorageFilters) {
      //   this.dashboardFilters.selectedItemsDateRanges = this.doneItemsDateRanges && this.doneItemsDateRanges[0];
      // }
    });
  }

  // Torna il testo per il pulsante riassunto delle funzioni selezionate
  getSelectedFunctionsFilterText() {
    if (this.dashboardFilters && this.dashboardFilters.selectedFunctions && this.dashboardFilters.selectedFunctions.length) {
      let text = this.dashboardFilters.selectedFunctions.length + ' ';
      if (this.dashboardFilters.selectedFunctions.length === 1) {
        text = text + this.translate.instant('dashboard.FUNCTION');
      } else {
        text = text + this.translate.instant('dashboard.FUNCTIONS');
      }

      return text;
    };

    return;
  }

  // Torna il testo per il pulsante riassunto delle aziende selezionate
  getSelectedCompaniesFilterText() {
    if (this.dashboardFilters && this.dashboardFilters.selectedCompanies && this.dashboardFilters.selectedCompanies.length) {
      let text = this.dashboardFilters.selectedCompanies.length + ' ';
      if (this.dashboardFilters.selectedCompanies.length === 1) {
        text = text + this.translate.instant('dashboard.COMPANY');
      } else {
        text = text + this.translate.instant('dashboard.COMPANIES');
      }

      return text;
    };

    return;
  }

  // Torna il testo per il pulsante riassunto dei dipartimenti selezionati
  getSelectedDipartimentoFilterText() {
    if (this.dashboardFilters && this.dashboardFilters.selectedDipartimento && this.dashboardFilters.selectedDipartimento.length) {
      let text = this.dashboardFilters.selectedDipartimento.length + ' ';
      if (this.dashboardFilters.selectedDipartimento.length === 1) {
        text = text + this.translate.instant('dashboard.DEPARTEMENT');
      } else {
        text = text + this.translate.instant('dashboard.DEPARTEMENTS');
      }

      return text;
    };

    return;
  }

  // Torna il testo per il pulsante riassunto dei servizi selezionati
  getSelectedServizioFilterText() {
    if (this.dashboardFilters && this.dashboardFilters.selectedServizio && this.dashboardFilters.selectedServizio.length) {
      let text = this.dashboardFilters.selectedServizio.length + ' ';
      if (this.dashboardFilters.selectedServizio.length === 1) {
        text = text + this.translate.instant('dashboard.SERVICE');
      } else {
        text = text + this.translate.instant('dashboard.SERVICES');
      }

      return text;
    };

    return;
  }

  // Torna il testo per il pulsante riassunto delle divisioni selezionate
  getSelectedDivisioneFilterText() {
    if (this.dashboardFilters && this.dashboardFilters.selectedDivisione && this.dashboardFilters.selectedDivisione.length) {
      let text = this.dashboardFilters.selectedDivisione.length + ' ';
      if (this.dashboardFilters.selectedDivisione.length === 1) {
        text = text + this.translate.instant('dashboard.DIVISION');
      } else {
        text = text + this.translate.instant('dashboard.DIVISIONS');
      }

      return text;
    };

    return;
  }

  // Torna il testo per il pulsante riassunto dei nuclei selezionati
  getSelectedNucleoFilterText() {
    if (this.dashboardFilters && this.dashboardFilters.selectedNucleo && this.dashboardFilters.selectedNucleo.length) {
      let text = this.dashboardFilters.selectedNucleo.length + ' ';
      if (this.dashboardFilters.selectedNucleo.length === 1) {
        text = text + this.translate.instant('dashboard.NUCLEO');
      } else {
        text = text + this.translate.instant('dashboard.NUCLEOS');
      }

      return text;
    };

    return;
  }

  // Torna il testo per il pulsante riassunto dei segretariali selezionati
  getSelectedSegreterialeFilterText() {
    if (this.dashboardFilters && this.dashboardFilters.selectedSegreteriale && this.dashboardFilters.selectedSegreteriale.length) {
      let text = this.dashboardFilters.selectedSegreteriale.length + ' ';
      if (this.dashboardFilters.selectedSegreteriale.length === 1) {
        text = text + this.translate.instant('dashboard.SEGRETERIALE');
      } else {
        text = text + this.translate.instant('dashboard.SEGRETERIALES');
      }

      return text;
    };

    return;
  }

  // Torna il testo per il pulsante riassunto delle organizzative selezionate
  getSelectedOrganizzativaFilterText() {
    if (this.dashboardFilters && this.dashboardFilters.selectedOrganizzativa && this.dashboardFilters.selectedOrganizzativa.length) {
      let text = this.dashboardFilters.selectedOrganizzativa.length + ' ';
      if (this.dashboardFilters.selectedOrganizzativa.length === 1) {
        text = text + this.translate.instant('dashboard.ORGANIZZATIVA');
      } else {
        text = text + this.translate.instant('dashboard.ORGANIZZATIVAS');
      }

      return text;
    };

    return;
  }

  getSelectedUserFilterText() {
    if (this.dashboardFilters && this.dashboardFilters.selectedUser && this.dashboardFilters.selectedUser.length) {
      let text = this.dashboardFilters.selectedUser.length + ' ';
      if (this.dashboardFilters.selectedUser.length === 1) {
        text = text + this.translate.instant('dashboard.USER');
      } else {
        text = text + this.translate.instant('dashboard.USERS');
      }

      return text;
    };

    return;
  }

  getSelectedInitiativeFilterText() {
    if (this.dashboardFilters && this.dashboardFilters.selectedInitiatives && this.dashboardFilters.selectedInitiatives.length) {
      let text = this.dashboardFilters.selectedInitiatives.length + ' ';
      if (this.dashboardFilters.selectedInitiatives.length === 1) {
        text = text + this.translate.instant('dashboard.INITIATIVE');
      } else {
        text = text + this.translate.instant('dashboard.INITIATIVES');
      }

      return text;
    };

    return;
  }

  // Deseleziona un filtro, riavvinto la ricerca
  clearFilter(filterType) {
    if (filterType) {
      if (filterType === 'dateRange') {
        this.onItemsDateRangeChanged.emit(this.doneItemsDateRanges[0]);
      } else if (filterType === 'function') {
        this.onFunctionSelected.emit([]);
      } else if (filterType === 'company') {
        this.onCompanySelected.emit([]);
      } else if (filterType === 'dipartimento') {
        this.onDepartmentSelected.emit([]);
      } else if (filterType === 'servizio') {
        this.onServizioSelected.emit([]);
      } else if (filterType === 'divisione') {
        this.onDivisionSelected.emit([]);
      } else if (filterType === 'nucleo') {
        this.onNucleoSelected.emit([]);
      } else if (filterType === 'segretariale') {
        this.onSegretarialeSelected.emit([]);
      } else if (filterType === 'organizzativa') {
        this.onOrganizzativaSelected.emit([]);
      } else if (filterType == 'userfilter') {
        this.onUserSelected.emit([]);
      } else if (filterType == 'initiatives') {
        this.onInitiativeSelected.emit([]);
      } else if (filterType == "team") {
        this.onTeamSelected.emit([]);
      } else if (filterType == "tipologiaContratto") {
        this.onTipologiaContrattoSelected.emit([]);
      } else if (filterType == "industry") {
        this.onIndustrySelected.emit([]);
      }
      this.applyFilters.emit(true);
      this.cdr.detectChanges();
    }
  }

  emitOnFunctionSelected(data?) {
    this.onFunctionSelected.emit(data);
  }

  emitOnItemsDateRangeChanged(data?) {
    this.onItemsDateRangeChanged.emit(data);
  }

  emitOnCompanySelected(data?) {
    this.onCompanySelected.emit(data);
  }

  emitToggleFiltersSection(data?) {
    this.toggleFiltersSection.emit(data);
  }

  fetchMoreCompanies(data?) {
    this.companiesSrvSubject.next();
  }

  fetchMoreFunctions(data?) {
    this.functionsSrvSubject.next();
  }

  emitOnDepartmentSelected(data?) {
    this.onDepartmentSelected.emit(data);
  }

  fetchMoreDepartments(data?) {
    this.departmentsSrvSubject.next();
  }

  emitOnServizioSelected(data?) {
    this.onServizioSelected.emit(data);
  }

  fetchMoreServizi(data?) {
    this.serviziSrvSubject.next();
  }

  emitOnDivisionSelected(data?) {
    this.onDivisionSelected.emit(data);
  }

  fetchMoreDivisions(data?) {
    this.divisionSrvSubject.next();
  }

  emitOnNucleoSelected(data?) {
    this.onNucleoSelected.emit(data);
  }

  fetchMoreNucleos(data?) {
    this.nucleoSrvSubject.next();
  }

  emitOnSegretarialeSelected(data?) {
    this.onSegretarialeSelected.emit(data);
  }

  fetchMoreSegretariale(data?) {
    this.segretarialeSrvSubject.next();
  }

  emitOnOrganizzativaSelected(data?) {
    this.onOrganizzativaSelected.emit(data);
  }

  emitOnCreditsSelected(data?) {
    this.onCreditsSelected.emit(data);
  }

  fetchMoreOrganizzativa(data?) {
    this.organizzativaSrvSubject.next();
  }

  emitOnUserSelected(data?) {
    this.onUserSelected.emit(data);
  }

  fetchMoreUsers(data?) {
    this.userSrvSubject.next(data);
  }

  emitOnInitiativeSelected(data?) {
    this.onInitiativeSelected.emit(data);
  }

  fetchMoreInitiatives(data?) {
    if (this.initiativeLoaded <= this.initiativeList?.length) {
      this.initiativeLoaded += 10;
    }
    this.initiativeSrvSubject.next(data);
  }

  emitOnStartDateChange(data?) {
    this.onStartDateChange.emit(data);
  }
  emitOnEndDateChange(data?) {
    this.onEndDateChange.emit(data);
  }

  // Recupera altri team
  fetchMoreTeams(data?) {
    this.teamSrvSubject.next();
  }

  emitOnTeamSelected(data?) {
    this.onTeamSelected.emit(data);
  }

  clearTeamResults() {
    this.teamLoaded = 0;
    this.teamList = [];
  }

  getSelectedTeamFilterText() {
    if (this.dashboardFilters && this.dashboardFilters.selectedTeam && this.dashboardFilters.selectedTeam.length) {
      let text = this.dashboardFilters.selectedTeam.length + ' ';
      if (this.dashboardFilters.selectedTeam.length === 1) {
        text = text + this.translate.instant('dashboard.TEAM');
      } else {
        text = text + this.translate.instant('dashboard.TEAMS');
      }

      return text;
    };

    return;
  }


  // Recupera altre tipologieContratto
  fetchMoreTipologiaContratto(data?) {
    this.tipologiaContrattoSrvSubject.next();
  }

  emitOnTipologiaContrattoSelected(data?) {
    this.onTipologiaContrattoSelected.emit(data);
  }

  clearTipologiaContrattoResults() {
    this.tipologiaContrattoLoaded = 0;
    this.tipologiaContrattoList = [];
  }

  getSelectedTipologiaContrattoFilterText() {
    if (this.dashboardFilters && this.dashboardFilters.selectedTipologiaContratto && this.dashboardFilters.selectedTipologiaContratto.length) {
      let text = this.dashboardFilters.selectedTipologiaContratto.length + ' ';
      if (this.dashboardFilters.selectedTipologiaContratto.length === 1) {
        text = text + this.translate.instant('dashboard.CONTRACTUAL');
      } else {
        text = text + this.translate.instant('dashboard.CONTRACTUAL');
      }

      return text;
    };

    return;
  }

  // Recupera altre tipologieContratto
  fetchMoreIndustry(data?) {
    this.industrySrvSubject.next();
  }

  emitOnIndustrySelected(data?) {
    this.onIndustrySelected.emit(data);
  }

  clearIndustryResults() {
    this.industryLoaded = 0;
    this.industryList = [];
  }

  getSelectedIndustryFilterText() {
    if (this.dashboardFilters && this.dashboardFilters.selectedIndustry && this.dashboardFilters.selectedIndustry.length) {
      let text = this.dashboardFilters.selectedIndustry.length + ' ';
      if (this.dashboardFilters.selectedIndustry.length === 1) {
        text = text + this.translate.instant('dashboard.INDUSTRY');
      } else {
        text = text + this.translate.instant('dashboard.INDUSTRY');
      }

      return text;
    };

    return;
  }
}
