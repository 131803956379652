<!-- Sidenav di sinistra -->
<ng-sidebar-container>
  <!-- Sidebar di sinistra contente il menu principale -->
  <ng-sidebar *ngIf="!(isFetchingLangs$ | async)" [(opened)]="isMainMenuSidebarOpened" mode="over" keyClose="true"
    position="left" closeOnClickBackdrop="true" showBackdrop="true" [animate]="initialAnimateEnabled" trapFocus="true"
    autoFocus="true" sidebarClass="main-menu-sidebar" (onCloseStart)="onMainMenuSidenavClose()"
    (onOpenStart)="onProfileMenuSidenavClose()">
    <div tabindex="-1" #sidebarLeftNav aria-label="Menu Principale">
      <div class="aside-menu-header">
        <p translate="mainMenuSidenav.MAIN_MENU" class="title">
        </p>
      </div>
      <!-- Pagine -->
      <div *ngIf="showPagesTitle()" fxLayout="row" fxLayoutAlign="space-between center" class="aside-category-title"
        role="menuitem">
        <p translate="generic.PAGES">
        </p>
      </div>
      <!-- Menu admin, utenti, docenti-->
      <ng-container *ngIf="activeModule !== 'manager'">
        <button tabindex="0" *ngIf="showGoToHome()" fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : (isAdminCanManageInitiatives && isThisCurrentPage('home') || (!isAdminCanManageInitiatives && isThisCurrentPage('adminHome'))) }"
          class="item-list-menu is-button"
          (click)="redirect.goToHome(!authService.isAdminMode() && !authService.isTeacherMode(), authService.isTeacherMode())"
          role="button">
          <p translate="routing.HOME">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Library -->
        <button tabindex="0" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="showGoToLibrary()"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('library') }" class="item-list-menu is-button"
          (click)="redirect.goToLibrary()" role="button">
          <p translate="routing.LIBRARY">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Libretto formativo -->
        <button tabindex="0" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="showgoToTrainingBookletHome()"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('trainingBooklet') }" class="item-list-menu is-button"
          (click)="redirect.goToTrainingBookletHome()" role="button">
          <p translate="routing.TRAINING_BOOKLET">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Calendario -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('calendar') }" *ngIf="showGoToCalendar()"
          class="item-list-menu is-button" (click)="redirect.goToCalendar(!authService.isAdminMode())" role="button">
          <p translate="calendar.CALENDAR">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Dashboard -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('dashboard') }" *ngIf="showGoToDashboard()"
          class="item-list-menu is-button" (click)="redirect.goToDashboard()" role="button">
          <p translate="routing.DASHBOARD">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- News  -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('news') }" *ngIf="showGoToNews()"
          class="item-list-menu is-button" (click)="redirect.goToNews(!authService.isAdminMode())" role="button">
          <p translate="routing.NEWS">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Catalogo  -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('catalog') }" *ngIf="showGoToCatalog()"
          class="item-list-menu is-button" (click)="redirect.goToCatalog()" role="button">
          <p translate="routing.CATALOG">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Notifiche -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('notifications') }" *ngIf="showGoToNotifications()"
          class="item-list-menu is-button" (click)="redirect.goToNotifications(!authService.isAdminMode())"
          role="button">
          <p translate="header.NOTIFICATIONS">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>


        <!-- Oggetti library -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('itemsAdmin') }" *ngIf="showGoToLibraryItems()"
          class="item-list-menu is-button" (click)="redirect.goToItemsAdmin()" role="button">
          <p translate="routing.LIBRARY_ITEMS">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Profilo -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('profile') }" *ngIf="showGoToProfile()"
          class="item-list-menu is-button"
          (click)="redirect.goToProfile(!authService.isAdminMode() && !authService.isTeacherMode(), authService.isTeacherMode())"
          role="button">
          <p translate="routing.PROFILE">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- FAQ UTENTE -->
        <button fxLayout="row" fxLayoutAlign="space-between center" *ngIf="showUserFaq()"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('faq') }" class="item-list-menu is-button"
          (click)="redirect.goToUserFAQ()" role="button">
          <p translate="generic.faq.FAQ">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Tutte le iniziative-->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('initiatives') }" *ngIf="showGoToAllInitiatives()"
          class="item-list-menu is-button" (click)="redirect.goToInitiatives()" role="button">
          <p translate="generic.ALL_INITIATIVES">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Contabilità docenti -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('manageTeachersAccounting') }"
          *ngIf="false && isAdmin && showGoToManageTeachersAccounting()" class="item-list-menu is-button"
          (click)="redirect.goToManageTeachersAccounting()" role="button">
          <p translate="routing.MANAGE_ACCOUNTING">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <hr *ngIf="authService.isAdminMode() && showSettingsTitle()" class="aside-hr">
        <!-- Impostazioni -->
        <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="showSettingsTitle()"
          class="aside-category-title">
          <p translate="generic.SETTINGS">
          </p>
        </div>
        <!-- Gestione Tags -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('tags') }" *ngIf="isAdmin && showGoToTags()"
          class="item-list-menu is-button" (click)="redirect.goToTags()" role="button">
          <p translate="routing.TAGS">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Gestione Competenze -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('competences') }"
          *ngIf="isAdmin && showGoToCompetences()" class="item-list-menu is-button" (click)="redirect.goToCompetences()"
          role="button">
          <p translate="routing.COMPETENCES">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Gestione profili competenze -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('competenceProfiles') }"
          *ngIf="isAdmin && showGoToCompetences()" class="item-list-menu is-button"
          (click)="redirect.goToCompetencesProfiles(authService.isAdminMode())" role="button">
          <p translate="routing.COMPETENCESPROFILES">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Gestione Modelli Orari -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('scheduleModels') }"
          *ngIf="isAdmin && showGoToScheduleModels()" class="item-list-menu is-button"
          (click)="redirect.goToScheduleModels()" role="button">
          <p translate="routing.SCHEDULE_MODELS">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Fornitori -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('suppliers') }" *ngIf="isAdmin && showGoToSuppliers()"
          class="item-list-menu is-button" (click)="redirect.goToSuppliers()" role="button">
          <p translate="routing.SUPPLIERS">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Gruppi -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('groups') }" *ngIf="showGoToGroups()"
          class="item-list-menu is-button" (click)="redirect.goToListGroups()" role="button">
          <p translate="routing.GROUPS">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Aula/Luogo -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('locations') }" *ngIf="showGoToLocations()"
          class="item-list-menu is-button" (click)="redirect.goToLocations()" role="button">
          <p translate="routing.SITE">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Template -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('templates') }" *ngIf="showGoToTemplates()"
          class="item-list-menu is-button" (click)="redirect.goToTemplates()" role="button">
          <p translate="routing.TEMPLATES">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- gestione Utenti -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('usersAdmin') }" *ngIf="showGoToUsers()"
          class="item-list-menu is-button" (click)="redirect.goToUsersAdmin()" role="button">
          <p translate="routing.USERS_ADMIN">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Impersonifica -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('impersonificationAdmin') }"
          *ngIf="showGoToImpersonification() && false" class="item-list-menu is-button"
          (click)="redirect.goToImpersonificationAdmin()" role="button">
          <p translate="routing.IMPERSONIFICATION_ADMIN">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Approva iniziative -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('approvationInitiativesAdmin') }"
          *ngIf="isAdmin && showGoToManageInitiativesWorkflow()" class="item-list-menu is-button"
          (click)="redirect.goToApprovationInitiativesAdmin()" role="button">
          <p translate="routing.APPROVAL_INITIATIVES">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Approva edizioni
      <div fxLayout="row" fxLayoutAlign="space-between center"
        [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('approvationPreregistrationsAdmin') }"
        *ngIf="isAdmin && showGoToManagePreregWorkflow()" class="item-list-menu"
        (click)="redirect.goToApprovationAdmin()" role="menuitem">
        <p translate="routing.APPROVAL_EDITIONS">
        </p>
        <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
      </div> -->
        <!-- Approva preiscrizioni -->
        <button fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('approvationPreregistrationsAdmin') }"
          *ngIf="isAdmin && showGoToManagePreregWorkflow()" class="item-list-menu is-button"
          (click)="redirect.goToApprovationPreregistrationsAdmin()" role="button">
          <p translate="routing.APPROVAL_PREREGISTRATIONS">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- import/export -->
        <button fxLayout="row" fxLayoutAlign="space-between center" *ngIf="showGoToImportExport()"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('importExport/manageImportExport') }"
          (click)="redirect.goToImportExport()" class="item-list-menu is-button" role="button">
          <p translate="routing.IMPORT_EXPORT">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- FAQ ADMIN -->
        <button fxLayout="row" fxLayoutAlign="space-between center" *ngIf="showGoToFaq()" (click)="redirect.goToFAQ()"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('faq') }" class="item-list-menu is-button"
          role="button">
          <p translate="routing.FAQ">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
      </ng-container>
      <!-- Menu manager -->
      <ng-container *ngIf="activeModule == 'manager'">
        <!-- -->
        <button *ngIf="showGoToApprovals()" fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('home') }" class="item-list-menu is-button"
          (click)="redirect.goToHome(false,false,true)" role="button">
          <p translate="routing.APPROVAL">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Deleghe -->
        <button *ngIf="showGoToDelegations()" fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('delegation') }" class="item-list-menu is-button"
          (click)="redirect.goToDelegations()" role="button">
          <p translate="routing.DELEGATIONS">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Calendario manager -->
        <button *ngIf="showGoToManagerCalendar()" fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('calendar') }" class="item-list-menu is-button"
          (click)="redirect.goToManagerCalendar()" role="button">
          <p translate="routing.CALENDAR">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Dashboard manager -->
        <button *ngIf="showGoToManagerDashboard()" fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{ 'is-in-current-page' : isThisCurrentPage('dashboard') }" class="item-list-menu is-button"
          (click)="redirect.goToManagerDashboard()" role="button">
          <p translate="routing.DASHBOARD">
          </p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>


      </ng-container>
      <!-- Cambia ruolo -->
      <ng-container *ngIf="canChangeRole()">
        <hr class="aside-hr">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="aside-category-title">
          <p translate="generic.CHANGE_ROLE">
          </p>
        </div>
        <!-- Admin -->
        <button *ngIf="isAdmin" fxLayout="row" fxLayoutAlign="space-between center" class="item-list-menu is-button"
          [ngClass]="{ 'is-in-current-page' : activeModule == 'admin' }" (click)="switchProfileMode('admin')"
          role="button">
          <p translate="home.ADMIN"></p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Manager -->
        <button fxLayout="row" fxLayoutAlign="space-between center" class="item-list-menu is-button" *ngIf="isManager()"
          [ngClass]="{ 'is-in-current-page' : activeModule == 'manager' }" (click)="switchProfileMode('manager')"
          role="button">
          <p translate="home.MANAGER"></p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Utente -->
        <button fxLayout="row" fxLayoutAlign="space-between center" class="item-list-menu is-button"
          [ngClass]="{ 'is-in-current-page' : activeModule == 'takers' }" (click)="switchProfileMode('takers')"
          role="button">
          <p translate="home.USER"></p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
        <!-- Docente -->
        <button fxLayout="row" fxLayoutAlign="space-between center" class="item-list-menu is-button"
          *ngIf="canAccessTeacherMode && isTeacher" [ngClass]="{ 'is-in-current-page' : activeModule == 'teacher' }"
          (click)="switchProfileMode('teacher')" role="button">
          <p translate="home.TEACHER"></p>
          <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
        </button>
      </ng-container>
      <hr class="aside-hr">
      <!-- Logout -->
      <button fxLayout="row" fxLayoutAlign="space-between center" class="item-list-menu is-button" (click)="onLogout()"
        role="button">
        <p translate="header.LOGOUT"></p>
        <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
      </button>
    </div>
  </ng-sidebar>

  <!-- Sidebar di destra contenente le impostazioni di profilo (notifiche, impostazioni, logout) -->
  <ng-sidebar *ngIf="!(isFetchingLangs$ | async)" [(opened)]="isProfileMenuSidebarOpened" mode="over" keyClose="true"
    position="right" closeOnClickBackdrop="true" showBackdrop="true" [animate]="initialAnimateEnabled" trapFocus="true"
    autoFocus="true" sidebarClass="profile-menu-sidebar" role="menu" (onCloseStart)="onProfileMenuSidenavClose()"
    (onOpenStart)="onMainMenuSidenavClose()">
    <div *ngIf="loggedUser && loggedUser.user" role="menuitem" class="margin-top16">
      <img class="img-avatar-coursemanager-home" [src]="avatar"
        alt="{{'accessibilityAttributes.AVATAR_IMAGE_USER' | translate}}"
        title="{{'accessibilityAttributes.AVATAR_IMAGE_USER' | translate}}">
      <div class="wrapper-name-role truncate">
        <p class="user-name margin0">{{ loggedUser.user.forename }} {{ loggedUser.user.surname }}</p>
        <p *ngIf="loggedUser.user.jobFamilyPrimaria" class="user-role margin0">{{ loggedUser.user.jobFamilyPrimaria }}
        </p>
      </div>
    </div>
    <hr>
    <div fxLayout="row" fxLayoutAlign="space-between center"
      *ngIf="!authService.isAdminMode() && canAccessUserMode && canAccessUserProfile" class="item-list-menu"
      (click)="redirect.goToProfile(!authService.isAdminMode() && !authService.isTeacherMode(), authService.isTeacherMode())"
      role="menuitem">
      <p translate="routing.PROFILE">
      </p>
      <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="item-list-menu" role="menuitem"
      (click)="onLogout()">
      <p translate="header.LOGOUT">
      </p>
      <svg-icon src="assets/icons/icon-caret-right.svg"></svg-icon>
    </div>
  </ng-sidebar>
  <div ng-sidebar-content>
    <app-header
      *ngIf="isAuthenticated && !(isFetchingLangs$ | async) && !isThisCurrentPage('coursePrivacy') && !isThisCurrentPage('resetPassword')"
      [currentModule]="activeModule" (activateAnimation)="activateAnimation()"></app-header>
    <!-- Banner se si sta impersonificando qualcuno -->
    <div fxLayout="row"
      *ngIf="!(isFetchingLangs$ | async) && !showApplicationLoader && loggedUser && isImpersonificationBanner"
      fxLayoutAlign="center center" class="impersonificationBanner">
      <p>{{'generic.YOU_ARE_IMPERSONATING' | translate}} {{loggedUser.user.forename}} {{loggedUser.user.surname}}</p>
    </div>
    <router-outlet *ngIf="!(isFetchingLangs$ | async) && !showApplicationLoader">
    </router-outlet>
    <!-- Footer -->
    <lib-alloy-footer *ngIf="!(isFetchingLangs$ | async) && !showApplicationLoader && isAuthenticated"
      [leftColumnTitle]="'takerFooter.FACILE_LEARNING' | translate"
      [leftColumnFirstHint]="'takerFooter.FIRST_HINT' | translate"
      [leftColumnSecondHint]="'takerFooter.SECOND_HINT' | translate"
      [rightColumnTitle]="'takerFooter.SUPPORT' | translate" [firstBtnLabel]="'takerFooter.TUTORIAL' | translate"
      [secondBtnLabel]="'takerFooter.VIDEO' | translate" 
      [supportHint]="'takerFooter.DO_YOU_NEED_TECH_SUPPORT' | translate" [supportMail]="'solutions@alloy.it'"
      [showCompanyRow]="true" [companyLogoSrc]="'../../assets/icons/logo_white.svg'" [companyLogoHeight]="35"
      [companyLogoTitle]="'logo'">
    </lib-alloy-footer>
  </div>
</ng-sidebar-container>
<!-- Loader dell'applicazione, del caricamento delle pagine e del recupero delle lingue disponibili, o ogniqualvolta il valore "showApplicationLoader " nello Store è vero -->
<div *ngIf="(isFetchingLangs$ | async) || showApplicationLoader" class="app-loading">
  <div class="logo"></div>
</div>