<div fxLayout="row" fxFill fxLayoutAlign="center center">
  <div fxLayout="column" fxFill fxLayoutAlign="center center" *ngIf="scormUrl">
    <iframe height="100%" id="scormPlayerIFrame" width="100%" class="contentPlayerIFrame" frameborder="0"
      allowfullscreen [src]="scormUrl | safeHtml: 'resourceUrl'"></iframe>
    <br>
    <div fxLayout="column" fxLayoutAlign="center center">
      <span class="scormPlayerRestartText" translate="itemDetail.RESTART_SCORM_OBJECT"></span>
      <!-- <button (click)="reloadPage()" class="md-raised " aria-label="Refresh">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span translate="itemDetail.REFRESH_PAGE"></span>
        </div>
      </button> -->
      <a (click)="reloadPage()"><span translate="itemDetail.REFRESH_PAGE"></span></a>
    </div>
  </div>
  <div fxLayout="row" fxFill fxLayoutAlign="center center" *ngIf="!scormUrl">
    <!-- <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!singletonLock">
      <a *ngIf="launchEnabled" (click)="launchScorm()">{{startLabel}}</a>
    </div> -->
    <button *ngIf="!launchEnabled" class="md-raised btn-center md-no-focus" [disabled]="true">
      {{cannotStartLabel}}
    </button>
    <div *ngIf="singletonLock && firstSingletonCheckMade">
      <span class="scormPlayerSingletonError" translate="itemDetail.SINGLETON_SCORM_OBJECT_IN_USE"></span>
    </div>
  </div>
</div>