/*
 * Servizio che gestisce le chiamate ai servizi per le lingue
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlService } from "../../shared/services/url.service";
import { TranslateService } from '@ngx-translate/core';
import { Item, ItemLang, Lang, SenecaResponse, User } from "../../../cm2-commonclasses";
import { CourseEdition } from "src/app/shared/models/course.model";
import { IDataItem } from 'src/app/shared/models/item.model';

@Injectable()
export class LangsService {
  constructor(private http: HttpClient,
    private urlService: UrlService,
    private translate: TranslateService) { }

  // Recupera le lingue disponibili a sistema
  getAvailableLangs(): any {
    let applicationContext = this.urlService.getApplicationUrl().baseUrl;
    return this.http.get<SenecaResponse<Lang[]>>(applicationContext + 'rest-api/corporateacademy-mediator/get-all-langs');
  }

  // Recupera la lingua di default del browser
  getBrowserLang(): string {
    return this.translate.getBrowserLang()
  }

  // Lingua attualmente in uso recuperata dal Token
  getUserLang = (user: User) => {
    return user && user && user.userOptions && user.userOptions.langCode ? user.userOptions.langCode.substring(0, 2) : "it";
  }

  // Metodo che cambia la lingua e le relative traduzioni all'appplicazione
  useLanguage(language: string) {
    this.translate.use(language);
  }

  // Trova l'indice di una lingua all'interno di un Item
  findItemLangIndex = (langCode: string, item: Item | CourseEdition | IDataItem) => {
    let index: number = -1;
    if (item && item.itemLangs) {
      item.itemLangs.forEach((one: ItemLang, idx: number) => {
        if (one.langCode === langCode) {
          index = idx;
        }
      });
    }
    return index;
  }
}