/*
* Componente per una sezione di un learning plan
*/

import { Component, Input, Output, EventEmitter, OnInit, SimpleChanges } from "@angular/core"
import { Item, ItemTypes, ItemAttributeTypes, ScormObjectTypes, ItemAttributeObjectTypes, ItemChild, CMUtils, ItemTakerEnrollStatusTypes } from 'src/cm2-commonclasses';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { take } from 'rxjs/operators';
import { ItemUtil } from 'src/app/shared/models/item.model';

@Component({
  selector: 'item-in-learning-plan',
  templateUrl: './item-in-learning-plan.component.html',
  styleUrls: ['./item-in-learning-plan.component.scss']
})
export class ItemInLearningPlanComponent implements OnInit {
  @Input() item;
  @Input() selectedItemList: Item[];
  @Input() page: string;
  @Input() isLearningPlanEditingMode: boolean;
  @Input() propedeuticReferenceId: string;
  @Input() mandatory: number;
  @Input() propedeuticReferenceIds: string[];
  @Input() currentItemType: string;
  @Input() isNotDisabled: boolean;
  @Input() allParentChilds: Item[];
  @Input() selectedItemChilds: ItemChild[];
  @Input() isSectionDisabled: boolean;
  @Input() loggedUserId?: string;
  @Input() lastElement?: boolean = false;
  @Input() survey?: {
    isSurveyStarted: boolean,
    isSurveyCertificable: boolean,
    isItemCertifiable: boolean
  };

  @Input() isInPreview?: boolean = false;
  @Input() isSchoolPage?: string;
  @Input() isLpItemDisabled?: boolean = false;
  @Input() hideDetails?: boolean = false;

  @Output() showPropedeuticalItemToThis: EventEmitter<any> = new EventEmitter();
  @Output() itemChildChecked = new EventEmitter<ItemChild>();
  @Output() itemChildMandatoryChanged = new EventEmitter<{ childItemId: string, mandatory: number }>();
  @Output() managePrerequisites = new EventEmitter<ItemChild>();
  card: any;
  itemType: any;
  hourValue: any;
  isLearningPlan: boolean;
  isProject: boolean;
  isBlendedProject: boolean;
  isSection: boolean;
  percentageItem: any;
  isItemAlreadyCertified: boolean;
  isMandatory: boolean;
  isPropedeutic: boolean;
  hasPrerequisites: boolean;
  propedeuticTitle: string;
  prerequisites: any;
  referenceId: string;
  isScorm: boolean;
  isConsumed: boolean;
  isItemDisabled: any;
  fullItem: any;
  adminMode: boolean;
  isFirstCheckboxVisible: boolean;
  isSecondCheckboxVisible: boolean;
  subIconType: string;
  showHourValue: boolean;
  isHourValueValorised: boolean;
  isCompleted: boolean;
  isItemContainer: boolean;
  isRequired: boolean;
  isNotRequired: boolean;
  isDetailBtnVisible: boolean;
  subtype: string;
  cardCover: string;
  category: string;

  subscribedEditionExpanded: boolean = false;

  constructor(private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute) {
  }

  // Controllo se non è obbligatorio
  checkIfNotRequired() {
    if (this.isPropedeutic || (this.mandatory == 1 && this.isPropedeutic)) {
      this.isNotRequired = true;
    } else {
      this.isNotRequired = false;
    }
  }
  checkPropedeuticReferenceIds() {
    if (this.propedeuticReferenceIds && this.propedeuticReferenceIds.length) {
      for (let m = 0; m < this.propedeuticReferenceIds.length; m++) {
        if (this.referenceId && this.propedeuticReferenceIds[m] == this.referenceId) {
          this.isPropedeutic = true;
          break;
        } else {
          this.isPropedeutic = false;
        }
      }
    } else {
      this.isPropedeutic = false;
    }
    this.checkIfNotRequired();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.propedeuticReferenceId && this.fullItem && this.fullItem.propedeuticReferenceId) {
      this.propedeuticTitle = ItemUtil.getPropedeuticReferenceTitle(this.allParentChilds, this.fullItem);
      this.prerequisites = {
        hasPrerequisites: this.propedeuticReferenceId ? true : false
      }
    }

    // Se non c'è il valore corrente, ma c'era prima, significa che ho tolto il check sulla propedeuticità
    if (changes.propedeuticReferenceId && changes.propedeuticReferenceId.previousValue && !changes.propedeuticReferenceId.currentValue) {
      this.prerequisites = {
        hasPrerequisites: this.propedeuticReferenceId ? true : false
      }
    }

    if (changes.propedeuticReferenceIds) {
      this.isPropedeutic = false;
      this.checkPropedeuticReferenceIds();
    }
  }

  // Il mandatory a db è un numero (0 / 1), ma all'md-switch serve un boolean. Così devo gestire manualmente lo switch
  changeMandatory = () => {
    if (this.isMandatory == true) {
      this.mandatory = 0;
      this.isMandatory = false;
    } else {
      this.mandatory = 1;
      this.isMandatory = true;
    }

    let eventData = {
      childItemId: this.item.itemId,
      mandatory: this.mandatory
    }

    this.itemChildMandatoryChanged.emit(eventData);

    this.checkIfRequired();
  }

  // Emette l'evento per la propedeuticità
  onManagePrerequisites(event: Event) {
    if (!this.prerequisites.hasPrerequisites) {
      // Se ha prerequisiti, lo sto de-checkando
      event.preventDefault();
    }
    this.managePrerequisites.emit(this.fullItem);
  }

  // Mostra l'oggetto propedeutico
  onShowPropedeuticalItemToThis() {
    if (this.isItemDisabled) {
      this.showPropedeuticalItemToThis.emit(this.fullItem);
    }
  }

  // Controllo se l'oggetto è obbligatorio
  checkIfRequired() {
    if (this.mandatory == 1 || (this.mandatory == 1 && this.isPropedeutic)) {
      this.isRequired = true;
    } else {
      this.isRequired = false;
    }
  }

  // Porta alla pagina di dettaglio dell'oggetto selezionato
  goToItemDetail(selectedItemId: string) {
    if (this.isSchoolPage) {
      if (this.itemType === ItemAttributeObjectTypes.LEARNING_PLAN) {
        this.router.navigate(['takers/thematicSchool/' + this.isSchoolPage + '/itemDetails/', this.card.itemId]);
      } else if (this.currentItemType == ItemAttributeObjectTypes.SECTION) {
        this.router.navigate(['takers/thematicSchool/' + this.isSchoolPage + '/itemDetailSec/', this.item.itemId, selectedItemId, this.card.itemId]);
      } else if (this.currentItemType == ItemAttributeObjectTypes.LEARNING_PLAN) {
        // Sto accedendo a un oggetto contenuto in un learning plan (chiamato playlist)
        this.router.navigate(['takers/thematicSchool/' + this.isSchoolPage + '/itemDetails/', this.item.itemId, this.card.itemId]);
        // $state.go("app.libraryApp.itemDetailLp", { lpId: $stateParams.itemId, itemId: this.card.itemId });
      } else {
        this.router.navigate(['takers/thematicSchool/' + this.isSchoolPage + '/itemDetails/', this.item.itemId, this.card.itemId]);
      }
    } else {
      this.route.params.pipe(take(1))
        .subscribe(
          (params: Params) => {
            let itemId = params['itemId'];
            // Se l'id passato alla funzione è diverso dall'id dell'oggetto Item significa che sto aprendo
            // la card di un elemento suggerito all'interno di un Item (es Learning Plan)
            if (selectedItemId != this.card.itemId) {
              let baseUrl = null;
              if (this.adminMode) {
                baseUrl = 'itemsAdmin/';
              } else {
                const isInLibrary = this.router.url.indexOf('library') !== -1;
                const isInCatalog = this.router.url.indexOf('catalog') !== -1;
                const isInTrainingBooklet = this.router.url.indexOf('trainingBooklet') !== -1;
                if (isInLibrary) {
                  baseUrl = 'takers/library/';
                } else if (isInTrainingBooklet) {
                  baseUrl = 'takers/trainingBooklet/';
                } else if (isInCatalog) {
                  baseUrl = 'takers/catalog/';
                } else if (isInTrainingBooklet) {
                  baseUrl = 'takers/trainingBooklet/';
                } else {
                  baseUrl = 'takers/';
                }
              }
              if (this.itemType === ItemAttributeObjectTypes.LEARNING_PLAN) {
                this.router.navigate([baseUrl + 'itemDetails/', this.card.itemId]);
              } else if (this.currentItemType == ItemAttributeObjectTypes.SECTION) {
                this.router.navigate([baseUrl + 'itemDetailSec/', itemId, selectedItemId, this.card.itemId]);
              } else if (this.currentItemType == ItemAttributeObjectTypes.LEARNING_PLAN) {
                // Sto accedendo a un oggetto contenuto in un learning plan (chiamato playlist)
                this.router.navigate([baseUrl + 'itemDetails/', itemId, this.card.itemId]);
                // $state.go("app.libraryApp.itemDetailLp", { lpId: $stateParams.itemId, itemId: this.card.itemId });
              } else {
                this.router.navigate([baseUrl + 'itemDetails/', itemId, this.card.itemId]);
              }
            }
          }
        );
    }
  }

  // Verifica se un item era già stato checkato
  isItemChecked(item: ItemChild) {
    let isChecked = false;
    if (this.selectedItemChilds && this.selectedItemChilds.length) {
      for (let i = 0, itemsLength = this.selectedItemChilds.length; i < itemsLength; i++) {
        let currentItemChild = this.selectedItemChilds[i];
        if (currentItemChild && item && currentItemChild && currentItemChild.childObject
          && currentItemChild.childObject.itemId === item.itemId) {
          isChecked = true;
          break;
        }
      }
    }
    return isChecked;
  }

  // Emette l'evento che seleziona l'item child corrent
  emitItemChecked(selectedItem: ItemChild) {
    this.itemChildChecked.emit(selectedItem);
  }

  //Definisce il testo da mostrare in base allo stato della survey associata all'oggetto
  getButtonTextForSurvey() {
    if (this.survey && this.survey.isItemCertifiable && this.isConsumed) {
      if (this.survey.isSurveyCertificable) {
        return this.translate.instant('survey.ACCESS_AND_START')
      } else if (this.survey.isSurveyStarted) {
        return this.translate.instant('survey.ACCESS_AND_CONTINUE')
      } else {
        return this.translate.instant('generic.SHOW_DETAILS')
      }
    } else {
      return this.translate.instant('generic.SHOW_DETAILS')
    }
  }

  trackByItemId(index, item) {
    return item.itemId
  }

  ngOnInit() {
    // Item passato alla Direttiva. Se esiste il referenceId significa che si tratta di un childItem
    if (!this.item.referenceId || !this.item.childObject) {
      this.card = this.item;
    } else {
      // Prima di salvare il childObject, recupero l'referenceId affinchè, in seguito, possa verifica se tale oggetto è propedeutico per qualche altro
      this.referenceId = this.item && this.item.referenceId;
      this.fullItem = this.item;
      this.card = this.item.childObject;
      this.item = this.item.childObject;
    }

    if (this.item && !this.item.itemTypeLabel) {
      this.item.itemTypeLabel = ItemUtil.getItemTypeLabel(this.item, this.translate);
    }

    if (this.item && this.item.userEnrolledToEditions) {
      for (let i = 0, editionsLength = this.item.userEnrolledToEditions.length; i < editionsLength; i++) {
        let currentEdition = this.item.userEnrolledToEditions[i];
        if (currentEdition) {
          currentEdition.formattedDate = currentEdition.firstDayDate && moment(currentEdition.firstDayDate).utc().format('DD/MM/YYYY');
          currentEdition.isPresent = currentEdition.userStatus && currentEdition.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_PRESENT;
        }
      }
    }

    // Verifica se è un oggetto contenitore
    this.isItemContainer = false;

    // Verifica se c'è almeno o l'ora o i minuti nel monte ore
    this.isHourValueValorised = false;

    // Verifica se mostrare il monte ore
    this.showHourValue = false;

    // Verifica se sono nella parte amministrativa 
    if (this.router.url.indexOf('Admin') !== -1) {
      this.adminMode = true;
    } else {
      this.adminMode = false;
    }

    // Verifica se è completato
    this.isCompleted = false;

    // Verifico se questo oggetto è propedeutico per qualche altro oggetto
    this.isPropedeutic = false;

    if (this.propedeuticReferenceIds && this.propedeuticReferenceIds.length) {
      this.checkPropedeuticReferenceIds();
    }
    this.checkIfNotRequired();

    // Verifico se esiste un altro oggetto propedeutico per quello attuale
    this.prerequisites = {
      hasPrerequisites: this.propedeuticReferenceId ? true : false
    }

    // Se ho un item propedeutico a quello attuale, recupero il suo titolo
    if (this.prerequisites.hasPrerequisites) {
      this.propedeuticTitle = ItemUtil.getPropedeuticReferenceTitle(this.allParentChilds, this.fullItem || this.card, this.propedeuticReferenceId);
    }

    // Salvo l'obbligatorietà
    this.isMandatory = !!this.mandatory;


    // Verifico se è un oggetto scorm
    this.isScorm = this.card && this.card.itemType && (this.card.itemType === ItemTypes.SCORM_FREE || this.card.itemType === ItemTypes.SCORM_INVITE) ? true : false;

    // Tipo dell'oggetto (video, audio, e-book...)
    this.itemType = null;

    // Sottotipo
    this.subtype = null;

    // Valore formativo
    this.hourValue = null;

    // Verifica se l'item è stato completato
    this.isConsumed = false;

    // Verifica se è un learning plan [chiamato playlist]
    this.isLearningPlan = false;

    // Verifica se è un progetto [chiamato learning plan]
    this.isProject = false;

    // Verifica se è un progetto [chiamato learning plan] blended
    this.isBlendedProject = false;

    // Verifica se è una sezione
    this.isSection = false;

    // Verifico gli attributi dell'oggetto
    if (this.card) {
      if (this.card.itemAttributes) {
        // Recupero l'immagine di sfondo
        let cardCoverAttr = ItemUtil.getAttributeByKey(this.card, ItemTypes.CARD_COVER);
        this.cardCover = cardCoverAttr && ((cardCoverAttr.attributeValue) || (cardCoverAttr.crossReferenceObject && cardCoverAttr.crossReferenceObject.url));

        let itemAttributes = this.card.itemAttributes;
        for (let k = 0; k < itemAttributes.length; k++) {
          // Verifico il tipo di oggetto
          if (itemAttributes[k].attributeType === ItemAttributeTypes.OBJECT_TYPE) {
            this.itemType = itemAttributes[k].attributeValue;
            if (this.itemType === ItemAttributeObjectTypes.LEARNING_PLAN) {
              this.isLearningPlan = true;
            } else if (itemAttributes[k].attributeValue === ItemAttributeObjectTypes.PROJECT) {
              this.isProject = true;
            } else if (itemAttributes[k].attributeValue === ItemAttributeObjectTypes.BLENDED_PROJECT) {
              this.isBlendedProject = true;
            } else if (itemAttributes[k].attributeValue === ItemAttributeObjectTypes.SECTION) {
              this.isSection = true;
            }
          }

          // Verifico se ha il Sottotipo
          if (itemAttributes[k].attributeType === ItemAttributeTypes.OBJECT_TYPE_SPECIALIZATION) {
            this.subtype = itemAttributes[k].attributeValue;
          }

          // Verifico se c'è il valore formativo
          if (itemAttributes[k].attributeType === ItemAttributeTypes.VALUE) {
            // let duration = moment.duration(parseInt(itemAttributes[k].attributeValue), 'seconds');
            // this.hourValue = {
            //   hours: duration.minutes(),
            //   minutes: duration.seconds()
            // }
            const hours = Math.trunc(parseInt(itemAttributes[k].attributeValue) / 60);
            const minutes = (parseInt(itemAttributes[k].attributeValue) - (hours * 60));
            this.hourValue = {
              hours: hours,
              minutes: minutes
            }
          }
        }

        // Se non ho trovato il sottotipo, lo setto di default come la tipologia dell'oggetto
        if (!this.subtype) {
          if (this.itemType) {
            this.subtype = this.itemType;
          } else if (!this.itemType && this.isScorm) {
            this.subtype = ScormObjectTypes.SCORM;
          } else if (!this.itemType && this.card && this.card.itemType && this.card.itemType === ItemTypes.COURSE_CLASS_STAGE) {
            // Se arrivo qui significa che è una iniziativa blended
            this.itemType = ItemTypes.COURSE_CLASS_STAGE;
            this.subtype = ItemTypes.COURSE_CLASS_STAGE;
          }
        }
      }

      // Nella parte admin non ho il consumable item, ma un Item normale
      if (this.adminMode) {
        let percentageAndCertificationData = ItemUtil.getPercentageAndCertificationData(this.card);
        if (percentageAndCertificationData) {
          this.percentageItem = percentageAndCertificationData.percentageItem;
          this.isConsumed = percentageAndCertificationData.isConsumed;
          this.isItemAlreadyCertified = percentageAndCertificationData.isItemAlreadyCertified;
        }
      } else {
        this.percentageItem = this.card.completionPercentage;
        this.isConsumed = this.card.isConsumed;
        this.isItemAlreadyCertified = this.card.isCertified;
      }
    }

    this.isItemDisabled = false;
    if (!this.adminMode && this.propedeuticReferenceId && !this.isNotDisabled && this.page != 'editLearningPlanAdmin' && this.page != 'editSection' && this.page != 'editProject' && this.page != 'editBlendedSection' && this.page != 'editBlendedProject') {
      this.isItemDisabled = true;
    }

    // Verifico se la prima checkbox è visibile 
    if ((this.isLearningPlanEditingMode && this.page === 'editLearningPlan') || (this.isLearningPlanEditingMode && this.page === 'editLearningPlanAdmin') || (this.isLearningPlanEditingMode && (this.page === 'editSection' || this.page === 'editBlendedSection')) || this.page === 'editSection' || this.page === 'editBlendedSection') {
      this.isFirstCheckboxVisible = true;
    } else {
      this.isFirstCheckboxVisible = false;
    }

    // Verifico se la seconda checkbox è visibile 
    if ((this.isLearningPlanEditingMode && this.page === 'addItemToCurrentLearningPlan') || this.page === 'addItemToCurrentSection') {
      this.isSecondCheckboxVisible = true;
    } else {
      this.isSecondCheckboxVisible = false;
    }

    if (this.subtype) {
      this.subIconType = this.translate.instant('generic.itemTypes.' + this.subtype);
    }

    // Verifico se è completato
    if (this.isConsumed && !this.isItemAlreadyCertified) {
      this.isCompleted = true;
    }

    // Calcolo le classi che andranno aggiunte al contenitore dell'intera card
    if (this.isProject || this.isBlendedProject || this.isLearningPlan || this.isSection) {
      // Salvo il fatto che è un oggetto contenitore
      this.isItemContainer = true;
    }

    // Verifica se c'è o l'ora o i minuti nel monte ore
    if (this.hourValue && (this.hourValue.hours != 0 || this.hourValue.minutes != 0)) {
      this.isHourValueValorised = true;
    }

    // Verifico se mostrare il monte ore
    if (this.adminMode) {
      this.showHourValue = true;
    }

    // Verifico se far vedere il pulsante per andare nel dettaglio dell'oggetto
    if (this.isItemDisabled || this.isSectionDisabled || this.isLearningPlanEditingMode) {
      this.isDetailBtnVisible = false;
    } else {
      this.isDetailBtnVisible = true;
    }
    this.checkIfRequired();
    this.checkIfNotRequired();
  }

  // Verifica se l'item è online
  isItemOnline() {
    let isOnline = true;
    if (this.subtype && CMUtils.isClassStage(this.subtype)) {
      isOnline = false;
    }

    return isOnline;
  }
}