<div class="box-news animated-background ">
  <div class="box-news-content">
    <div class="box-news">
      <div class="box-news-label">
        <h3 class="title animated-background-label">
        </h3>
        <h4 class="animated-background-label"></h4>
        <h6 class="animated-background-label"></h6>
        <div class="btn-container">
          <lib-alloy-button importance="bordless" dimension="btn-small" type="submit"
            text="{{ 'news.MORE' | translate }}">
          </lib-alloy-button>
        </div>
      </div>
    </div>
  </div>
</div>