/*
* Componente per la paginazione
*/
import {
  ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef, forwardRef, AfterViewChecked, Renderer2
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { BaseFormComponent } from '../../shared/components/base-form.component';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
@Component({
  selector: 'syllabus-basic-editor',
  templateUrl: './syllabus-basic-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // per le validazioni con Reactive forms da aggiungere in ogni custom components di input
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SyllabusBasicEditorComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => SyllabusBasicEditorComponent), multi: true }
  ]
})
export class SyllabusBasicEditorComponent extends BaseFormComponent implements OnInit, AfterViewChecked {

  // @Input() id: string;
  @Input() label: string;
  _reviewMode: boolean = false;

  @Input() set reviewMode(value: string | boolean) {
    this._reviewMode = coerceBooleanProperty(value);
  }
  @Input() editSwitchFlag: any;
  @Input() editorConfig: any;
  @Input() isDisabled: boolean;
  @Input() hideSwitch: boolean;
  @Input() isSmart: boolean;
  @Input() showEditableToggle: boolean;
  @Output() toggleEditable = new EventEmitter();
  @Output() editSwitchFlagChange = new EventEmitter();
  @Output() updateEditModel = new EventEmitter();

  showEditSwitch: boolean;

  constructor(
    private __translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private __renderer: Renderer2) {

    super(__translate, __renderer);
  }

  ngOnInit(): void {
    this.reviewMode = this.readBooleanInput(this.reviewMode);
    this.editSwitchFlag = this.readBooleanInput(this.editSwitchFlag);
    this.showEditableToggle = this.readBooleanInput(this.showEditableToggle);
    this.showEditSwitch = !this.reviewMode && !this.hideSwitch; // && this.showEditableToggle;

    this.markAsDirty = this.reviewMode ? true : this.markAsDirty;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onUpdateEditModel() {
    if (this.updateEditModel) {
      this.updateEditModel.emit(this.value);
    }
  }

  emitToggleEditable(event): void {
    this.editSwitchFlag = !this.editSwitchFlag;
    this.editSwitchFlagChange.emit(this.editSwitchFlag);
    this.toggleEditable.emit(this.editSwitchFlag);
  }
}
