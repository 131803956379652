/*
*  Lista di azioni riguardante la gestione della contabilità dei docenti
*/

import { Action } from '@ngrx/store';

// Salva la lista di docenti recuperati
export const SET_TEACHERS = 'SET_TEACHERS';

// Cancella un docente
export const DELETE_TEACHER = 'DELETE_TEACHER';

// Recupera i docenti da remoto
export const GET_TEACHERS = 'GET_TEACHERS';

// Setta il contatore dei docenti recuperati da remoto
export const SET_TEACHERS_COUNT = 'SET_TEACHERS_COUNT';

// Setta la paginazione
export const SET_PAGINATION_TEACHERS = 'SET_PAGINATION_TEACHERS';

// Setta la pagina corrente
export const SET_PAGE_TEACHERS = 'SET_PAGE_TEACHERS';

// Setta il testo (searchedText)
export const SET_TEACHERS_TEXT_FILTER = 'SET_TEACHERS_TEXT_FILTER';

// Setta la direzione di ordinamento
export const SET_SORTING_DIR = 'SET_ORDER_DIR';

//Setta la colonna di ordinamento
export const SET_SORTING_COL = 'SET_SORTING_COL';

export class SetTeachers implements Action {
  readonly type = SET_TEACHERS;

  constructor(public payload: any[]) { }
}

export class DeleteTeachers implements Action {
  readonly type = DELETE_TEACHER;

  constructor(public payload: string) { }
}

export class GetTeachers implements Action {
  readonly type = GET_TEACHERS;

  constructor(public payload?: boolean) { }
}

export class SetSortingCol implements Action {
  readonly type = SET_SORTING_COL;

  constructor(public payload: string) { }
}

export class SetSortingDir implements Action {
  readonly type = SET_SORTING_DIR;

  constructor(public payload: string) { }
}

export class SetTeachersCount implements Action {
  readonly type = SET_TEACHERS_COUNT;

  constructor(public payload: number) { }
}

export class SetPageTeachers implements Action {
  readonly type = SET_PAGE_TEACHERS;

  constructor(public payload: number) { }
}

export class SetPaginationTeachers implements Action {
  readonly type = SET_PAGINATION_TEACHERS;

  constructor(public payload: { fromRecord: number, numRecords: number }) { }
}
export class SetTeachersTextFilter implements Action {
  readonly type = SET_TEACHERS_TEXT_FILTER;

  constructor(public payload: string) { }
}

export type TeachersActions = SetTeachers |
  DeleteTeachers |
  SetTeachersCount |
  SetPaginationTeachers |
  SetPageTeachers |
  SetTeachersTextFilter |
  SetSortingDir |
  SetSortingCol |
  GetTeachers;