<div class="item-details-content">
  <div class="bg-item" fxFill>
    <div class="itemDetails" fxLayout="column">
      <div class="content min">
        <div class="padding-top45 padding-bottom15 more-z-index" fxLayout="row" layout-xs="column">
        </div>
      </div>
      <div class="more-z-index" fxLayout="row" fxFill>
        <div [ngStyle]="{ 'background-image': avatar ? 'url(' + avatar + ')' : '' }" class="avatar-profile-detail">
        </div>
      </div>

      <div *ngIf="loggedUser" class="more-z-index" fxLayout="column" fxFill fxLayoutAlign="center center">
        <h3 class="margin-top15">{{ loggedUser.user.forename + ' ' + loggedUser.user.surname }}</h3>
      </div>
    </div>
  </div>
  <div class="itemDetails" fxLayout="column">

    <p class="margin-top35 full-width" tabindex="0">{{ 'generic.NEW_PASSWORD_REQUIRED_TO_PROCEED' | translate }}</p>

    <div class="al-divider"></div>

    <div class="item-list cursor-default" fxLayout="column" fxFlexAlign="start start">
      <!-- Loader -->
      <div *ngIf="loaderActive">
        <pending-initiatives-placeholder *ngFor="let number of [0,1,2,3]"></pending-initiatives-placeholder>
      </div>
      <div *ngIf="!loaderActive" class="contact-new-supplier-sidebar custom-select generali-input small skill-select">
        <!-- Password attuale -->
        <lib-alloy-input id="oldPassword" [type]="'password'" [bindValue]="oldPassword"
          (onModelChanged)="onOldPasswordChanged($event)" [attrName]="'oldPassword'" [required]="true"
          [placeholder]="'generic.TYPE_PASSWORD' | translate" name="oldPassword"
          [attrAriaLabel]="'generic.TYPE_PASSWORD' | translate" inputContainerClass="small-dimension"
          errorRequiredText="{{ 'errors.forms.generic.ERROR_FIELD_REQUIRED' | translate }}" [required]="true"
          title="{{ 'generic.OLD_PSW' | translate }}" autocompleteText="old-password"></lib-alloy-input>
        <div class="space30"></div>
        <!-- Nuova password -->
        <lib-alloy-input id="newPassword" [type]="'password'" [bindValue]="newPassword" [pattern]="passwordPattern"
          (onModelChanged)="onNewPasswordChanged($event)" [attrName]="'newPassword'" [required]="true"
          [placeholder]="'generic.TYPE_PASSWORD' | translate"
          errorPatternText="{{ 'login.errors.PATTERN' | translate }}"
          [attrAriaLabel]="'generic.TYPE_PASSWORD' | translate" inputContainerClass="small-dimension"
          errorRequiredText="{{ 'errors.forms.generic.ERROR_FIELD_REQUIRED' | translate }}" [required]="true"
          title="{{ 'generic.NEW_PSW' | translate }}" autocompleteText="new-password"></lib-alloy-input>
        <div class="space30"></div>
        <!-- Conferma nuova password -->
        <lib-alloy-input id="confirmPassword" [type]="'password'" [bindValue]="confirmPassword"
          [pattern]="passwordPattern" (onModelChanged)="onConfirmPasswordChanged($event)" [attrName]="'confirmPassword'"
          [required]="true" [placeholder]="'generic.TYPE_PASSWORD' | translate"
          errorPatternText="{{ 'login.errors.PATTERN' | translate }}"
          [attrAriaLabel]="'generic.TYPE_PASSWORD' | translate" inputContainerClass="small-dimension"
          errorRequiredText="{{ 'errors.forms.generic.ERROR_FIELD_REQUIRED' | translate }}" [required]="true"
          title="{{ 'generic.CONFIRM_PSW' | translate }}" autocompleteText="renew-password">
        </lib-alloy-input>
        <div class="space30"></div>
        <!-- Conferma cambio password -->
        <lib-alloy-button importance="primary" [disabled]="!arePswMatchingPattern() || !oldPassword || !newPassword || !confirmPassword ||
                (newPassword && confirmPassword && newPassword !== confirmPassword)" dimension="btn-regular"
          text="{{ 'generic.CONFIRM_NEW_PSW' | translate }}" (onButtonClicked)="submitNewPassword()">
        </lib-alloy-button>
      </div>
    </div>

    <div class="al-divider"></div>

  </div>
</div>