<div class="item-details-content">
  <div class="bg-item" fxFill>
    <div class="itemDetails" fxLayout="column">
      <div class="content min">
        <!-- Breadcrumbs -->
        <div class="padding-top45 padding-bottom15 more-z-index" fxLayout="row" layout-xs="column">
          <div fxLayout="row" fxLayoutAlign="start start">
            <ol class="breadcrumb-alloy text-uppercase">
              <li>
                <a *ngIf="!isInTrainingBooklet" (click)="redirectService.goToHome(true)" translate="routing.HOME"></a>
                <a *ngIf="isInTrainingBooklet" (click)="goToTrainingBooklet()"
                  translate="routing.TRAINING_BOOKLET_MIN"></a>
              </li>
              <li>
                <a class="current" translate="generic.itemTypes.SURVEY"></a>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="more-z-index" fxLayout="column" fxLayoutAlign="center center">
        <h2 translate="generic.itemTypes.SURVEY"></h2>
        <h3>{{ currentSurveyInfo && currentSurveyInfo.title }}</h3>
      </div>
    </div>
  </div>
  <div fxfxLayout="row" class="survey content">
    <div class="survey-wrapper">
      <div class="inviteBoxBottom" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- Torna indietro al dettaglio dell'oggetto -->
        <lib-alloy-button importance="neutral" dimension="btn-regular" text="{{'generic.BACK' | translate}}"
          (onButtonClicked)="backToItemDetails()">
        </lib-alloy-button>
        <!-- Domande precedenti-->
        <lib-alloy-button *ngIf="!prevDisabled" importance="secondary" dimension="btn-regular"
          text="{{ 'survey.BACK' | translate }}" [disabled]="!btnEnabled()" (onButtonClicked)="prevItem()">
        </lib-alloy-button>
        <!-- Domande successive -->
        <lib-alloy-button *ngIf="!nextDisabled" importance="primary" dimension="btn-regular"
          text="{{ 'survey.NEXT' | translate }}" [disabled]="!btnEnabled()" (onButtonClicked)="nextItem()">
        </lib-alloy-button>
        <!-- Completa il test -->
        <lib-alloy-button *ngIf="currentPos + 1 == totalGroups && isSurveyEditable" importance="primary"
          dimension="btn-regular" text="{{ 'generic.CONFIRM' | translate }}" [disabled]="!btnEnabled()"
          (onButtonClicked)="openConfirmSurveyModal()">
        </lib-alloy-button>
      </div>

      <!-- Loader -->
      <ng-template #fetchingDataLoader>
        <pending-initiatives-placeholder [containerClass]="'syllabus-title-placeholder margin-top30'"
          *ngFor="let number of [0]"></pending-initiatives-placeholder>
        <pending-initiatives-placeholder *ngFor="let number of [0]"></pending-initiatives-placeholder>
        <pending-initiatives-placeholder [containerClass]="'syllabus-title-placeholder'" *ngFor="let number of [0]">
        </pending-initiatives-placeholder>
        <pending-initiatives-placeholder *ngFor="let number of [0,1,2,3,4]"></pending-initiatives-placeholder>
      </ng-template>

      <div *ngIf="initiativeLoaded; else fetchingDataLoader" class="inviteBoxBottom" fxfxLayout="row"
        fxLayoutAlign="space-between center">
        <div class="main-wrapper" fxFlexFill>
          <div fxLayout="row" fxLayoutAlign="start center" class="control-panel-wrapper center-box width-auto"
            fxFlexFill layout-wrap>
            <div class="control-panel-obj-detail md-whiteframe-1dp no-padding no-margin" fxFlexFill>
              <div class="control-panel-top-bar"></div>
              <div class="control-panel-top-obj-detail bg-white">
                <div class="control-panel-title">
                  <!-- Titolo del questionario -->
                  <div *ngIf="currentSurveyInfo && currentSurveyInfo.title">
                    <h2 class="bold-text">{{ currentSurveyInfo.title }}</h2>
                    <p translate="survey.ANSWER_TO_ALL_QUESTIONS"></p>
                  </div>
                  <!-- Sottotitolo del questionario -->
                  <div *ngIf="currentSurveyInfo && currentSurveyInfo.subTitle">
                    <h3>{{
                      currentSurveyInfo.subTitle }}</h3>
                  </div>
                </div>
                <div class="clearer clearfix"></div>
                <!-- Toolbar -->
                <div class="progressbar" fxLayout="row" fxLayoutAlign="start center" fxFlexFill>
                  <h5 class="bold-text to-lowercase margin-right15">{{currentPos + 1}} <span
                      translate="survey.OF"></span>
                    {{totalGroups}}</h5>
                  <progress-bar fxFlex [value]="percentageProgressLinear + percentageOfTotalQuestions"></progress-bar>
                </div>
              </div>

              <div fxLayout="column" fxLayoutAlign="center start">
                <div layout-xs="column" fxLayout="row" fxLayoutAlign="start center" layout-wrap fxFlexFill>
                  <form name="surveyQuestionsForm" class="form-validation" fxFlexFill>
                    <div class="box-group">
                      <div fxLayout="row">
                        <div class="survey-infobox-container">
                          <p fxLayout="row" class="btn-container">
                            {{selectedGroup && selectedGroup.itemText}}
                          </p>
                          <div fxLayout="row" *ngIf="groupHint" class="btn-container">
                            {{groupHint}}
                          </div>
                        </div>
                      </div>

                      <div class="space"></div>

                      <!-- Costruzione domande dinamiche -->
                      <div fxLayoutAlign="start center" *ngFor="let question of questions; let iquest = index;"
                        fxFlexFill>
                        <!-- textbox -->
                        <div class="full-width" fxLayout="column" fxLayoutAlign="start start"
                          *ngIf="question && question.answerType === 'T'">
                          <p class="question-title"
                            [ngClass]="{'text-primary-1' : question.answerMandatory == 'true' && !question.textAnswer}">
                            {{question.itemText}}<span
                              *ngIf="question.answerMandatory == 'true' && !question.textAnswer">*</span>
                          </p>
                          <div fxLayout="row" *ngIf="question.itemHint">
                            <div fxLayout="column">
                              {{question.itemHint}}</div>
                          </div>
                          <div class="margin-top10" fxLayout="column" fxFlexFill>
                            <div class="md-block inline-block md-icon-left" fxFlexFill>
                              <svg-icon src="assets/icons/icon-pencil.svg"></svg-icon>
                              <input [(ngModel)]="question.textAnswer" name="inputText" type="text" maxlength="4000"
                                [disabled]="!isSurveyEditable" [required]="question.answerMandatory == 'true'"
                                class="margin0">
                            </div>
                          </div>
                        </div>

                        <!-- numberbox -->
                        <div class="full-width" fxLayout="column" fxLayoutAlign="start start"
                          *ngIf="question && question.answerType === 'N'">
                          <p class="question-title"
                            [ngClass]="{'text-primary-1' : question.answerMandatory == 'true' && !question.numericAnswer}">
                            {{question.itemText}}
                            <span *ngIf="question.answerMandatory == 'true' && !question.numericAnswer">*</span>
                          </p>
                          <div fxLayout="row" *ngIf="question.itemHint">
                            <div fxLayout="column">
                              {{question.itemHint}}</div>
                          </div>
                          <div class="margin-top10" fxLayout="column">
                            <div class="md-block inline-block md-icon-left no-margin">
                              <svg-icon src="assets/icons/icon-pencil.svg"></svg-icon>
                              <input [(ngModel)]="question.numericAnswer" type="number" name="inputNumber"
                                [disabled]="!isSurveyEditable" [required]="question.answerMandatory == 'true'"
                                class="no-margin">
                            </div>
                          </div>
                        </div>

                        <!-- extended text -->
                        <div class="full-width" fxLayout="column" layout-xs="column" fxLayoutAlign="start start"
                          fxFlexFill *ngIf="question && question.answerType === 'E'">
                          <p class="question-title"
                            [ngClass]="{'text-primary-1' : question.answerMandatory == 'true' && !question.textAnswer}">
                            {{question.itemText}}<span
                              *ngIf="question.answerMandatory == 'true' && !question.textAnswer">*</span>
                          </p>
                          <div fxLayout="row" *ngIf="question.itemHint">
                            <div fxLayout="column">
                              {{question.itemHint}}</div>
                          </div>
                          <div class="margin-top10" fxLayout="column">
                            <div class="md-block inline-block md-icon-left" fxFlexFill>
                              <svg-icon src="assets/icons/icon-pencil.svg"></svg-icon>
                              <textarea [(ngModel)]="question.textAnswer" maxlength="4000" rows="2"
                                aria-label="Inserire testo" md-select-on-focus="" [disabled]="!isSurveyEditable"
                                name="inputTextArea" [required]="question.answerMandatory == 'true'"></textarea>
                            </div>
                          </div>
                        </div>

                        <!-- radio -->
                        <div fxLayout="row" layout-xs="column" fxLayoutAlign="center center"
                          *ngIf="question && question.answerType === 'S'">
                          <div class="full-width" fxLayout="column">
                            <p class="question-title"
                              [ngClass]="{'text-primary-1' : question.answerMandatory == 'true' && !question.selectedAnswerIds}">
                              {{ question.itemText }}<span
                                *ngIf="question.answerMandatory == 'true' && !question.selectedAnswerIds">*</span>
                            </p>
                            <div fxLayout="row" *ngIf="question.itemHint">
                              <div fxLayout="column">
                                {{question.itemHint}}</div>
                            </div>
                            <div *ngFor="let answer of question.answers; let i = index;"
                              class="md-radio survey-single-check">
                              <input type="radio" id="{{'opt' + iquest + '_' + i}}"
                                name="{{'options' + iquest + '_' +  i}}" [disabled]="!isSurveyEditable"
                                [(ngModel)]="question.selectedAnswerIds" value="{{answer.answerId}}">
                              <label class="height-100" for="{{'opt' + iquest + '_' + i}}">{{answer.itemText}}</label>
                            </div>
                          </div>
                        </div>

                        <!-- select -->
                        <div fxLayout="row" layout-xs="column" fxLayoutAlign="center center"
                          *ngIf="question && question.answerType === 'C'">
                          <div class="full-width" fxLayout="column">
                            <p class="question-title"
                              [ngClass]="{'text-primary-1' : question.answerMandatory == 'true' && !question.selectedAnswerIds}">
                              {{ question.itemText }}<span
                                *ngIf="question.answerMandatory == 'true' && !question.selectedAnswerIds">*</span>
                            </p>
                            <div fxLayout="row" *ngIf="question.itemHint">
                              <div fxLayout="column">
                                {{question.itemHint}}</div>
                            </div>
                            <select class="margin-top10" name="inputSelect" [(ngModel)]="question.selectedAnswerIds"
                              [required]="question.answerMandatory == 'true'">
                              <option value="" [disabled]="!isSurveyEditable">{{selectAnswer}}</option>
                              <option *ngFor="let answer of question.answers" value="{{answer.answerId}}"
                                ng-selected="answer.answerId === question.selectedAnswerIds"
                                [disabled]="!isSurveyEditable">
                                {{answer.itemText}}
                              </option>
                            </select>
                          </div>
                        </div>

                        <!-- multi-checkbox -->
                        <div *ngIf="question && question.answerType === 'M'">
                          <div fxLayout="column" class="full-width single-checklist-container margin-top10">
                            <p class="question-title margin-bottom10"
                              [ngClass]="{'text-primary-1' : question.answerMandatory == 'true' && !question.selected}">
                              {{ question.itemText }}<span
                                *ngIf="question.answerMandatory == 'true' && !question.selected">*</span>
                            </p>
                            <div fxLayout="row" *ngIf="question.itemHint">
                              <div fxLayout="column">
                                {{question.itemHint}}</div>
                            </div>
                            <div class="md-checkbox margin-top10"
                              *ngFor="let answer of question.answers; let i = index;">
                              <input id="inputCheckboxMultiple{{answer.answerId}}"
                                name="inputCheckboxMultiple{{answer.answerId}}" type="checkbox"
                                [disabled]="!isSurveyEditable" [(ngModel)]="answer.selected">
                              <label for="inputCheckboxMultiple{{answer.answerId}}">
                                {{answer.itemText}}
                              </label>
                            </div>
                          </div>
                        </div>

                        <!-- single-checkbox -->
                        <div *ngIf="question && question.answerType === 'X'">
                          <div fxLayout="column" class="full-width single-checklist-container margin-bottom10">
                            <p class="question-title">
                              {{ question.itemText }}
                            </p>
                            <div fxLayout="row" *ngIf="question.itemHint">
                              <div fxLayout="column">
                                {{question.itemHint}}</div>
                            </div>
                            <div class="md-checkbox margin-top10">
                              <input type="checkbox" id="inputCheckbox" [disabled]="!isSurveyEditable"
                                name="inputCheckbox" [(ngModel)]="question.selected">
                              <label for="inputCheckbox">
                              </label>
                            </div>
                          </div>
                        </div>

                        <!-- date -->
                        <div class="full-width" fxLayout="column" layout-xs="column" fxLayoutAlign="start start"
                          fxFlexFill *ngIf="question && question.answerType === 'D'">
                          <p class="question-title"
                            [ngClass]="{'text-primary-1' : question.answerMandatory == 'true' && !question.dateAnswer}">
                            {{ question.itemText }}<span
                              *ngIf="question.answerMandatory == 'true' && !question.dateAnswer">*</span>
                          </p>
                          <div fxLayout="row" *ngIf="question.itemHint">
                            <div fxLayout="column">
                              {{question.itemHint}}</div>
                          </div>
                          <div class="col-xs-12 m-b-sm m-l-sm">
                            <div class="m-xs table-like">
                              <div class="table-cell-like vertical-center">
                                <datepicker [disabled]="!isSurveyEditable" class="full-width"
                                  [(value)]="question.dateAnswer">
                                </datepicker>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- Inidice di gradimento -->
                        <div fxLayout="row" layout-xs="column" fxLayoutAlign="center center"
                          *ngIf="question && question.answerType === 'F'" fxFlexFill>
                          <div class="full-width" fxLayout="column" fxFlexFill>
                            <p class="question-title"
                              [ngClass]="{'text-primary-1' : question.answerMandatory == 'true' && !question.selectedAnswerIds}">
                              {{ question.itemText }}<span
                                *ngIf="question.answerMandatory == 'true' && !question.selectedAnswerIds">*</span>
                            </p>
                            <div fxLayout="row" *ngIf="question.itemHint">
                              <div fxLayout="column">
                                {{question.itemHint}}</div>
                            </div>
                            <div fxLayout="row" class="full-width margin-top20" fxLayoutAlign="space-between start">
                              <div fxLayout="column" fxLayoutAlign="start center" fxFlex="20">
                                <svg-icon src="assets/icons/liking_1.svg"></svg-icon>
                                <div class="md-radio">
                                  <input type="radio" id="liking1" name="liking1" [disabled]="!isSurveyEditable"
                                    [(ngModel)]="question.numericAnswer" value="1">
                                  <label for="liking1"></label>
                                </div>
                                <span translate="survey.liking.FIRST"></span>
                              </div>
                              <div fxLayout="column" fxLayoutAlign="start center" fxFlex="20">
                                <svg-icon src="assets/icons/liking_2.svg"></svg-icon>
                                <div class="md-radio">
                                  <input type="radio" id="liking2" name="liking2" [disabled]="!isSurveyEditable"
                                    [(ngModel)]="question.numericAnswer" value="2">
                                  <label for="liking2"></label>
                                </div>
                                <span translate="survey.liking.SECOND"></span>
                              </div>
                              <div fxLayout="column" fxLayoutAlign="start center" fxFlex="20">
                                <svg-icon src="assets/icons/liking_3.svg"></svg-icon>
                                <div class="md-radio">
                                  <input type="radio" id="liking3" name="liking3" [disabled]="!isSurveyEditable"
                                    [(ngModel)]="question.numericAnswer" value="3">
                                  <label for="liking3"></label>
                                </div>
                                <span translate="survey.liking.THIRD"></span>
                              </div>
                              <div fxLayout="column" fxLayoutAlign="start center" fxFlex="20">
                                <svg-icon src="assets/icons/liking_4.svg"></svg-icon>
                                <div class="md-radio">
                                  <input type="radio" id="liking4" name="liking4" [disabled]="!isSurveyEditable"
                                    [(ngModel)]="question.numericAnswer" value="4">
                                  <label for="liking4"></label>
                                </div>
                                <span translate="survey.liking.FOURTH"></span>
                              </div>
                              <div fxLayout="column" fxLayoutAlign="start center" fxFlex="20">
                                <svg-icon src="assets/icons/liking_5.svg"></svg-icon>
                                <div class="md-radio">
                                  <input type="radio" id="liking5" name="liking5" [disabled]="!isSurveyEditable"
                                    [(ngModel)]="question.numericAnswer" value="5">
                                  <label for="liking5"></label>
                                </div>
                                <span translate="survey.liking.FIFTH"></span>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="inviteBoxBottom" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- Torna indietro al dettaglio dell'item -->
        <lib-alloy-button importance="neutral" dimension="btn-regular" text="{{'generic.BACK' | translate}}"
          (onButtonClicked)="backToItemDetails()">
        </lib-alloy-button>
        <!-- Domande precedenti-->
        <lib-alloy-button *ngIf="!prevDisabled" importance="secondary" dimension="btn-regular"
          text="{{ 'survey.BACK' | translate }}" [disabled]="!btnEnabled()" (onButtonClicked)="prevItem()">
        </lib-alloy-button>
        <!-- Domande successive -->
        <lib-alloy-button *ngIf="!nextDisabled" importance="primary" dimension="btn-regular"
          text="{{ 'survey.NEXT' | translate }}" [disabled]="!btnEnabled()" (onButtonClicked)="nextItem()">
        </lib-alloy-button>
        <!-- Copmleta il test -->
        <lib-alloy-button *ngIf="currentPos + 1 == totalGroups && isSurveyEditable" importance="primary"
          dimension="btn-regular" text="{{ 'generic.CONFIRM' | translate }}" [disabled]="!btnEnabled()"
          (onButtonClicked)="openConfirmSurveyModal()">
        </lib-alloy-button>
      </div>
    </div>
  </div>
</div>

<!-- Modale per chiedere conferma sul completamento della Survey -->
<lib-alloy-modal class="not-destructive-modal" id="confirmSurvey" aria-modal="true" role="dialog" tabindex="0"
  aria-labelledby="confirm-survey-title">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h1 class="custom-modal-title" translate="survey.CLOSE" id="confirm-survey-title"></h1>
    <svg-icon class="cursor-pointer" (click)="closeConfirmSurveyModal()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <div class="content-modal" fxLayout="row" fxLayoutAlign="start start">
    <p translate="survey.CONFIRM_CLOSE_ONE">
    </p>
  </div>
  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- Annulla l'operazione -->
    <lib-alloy-button importance="bordless" dimension="btn-regular" text="{{'modals.CANCEL_BTN' | translate}}"
      (onButtonClicked)="closeConfirmSurveyModal()">
    </lib-alloy-button>
    <div fxHide.gt-xs class="space15"></div>
    <!-- Conferma l'operazione -->
    <lib-alloy-button importance="primary" dimension="btn-regular" text="{{'modals.CONFIRM_BTN' | translate}}"
      (onButtonClicked)="closeConfirmSurveyModal(true);">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>