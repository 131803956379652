<div [hidden]="!isLoaded" class="cursor-pointer" (click)="changeValue('2')">
  <div class="card-image" fxLayoutFill>
    <img src="assets/img/test_card_2.jpeg" (load)="imgRendered();"
      alt="{{'generic.INITIATIVE_FROM_EXISTING'|translate}}" title="{{'generic.INITIATIVE_FROM_EXISTING'|translate}}">
    <div class="card-content">
      <h1 class="card-header" translate="generic.INITIATIVE_FROM_EXISTING"></h1>
    </div>
    <div class="card-description">
      <p translate="createInitiativeCard.concept.FOOTER"></p>
    </div>
  </div>
  <div class="md-radio text-center">
    <input type="radio" role="radio" name="options" [ngModel]="selectedCreationPossibility.options" value="2">
    <label></label>
  </div>
</div>
<div [hidden]="isLoaded">
  <create-initiative-card-placeholder></create-initiative-card-placeholder>
</div>