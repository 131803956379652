<div fxLayout="column" class="small-widget-container">
  <div fxLayout="row" class="widget-content">
    <div fxLayout="column" class="icon-cards" fxLayoutAlign="start center">
      <div fxLayout="column">
        <img class="cursor-pointer" alt="image" src="assets/icons/icon-cards2.png">
      </div>
      <div fxLayout="column" class="margin-top35">
        <svg-icon class="cursor-pointer" src="assets/icons/icon-info-circle-line.svg">
        </svg-icon>
      </div>
    </div>
    <div class="full-width" fxLayout="column" fxLayoutAlign="start start">
      <div fxLayout="column" class="title-wrapper">
        <div class="line width125 height6"></div>
        <div class="line width100 height6"></div>
      </div>

      <div fxFlex fxLayout.gt-xs="row" fxLayout.xs="column" class="number-wrapper full-width margin-top20">
        <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="number-container first">
          <div class="indicator-number full-width" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="line width75 height6"></div>
          </div>
          <div class="indicator-number-hint" fxLayout="row" fxLayoutAlign="center center">
            <div class="line width125 height6"></div>
          </div>
        </div>
        <div fxFlex *ngIf="numberOfData && (numberOfData === 2 || numberOfData === 3)" fxLayout="column"
          fxLayoutAlign="start start" class="number-container second">
          <div class="indicator-number full-width" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="line width75 height6"></div>
          </div>
          <div class="indicator-number-hint" fxLayout="row" fxLayoutAlign="center center">
            <div class="line width125 height6"></div>
          </div>
        </div>
        <div fxFlex *ngIf="numberOfData && numberOfData === 3" fxLayout="column" fxLayoutAlign="start start"
          class="number-container third">
          <div class="indicator-number full-width" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="line width75 height6"></div>
          </div>
          <div class="indicator-number-hint" fxLayout="row" fxLayoutAlign="center center">
            <div class="line width125 height6"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row" class="widget-footer" fxLayoutAlign="end center">
    <p class="cursor-pointer" translate="generic.SHOW_DETAILS"></p>
    <svg-icon src="assets/icons/icon-angle-right.svg"></svg-icon>
  </div>
</div>