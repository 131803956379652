import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SmallWidgetIndicator } from 'src/cm2-commonclasses';

@Component({
  selector: 'small-widget',
  templateUrl: './small-widget.component.html',
  styleUrls: ['./small-widget.component.scss']
})
export class SmallWidgetComponent {
  @Input() indicator: SmallWidgetIndicator;
  @Input() hasMainButton: boolean = true;

  @Output() onMainButtonClick = new EventEmitter<any>();
  @Output() onInfoClick = new EventEmitter<any>();

  constructor() {
  }

  // Emette l'evento al click sul pulsante principale
  emitOnMainButtonClick() {
    this.onMainButtonClick.emit(this.indicator.id);
  }

  // Emette l'evento al click sull'icona delle info
  emitOnInfoClick() {
    this.onInfoClick.emit(this.indicator.id);
  }
}
