import { Component, Input, } from '@angular/core';

@Component({
  selector: 'lib-alloy-course-card-placeholder',
  templateUrl: './course-card-placeholder.component.html',
  styleUrls: ['./course-card-placeholder.component.scss']
})
export class CourseCardPlaceholderComponent {
  @Input() cardCover?: string;

  constructor() {
  }
}
