import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import * as fromApp from '../../ngrx/app.reducers';

@Injectable()
export class ForceResetPasswordGuard implements CanActivate {

  constructor(private store: Store<fromApp.AppState>,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(select(fromApp.getLoggedUser))
      .pipe(
        map((loggedUser) => {
          const loggedUsr = loggedUser;
          const hasToResetPassword = loggedUsr && loggedUsr.user && loggedUsr.user.forceResetPassword;
          if (hasToResetPassword) {
            this.router.navigate(['/resetPassword']);
            return false;
          } else {
            return true;
          }
        })
      );
  }
}
