/*
* Componente relativo alla modale di conferma
*/

import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'error-page',
  templateUrl: './error-page.component.html'
})
export class ErrorPageComponent implements OnInit {
  constructor() { }
  ngOnInit() {
  }
}
