/*
*    Dichiarazione dei moduli comuni e delle direttive
*/

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppTranslateModule } from '../translate/translate.module';
import { PaginationComponent } from '../core/pagination/pagination.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MomentModule } from 'ngx-moment';
import { SidebarModule } from 'ng-sidebar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmModalComponent } from '../core/modals/confirm-modal/confirm-modal.component';
import { BaseComponent } from './components/base-component.component';
import { BaseSubscriberComponent } from './components/base-subscriber.component';
import { BaseFormComponent } from './components/base-form.component';
import { DatepickerComponent } from '../core/datepicker/datepicker.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@busacca/ng-pick-datetime';
import { TimepickerComponent } from '../core/timepicker/timepicker.component';
import { PendingInitiativesPlaceholderComponent } from '../core/placeholders/pending-initiatives/pending-initiatives-placeholder.component';
import { NewItemCardComponent } from '../core/cards/new-item-card/new-item-card.component';
import { NewConceptCardComponent } from '../core/cards/new-concept-card/new-concept-card.component';
import { NewSyllabusCardComponent } from '../core/cards/new-syllabus-card/new-syllabus-card.component';
import { CreateInitiativeCardPlaceholderComponent } from '../core/placeholders/create-initiative-card/create-initiative-card-placeholder.component';
import { StagesPlaceholderComponent } from '../core/placeholders/stages/stages-placeholder.component';
import { DisableFormControlDirective } from './directive/disable-form-control.directive';
import { AgmCoreModule } from '@agm/core';
import { FileUploadModule } from 'ng2-file-upload';
import { OrderByDatePipe } from './pipes/order-by-date.pipe';
import { ScormPlayerComponent } from '../core/scorm-player/scorm-player.component';
import { MultilinesTextPlaceholderComponent } from '../core/placeholders/multilines-text-placeholder/multilines-text-placeholder.component';
import { InputPlaceholderComponent } from '../core/placeholders/input/input-placeholder.component';
import { ItemInLearningPlanComponent } from '../core/item-in-learning-plan/item-in-learning-plan.component';
import { SectionPlaceholderComponent } from '../core/placeholders/section-placeholder/section-placeholder.component';
import { SectionComponent } from '../core/section/section.component';
import { ItemInLearningPlanPlaceholderComponent } from '../core/placeholders/item-in-learning-plan-placeholder/item-in-learning-plan-placeholder.component';
import { LinkedInShareComponent } from './components/linkedin-share/linkedin-share.component';
import { AzurePlayerComponent } from '../core/azure-player/azure-player.component';
import { LibraryAlloyModule } from 'library-alloy/dist/library-alloy';
import { ItemDetailsComponent } from '../core/item-details/item-details.component';
import { OrderByUserStatusPipe } from './pipes/order-by-user-status.pipe';
import { CourseCardPlaceholderComponent } from '../core/placeholders/course-card-placeholder/course-card-placeholder.component';
import { SurveyResultComponent } from '../takers/survey-result/survey-result.component';
import { SurveyComponent } from '../takers/survey/survey.component';
import { ProgressBarComponent } from '../core/progress-bar/progress-bar.component';
import { NewsCardPlaceholderComponent } from '../core/placeholders/news-card-placeholder/news-card-placeholder.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { LibraryCardPlaceholderComponent } from '../core/placeholders/library-card-placeholder/library-card-placeholder.component';
import { NgInitDirective } from './directive/ng-init.directive';
import { VerticalWidgetComponent } from '../dashboard/vertical-widget/vertical-widget.component';
import { BigWidgetComponent } from '../dashboard/big-widget/big-widget.component';
import { ActiveFiltersButtonComponent } from '../dashboard/filters/active-filters-button/active-filters-button.component';
import { SectionFiltersButtonComponent } from '../dashboard/filters/section-filters/section-filters.component';
import { BigWidgetPlaceholderComponent } from '../dashboard/placeholders/big-widget-placeholder/big-widget-placeholder.component';
import { SmallWidgetPlaceholderComponent } from '../dashboard/placeholders/small-widget-placeholder/small-widget-placeholder.component';
import { VerticalWidgetPlaceholderComponent } from '../dashboard/placeholders/vertical-widget-placeholder/vertical-widget-placeholder.component';
import { SmallWidgetComponent } from '../dashboard/small-widget/small-widget.component';
import { NumberAbbreviationPipe } from './pipes/number-abbreviation';
import { ItemCertificateComponent } from '../core/item-certificate/item-certificate.component';
import { NgxAutocomPlaceDirective } from './directive/ngx-autocom-place.directive';
import { MapsInputComponent } from './components/maps-input/maps-input.component';
import { CardSyllabusComponent } from '../initiatives/initiative-edit/steppers/from-syllabus/card-syllabus/card-syllabus.component';
import { TabComponent } from '../core/tab/tab.component';
import { SyllabusBasicEditorComponent } from '../core/syllabus-basic-editor/syllabus-basic-editor.component';
import { TabSetComponent } from '../core/tabset/tabset.component';
import { OrderByAttributeOrder } from './pipes/order-by-attribute-order.pipe';
import { SyllabusPlaceholderComponent } from '../core/placeholders/syllabus/syllabus-placeholder.component';
import { CoursePlaceholderComponent } from '../core/placeholders/course-placeholder/course-placeholder.component';
import { DxCircularGaugeModule, DxLinearGaugeModule, DxSliderModule } from 'devextreme-angular';
import { FilterSharedFilePipe } from './pipes/filter-file-shared.pipe';
import { FormatFileSizePipe } from './pipes/format-file-size.pipe';
import { CreditWidgetComponent } from './components/credit-widget/credit-widget.component';
import { CreditListComponent } from './components/credit-list/credit-list.component';
import { MinutesToHours } from './pipes/minutes-to-hour.pipe';
import { SelectTmpComponent } from './components/select-tmp/select-tmp.component';
import { LearningPlanTrackerComponent } from './components/learning-plan-tracker/learning-plan-tracker.component';
import { BitmovinPlayerComponent } from '../core/bitmovin-player/bitmovin-player.component';

@NgModule({
  declarations: [
    OrderByDatePipe,
    OrderByUserStatusPipe,
    FilterSharedFilePipe,
    FormatFileSizePipe,
    PaginationComponent,
    ScormPlayerComponent,
    InputPlaceholderComponent,
    MultilinesTextPlaceholderComponent,
    BaseComponent,
    BaseSubscriberComponent,
    BaseFormComponent,
    ConfirmModalComponent,
    DatepickerComponent,
    TimepickerComponent,
    CreateInitiativeCardPlaceholderComponent,
    NewSyllabusCardComponent,
    CourseCardPlaceholderComponent,
    NewConceptCardComponent,
    StagesPlaceholderComponent,
    NewItemCardComponent,
    ProgressBarComponent,
    PendingInitiativesPlaceholderComponent,
    CoursePlaceholderComponent,
    DisableFormControlDirective,
    SurveyComponent,
    SurveyResultComponent,
    AzurePlayerComponent,
    ItemInLearningPlanComponent,
    LinkedInShareComponent,
    SectionPlaceholderComponent,
    SectionComponent,
    ItemDetailsComponent,
    ItemInLearningPlanPlaceholderComponent,
    NewsCardPlaceholderComponent,
    NgInitDirective,
    LibraryCardPlaceholderComponent,
    SmallWidgetComponent,
    BigWidgetComponent,
    BigWidgetPlaceholderComponent,
    BigWidgetComponent,
    SmallWidgetPlaceholderComponent,
    VerticalWidgetPlaceholderComponent,
    ActiveFiltersButtonComponent,
    SectionFiltersButtonComponent,
    VerticalWidgetComponent,
    NumberAbbreviationPipe,
    LibraryCardPlaceholderComponent,
    ItemCertificateComponent,
    NgxAutocomPlaceDirective,
    MapsInputComponent,
    CardSyllabusComponent,
    TabComponent,
    SyllabusBasicEditorComponent,
    TabSetComponent,
    SyllabusPlaceholderComponent,
    OrderByAttributeOrder,
    CreditWidgetComponent,
    CreditListComponent,
    MinutesToHours,
    SelectTmpComponent,
    LearningPlanTrackerComponent,
    BitmovinPlayerComponent,
  ],
  imports: [
    DxCircularGaugeModule,
    DxLinearGaugeModule,
    DxSliderModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    AppTranslateModule.forRoot(),
    FileUploadModule,
    CKEditorModule,
    LibraryAlloyModule,
    ImageCropperModule,
    MomentModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBZ25MNayUTrWKe9uvvPj3DyoihFuGpCvY',
      libraries: ['places']
    }),
    SidebarModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  exports: [
    ItemCertificateComponent,
    CommonModule,
    AppTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    LibraryAlloyModule,
    CKEditorModule,
    ProgressBarComponent,
    ImageCropperModule,
    ScormPlayerComponent,
    LinkedInShareComponent,
    ItemDetailsComponent,
    SectionPlaceholderComponent,
    SectionComponent,
    InputPlaceholderComponent,
    ItemInLearningPlanComponent,
    MultilinesTextPlaceholderComponent,
    FileUploadModule,
    ItemInLearningPlanPlaceholderComponent,
    PaginationComponent,
    OrderByDatePipe,
    OrderByUserStatusPipe,
    FilterSharedFilePipe,
    FormatFileSizePipe,
    MomentModule,
    AgmCoreModule,
    SidebarModule,
    BaseComponent,
    ConfirmModalComponent,
    CreateInitiativeCardPlaceholderComponent,
    StagesPlaceholderComponent,
    NewSyllabusCardComponent,
    CourseCardPlaceholderComponent,
    NewConceptCardComponent,
    NewItemCardComponent,
    BaseSubscriberComponent,
    BaseFormComponent,
    DatepickerComponent,
    TimepickerComponent,
    PendingInitiativesPlaceholderComponent,
    CoursePlaceholderComponent,
    DisableFormControlDirective,
    AzurePlayerComponent,
    NewsCardPlaceholderComponent,
    NgInitDirective,
    LibraryCardPlaceholderComponent,
    SmallWidgetComponent,
    BigWidgetComponent,
    BigWidgetPlaceholderComponent,
    BigWidgetComponent,
    SmallWidgetPlaceholderComponent,
    VerticalWidgetPlaceholderComponent,
    ActiveFiltersButtonComponent,
    SectionFiltersButtonComponent,
    VerticalWidgetComponent,
    NumberAbbreviationPipe,
    LibraryCardPlaceholderComponent,
    NgxAutocomPlaceDirective,
    MapsInputComponent,
    CardSyllabusComponent,
    TabComponent,
    SyllabusBasicEditorComponent,
    TabSetComponent,
    SyllabusPlaceholderComponent,
    OrderByAttributeOrder,
    CreditWidgetComponent,
    CreditListComponent,
    MinutesToHours,
    SelectTmpComponent,
    LearningPlanTrackerComponent,
    BitmovinPlayerComponent,
  ]
})

export class SharedModule {
}
