/*
 * Servizio che permette le chiamate rest relative ai Supplier (fornitori)
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as SupplierActions from '../ngrx/supplier.actions';
import * as InitiativeActions from '../../initiatives/ngrx/initiative.actions';
import * as fromApp from '../../ngrx/app.reducers';
import { GlobalApplicationData } from "../../shared/models/global-application-data.model";
import { Observable, Subscription } from "rxjs";
import { combineLatest } from "rxjs";
import { Item, Supplier_new, SenecaResponse, SupplierTask, SupplierSorting, TextTemplate } from "../../../cm2-commonclasses";
import { ExtendedSupplierPerson } from "../../shared/models/extended-supplier-person.model";

@Injectable()
export class SupplierService {
  applicationData: GlobalApplicationData;
  fromRecord: number;
  numRecords: number;
  result$: Subscription;
  currentPage: number;
  suppliers: any[];
  supplierTextFilter: string;

  constructor(private store: Store<fromApp.AppState>, private http: HttpClient) {
    // Dallo Store applicativo, ricavo l'url per le chiamate rest e anche la pagina corrente in cui mi trovo nella visualizzazione delle iniziative
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    let numRecords$: Observable<number> = this.store.select(fromApp.getSuppliersNumRecords);
    let fromRecord$: Observable<number> = this.store.select(fromApp.getSuppliersFromRecord);
    let currentPage$: Observable<number> = this.store.select(fromApp.getSuppliersPage);
    let supplierTextFilter$: Observable<string> = this.store.select(fromApp.getSupplierTextFilter);
    const combinedSelectes$ = combineLatest(globalApplicationData$, fromRecord$, numRecords$, currentPage$, supplierTextFilter$);
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData, fromRecord, numRecords, currentPage, supplierTextFilter]) => {
        this.applicationData = globalApplicationData;
        this.fromRecord = fromRecord;
        this.numRecords = numRecords;
        this.currentPage = currentPage;
        this.supplierTextFilter = supplierTextFilter;
      });
  }

  // Converte la pagina in una paginazione
  paginate(): { fromRecord: number, numRecords: number } {
    let fromRecord: number = 0;
    // Offeset e ResultLimit sono due parametri passati automaticamente dal componente della tabella. La prima volta, non esisteranno. Le altre sì, e mi serviranno per sapere se sto procedendo o retrocedendo nella ricerca
    if (this.currentPage && this.numRecords) {
      fromRecord = (this.currentPage - 1) * this.numRecords;
    } else {
      fromRecord = 0;
    }

    let pagination = {
      fromRecord: fromRecord,
      numRecords: this.numRecords
    }

    // Eseguo il dispatch dell'azione che salva la paginazione
    this.store.dispatch(new SupplierActions.SetPagination(pagination));

    return pagination;
  }

  setCurrentWizardStep(stepId: string) {
    this.store.dispatch(new InitiativeActions.SetCurrentWizardStep(stepId));
  }

  // Servizio che aggiorna un Syllabus
  updateSyllabus(syllabus: Item) {
    return this.http.post<SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/update-syllabus-for-supplier', {
      syllabus: syllabus
    });
  }

  // Servizio che crea un Supplier
  createSupplier(supplier: any) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-supplier', {
      supplier: supplier
    });
  }

  // Servizio che crea una Supplier person
  createSupplierPerson(supplierPerson: ExtendedSupplierPerson) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-supplier-person', {
      supplierPerson: supplierPerson
    });
  }

  // Servizio che aggiorna un Supplier
  updateSupplier(supplier: any) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/update-supplier', {
      supplier: supplier
    });
  }

  // Crea un nuovo allegato
  uploadSupplierPersonAttachment(uploadObj) {
    let formData = new FormData();
    formData.append('file', uploadObj.file.file.rawFile);
    // formData.append('entryId', uploadObj.entryId || '');
    formData.append('title', uploadObj.title || '');
    // formData.append('itemId', uploadObj.itemId || '');
    formData.append('originApplicationName', uploadObj.originApplicationName || '');
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/upload-any-file', formData, options);
    return this.http.request(req);
  }

  getSupplierPersonAttachment(uploadId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('uploadId', uploadId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-upload-by-id', {
      params: httpParams
    });
  }

  deleteUploadedData(uploadId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-upload-file', {
      uploadId: uploadId
    });
  }

  // Servizio che crea una persona dentro un Supplier
  updateSupplierPerson(supplierPerson: ExtendedSupplierPerson, returnFullAttributes?: boolean) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/update-supplier-person', {
      supplierPerson: supplierPerson,
      returnFullAttributes: returnFullAttributes
    });
  }

  // Servizio che cancella una persona di un Supplier dato il suo id
  deleteSupplierPerson(supplierPersonId: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('supplierPersonId', supplierPersonId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-supplier-person/' + supplierPersonId, {
      params: httpParams
    });
  }

  // Servizio che cancella un Supplier dato il suo id
  deleteSupplier(supplierId: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('supplierId', supplierId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-supplier/' + supplierId, {
      params: httpParams
    });
  }

  // Servizio che recupera il counter delle supplier person
  countSupplierPersonsBySupplierId(supplierId: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('supplierId', supplierId || '');
    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-supplier-persons-by-supplierId', {
      params: httpParams
    });
  }

  // Servizio che recupera e supplier person
  listSupplierPersonsBySupplierId(supplierId: string, fromRecord?: number, numRecords?: number, allData?: boolean, withAttributesLoaded?: boolean, skipPrivateData?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', (fromRecord && fromRecord.toString()) || (allData ? '' : '0'));
    httpParams = httpParams.append('numRecords', (numRecords && numRecords.toString()) || '');
    httpParams = httpParams.append('allData', (allData && allData.toString()) || '');
    httpParams = httpParams.append('withAttributesLoaded', (withAttributesLoaded && withAttributesLoaded.toString()) || '');
    httpParams = httpParams.append('supplierId', supplierId || '');
    httpParams = httpParams.append('skipPrivateData', skipPrivateData.toString() || '');
    return this.http.get<SenecaResponse<ExtendedSupplierPerson[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-supplier-persons-by-supplierId', {
      params: httpParams
    });
  }

  // Servizio che recupera il counter dei fornitori
  countSuppliers(supplierName?: string, supplierType?: string[]) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    if (supplierType && supplierType.length) {
      supplierType.forEach(supplierType => {
        httpParams = httpParams.append('supplierType', supplierType);
      });
    }
    httpParams = httpParams.append('supplierName', supplierName || this.supplierTextFilter || '');
    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-suppliers-by-any', {
      params: httpParams
    });
  }

  // Servizio che recupera il counter dei course supplier
  countCourseSuppliersByAny(supplierName?: string, searchIntoFaculty?: boolean) {
    // Cambio servizio in base alla tipologia di fornitore voluta (course supplier o faculty trainer)
    if (searchIntoFaculty) {
      return this.countFacultyTrainersByAny(supplierName);
    } else {
      // Preparo i parametri per la richiesta http
      let httpParams = new HttpParams();
      httpParams = httpParams.append('supplierName', supplierName || '');
      return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-course-suppliers-by-any', {
        params: httpParams
      });
    }
  }

  // Servizio che recupera i course supplier
  listCourseSuppliersByAny(supplierName?: string, fromRecord?: number, numRecords?: number, sorting?: string, searchIntoFaculty?: boolean) {
    // Cambio servizio in base alla tipologia di fornitore voluta (course supplier o faculty trainer)
    if (searchIntoFaculty) {
      return this.listFacultyTrainersByAny(supplierName, fromRecord, numRecords);
    } else {
      // Calcolo la paginazione per vedere da quale risultato devo chiedere i dati ai servizi
      let pagination = {
        fromRecord: fromRecord,
        numRecords: numRecords
      };

      // Preparo i parametri per la richiesta http
      let httpParams = new HttpParams();
      httpParams = httpParams.append('fromRecord', pagination && pagination.fromRecord && pagination.fromRecord.toString());
      httpParams = httpParams.append('numRecords', pagination && pagination.numRecords && pagination.numRecords.toString());
      httpParams = httpParams.append('supplierName', supplierName || '');
      httpParams = httpParams.append('sorting', sorting || '');

      return this.http.get<SenecaResponse<{ suppliers: Supplier_new[] }>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-course-suppliers-by-any/' + pagination.fromRecord + '/' + pagination.numRecords, {
        params: httpParams
      });
    }
  }

  // Servizio che recupera il counter dei faculty trainers
  countFacultyTrainersByAny(supplierName?: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('supplierName', supplierName || '');
    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-faculty-trainers-by-any', {
      params: httpParams
    });
  }

  // Servizio che recupera i faculty trainers
  listFacultyTrainersByAny(supplierName?: string, fromRecord?: number, numRecords?: number) {
    // Calcolo la paginazione per vedere da quale risultato devo chiedere i dati ai servizi
    let pagination = {
      fromRecord: fromRecord,
      numRecords: numRecords
    };

    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', pagination && pagination.fromRecord && pagination.fromRecord.toString());
    httpParams = httpParams.append('numRecords', pagination && pagination.numRecords && pagination.numRecords.toString());
    httpParams = httpParams.append('supplierName', supplierName || '');

    return this.http.get<SenecaResponse<{ suppliers: Supplier_new[] }>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-faculty-trainers-by-any', {
      params: httpParams
    });
  }

  // Servizio che recupera una lista di fornitori
  getSuppliers(supplierName?: string, supplierType?: string[], customPagination?: boolean, fromRecord?: number, numRecords?: number, sorting?: string) {

    // Calcolo la paginazione per vedere da quale risultato devo chiedere i dati ai servizi
    let pagination = null;
    if (!customPagination) {
      pagination = this.paginate();
    } else {
      pagination = {
        fromRecord: fromRecord,
        numRecords: numRecords
      }
    }

    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', pagination && pagination.fromRecord && pagination.fromRecord.toString());
    httpParams = httpParams.append('numRecords', pagination && pagination.numRecords && pagination.numRecords.toString());
    httpParams = httpParams.append('supplierName', supplierName || this.supplierTextFilter || '');
    httpParams = httpParams.append('sorting', sorting || SupplierSorting.SUPPLIER_NAME_ASC);
    if (supplierType && supplierType.length) {
      supplierType.forEach(supplierType => {
        httpParams = httpParams.append('supplierType', supplierType);
      });
    }

    return this.http.get<SenecaResponse<{ suppliers: Supplier_new[] }>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-suppliers-by-any/' + pagination.fromRecord + '/' + pagination.numRecords, {
      params: httpParams
    });
  }

  // Servizio che recupera un Supplier dato il suo id
  getSupplierById(supplierId: string, withAttributesLoaded?: boolean, withFullAttributesLoaded?: boolean, withPersonsLoaded?: boolean, withOpenTasksLoaded?: boolean, ignoreActive?: boolean, ignoreTenant?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('supplierId', supplierId);
    httpParams = httpParams.append('withAttributesLoaded', withAttributesLoaded && withAttributesLoaded.toString());
    httpParams = httpParams.append('withFullAttributesLoaded', withFullAttributesLoaded && withFullAttributesLoaded.toString());
    httpParams = httpParams.append('withPersonsLoaded', withPersonsLoaded && withPersonsLoaded.toString());
    httpParams = httpParams.append('withOpenTasksLoaded', withOpenTasksLoaded && withOpenTasksLoaded.toString());
    httpParams = httpParams.append('ignoreActive', ignoreActive && ignoreActive.toString());
    httpParams = httpParams.append('ignoreTenant', ignoreTenant && ignoreTenant.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-supplier-by-id/' + supplierId, {
      params: httpParams
    });
  }

  // Servizio che recupera i dati di una persona di un Supplier dato il suo id
  getSupplierPersonById(supplierPersonId: string, withAttributesLoaded?: boolean, withFullAttributesLoaded?: boolean, withPersonsLoaded?: boolean, ignoreActive?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('supplierPersonId', supplierPersonId);
    httpParams = httpParams.append('withAttributesLoaded', withAttributesLoaded && withAttributesLoaded.toString());
    httpParams = httpParams.append('withFullAttributesLoaded', withFullAttributesLoaded && withFullAttributesLoaded.toString());
    httpParams = httpParams.append('withPersonsLoaded', withPersonsLoaded && withPersonsLoaded.toString());
    httpParams = httpParams.append('ignoreActive', ignoreActive && ignoreActive.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-supplier-person-by-id/' + supplierPersonId, {
      params: httpParams
    });
  }

  /**
   * Ritorna la liste delle iniziative per il Portale Fornitori
   * @param fromRecord 
   * @param numRecords 
   * @param showInitiativeFor 
   *  filtro sullo stato delle iniziative da cercare
   */
  getLatestSupplierInitiatives(fromRecord: string, numRecords: string, searchedText: string, showInitiativeFor: string): any {

    if (showInitiativeFor == showInitiativeFor && showInitiativeFor === 'ARCHIVED_INITIATIVES') {
      return this.getLatestArchivedSupplierInitiatives(fromRecord, numRecords, searchedText);
    } else if (showInitiativeFor == showInitiativeFor && showInitiativeFor === 'DEROGATION_COURSES') {
      return this.getDerogatedCoursesForSupplier(fromRecord, numRecords, searchedText);
    }
    return this.getLatestSuspendedSupplierInitiatives(fromRecord, numRecords, searchedText);
  }

  /**
   * Ritorna la liste delle iniziative in sospeso per il Portale Fornitori
   * @param fromRecord 
   * @param numRecords 
   */
  getLatestSuspendedSupplierInitiatives(fromRecord: string, numRecords: string, searchedText: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    httpParams = httpParams.append('title', searchedText);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-supplier-initiatives/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  /**
   * Ritorna la liste delle iniziative archiviate per il Portale Fornitori
   * @param fromRecord 
   * @param numRecords 
   */
  getLatestArchivedSupplierInitiatives(fromRecord: string, numRecords: string, searchedText: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('closedTaskOnly', "true");
    httpParams = httpParams.append('title', searchedText);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-supplier-initiatives/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  /**
   * Ritorna la liste delle iniziative derogate ai Fornitori
   * @param fromRecord 
   * @param numRecords 
   */
  getDerogatedCoursesForSupplier(fromRecord: string, numRecords: string, searchedText: string, allData?: boolean): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('title', searchedText);
    httpParams = httpParams.append('fromRecord', (fromRecord && fromRecord.toString()) || (allData ? '' : '0'));
    httpParams = httpParams.append('numRecords', (numRecords && numRecords.toString()) || '');
    httpParams = httpParams.append('allData', (allData && allData.toString()) || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-derogated-courses-for-supplier', {
      params: httpParams
    });
  }

  /**
   * Torna uno Stage dato il suo Id
   * @param stageItemId 
   */
  getStageFromIdForSupplier(stageItemId: string): any {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('stageItemId', stageItemId);
    httpParams = httpParams.append('withFullAttributesLoaded', 'true');
    httpParams = httpParams.append('withFullChildrenAttributesLoaded', 'true');

    return this.http.get<SenecaResponse<TextTemplate[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-stage-from-id-for-supplier/' + stageItemId, {
      params: httpParams
    });
  }

  /**
   * Servizio che recupera un Item dato il suo id
   * @param itemId 
   * @param withChildsLoaded 
   */
  getItemByIdForSupplier(itemId: string, withChildsLoaded?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    httpParams = withChildsLoaded ? httpParams.append('withChildsLoaded', 'true') : httpParams;
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-item-by-id-for-supplier/' + itemId, {
      params: httpParams
    });
  }

  /**
   * Crea e carica un nuova Offerta (portale Supplier)
   * @param uploadObj 
   */
  createSupplierProposal(uploadObj: any) {

    let formData = new FormData();
    formData.append('file', uploadObj.file);

    let httpParams = new HttpParams();
    httpParams = httpParams.append('parentSupplierTaskId', uploadObj.supplierTaskId);
    httpParams = httpParams.append('title', uploadObj.title);
    httpParams = httpParams.append('courseType', uploadObj.courseType);
    httpParams = httpParams.append('price', uploadObj.price);


    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-supplier-proposal', formData, {
      params: httpParams
    })
  }


  /**
   * Crea e carica un nuova Fattura (portale Supplier)
   * @param uploadObj 
   */
  createSupplierDepositSlip(uploadObj: any) {
    let formData = new FormData();
    formData.append('file', uploadObj.file);

    let httpParams = new HttpParams();
    httpParams = httpParams.append('parentSupplierTaskId', uploadObj.supplierTaskId);
    httpParams = httpParams.append('title', uploadObj.title);
    httpParams = httpParams.append('invoiceNumber', uploadObj.invoiceNumber);
    httpParams = httpParams.append('invoiceDate', uploadObj.invoiceDate);
    httpParams = httpParams.append('invoiceAmount', uploadObj.invoiceAmount);

    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-supplier-invoice', formData, {
      params: httpParams
    });
  }

  /**
   * Crea e carica un nuovo Materiale (portale Supplier)
   * @param uploadObj 
   */
  createSupplierMaterial(uploadObj: any, editionId: string) {
    let formData = new FormData();
    formData.append('file', uploadObj.file);

    let httpParams = new HttpParams();
    httpParams = httpParams.append('parentSupplierTaskId', uploadObj.supplierTaskId);
    httpParams = httpParams.append('title', uploadObj.title);
    httpParams = httpParams.append('materialType', uploadObj.materialType.code);
    httpParams = httpParams.append('editionId', editionId);

    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-supplier-material', formData, {
      params: httpParams
    });
  }

  /**
   * Ritorna la lista delle offerte (portale Supplier)
   */
  getLatestProposals(taskId: string, fromRecord: number, numRecords: number) {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('parentSupplierTaskId', taskId);
    httpParams = httpParams.append('searchClosedStatesToo', "true");

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-supplier-proposals-by-parent/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  /**
   * Ritorna la lista delle fatture (portale Supplier)
   */
  getLatestDepositSlips(taskId: string, fromRecord: number, numRecords: number) {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('parentSupplierTaskId', taskId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-supplier-invoices-by-parent/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  /**
   * Ritorna la lista delle edizioni (portale Supplier)
   * @param taskId 
   */
  getLatestEditions(taskId: string) {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('supplierTaskId', taskId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-course-editions-by-supplier-task-id/', {
      params: httpParams
    });
  }

  /**
   * Ritorna la lista delle note (portale Supplier) 
   */
  listSupplierNotesForSupplier(supplierTaskId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('supplierTaskId', supplierTaskId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-notes-for-supplier/' + supplierTaskId, {
      params: httpParams
    });
  }

  /**
   * Crea una nota (portale Supplier) 
   */
  createSupplierNoteForSupplier(supplierTaskId: string, noteText: string) {

    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-note-for-supplier/' + supplierTaskId, {
      noteText: noteText
    });
  }

  /**
   * Cancella una nota (portale Supplier) 
   */
  deleteSupplierNoteForSupplier(noteId: string) {

    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-note-for-supplier/' + noteId, {
    });
  }

  /**
   * Approva un edizione (portale Supplier) 
   */
  approveEditionForSupplier(editionId: string) {

    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/approve-course-edition-for-supplier/', {
      editionId: editionId
    });
  }

  /**
   * Disapprova un edizione (portale Supplier) 
   */
  negateEditionForSupplier(editionId: string) {

    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/negate-course-edition-for-supplier/', {
      editionId: editionId
    });
  }

  /**
   * Recupera le edizione dato l'id di un iniziativa (portale Supplier) 
   */
  getEditionsByStageItemId(stageId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('stageItemId', stageId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-editions-by-stage-item-id/', {
      params: httpParams
    });
  }

  /**
   * Recupera i materiali dato il task id (portale Supplier) 
   */
  getLatestMaterials(taskId: string, fromRecord: number, numRecords: number, supplierId?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('parentSupplierTaskId', taskId);
    httpParams = httpParams.append('supplierId', supplierId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-supplier-materials-by-parent/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  /**
   * Assegna i docenti ad una data edizione
   */
  assignTeachersToEdition(taskId: string, teacherIds: string[], courseDayId: string) {

    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/assign-teachers-to-course-edition-for-supplier/', {
      editionId: taskId,
      teacherIds: teacherIds,
      courseDayId: courseDayId
    });
  }

  /**
   * Assegna i tutor ad una data edizione
   */
  assignTutorsToEdition(taskId: string, tutorIds: string[], courseDayId: string) {

    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/assign-tutors-to-course-edition-for-supplier/', {
      editionId: taskId,
      tutorIds: tutorIds,
      courseDayId: courseDayId
    });
  }

  cancelStatusSupplierTaskFile(parentSupplierTaskId: string, SupplierTask: SupplierTask) {

    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/cancel-status-for-supplier-task-file/', {
      supplierTask: SupplierTask,
      parentSupplierTaskId: parentSupplierTaskId

    });
  }

  /**
   * Ritorna il supplier task dato un supplierTaskId (portale Supplier)
   */
  getSupplierTaskBySupplierTaskId(supplierTaskId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('supplierTaskId', supplierTaskId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-supplier-task-by-supplierTaskId/', {
      params: httpParams
    });
  }
}