import { TranslateService } from '@ngx-translate/core';
import { CommonConstants, DateUtil, defaultTo, Engagement, Item, ItemAttribute, ItemAttributeTypes, ItemChild, ItemLang, ItemTenant, ItemTypes, ItemVisibility, parseBoolean, ReferenceTypes, sortByRules, MailSender, Lang, ItemAttributeObjectTypes, AttributeTypes, ItemTakerAttributeTypes, SupplierPersonAttributeTypes, ItemTakerEnrollStatusTypes } from "../../../cm2-commonclasses";
import { ExtendedItemAttribute } from './extended-item-attribute.model';
import { ExtendedItem } from './extended-item.model';
import { InitiativeUtils } from './initiatives.model';
import { ItemUtil } from './item.model';
import { SupplierTask } from "../../../cm2-commonclasses";
import * as moment from 'moment';
import { LangsService } from 'src/app/core/services/langs.service';
import { UrlService } from '../services/url.service';

class FrontEndDate {
  courseEditionIndex: number;
  markerClass: string;
  editionId: string;
  dayId: string;
  address: string;
  room: string;
  dayDate: string;
  schedules: {
    startTime: string;
    endTime: string;
  }[];
  stopDate: string;
  status: string;
  place: string;
  invited: number;
  roomCapacity: number;
  seatsLimit: number;
  sellerPercentage: number;
  price: number;
  suppliers: string[];
}

/**
 * CourseStage rappresenta il "CORSO" cioè l'accoppiamento logico (iniziativa - modulo) manipolando le edizioni
 */
export class CourseStage {
  // item id del course module
  moduleItemId: string;
  // item id dell'iniziativa (che sarà sempre un corso o una tappa nel contesto delle edizioni)
  stageItemId: string;

  initiativeType: string;

  // informazioni da salvare nell'iniziativa (che sarà sempre un corso od una tappa nel contesto delle edizioni)
  courseValueInMinutes: number; // numero di ore convertite in minuti in scrittura
  multipleEditionAttendance: boolean; // permette iscrizione a più edizioni

  isPublicInitiative: boolean;
  price: number;

  constructor() {
    this.multipleEditionAttendance = false;
  }

  // courseValue in hours
  get courseValue() {
    return this.courseValueInMinutes ? (this.courseValueInMinutes / 60) : 0;
  }
  set courseValue(_value: any) {
    this.courseValueInMinutes = (_value ? parseInt(_value, 10) : 0) * 60;
  }
}

/**
 * Supplier
 */
export class SupplierFEData {
  moduleItem: Item;
  initiativeItemId: string;
  supplierId: string;
  supplierName: string;
  odaNumber: string;
  odaDate: string;
  hasPossibleSupplier: boolean;
  hasSelectedSupplier: boolean;
  hasODA: boolean;
}


/**
 * MM Vedi se rimuovere
 */
export class SupplierTaskWithInitiativeData {
  initiative: ExtendedItem;
  task: SupplierTask;
}

/**
 * COURSE_EDITION
 */
export class CourseEdition {
  // item id dell'edizione
  itemId: string;

  get isOnlineCourse() {
    return this.parentCourse && InitiativeUtils.isOnlineInitiative(this.parentCourse.initiativeType);
  }

  get isWebinar() {
    return this.parentCourse && InitiativeUtils.isWebinar(this.parentCourse.initiativeType);
  }

  get hasMoreDays() {
    return this.courseSchedules && this.courseSchedules.length > 1;
  }

  parentCourse: CourseStage;

  firstDayDate: string; // data iso con time
  lastDayDate: string; // data iso con time
  endEnrollDate: string; // data iso con time
  courseSchedules: Array<CourseDaySchedule>;

  status: string;

  isSelected: boolean;
  invitedPeople: any[];

  courseEditionFunded: boolean;
  limitedSeatsCourseEdition: boolean;
  verifySupplierAvailability: boolean;
  autoPublish: boolean;
  editionName?: string;

  subscriptionCustomLink?: string;
  subscriptionCustomMail?: string;

  identificationCode?: string;
  identificationCodeDesc?: string;
  sellers: Array<any>;
  sellerPercentage: number;
  price: number;

  itemLangs?: ItemLang[];

  seatsLimit: number;
  get availableSeats(): number {
    return (!this.usedSeats && this.seatsLimit)
      || (this.seatsLimit && this.usedSeats >= 0 && (this.seatsLimit - this.usedSeats)) || 0;
  }
  usedSeats?: number;
  overbookingLimit: number;
  get availableWithOverbooking(): number {
    return (!this.usedSeats && this.seatsLimit)
      || (this.overbookingLimit && this.usedOverbooking >= 0 && (this.overbookingLimit - this.usedOverbooking)) || 0;
  }
  usedOverbooking?: number;
  itemAttributes: ItemAttribute[];
  reminderActive: boolean;
  reminderDate: string;
  solicitsActive: boolean;
  firstSolicitAfterHours: number;
  secondSolicitAfterHours: number;
  thirdSolicitAfterHours: number;
  recurrentSolicitsActive: boolean;
  recurrentSolicitsAfterHours: number; // Ogni quante ore
  recurrentSolicitsDeadlineAfterDays: number; // Per quanti giorni
  recurrentSolicitsAfterHoursAfterDeadline: number;
  calendarTypeOnConfirm: string;
  eTicket: boolean;
  enableUsableTakes: boolean;
  enableUsableTakesHint: string;
  maxUsableTakes: number;
  enableCustomTitleAndSubtitleView: boolean;
  customTitle: string;
  customSubTitle: string;
  datesByPlace: any[];
  /** counter step invitati */
  invitedPeopleCounter: number;
  /** counter step edizioni */
  invited?: number;
  approvalStatus?: string;
  engagements?: Engagement[];
  isAlreadyCertified?: boolean;
  isItemAlreadyCertified?: boolean;
  certifiedDate?: Date;
  isSurveyStarted?: boolean;
  isSurveyCertificable?: boolean;
  isConsumed?: boolean;
  expiredEndEnroll?: boolean;

  constructor() {
    this.parentCourse = new CourseStage();
    this.courseSchedules = new Array<CourseDaySchedule>();
    this.calendarTypeOnConfirm = 'USER_ONLY';
    this.invitedPeopleCounter = 0;
    this.invited = 0;
    this.firstDayDate = null;
    this.lastDayDate = null;
  }
}

export class CourseEditionOptions extends CourseEdition {
  confirmed: boolean;
  isOpened: boolean;
  isOnlineCourseType: boolean;
  isWebinarType: boolean;
  initiativeName?: any;
  fieldName?: any;
  confirmAction?: Function;
  onCloseAction?: Function;
  isFetchingSignatures?: boolean;
  signatureList?: any[];
  selectedSignature?: any;
  fromEmailList?: any[];
  selectedFromEmail?: MailSender;
  isLoadingRecipients?: boolean;

  constructor() {
    super();
    this.isOpened = false;
    this.calendarTypeOnConfirm = 'USER_ONLY';
    this.onCloseAction = () => { };
  }

  // Deve essere valorizzata la data del reminder se c'è il flag attivo
  isReminderValid() {
    if (this.reminderActive && !this.reminderDate) {
      return false;
    }

    return true;
  }

  // Deve essere valorizzato il titolo custom se c'è il flag attivo
  isCustomTitleView() {
    if (!this.enableCustomTitleAndSubtitleView) {
      return false;
    }
    return true;
  }

  // Deve essere valorizzato il numero se è presente il check sull'abilitazione della prenotazione
  isMaxUsableTakesValid() {
    if (this.enableUsableTakes && !this.maxUsableTakes) {
      return false;
    }

    return true;
  }

  isConfirmEnabled() {
    if (this && this.parentCourse && (
      !this.parentCourse.isPublicInitiative
      || (
        !!this.selectedFromEmail
        && !!this.selectedSignature
      )) && this.isReminderValid() && this.isMaxUsableTakesValid()) {
      return true;
    };
    return false;
  };
}

/**
 * COURSE_DAY
 */
export class CourseDaySchedule {
  itemId: string;
  dayDate: string;
  firstAndLastTimeSchedule: TimeSchedule;  // attribute order 0 usato per gli ONLINE
  morningTimeSchedule: TimeSchedule; // attribute order 0
  afternoonTimeSchedule: TimeSchedule; // attribute order 1
  location: Location;
  webinarTopicAccountId: any;
  webinarSessionId: string;
  webinarSessionType: any;
  webinarDisplayPartecipantsName: any;
  webinarAccessBeforeStart: any;
  webinarLobby: boolean;
  webinarPassword: string;
  webinarBreakoutRooms: boolean;
  webinarScreenSharing: boolean;
  webinarMediaLibrary: boolean;
  webinarRecording: boolean;
  webinarRequestToSpeak: boolean;

  teachers: Array<any>;
  tutors: Array<any>;
  itemAttributes: ItemAttribute[];
  isCourseDayFormValid?: boolean;
  webinarJoinUrl?;

  get timeSchedules() {
    let _timeSchedules: TimeSchedule[] = [];
    _timeSchedules = this.morningTimeSchedule ? _timeSchedules.concat([this.morningTimeSchedule]) : _timeSchedules;
    _timeSchedules = this.afternoonTimeSchedule ? _timeSchedules.concat([this.afternoonTimeSchedule]) : _timeSchedules;
    _timeSchedules = this.firstAndLastTimeSchedule ? _timeSchedules.concat([this.firstAndLastTimeSchedule]) : _timeSchedules;
    return _timeSchedules;
  }

  getFirstValidTimeScheduleByOrder(reverse: boolean) {
    let firstSchedule = this.timeSchedules.filter((schedule) => {
      return !!schedule.startTime;
    }).sort((x2, y2) => {
      return sortByRules<TimeSchedule>(x2, y2, !!reverse, [{ fieldName: 'startTime' }])
    })[0];
    return firstSchedule;
  }

  constructor() {
    this.location = new Location();
  }
}

export class Location {
  physicalPlaceItem: Item;
  address: any;
  streetNumber: any;
  place: any;
  city: any;
  room: any;
  roomCapacity: number;
  locationLat: number;
  locationLong: number;
  building: any;

  constructor() {
    this.physicalPlaceItem = new Item();
    this.place = null;
    this.address = null;
    this.room = null;
    this.building = null;
  }

  locationNameForCard() {
    return (this.place || this.city || this.address) && (this.place || this.city || this.address).id
  }

  getBuilding() {
    return this.building;
  }
}

export class TimeSchedule {
  startTime: string; endTime: string;
}

const locationAttrKeys = [ItemAttributeTypes.LOCATION_ADDRESS,
ItemAttributeTypes.LOCATION_BUILDING,
ItemAttributeTypes.LOCATION_CITY, ItemAttributeTypes.LOCATION_COUNTRY,
ItemAttributeTypes.LOCATION_FLOOR,
ItemAttributeTypes.LOCATION_LAT, ItemAttributeTypes.LOCATION_LONG,
ItemAttributeTypes.LOCATION_PHONE, ItemAttributeTypes.LOCATION_PLACE,
ItemAttributeTypes.LOCATION_ARRANGEMENT,
ItemAttributeTypes.LOCATION_ROOM, ItemAttributeTypes.LOCATION_ROOM_CAPACITY];

export class LocationItem extends Item {
  fullName: ItemAttribute;
  fullAddress: ItemAttribute;
  address: ItemAttribute;
  city: ItemAttribute;
  place: ItemAttribute;
  country: ItemAttribute;
  room: ItemAttribute;
  roomCapacity: ItemAttribute;
  minCapacity: ItemAttribute;
  locationLat: ItemAttribute;
  locationLong: ItemAttribute;
  phone: ItemAttribute;
  building: ItemAttribute;
  floor: ItemAttribute;
  arrangement: ItemAttribute;
  zipCode: ItemAttribute;

  constructor(item: Item) {
    super();
    Object.assign(this, item);

    this.fullName = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_FULL_NAME) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_FULL_NAME, null);
    this.fullAddress = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_FULL_ADDRESS) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_FULL_ADDRESS, null);
    this.address = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_ADDRESS) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_ADDRESS, null);
    this.room = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_ROOM) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_ROOM, null);
    this.city = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_CITY) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_CITY, null);
    this.place = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_PLACE) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_PLACE, null);
    this.country = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_COUNTRY) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_COUNTRY, null);
    this.minCapacity = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_ROOM_MIN_CAPACITY) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_ROOM_MIN_CAPACITY, null);
    this.roomCapacity = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_ROOM_CAPACITY) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_ROOM_CAPACITY, null);
    this.locationLat = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_LAT) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_LAT, null);
    this.locationLong = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_LONG) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_LONG, null);
    this.zipCode = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_ZIP) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_ZIP, null);
    this.arrangement = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_ARRANGEMENT) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_ARRANGEMENT, null);
    this.phone = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_PHONE) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_PHONE, null);
    this.building = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_BUILDING) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_BUILDING, null);
    this.floor = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_FLOOR) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_FLOOR, null);
  }

  buildFullName() {
    return `${(this.place || this.city).attributeValue} - ${(this.address).attributeValue} - ${this.room.attributeValue}`.toUpperCase();
  }
}

export function newLocationItem(tenant, langCode, title, subTitle?: string, description?: string) {
  let itemLangs = [<ItemLang>{
    langCode: langCode,
    title: title,
    subTitle: subTitle,
    description: description
  }];
  let itemTenants = [<ItemTenant>{
    tenant: tenant,
    owner: true
  }];
  let itemVisibilities: ItemVisibility[] = [];

  // creo gli attributi necessari vuoti
  let itemAttributes: ItemAttribute[] = locationAttrKeys.map((key, i) => <ItemAttribute>{
    attributeType: key,
    attributeValue: null,
    attributeOrder: 0
  });

  return new LocationItem(<Item>{
    itemType: ItemTypes.PHYSICAL_PLACE,
    indexed: false,
    unpublished: false,
    originApplicationName: CommonConstants.APPLICATION_CORPORATE_ACADEMY,
    itemAttributes: itemAttributes,
    itemLangs: itemLangs,
    itemTenants: itemTenants,
    itemVisibilities: itemVisibilities
  });
}

/*
* COURSE_MODULE utility
*/
export class CourseModuleUtil {
  // Apre la pagina della privacy
  static openPrivacy(privacyPage: string, urlService: UrlService) {
    let applicationContext = urlService.getApplicationUrl().baseUrl;
    applicationContext = applicationContext + '#/' + privacyPage;
    window.open(applicationContext, '_blank');
  }

  static listCourseEditionsFromModule(courseModuleItem: Item, initiative: Item, includeEngagements?: boolean, langsService?: LangsService, currentLang?: Lang, includeItemLangs?: boolean): Array<CourseEdition> {
    const courseEditions = new Array<CourseEdition>();
    if (!courseModuleItem || !courseModuleItem.itemChilds) {
      return courseEditions;
    }

    for (let i = 0, arrLeng = courseModuleItem.itemChilds.length; i < arrLeng; i++) {
      const child: ItemChild = courseModuleItem.itemChilds[i];
      if (child.childObject && child.childObject.itemType === ItemTypes.COURSE_EDITION) {
        const courseEdition = CourseEditionUtil.getCourseEditionModelFromItem(child.childObject, initiative, courseModuleItem, langsService, currentLang);
        if (includeEngagements) {
          courseEdition.engagements = child.childObject.engagements;
        }
        if (includeItemLangs) {
          courseEdition.itemLangs = child.childObject.itemLangs;
        }
        courseEditions.push(courseEdition);
      }
    }

    return courseEditions;
  }

  static getCourseStageFromItem(initiative: Item, moduleItem?: Item) {
    const courseModule: CourseStage = new CourseStage();
    courseModule.moduleItemId = moduleItem && moduleItem.itemId;
    courseModule.stageItemId = initiative.itemId;
    courseModule.initiativeType = initiative.itemType;
    courseModule.courseValueInMinutes = ItemUtil.getAttributeValue(initiative, ItemAttributeTypes.VALUE);
    const syllabusPriceAttribute = initiative && initiative.extendedItem && initiative.extendedItem.itemAttributes && initiative.extendedItem.itemAttributes.length && ItemUtil.getAttributeByKey(initiative.extendedItem, ItemAttributeTypes.PRICE);
    courseModule.price = syllabusPriceAttribute && syllabusPriceAttribute.description && parseFloat(defaultTo(syllabusPriceAttribute.description, 0));
    courseModule.isPublicInitiative = parseBoolean(ItemUtil.getAttributeValue(initiative, ItemAttributeTypes.PUBLIC_EDITION));
    courseModule.multipleEditionAttendance = parseBoolean(ItemUtil.getAttributeValue(initiative, ItemAttributeTypes.MULTIPLE_EDITION_ATTENDACE));
    return courseModule;
  }
}


export function locationNameForCard(location: Location) {
  if (location && location.place || location.city || location.address) {
    return (location.place || location.city || location.address).id;
  }
  return '';
}

// Formatta le date unendole tramite il luogo
export function formatEditionsByPlace(courseEditionList: any[], sort?: boolean) {
  const editionsByPlace = [];

  const places: any = {};

  // raggruppare per il luogo della prima giornata dell'edizione
  courseEditionList && courseEditionList.forEach((courseEdition: any) => {
    if (courseEdition.days && courseEdition.days.length) {
      let courseDay = courseEdition.days[0];
      const placeId = locationNameForCard(courseDay.location);
      if (placeId && places[placeId]) {
        places[placeId].editions.push(courseEdition);

      } else if (placeId && !places[placeId]) {
        places[placeId] = { editions: [] };
        places[placeId].editions.push(courseEdition);
      }
    }
  });

  for (const key in places) {
    if (places.hasOwnProperty(key)) {
      const element = places[key];
      editionsByPlace.push({
        place: key,
        editions: element.editions
      });
    }
  }

  // ordino per place
  return sort ? editionsByPlace.sort((x, y) => sortByRules<any>(x, y, false, [{ fieldName: 'place' }])) : editionsByPlace;
}

/*
* COURSE_EDITION utility
*/
export class CourseEditionUtil {
  static formatCreditsForFeCards(currentItem) {
    if (currentItem && currentItem.credits && currentItem.credits.length) {
      for (let m = 0, creditsLength = currentItem.credits.length; m < creditsLength; m++) {
        let currentCredit = currentItem.credits[m];
        if (currentCredit.creditType) {
          if (currentCredit.creditType === AttributeTypes.IVASS_TYPE) {
            if (!currentItem.ivassCredits) {
              currentItem.ivassCredits = {
                value: 0
              };
            }
            currentItem.ivassCredits.value = currentItem.ivassCredits.value + +currentCredit.value;
          } else if (currentCredit.creditType === AttributeTypes.MIFID_II_TYPE) {
            if (!currentItem.mifidCredits) {
              currentItem.mifidCredits = {
                value: 0
              };
            }
            currentItem.mifidCredits.value = currentItem.mifidCredits.value + +currentCredit.value;
          } else if (currentCredit.creditType === AttributeTypes.EFA_A_TYPE || currentCredit.creditType === AttributeTypes.EFA_B_TYPE) {
            if (!currentItem.efpaCredits) {
              currentItem.efpaCredits = {
                value: 0
              };
            }
            currentItem.efpaCredits.value = currentItem.efpaCredits.value + +currentCredit.value;
          }
        }
      }
    }
  }

  static createCourseEditionItem(courseEdition: CourseEdition, initiativeItem: Item, parentItemLangs?: ItemLang[]): Item {
    const editionItem = <Item>{};
    editionItem.itemId = courseEdition.itemId;
    editionItem.itemType = ItemTypes.COURSE_EDITION;
    // dev'essere impostato a true altrimenti tutti i taker che verranno associati ad esso verranno cancellati
    // al momento fissso
    editionItem.consumeAuthRequired = true;

    editionItem.title = 'COURSE_EDITION';

    // Se non è un corso online devo calcolare l'inizio e fine dalle giornate inserite nell'edizione

    // creo gli attributi
    CourseEditionUtil.fillCourseEditionItem(editionItem, courseEdition, initiativeItem);

    // ricostruisco la schedulazione
    CourseDayUtil.setItemCourseDaySchedules(editionItem, courseEdition.courseSchedules, courseEdition.isOnlineCourse, courseEdition.isWebinar);

    let itemLangs = parentItemLangs && parentItemLangs.length && JSON.parse(JSON.stringify(parentItemLangs));

    // Preparo il contenitore delle lang
    if (itemLangs) {
      editionItem.itemLangs = itemLangs;
      for (let i = 0, langsLength = editionItem.itemLangs.length; i < langsLength; i++) {
        let currentItemLang = editionItem.itemLangs[i];
        currentItemLang.title = editionItem.title;
        currentItemLang.subTitle = null;
      }
    }

    return editionItem;
  }

  static updateCourseEditionItem(editionItem: Item, courseEdition: CourseEdition, initiativeItem: Item, currentLang?: Lang, langsService?: LangsService): Item {
    if (courseEdition.itemAttributes && courseEdition.itemAttributes.length) {
      editionItem.itemAttributes = courseEdition.itemAttributes;
    }
    // aggiorno o creo gli attributi necessari
    CourseEditionUtil.fillCourseEditionItem(editionItem, courseEdition, initiativeItem, true, currentLang, langsService);

    let isOnline = courseEdition.parentCourse && InitiativeUtils.isOnlineInitiative(courseEdition.parentCourse.initiativeType)
      || (<CourseEditionOptions>courseEdition).isOnlineCourseType === true
      || editionItem && InitiativeUtils.isOnlineInitiative(editionItem.itemType);

    let isWebinar = courseEdition.parentCourse && InitiativeUtils.isWebinar(courseEdition.parentCourse.initiativeType)
      || (<CourseEditionOptions>courseEdition).isWebinarType === true
      || editionItem && InitiativeUtils.isWebinar(editionItem.itemType);

    // ricostruisco la schedulazione
    CourseDayUtil.setItemCourseDaySchedules(editionItem, courseEdition.courseSchedules, isOnline, isWebinar);

    return editionItem;
  }

  static fillCourseEditionItem(editionItem: Item, courseEdition: CourseEdition, initiativeItem: Item, update?: boolean, currentLang?: Lang, langsService?: LangsService) {
    ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.END_ENROLL_DATE, courseEdition.endEnrollDate, update);
    // const calculateFirstAndLastDay = !courseEdition.isOnlineCourse || (!courseEdition.parentCourse && !(<CourseEditionOptions>courseEdition).isOnlineCourseType);
    const calculateFirstAndLastDay = (!(courseEdition.parentCourse && InitiativeUtils.isOnlineInitiative(courseEdition.parentCourse.initiativeType))
      || (<CourseEditionOptions>courseEdition).isOnlineCourseType === false)
      && (
        !(courseEdition.parentCourse && InitiativeUtils.isWebinar(courseEdition.parentCourse.initiativeType))
        || (<CourseEditionOptions>courseEdition).isWebinarType === false);

    if (calculateFirstAndLastDay && courseEdition.courseSchedules && courseEdition.courseSchedules.length) {
      let firstDayDate = null;
      let lastDayDate = null;
      for (let index = 0; index < courseEdition.courseSchedules.length; index++) {
        const courseDay = courseEdition.courseSchedules[index];
        const currentStartTime = courseDay.morningTimeSchedule && courseDay.morningTimeSchedule.startTime;
        const currentEndTime = courseDay.afternoonTimeSchedule && courseDay.afternoonTimeSchedule.endTime;
        firstDayDate = firstDayDate || currentStartTime;
        lastDayDate = lastDayDate || currentEndTime;
        if (currentEndTime && new Date(currentEndTime) > new Date(lastDayDate)) {
          lastDayDate = currentEndTime;
        }
        if (currentStartTime && new Date(currentStartTime) < new Date(firstDayDate)) {
          firstDayDate = currentStartTime;
        }
      }
      courseEdition.firstDayDate = firstDayDate;
      courseEdition.lastDayDate = lastDayDate;

    } else {
      if (courseEdition.courseSchedules && courseEdition.courseSchedules.length) {
        courseEdition.firstDayDate = courseEdition.courseSchedules[0].firstAndLastTimeSchedule.startTime;
        courseEdition.lastDayDate = courseEdition.courseSchedules[courseEdition.courseSchedules.length - 1].firstAndLastTimeSchedule.endTime;
      }
    }
    ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.FIRST_DAY_DATE, courseEdition.firstDayDate, update);
    ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.LAST_DAY_DATE, courseEdition.lastDayDate, update);

    ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.MAX_ENROLLMENTS, !!courseEdition.seatsLimit ? courseEdition.seatsLimit : null, update, 0, true);
    ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.SUBSCRIPTION_CUSTOM_MAIL, courseEdition.subscriptionCustomMail, update);
    ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.SUBSCRIPTION_CUSTOM_LINK, courseEdition.subscriptionCustomLink, update);

    if (editionItem && editionItem.itemAttributes && editionItem.itemAttributes.length) {
      // Rimuovo gli eventuali attributi selezionati in precedenza, sia del venditore che del codice identificativo
      for (let t = editionItem.itemAttributes.length - 1; t >= 0; t--) {
        if (editionItem.itemAttributes[t].attributeType === ItemTakerAttributeTypes.SALESPERSON || editionItem.itemAttributes[t].attributeType === ItemAttributeTypes.IDENTIFIER_CODE) {
          editionItem.itemAttributes.splice(t, 1);
        }
      }
    }

    if (courseEdition.identificationCode || courseEdition.identificationCodeDesc) {
      let attributeLang = [];
      if (courseEdition.identificationCodeDesc) {
        attributeLang.push({
          attributeId: null,
          description: courseEdition.identificationCodeDesc,
          langCode: 'it'
        });
      }
      const newIdentificationCodeAttribute: any = {
        attributeId: null,
        attributeType: ItemAttributeTypes.IDENTIFIER_CODE,
        attributeValue: courseEdition.identificationCode,
        attributeOrder: 0,
        attributeLangs: attributeLang
      }
      editionItem.itemAttributes.push(newIdentificationCodeAttribute);
    }

    if (courseEdition.sellers && courseEdition.sellers.length) {
      courseEdition.sellers.forEach((selller: any) => {
        ItemUtil.setProgressiveAttributeWithReference(editionItem, ItemTakerAttributeTypes.SALESPERSON, selller.supplierPersonId, ReferenceTypes.SUPPLIER_PERSON, false, CommonConstants.APPLICATION_CORPORATE_ACADEMY);
      });
    }

    if (courseEdition["scheduleModel"] != null && courseEdition["scheduleModel"] != undefined) {
      ItemUtil.setKeyValueAttribute(editionItem, courseEdition["scheduleModel"].attributeType, courseEdition["scheduleModel"].attributeValue)
    }

    ItemUtil.setKeyValueAttribute(editionItem, SupplierPersonAttributeTypes.SELL_PERCENTAGE, !!courseEdition.sellerPercentage ? courseEdition.sellerPercentage : null, update, 0, true);
    ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.PRICE, !!courseEdition.price ? courseEdition.price : null, update, 0);

    ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.MAX_OB_ENROLLMENTS, courseEdition.overbookingLimit, update);
    ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.FUNDED_ITEM, courseEdition.courseEditionFunded, update);
    ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.ITEM_WITH_LIMITED_SEATS, courseEdition.limitedSeatsCourseEdition, update);
    if (courseEdition.verifySupplierAvailability) {
      ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.VERIFY_SUPPLIER_AVAILABILITY,
        courseEdition.verifySupplierAvailability, update);
    } else {
      ItemUtil.removeItemAttributeByKey(editionItem, ItemAttributeTypes.VERIFY_SUPPLIER_AVAILABILITY);
    }
    ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.AUTO_PUBLISH, courseEdition.autoPublish, update);
    ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.STATUS, courseEdition.status, update);

    ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.REMINDER_ACTIVE, courseEdition.reminderActive, update);
    // Se il reminder non è attivo, devo cancellare la data
    if (courseEdition.reminderActive) {
      ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.REMINDER_DATE, courseEdition.reminderDate, update);
    } else {
      ItemUtil.removeItemAttributeByKey(editionItem, ItemAttributeTypes.REMINDER_DATE);
    }

    // Lingua dell'edizione
    if (currentLang && currentLang.langCode && langsService && editionItem.itemLangs && editionItem.itemLangs.length) {
      let initiativeIndex: number = langsService.findItemLangIndex(currentLang.langCode, editionItem);
      if (courseEdition) {
        editionItem.itemLangs[initiativeIndex].title = courseEdition.customTitle;
        editionItem.itemLangs[initiativeIndex].subTitle = courseEdition.customSubTitle;
      }
    }

    // Se esiste l'attributo, lo aggiorno, altrimenti lo elimino
    if (courseEdition.solicitsActive) {
      ItemUtil.setKeyValueAttribute(initiativeItem, ItemAttributeTypes.SOLICITS_ACTIVE, courseEdition.solicitsActive, update);
    } else {
      ItemUtil.removeItemAttributeByKey(initiativeItem, ItemAttributeTypes.SOLICITS_ACTIVE);
    }
    ItemUtil.setKeyValueAttribute(
      initiativeItem, ItemAttributeTypes.SOLICIT_AFTER_HOURS, courseEdition.firstSolicitAfterHours, update, 0)
      ;
    ItemUtil.setKeyValueAttribute(
      initiativeItem, ItemAttributeTypes.SOLICIT_AFTER_HOURS, courseEdition.secondSolicitAfterHours, update, 1)
      ;
    ItemUtil.setKeyValueAttribute(
      initiativeItem, ItemAttributeTypes.SOLICIT_AFTER_HOURS, courseEdition.thirdSolicitAfterHours, update, 2)
      ;
    // Se esiste l'attributo, lo aggiorno, altrimenti lo elimino
    if (courseEdition.recurrentSolicitsActive) {
      ItemUtil.setKeyValueAttribute(initiativeItem, ItemAttributeTypes.RECURRENT_SOLICITS_ACTIVE, courseEdition.recurrentSolicitsActive, update);
    } else {
      ItemUtil.removeItemAttributeByKey(initiativeItem, ItemAttributeTypes.RECURRENT_SOLICITS_ACTIVE);
    }
    ItemUtil.setKeyValueAttribute(initiativeItem, ItemAttributeTypes.RECURRENT_SOLICITS_AFTER_HOURS, courseEdition.recurrentSolicitsAfterHours, update);
    ItemUtil.setKeyValueAttribute(initiativeItem, ItemAttributeTypes.RECURRENT_SOLICITS_DEADLINE_AFTER_DAYS, courseEdition.recurrentSolicitsDeadlineAfterDays, update);
    ItemUtil.setKeyValueAttribute(initiativeItem, ItemAttributeTypes.RECURRENT_SOLICITS_AFTER_HOURS_AFTER_DEADLINE, courseEdition.recurrentSolicitsAfterHoursAfterDeadline, update);
    ItemUtil.setKeyValueAttribute(
      editionItem, ItemAttributeTypes.CALENDAR_TYPE_ON_CONFIRM, courseEdition.calendarTypeOnConfirm, update
    );
    ItemUtil.setKeyValueAttribute(
      editionItem, ItemAttributeTypes.ETICKET_ON_CONFIRM, courseEdition.eTicket, update
    );
    ItemUtil.setKeyValueAttribute(
      editionItem, ItemAttributeTypes.ENABLE_CUSTOM_TITLE_AND_SUBTITLE, courseEdition.enableCustomTitleAndSubtitleView, update, null, true
    );
    ItemUtil.setKeyValueAttribute(
      editionItem, ItemAttributeTypes.MAX_USABLE_TAKES, courseEdition.maxUsableTakes, update, null, true
    );
    ItemUtil.setKeyValueAttribute(
      editionItem, ItemAttributeTypes.MAX_USABLE_TAKES_HINT, courseEdition.enableUsableTakesHint, update, null, true
    );
  }

  static getFrontEndDateByCourseEditionAndDay(courseEditionIndex: number, courseEdition: CourseEdition,
    courseDay: CourseDaySchedule): FrontEndDate {
    const timeschedules = [];
    const markerClass = courseEdition.courseSchedules.length > 1 ? 'counter-marker red' : 'counter-marker grey';
    if (courseDay.morningTimeSchedule) {
      timeschedules.push({
        startTime: courseDay.morningTimeSchedule.startTime,
        endTime: courseDay.morningTimeSchedule.endTime
      });
    }
    if (courseDay.afternoonTimeSchedule) {
      timeschedules.push({
        startTime: courseDay.afternoonTimeSchedule.startTime,
        endTime: courseDay.afternoonTimeSchedule.endTime
      });
    }
    if (courseDay.firstAndLastTimeSchedule) {
      timeschedules.push({
        startTime: courseDay.firstAndLastTimeSchedule.startTime,
        endTime: courseDay.firstAndLastTimeSchedule.endTime
      });
    }

    return <FrontEndDate>{
      markerClass: markerClass,
      courseEditionIndex: courseEditionIndex,
      editionId: courseEdition.itemId,
      dayId: courseDay.itemId,
      dayDate: courseDay.dayDate,
      schedules: timeschedules,
      stopDate: courseEdition.endEnrollDate,
      status: courseEdition.status,
      address: courseDay.location.address && courseDay.location.address.title,
      place: courseDay.location.address && courseDay.location.address.title,
      room: courseDay.location.room && courseDay.location.room.title,
      roomCapacity: courseDay.location.roomCapacity,
      seatsLimit: courseEdition.seatsLimit,
      sellerPercentage: courseEdition.sellerPercentage,
      price: courseEdition.price,
      subscriptionCustomLink: courseEdition.subscriptionCustomLink,
      identificationCode: courseEdition.identificationCode,
      identificationCodeDesc: courseEdition.identificationCodeDesc,
      subscriptionCustomMail: courseEdition.subscriptionCustomMail,
      itemAttributes: courseEdition.itemAttributes,
      invited: courseEdition.invited,
      suppliers: []
    };
  }

  /**
   * Se corso in presenza genera un nome dalla prima data disponibile, esempio: Roma - via Bissolati (RM), 30/22/2018.
   * Se corso online, oppure Webinar, genera un il nome dalla startDate: "Edizione 20/11/2018"
   * @param courseEdition
   */
  static generateEditionName(courseEdition: CourseEdition, editionLabel: string, dateFormat: string) {
    let editionName = "";
    if (courseEdition.isOnlineCourse || courseEdition.isWebinar) {
      let firstDayDate = courseEdition.firstDayDate;
      // prendo la prima data
      editionName = editionLabel + " " + moment(firstDayDate).format(dateFormat);
    } else {
      let address = courseEdition.courseSchedules[0].location && courseEdition.courseSchedules[0].location.address && courseEdition.courseSchedules[0].location.address.title;
      let firstDayDate = courseEdition.firstDayDate;
      editionName = address + ", " + moment(firstDayDate).format(dateFormat);
    }
    return editionName;
  }

  // static getHtmlTemplateLang(attribute: ExtendedItemAttribute, applicationLang: string) {
  //     if (attribute && applicationLang && attribute.crossReferenceObject && attribute.crossReferenceObject.templateLangs) {
  //         for (let k = 0, templatesLangLength = attribute.crossReferenceObject.templateLangs.length; k < templatesLangLength; k++) {
  //             const currentLang = attribute.crossReferenceObject.templateLangs[k];
  //             if (currentLang.langCode && currentLang.langCode === applicationLang.toLowerCase()) {
  //                 attribute.subject = currentLang.subject;
  //                 attribute.htmlText = currentLang.text;
  //                 break;
  //             }
  //         }
  //     }
  // }

  static setHtmlTemplateLang(attribute: ExtendedItemAttribute, applicationLang: string, crossReferenceObject: any) {
    if (attribute && applicationLang && crossReferenceObject && crossReferenceObject.templateLangs) {
      for (let k = 0, templatesLangLength = crossReferenceObject.templateLangs.length; k < templatesLangLength; k++) {
        const currentLang = crossReferenceObject.templateLangs[k];
        if (currentLang.langCode && currentLang.langCode === applicationLang.toLowerCase()) {
          attribute.subject = currentLang.subject;
          attribute.htmlText = currentLang.text;
          break;
        }
      }
    }
  }

  static getHtmlTemplateLang(attribute: ExtendedItemAttribute, applicationLang: string, templateService?: any, skipCallService?: boolean) {
    return new Promise((resolve, reject) => {
      if (attribute && attribute.referenceId && applicationLang) {
        if (attribute.crossReferenceObject && attribute.crossReferenceObject.templateLangs) {
          this.setHtmlTemplateLang(attribute, applicationLang, attribute.crossReferenceObject);
          resolve(true);
        } else {
          if (skipCallService) {
            resolve(true);
          } else {
            // Recupero il crossReferenceObject dell'attributo, che è il dettaglio del template
            templateService.getTemplateById(attribute.referenceId)
              .subscribe((templateDetails: any) => {
                let textTemplateType = templateDetails.response && templateDetails.response.textTemplateType || null;
                attribute.crossReferenceObject = {
                  textTemplateType: textTemplateType
                };
                this.setHtmlTemplateLang(attribute, applicationLang, templateDetails.response);
                resolve(true);
              },
                (err) => {
                  reject()
                });
          }
        }
      } else {
        resolve(true);
      }
    });
  }

  // Prevalorizza i template mail dell'edizione con quelli dell'iniziativa qualora mancassero
  static prefillTemplateOnEdition(course, courseEdition) {
    if (courseEdition && course) {
      if (!courseEdition.itemAttributes) {
        courseEdition.itemAttributes = [];
      }

      let inviteTemplateFound = false;
      let createdDayTemplateFound = false;
      let modifiedDayTemplateFound = false;
      let deleteDayTemplateFound = false;
      let stageInviteTemplateFound = false;
      let refuseTemplateFound = false;
      let preregistrationTemplateFound = false;
      let confirmTemplateFound = false;
      let obConfirmTemplateFound = false;
      let obPromotionTemplateFound = false;
      let presenceRegisteredTemplateFound = false;
      let courseReminderTemplateFound = false;
      let invitationSolicit1TemplateFound = false;
      let invitationSolicit2TemplateFound = false;
      let invitationSolicit3TemplateFound = false;
      // Verifico cosa già possiede l'edizione
      for (let i = 0, itemAttributeslength = courseEdition.itemAttributes.length; i < itemAttributeslength; i++) {
        const currentAttribute = courseEdition.itemAttributes[i];
        if (currentAttribute.attributeType === ItemAttributeTypes.INVITATION_MAIL) {
          // Template dell'invito
          inviteTemplateFound = true;
        } else if (currentAttribute.attributeType === ItemAttributeTypes.CREATED_DAY_MAIL) {
          // Template dell'invito
          createdDayTemplateFound = true;
        } else if (currentAttribute.attributeType === ItemAttributeTypes.MODIFIED_DAY_MAIL) {
          // Template dell'invito
          modifiedDayTemplateFound = true;
        } else if (currentAttribute.attributeType === ItemAttributeTypes.DELETED_DAY_MAIL) {
          // Template dell'invito
          deleteDayTemplateFound = true;
        } else if (currentAttribute.attributeType === ItemAttributeTypes.STAGE_INVITATION_MAIL) {
          // Template dell'invito
          stageInviteTemplateFound = true;
        } else if (currentAttribute.attributeType === ItemAttributeTypes.SELF_CANCEL_MAIL) {
          // Template della cancellazione/rifiuto
          refuseTemplateFound = true;
        } else if (currentAttribute.attributeType === ItemAttributeTypes.PREREGISTRATION_MAIL) {
          // Template della preiscrizione
          preregistrationTemplateFound = true;
        } else if (currentAttribute.attributeType === ItemAttributeTypes.CONFIRMATION_MAIL) {
          // Template della conferma partecipazione
          confirmTemplateFound = true;
        } else if (currentAttribute.attributeType === ItemAttributeTypes.OB_CONFIRMATION_MAIL) {
          // Template di conferma partipazione Overbooking
          obConfirmTemplateFound = true;
        } else if (currentAttribute.attributeType === ItemAttributeTypes.OB_PROMOTION_MAIL) {
          // Template di passagio da lista d'attesa a effettivo
          obPromotionTemplateFound = true;
        } else if (currentAttribute.attributeType === ItemAttributeTypes.PRESENCE_REGISTERED_MAIL) {
          // Template di presenza registrata
          presenceRegisteredTemplateFound = true;
        } else if (currentAttribute.attributeType === ItemAttributeTypes.COURSE_REMINDER_MAIL) {
          // Template di reminder edizione
          courseReminderTemplateFound = true;
        } else if (currentAttribute.attributeType === ItemAttributeTypes.INVITATION_SOLICIT_MAIL_1) {
          // Template del primo sollecito all'iscrizione
          invitationSolicit1TemplateFound = true;
        } else if (currentAttribute.attributeType === ItemAttributeTypes.INVITATION_SOLICIT_MAIL_2) {
          // Template del secondo sollecito all'iscrizione
          invitationSolicit2TemplateFound = true;
        } else if (currentAttribute.attributeType === ItemAttributeTypes.INVITATION_SOLICIT_MAIL_3) {
          // Template del terzo sollecito all'iscrizione
          invitationSolicit3TemplateFound = true;
        }
      }

      if (!inviteTemplateFound || !stageInviteTemplateFound || !refuseTemplateFound || !preregistrationTemplateFound || !confirmTemplateFound || !obConfirmTemplateFound || !obPromotionTemplateFound || !presenceRegisteredTemplateFound || !courseReminderTemplateFound || !invitationSolicit1TemplateFound || !invitationSolicit2TemplateFound || !invitationSolicit3TemplateFound || !createdDayTemplateFound || !modifiedDayTemplateFound || !deleteDayTemplateFound) {
        for (let j = 0, itemAttributeslength = course.itemAttributes.length; j < itemAttributeslength; j++) {
          const currentCourseAttribute = course.itemAttributes[j];
          if (!inviteTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.INVITATION_MAIL) {
            // Template dell'invito
            let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
            // Rimuovo l'attribute id
            copiedAttribute.attributeId = null;
            // Aggiorno l'id
            copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
            copiedAttribute.itemId = courseEdition.itemId;
            courseEdition.itemAttributes.push(copiedAttribute);
          } else if (!createdDayTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.CREATED_DAY_MAIL) {
            // Template dell'invito
            let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
            // Rimuovo l'attribute id
            copiedAttribute.attributeId = null;
            // Aggiorno l'id
            copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
            copiedAttribute.itemId = courseEdition.itemId;
            courseEdition.itemAttributes.push(copiedAttribute);
          } else if (!modifiedDayTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.MODIFIED_DAY_MAIL) {
            // Template dell'invito
            let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
            // Rimuovo l'attribute id
            copiedAttribute.attributeId = null;
            // Aggiorno l'id
            copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
            copiedAttribute.itemId = courseEdition.itemId;
            courseEdition.itemAttributes.push(copiedAttribute);
          } else if (!deleteDayTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.DELETED_DAY_MAIL) {
            // Template dell'invito
            let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
            // Rimuovo l'attribute id
            copiedAttribute.attributeId = null;
            // Aggiorno l'id
            copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
            copiedAttribute.itemId = courseEdition.itemId;
            courseEdition.itemAttributes.push(copiedAttribute);
          } else if (!stageInviteTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.DELETED_DAY_MAIL) {
            // Template dell'invito
            let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
            // Rimuovo l'attribute id
            copiedAttribute.attributeId = null;
            // Aggiorno l'id
            copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
            copiedAttribute.itemId = courseEdition.itemId;
            courseEdition.itemAttributes.push(copiedAttribute);
          } else if (!refuseTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.SELF_CANCEL_MAIL) {
            // Template della cancellazione/rifiuto
            let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
            // Rimuovo l'attribute id
            copiedAttribute.attributeId = null;
            // Aggiorno l'id
            copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
            copiedAttribute.itemId = courseEdition.itemId;
            courseEdition.itemAttributes.push(copiedAttribute);
          } else if (!preregistrationTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.PREREGISTRATION_MAIL) {
            // Template della preiscrizione
            let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
            // Rimuovo l'attribute id
            copiedAttribute.attributeId = null;
            // Aggiorno l'id
            copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
            copiedAttribute.itemId = courseEdition.itemId;
            courseEdition.itemAttributes.push(copiedAttribute);
          } else if (!confirmTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.CONFIRMATION_MAIL) {
            // Template della conferma partecipazione
            let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
            // Rimuovo l'attribute id
            copiedAttribute.attributeId = null;
            // Aggiorno l'id
            copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
            copiedAttribute.itemId = courseEdition.itemId;
            courseEdition.itemAttributes.push(copiedAttribute);
          } else if (!obConfirmTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.OB_CONFIRMATION_MAIL) {
            // Template di conferma partipazione Overbooking
            let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
            // Rimuovo l'attribute id
            copiedAttribute.attributeId = null;
            // Aggiorno l'id
            copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
            copiedAttribute.itemId = courseEdition.itemId;
            courseEdition.itemAttributes.push(copiedAttribute);
          } else if (!obPromotionTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.OB_PROMOTION_MAIL) {
            // Template di passagio da lista d'attesa a effettivo
            let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
            // Rimuovo l'attribute id
            copiedAttribute.attributeId = null;
            // Aggiorno l'id
            copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
            copiedAttribute.itemId = courseEdition.itemId;
            courseEdition.itemAttributes.push(copiedAttribute);
          } else if (!presenceRegisteredTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.PRESENCE_REGISTERED_MAIL) {
            // Template di presenza registrata
            let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
            // Rimuovo l'attribute id
            copiedAttribute.attributeId = null;
            // Aggiorno l'id
            copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
            copiedAttribute.itemId = courseEdition.itemId;
            courseEdition.itemAttributes.push(copiedAttribute);
          } else if (!courseReminderTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.COURSE_REMINDER_MAIL) {
            // Template di reminder edizione
            let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
            // Rimuovo l'attribute id
            copiedAttribute.attributeId = null;
            // Aggiorno l'id
            copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
            copiedAttribute.itemId = courseEdition.itemId;
            courseEdition.itemAttributes.push(copiedAttribute);
          } else if (!invitationSolicit1TemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.INVITATION_SOLICIT_MAIL_1) {
            // Template del primo sollecito all'iscrizione
            let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
            // Rimuovo l'attribute id
            copiedAttribute.attributeId = null;
            // Aggiorno l'id
            copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
            copiedAttribute.itemId = courseEdition.itemId;
            courseEdition.itemAttributes.push(copiedAttribute);
          } else if (!invitationSolicit2TemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.INVITATION_SOLICIT_MAIL_2) {
            // Template del secondo sollecito all'iscrizione
            let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
            // Rimuovo l'attribute id
            copiedAttribute.attributeId = null;
            // Aggiorno l'id
            copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
            copiedAttribute.itemId = courseEdition.itemId;
            courseEdition.itemAttributes.push(copiedAttribute);
          } else if (!invitationSolicit3TemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.INVITATION_SOLICIT_MAIL_3) {
            // Template del terzo sollecito all'iscrizione
            let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
            // Rimuovo l'attribute id
            copiedAttribute.attributeId = null;
            // Aggiorno l'id
            copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
            copiedAttribute.itemId = courseEdition.itemId;
            courseEdition.itemAttributes.push(copiedAttribute);
          }
        }
      }
    }
  }

  static MAIL_TEMPLATES_TO_MAP_ON_ITEM = {
    [ItemAttributeTypes.INVITATION_MAIL]: "inviteTemplate",
    [ItemAttributeTypes.CREATED_DAY_MAIL]: "createdDayTemplate",
    [ItemAttributeTypes.MODIFIED_DAY_MAIL]: "modifiedDayTemplate",
    [ItemAttributeTypes.DELETED_DAY_MAIL]: "deletedDayTemplate",
    [ItemAttributeTypes.STAGE_INVITATION_MAIL]: "stageInviteTemplate",
    [ItemAttributeTypes.SELF_CANCEL_MAIL]: "refuseTemplate",
    [ItemAttributeTypes.PREREGISTRATION_MAIL]: "preregistrationTemplate",
    [ItemAttributeTypes.CONFIRMATION_MAIL]: "confirmTemplate",
    [ItemAttributeTypes.OB_CONFIRMATION_MAIL]: "obConfirmTemplate",
    [ItemAttributeTypes.OB_PROMOTION_MAIL]: "obPromotionTemplate",
    [ItemAttributeTypes.PRESENCE_REGISTERED_MAIL]: "presenceRegisteredTemplate",
    [ItemAttributeTypes.COURSE_REMINDER_MAIL]: "courseReminderTemplate",
    [ItemAttributeTypes.INVITATION_SOLICIT_MAIL_1]: "invitationSolicit1Template",
    [ItemAttributeTypes.INVITATION_SOLICIT_MAIL_2]: "invitationSolicit2Template",
    [ItemAttributeTypes.INVITATION_SOLICIT_MAIL_3]: "invitationSolicit3Template"
  }

  // Recupera i template associati all'edizione
  static getSelectedTemplates(initiative: ExtendedItem, course: ExtendedItem, applicationLang: string, templateService?: any, skipCallService?: boolean) {
    let promises = [];
    if (course && course.itemAttributes) {
      Object.keys(this.MAIL_TEMPLATES_TO_MAP_ON_ITEM).forEach(templateId => {
        promises.push(new Promise((resolve: Function, reject: Function) => {
          // inizializzo a vuoto il campo per gestire il template a FE
          course[this.MAIL_TEMPLATES_TO_MAP_ON_ITEM[templateId]] = null;
          // per ogni template id cerco prima nell'edizione e poi eventualmente nell'iniziativa
          let attribute = ItemUtil.getAttributeByKey(course, templateId) || ItemUtil.getAttributeByKey(initiative, templateId);
          let getTemplatePromise = CourseEditionUtil.getHtmlTemplateLang(attribute, applicationLang, templateService, skipCallService);
          getTemplatePromise.then(() => {
            course[this.MAIL_TEMPLATES_TO_MAP_ON_ITEM[templateId]] = attribute;
            resolve();
          })
            .catch(() => {
              reject();
            });
        }));
      });
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  // Formatta le date unendole tramite il luogo
  static formatDatesByPlace(courseEditionList: CourseEdition[]): CourseEdition[] {
    const datesByPlace = [];

    const places: any = {};
    let index = 1;

    // creo i raggruppamenti per giorno per giorno le edizioni per Giorno
    courseEditionList.forEach((courseEdition: CourseEdition) => {
      courseEdition.courseSchedules.forEach((courseDay: CourseDaySchedule) => {
        const placeId = courseDay.location && courseDay.location.address && courseDay.location.address.id;
        if (placeId && places[placeId]) {
          const feDate: FrontEndDate = CourseEditionUtil.getFrontEndDateByCourseEditionAndDay(index, courseEdition, courseDay);
          places[placeId].dates.push(feDate);

        } else if (placeId && !places[placeId]) {
          places[placeId] = { place: placeId };
          places[placeId].dates = [];
          const feDate: FrontEndDate = CourseEditionUtil.getFrontEndDateByCourseEditionAndDay(index, courseEdition, courseDay);
          places[placeId].dates.push(feDate);
        }
      });
      index++;
    });

    for (const key in places) {
      if (places.hasOwnProperty(key)) {
        const element = places[key];
        datesByPlace.push({
          place: key,
          dates: element.dates
        });
      }
    }

    // ordino per place
    return datesByPlace.sort((x, y) => sortByRules<any>(x, y, false, [{ fieldName: 'place' }]));
  }

  // Formatta le date unendole tramite la giornata
  static formatDatesByDay(courseEditionList: CourseEdition[]): CourseEdition[] {
    const datesByDay = [];

    const days: any = {};

    let index = 1;
    // creo i raggruppamenti per giorno per giorno le edizioni per Giorno
    courseEditionList.forEach((courseEdition: CourseEdition) => {
      courseEdition.courseSchedules.forEach((courseDay: CourseDaySchedule) => {
        if (courseDay.dayDate && days[courseDay.dayDate]) {
          const feDate: FrontEndDate = CourseEditionUtil.getFrontEndDateByCourseEditionAndDay(index, courseEdition, courseDay);

          days[courseDay.dayDate].dates.push(feDate);

        } else if (courseDay.dayDate && !days[courseDay.dayDate]) {
          days[courseDay.dayDate] = { day: courseDay.dayDate };
          days[courseDay.dayDate].dates = [];

          const feDate: FrontEndDate = CourseEditionUtil.getFrontEndDateByCourseEditionAndDay(index, courseEdition, courseDay);

          days[courseDay.dayDate].dates.push(feDate);
        }
      });
      index++;
    });

    for (const key in days) {
      if (days.hasOwnProperty(key)) {
        const element = days[key];
        datesByDay.push({
          day: key,
          dates: element.dates
        });
      }
    }

    // ordino per day
    return datesByDay.sort((x, y) => sortByRules<any>(x, y, false, [{ fieldName: 'day' }]));
  }

  static sortCourseEditionList(courseEditions: Array<CourseEdition>, reverse?: boolean, fieldName?: string) {
    if (courseEditions && courseEditions.length) {
      const _fieldName = !!fieldName ? fieldName : 'dayDate';
      // ordino tutte le date delle singole edizioni, poi ordino le edizioni per la prima delle proprie giornate
      courseEditions = courseEditions.sort((x, y) => {
        return sortByRules<CourseEdition>(x, y, !!reverse, [{
          fieldExtractor: (courseEdition) => {
            if (courseEdition && courseEdition.courseSchedules && courseEdition.courseSchedules.length) {

              courseEdition.courseSchedules = courseEdition.courseSchedules.sort((x1, y1) => {
                return sortByRules<CourseDaySchedule>(x1, y1, !!reverse, [{
                  fieldExtractor: (courseDaySchedule) => {
                    let firstTimeSchedule = courseDaySchedule.getFirstValidTimeScheduleByOrder(!!reverse);
                    return firstTimeSchedule?.startTime;
                  }
                }]);
              });

              let firstDay = courseEdition.courseSchedules[0];
              if (!!firstDay && !!firstDay.timeSchedules) {
                let firstTimeSchedule = firstDay.getFirstValidTimeScheduleByOrder(!!reverse);
                return firstTimeSchedule?.startTime;
              }
              return firstDay && firstDay[fieldName];
            } else {
              return null;
            }
          }
        }]);
      });
    }
  }

  static getFirstValidTimeScheduleByOrderForUser(timeSchedules, reverse: boolean) {
    let firstSchedule = timeSchedules.filter((schedule) => {
      return !!schedule.startTime;
    }).sort((x2, y2) => {
      return sortByRules<TimeSchedule>(x2, y2, !!reverse, [{ fieldName: 'startTime' }])
    })[0];
    return firstSchedule;
  }

  static sortCourseEditionListForUser(courseEditions: Array<any>) {
    if (courseEditions && courseEditions.length) {
      // ordino tutte le date delle singole edizioni, poi ordino le edizioni per la prima delle proprie giornate
      courseEditions = courseEditions.sort((x, y) => {
        return sortByRules<any>(x, y, false, [{
          fieldExtractor: (courseEdition) => {
            if (courseEdition && courseEdition.days && courseEdition.days.length) {
              courseEdition.days = courseEdition.days.sort((x1, y1) => {
                return sortByRules<CourseDaySchedule>(x1, y1, false, [{
                  fieldExtractor: (day) => {
                    let firstTimeSchedule = CourseEditionUtil.getFirstValidTimeScheduleByOrderForUser(day.timeSchedules || [], false);
                    return firstTimeSchedule?.startTime;
                  }
                }]);
              });

              let firstDay = courseEdition.days[0];
              if (!!firstDay && !!firstDay.timeSchedules) {
                let firstTimeSchedule = CourseEditionUtil.getFirstValidTimeScheduleByOrderForUser(firstDay.timeSchedules || [], false);
                return firstTimeSchedule?.startTime;
              }
              return firstDay && firstDay['dayDate'];
            } else {
              return null;
            }
          }
        }]);
      });
    }
  }

  // serve a ricostruire il modello del form dall'Item
  static getCourseEditionModelFromItem(courseEditionItem: Item, initiative: Item, moduleItem: Item, langsService?: LangsService, currentLang?: Lang): CourseEdition {
    const courseEdition: CourseEdition = new CourseEdition();
    courseEdition.itemId = courseEditionItem.itemId;

    courseEdition.parentCourse = CourseModuleUtil.getCourseStageFromItem(initiative, moduleItem);

    courseEdition.endEnrollDate = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.END_ENROLL_DATE);
    courseEdition.firstDayDate = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.FIRST_DAY_DATE);
    courseEdition.lastDayDate = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.LAST_DAY_DATE);

    courseEdition.seatsLimit = parseInt(defaultTo(ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.MAX_ENROLLMENTS), 0));
    courseEdition.subscriptionCustomMail = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.SUBSCRIPTION_CUSTOM_MAIL);
    courseEdition.subscriptionCustomLink = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.SUBSCRIPTION_CUSTOM_LINK);
    courseEdition.identificationCode = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.SIMPLE_ID);

    const identificationCodeDescAttrLang = ItemUtil.getAttributeLangByKey(courseEditionItem, ItemAttributeTypes.IDENTIFIER_CODE, 'it');
    courseEdition.identificationCodeDesc = identificationCodeDescAttrLang && identificationCodeDescAttrLang.description;

    const sellersAttrs = ItemUtil.getAttributesByKey(courseEditionItem, ItemTakerAttributeTypes.SALESPERSON);
    courseEdition.sellers = sellersAttrs.map((attr) => {
      return {
        supplierPersonId: attr.referenceId,
        title: attr.crossReferenceObject && (attr.crossReferenceObject.surname + ' ' + attr.crossReferenceObject.forename),
        reference: attr.crossReferenceObject
      };
    }) || [];
    courseEdition.sellerPercentage = parseFloat(defaultTo(ItemUtil.getAttributeValue(courseEditionItem, SupplierPersonAttributeTypes.SELL_PERCENTAGE), 0));

    const priceAttribute = ItemUtil.getAttributeByKey(courseEditionItem, ItemAttributeTypes.PRICE);
    if (priceAttribute) {
      courseEdition.price = parseFloat(defaultTo(priceAttribute.attributeValue, 0));
    } else if (initiative && initiative.extendedItem && initiative.extendedItem.itemAttributes && initiative.extendedItem.itemAttributes.length) {
      // Non ho ancora creato l'attributo del prezzo, quindi lo prevalorizzo con il valore del prezzo scelto nel Syllabus
      const syllabusPriceAttribute = ItemUtil.getAttributeByKey(initiative.extendedItem, ItemAttributeTypes.PRICE);

      if (syllabusPriceAttribute) {
        const syllabusPrice = parseFloat(defaultTo(syllabusPriceAttribute.attributeValue, 0));
        courseEdition.price = syllabusPrice
      }
    }

    courseEdition.overbookingLimit = parseInt(defaultTo(
      ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.MAX_OB_ENROLLMENTS)
      , 0));
    courseEdition.courseEditionFunded = parseBoolean(ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.FUNDED_ITEM));
    courseEdition.limitedSeatsCourseEdition = parseBoolean(ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.ITEM_WITH_LIMITED_SEATS));
    courseEdition.verifySupplierAvailability = parseBoolean(
      ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.VERIFY_SUPPLIER_AVAILABILITY)
    );
    courseEdition.autoPublish = parseBoolean(ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.AUTO_PUBLISH));
    courseEdition.reminderActive = parseBoolean(
      ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.REMINDER_ACTIVE));
    courseEdition.reminderDate = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.REMINDER_DATE);
    courseEdition.solicitsActive = parseBoolean(
      ItemUtil.getAttributeValue(initiative, ItemAttributeTypes.SOLICITS_ACTIVE));
    courseEdition.recurrentSolicitsActive = parseBoolean(
      ItemUtil.getAttributeValue(initiative, ItemAttributeTypes.RECURRENT_SOLICITS_ACTIVE));
    courseEdition.recurrentSolicitsAfterHours = ItemUtil.getAttributeValue(initiative, ItemAttributeTypes.RECURRENT_SOLICITS_AFTER_HOURS);
    courseEdition.recurrentSolicitsDeadlineAfterDays = ItemUtil.getAttributeValue(initiative, ItemAttributeTypes.RECURRENT_SOLICITS_DEADLINE_AFTER_DAYS);
    /* courseEdition.recurrentSolicitsAfterHoursAfterDeadline = ItemUtil.getAttributeValue(initiative, ItemAttributeTypes.RECURRENT_SOLICITS_AFTER_HOURS_AFTER_DEADLINE); */
    courseEdition.firstSolicitAfterHours = (ItemUtil.getAttributeValue(
      initiative, ItemAttributeTypes.SOLICIT_AFTER_HOURS, 0));
    courseEdition.secondSolicitAfterHours = (ItemUtil.getAttributeValue(
      initiative, ItemAttributeTypes.SOLICIT_AFTER_HOURS, 1));
    courseEdition.thirdSolicitAfterHours = (ItemUtil.getAttributeValue(
      initiative, ItemAttributeTypes.SOLICIT_AFTER_HOURS, 2));
    courseEdition.calendarTypeOnConfirm = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.CALENDAR_TYPE_ON_CONFIRM);
    courseEdition.eTicket = parseBoolean(ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.ETICKET_ON_CONFIRM));
    courseEdition.enableCustomTitleAndSubtitleView = parseBoolean(ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.ENABLE_CUSTOM_TITLE_AND_SUBTITLE));

    courseEdition.enableUsableTakes = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.MAX_USABLE_TAKES);
    if (courseEdition.enableUsableTakes) {
      courseEdition.maxUsableTakes = parseInt(ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.MAX_USABLE_TAKES));
      courseEdition.enableUsableTakesHint = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.MAX_USABLE_TAKES_HINT);
    }

    // Lingua dell'edizione
    if (currentLang && currentLang.langCode && langsService && courseEditionItem.itemLangs && courseEditionItem.itemLangs.length) {
      let initiativeIndex: number = langsService.findItemLangIndex(currentLang.langCode, courseEditionItem);
      courseEdition.customTitle = courseEditionItem.itemLangs[initiativeIndex] && JSON.parse(JSON.stringify(courseEditionItem.itemLangs[initiativeIndex].title)) || null;
      courseEdition.customSubTitle = courseEditionItem.itemLangs[initiativeIndex] && JSON.parse(JSON.stringify(courseEditionItem.itemLangs[initiativeIndex].subTitle)) || null;
    }

    // Ricostruisco le giornate
    courseEdition.courseSchedules = CourseDayUtil.getCourseSchedulesFromEditionItem(courseEditionItem, courseEdition.isOnlineCourse, courseEdition.isWebinar);
    courseEdition.itemAttributes = courseEditionItem.itemAttributes;
    courseEdition.status = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.STATUS);

    return courseEdition;
  }

  // Ritorna l'etichetta della tipologia già tradotta
  static getCourseTypologyLabel(courseEditionItem: ExtendedItem, translateService: TranslateService): string {
    let type: string = null;
    type = translateService.instant('generic.initiativeTypes.' + courseEditionItem.itemType);
    return type;
  }

  // Ritorna l'etichetta dello status già tradotta
  static getCourseStatusLabel(item: Item, translateService: TranslateService): string {
    let status: string = null;
    // Recupero l'attributo relativo allo stato
    const itemStatus = ItemUtil.getAttributeByKey(item, 'STATUS');
    status = translateService.instant('generic.initiativeStatuses.' + itemStatus.attributeValue);
    return status;
  }

  // Ritorna l'etichetta dello status già tradotta
  static getCourseStatus(item: Item): string {
    // Recupero l'attributo relativo allo stato
    let itemStatus = ItemUtil.getAttributeByKey(item, 'STATUS');
    return (itemStatus && itemStatus.attributeValue);
  }

  // Ritorna l'etichetta dello status associato al Task
  static getSupplierStatusTaskLabel(item: Item, translateService: TranslateService): string {
    let status: string = null;
    // Recupero l'attributo relativo allo stato
    const itemStatus = ItemUtil.getAttributeByKey(item, 'STATUS');
    status = translateService.instant('generic.supplierTaskStatuses.' + itemStatus.attributeValue);
    return status;
  }

  static getEditionLocationLabelByInitiativeType(initiative: Item, translateService: any) {
    if (InitiativeUtils.isOnlineEvent(initiative.itemType)) {
      return (ItemUtil.getAttributeByKey(initiative, ItemAttributeTypes.ONLINE_EVENT_LINK_TITLE) || <ItemAttribute>{}).attributeValue;
    } else if (InitiativeUtils.isOnlineCourseOrEvent(initiative.itemType)) {
      return translateService.instant('editionLocationsLabels.ONLINE');
    } else if (InitiativeUtils.isAssessment(initiative.itemType)) {
      return translateService.instant('editionLocationsLabels.ASSESSMENT');
    } else if (InitiativeUtils.isWebinar(initiative.itemType)) {
      return translateService.instant('editionLocationsLabels.WEBINAR');
    } else {
      return translateService.instant('editionLocationsLabels.PRESENCE');
    }
  }

  static getEditionLocationByInitiativeType(initiative: Item, courseEdition: CourseEdition) {
    if (InitiativeUtils.isOnlineEvent(initiative.itemType)) {
      return (ItemUtil.getAttributeByKey(initiative, ItemAttributeTypes.ONLINE_EVENT_LINK) || <ItemAttribute>{}).attributeValue;
    } else if (InitiativeUtils.isOnlineCourseOrEvent(initiative.itemType)
      || InitiativeUtils.isAssessment(initiative.itemType) || InitiativeUtils.isWebinar(initiative.itemType)) {
      return '';
    } else {
      if (courseEdition.courseSchedules && courseEdition.courseSchedules.length) {
        let courseDay = courseEdition.courseSchedules[0];
        return courseDay.location && courseDay.location.address && courseDay.location.address.id;
      }
      return '';
    }
  }
  /**
    // Formatta le card delle iniziative
    static formatInitiativeCourseCardTEEEEEST(moment, currentItem, translate, isCatalog?: boolean) {
      if (currentItem) {
        if (currentItem.credits && currentItem.credits.length) {
          CourseEditionUtil.formatCreditsForFeCards(currentItem);
        }  // setto le label online/classroom in base al valore della select in rete/in presenza
  
        let attribute = currentItem.itemAttributes?.find((attribute: any) => attribute.attributeType == ItemAttributeTypes.PARTICIPATION_TYPOLOGY);
        let surveyType = currentItem.itemAttributes?.find((attribute: any) => attribute.attributeType == ItemAttributeTypes.SURVEY_TYPE);
        let specializationType = currentItem.itemAttributes?.find((attribute: any) => attribute.attributeType == ItemAttributeTypes.OBJECT_TYPE_SPECIALIZATION);
  
        if (currentItem.itemType == 'ASSESSMENT' || surveyType) {
          currentItem.itemType = translate.instant('ASSESSMENT');
        } else if (specializationType) {
          currentItem.courseTypeLabel = translate.instant('generic.itemTypes.' + specializationType?.attributeValue);
        }
  
        let deadlineString = specializationType?.attributeValue == "ONLINE_COURSE" ? translate.instant('generic.AVAILABILITY') : translate.instant('generic.EXPIRES_DAY');
  
        if (attribute && attribute.attributeValue == 'In presenza') {
          currentItem.courseTypeClass = 'classroom';
          if (specializationType?.attributeValue === "ASSESSMENT") {
            currentItem.itemType = specializationType.attributeValue;
          } else {
            currentItem.itemType = translate.instant('generic.itemTypes.' + specializationType?.attributeValue);
          }
          if (surveyType) {
            currentItem.itemType = translate.instant('generic.itemTypes.ASSESSMENT');
          }
          currentItem.itemTypeLabel = currentItem.startDate && moment(currentItem.startDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          // Etichetta del pulsante per l'azione; varia in base allo stato dell'utente
          if (currentItem.userStatus && currentItem.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_INVITED) {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
        } else if (attribute && attribute.attributeValue == 'In rete') {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'online';
          if (specializationType?.attributeValue === "ASSESSMENT") {
            currentItem.itemType = specializationType.attributeValue;
          } else {
            currentItem.itemType = translate.instant('generic.itemTypes.' + specializationType?.attributeValue);
          }
  
          if (surveyType) {
            currentItem.itemType = translate.instant('generic.itemTypes.ASSESSMENT');
          }
          // Tipologia della card
          //currentItem.itemTypeLabel = '';
          if (currentItem.stopDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          } else if (currentItem.endDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          }
          // Etichetta del pulsante per l'azione
          if (currentItem.completionPercentage === 0) {
            // L'utente deve ancora avviare il contenuto
            if (isCatalog) {
              currentItem.buttonText = translate.instant('generic.FIND_OUT_MORE');
            } else {
              currentItem.buttonText = translate.instant('generic.BEGIN');
            }
          } else if (currentItem.completionPercentage > 0 && currentItem.completionPercentage < 99) {
            // L'utente ha avviato il contenuto ma non l'ha ancora portato a termine
            currentItem.buttonText = translate.instant('generic.CONTINUE_ITEM');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
  
        } else if (attribute && attribute.attributeValue == 'Blended') {
  
          currentItem.courseTypeClass = 'blended';
          if (specializationType?.attributeValue === "ASSESSMENT") {
            currentItem.itemType = specializationType.attributeValue;
          } else {
            currentItem.itemType = translate.instant('generic.itemTypes.' + specializationType?.attributeValue);
          }
  
          if (surveyType) {
            currentItem.itemType = translate.instant('generic.itemTypes.ASSESSMENT');
          }
          if (currentItem.stopDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          } else if (currentItem.endDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          }
          // Etichetta del pulsante per l'azione
          if (currentItem.completionPercentage === 0) {
            // L'utente deve ancora avviare il contenuto
            if (isCatalog) {
              currentItem.buttonText = translate.instant('generic.FIND_OUT_MORE');
            } else {
              currentItem.buttonText = translate.instant('generic.BEGIN');
            }
          } else if (currentItem.completionPercentage > 0 && currentItem.completionPercentage < 99) {
            // L'utente ha avviato il contenuto ma non l'ha ancora portato a termine
            currentItem.buttonText = translate.instant('generic.CONTINUE_ITEM');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
        } else if (InitiativeUtils.isAssessment(currentItem.itemType)) {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'online';
          // Tipologia della card
          currentItem.itemType = translate.instant('generic.itemTypes.' + currentItem.itemType);
          currentItem.courseTypeLabel = translate.instant('generic.itemTypes.ASSESSMENT');
          currentItem.itemTypeLabel = '';
          if (currentItem.stopDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          } else if (currentItem.endDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          }
          // Etichetta del pulsante per l'azione
          currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
  
        } else if (InitiativeUtils.isBlendedStage(currentItem.itemType)) {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'blended';
          // Tipologia della card
          if (currentItem.itemType && currentItem.itemType === ItemTypes.BLENDED_STAGE) {
            currentItem.courseTypeLabel = translate.instant('generic.BLENDED_COURSE');
            currentItem.courseTypeClass = 'blended';
          } else {
            currentItem.courseTypeLabel = translate.instant('generic.ONLINE');
          }
          currentItem.itemTypeLabel = '';
          if (currentItem.stopDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          } else if (currentItem.endDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          }
          // Etichetta del pulsante per l'azione
          if (currentItem.completionPercentage === 0) {
            // L'utente deve ancora avviare il contenuto
            if (isCatalog) {
              currentItem.buttonText = translate.instant('generic.FIND_OUT_MORE');
            } else {
              currentItem.buttonText = translate.instant('generic.BEGIN');
            }
          } else if (currentItem.completionPercentage > 0 && currentItem.completionPercentage < 99) {
            // L'utente ha avviato il contenuto ma non l'ha ancora portato a termine
            currentItem.buttonText = translate.instant('generic.CONTINUE_ITEM');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
        } else if (InitiativeUtils.isCourseClassStage(currentItem.itemType)) {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'classroom';
          currentItem.itemType = translate.instant('generic.itemTypes.' + currentItem.itemType);
          if (specializationType) {
            currentItem.itemType = translate.instant('generic.itemTypes.' + specializationType?.attributeValue);
          }
          currentItem.itemTypeLabel = currentItem.startDate && moment(currentItem.startDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          // Etichetta del pulsante per l'azione; varia in base allo stato dell'utente
          if (currentItem.userStatus && currentItem.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_INVITED) {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
  
        } else if (InitiativeUtils.isExternalCourseClassStage(currentItem.itemType)) {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'online';
          // Tipologia della card
          currentItem.itemType = translate.instant('generic.itemTypes.' + currentItem.itemType);
          if (currentItem.itemType && currentItem.itemType === ItemTypes.BLENDED_STAGE) {
            currentItem.courseTypeLabel = translate.instant('generic.EXTERNAL_COURSE_CLASS_STAGE');
            currentItem.courseTypeClass = 'blended';
          } else {
            currentItem.courseTypeLabel = translate.instant('generic.ONLINE');
          }
          currentItem.itemTypeLabel = '';
          if (currentItem.stopDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          } else if (currentItem.endDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          }
          // Etichetta del pulsante per l'azione
          if (currentItem.completionPercentage === 0) {
            // L'utente deve ancora avviare il contenuto
            if (isCatalog) {
              currentItem.buttonText = translate.instant('generic.FIND_OUT_MORE');
            } else {
              currentItem.buttonText = translate.instant('generic.BEGIN');
            }
          } else if (currentItem.completionPercentage > 0 && currentItem.completionPercentage < 99) {
            // L'utente ha avviato il contenuto ma non l'ha ancora portato a termine
            currentItem.buttonText = translate.instant('generic.CONTINUE_ITEM');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
        } else if (InitiativeUtils.isCourseOnlineStage(currentItem.itemType)) {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'online';
          // Tipologia della card
          currentItem.itemType = translate.instant('generic.itemTypes.' + currentItem.itemType);
          if (currentItem.itemType && currentItem.itemType === ItemTypes.BLENDED_STAGE) {
            currentItem.courseTypeLabel = translate.instant('generic.BLENDED_COURSE');
            currentItem.courseTypeClass = 'blended';
          } else {
            currentItem.courseTypeLabel = translate.instant('generic.ONLINE');
          }
          currentItem.itemTypeLabel = '';
          if (currentItem.stopDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          } else if (currentItem.endDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          }
          // Etichetta del pulsante per l'azione
          if (currentItem.completionPercentage === 0) {
            // L'utente deve ancora avviare il contenuto
            if (isCatalog) {
              currentItem.buttonText = translate.instant('generic.FIND_OUT_MORE');
            } else {
              currentItem.buttonText = translate.instant('generic.BEGIN');
            }
          } else if (currentItem.completionPercentage > 0 && currentItem.completionPercentage < 99) {
            // L'utente ha avviato il contenuto ma non l'ha ancora portato a termine
            currentItem.buttonText = translate.instant('generic.CONTINUE_ITEM');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
        } else if (InitiativeUtils.isExternalOnlineStage(currentItem.itemType)) {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'online';
          // Tipologia della card
          currentItem.itemType = translate.instant('generic.itemTypes.' + currentItem.itemType);
          if (currentItem.itemType && currentItem.itemType === ItemTypes.BLENDED_STAGE) {
            currentItem.courseTypeLabel = translate.instant('generic.BLENDED_COURSE');
            currentItem.courseTypeClass = 'blended';
          } else {
            currentItem.courseTypeLabel = translate.instant('generic.ONLINE');
          }
          currentItem.itemTypeLabel = '';
          if (currentItem.stopDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          } else if (currentItem.endDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          }
          // Etichetta del pulsante per l'azione
          if (currentItem.completionPercentage === 0) {
            // L'utente deve ancora avviare il contenuto
            if (isCatalog) {
              currentItem.buttonText = translate.instant('generic.FIND_OUT_MORE');
            } else {
              currentItem.buttonText = translate.instant('generic.BEGIN');
            }
          } else if (currentItem.completionPercentage > 0 && currentItem.completionPercentage < 99) {
            // L'utente ha avviato il contenuto ma non l'ha ancora portato a termine
            currentItem.buttonText = translate.instant('generic.CONTINUE_ITEM');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
        } else if (currentItem.itemType == "WEBINAR") {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'online';
          currentItem.itemType = translate.instant('generic.itemTypes.' + currentItem.itemType);
          currentItem.itemTypeLabel = currentItem.startDate && moment(currentItem.startDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          // Etichetta del pulsante per l'azione; varia in base allo stato dell'utente
          if (currentItem.userStatus && currentItem.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_INVITED) {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
        } else {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'classroom';
          // Il corso è in presenza
          currentItem.courseTypeLabel = translate.instant('editionLocationsLabels.PRESENCE');
          currentItem.itemTypeLabel = currentItem.startDate && moment(currentItem.startDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          // Etichetta del pulsante per l'azione; varia in base allo stato dell'utente
          if (currentItem.userStatus && currentItem.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_INVITED) {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
        }
      }
    }
  
    // Formatta le card delle iniziative
    static formatInitiativeCourseCardDefault(moment, currentItem, translate, isCatalog?: boolean) {
      if (currentItem) {
        if (currentItem.credits && currentItem.credits.length) {
          CourseEditionUtil.formatCreditsForFeCards(currentItem);
        }
        if (InitiativeUtils.isOnlineCourseOrEvent(currentItem.itemType) || InitiativeUtils.isWebinar(currentItem.itemType)) {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'online';
          // Tipologia della card
          if (currentItem.itemType && currentItem.itemType === ItemTypes.BLENDED_STAGE) {
            currentItem.courseTypeLabel = translate.instant('generic.BLENDED_COURSE');
            currentItem.courseTypeClass = 'blended';
          } else {
            currentItem.courseTypeLabel = translate.instant('generic.ONLINE');
          }
          currentItem.itemTypeLabel = '';
          if (currentItem.stopDate) {
            currentItem.itemTypeLabel = translate.instant('generic.EXPIRES_DAY') + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          } else if (currentItem.endDate) {
            currentItem.itemTypeLabel = translate.instant('generic.EXPIRES_DAY') + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          }
          // Etichetta del pulsante per l'azione
          if (currentItem.completionPercentage === 0) {
            // L'utente deve ancora avviare il contenuto
            if (isCatalog) {
              currentItem.buttonText = translate.instant('generic.FIND_OUT_MORE');
            } else {
              currentItem.buttonText = translate.instant('generic.BEGIN');
            }
          } else if (currentItem.completionPercentage > 0 && currentItem.completionPercentage < 99) {
            // L'utente ha avviato il contenuto ma non l'ha ancora portato a termine
            currentItem.buttonText = translate.instant('generic.CONTINUE_ITEM');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
        } else if (InitiativeUtils.isAssessment(currentItem.itemType)) {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'online';
          // Tipologia della card
          currentItem.courseTypeLabel = translate.instant('generic.itemTypes.ASSESSMENT');
          currentItem.itemTypeLabel = '';
          if (currentItem.stopDate) {
            currentItem.itemTypeLabel = translate.instant('generic.EXPIRES_DAY') + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          } else if (currentItem.endDate) {
            currentItem.itemTypeLabel = translate.instant('generic.EXPIRES_DAY') + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          }
          // Etichetta del pulsante per l'azione
          if (currentItem.completionPercentage === 0) {
            // L'utente deve ancora avviare il contenuto
            if (isCatalog) {
              currentItem.buttonText = translate.instant('generic.FIND_OUT_MORE');
            } else {
              currentItem.buttonText = translate.instant('generic.BEGIN');
            }
          } else if (currentItem.completionPercentage > 0 && currentItem.completionPercentage < 99) {
            // L'utente ha avviato il contenuto ma non l'ha ancora portato a termine
            currentItem.buttonText = translate.instant('generic.CONTINUE_ITEM');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
        } else if (InitiativeUtils.isAssessment(currentItem.itemType)) {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'online';
          // Tipologia della card
          currentItem.courseTypeLabel = translate.instant('generic.itemTypes.ASSESSMENT');
          currentItem.itemTypeLabel = '';
          if (currentItem.stopDate) {
            currentItem.itemTypeLabel = translate.instant('generic.EXPIRES_DAY') + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          } else if (currentItem.endDate) {
            currentItem.itemTypeLabel = translate.instant('generic.EXPIRES_DAY') + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          }
          // Etichetta del pulsante per l'azione
          currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
        } else {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'classroom';
          // Il corso è in presenza
          currentItem.courseTypeLabel = translate.instant('editionLocationsLabels.PRESENCE');
          currentItem.courseTypeLabel = translate.instant('generic.itemTypes' + currentItem.itemType);
          currentItem.itemTypeLabel = currentItem.startDate && moment(currentItem.startDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          // Etichetta del pulsante per l'azione; varia in base allo stato dell'utente
          if (currentItem.userStatus && currentItem.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_INVITED) {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
        }
      }
    }
  
    static formatInitiativeCourseCardHome(moment, currentItem, translate, isCatalog?: boolean) {
      if (currentItem) {
        if (currentItem.credits && currentItem.credits.length) {
          CourseEditionUtil.formatCreditsForFeCards(currentItem);
        }  // setto le label online/classroom in base al valore della select in rete/in presenza
        let attribute = currentItem.itemAttributes?.find((attribute: any) => attribute.attributeType == ItemAttributeTypes.PARTICIPATION_TYPOLOGY);
        let surveyType = currentItem.itemAttributes?.find((attribute: any) => attribute.attributeType == ItemAttributeTypes.SURVEY_TYPE);
        let specializationType = currentItem.itemAttributes?.find((attribute: any) => attribute.attributeType == ItemAttributeTypes.OBJECT_TYPE_SPECIALIZATION);
  
        if (!specializationType) {
          if (currentItem.itemType == 'ASSESSMENT' || surveyType) {
            currentItem.itemType = translate.instant('ASSESSMENT');
          } else if (specializationType) {
            currentItem.courseTypeLabel = translate.instant('generic.itemTypes.' + specializationType?.attributeValue);
          } else {
            currentItem.itemType = translate.instant('TYPE_MISSING');
          }
        }
        let deadlineString = specializationType?.attributeValue == "ONLINE_COURSE" ? translate.instant('generic.AVAILABILITY') : translate.instant('generic.EXPIRES_DAY');
  
        if (attribute && attribute.attributeValue == 'In presenza') {
          currentItem.courseTypeClass = 'classroom';
          if (specializationType?.attributeValue === "ASSESSMENT") {
            currentItem.itemType = specializationType.attributeValue;
          } else {
            currentItem.itemType = translate.instant('generic.itemTypes.' + specializationType?.attributeValue);
          }
  
          if (surveyType) {
            currentItem.itemType = translate.instant('generic.itemTypes.ASSESSMENT');
          }
          currentItem.itemTypeLabel = currentItem.startDate && moment(currentItem.startDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          // Etichetta del pulsante per l'azione; varia in base allo stato dell'utente
          if (currentItem.userStatus && currentItem.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_INVITED) {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
        } else if (attribute && attribute.attributeValue == 'In rete') {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'online';
          if (specializationType?.attributeValue === "ASSESSMENT") {
            currentItem.itemType = specializationType.attributeValue;
          } else {
            currentItem.itemType = translate.instant('generic.itemTypes.' + specializationType?.attributeValue);
          }
  
          if (surveyType) {
            currentItem.itemType = translate.instant('generic.itemTypes.ASSESSMENT');
          }
          //currentItem.itemTypeLabel = '';
          if (currentItem.stopDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          } else if (currentItem.endDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          }
          // Etichetta del pulsante per l'azione
          if (currentItem.completionPercentage === 0) {
            // L'utente deve ancora avviare il contenuto
            if (isCatalog) {
              currentItem.buttonText = translate.instant('generic.FIND_OUT_MORE');
            } else {
              currentItem.buttonText = translate.instant('generic.BEGIN');
            }
          } else if (currentItem.completionPercentage > 0 && currentItem.completionPercentage < 99) {
            // L'utente ha avviato il contenuto ma non l'ha ancora portato a termine
            currentItem.buttonText = translate.instant('generic.CONTINUE_ITEM');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
  
        } else if (attribute && attribute.attributeValue == 'Blended') {
  
          currentItem.courseTypeClass = 'blended';
          if (specializationType?.attributeValue === "ASSESSMENT") {
            currentItem.itemType = specializationType.attributeValue;
          } else {
            currentItem.itemType = translate.instant('generic.itemTypes.' + specializationType?.attributeValue);
          }
  
          if (surveyType) {
            currentItem.itemType = translate.instant('generic.itemTypes.ASSESSMENT');
          }
          // Tipologia della card
          if (currentItem.stopDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          } else if (currentItem.endDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          }
          // Etichetta del pulsante per l'azione
          if (currentItem.completionPercentage === 0) {
            // L'utente deve ancora avviare il contenuto
            if (isCatalog) {
              currentItem.buttonText = translate.instant('generic.FIND_OUT_MORE');
            } else {
              currentItem.buttonText = translate.instant('generic.BEGIN');
            }
          } else if (currentItem.completionPercentage > 0 && currentItem.completionPercentage < 99) {
            // L'utente ha avviato il contenuto ma non l'ha ancora portato a termine
            currentItem.buttonText = translate.instant('generic.CONTINUE_ITEM');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
        } else if (InitiativeUtils.isAssessment(currentItem.itemType)) {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'online';
          // Tipologia della card
          currentItem.itemType = translate.instant('generic.itemTypes.' + currentItem.itemType);
          currentItem.courseTypeLabel = translate.instant('generic.itemTypes.ASSESSMENT');
          currentItem.itemTypeLabel = '';
          if (currentItem.stopDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          } else if (currentItem.endDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          }
          // Etichetta del pulsante per l'azione
          currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
  
        } else if (InitiativeUtils.isBlendedStage(currentItem.itemType)) {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'blended';
          currentItem.itemType = translate.instant('generic.itemTypes.' + currentItem.itemType);
          // Tipologia della card
          if (currentItem.itemType && currentItem.itemType === ItemTypes.BLENDED_STAGE) {
            currentItem.courseTypeLabel = translate.instant('generic.BLENDED_COURSE');
            currentItem.courseTypeClass = 'blended';
          } else {
            currentItem.courseTypeLabel = translate.instant('generic.ONLINE');
          }
          currentItem.itemTypeLabel = '';
          if (currentItem.stopDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          } else if (currentItem.endDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          }
          // Etichetta del pulsante per l'azione
          if (currentItem.completionPercentage === 0) {
            // L'utente deve ancora avviare il contenuto
            if (isCatalog) {
              currentItem.buttonText = translate.instant('generic.FIND_OUT_MORE');
            } else {
              currentItem.buttonText = translate.instant('generic.BEGIN');
            }
          } else if (currentItem.completionPercentage > 0 && currentItem.completionPercentage < 99) {
            // L'utente ha avviato il contenuto ma non l'ha ancora portato a termine
            currentItem.buttonText = translate.instant('generic.CONTINUE_ITEM');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
        } else if (InitiativeUtils.isCourseClassStage(currentItem.itemType)) {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'classroom';
          currentItem.itemType = translate.instant('generic.itemTypes.' + currentItem.itemType);
          if (specializationType) {
            currentItem.itemType = translate.instant('generic.itemTypes.' + specializationType?.attributeValue);
          }
          currentItem.itemTypeLabel = currentItem.startDate && moment(currentItem.startDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          // Etichetta del pulsante per l'azione; varia in base allo stato dell'utente
          if (currentItem.userStatus && currentItem.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_INVITED) {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
  
        } else if (InitiativeUtils.isExternalCourseClassStage(currentItem.itemType)) {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'online';
          // Tipologia della card
          currentItem.itemType = translate.instant('generic.itemTypes.' + currentItem.itemType);
          if (currentItem.itemType && currentItem.itemType === ItemTypes.BLENDED_STAGE) {
            currentItem.courseTypeLabel = translate.instant('generic.EXTERNAL_COURSE_CLASS_STAGE');
            currentItem.courseTypeClass = 'blended';
          } else {
            currentItem.courseTypeLabel = translate.instant('generic.ONLINE');
          }
          currentItem.itemTypeLabel = '';
          if (currentItem.stopDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          } else if (currentItem.endDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          }
          // Etichetta del pulsante per l'azione
          if (currentItem.completionPercentage === 0) {
            // L'utente deve ancora avviare il contenuto
            if (isCatalog) {
              currentItem.buttonText = translate.instant('generic.FIND_OUT_MORE');
            } else {
              currentItem.buttonText = translate.instant('generic.BEGIN');
            }
          } else if (currentItem.completionPercentage > 0 && currentItem.completionPercentage < 99) {
            // L'utente ha avviato il contenuto ma non l'ha ancora portato a termine
            currentItem.buttonText = translate.instant('generic.CONTINUE_ITEM');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
        } else if (InitiativeUtils.isCourseOnlineStage(currentItem.itemType)) {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'online';
          // Tipologia della card
          currentItem.itemType = translate.instant('generic.itemTypes.' + currentItem.itemType);
          if (currentItem.itemType && currentItem.itemType === ItemTypes.BLENDED_STAGE) {
            currentItem.courseTypeLabel = translate.instant('generic.BLENDED_COURSE');
            currentItem.courseTypeClass = 'blended';
          } else {
            currentItem.courseTypeLabel = translate.instant('generic.ONLINE');
          }
          currentItem.itemTypeLabel = '';
          if (currentItem.stopDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          } else if (currentItem.endDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          }
          // Etichetta del pulsante per l'azione
          if (currentItem.completionPercentage === 0) {
            // L'utente deve ancora avviare il contenuto
            if (isCatalog) {
              currentItem.buttonText = translate.instant('generic.FIND_OUT_MORE');
            } else {
              currentItem.buttonText = translate.instant('generic.BEGIN');
            }
          } else if (currentItem.completionPercentage > 0 && currentItem.completionPercentage < 99) {
            // L'utente ha avviato il contenuto ma non l'ha ancora portato a termine
            currentItem.buttonText = translate.instant('generic.CONTINUE_ITEM');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
        } else if (InitiativeUtils.isExternalOnlineStage(currentItem.itemType)) {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'online';
          // Tipologia della card
          currentItem.itemType = translate.instant('generic.itemTypes.' + currentItem.itemType);
          if (currentItem.itemType && currentItem.itemType === ItemTypes.BLENDED_STAGE) {
            currentItem.courseTypeLabel = translate.instant('generic.BLENDED_COURSE');
            currentItem.courseTypeClass = 'blended';
          } else {
            currentItem.courseTypeLabel = translate.instant('generic.ONLINE');
          }
          currentItem.itemTypeLabel = '';
          if (currentItem.stopDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          } else if (currentItem.endDate) {
            currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          }
          // Etichetta del pulsante per l'azione
          if (currentItem.completionPercentage === 0) {
            // L'utente deve ancora avviare il contenuto
            if (isCatalog) {
              currentItem.buttonText = translate.instant('generic.FIND_OUT_MORE');
            } else {
              currentItem.buttonText = translate.instant('generic.BEGIN');
            }
          } else if (currentItem.completionPercentage > 0 && currentItem.completionPercentage < 99) {
            // L'utente ha avviato il contenuto ma non l'ha ancora portato a termine
            currentItem.buttonText = translate.instant('generic.CONTINUE_ITEM');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
        } else {
          // Classe per il pallino colorato della card
          currentItem.courseTypeClass = 'classroom';
          currentItem.itemType = translate.instant('generic.itemTypes.' + currentItem.itemType);
          // Il corso è in presenza
          currentItem.courseTypeLabel = translate.instant('editionLocationsLabels.PRESENCE');
          currentItem.itemTypeLabel = currentItem.startDate && moment(currentItem.startDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
          // Etichetta del pulsante per l'azione; varia in base allo stato dell'utente
          if (currentItem.userStatus && currentItem.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_INVITED) {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          } else {
            currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
          }
        }
      }
    }
    */


  // Uso una sola funzione per la formattazione delle card, deriva dalla funzione del catalogo
  static formatInitiativeCourseCard(moment, currentItem, translate, isCatalog?: boolean) {
    if (currentItem) {
      if (currentItem.credits && currentItem.credits.length) {
        CourseEditionUtil.formatCreditsForFeCards(currentItem);
      }

      let attribute = currentItem.itemAttributes?.find((attribute: any) => attribute.attributeType == ItemAttributeTypes.PARTICIPATION_TYPOLOGY);
      let surveyType = currentItem.itemAttributes?.find((attribute: any) => attribute.attributeType == ItemAttributeTypes.SURVEY_TYPE);
      let specializationType = currentItem.itemAttributes?.find((attribute: any) => attribute.attributeType == ItemAttributeTypes.OBJECT_TYPE_SPECIALIZATION);
      let deadlineString = translate.instant('generic.AVAILABILITY');

      if (!specializationType) {
        if (currentItem.itemType == 'ASSESSMENT' || surveyType) {
          currentItem.itemType = translate.instant('ASSESSMENT');
        } else {
          currentItem.itemType = translate.instant('TYPE_MISSING');
        }
      }
      if (attribute) {
        if (attribute && attribute.attributeValue == 'In presenza') {
          currentItem.courseTypeClass = 'classroom';
        } else if (attribute && attribute.attributeValue == 'In rete') {
          currentItem.courseTypeClass = 'online';
        } else {
          currentItem.courseTypeClass = 'blended';
        }

        if (currentItem.itemType) {
          if (specializationType?.attributeValue === "ASSESSMENT") {
            currentItem.courseTypeLabel = specializationType.attributeValue;
          } else {
            currentItem.courseTypeLabel = translate.instant('generic.itemTypes.' + specializationType?.attributeValue);
          }
          if (surveyType) {
            currentItem.itemType = translate.instant('generic.itemTypes.ASSESSMENT');
          }
        } else {
          currentItem.courseTypeLabel = translate.instant('generic.itemTypes.' + currentItem.courseType);
        }
        if (currentItem.completionPercentage === 0) {
          // L'utente deve ancora avviare il contenuto
          if (isCatalog) {
            currentItem.buttonText = translate.instant('generic.FIND_OUT_MORE');
          } else {
            currentItem.buttonText = translate.instant('generic.BEGIN');
          }
        } else if (currentItem.completionPercentage > 0 && currentItem.completionPercentage < 99) {
          // L'utente ha avviato il contenuto ma non l'ha ancora portato a termine
          currentItem.buttonText = translate.instant('generic.CONTINUE_ITEM');
        } else {
          currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
        }
      } else if (InitiativeUtils.isExternalOnlineStage(currentItem.itemType) ||
        InitiativeUtils.isCourseOnlineStage(currentItem.itemType) ||
        InitiativeUtils.isOnlineEvent(currentItem.itemType) ||
        InitiativeUtils.isExternalCourseClassStage(currentItem.itemType)) {
        currentItem.courseTypeClass = 'online';
        let deadlineString = translate.instant('generic.AVAILABILITY');

        if (currentItem.itemType && currentItem.itemType === ItemTypes.BLENDED_STAGE) {
          currentItem.courseTypeLabel = translate.instant('generic.BLENDED_COURSE');
          currentItem.courseTypeClass = 'blended';
        } else {
          if (specializationType) {
            currentItem.courseTypeLabel = translate.instant('generic.itemTypes.' + specializationType.attributeValue);
          } else {
            currentItem.courseTypeLabel = translate.instant('generic.itemTypes.' + currentItem.itemType);
          }
          // currentItem.courseTypeLabel = translate.instant('generic.ONLINE');
        }
        currentItem.itemType = translate.instant('generic.itemTypes.' + currentItem.itemType);
        currentItem.itemTypeLabel = '';
        if (currentItem.stopDate) {
          currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
        } else if (currentItem.endDate) {
          currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
        }

        // Etichetta del pulsante per l'azione
        if (currentItem.completionPercentage === 0) {
          // L'utente deve ancora avviare il contenuto
          if (isCatalog) {
            currentItem.buttonText = translate.instant('generic.FIND_OUT_MORE');
          } else {
            currentItem.buttonText = translate.instant('generic.BEGIN');
          }
        } else if (currentItem.completionPercentage > 0 && currentItem.completionPercentage < 99) {
          // L'utente ha avviato il contenuto ma non l'ha ancora portato a termine
          currentItem.buttonText = translate.instant('generic.CONTINUE_ITEM');
        } else {
          currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
        }

      } else if (InitiativeUtils.isAssessment(currentItem.itemType)) {
        // Classe per il pallino colorato della card
        currentItem.courseTypeClass = 'online';
        currentItem.itemType = translate.instant('generic.itemTypes.' + currentItem.itemType);
        // Tipologia della card
        currentItem.courseTypeLabel = translate.instant('generic.itemTypes.ASSESSMENT');
        currentItem.itemTypeLabel = '';

        if (currentItem.stopDate) {
          currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
        } else if (currentItem.endDate) {
          currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
        }
        // Etichetta del pulsante per l'azione
        if (currentItem.completionPercentage === 0) {
          // L'utente deve ancora avviare il contenuto
          if (isCatalog) {
            currentItem.buttonText = translate.instant('generic.FIND_OUT_MORE');
          } else {
            currentItem.buttonText = translate.instant('generic.BEGIN');
          }
        } else if (currentItem.completionPercentage > 0 && currentItem.completionPercentage < 99) {
          // L'utente ha avviato il contenuto ma non l'ha ancora portato a termine
          currentItem.buttonText = translate.instant('generic.CONTINUE_ITEM');
        } else {
          currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
        }

      } else if (InitiativeUtils.isCourseClassStage(currentItem.itemType)) {
        currentItem.courseTypeClass = 'classroom';
        currentItem.courseTypeLabel = translate.instant('generic.itemTypes.' + currentItem.itemType);
        currentItem.itemType = translate.instant('generic.itemTypes.' + currentItem.itemType);
        currentItem.itemTypeLabel = currentItem.startDate && (deadlineString + ' ' + moment(currentItem.startDate).utc().format(translate.instant('generic.DATE_FORMAT'))) || '';
        if (specializationType) {
          currentItem.courseTypeLabel = translate.instant('generic.itemTypes.' + specializationType?.attributeValue);
        }
        if (currentItem.userStatus && currentItem.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_INVITED) {
          currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
        } else {
          currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
        } // recupero i valori dalle select in rete/ in presenza
      } else if (InitiativeUtils.isBlendedStage(currentItem.itemType)) {
        currentItem.courseTypeClass = 'blended';
        currentItem.courseTypeLabel = translate.instant('generic.itemTypes.' + currentItem.itemType);
        currentItem.itemType = translate.instant('generic.itemTypes.' + currentItem.itemType);
        if (currentItem.stopDate) {
          currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.stopDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
        } else if (currentItem.endDate) {
          currentItem.itemTypeLabel = deadlineString + ' ' + moment(currentItem.endDate).utc().format(translate.instant('generic.DATE_FORMAT')) || '';
        }
        // Etichetta del pulsante per l'azione
        if (currentItem.completionPercentage === 0) {
          // L'utente deve ancora avviare il contenuto
          if (isCatalog) {
            currentItem.buttonText = translate.instant('generic.FIND_OUT_MORE');
          } else {
            currentItem.buttonText = translate.instant('generic.BEGIN');
          }
        } else if (currentItem.completionPercentage > 0 && currentItem.completionPercentage < 99) {
          // L'utente ha avviato il contenuto ma non l'ha ancora portato a termine
          currentItem.buttonText = translate.instant('generic.CONTINUE_ITEM');
        } else {
          currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
        }

      } else if (currentItem.itemType == "WEBINAR") {
        // Classe per il pallino colorato della card
        currentItem.courseTypeClass = 'online';
        currentItem.itemType = translate.instant('generic.itemTypes.' + currentItem.itemType);
        currentItem.itemTypeLabel = currentItem.startDate && (deadlineString + ' ' + moment(currentItem.startDate).utc().format(translate.instant('generic.DATE_FORMAT'))) || '';
        // Il corso è in presenza
        currentItem.courseTypeLabel = translate.instant('generic.itemTypes.' + currentItem.itemType);
        // Etichetta del pulsante per l'azione; varia in base allo stato dell'utente
        if (currentItem.userStatus && currentItem.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_INVITED) {
          currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
        } else {
          currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
        }
      } else {
        // Classe per il pallino colorato della card
        currentItem.courseTypeClass = 'classroom';
        // Il corso è in presenza
        currentItem.courseTypeLabel = translate.instant('editionLocationsLabels.PRESENCE');
        currentItem.itemTypeLabel = currentItem.startDate && (deadlineString + ' ' + moment(currentItem.startDate).utc().format(translate.instant('generic.DATE_FORMAT'))) || '';
        // Etichetta del pulsante per l'azione; varia in base allo stato dell'utente
        if (currentItem.userStatus && currentItem.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_INVITED) {
          currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
        } else {
          currentItem.buttonText = translate.instant('generic.SHOW_DETAILS');
        }
      }
    }
  }




}




/*
* COURSE_DAY utility
*/
export class CourseDayUtil {

  static getCourseSchedulesFromEditionItem(editionItem: Item, isOnline: boolean, isWebinar?: boolean): Array<CourseDaySchedule> {
    const courseDays = new Array<CourseDaySchedule>();
    for (let i = 0, arrLeng = editionItem.itemChilds.length; i < arrLeng; i++) {
      const itemChild: ItemChild = editionItem.itemChilds[i];
      if (itemChild.itemChildId && itemChild.childObject && itemChild.childObject.itemId) {
        if (itemChild.childObject.itemType === ItemTypes.COURSE_DAY) {
          courseDays.push(CourseDayUtil.getCourseDayScheduleFromItem(itemChild.childObject, isOnline, isWebinar));
        }
      }
    }
    return courseDays;
  }

  static getCourseDayScheduleFromItem(courseDayItem: Item, isOnline: boolean, isWebinar?: boolean): CourseDaySchedule {
    const daySchedule = new CourseDaySchedule();

    daySchedule.itemId = courseDayItem.itemId;

    const attrLocation = ItemUtil.getAttributeByKey(courseDayItem, ItemAttributeTypes.LOCATION);
    if (attrLocation) {
      const courseDayLocationItem = <Item>attrLocation.crossReferenceObject;
      if (courseDayLocationItem) {
        daySchedule.location = new Location();
        daySchedule.location.physicalPlaceItem = courseDayLocationItem;
        const fullAddressValue = ItemUtil.getAttributeValue(courseDayLocationItem, ItemAttributeTypes.LOCATION_FULL_ADDRESS);
        daySchedule.location.address = {
          id: fullAddressValue,
          title: fullAddressValue,
          reference: courseDayLocationItem
        };
        const streetNumber = ItemUtil.getAttributeValue(courseDayLocationItem, ItemAttributeTypes.LOCATION_ADDRESS);
        daySchedule.location.streetNumber = {
          id: streetNumber,
          title: streetNumber,
          reference: courseDayLocationItem
        };
        const buildingValue = ItemUtil.getAttributeValue(courseDayLocationItem, ItemAttributeTypes.LOCATION_BUILDING);
        daySchedule.location.building = {
          id: buildingValue,
          title: buildingValue,
          reference: courseDayLocationItem
        };
        const roomValue = ItemUtil.getAttributeValue(courseDayLocationItem, ItemAttributeTypes.LOCATION_ROOM);
        daySchedule.location.room = {
          id: roomValue,
          title: roomValue,
          reference: courseDayLocationItem
        };
        const cityValue = ItemUtil.getAttributeValue(courseDayLocationItem, ItemAttributeTypes.LOCATION_CITY);
        daySchedule.location.city = {
          id: cityValue,
          title: cityValue,
          reference: courseDayLocationItem
        };
        const placeValue = ItemUtil.getAttributeValue(courseDayLocationItem, ItemAttributeTypes.LOCATION_PLACE);
        daySchedule.location.place = {
          id: placeValue,
          title: placeValue,
          reference: courseDayLocationItem
        };
        daySchedule.location.roomCapacity = defaultTo(
          ItemUtil.getAttributeValue(courseDayLocationItem, ItemAttributeTypes.LOCATION_ROOM_CAPACITY), 0
        );

        daySchedule.location.locationLat = defaultTo(parseFloat(ItemUtil.getAttributeValue(courseDayLocationItem, ItemAttributeTypes.LOCATION_LAT)), null);
        daySchedule.location.locationLong = defaultTo(parseFloat(ItemUtil.getAttributeValue(courseDayLocationItem, ItemAttributeTypes.LOCATION_LONG)), null);
      }
    }

    const tutorsAttrs = ItemUtil.getAttributesByKey(courseDayItem, ItemAttributeTypes.TUTOR);
    daySchedule.tutors = tutorsAttrs.map((attr) => {
      return {
        id: attr.referenceId,
        title: attr.crossReferenceObject && (attr.crossReferenceObject.surname + ' ' + attr.crossReferenceObject.forename),
        reference: attr.crossReferenceObject,
        attributeValue: attr.attributeValue
      };
    }) || [];

    const teachersAttrs = ItemUtil.getAttributesByKey(courseDayItem, ItemAttributeTypes.TEACHER);
    daySchedule.teachers = teachersAttrs.map((attr) => {
      return {
        id: attr.referenceId,
        title: attr.crossReferenceObject && (attr.crossReferenceObject.surname + ' ' + attr.crossReferenceObject.forename),
        reference: attr.crossReferenceObject,
        attributeValue: attr.attributeValue
      };
    }) || [];



    // Dati per SAMBA (Webinar)
    // Salvo l'accountID di samba, che identifica l'aula
    const sambaTopicAccountAttr = ItemUtil.getAttributeByKey(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_ACCOUNT_ID);
    if (sambaTopicAccountAttr) {
      daySchedule.webinarTopicAccountId = {
        id: sambaTopicAccountAttr.attributeValue
      };
    }

    // Url per accedere al webinar
    const webinarJoinUrlAttr = ItemUtil.getAttributeByKey(courseDayItem, ItemAttributeTypes.WEBINAR_JOIN_URL);
    if (webinarJoinUrlAttr) {
      daySchedule.webinarJoinUrl = {
        id: webinarJoinUrlAttr.attributeValue
      };
    }

    // Salvo l'id della sessione di samba
    const sambaSessionIdAttr = ItemUtil.getAttributeByKey(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_SESSION_ID);
    if (sambaSessionIdAttr) {
      daySchedule.webinarSessionId = sambaSessionIdAttr.attributeValue
    };

    // Salvo l'eventuale password del webinar
    const webinarPasswordAttr = ItemUtil.getAttributeByKey(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_PASSWORD);
    if (webinarPasswordAttr) {
      daySchedule.webinarPassword = webinarPasswordAttr.attributeValue;
    }

    // Salvo la tipologia della sessione (html5/flash)
    const webinarSessionTypeAttr = ItemUtil.getAttributeByKey(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_SESSION_TYPE);
    if (webinarSessionTypeAttr) {
      daySchedule.webinarSessionType = {
        id: webinarSessionTypeAttr.attributeValue
      };
    };

    // Salvo la visualizzazione dei nomi partecipanti
    const webinarDisplayPartecipantsNameAttr = ItemUtil.getAttributeByKey(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_DISPLAY_PARTICIPANTS_NAME);
    if (webinarDisplayPartecipantsNameAttr) {
      daySchedule.webinarDisplayPartecipantsName = {
        id: webinarDisplayPartecipantsNameAttr.attributeValue
      };
    };

    // Salvo la limitazione dell'accesso prima dell'avvio
    const accessBeforeStartAttr = ItemUtil.getAttributeByKey(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_ACCESS_BEFORE_START);
    if (accessBeforeStartAttr) {
      daySchedule.webinarAccessBeforeStart = {
        id: accessBeforeStartAttr.attributeValue
      };
    };

    // Lobby di Samba
    daySchedule.webinarLobby = parseBoolean(ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_LOBBY));
    // Breakout rooms
    daySchedule.webinarBreakoutRooms = parseBoolean(ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_BREAKOUT_ROOMS));
    // Media Library
    daySchedule.webinarMediaLibrary = parseBoolean(ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_MEDIA_LIBRARY));
    // Registrazione
    daySchedule.webinarRecording = parseBoolean(ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_RECORDING));
    // Richiesta di parola
    daySchedule.webinarRequestToSpeak = parseBoolean(ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_REQUEST_TO_SPEAK));
    // Richiesta di parola
    daySchedule.webinarScreenSharing = parseBoolean(ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_SCREEN_SHARING));

    if (!!isOnline || !!isWebinar) {
      daySchedule.firstAndLastTimeSchedule = new TimeSchedule();
      daySchedule.firstAndLastTimeSchedule.startTime = ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.START_TIME, 0);
      daySchedule.firstAndLastTimeSchedule.endTime = ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.END_TIME, 0);
    } else {
      // mattina
      daySchedule.morningTimeSchedule = new TimeSchedule();
      daySchedule.morningTimeSchedule.startTime = ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.START_TIME, 0);
      daySchedule.morningTimeSchedule.endTime = ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.END_TIME, 0);

      // pomeriggio
      daySchedule.afternoonTimeSchedule = new TimeSchedule();
      daySchedule.afternoonTimeSchedule.startTime = ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.START_TIME, 1);
      daySchedule.afternoonTimeSchedule.endTime = ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.END_TIME, 1);
    }

    // prendo la prima data valorizzata per ricavare il dayDate
    const _dayDate = (daySchedule.firstAndLastTimeSchedule && daySchedule.firstAndLastTimeSchedule.startTime)
      || (daySchedule.morningTimeSchedule && daySchedule.morningTimeSchedule.startTime)
      || (daySchedule.afternoonTimeSchedule && daySchedule.afternoonTimeSchedule.startTime);

    // DAY_DATE è calcolato dalla startTime per prevalorizzare il form di EDIT
    // la data viene calocolata in base al locale se avevo già salvato una data partendo da quella UTC
    daySchedule.dayDate = DateUtil.getDayDateFromUTCDate(_dayDate);

    // Aggiungo gli attributi
    daySchedule.itemAttributes = courseDayItem.itemAttributes;
    return daySchedule;
  }

  static setItemCourseDaySchedules(editionItem: Item, courseSchedules: Array<CourseDaySchedule>, isOnlineCourse: boolean, isWebinar?: boolean) {
    editionItem.itemChilds = editionItem.itemChilds || [];
    let myArray = [];
    //if (editionItem.itemChilds.length >= courseSchedules.length) {
    for (let i = 0; i < editionItem.itemChilds.length; i++) {
      for (let j = 0; j < courseSchedules.length; j++) {
        if (editionItem.itemChilds[i].referenceId == courseSchedules[j].itemId) {
          myArray.push(editionItem.itemChilds[i]);
        }
      }
    }
    //}
    editionItem.itemChilds = myArray;

    if (courseSchedules && courseSchedules.length) {
      courseSchedules.forEach((day: CourseDaySchedule) => {
        // verifico se sto aggiornando un course day esistente
        let itemToUpdate: Item;
        for (let i = 0, arrLeng = editionItem.itemChilds.length; i < arrLeng; i++) {
          const itemChild: ItemChild = editionItem.itemChilds[i];
          if (itemChild.itemChildId && itemChild.childObject && itemChild.childObject.itemId === day.itemId) {
            itemToUpdate = itemChild.childObject;
            break;
          }
        }
        if (itemToUpdate) {
          CourseDayUtil.updateCourseDayItem(itemToUpdate, day, isOnlineCourse, isWebinar);
        } else {
          const _newItem = CourseDayUtil.createCourseDayItem(day, isOnlineCourse, isWebinar);
          const newItemChild: ItemChild = {
            itemChildId: null,
            itemId: editionItem.itemId,
            childOrder: editionItem.itemChilds.length + 1,
            referenceId: null,
            mandatory: false,
            propedeuticReferenceId: null,
            childObject: _newItem
          };
          editionItem.itemChilds.push(newItemChild);
        }
      });
    }
  }

  static createCourseDayItem(daySchedule: CourseDaySchedule, isOnlineCourse, isWebinar?: boolean): Item {
    const courseDayItem = <Item>{};
    courseDayItem.itemType = ItemTypes.COURSE_DAY;
    courseDayItem.itemId = daySchedule.itemId;

    courseDayItem.title = 'COURSE_DAY';

    CourseDayUtil.fillCourseDayItem(courseDayItem, daySchedule, false, isOnlineCourse, isWebinar);

    return courseDayItem;
  }

  static updateCourseDayItem(courseDayItem: Item, daySchedule: CourseDaySchedule, isOnlineCourse: boolean, isWebinar?: boolean): Item {
    CourseDayUtil.fillCourseDayItem(courseDayItem, daySchedule, true, isOnlineCourse, isWebinar);

    return courseDayItem;
  }

  static fillCourseDayItem(courseDayItem: Item, daySchedule: CourseDaySchedule, update?: boolean, isOnlineCourse?: boolean, isWebinar?: boolean) {
    // Se non ho già gli attributi
    if (!courseDayItem.itemAttributes || !courseDayItem.itemAttributes.length) {
      courseDayItem.itemAttributes = [];
    }


    let tutorAttr = courseDayItem.itemAttributes.filter((attr) => attr.attributeType == ItemAttributeTypes.TUTOR);
    if (tutorAttr && daySchedule.tutors && tutorAttr.length >= daySchedule.tutors.length) {
      // Fare mappa a cui accedere direttamente, no usando includes
      let referenceIds = daySchedule.tutors.map((tutor) => tutor.id);
      let idToKeep = courseDayItem.itemAttributes.filter((attr) => attr.attributeType == ItemAttributeTypes.TUTOR && referenceIds.includes(attr.referenceId)).map((attr) => attr.attributeId);
      courseDayItem.itemAttributes = courseDayItem.itemAttributes.filter((attr) => attr.attributeType != ItemAttributeTypes.TUTOR || idToKeep.includes(attr.attributeId))
    }
    if (daySchedule.tutors && daySchedule.tutors.length) {
      daySchedule.tutors.forEach((tutor: any) => {
        ItemUtil.setProgressiveAttributeWithReference(courseDayItem, ItemAttributeTypes.TUTOR, tutor.id, ReferenceTypes.SUPPLIER_PERSON, update, null, null, true);
      });
    }

    let teacherAttr = courseDayItem.itemAttributes.filter((attr) => attr.attributeType == ItemAttributeTypes.TEACHER);
    if (teacherAttr && daySchedule.teachers && teacherAttr.length >= daySchedule.teachers.length) {
      let referenceIds = daySchedule.teachers.map((teacher) => teacher.id);
      let idToKeep = courseDayItem.itemAttributes.filter((attr) => attr.attributeType == ItemAttributeTypes.TEACHER && referenceIds.includes(attr.referenceId)).map((attr) => attr.attributeId);
      courseDayItem.itemAttributes = courseDayItem.itemAttributes.filter((attr) => attr.attributeType != ItemAttributeTypes.TEACHER || idToKeep.includes(attr.attributeId))
    }
    if (daySchedule.teachers && daySchedule.teachers.length) {
      daySchedule.teachers.forEach((teacher: any) => {
        ItemUtil.setProgressiveAttributeWithReference(courseDayItem, ItemAttributeTypes.TEACHER, teacher.id, ReferenceTypes.SUPPLIER_PERSON, update, null, null, true);
      });
    }


    // Dati per SAMBA (webinar)
    // Aula
    if (daySchedule.webinarTopicAccountId) {
      ItemUtil.setKeyValueAttributeByKey(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_ACCOUNT_ID, daySchedule.webinarTopicAccountId.id, update);
    }
    if (daySchedule.webinarJoinUrl) {
      ItemUtil.setKeyValueAttributeByKey(courseDayItem, ItemAttributeTypes.WEBINAR_JOIN_URL, daySchedule.webinarJoinUrl.id, update);
    }
    if (daySchedule.webinarSessionId) {
      ItemUtil.setKeyValueAttributeByKey(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_SESSION_ID, daySchedule.webinarSessionId, update);
    }
    // Password
    if (daySchedule.webinarPassword) {
      ItemUtil.setKeyValueAttributeByKey(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_PASSWORD, daySchedule.webinarPassword, update);
    }
    // Tipologia (html5/flash)
    if (daySchedule.webinarSessionType) {
      ItemUtil.setKeyValueAttributeByKey(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_SESSION_TYPE, daySchedule.webinarSessionType.id, update);
    }
    // Visualizzazione partecipanti
    if (daySchedule.webinarDisplayPartecipantsName) {
      ItemUtil.setKeyValueAttributeByKey(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_DISPLAY_PARTICIPANTS_NAME, daySchedule.webinarDisplayPartecipantsName.id, update);
    }
    // Limita accesso prima dell'inizio
    if (daySchedule.webinarAccessBeforeStart) {
      ItemUtil.setKeyValueAttributeByKey(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_ACCESS_BEFORE_START, daySchedule.webinarAccessBeforeStart.id, update);
    }
    // Abilita Lobby
    ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_LOBBY, daySchedule.webinarLobby, update);
    // Abilita breakout rooms
    ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_BREAKOUT_ROOMS, daySchedule.webinarBreakoutRooms, update);
    // Abilita media library
    ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_MEDIA_LIBRARY, daySchedule.webinarMediaLibrary, update);
    // Abilita screen sharing
    ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_SCREEN_SHARING, daySchedule.webinarScreenSharing, update);
    // Abilita registrazione
    ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_RECORDING, daySchedule.webinarRecording, update);
    // Abilita richiesta di parola
    ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.LIVEWEBINAR_REQUEST_TO_SPEAK, daySchedule.webinarRequestToSpeak, update);

    // creando per l'ONLINE (o webinar) troverÃ² valorizzato il firstAndLastTimeSchedule
    if ((isOnlineCourse || isWebinar) && daySchedule.firstAndLastTimeSchedule) {
      // ItemUtil.setProgressiveKeyValueAttribute(courseDayItem, ItemAttributeTypes.START_TIME,
      //   daySchedule.firstAndLastTimeSchedule.startTime, update);
      ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.START_TIME, daySchedule.firstAndLastTimeSchedule.startTime, update);
      // ItemUtil.setProgressiveKeyValueAttribute(courseDayItem, ItemAttributeTypes.END_TIME,
      //   daySchedule.firstAndLastTimeSchedule.endTime, update);
      ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.END_TIME, daySchedule.firstAndLastTimeSchedule.endTime, update);
    } else if (!isOnlineCourse && !isWebinar) {
      // create/update Item Location
      CourseDayUtil.setAttributeLocation(courseDayItem, daySchedule, update);
      // DAY_DATE non è più utilizzato
      // ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.DAY_DATE, daySchedule.dayDate);
      // mattina
      if (daySchedule.morningTimeSchedule) {
        // ItemUtil.setProgressiveKeyValueAttribute(courseDayItem, ItemAttributeTypes.START_TIME,
        //   daySchedule.morningTimeSchedule.startTime, update);
        // ItemUtil.setProgressiveKeyValueAttribute(courseDayItem, ItemAttributeTypes.END_TIME,
        //   daySchedule.morningTimeSchedule.endTime, update);
        ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.START_TIME, daySchedule.morningTimeSchedule.startTime, update, 0);
        ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.END_TIME, daySchedule.morningTimeSchedule.endTime, update, 0);
      }
      // pomeriggio
      if (daySchedule.afternoonTimeSchedule && daySchedule.afternoonTimeSchedule.startTime && daySchedule.afternoonTimeSchedule.endTime) {
        // ItemUtil.setProgressiveKeyValueAttribute(courseDayItem, ItemAttributeTypes.START_TIME,
        //   daySchedule.afternoonTimeSchedule.startTime, update);
        // ItemUtil.setProgressiveKeyValueAttribute(courseDayItem, ItemAttributeTypes.END_TIME,
        //   daySchedule.afternoonTimeSchedule.endTime, update);
        ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.START_TIME, daySchedule.afternoonTimeSchedule.startTime, update, 1);
        ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.END_TIME, daySchedule.afternoonTimeSchedule.endTime, update, 1);
      }
    }
  }

  static setAttributeLocation(item: Item, daySchedule: CourseDaySchedule, update?: boolean) {
    // non gestisco l'update, ricreo nuovamente tutto l'attributo location
    // lo valorizzo con l'item che corrisponde alla aula selezionata
    let attribute = item.itemAttributes.find((attr) => attr.attributeType == ItemAttributeTypes.LOCATION);
    if (!attribute) {
      attribute = <ItemAttribute>{
        attributeId: null,
        attributeType: ItemAttributeTypes.LOCATION,
        attributeValue: daySchedule.location.room && daySchedule.location.room.reference.itemId,
        attributeOrder: 0,
        attributeWeight: null,
        itemId: null,
        crossReferenceObject: null,
        referenceApplicationName: null,
        referenceId: null,
        referenceType: null
      };
      // Mi basta salvare il riferimento all'item PHYSICAL_PLACE
      attribute.referenceId = daySchedule.location.room && daySchedule.location.room.reference.itemId;
      attribute.referenceType = ReferenceTypes.ITEM;
      if (!item.itemAttributes) {
        item.itemAttributes = [];
      }
      item.itemAttributes.push(attribute);
    } else {
      // Mi basta salvare il riferimento all'item PHYSICAL_PLACE
      attribute.referenceId = daySchedule.location.room && daySchedule.location.room.reference.itemId;
      attribute.referenceType = ReferenceTypes.ITEM;
    }

    return attribute;
  }

}
