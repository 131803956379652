import { Observable, ObservableInput } from "rxjs";
import { switchMap, take, filter } from "rxjs/operators";

/**
 * The operator takes the first emitted value from the sourceObservable and then do a switchMap to a new inner observable
 * @param project Projection function for the Trasformation operator
 */
export function switchOnce<T, R>(project: (value: T, index?: number) => ObservableInput<R>) {
  // notice that we return a function here.. 
  return function switchOnceImplementation(source: Observable<T>): Observable<R> {
    return source.pipe(take(1), switchMap(project))
  }
}

/**
 * 
 */
export function onceIfNotEmpty<T>(project?: (value: T, index?: number) => ObservableInput<T>) {
  // notice that we return a function here.. 
  return function switchOnceImplementation(source: Observable<T>): Observable<T> {
    return source.pipe(filter(val => !!val), take(1));
  }
}
