import { Store } from "@ngrx/store";
import { GlobalApplicationData } from "../../shared/models/global-application-data.model";
import { Subscription, Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import * as fromApp from '../../ngrx/app.reducers';
import { Injectable } from "@angular/core";
import { SenecaResponse, JwtPayload } from "../../../cm2-commonclasses";

@Injectable()
export class ScormService {
  applicationData: GlobalApplicationData;
  result$: Subscription;
  loggedUser: JwtPayload;

  constructor(private store: Store<fromApp.AppState>,
    private http: HttpClient) {
    // Dallo store recupero l'oggetto principale contenente generici dati, e la lingua dell'applicazione
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    globalApplicationData$.subscribe(
      (globalApplicationData) => {
        this.applicationData = globalApplicationData;
      });
  }

  isSingletonInUse() {
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/is-singleton-item-in-use', {
    });
  }

  markSingletonInUse() {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/mark-singleton-item-in-use', {
    });
  }

  createScormRegistration(scormId: string, itemId: string, editionId?: string | string[], stageId?: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-scorm-registration/' + scormId, {
      itemId: itemId,
      scormId: scormId,
      editionId: editionId,
      stageId: stageId
    });
  }

  getScormPreviewUrl(scormId: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('scormId', scormId || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-scorm-preview-url/' + scormId, {
      params: httpParams
    });
  }

  getScormLaunchUrl(registrationId: string, checkForSingleton: boolean): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('registrationId', registrationId || '');
    httpParams = httpParams.append('checkForSingleton', (checkForSingleton && checkForSingleton.toString()) || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-scorm-launch-url/' + registrationId + '/' + checkForSingleton, {
      params: httpParams
    });
  }
}