/*
*  Reducer che gestiscono e modificano lo State applicativo riguardanti i gruppi
*/

import * as CompetencesActions from './competences.actions';
import * as fromApp from '../../ngrx/app.reducers';

const usersInProfile = 15; 
const competencesProfileNum= 20;
// I gruppi sono una feature dell'applicazione, infatti è un modulo caricato in maniera lazy (e quindi anche lo Store).
// Creo un'interfaccia, FeatureState, in cui mappo i gruppi
// Estendo fromApp.AppState così per ereditare tutte le proprietà dello Store principale
export interface FeatureState extends fromApp.AppState {
  competences: CompetencesState
}

// Interfaccia dello Store
export interface CompetencesState {
  competences: any[];
  count: number;
  fromRecord: number;
  numRecords: number;
  page: number;
  isFetchingCompetences: boolean;
  competencesTextFilter: string;
  sortingDir: string;
  sortingCol: string;

  profileId: string;
  users: any[];
  usersCount: number;
  usersfromRecord: number;
  usersNumRecords: number;
  usersPage: number;
  isFetchingUsers: boolean;
 
}

// Store iniziale
const initialCompetencesState: CompetencesState = {
  competences: [],
  count: 0, // contatore totale delle competences
  fromRecord: 0, // numero da cui recuperare gli elementi
  numRecords: competencesProfileNum, // elementi da recuperare
  page: 1, // pagina della tabella visualizzata
  isFetchingCompetences: false, // verifica se sto eseguendo una chiamata
  competencesTextFilter: null, // searchedText input
  sortingDir: 'DESC',
  sortingCol: 'startDate',

  profileId: null,
  users: [],
  usersCount: 0,
  usersfromRecord: 0,
  usersNumRecords: usersInProfile,
  usersPage: 1,
  isFetchingUsers: false

};

export function CompetencesReducer(state = initialCompetencesState, action: CompetencesActions.CompetencesActions) {
  switch (action.type) {
    case CompetencesActions.SET_COMPETENCES:
      return {
        ...state,
        isFetchingCompetences: false,
        competences: [...action.payload]
      };
    case CompetencesActions.SET_PAGINATION_COMPETENCES:
      return {
        ...state,
        fromRecord: action.payload.fromRecord,
        numRecords: action.payload.numRecords
      };
    case CompetencesActions.GET_COMPETENCES:
      return {
        ...state,
        isFetchingCompetences: true
      };
    case CompetencesActions.SET_PAGE_COMPETENCES:
      return {
        ...state,
        page: action.payload
      };
    case CompetencesActions.SET_COMPETENCES_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    case CompetencesActions.SET_COMPETENCES_TEXT_FILTER:
      return {
        ...state,
        competencesTextFilter: action.payload
      };
    case CompetencesActions.SET_SORTING_DIR:
      return {
        ...state,
        sortingDir: action.payload
      };
    case CompetencesActions.SET_SORTING_COL:
      return {
        ...state,
        sortingCol: action.payload
      };
    case CompetencesActions.SET_USERS_IN_PROFILE:
      return {
        ...state,
        users: [...action.payload],
        isFetchingUsers: false
      };
    case CompetencesActions.SET_PROFILE_ID:
      return {
        ...state,
        profileId: action.payload
      };
    case CompetencesActions.GET_USERS_IN_PROFILE:
      return {
        ...state,
        isFetchingUsers: true
      };
    case CompetencesActions.SET_USERS_IN_PROFILE_COUNT:
      return {
        ...state,
        usersCount: action.payload
      };
    case CompetencesActions.SET_USERS_IN_PROFILE_PAGE:
      return {
        ...state,
        usersPage: action.payload
      };
    case CompetencesActions.SET_PAGINATION_USERS_IN_PROFILE:
      return {
        ...state,
        usersfromRecord: action.payload.fromRecord,
        usersnumRecords: action.payload.numRecords
      };
    default:
      return state;
  }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getCompetences = (state: CompetencesState) => (state && state.competences) || [];
export const getCompetencesCount = (state: CompetencesState) => (state && state.count) || 0;
export const getCompetencesFromRecord = (state: CompetencesState) => (state && state.fromRecord) || 0;
export const getCompetencesNumRecords = (state: CompetencesState) => (state && state.numRecords) || competencesProfileNum;
export const getCompetencesPage = (state: CompetencesState) => (state && state.page) || 0;
export const isFetchingCompetences = (state: CompetencesState) => state.isFetchingCompetences || false;
export const getCompetencesTextFilter = (state: CompetencesState) => (state && state.competencesTextFilter) || null;
export const getSortingDir = (state: CompetencesState) => (state && state.sortingDir) || 'DESC';
export const getSortingCol = (state: CompetencesState) => (state && state.sortingCol) || 'startDate'

//lista utenti associati a un profilo
export const getUsersInProfile = (state: CompetencesState) => (state && state.users) || [];
export const getUsersInProfileCount = (state: CompetencesState) => (state && state.usersCount) || 0;
export const getUsersInProfileFromRecord = (state: CompetencesState) => (state && state.usersfromRecord) || 0;
export const getUsersInProfileNumRecords = (state: CompetencesState) => (state && state.usersNumRecords) || usersInProfile;
export const getUsersInProfilePage = (state: CompetencesState) => (state && state.usersPage) || 0;
export const isFetchingUsersInProfileCompetence = (state: CompetencesState) => state.isFetchingUsers || false;
export const getUsersInProfileProfileId = (state: CompetencesState) => (state && state.profileId) || null;
