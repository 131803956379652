import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { JwtPayload, SenecaResponse } from 'src/cm2-commonclasses';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/services/auth.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import * as AuthActions from '../../auth/ngrx/auth.actions';
import { getDefaultRedirectUrl } from 'src/app/auth/ngrx/auth.effects';
import * as ProfileActions from '../ngrx/profile.actions';
import * as jwtDecode from 'jwt-decode';

@Component({
  selector: 'app-forceResetPassword',
  templateUrl: './forceResetPassword.component.html',
  styleUrls: ['./forceResetPassword.component.scss']
})
export class ForceResetPasswordComponent implements OnInit {
  loaderActive: boolean;
  loggedUser: JwtPayload;
  avatar: string;
  updatePassword$: Subscription;
  renewToken$: Subscription;
  getJWTToken$: Subscription;
  result$: Subscription;
  redirectUrl: string;
  isTaker: boolean;
  isTeacher: boolean;
  oldPassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  passwordPattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@!%*?&£\._:\-\+])[A-Za-z\d$@!%*?&£\._:\-\+]{8,}/g;

  constructor(private toastr: ToastrService,
    private store: Store<fromApp.AppState>,
    public authService: AuthService,
    private router: Router,
    public redirectService: RedirectService,
    private translate: TranslateService) {
    const redirectUrl$: Observable<string> = this.store.select(fromApp.getRedirectUrl);
    const isTaker$: Observable<boolean> = this.store.select(fromApp.isTaker);
    const isTeacher$: Observable<boolean> = this.store.select(fromApp.isTeacher);
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);

    const combinedSelectes$ = combineLatest([redirectUrl$, isTaker$, loggedUser$, isTeacher$]);

    this.result$ = combinedSelectes$.subscribe(([redirectUrl, isTaker, loggedUser, isTeacher]) => {
      this.redirectUrl = redirectUrl;
      this.isTaker = isTaker;
      this.isTeacher = isTeacher;
      this.loggedUser = loggedUser;

      this.avatar = (this.loggedUser && this.loggedUser.user && this.loggedUser.user.userOptions && this.loggedUser.user.userOptions.avatarImage)
        || (this.loggedUser && this.loggedUser.user && (!this.loggedUser.user.chiaveSesso || this.loggedUser.user.chiaveSesso == "M") ? 'assets/img/placeholder_male.svg' : 'assets/img/placeholder_female.svg');
    });
  }

  ngOnInit() {
    const appInitiatives = document.getElementsByClassName("ng-sidebar__content") && document.getElementsByClassName("ng-sidebar__content")[0];
    if (appInitiatives) {
      appInitiatives.scrollTop = 0;
    }
  }

  onOldPasswordChanged(newValue?: string) {
    this.oldPassword = newValue;
  }

  onNewPasswordChanged(newValue?: string) {
    this.newPassword = newValue;
  }

  onConfirmPasswordChanged(newValue?: string) {
    this.confirmPassword = newValue;
  }

  arePswMatchingPattern() {
    if (this.newPassword
      && this.newPassword.match(this.passwordPattern)
      && this.confirmPassword
      && this.confirmPassword.match(this.passwordPattern)) {
      return true;
    }

    return false;
  }

  // Imposta una nuova password
  submitNewPassword() {
    this.loaderActive = true;
    let setNewPasswordPromise = this.setNewPassword();
    setNewPasswordPromise.then(() => {
      // Il parametro loggedUsr.user.forceResetPassword è ancora a true, quindi se ad es. andrà nella home (takers/home) ci sarà il guard che mi blocca e che mi farà rimanara sulla pagina /resetPassword.
      // Per evitare questo problema, aggiorno i dati dell'utente loggato
      let renewTokenPromise = this.renewToken();
      renewTokenPromise.then(() => {
        this.toastr.success(this.translate.instant(this.translate.instant('generic.DATA_SAVED')));
        this.loaderActive = false;
        this.oldPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';

        // Potrebbe essere che l'utente abbia eseguito un refresh nella pagina, quindi il redirect url resti in /resetPassword
        if (this.redirectUrl && this.redirectUrl === "/resetPassword") {
          this.redirectUrl = null;
        }

        const defaultRedirect = getDefaultRedirectUrl(this.authService, this.redirectUrl, this.isTaker, this.isTeacher);
        this.router.navigate([defaultRedirect]);
      })
        .catch(() => {
          this.loaderActive = false;
        })
    })
      .catch(() => {
        this.loaderActive = false;
      })
  }

  // Aggiorna la password
  setNewPassword() {
    return new Promise((resolve, reject) => {
      this.updatePassword$ = this.authService.setNewPassword(this.oldPassword, this.newPassword)
        .subscribe(
          (senecaResponse: SenecaResponse<string>) => {
            if (senecaResponse.error) {
              reject();
              // Vedo se c'è la traduzione dell'errore
              this.toastr.error(this.translate.instant('errors.' + senecaResponse.error));
            } else {
              resolve(senecaResponse.response);
            }
          },
          (err) => {
            this.toastr.error(this.translate.instant('errors.' + err.message));
            reject();
          });
    })
  }

  // Aggiorna il tinyToken dell'utente
  renewToken() {
    return new Promise((resolve, reject) => {
      const sessionStorageToken: string = sessionStorage.getItem('token');
      if (!sessionStorageToken) {
        reject();
      } else {
        sessionStorage.removeItem('token');
        this.renewToken$ = this.authService.renewToken(sessionStorageToken, true)
          .subscribe(
            (senecaResponse: SenecaResponse<string>) => {
              if (senecaResponse.error) {
                reject();
                this.toastr.error(this.translate.instant('errors.' + senecaResponse.error));
              } else {
                const tinyToken = senecaResponse.response;
                this.store.dispatch(AuthActions.SetToken({ payload: tinyToken }));

                // Recupero il token completo
                this.getJWTToken$ = this.authService.getJWTToken()
                  .subscribe(
                    (fulTokenRes: SenecaResponse<string>) => {
                      if (fulTokenRes.error) {
                        reject();
                        this.toastr.error(this.translate.instant('errors.' + fulTokenRes.error));
                      } else {
                        this.store.dispatch(new ProfileActions.DecodeToken(fulTokenRes.response));
                        let decodedJwt = jwtDecode(fulTokenRes.response)
                        this.store.dispatch(new ProfileActions.UpdateUser(decodedJwt));
                        resolve(true);
                      }
                    },
                    (err) => {
                      this.toastr.error(this.translate.instant('errors.' + err.message));
                      reject();
                    });
              }
            },
            (err) => {
              this.toastr.error(this.translate.instant('errors.' + err.message));
              reject();
            });
      }
    })
  }

  ngOnDestroy() {
    if (this.updatePassword$) {
      this.updatePassword$.unsubscribe();
    }
    if (this.renewToken$) {
      this.renewToken$.unsubscribe();
    }
    if (this.result$) {
      this.result$.unsubscribe();
    }
    if (this.getJWTToken$) {
      this.getJWTToken$.unsubscribe();
    }
  }
}
