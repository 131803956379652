/*
 * Direttiva che abbrevia i numeri più grandi di 999, ad esempio 1000 diventa 1K
*/

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'numberAbbreviation',
  pure: false
})
export class NumberAbbreviationPipe implements PipeTransform {
  transform(input: number, decimals: any): string | number {
    let exp: number = null;
    let rounded: number = null;
    let suffixes: Array<any> = ['k', 'M', 'G', 'T', 'P', 'E'];

    if (isNaN(input)) {
      return null;
    }

    if (input < 1000) {
      return (Math.floor(input*100)/100);
    }

    exp = Math.floor(Math.log(input) / Math.log(1000));

    return (input / Math.pow(1000, exp)).toFixed(decimals) + suffixes[exp - 1];
  }
}