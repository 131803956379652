import { Router } from "@angular/router";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subscription, combineLatest } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from '../../core/ngrx/core.actions';
import { environment } from 'src/environments/environment';
import { JwtPayload, RequiredAuth } from 'atfcore-commonclasses';
import { AuthService } from 'src/app/auth/services/auth.service';

/*
*    Servizio che gestisce i vari redirect
*/
@Injectable()
export class RedirectService implements OnDestroy {
  isMainMenuSidebarOpened: boolean;
  isProfileMenuSidebarOpened: boolean;
  sidenavObservables$: Subscription;
  loggedUser: JwtPayload;
  isAdmin: boolean = false;
  isAdminCanManageInitiatives: boolean = false;
  constructor(private store: Store<fromApp.AppState>,
    private router: Router,
    private authService: AuthService) {
    // Recupero le informazioni sull'utente loggato dallo Store applicativo
    let loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    // Recupero dallo Store applicativo le variabili che mi indicano se le sidenav sono aperte
    let isMainMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isMainMenuSidebarOpened);
    // Recupero dallo Store applicativo le variabili che mi indicano se le sidenav sono aperte
    let isProfileMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isProfileMenuSidebarOpened);
    const combinedSelectes$ = combineLatest(isMainMenuSidebarOpened$, isProfileMenuSidebarOpened$, loggedUser$);

    this.sidenavObservables$ = combinedSelectes$.subscribe(
      ([isMainMenuSidebarOpened, isProfileMenuSidebarOpened, loggedUser]) => {
        this.isMainMenuSidebarOpened = isMainMenuSidebarOpened;
        this.isProfileMenuSidebarOpened = isProfileMenuSidebarOpened;
        this.loggedUser = loggedUser;
        if (this.loggedUser) {
          if (loggedUser.auths && loggedUser.auths.length && this.authService.isUserAuthorized(RequiredAuth.CORPORATEACADEMY_IS_ADMIN, loggedUser.auths)) {
            this.isAdmin = true;
          }
          if (loggedUser.auths && loggedUser.auths.length && this.authService.isUserAuthorized(RequiredAuth.CORPORATEACADEMY_MANAGE_COURSES, loggedUser.auths)) {
            this.isAdminCanManageInitiatives = true;
          }
        }
      });
  }

  removeSessionStorageLibrarySearches() {
    const filters = sessionStorage.getItem("SEARCHES");
    if (filters) {
      sessionStorage.removeItem("SEARCHES");
    }
  }

  removeSessionStorageCatalogSearches() {
    const filters = sessionStorage.getItem("CATALOG_SEARCHES");
    if (filters) {
      sessionStorage.removeItem("CATALOG_SEARCHES");
    }
  }

  // Quando il componente è distrutto, eseguo anche l'unsubscribe
  ngOnDestroy() {
    if (this.sidenavObservables$) {
      this.sidenavObservables$.unsubscribe();
    }
  }

  // Chiude la sidebar di sinistra contente il menu principale
  closeSidenavMainMenu() {
    this.store.dispatch(new CoreActions.CloseMainMenuSidebar());
  }

  // Chiude la sidebar di destra con il profilo utente
  closeSidenavProfileMenu() {
    this.store.dispatch(new CoreActions.CloseProfileMenuSidebar());
  }



  // Porta all'home page
  goToAdminHome(isTaker?: boolean, isTeacher?: boolean, isManager?: boolean) {
    this.removeSessionStorageLibrarySearches();
    this.removeSessionStorageCatalogSearches();
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    if (environment.canAccessAdminMode && this.isAdmin) {
      if (environment.canAccessManageInitiatives && this.isAdminCanManageInitiatives) {
        this.router.navigate(['/home']);
      } else
        if (this.isAdmin) {
          this.router.navigate(['/adminHome']);
        } else if (environment.canAccessManageLibrary) {
          this.router.navigate(['/itemsAdmin/itemList']);
        } else if (environment.canAccessAdminCalendar) {
          this.router.navigate(['/calendar']);
        } else if (environment.canAccessAdminDashboard) {
          this.router.navigate(['/dashboard']);
        } else if (environment.canAccessAdminNews) {
          this.router.navigate(['/news']);
        } else if (environment.canAccessAdminSuppliers) {
          this.router.navigate(['/suppliers']);
        } else if (environment.canAccessManageGroups) {
          this.router.navigate(['/groups/listGroups']);
        } else if (environment.canAccessManageUsers) {
          this.router.navigate(['/usersAdmin/listAdminUsers']);
        } else if (environment.canAccessImpersonification) {
          this.router.navigate(['/impersonificationAdmin/home/1/1/false/true']);
        } else if (environment.canAccessManageLocations) {
          this.router.navigate(['/locations']);
        } else if (environment.canAccessManageTemplates) {
          this.router.navigate(['/templates']);
        } else if (environment.canAccessManageTeachersAccounting) {
          this.router.navigate(['/manageTeachersAccounting']);
        } else if (environment.canAccessManageTeachersAccounting) {
          this.router.navigate(['/manageTeachersAccounting']);
        } else if (environment.canManageAdminPreregWorkflow) {
          this.router.navigate(['/approvationPreregistrationsAdmin']);
        } else if (environment.canManageAdminInitiativesWorkflow) {
          this.router.navigate(['/approvationInitiativesAdmin']);
        } else {
          this.router.navigate(['/403']);
        }
    }
  }


  goToUsersHome() {
    this.removeSessionStorageLibrarySearches();
    this.removeSessionStorageCatalogSearches();
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    if (environment.canAccessUserMode) {
      if (environment.canAccessUserHome) {
        this.router.navigate(['takers/home']);
      } else if (environment.canAccessUserLibrary) {
        this.router.navigate(['takers/library/0']);
      } else if (environment.canAccessUserTrainingBooklet) {
        this.router.navigate(['takers/trainingBooklet']);
      } else if (environment.canAccessUserCalendar) {
        this.router.navigate(['takers/calendar']);
      } else if (environment.canAccessUserNews) {
        this.router.navigate(['takers/news']);
      } else if (environment.canAccessUserCatalog) {
        this.router.navigate(['takers/catalog/0']);
      } else if (environment.canAccessUserNotifications) {
        this.router.navigate(['takers/notifications']);
      } else if (environment.canAccessUserProfile) {
        this.router.navigate(['takers/profile']);
      } else {
        this.router.navigate(['403']);
      }
    }
  }

  goToTeachersHome() {
    this.removeSessionStorageLibrarySearches();
    this.removeSessionStorageCatalogSearches();
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    if (environment.canAccessTeacherMode) {
      this.router.navigate(['/teachers/home']);
    } else {
      this.router.navigate(['403']);
    }
  }

  goToManagerHome() {
    this.removeSessionStorageLibrarySearches();
    this.removeSessionStorageCatalogSearches();
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }

    if (environment.canAccessManagerMode) {
      this.router.navigate(['manager/home']);
    } else {
      this.router.navigate(['403']);
    }
  }

  // Porta all'home page
  goToHome(isTaker?: boolean, isTeacher?: boolean, isManager?: boolean) {
    this.removeSessionStorageLibrarySearches();
    this.removeSessionStorageCatalogSearches();
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }

    if (isManager && environment.canAccessManagerMode) {
      this.router.navigate(['manager/home']);
    } else if (isTeacher && environment.canAccessTeacherMode) {
      this.router.navigate(['/teachers/home']);
    } else if (isTeacher && environment.canAccessManageTeachersAccounting) {
      this.router.navigate(['/teachers/home']);
    } else if (isTaker && environment.canAccessUserMode) {
      if (environment.canAccessUserHome) {
        this.router.navigate(['/takers/home']);
      } else if (environment.canAccessUserLibrary) {
        this.router.navigate(['/takers/library/0']);
      } else if (environment.canAccessUserTrainingBooklet) {
        this.router.navigate(['/takers/trainingBooklet']);
      } else if (environment.canAccessUserCalendar) {
        this.router.navigate(['/takers/calendar']);
      } else if (environment.canAccessUserNews) {
        this.router.navigate(['/takers/news']);
      } else if (environment.canAccessUserCatalog) {
        this.router.navigate(['/takers/catalog/0']);
      } else if (environment.canAccessUserNotifications) {
        this.router.navigate(['/takers/notifications']);
      } else if (environment.canAccessUserProfile) {
        this.router.navigate(['/takers/profile']);
      } else {
        this.router.navigate(['403']);
      }
    } else if (!isTaker && !isTeacher && environment.canAccessAdminMode && this.isAdmin) {
      if (environment.canAccessManageInitiatives && this.isAdminCanManageInitiatives) {
        this.router.navigate(['/home']);

      } else
        if (this.isAdmin) {
          this.router.navigate(['/adminHome']);
        } else if (environment.canAccessManageLibrary) {
          this.router.navigate(['/itemsAdmin/itemList']);
        } else if (environment.canAccessAdminCalendar) {
          this.router.navigate(['/calendar']);
        } else if (environment.canAccessAdminDashboard) {
          this.router.navigate(['/dashboard']);
        } else if (environment.canAccessAdminNews) {
          this.router.navigate(['/news']);
        } else if (environment.canAccessAdminSuppliers) {
          this.router.navigate(['/suppliers']);
        } else if (environment.canAccessManageGroups) {
          this.router.navigate(['/groups/listGroups']);
        } else if (environment.canAccessManageUsers) {
          this.router.navigate(['/usersAdmin/listAdminUsers']);
        } else if (environment.canAccessImpersonification) {
          this.router.navigate(['/impersonificationAdmin/home/1/1/false/true']);
        } else if (environment.canAccessManageLocations) {
          this.router.navigate(['/locations']);
        } else if (environment.canAccessManageTemplates) {
          this.router.navigate(['/templates']);
        } else if (environment.canAccessManageTeachersAccounting) {
          this.router.navigate(['/manageTeachersAccounting']);
        } else if (environment.canAccessManageTeachersAccounting) {
          this.router.navigate(['/manageTeachersAccounting']);
        } else if (environment.canManageAdminPreregWorkflow) {
          this.router.navigate(['/approvationPreregistrationsAdmin']);
        } else if (environment.canManageAdminInitiativesWorkflow) {
          this.router.navigate(['/approvationInitiativesAdmin']);
        } else {
          this.router.navigate(['/403']);
        }
    } else {
      this.router.navigate(['403']);
    }
    this.change();
  }

  // Porta alla pagina con la lista dei tipi di Tag
  goToTags() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/tags/tagTypeList']);
  }

  // Porta alla pagina dei modelli orari
  goToScheduleModels() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/scheduleModels/scheduleModelsList']);
  }

  // Porta alla pagina dei modelli orari
  goToCompetences() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/competences']);
  }


  // Porta alla library
  goToLibrary() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.removeSessionStorageLibrarySearches();
    this.removeSessionStorageCatalogSearches();
    this.router.navigate(['/takers/library', 0]);
  }

  // Porta al libretto formativo
  goToTrainingBookletHome() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.removeSessionStorageLibrarySearches();
    this.removeSessionStorageCatalogSearches();
    this.router.navigate(['/takers/trainingBooklet/home']);
  }

  // Porta al catalogo
  goToCatalog() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.removeSessionStorageLibrarySearches();
    this.removeSessionStorageCatalogSearches();
    this.router.navigate(['/takers/catalog', 0]);
  }

  // Porta alla creazione di un'iniziativa da nuovo Syllabus
  goToCreateInitiativeNewSyllabus() {
    this.router.navigate(['/initiatives/newInitiative/newSyllabus']);
  }

  // Porta alla lista degli item lato admin
  goToItemsAdmin() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/itemsAdmin/itemList']);
  }

  goToApprovationPreregistrationsAdmin() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/approvationPreregistrationsAdmin']);
  }

  // Porta alla pagina per l'approvazione delle iniziative
  goToApprovationInitiativesAdmin() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/approvationInitiativesAdmin']);
  }

  // Porta alla dashboard lato admin
  goToDashboard() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/dashboard']);
  }

  // Porta alla pagina per la creazione di un nuovo oggetto atomico
  goToCreateNewItem(code: string) {
    this.router.navigate(['/itemsAdmin/createItem', code]);
  }

  // Porta alla pagina per la creazione di un nuovo learning plan [chiamato playlist]
  goToCreateNewLearningPlan() {
    this.router.navigate(['/itemsAdmin/newLearningPlan']);
  }

  // Porta alla pagina per la creazione di un nuovo progetto [chiamato learning plan]
  goToCreateNewProject() {
    this.router.navigate(['/itemsAdmin/newProject']);
  }

  // Porta alla pagina per la creazione di un progetto blended
  goToCreateNewBlendedProject() {
    this.router.navigate(['/itemsAdmin/newBlendedProject']);
  }

  // Porta alla pagina con il dettaglio di un ruolo
  goToRoleDetail(tagId: string) {
    this.router.navigate(['/dashboard/roleDetail', tagId]);
  }

  // Porta alla pagina con il dettaglio degli utenti di un gruppo
  goToGroupUsers(tagId: string, groupId: string) {
    this.router.navigate(['/dashboard/roleDetail', tagId, groupId]);
  }

  // Porta ai ruoli nella dashboard
  goToDashboardRoles() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/dashboard/roles']);
  }

  // Porta alla creazione di un'iniziativa da un syllabus esistente
  goToCreateInitiativeFromSyllabus() {
    this.router.navigate(['/initiatives/newInitiative/fromSyllabus']);
  }

  // Porta alla creazione di un'iniziativa smart
  goToCreateInitiativeSmart() {
    this.router.navigate(['/initiatives/newInitiative/smart']);
  }

  // Porta alla pagina con la lista delle iniziative
  goToInitiatives() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/initiatives']);
  }

  // Porta alla pagina con le iniziative e il crea iniziativa
  goToInitiativesHome() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/home']);
  }

  // Porta alla pagina per l'edit dei gruppi e aziende
  goToEditGroups() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/dashboard/editGroups']);
  }

  // Porta alla pagina per l'edit delle persone
  goToEditUser() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/usersAdmin/editUser']);
  }

  // Porta alla pagina dei gruppi
  goToListGroups() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/groups/listGroups']);
  }

  // Porta alla pagina per la gestione della contabilità dei docenti
  goToManageTeachersAccounting() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/manageTeachersAccounting/accountingList']);
  }

  // Porta alla pagina delle persone
  goToUsersAdmin() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/usersAdmin/listAdminUsers']);
  }

  // Porta alla pagina con la lista dei Supplier (fornitori)
  goToSuppliers() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/suppliers']);
  }

  // Porta alla pagina per l'impersonificazione
  goToImpersonificationAdmin() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/impersonificationAdmin/home/1/1/false/true']);
  }

  // Porta alla pagina con la lista degli announcements
  goToNews(isTaker?: boolean) {
    this.removeSessionStorageLibrarySearches();
    this.removeSessionStorageCatalogSearches();
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    };
    if (isTaker) {
      this.router.navigate(['/takers/news']);
    } else {
      this.router.navigate(['/news']);
    }
  }

  // Porta alla pagina con la lista dei template mail
  goToEmailTemplates() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/templates']);
  }

  // Porta alla lista dei template mail
  goToTemplates() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/templates']);
  }

  goToImportExport() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/importExport/manageImportExport']);
  }

  goToUserFAQ() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/faq/userFaq']);
  }

  goToFAQ() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/faq/manageFaq']);
  }

  goToNewFAQ() {
    this.router.navigate(['/faq/manageFaq/newFaq']);
  }

  goToEditFAQ(idFaq) {
    this.router.navigate(['/faq/manageFaq/editFaq/' + idFaq]);
  }

  goToLocations() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/locations']);
  }

  goToCalendar(isTaker?: boolean, isTeacher?: boolean) {
    this.removeSessionStorageLibrarySearches();
    this.removeSessionStorageCatalogSearches();
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    };
    if (isTeacher) {
      this.router.navigate(['/teachers/calendar']);
    } else if (isTaker) {
      this.router.navigate(['/takers/calendar']);
    } else {
      this.router.navigate(['/calendar']);
    }
  }

  goToNotifications(isTaker?: boolean) {
    this.removeSessionStorageLibrarySearches();
    this.removeSessionStorageCatalogSearches();
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    };
    if (isTaker) {
      this.router.navigate(['/takers/notifications']);
    } else {
      this.router.navigate(['/notifications']);
    }
  }

  getModuleName() {
    let url = window.location.href;
    if (url.indexOf('/teachers/') >= 0) {
      return 'teacher';
    } else if (url.indexOf('/takers/') >= 0) {
      return 'takers';
    } else if (url.indexOf('userFaq') >= 0) {
      return 'takers';
    } else if (url.indexOf('/manager/') >= 0) {
      return 'manager';
    } else {
      return 'admin';
    }
  }


  // Redirect per la pagina manager di gestione delle deleghe
  goToManagerDelegations() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/manager/delegations']);
  }

  // Redirect al calendario manager
  goToManagerCalendar() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/manager/calendar']);
  }

  // Redirect alla dashboard del manager
  goToManagerDashboard() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/manager/dashboard']);
  }

  // Redirect alle deleghe del manager
  goToDelegations() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/manager/delegations']);
  }

  goToProfile(isTaker?: boolean, isTeacher?: boolean) {
    this.removeSessionStorageLibrarySearches();
    this.removeSessionStorageCatalogSearches();
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    };
    if (isTeacher) {
      this.router.navigate(['/teachers/profile']);
    } else if (isTaker) {
      this.router.navigate(['/takers/profile']);
    } else {
      this.router.navigate(['/profile']);
    }
  }

  goToTeacherCourseMaterials(courseId: string) {
    this.router.navigate([`/teachers/materials/${courseId}`]);
  }

  goToLinks() {
    this.router.navigate(['/takers/link']);
  }

  goToThematicScools() {
    this.router.navigate(['/takers/thematicSchool']);
  }

  //porta alla sezione dei profili di competenza
  goToCompetencesProfiles(isAdmin?: boolean) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (isAdmin) {
      this.router.navigate(['/competenceProfiles']);
    }
  }


  goToInfoTools() {
    this.removeSessionStorageLibrarySearches();
    this.removeSessionStorageCatalogSearches();
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    };
    this.router.navigate(['/takers/link']);
  }
  change() {
    setTimeout(() => {
      let menu = document.getElementById("menu-button");
      let notifications = document.getElementById("n-menu-button");
      menu.getElementsByTagName("p")[0].style.fontWeight = "bold";
      if (notifications) {
        notifications.getElementsByTagName("p")[0].style.fontWeight = "bold";
      }
    }, 100)
  }
}
