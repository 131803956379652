import { HttpParams } from "@angular/common/http";
import * as moment from "moment";
import { Observable, ObservableInput } from "rxjs";
import { switchMap, take, filter } from "rxjs/operators";

/**
 * The operator takes the first emitted value from the sourceObservable and then do a switchMap to a new inner observable
 * @param project Projection function for the Trasformation operator
 */
export function switchOnce<T, R>(project: (value: T, index?: number) => ObservableInput<R>) {
  // notice that we return a function here..
  return function switchOnceImplementation(source: Observable<T>): Observable<R> {
    return source.pipe(take(1), switchMap(project))
  }
}

/**
 *
 */
export function onceIfNotEmpty<T>(project?: (value: T, index?: number) => ObservableInput<T>) {
  // notice that we return a function here..
  return function switchOnceImplementation(source: Observable<T>): Observable<T> {
    return source.pipe(filter(val => !!val), take(1));
  }
}

// Funzione che pulisce i query params da un path params. Quindi se in input riceve "439234-234?ssoToken=test", torna "439234-234"
export function getDecodedUrlParam(param: string): string {
  const decodedParam = param && decodeURIComponent(param);
  return decodedParam && decodedParam.split('?')[0];
}

/**
 * Set the arguments ids in & configuration
 * @param httpParams HttpParams to append the data
 * @param paramName Name of custom parameter
 * @param list List of arguments
 * @returns HttpParams with appended list if the data are presents
 */
export function handleCustomList(httpParams: HttpParams, paramName: string, list?: any | any[], isObject?: boolean): HttpParams {
  if (list && list.length && Array.isArray(list)) {
    list && list.forEach(type => {
      if (isObject) {
        httpParams = httpParams.append(paramName, JSON.stringify(type));
      } else {
        httpParams = httpParams.append(paramName, type);
      }
    });
  } else if (list && list.length && !Array.isArray(list)) {
    httpParams = httpParams.append(paramName, list);
  }

  return httpParams;
}


// Controlla il type della data e fa il format
export function formatDateW(date) {
  if (moment(date, ['DD/MM/YYYY', 'DD-MM-YYYY', 'DD.MM.YYYY'], true).isValid()) {
    const newDate = moment(date, ['DD/MM/YYYY', 'DD-MM-YYYY', 'DD.MM.YYYY'], true);
    return newDate.toISOString();
  }
  if (moment(date, moment.ISO_8601, true).isValid()) {
    const newDate = moment(date, moment.ISO_8601, true).toISOString();
    return newDate;
  }
  return null;
}

export function sanitazeUrl(url){
  return url?.replace(/\(/g, '%28').replace(/\)/g, '%29');
}