/*
 * Reducer per l'autenticazione, triggherate ogniqualvolta viene eseguito il dispatch di un'action, per l'update dello state
 *
*/

import * as AuthActions from './auth.actions';
import { createReducer, on, Action } from '@ngrx/store';

export interface AuthState {
  token: string;
  authenticated: boolean;
  isTaker: boolean;
  isTeacher: boolean;
}

// State iniziale
const initialState: AuthState = {
  token: null,
  authenticated: false,
  isTaker: false,
  isTeacher: false
};

// Genera un UUID
let uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const createAuthReducer = createReducer(
  initialState,
  on(AuthActions.SetUserAuthenticated, (state, action) => {
    let isTaker = action && action.isTaker;
    let isTeacher = action && action.isTeacher;
    if (isTaker) {
      sessionStorage.setItem('isTaker', "true");
      sessionStorage.removeItem('isTeacher');
    } else if (isTeacher) {
      sessionStorage.setItem('isTeacher', "true");
      sessionStorage.removeItem('isTaker');
    }

    if (!sessionStorage.getItem('NAVIGATION_SESSION_ID')) {
      const uuid = uuidv4();
      // Inserisco nel sessionStorage un ID che mi identifica l'id di navigazione (serve per il tracciamento)
      sessionStorage.setItem('NAVIGATION_SESSION_ID', uuid);
    };
    return {
      ...state,
      authenticated: true,
      isTaker: isTaker,
      isTeacher: isTeacher
    };
  }),
  on(AuthActions.Logout, (state, action) => {
    if (sessionStorage.getItem('isTaker')) {
      sessionStorage.removeItem('isTaker');
    }
    if (sessionStorage.getItem('isTeacher')) {
      sessionStorage.removeItem('isTeacher');
    }
    if (sessionStorage.getItem('redirectUrl')) {
      sessionStorage.removeItem('redirectUrl');
    }
    if (sessionStorage.getItem('token')) {
      sessionStorage.removeItem('token');
    }
    if (sessionStorage.getItem('NAVIGATION_SESSION_ID')) {
      sessionStorage.removeItem('NAVIGATION_SESSION_ID');
    }
    return {
      ...state,
      token: null,
      authenticated: false,
      isTaker: false,
      isTeacher: false
    };
  }),
  on(AuthActions.SetIsTaker, (state, action) => {
    return {
      ...state,
      isTaker: true
    };
  }),
  on(AuthActions.SetIsTeacher, (state, action) => {
    return {
      ...state,
      isTeacher: true
    };
  }),
  on(AuthActions.SetToken, (state, action) => {
    // Setto il token all'interno del session storage, e poi torno lo state aggiornato con il token
    // Ovviamente lo setto solo se non c'è già
    sessionStorage.setItem('token', action.payload);
    return {
      ...state,
      token: action.payload
    };
  })
);

export function authReducer(state: AuthState | undefined, action: Action) {
  return createAuthReducer(state, action);
}

export const getToken = (state: AuthState) => state.token;
export const isAuthenticated = (state: AuthState) => state.authenticated;
export const isTaker = (state: AuthState) => state.isTaker;
export const isTeacher = (state: AuthState) => state.isTeacher;
