/*
*  Lista di azioni riguardanti le iniziative (corsi)
*/

import { Action } from '@ngrx/store';
import { Item } from "../../../cm2-commonclasses";

// Salva la lista di iniziative recuperate
export const SET_INITIATIVES = 'SET_INITIATIVES';

// Aggiunge una nuova iniziativa
export const ADD_INITIATIVE = 'ADD_INITIATIVE';

// Aggiorna una iniziativa
export const UPDATE_INITIATIVE = 'UPDATE_INITIATIVE';

// Get di una iniziativa
export const GET_INITIATIVE = 'GET_INITIATIVE';

// Set di una iniziativa
export const SELECT_INITIATIVE = 'SELECT_INITIATIVE';

// Cancella una iniziativa
export const DELETE_INITIATIVE = 'DELETE_INITIATIVE';

// Salva un'iniziativa
export const STORE_INITIATIVE = 'STORE_INITIATIVE';

// Recupera le iniziative da remoto
export const GET_INITIATIVES = 'GET_INITIATIVES';

// Setta il contatore delle iniziative recuperate da remoto
export const SET_INITIATIVES_COUNT = 'SET_INITIATIVES_COUNT';

// Setta la paginazione
export const SET_PAGINATION = 'SET_PAGINATION';

// Setta la pagina corrente
export const SET_PAGE = 'SET_PAGE';

// Salva l'intero oggetto dell'iniziativa scelta
export const SET_SELECTED_INITIATIVE_ITEM = 'SET_SELECTED_INITIATIVE_ITEM';

// Salva l'intero oggetto padre (quindi il percorso) dell'iniziativa scelta
export const SET_PATH_ITEM_OF_SELECTED_INITIATIVE = 'SET_PATH_ITEM_OF_SELECTED_INITIATIVE';

// Resetta i dati dell'iniziativa scelta
export const DELETE_SELECTED_INITIATIVE_DATA = 'DELETE_SELECTED_INITIATIVE_DATA';

// Resetta i dati dell'iniziativa scelta
export const SET_CURRENT_WIZARD_STEP = 'SET_CURRENT_WIZARD_STEP';

// Setta il filtro per la tipologia
export const SET_INITIATIVES_TYPE = 'SET_INITIATIVES_TYPE';

// Setta il filtro per lo status
export const SET_INITIATIVES_STATUS = 'SET_INITIATIVES_STATUS';

// Setta il filtro per la tipologia
export const SET_INITIATIVES_ADMIN = 'SET_INITIATIVES_ADMIN';

// Setta il filtro per il luogo
export const SET_INITIATIVES_LOCATIONS = 'SET_INITIATIVES_LOCATIONS';

// Setta il filtro per categoria corso
export const SET_INITIATIVES_ARGUMENTS = 'SET_INITIATIVES_ARGUMENTS';

// Setta il filtro per tipologia formazione
export const SET_INITIATIVES_STREAMS = 'SET_INITIATIVES_STREAMS';

// Setta il filtro per la ricerca testuale
export const SET_INITIATIVES_TEXT_FILTER = 'SET_INITIATIVES_TEXT_FILTER';

// Setta il filtro per codice SAP
export const SET_SAP_CODE_FILTER = 'SET_SAP_CODE_FILTER';

// Setta il messaggio della modale di errore vpl
export const SET_VPL_MODAL_ERROR = "SET_VPL_MODAL_ERROR";

export class SetInitiatives implements Action {
  readonly type = SET_INITIATIVES;

  constructor(public payload: any[]) { }
}

export class AddInitiative implements Action {
  readonly type = ADD_INITIATIVE;

  constructor(public payload: any) { }
}

export class UpdateInitiative implements Action {
  readonly type = UPDATE_INITIATIVE;

  constructor(public payload: { index: number, updatedInitiative: any }) { }
}

export class GetInitiative implements Action {
  readonly type = GET_INITIATIVE;

  constructor(public payload: string) { }
}

export class SetSelectedInitiativeItem implements Action {
  readonly type = SET_SELECTED_INITIATIVE_ITEM;

  constructor(public payload: Item) { }
}

export class DeleteSelectedInitativeData implements Action {
  readonly type = DELETE_SELECTED_INITIATIVE_DATA;
}

export class SetPathItemOfSelectedInitiative implements Action {
  readonly type = SET_PATH_ITEM_OF_SELECTED_INITIATIVE;

  constructor(public payload: Item) { }
}

export class SelectInitiative implements Action {
  readonly type = SELECT_INITIATIVE;

  constructor(public payload: string) { }
}
export class SetInitiativesAdmin implements Action {
  readonly type = SET_INITIATIVES_ADMIN;

  constructor(public payload: string[]) { }
}

export class SetInitiativesType implements Action {
  readonly type = SET_INITIATIVES_TYPE;

  constructor(public payload: string) { }
}

export class SetInitiativesStatus implements Action {
  readonly type = SET_INITIATIVES_STATUS;

  constructor(public payload: string) { }
}

export class SetInitiativesLocations implements Action {
  readonly type = SET_INITIATIVES_LOCATIONS;

  constructor(public payload: string[]) { }
}

export class SetInitiativesArguments implements Action {
  readonly type = SET_INITIATIVES_ARGUMENTS;

  constructor(public payload: string[]) { }
}

export class SetInitiativesStreams implements Action {
  readonly type = SET_INITIATIVES_STREAMS;

  constructor(public payload: string[]) { }
}

export class SetInitiativesTextFilter implements Action {
  readonly type = SET_INITIATIVES_TEXT_FILTER;

  constructor(public payload: string) { }
}

export class DeleteInitiative implements Action {
  readonly type = DELETE_INITIATIVE;

  constructor(public payload: string) { }
}

export class StoreInitiative implements Action {
  readonly type = STORE_INITIATIVE;
}

export class GetInitiatives implements Action {
  readonly type = GET_INITIATIVES;
}

export class SetInitiativesCount implements Action {
  readonly type = SET_INITIATIVES_COUNT;

  constructor(public payload: number) { }
}

export class SetPage implements Action {
  readonly type = SET_PAGE;

  constructor(public payload: number) { }
}

export class SetPagination implements Action {
  readonly type = SET_PAGINATION;

  constructor(public payload: { fromRecord: number, numRecords: number }) { }
}

export class SetCurrentWizardStep implements Action {
  readonly type = SET_CURRENT_WIZARD_STEP;

  constructor(public payload: string) { }
}

export class SetInitiativeSapCodeFilter implements Action {
  readonly type = SET_SAP_CODE_FILTER;

  constructor(public payload: string) { }
}

export class SetVplModalError implements Action {
  readonly type = SET_VPL_MODAL_ERROR;

  constructor(public payload: any) { }
}

export type InitiativeActions =
  SetInitiatives |
  GetInitiative |
  SelectInitiative |
  AddInitiative |
  UpdateInitiative |
  DeleteInitiative |
  SetInitiativesTextFilter |
  StoreInitiative |
  SetInitiativesCount |
  SetPagination |
  DeleteSelectedInitativeData |
  SetPage |
  SetSelectedInitiativeItem |
  SetPathItemOfSelectedInitiative |
  GetInitiatives |
  SetInitiativesType |
  SetInitiativesStatus |
  SetInitiativesAdmin |
  SetInitiativesLocations |
  SetInitiativesArguments |
  SetInitiativesStreams |
  SetCurrentWizardStep |
  SetInitiativeSapCodeFilter |
  SetVplModalError;
