/*
* Componente che crea un placeholder per una lista di righe di testo
*/

import { Component } from "@angular/core"

@Component({
  selector: 'multilines-text-placeholder',
  templateUrl: './multilines-text-placeholder.component.html',
  styleUrls: ['./multilines-text-placeholder.component.scss']
})
export class MultilinesTextPlaceholderComponent {
  constructor() {
  }
}