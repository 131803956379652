/*
* Componente che crea un placeholder per l'aggiornamento/creazione del Syllabus, quidni lo step delle informazioni generali
*/

import { Component } from '@angular/core';
@Component({
  selector: 'syllabus-placeholder',
  templateUrl: './syllabus-placeholder.component.html',
  styleUrls: ['./syllabus-placeholder.component.scss']
})

export class SyllabusPlaceholderComponent {

  constructor() {
  }
}