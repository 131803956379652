/*
 * Componente per la pagina di risultato di una Survey
*/

import { Component, OnInit } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { JwtPayload, SenecaResponse, SurveyStatuses, SurveyAttributeTypes } from "../../../cm2-commonclasses";
import { BaseSubscriberComponent } from '../../shared/components/base-subscriber.component';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { TakerService } from '../services/taker.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ExtendedSurvey } from '../../shared/models/extended-survey.model';
import { onceIfNotEmpty } from 'src/app/ngrx/util';
import { getDecodedUrlParam } from 'src/app/shared/services/util';

@Component({
  selector: 'app-survey-result',
  templateUrl: './survey-result.component.html',
  styleUrls: ['./survey-result.component.scss']
})
export class SurveyResultComponent extends BaseSubscriberComponent implements OnInit {
  initiativeLoaded: boolean;
  initiativeId: string;
  defaultLang: string;
  firstInitCompleted: boolean;
  loggedUser: JwtPayload;
  isFetchingData: boolean;
  surveyId: string;
  isSurveyPassed: boolean;
  textSurveyPassed: string;
  textSurveyNotPassed: string;
  textresultToShow: string; //Può avere 3 stati: NONE (default, nessun esito specifico), ALLANSWERS (mostra il numero di domande superate e non), WRONGANSWERS (elenca tutte le risposte errate)
  textIfCanRepeat: boolean;
  textCustomTitlePosition: string;
  itemTitle: string;
  showCountAnswers: boolean;
  showWrongAnswers: boolean;
  listWrongAnswers: any[];
  totalQuestions: number;
  wrongQuestions: number;
  correctQuestions: number;
  originId: {
    itemId?: string,
    projectId?: string,
    sectionId?: string,
    lpId?: string
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private takerService: TakerService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private store: Store<fromApp.AppState>
  ) {
    super();
  }

  ngOnInit() {
    this.originId = {};
    this.textIfCanRepeat = true;
    this.listWrongAnswers = [];
    this.totalQuestions = 0;
    this.wrongQuestions = 0;
    this.correctQuestions = 0;
    this.showCountAnswers = false;
    this.showWrongAnswers = false;

    this.initComponent();
    // Collegamento alla lingua di default
    const defaultLang$: Observable<string> = this.store.select(fromApp.getDefaultLang).pipe(onceIfNotEmpty());
    // Collegamento all'utente loggato
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser).pipe(onceIfNotEmpty());

    // Il cast <any> è necessario per evitare l'errore di Typescript che non riesce a costruire un combineLatest con più di 6 parametri
    const combinedSelectes$ = combineLatest<any>(this.route.params, defaultLang$,
      loggedUser$)
      .pipe(
        map(([params, defaultLang, loggedUser]) => {
          this.initiativeLoaded = false;
          // Salvo l'utente loggato
          if (!this.loggedUser) {
            this.loggedUser = loggedUser;
          }
          this.defaultLang = defaultLang;
          // Aggiorno i parametri presi dall'url
          this.initiativeId = getDecodedUrlParam(params.initiativeId);
          this.surveyId = getDecodedUrlParam(params.surveyId);
          this.originId = params;

          if (!this.isFetchingData) {
            this.loadSurvey();
          }
        }),
        takeUntil(this.unsubscribe$)
      );

    combinedSelectes$.subscribe(() => {
      this.firstInitCompleted = true;
    });
  }

  initComponent() {
    this.isSurveyPassed = false;
    this.isFetchingData = false;
  }
  //getItemTitleByIdForPublic
  // Carica i dati della survey
  loadSurvey() {
    this.isFetchingData = true;

    this.takerService.getItemTitleByIdForPublic(this.loggedUser.user.userId, this.initiativeId)
      .subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + data.error));
          } else if (!data.response) {
            // Alzo l'errore di Item non trovato
            this.toastr.error(this.translate.instant('errors.ITEM_NOT_FOUND'));
          } else {
            this.itemTitle = data.response;
          }
        });

    this.takerService.getSurveyBySurveyId(this.loggedUser.user.userId, this.surveyId)
      .subscribe(
        (data: SenecaResponse<ExtendedSurvey>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + data.error));
          } else if (!data.response) {
            // Alzo l'errore di Item non trovato
            this.toastr.error(this.translate.instant('errors.ITEM_NOT_FOUND'));
          } else {
            let survey = data.response;

            this.totalQuestions = survey.totalQuestions;
            this.correctQuestions = survey.correctQuestions;
            this.wrongQuestions = survey.wrongQuestions;
            this.listWrongAnswers = survey.listWrongAnswers || [];

            //Controllo tutti gli attributi
            for (let i = 0; i < survey.surveyAttributes.length; i++) {
              //Se ha un testo personalizzato per la survey passata
              if (survey.surveyAttributes[i].attributeType == SurveyAttributeTypes.TEXT_SURVEY_PASSED) {
                //Controllo se ha un posizionamento personalizzato per il titolo
                if (survey.surveyAttributes[i].attributeValue.indexOf("${i.title}") > 0) {
                  let index = survey.surveyAttributes[i].attributeValue.indexOf("${i.title}");
                  this.textSurveyPassed = survey.surveyAttributes[i].attributeValue.substr(0, index);
                  this.textCustomTitlePosition = survey.surveyAttributes[i].attributeValue.substr(index + 10, survey.surveyAttributes[i].attributeValue.length);
                } else {
                  this.textSurveyPassed = survey.surveyAttributes[i].attributeValue;
                }
              }
              //Se ha un testo personalizzato per la survey non passata
              if (survey.surveyAttributes[i].attributeType == SurveyAttributeTypes.TEXT_SURVEY_NOT_PASSED) {
                this.textSurveyNotPassed = survey.surveyAttributes[i].attributeValue;
              }
              //Se ha un testo personalizzato da mostrare al completamento del questionario (devo controllarne il testo al suo interno)
              if (survey.surveyAttributes[i].attributeType == SurveyAttributeTypes.RESULT_TO_SHOW) {
                if (survey.surveyAttributes[i].attributeValue.indexOf(SurveyAttributeTypes.RESULT_TO_SHOW_NONE) == 0) {
                  this.textresultToShow = SurveyAttributeTypes.RESULT_TO_SHOW_NONE;
                } else if (survey.surveyAttributes[i].attributeValue.indexOf(SurveyAttributeTypes.RESULT_TO_SHOW_ALLANSWERS) == 0) {
                  this.textresultToShow = SurveyAttributeTypes.RESULT_TO_SHOW_ALLANSWERS;
                  this.showCountAnswers = true;
                } else if (survey.surveyAttributes[i].attributeValue.indexOf(SurveyAttributeTypes.RESULT_TO_SHOW_WRONGANSWERS) == 0) {
                  this.textresultToShow = SurveyAttributeTypes.RESULT_TO_SHOW_WRONGANSWERS;
                  this.showWrongAnswers = true;
                }
              }

              //Se è ripetibile aggiungo un testo in coda al messaggio finale
              if (survey.surveyAttributes[i].attributeType == SurveyAttributeTypes.IS_SURVEY_NOT_REPEATABLE) {
                this.textIfCanRepeat = false;
              }
            }

            // Gli stati della Survey conclusa possono essere o "PASSED" o "NOT_PASSED"
            if (data.response.surveyStatus === SurveyStatuses.PASSED || data.response.surveyStatus === SurveyStatuses.ENDED) {
              this.isSurveyPassed = true;
            } else {
              this.isSurveyPassed = false;
            }
          }
          this.initiativeLoaded = true;
          this.isFetchingData = false;
        },
        (err) => {
          this.initiativeLoaded = true;
          this.isFetchingData = false;
          this.toastr.error(this.translate.instant('errors.' + err.message));
        });
  }

  // Torna al dettaglio dell'item
  backToItemDetails() {
    const isInLibrary = this.router.url.indexOf('library') !== -1;
    const isInCatalog = this.router.url.indexOf('catalog') !== -1;
    const isInTrainingBooklet = this.router.url.indexOf('trainingBooklet') !== -1;
    let baseUrl;
    if (isInLibrary) {
      baseUrl = 'takers/library/';
    } else if (isInCatalog) {
      baseUrl = 'takers/catalog/';
    } else if(isInTrainingBooklet) {
      baseUrl = 'takers/trainingBooklet/';
    } else {
      baseUrl = 'takers/';
    }
    this.router.navigate([baseUrl + 'itemDetails', this.initiativeId]);
  }
}