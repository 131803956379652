<div fxLayout="column" fxLayout.gt-sm="row" class="full-width" style="overflow: hidden!important;">
  <div class="button-bar" fxLayout="row" fxLayout.gt-sm="column">
    <button *ngFor="let tab of tabs"
      [ngClass]="{ active: isActive(tab, activeTabId$ | async), 'with-subtitle': tab.subtitle }"
      (click)="activateTab(tab.tabId)">
      <p class="tab-title">{{ tab.title }}</p>
      <p class="tab-subtitle" *ngIf="tab.subtitle">{{ tab.subtitle }}</p>
    </button>
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>