<div fxLayout="column">
  <!-- Switch editabilità -->
  <div fxLayout="row" *ngIf="mode === 'RANGE' && label">
    <h5>{{ (label | translate) + (required ? '*' : '') }}</h5>

    <input *ngIf="showEditSwitch" type="checkbox" attr.aria-label="{{editSwitchFlag}}" [(ngModel)]="editSwitchFlag"
      [ngModelOptions]="{standalone: true}" class="material-switch">
    <label *ngIf="showEditSwitch" class="switch-label" (click)="emitToggleEditable(editSwitchFlag)">
      <span translate="awWizard.generalInfo.EDITABLE"></span>
    </label>
  </div>

  <div fxLayout="column" *ngIf="mode !== 'RANGE'" [ngClass]="{'margin-bottom0': isWebinarCreation}"
    class="input-wrapper">
    <div class="title-input" *ngIf="label">
      <h5>{{ (label | translate) + (required ? '*' : '') }}</h5>
    </div>
    <input #singleTimeInput [disabled]="disabled" placeholder="{{ placeholder | translate }}" attr.aria-label="{{ placeholder | translate }}"
      class="time-input datetimepicker-input" [owlDateTimeTrigger]="dt4" [owlDateTime]="dt4" [ngModel]="value"
      [readonly]="true" (ngModelChange)="updateValueFromInputText(singleTimeInput);"
      (dateTimeChange)="updateValue($event);">
    <owl-date-time [pickerType]="'timer'" hour12Timer="false" #dt4></owl-date-time>
  </div>

  <div class="input-wrapper" [ngClass]="{'margin-bottom0': isWebinarCreation}" fxLayout="row" *ngIf="mode === 'RANGE'">
    <div fxFlex="50" fxLayout="column">
      <div class="title-input">
        <h5 class="" translate="awWizard.datesAndPlaces.START_TIME"></h5>
      </div>
      <input #startTimeInput [disabled]="disabled" [readonly]="true" placeholder="{{ placeholder | translate }}"
        attr.aria-label="{{ placeholder | translate }}" class="time-input datetimepicker-input"
        [owlDateTimeTrigger]="dt5" [owlDateTime]="dt5" [ngModel]="startTime"
        (ngModelChange)="updateStartTimeFromInputText(startTimeInput);" (dateTimeChange)="updateStartTime($event);">
      <owl-date-time [pickerType]="'timer'" hour12Timer="false" #dt5></owl-date-time>
    </div>
    <div fxFlex="50" fxLayout="column">
      <div class="title-input">
        <h5 class="" translate="awWizard.datesAndPlaces.END_TIME"></h5>
      </div>
      <input #endTimeInput [disabled]="disabled" [readonly]="true" placeholder="{{ placeholder | translate }}"
        attr.aria-label="{{ placeholder | translate }}" class="time-input datetimepicker-input"
        [owlDateTimeTrigger]="dt6" [owlDateTime]="dt6" [ngModel]="endTime"
        (ngModelChange)="updateEndTimeFromInputText(endTimeInput);" (dateTimeChange)="updateEndTime($event);">
      <owl-date-time [pickerType]="'timer'" hour12Timer="false" #dt6></owl-date-time>
    </div>
  </div>

  <div fxLayout="row" *ngIf="fControl  && (fControl.dirty || fControl.touched) && fControl.invalid"
    class="alert alert-danger">
    <div [hidden]="!fControl.errors.required">
      {{ errors.required | translate }}
    </div>
    <div [hidden]="!fControl.errors.minlength">
      {{ errors.minlength | translate }}
    </div>
    <div [hidden]="!fControl.errors.maxlength">
      {{ errors.maxlength | translate }}
    </div>
    <div [hidden]="!fControl.errors.invalidStartDateTime">
      {{ 'errors.forms.dates.ERROR_INVALID_START_DATETIME' | translate }}
    </div>
    <div [hidden]="!fControl.errors.invalidEndDateTime">
      {{ 'errors.forms.dates.ERROR_INVALID_END_DATETIME' | translate }}
    </div>
    <div [hidden]="!fControl.errors.invalidRangeOfTime">
      {{ 'errors.forms.dates.ERROR_INVALID_RANGE_OF_TIME' | translate }}
    </div>
  </div>

</div>