<div class="custom-select generali-input small skill-select" [ngClass]="{disabled: disabled}">
  <!-- Switch editabilità -->
  <div class="title-input" *ngIf="label">
    <div fxLayout="row">
      <h5 [ngClass]="{'on-preiscrition-initiative': onPreiscritionInitiative}" class="full-width">{{ (label | translate) + (required ? '*' : '') }}</h5>

      <input *ngIf="showEditSwitch" type="checkbox" [(ngModel)]="editSwitchFlag" [ngModelOptions]="{standalone: true}"
        class="material-switch" role="checkbox" [min]="min" [max]="max">
      <label *ngIf="showEditSwitch" class="switch-label" (click)="emitToggleEditable(editSwitchFlag)">
        <span translate="awWizard.generalInfo.EDITABLE"></span>
      </label>
    </div>
  </div>

  <div fxLayout="row" class="input-wrapper" [ngClass]="{'margin-bottom0': isWebinarCreation}"
    fxLayoutAlign="start center">
    <input #datePickerInput class="small-dimension datetimepicker-input" attr.aria-label="{{ placeholder | translate }}"
      [owlDateTime]="dt2" [max]="max" [disabled]="disabled" placeholder="{{ placeholder | translate }}"
      [ngModel]="value" (ngModelChange)="updateModelFromInputvalue(datePickerInput);"
      (dateTimeChange)="updateModel(this, $event);" [ngClass]="dimension" tabindex="0" attr.aria-label="{{ 'trainingBooklet.SELECT_COMPETENCE_GOAL_DATE' | translate }}" >

    <span class="input-group-btn" aria-hidden="true" tabindex="-1">
      <button type="button" class="btn-datapiker" [owlDateTimeTrigger]="dt2"  aria-hidden="true" tabindex="-1"
        [disabled]="disabled">
        <svg-icon aria-hidden="true" tabindex="-1" src="assets/icons/icon-calendar.svg"></svg-icon>
      </button>
    </span>
    <owl-date-time aria-hidden="true" tabindex="-1" class="date-picker" #dt2 [firstDayOfWeek]="options?.firstDayOfWeek" [disabled]="disabled" 
      [pickerMode]="pickerMode" [pickerType]="pickerType" (afterPickerClosed)="cleanOpenedStyle()"></owl-date-time>
  </div>

  <div fxLayout="row" *ngIf="fControl  && (fControl.dirty || fControl.touched) && fControl.invalid"
    class="alert alert-danger">
    <div [hidden]="!fControl.errors.required">
      {{ errors.required | translate }}
    </div>
    <div [hidden]="!fControl.errors.minlength">
      {{ errors.minlength | translate }}
    </div>
    <div [hidden]="!fControl.errors.maxlength">
      {{ errors.maxlength | translate }}
    </div>
    <div [hidden]="!fControl.errors.invalidStartDateTime">
      {{ 'errors.forms.dates.ERROR_INVALID_START_DATETIME' | translate }}
    </div>
    <div [hidden]="!fControl.errors.invalidEndDateTime">
      {{ 'errors.forms.dates.ERROR_INVALID_END_DATETIME' | translate }}
    </div>
    <div [hidden]="!fControl.errors.invalidEndEnrollDate">
      {{ 'errors.forms.dates.ERROR_INVALID_END_ENROLLMENT_DATE' | translate }}
    </div>
    <div [hidden]="!fControl.errors.invalidRangeOfTime">
      {{ 'errors.forms.dates.ERROR_INVALID_RANGE_OF_TIME' | translate }}
    </div>
  </div>

</div>