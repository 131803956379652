/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante i template
*/

import * as TemplateActions from './template.actions';
import * as fromApp from '../../ngrx/app.reducers';
import { TextTemplate } from 'atfcore-commonclasses';

// I template sono una feature dell'applicazione, infatti è un modulo caricato in maniera lazy (e quindi anche lo Store).
// Creo un'interfaccia, FeatureState, in cui mappo i template dello state Templatestate
// Estendo fromApp.AppState così per ereditare tutte le proprietà dello Store principale
export interface FeatureState extends fromApp.AppState {
  template: TemplateState
}

// Interfaccia dello Store che torna la lista dei template recuperata
export interface TemplateState {
  templates: TextTemplate[];
  count: number;
  fromRecord: number;
  numRecords: number;
  page: number;
  isFetchingTemplates: boolean;
  selectedTemplateType: string;
}

// Store iniziale
const initialState: TemplateState = {
  templates: [], // lista dei template
  count: 0, // contatore totale dei template
  fromRecord: 0, // numero da cui recuperare gli elementi
  numRecords: 20, // elementi da recuperare
  page: 1, // pagina della tabella visualizzata
  isFetchingTemplates: false, // verifica se sto eseguendo una chiamata per recuperare i template
  selectedTemplateType: null
};

export function templateReducer(state = initialState, action: TemplateActions.TemplateActions) {
  switch (action.type) {
    case TemplateActions.SET_TEMPLATES:
      // return {
      //     ...state,
      //     templates: [...state.templates, ...action.payload] // sovrascrivo i template
      // };
      return {
        ...state,
        isFetchingTemplates: false,
        templates: [...action.payload] // sovrascrivo i template
      };
    case TemplateActions.SET_PAGINATION:
      return {
        ...state,
        fromRecord: action.payload.fromRecord,
        numRecords: action.payload.numRecords
      };
    case TemplateActions.SET_TEMPLATES_TYPE:
      return {
        ...state,
        selectedTemplateType: action.payload
      };
    case TemplateActions.GET_TEMPLATES:
      return {
        ...state,
        isFetchingTemplates: true
      };
    case TemplateActions.SET_PAGE:
      return {
        ...state,
        page: action.payload
      };
    case TemplateActions.SET_TEMPLATES_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    case (TemplateActions.ADD_TEMPLATE):
      return {
        ...state,
        templates: [action.payload, ...state.templates]
      };
    case (TemplateActions.UPDATE_TEMPLATE):
      // Torneremo sempre lo stato aggiornato, ma prima devo modificare il template. Quindi procedo recuperandolo
      const template = state.templates[action.payload.updatedTemplate.textTemplateId]; // template che vogliamo cambiare
      const updatedTemplate = {
        ...template,
        ...action.payload.updatedTemplate
      };
      const templates = [...state.templates]; // con lo spread operator tiro fuori tutti gli elementi dell'array state.templates per aggiungerli immediatamente nel nuovo array. Così, li ho aggiunti in maniera immutabile
      templates[action.payload.updatedTemplate.textTemplateId] = updatedTemplate; // ora posso aggiornare il template
      return {
        ...state,
        templates: templates
      };
    case (TemplateActions.DELETE_TEMPLATE):
      const oldTemplates = [...state.templates];
      for (let i = 0, templatesLength = oldTemplates.length; i < templatesLength; i++) {
        let currentTemplate = oldTemplates[i];
        if (currentTemplate.textTemplateId === action.payload) {
          oldTemplates.splice(i, 1);
          break;
        }
      }
      return {
        ...state,
        templates: oldTemplates
      };
    default:
      return state;
  }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getTemplates = (state: TemplateState) => (state && state.templates) || [];
export const getTemplatesCount = (state: TemplateState) => state.count;
export const getTemplatesFromRecord = (state: TemplateState) => (state && state.fromRecord) || 0;
export const getTemplatesNumRecords = (state: TemplateState) => (state && state.numRecords) || 20;
export const getTemplatesPage = (state: TemplateState) => (state && state.page) || 0;
export const isFetchingTemplates = (state: TemplateState) => state.isFetchingTemplates;
export const getTemplateFilterType = (state: TemplateState) => (state && state.selectedTemplateType) || null;