/*
* Pipe per ordinare le gli item (edizioni) in base allo stato dell'utente
*/

import { PipeTransform, Pipe } from '@angular/core';
import { ItemTakerEnrollStatusTypes } from 'src/cm2-commonclasses';

const fullStatusesList: any[] = [
  ItemTakerEnrollStatusTypes.USER_STATUS_CONFIRMED,
  ItemTakerEnrollStatusTypes.USER_STATUS_OVERBOOKING_CONFIRMED,
  ItemTakerEnrollStatusTypes.USER_STATUS_PRESENT,
  ItemTakerEnrollStatusTypes.USER_STATUS_PREREGISTERED,
  ItemTakerEnrollStatusTypes.USER_STATUS_SPECTATOR_CONFIRMED,
  ItemTakerEnrollStatusTypes.USER_STATUS_INVITED,
  ItemTakerEnrollStatusTypes.USER_STATUS_CANCELLED,
  ItemTakerEnrollStatusTypes.USER_STATUS_ABSENT,
  ItemTakerEnrollStatusTypes.USER_STATUS_SPECTATOR_INSERTED
];
@Pipe({
  name: 'orderByUserStatus',
  pure: false
})

export class OrderByUserStatusPipe implements PipeTransform {
  transform(array?: Array<any>, args?: any): Array<any> {
    if (!array || array === undefined || array.length === 0) return null;
    array.sort((a: any, b: any) => {
      return fullStatusesList.indexOf(a.userStatus) - fullStatusesList.indexOf(b.userStatus);
    });
    return array;
  }
}