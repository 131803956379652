/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante la dashboard
*/

import * as DashboardActions from './dashboard.actions';
import * as fromApp from '../../ngrx/app.reducers';
import { BigWidgetIndicator, CreditWidgetIndicator, SmallWidgetIndicator } from 'src/cm2-commonclasses';

export interface FeatureState extends fromApp.AppState {
  dashboard: DashboardState
}

// Interfaccia dello Store che torna la lista dei template recuperata
export interface DashboardState {
  indicators: { [indicatorKey: string]: SmallWidgetIndicator | BigWidgetIndicator | CreditWidgetIndicator }
}

// Store iniziale
const initialState: DashboardState = {
  indicators: {}
};

export function dashboardReducer(state = initialState, action: DashboardActions.DashboardActions) {
  switch (action.type) {
    case (DashboardActions.UPDATE_INDICATOR):
      return {
        ...state,
        indicators: {
          ...state.indicators,
          [action.payload.key]: action.payload.indicator
        }
      };
    case (DashboardActions.DELETE_INDICATORS):
      for (let indicator in state.indicators) {
        delete state.indicators[indicator];
      }
      return {
        ...state
      };
    default:
      return state;
  }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getIndicators = (state: DashboardState) => (state && state.indicators) || {};