<!-- Syllabus -->
<div class="stepper-container-card">
  <div fxLayout="row">
    <!-- Titolo e sottotitolo della sezione -->
    <div fxLayout="column" class="title-step">
      <h2 translate="{{cardTitle}}"></h2>
      <h5 class="margin-bottom5" *ngIf="cardClaim" translate="{{cardClaim}}"></h5>
      <p>
        <span translate="generic.COURSE"></span>: {{currentTitle}}
      </p>
    </div>
    <!-- Pulsante avanzate -->
    <div (click)="emitToggleSyllabusAdvancedSidebar()" class="advanced-svg-container" fxLayout="row"
      fxLayoutAlign="start center">
      <svg-icon class="advanced-svg" src="assets/icons/icon-ellipsis-h.svg"></svg-icon>
      <p class="advanced" translate="generic.ADVANCED"></p>
    </div>
  </div>
  <!-- Lista con le tab del Syllabus -->
  <div fxLayout="row" fxLayout.xs="column-reverse" fxLayout.sm="column-reverse">
    <div fxLayout="column" class="full-width">
      <!-- Plceholder per la creazione/aggiornamento del syllabus -->
      <ng-template #updatingSyllabus>
        <syllabus-placeholder></syllabus-placeholder>
      </ng-template>

      <tabset *ngIf="!isUpdatingSyllabus; else updatingSyllabus" [tabsetName]="'createSyllabusTab'"
        (onActivateTab)="onActivateTab($event)">

        <!-- Titolo e sottotitolo -->
        <tab [tabProgress]="getFormFlagByFormValidation()" id="syllabus-button-column"
          title="{{ 'awWizard.generalInfo.syllabusTabs.titles.TITLE_AND_SUBTITLE' | translate}}"
          subtitle="{{ isSyllabusFilled('TITLE', 'title') ? ('generic.MANDATORY_FIELD_SYLLABUS_DONE' | translate) : ('generic.MANDATORY_FIELD_SYLLABUS' | translate) }}">
          <!-- Titolo -->
          <div fxLayout="column" fxLayoutAlign="space-between center" class="section-in-card">
            <div fxLayout="row" fxFlexFill>
              <lib-alloy-input *ngIf="frontEndSyllabus.TITLE" fxFlexFill [required]="true"
                errorRequiredText="{{ 'errors.forms.generic.ERROR_FIELD_REQUIRED' | translate }}"
                [attrName]="'syllabusTitle'" [type]="'text'" [bindValue]="frontEndSyllabus.TITLE.data.title"
                (onModelChanged)="onSyllabusFieldChanged($event, 'TITLE', 'title')"
                [placeholder]="'awWizard.generalInfo.syllabusPlaceholders.TYPE_INITIATIVE_TITLE' | translate"
                [attrAriaLabel]="'awWizard.generalInfo.syllabusPlaceholders.TYPE_INITIATIVE_TITLE' | translate"
                inputContainerClass="small-dimension" [disabled]="isInitiativeArchived"
                title="{{ 'awWizard.generalInfo.syllabusTabs.titles.TITLE' | translate }} *">
              </lib-alloy-input>
            </div>
          </div>

          <!-- Sottotitolo -->
          <div fxLayout="column" fxLayoutAlign="space-between center" class="section-in-card">
            <div fxLayout="row" fxFlexFill>
              <lib-alloy-input *ngIf="frontEndSyllabus.SUBTITLE" fxFlexFill [attrName]="'syllabusSubtitle'"
                [type]="'text'" [bindValue]="frontEndSyllabus.SUBTITLE.data.subTitle"
                (onModelChanged)="onSyllabusFieldChanged($event, 'SUBTITLE', 'subTitle')"
                [placeholder]="'awWizard.generalInfo.syllabusPlaceholders.TYPE_INITIATIVE_SUBTITLE' | translate"
                [attrAriaLabel]="'awWizard.generalInfo.syllabusPlaceholders.TYPE_INITIATIVE_SUBTITLE' | translate"
                inputContainerClass="small-dimension" [disabled]="isInitiativeArchived"
                title="{{ 'awWizard.generalInfo.syllabusTabs.titles.SUBTITLE' | translate }}">
              </lib-alloy-input>
            </div>
          </div>
        </tab>

        <!-- Obiettivi -->
        <tab [tabProgress]="getFormFlagByFormValidation(aimsForm)" [formGroup]="aimsForm.formGroup"
          title="{{ 'awWizard.generalInfo.syllabusTabs.titles.AIMS' | translate}}"
          subtitle="{{ isSyllabusFilled('AIMS', 'description') ? ('generic.MANDATORY_FIELD_SYLLABUS_DONE' | translate) : ('generic.MANDATORY_FIELD_SYLLABUS' | translate) }}">
          <syllabus-basic-editor fxFlexFill [editorConfig]="ckeConfig" [fControl]="aims" formControlName="aims"
            name="syllabusAims" [reviewMode]="reviewMode"
            label="{{'awWizard.generalInfo.syllabusTabs.titles.AIMS' | translate}} *"
            [(value)]="frontEndSyllabus.AIMS.data.description" [isDisabled]="isInitiativeArchived">
          </syllabus-basic-editor>
        </tab>

        <!-- Programma -->
        <tab [tabProgress]="getFormFlagByFormValidation(agendaForm)" [formGroup]="agendaForm.formGroup"
          title="{{ 'awWizard.generalInfo.syllabusTabs.titles.AGENDA' | translate}}"
          subtitle="{{ isSyllabusFilled('AGENDA', 'description') ? ('generic.MANDATORY_FIELD_SYLLABUS_DONE' | translate) : ('generic.MANDATORY_FIELD_SYLLABUS' | translate) }}">
          <syllabus-basic-editor [isDisabled]="isInitiativeArchived" [editorConfig]="ckeConfig" [fControl]="agenda"
            formControlName="agenda" name="syllabusAgenda" [reviewMode]="reviewMode"
            label="{{'awWizard.generalInfo.syllabusTabs.titles.AGENDA' | translate}} *"
            [(value)]="frontEndSyllabus.AGENDA.data.description">
          </syllabus-basic-editor>
        </tab>

        <!-- Durata è obbligatorio solo se è un corso interno -->
        <tab [tabProgress]="getFormFlagByFormValidation()" *ngIf="frontEndSyllabus['DURATION']?.active"
          title="{{ 'awWizard.generalInfo.syllabusTabs.titles.DURATION' | translate}}"
          subtitle="{{ (isExternalCourse || isSyllabusFilled('DURATION', 'description')) ? ('generic.MANDATORY_FIELD_SYLLABUS_DONE' | translate) : ('generic.MANDATORY_FIELD_SYLLABUS' | translate) }}">
          <!-- Durata -->

          <div class="section-in-card" fxLayout.gt-sm="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start start"
            fxLayoutAlign.gt-sm="space-between start">
            <div fxLayout="column" fxFlex.gt-sm style="margin-right: 20px;">
              <lib-alloy-input *ngIf="frontEndSyllabus.DURATION" fxFlexFill [required]="true"
                errorRequiredText="{{ 'errors.forms.generic.ERROR_FIELD_REQUIRED' | translate }}"
                [attrName]="'duration'" [type]="'text'" [bindValue]="frontEndSyllabus.DURATION.data.description"
                (onModelChanged)="onSyllabusFieldChanged($event, 'DURATION', 'description')"
                [placeholder]="'awWizard.generalInfo.syllabusPlaceholders.TYPE_INITIATIVE_DURATION' | translate"
                [attrAriaLabel]="'awWizard.generalInfo.syllabusPlaceholders.TYPE_INITIATIVE_DURATION' | translate"
                inputContainerClass="small-dimension" [disabled]="isInitiativeArchived"
                title="{{ 'awWizard.generalInfo.syllabusTabs.titles.DURATION' | translate }} *">
              </lib-alloy-input>
            </div>
            <div class="hint-label" fxFlex.gt-sm>
              <p translate="awWizard.generalInfo.syllabusTabs.titles.DURATION"></p>
              <p translate="awWizard.generalInfo.DURATION_DESCRIPTION"></p>
            </div>
          </div>

          <!-- Valore ore formative -->
          <!-- <div fxLayout="column" fxLayoutAlign="space-between center" class="section-in-card">
            <div fxLayout="row" fxFlexFill>
              <lib-alloy-input fxFlexFill [required]="true" class="margin-bottom30" [attrName]="'courseValue'"
                errorRequiredText="{{ 'errors.forms.generic.ERROR_FIELD_REQUIRED' | translate }}" [type]="'number'"
                min="0" [bindValue]="courseValue" (onModelChanged)="emitOnEditionCourseValueChanged($event)"
                [placeholder]="'accessibilityAttributes.INSERT_FORMATIVE_HOURS' | translate"
                [attrAriaLabel]="'accessibilityAttributes.INSERT_FORMATIVE_HOURS' | translate"
                [disabled]="isInitiativeArchived" title="{{ 'generic.HOUR_TRAINING_VALUE' | translate }} *"
                inputContainerClass="small-dimension">
              </lib-alloy-input>
            </div>
          </div> -->
        </tab>

        <!-- Competenze necessarie *ngIf="frontEndSyllabus['REQUIRED_COMPETENCES']?.active" -->
        <tab [formGroup]="agendaForm.formGroup"
          title="{{ 'awWizard.generalInfo.syllabusTabs.titles.REQUIRED_COMPETENCES' | translate}}">
          <p>{{ 'initiatives.INSERT_COMPETENCES_TITLE' | translate }}</p>

          <div class="space20"></div>

          <lib-alloy-button [disabled]="isInitiativeArchived" importance="primary" dimension="btn-regular"
            text="{{ 'trainingBooklet.ADD_COMPETENCE' | translate }}"
            (onButtonClicked)="addCompetence = !addCompetence">
          </lib-alloy-button>


          <div *ngIf="addCompetence">
            <div class="space20"></div>
            <div class="full-width" style="background: #F0F0F0; padding: 15px;">
              <div class="space10"></div>
              <h3>{{ 'trainingBooklet.ADD_COMPETENCE' | translate }}</h3>

              <div class="space20"></div>
              <div style="border: 1px solid #CECECE;"></div>
              <div class="space30"></div>

              <!-- Competenza e livello -->
              <div fxLayout="row" fxLayoutAlign="space-around center">
                <lib-alloy-select [disabled]="isInitiativeArchived" [multiple]="false" [virtualScroll]="true"
                  [items]="competences"
                  formTitle="{{ 'awWizard.generalInfo.syllabusPlaceholders.SELECT_COMPETENCE' | translate }}"
                  placeholderLabel="{{ 'awWizard.generalInfo.syllabusPlaceholders.SEARCH_COMPETENCE' | translate }}"
                  startSearchLabel="" noDataFoundLabel="No data" [clearOnBackspace]="true"
                  loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="regular-dimension"
                  (onSelectionChanged)="onNewRequiredCompetenceSelected($event)" [searchable]="true">
                </lib-alloy-select>

                <lib-alloy-select [disabled]="!selectedCompetence || isInitiativeArchived" [multiple]="false"
                  [virtualScroll]="true" [items]="competenceLevel"
                  formTitle="{{ 'awWizard.generalInfo.syllabusPlaceholders.COMPETENCE_LEVEL' | translate }}"
                  placeholderLabel="{{ 'awWizard.generalInfo.syllabusPlaceholders.SELECT_LEVEL' | translate }}"
                  startSearchLabel="" noDataFoundLabel="No data" [clearOnBackspace]="true"
                  loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="regular-dimension"
                  (onSelectionChanged)="onNewRequiredCompetenceLevelSelected($event)" [searchable]="true">
                </lib-alloy-select>
              </div>

              <div class="space30"></div>
              <div style="border: 1px solid #CECECE;"></div>
              <div class="space20"></div>

              <!-- Annulla e Aggiungi -->
              <div fxLayout="row" fxLayoutAlign="center center">
                <lib-alloy-button importance="bordless" dimension="btn-regular"
                  text="{{ 'generic.CANCEL' | translate }}" (onButtonClicked)="closeAddCompetences()">
                </lib-alloy-button>
                <lib-alloy-button importance="primary" dimension="btn-regular" text="{{ 'generic.ADD' | translate }}"
                  (onButtonClicked)="canAddRequiredCompetence()"
                  [disabled]="!selectedCompetence || !selectedCompetence.scaleLevelName || isInitiativeArchived">
                </lib-alloy-button>
              </div>
            </div>
          </div>


          <div class="space30"></div>

          <!-- Tabella Competenze -->
          <div *ngIf="requiredCompetences.length != 0">
            <div fxLayout="row" fxFlexFill fxLayoutAlign="center center">
              <table role="grid" class="theme-primary-table">
                <thead>
                  <tr>
                    <td>{{ 'initiatives.tableHeaders.COMPETENCE_NAME' | translate}}</td>
                    <td>{{ 'initiatives.tableHeaders.COMPETENCE_LEVEL' | translate}}</td>
                    <td>{{ 'generic.ACTIONS' | translate }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let competence of requiredCompetences">
                    <td>{{competence.competenceName}}</td>
                    <td>{{competence.levelName}}</td>
                    <td *ngIf="!isInitiativeArchived" (click)="deleteRequiredCompetence(competence)"
                      style="text-decoration: underline; font-style: italic; cursor: pointer;">{{'generic.REMOVE' |
                      translate}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </tab>

        <!-- Area funzionale -->
        <tab *ngIf="frontEndSyllabus['FUNCTIONAL_AREAS']?.active" [tabProgress]="getFormFlagByFormValidation()"
          title="{{ 'awWizard.generalInfo.syllabusTabs.titles.FUNCTIONAL_AREAS' | translate}}">
          <div fxLayout="column" fxLayoutAlign="space-between center" class="section-in-card">

            <lib-alloy-select [disabled]="isInitiativeArchived" *ngIf="frontEndSyllabus['FUNCTIONAL_AREAS']"
              class="full-width" [bindLabel]="'title'" [multiple]="false" [bindValue]="'tagId'"
              [bindModel]="frontEndSyllabus['FUNCTIONAL_AREAS'].data"
              [loading]="(tagVariables.get('FUNCTIONAL_AREAS') && tagVariables.get('FUNCTIONAL_AREAS').isLoadingTagList)"
              [parameterNameOfSelectedItem]="'title'" [virtualScroll]="true"
              [items]="(tagVariables.get('FUNCTIONAL_AREAS') && tagVariables.get('FUNCTIONAL_AREAS').tagList)"
              (scrollToEnd)="fetchMoreGenericTagsFunction($event, 'FUNCTIONAL_AREAS')"
              formTitle="{{ ('awWizard.generalInfo.syllabusTabs.titles.FUNCTIONAL_AREAS') | translate }}"
              placeholderLabel="{{ ('awWizard.generalInfo.syllabusPlaceholders.SELECT_FUNCTIONAL_AREAS') | translate }}"
              startSearchLabel="{{ ('awWizard.generalInfo.syllabusPlaceholders.SELECT_FUNCTIONAL_AREAS') | translate }}"
              noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}" [clearOnBackspace]="true"
              loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="regular-dimension"
              [typeahead]="(tagVariables.get('FUNCTIONAL_AREAS') && tagVariables.get('FUNCTIONAL_AREAS').tagSrvSubject)"
              [searchable]="true" (onSelectionChanged)="addGeneralTagToItem(syllabus, $event, 'FUNCTIONAL_AREAS')"
              attrAriaLabel="{{ ('awWizard.generalInfo.syllabusPlaceholders.SELECT_FUNCTIONAL_AREAS') | translate }}">
            </lib-alloy-select>
          </div>
        </tab>

        <!-- Caratteristiche specifiche iniziativa -->
        <tab *ngIf="frontEndSyllabus['STREAMS']?.active && frontEndSyllabus['COURSE_CATEGORY']?.active"
          [tabProgress]="getFormFlagByFormValidation()"
          title="{{ ('awWizard.generalInfo.syllabusTabs.titles.SPECIFIC_CHARACTERISTIC_INITIATIVE') | translate}}">
          <div fxLayout="column" fxLayoutAlign="space-between center" class="section-in-card">
            <div fxLayout="row" fxFlexFill>

              <lib-alloy-select [disabled]="isInitiativeArchived" class="full-width" [bindLabel]="'title'"
                [multiple]="false" [bindValue]="'tagId'" [bindModel]="frontEndSyllabus.STREAMS.data"
                [loading]="(tagVariables.get('STREAMS') && tagVariables.get('STREAMS').isLoadingTagList)"
                [parameterNameOfSelectedItem]="'title'" [virtualScroll]="true"
                [items]="(tagVariables.get('STREAMS') && tagVariables.get('STREAMS').tagList)"
                (scrollToEnd)="fetchMoreGenericTagsFunction($event, 'STREAMS')"
                formTitle="{{ ('awWizard.generalInfo.syllabusTabs.titles.STREAMS') | translate }}"
                placeholderLabel="{{ ('awWizard.generalInfo.syllabusPlaceholders.SELECT_STREAMS') | translate }}"
                startSearchLabel="{{ ('awWizard.generalInfo.syllabusPlaceholders.SELECT_STREAMS') | translate }}"
                noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}" [clearOnBackspace]="true"
                loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="regular-dimension"
                [typeahead]="(tagVariables.get('STREAMS') && tagVariables.get('STREAMS').tagSrvSubject)"
                [searchable]="true" (onSelectionChanged)="addGeneralTagToItem(syllabus, $event, 'STREAMS')"
                attrAriaLabel="{{ ('awWizard.generalInfo.syllabusPlaceholders.SELECT_STREAMS') | translate }}">
              </lib-alloy-select>
            </div>

            <div fxLayout="row" fxFlexFill>
              <lib-alloy-select [disabled]="isInitiativeArchived" class="full-width" [virtualScroll]="true"
                [clearOnBackspace]="true" 
                formTitle="{{ ('awWizard.generalInfo.syllabusTabs.titles.LANGUAGE') | translate }}"
                startSearchLabel="{{ ('awWizard.generalInfo.syllabusTabs.titles.LANGUAGE_SELECTION') | translate }}"
                [items]="languageList" noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
                placeholderLabel="{{ ('awWizard.generalInfo.syllabusTabs.titles.LANGUAGE_SELECTION') | translate }}"
                loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="regular-dimension"
                (onSelectionChanged)="addLanguageToItem(syllabus,'LANGUAGE',$event)" [bindModel]="selectedLanguage"
                attrAriaLabel="{{ ('awWizard.generalInfo.syllabusTabs.titles.LANGUAGE_SELECTION') | translate }}">
              </lib-alloy-select>
            </div>

          </div>
        </tab>


        <!-- Altri tag -->
        <ng-container *ngFor="let tag of getUsableTags()">
          <!-- *ngIf="frontEndSyllabus[tag]?.active" -->
          <tab [tabProgress]="getFormFlagByFormValidation()"
            title="{{ ('awWizard.generalInfo.syllabusTabs.titles.' + tag) | translate}}"
            subtitle="{{ isRequired(tag) ? isSyllabusFilled(tag, 'description') ? ('generic.MANDATORY_FIELD_SYLLABUS_DONE' | translate) : ('generic.MANDATORY_FIELD_SYLLABUS' | translate):'' }}">
            <div fxLayout="column" fxLayoutAlign="space-between center" class="section-in-card">

              <div fxLayout="row" fxFlexFill>

                <lib-alloy-select [disabled]="isInitiativeArchived" [required]="true"
                  *ngIf="tag && frontEndSyllabus[tag] && (tag != 'TECH_COMPETENCES' && tag != 'SOFT_COMPETENCES' && tag != 'LANG' && tag != 'ADMIN_TAGS')"
                  class="full-width" [bindLabel]="'title'" [multiple]="false" [bindValue]="'tagId'"
                  [bindModel]="frontEndSyllabus[tag]"
                  [loading]="(tagVariables.get(tag) && tagVariables.get(tag).isLoadingTagList)"
                  [parameterNameOfSelectedItem]="'title'" [virtualScroll]="true"
                  [items]="(tagVariables.get(tag) && tagVariables.get(tag).tagList)"
                  (scrollToEnd)="fetchMoreGenericTagsFunction($event, tag)"
                  formTitle="{{ ('awWizard.generalInfo.syllabusTabs.titles.' + tag) | translate }}{{tag == 'CLUSTERS' || tag == 'SAP_CODICE_TIPO_EVENTO' ? '*' : ''}}"
                  placeholderLabel="{{ ('awWizard.generalInfo.syllabusPlaceholders.SELECT_' + tag) | translate }}"
                  startSearchLabel="{{ ('awWizard.generalInfo.syllabusPlaceholders.SELECT_' + tag) | translate }}"
                  noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}" [clearOnBackspace]="true"
                  loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="regular-dimension"
                  [typeahead]="(tagVariables.get(tag) && tagVariables.get(tag).tagSrvSubject)" [searchable]="true"
                  (onSelectionChanged)="addGeneralTagToItem(syllabus, $event, tag)"
                  attrAriaLabel="{{ ('awWizard.generalInfo.syllabusPlaceholders.SELECT_' + tag) | translate }}">
                </lib-alloy-select>

                <lib-alloy-select [disabled]="isInitiativeArchived" [required]="true"
                  *ngIf="tag && frontEndSyllabus[tag] && (tag == 'TECH_COMPETENCES' || tag == 'SOFT_COMPETENCES' || tag == 'LANG' || tag == 'ADMIN_TAGS')"
                  class="full-width" [bindLabel]="'title'" [multiple]="true" [bindValue]="'tagId'"
                  [bindModel]="frontEndSyllabus[tag].data"
                  [loading]="(tagVariables.get(tag) && tagVariables.get(tag).isLoadingTagList)"
                  [parameterNameOfSelectedItem]="'title'" [virtualScroll]="true"
                  [weightSelection]="checkWeightSelectionTags(tag)" (addItem)="checkAddItemGenericTag($event, tag)"
                  [items]="(tagVariables.get(tag) && tagVariables.get(tag).tagList)"
                  (scrollToEnd)="fetchMoreGenericTagsFunction($event, tag)"
                  formTitle="{{ ('awWizard.generalInfo.syllabusTabs.titles.' + tag) | translate }}"
                  placeholderLabel="{{ ('awWizard.generalInfo.syllabusPlaceholders.SELECT_' + tag) | translate }}"
                  startSearchLabel="{{ ('awWizard.generalInfo.syllabusPlaceholders.SELECT_' + tag) | translate }}"
                  noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}" [clearOnBackspace]="true"
                  loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="regular-dimension"
                  [typeahead]="(tagVariables.get(tag) && tagVariables.get(tag).tagSrvSubject)" [searchable]="true"
                  (removeItem)="removeTagFromItem(syllabus, $event)"
                  attrAriaLabel="{{ ('awWizard.generalInfo.syllabusPlaceholders.SELECT_' + tag) | translate }}">
                </lib-alloy-select>

              </div>
            </div>
          </tab>
        </ng-container>

        <!-- A chi si rivolge (target) -->
        <tab [tabProgress]="getFormFlagByFormValidation(prereqsForm)" [formGroup]="prereqsForm.formGroup"
          title="{{ 'awWizard.generalInfo.syllabusTabs.titles.PREREQS' | translate}}"
          subtitle="{{ frontEndSyllabus.PREREQS?.data?.description ? ('generic.MANDATORY_FIELD_SYLLABUS_DONE' | translate) : ('generic.MANDATORY_FIELD_SYLLABUS' | translate) }}">
          <syllabus-basic-editor [isDisabled]="isInitiativeArchived" fxFlexFill [editorConfig]="ckeConfig"
            [fControl]="prereqs" formControlName="prereqs" [reviewMode]="reviewMode"
            label="{{'awWizard.generalInfo.syllabusTabs.titles.PREREQS' | translate}} *"
            [(value)]="frontEndSyllabus.PREREQS?.data.description">
          </syllabus-basic-editor>
        </tab>

        <!-- Organizzatore -->
        <!-- <tab [tabProgress]="getFormFlagByFormValidation(partenersForm)" [formGroup]="partenersForm.formGroup"
          title="{{ 'awWizard.generalInfo.syllabusTabs.titles.PARTNER' | translate}}">
          <h3 class="margin-bottom5">
            <span translate="awWizard.generalInfo.syllabusTabs.titles.CHOOSE_PARTNER"></span>
          </h3> -->

        <!-- Checkbox che mi permette di scegliere un ente organizzatore da una select -->
        <!-- <div fxLayout="row" fxLayoutAlign="start center" style="padding: 10px 0px 20px 0px;">
            <div fxLayout="row" class="margin-right10">
              <span class="labelSwitch" translate="generic.NO"></span>
            </div>
            <div fxLayout="row" class="margin-right10">
              <label class="switch" role="button" aria-label="Catalogo">
                <input [disabled]="isInitiativeArchived" type="checkbox" id="useSupplierPersonCheck" (click)="toggleChoosePartner()"
                  [checked]="parseBoolean(frontEndSyllabus.USE_SUPPLIER_PARTNER.data.description)">
                <div class="slider round">
                </div>
              </label>
            </div>
            <div fxLayout="row">
              <span class="labelSwitch" translate="generic.YES"></span>
            </div>
          </div> -->
        <!-- Scelta ente organizzatore tramite select  -->
        <!-- <ng-container>
            <lib-alloy-select [disabled]="isInitiativeArchived" [bindLabel]="'supplierName'" [bindValue]="'supplierId'"
              [bindModel]="organizerSelected" [loading]="isFetchingSuppliers"
              [parameterNameOfSelectedItem]="'supplierName'" (scrollToEnd)="fetchMoreSuppliers()" [items]="supplierList"
              [virtualScroll]="true" formTitle="{{ 'awWizard.generalInfo.syllabusTabs.titles.ORGANIZER' | translate }}"
              placeholderLabel="{{ 'awWizard.generalInfo.syllabusPlaceholders.SELECT_ORGANIZER' | translate }}"
              startSearchLabel="{{ 'awWizard.generalInfo.WRITE_ORGANIZER_DATA' | translate }}"
              noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
              (onSelectionChanged)="onSyllabusFieldChanged($event, 'SUPPLIER_PARTNER', 'description')"
              loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" [addTag]="false"
              containerClass="small-dimension" [multiple]="false" [typeahead]="getSupplierListInput$"
              [dropdownPosition]="'bottom'" (close)="clearSupplierList()" [searchable]="true"
              attrAriaLabel="{{ 'accessibilityAttributes.SELECT_ORGANIZER' | translate }}"
              avatarImageAccessibilityText="{{ 'awWizard.generalInfo.SEARCH_ORGANIZER' | translate }}"
              [clearOnBackspace]="true">
            </lib-alloy-select>
          </ng-container> -->
        <!-- Scelta ente organizzatore libera -->
        <!-- <ng-container *ngIf="!useSupplierPartner.value">
            <syllabus-basic-editor [isDisabled]="isInitiativeArchived" fxFlexFill [editorConfig]="ckeConfig" [fControl]="partner" formControlName="partner"
              [reviewMode]="reviewMode" label="awWizard.generalInfo.syllabusTabs.titles.ORGANIZER"
              [(value)]="frontEndSyllabus.PARTNER.data.description">
            </syllabus-basic-editor>
          </ng-container>
        </tab> -->

        <!-- Prezzo -->
        <tab *ngIf="frontEndSyllabus['PRICE']?.active && isPriceVisible()" [tabProgress]="getFormFlagByFormValidation()"
          title="{{ 'generic.PRICE' | translate}}">
          <div fxLayout="column" fxLayoutAlign="space-between center" class="section-in-card">
            <div fxLayout="row" fxFlexFill>
              <lib-alloy-input [disabled]="isInitiativeArchived" *ngIf="frontEndSyllabus.PRICE" fxFlexFill
                class="margin-bottom30" [attrName]="'price'" [type]="'number'" min="0"
                [bindValue]="frontEndSyllabus.PRICE.data.description" step="0.01"
                (onModelChanged)="onSyllabusFieldChanged($event, 'PRICE', 'description')"
                [placeholder]="'accessibilityAttributes.INSERT_PRICE' | translate"
                [attrAriaLabel]="'accessibilityAttributes.INSERT_PRICE' | translate"
                title="{{ 'generic.PRICE' | translate }}" inputContainerClass="regular-dimension">
              </lib-alloy-input>
            </div>
          </div>
        </tab>

        <!-- Contatti -->
        <tab [tabProgress]="getFormFlagByFormValidation()" *ngIf="frontEndSyllabus['CONTACTS']?.active"
          title="{{ 'awWizard.generalInfo.syllabusTabs.titles.CONTACTS' | translate}}">
          <!-- Contatto tutor -->
          <div fxLayout="column" fxLayoutAlign="space-between center" class="section-in-card">
            <div fxLayout="row" fxFlexFill>
              <lib-alloy-input [disabled]="isInitiativeArchived" *ngIf="frontEndSyllabus.TUTOR_CONTACT" fxFlexFill
                [attrName]="'syllabusTutorContact'" [type]="'text'"
                [bindValue]="frontEndSyllabus.TUTOR_CONTACT.data.description"
                (onModelChanged)="onSyllabusFieldChanged($event, 'TUTOR_CONTACT', 'description')"
                [placeholder]="'awWizard.generalInfo.syllabusPlaceholders.TYPE_TUTOR_CONTACT' | translate"
                [attrAriaLabel]="'awWizard.generalInfo.syllabusPlaceholders.TYPE_TUTOR_CONTACT' | translate"
                inputContainerClass="small-dimension"
                title="{{ 'awWizard.generalInfo.syllabusTabs.titles.TUTOR_CONTACT' | translate }}">
              </lib-alloy-input>
            </div>
          </div>

          <!-- Contatto docente -->
          <div fxLayout="column" *ngIf="frontEndSyllabus['TEACHER_CONTACT']?.active"
            fxLayoutAlign="space-between center" class="section-in-card">
            <div fxLayout="row" fxFlexFill>
              <lib-alloy-input [disabled]="isInitiativeArchived" *ngIf="frontEndSyllabus.TEACHER_CONTACT" fxFlexFill
                [attrName]="'syllabusTeacherContact'" [type]="'text'"
                [bindValue]="frontEndSyllabus.TEACHER_CONTACT.data.description"
                (onModelChanged)="onSyllabusFieldChanged($event, 'TEACHER_CONTACT', 'description')"
                [placeholder]="'awWizard.generalInfo.syllabusPlaceholders.TYPE_TEACHER_CONTACT' | translate"
                [attrAriaLabel]="'awWizard.generalInfo.syllabusPlaceholders.TYPE_TEACHER_CONTACT' | translate"
                inputContainerClass="small-dimension"
                title="{{ 'awWizard.generalInfo.syllabusTabs.titles.TEACHER_CONTACT' | translate }}">
              </lib-alloy-input>
            </div>
          </div>
        </tab>

        <!-- Protocollo -->
        <tab [tabProgress]="getFormFlagByFormValidation(protocolForm)" *ngIf="frontEndSyllabus['PROTOCOL']?.active"
          [formGroup]="protocolForm.formGroup"
          title="{{ 'awWizard.generalInfo.syllabusTabs.titles.PROTOCOL' | translate}}">
          <lib-alloy-input [disabled]="isInitiativeArchived" *ngIf="frontEndSyllabus.PROTOCOL_NUMBER" fxFlexFill
            class="margin-bottom30" [attrName]="'protocolNumber'" [type]="'text'"
            [bindValue]="frontEndSyllabus.PROTOCOL_NUMBER.data.description"
            (onModelChanged)="onSyllabusFieldChanged($event, 'PROTOCOL_NUMBER', 'description')"
            [placeholder]="'awWizard.generalInfo.syllabusTabs.titles.INSERT_AUTORIZATION_PROTOCOL_NUMBER' | translate"
            [attrAriaLabel]="'awWizard.generalInfo.syllabusTabs.titles.INSERT_AUTORIZATION_PROTOCOL_NUMBER' | translate"
            title="{{ 'awWizard.generalInfo.syllabusTabs.titles.AUTORIZATION_PROTOCOL_NUMBER' | translate }}"
            inputContainerClass="regular-dimension">
          </lib-alloy-input>
          <!-- Datapicker data del protocollo autorizzativo  -->
          <div class="form-input" layout="row" fxLayoutAlign="start center" class="margin-top15">
            <datepicker [disabled]="isInitiativeArchived"
              placeholder="awWizard.generalInfo.syllabusTabs.titles.INSERT_AUTORIZATION_PROTOCOL_DATE"
              class="full-width" label="awWizard.generalInfo.syllabusTabs.titles.AUTORIZATION_PROTOCOL_DATE"
              (valueChange)="onSyllabusFieldChanged($event, 'PROTOCOL_DATE', 'description')"
              [(value)]="frontEndSyllabus.PROTOCOL_DATE.data.description" [fControl]="protocolDate"
              pickerType="calendar" formControlName="protocolDate" [onPreiscritionInitiative]="true">
            </datepicker>
          </div>
        </tab>

        <!-- Formazione obbligatoria -->
        <tab [tabProgress]="getFormFlagByFormValidation(mandatoryCourseForm)"
          *ngIf="frontEndSyllabus['MANDATORY_COURSE']?.active" [formGroup]="mandatoryCourseForm.formGroup"
          title="{{ 'awWizard.generalInfo.syllabusTabs.titles.MANDATORY_COURSE' | translate}}">
          <!-- Formazione obbligatoria -->
          <h3 class="margin-bottom5">
            <span translate="awWizard.generalInfo.syllabusTabs.titles.MANDATORY_COURSE"></span>
          </h3>

          <!-- Checkbox se la formazione è obbligatoria -->
          <div fxLayout="row" fxLayoutAlign="start center" style="padding: 10px 0px 20px 0px;">
            <div fxLayout="row" class="margin-right10">
              <span class="labelSwitch" translate="generic.NO"></span>
            </div>
            <div fxLayout="row" class="margin-right10">
              <label class="switch" role="button" aria-label="Formazione obbligatoria">
                <input [disabled]="isInitiativeArchived" type="checkbox" id="mandatoryCourse"
                  (click)="toggleChooseMandatoryCourse()"
                  [checked]="parseBoolean(frontEndSyllabus.MANDATORY_COURSE.data.description)">
                <div class="slider round">
                </div>
              </label>
            </div>
            <div fxLayout="row">
              <span class="labelSwitch" translate="generic.YES"></span>
            </div>
          </div>
        </tab>

        <!-- Argomenti attestato -->
        <tab [tabProgress]="getFormFlagByFormValidation()" *ngIf="frontEndSyllabus['CERTIFICATE_ARGUMENTS']?.active"
          title="{{ 'awWizard.generalInfo.syllabusTabs.titles.CERTIFICATE_ARGUMENTS' | translate}}">
          <div fxLayout="column" class="section-in-card arguments-container">
            <p tabindex="0" class="margin-bottom25"
              *ngIf="frontEndSyllabus.CUSTOM_ARGUMENT && frontEndSyllabus.CUSTOM_ARGUMENT.data && frontEndSyllabus.CUSTOM_ARGUMENT.data.length">
              {{ 'awWizard.generalInfo.ARGUMENTS_ADDED' | translate
              }}</p>
            <!-- Argomenti aggiunti -->
            <div fxLayout.gt-xs="row wrap" class="full-width margin-bottom25" fxLayout="column"
              fxLayoutAlign.gt-xs="space-between center"
              *ngIf="frontEndSyllabus.CUSTOM_ARGUMENT && frontEndSyllabus.CUSTOM_ARGUMENT.data && frontEndSyllabus.CUSTOM_ARGUMENT.data.length">
              <div class="added-argument"
                *ngFor="let argument of frontEndSyllabus.CUSTOM_ARGUMENT.data | orderByAttributeOrder">
                <div fxLayout="row" class="full-width margin-bottom-30" fxLayoutAlign="start center">
                  <div class="added-arguments-list" fxLayout="column">
                    <p *ngIf="argument" tabindex="0">{{ argument.description || '' }}</p>
                  </div>
                  <div fxLayoutAlign="end center" fxLayout="column">
                    <lib-alloy-button *ngIf="!isInitiativeArchived" importance="secondary" dimension="btn-small"
                      text="{{ 'generic.REMOVE' | translate }}" (onButtonClicked)="removeCertiticateArgument(argument)">
                    </lib-alloy-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Aggiungi nuovo -->
            <div fxLayout="row" fxFlexFill>
              <lib-alloy-input [disabled]="isInitiativeArchived" fxFlexFill [attrName]="'certificateArguments'"
                [type]="'text'" [bindValue]="newCertificateArgumentTmp" (onIconClicked)="addNewCertificateArgument()"
                (onModelChanged)="onNewCertificateArgumentTmpChanged($event)"
                [placeholder]="'awWizard.generalInfo.syllabusPlaceholders.TYPE_CERTIFICATE_ARGUMENTS' | translate"
                [attrAriaLabel]="'awWizard.generalInfo.syllabusPlaceholders.TYPE_CERTIFICATE_ARGUMENTS' | translate"
                inputContainerClass="full-dimension" title="{{ 'awWizard.generalInfo.ADD_NEW_ARGUMENT' | translate }}">
              </lib-alloy-input>
            </div>
            <!-- Conferma nuovo argomento -->
            <div fxLayout="row" fxLayoutAlign="end center" class="remove-argument">
              <lib-alloy-button *ngIf="!isInitiativeArchived" importance="primary" dimension="btn-regular"
                text="{{'generic.ADD' | translate}}"
                [disabled]="isInitiativeArchived || !newCertificateArgumentTmp || !newCertificateArgumentTmp.length || isUpdatingArguments"
                (onButtonClicked)="addNewCertificateArgument()">
              </lib-alloy-button>
            </div>
          </div>
        </tab>
      </tabset>
    </div>

    <!-- Informazione di destra -->
    <div fxLayout="column" class="right-info-column">
      <div class="info-content">
        <p class="bold-text to-uppercase" translate="generic.SYLLABUS"></p>
        <p translate="awWizard.generalInfo.REMEMBER_INFO"></p>
        <!-- Se il tab è DURATA c'è già la descrizione -->
        <!--ng-container *ngIf="activeTabId === 3">
          <p class="margin-top15" translate="awWizard.generalInfo.REMEMBER_INFO_DURATION"></p>
        </ng-container-->
      </div>
    </div>
  </div>

  <!-- Conferma Syllabus -->
  <div fxLayout="row" fxFlexFill fxLayoutAlign="center center" class="margin-top30">
    <lib-alloy-button importance="primary" dimension="btn-regular"
      [disabled]="isInitiativeArchived || isConfirmSyllabusDisabled()"
      text="{{'awWizard.generalInfo.CONFIRM_SYLLABUS' | translate}}" (onButtonClicked)="emitConfirmSyllabus()">
    </lib-alloy-button>

    <!-- Conferma la prima sezione dello step inviando la richiesta di approvazione -->
    <lib-alloy-button *ngIf="showConfirmAndAskApprovationBtn() && !alreadyApproved()" importance="primary"
      dimension="btn-regular"
      text="{{ (isWatingApproval() ? 'awWizard.stages.CONFIRM_WITH_REQUEST_VALIDATION_WAITING' : 'awWizard.stages.CONFIRM_WITH_REQUEST_VALIDATION') | translate }}"
      [disabled]="isInitiativeArchived || isConfirmSyllabusDisabled() || isWatingApproval()"
      (onButtonClicked)="emitOpenConfirmAskInitiativeApprovationModal($event)">
    </lib-alloy-button>
  </div>
</div>

<!--  Modale per aggiungere un peso ad un nuovo tag -->
<lib-alloy-modal class="not-destructive-modal" id="addWeightToTagModal">
  <h1 class="custom-modal-title" translate="awWizard.generalInfo.NEW_TAG"></h1>
  <div class="content-modal" fxLayout="column" fxLayoutAlign="start start">
    <div fxFill fxLayout="column" fxLayoutAlign="start center">
      <!-- Testo centrale della modale-->
      <div fxFill fxLayout="row" fxLayoutAlign="start center">
        <h4 translate="generic.WEIGHT"></h4>
      </div>
      <div fxFill fxLayout="row" fxLayoutAlign="start center">
        <form #weightForm="ngForm">
          <div class="md-radio">
            <input value="1" id="1" type="radio" name="weight" ngModel required>
            <label for="1" translate="generic.weightList.LOW"></label>
          </div>
          <div class="md-radio">
            <input value="2" id="2" type="radio" name="weight" ngModel required>
            <label for="2" translate="generic.weightList.MEDIUM"></label>
          </div>
          <div class="md-radio">
            <input value="3" id="3" type="radio" name="weight" ngModel required>
            <label for="3" translate="generic.weightList.HIGH"></label>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- Conferma -->
    <lib-alloy-button importance="primary" dimension="btn-regular" [text]="'generic.CONFIRM' | translate"
      [disabled]="isInitiativeArchived || !weightForm.valid"
      (onButtonClicked)="confirmOnSkillAdded(weightForm.value.weight)">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>

<!--  Modale per aggiungere un peso ad un nuovo tag -->
<lib-alloy-modal class="informative-modal" id="cantAddRequiredCompetence">
  <h1 class="custom-modal-title" translate="initiatives.CAN_NOT_ADD_REQUIRED_COMPETENCE"></h1>

  <div class="content-modal" fxLayout="column" fxLayoutAlign="start start">
    <div fxFill fxLayout="column" fxLayoutAlign="start start">
      <!-- Testo centrale della modale-->
      <P translate="initiatives.CAN_NOT_ADD_REQUIRED_COMPETENCE_TEXT"></P>
    </div>
  </div>

  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- Conferma -->
    <lib-alloy-button [disabled]="isInitiativeArchived" importance="primary" dimension="btn-regular"
      [text]="'generic.OK' | translate" (onButtonClicked)="closeCantAddRequiredCompetence()">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>