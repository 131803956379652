/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante la parte utente-docente
*/

import * as fromApp from '../../ngrx/app.reducers';
import * as ActivitiesUsersActions from './teachers-users.actions';
import * as moment from 'moment';

export interface FeatureState extends fromApp.AppState {
  teachersUsers: TeachersUsersState
}

// Interfaccia dello Store
export interface TeachersUsersState {
  activities: any[];
  count: number;
  fromRecord: number;
  numRecords: number;
  page: number;
  activityTab: number;
  sortingDir: string;
  sortingCol: string;
  isFetchingActivities: boolean;
  startDateTime: any,
  endDateTime: any
}

// Store iniziale
const initialState: TeachersUsersState = {
  activities: [],
  count: 0,
  fromRecord: 0,
  numRecords: 20,
  sortingDir: 'DESC',
  sortingCol: 'name',
  page: 1,
  activityTab: 0,
  isFetchingActivities: false,
  startDateTime: moment().startOf('month').toDate(),
  endDateTime: moment().endOf('month').toDate()
};

export function TeachersUsersReducer(state = initialState, action: ActivitiesUsersActions.ActivitiesUsersActions) {
  switch (action.type) {
    case ActivitiesUsersActions.SET_ACTIVITIES:
      return {
        ...state,
        isFetchingActivities: false,
        activities: [...action.payload]
      };
    case ActivitiesUsersActions.SET_ACTIVITIES_START_DATE_TIME:
      return {
        ...state,
        startDateTime: action.payload
      };
    case ActivitiesUsersActions.SET_ACTIVITIES_END_DATE_TIME:
      return {
        ...state,
        endDateTime: action.payload
      };
    case ActivitiesUsersActions.SET_PAGINATION_ACTIVITIES:
      return {
        ...state,
        fromRecord: action.payload.fromRecord,
        numRecords: action.payload.numRecords
      };
    case ActivitiesUsersActions.GET_ACTIVITIES:
      return {
        ...state,
        isFetchingActivities: true
      };
    case ActivitiesUsersActions.SET_SORTING_DIR:
      return {
        ...state,
        sortingDir: action.payload
      };
    case ActivitiesUsersActions.SET_SORTING_COL:
      return {
        ...state,
        sortingCol: action.payload
      };
    case ActivitiesUsersActions.SET_PAGE_ACTIVITIES:
      return {
        ...state,
        page: action.payload
      };
    case ActivitiesUsersActions.SET_TAB_ACTIVITIES:
      return {
        ...state,
        activityTab: action.payload
      };
    case ActivitiesUsersActions.SET_ACTIVITIES_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    default:
      return state;
  }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getActivities = (state: TeachersUsersState) => (state && state.activities) || [];
export const getActivitiesCount = (state: TeachersUsersState) => (state && state.count) || 0;
export const getActivitiesStartDateTime = (state: TeachersUsersState) => state && state.startDateTime;
export const getActivitiesEndDateTime = (state: TeachersUsersState) => state && state.endDateTime;
export const getActivitiesFromRecord = (state: TeachersUsersState) => (state && state.fromRecord) || 0;
export const getActivitiesNumRecords = (state: TeachersUsersState) => (state && state.numRecords) || 20;
export const getActivitiesPage = (state: TeachersUsersState) => (state && state.page) || 0;
export const getActivitiesTab = (state: TeachersUsersState) => (state && state.activityTab) || 0;
export const getSortingDir = (state: TeachersUsersState) => (state && state.sortingDir) || 'DESC';
export const getSortingCol = (state: TeachersUsersState) => (state && state.sortingCol) || 'name'
export const isFetchingActivities = (state: TeachersUsersState) => (state && state.isFetchingActivities) || false;