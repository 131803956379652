/*
*  Reducer che gestiscono e modificano lo State applicativo riguardanti i gruppi
*/

import * as GroupsActions from './groups.actions';
import * as fromApp from '../../ngrx/app.reducers';
import { UserGroup } from '../../../cm2-commonclasses';

// I gruppi sono una feature dell'applicazione, infatti è un modulo caricato in maniera lazy (e quindi anche lo Store).
// Creo un'interfaccia, FeatureState, in cui mappo i gruppi
// Estendo fromApp.AppState così per ereditare tutte le proprietà dello Store principale
export interface FeatureState extends fromApp.AppState {
  group: GroupState
}

// Interfaccia dello Store
export interface GroupState {
  groups: UserGroup[];
  count: number;
  fromRecord: number;
  numRecords: number;
  page: number;
  isFetchingGroups: boolean;
  groupsTextFilter: string
}

// Store iniziale
const initialGroupState: GroupState = {
  groups: [],
  count: 0, // contatore totale dei template
  fromRecord: 0, // numero da cui recuperare gli elementi
  numRecords: 20, // elementi da recuperare
  page: 1, // pagina della tabella visualizzata
  isFetchingGroups: false, // verifica se sto eseguendo una chiamata per recuperare i template
  groupsTextFilter: null, // searchedText input
};

export function GroupsReducer(state = initialGroupState, action: GroupsActions.GroupsActions) {
  switch (action.type) {
    case GroupsActions.SET_GROUPS:
      return {
        ...state,
        isFetchingGroups: false,
        groups: [...action.payload]
      };
    case GroupsActions.SET_PAGINATION_GROUPS:
      return {
        ...state,
        fromRecord: action.payload.fromRecord,
        numRecords: action.payload.numRecords
      };
    case GroupsActions.GET_GROUPS:
      return {
        ...state,
        isFetchingGroups: true
      };
    case GroupsActions.SET_PAGE_GROUPS:
      return {
        ...state,
        page: action.payload
      };
    case GroupsActions.SET_GROUPS_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    case GroupsActions.SET_GROUPS_TEXT_FILTER:
      return {
        ...state,
        groupsTextFilter: action.payload
      };

    default:
      return state;
  }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getGroups = (state: GroupState) => (state && state.groups) || [];
export const getGroupsCount = (state: GroupState) => state.count || 0;
export const getGroupsFromRecord = (state: GroupState) => (state && state.fromRecord) || 0;
export const getGroupsNumRecords = (state: GroupState) => (state && state.numRecords) || 20;
export const getGroupsPage = (state: GroupState) => (state && state.page) || 0;
export const isFetchingGroups = (state: GroupState) => state.isFetchingGroups || false;
export const getGroupsTextFilter = (state: GroupState) => (state && state.groupsTextFilter) || null;