/*
* Componente che crea un placeholder per la lista delle iniziative pendenti
*/

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';
@Component({
  selector: 'course-placeholder',
  templateUrl: './course-placeholder.component.html',
  styleUrls: ['./course-placeholder.component.scss']
})

export class CoursePlaceholderComponent {
  @Input() containerClass: string = '';

  _hasGreyBackground: boolean = false;
  @Input() set hasGreyBackground(value: string | boolean) {
    this._hasGreyBackground = coerceBooleanProperty(value);
  }

  _isLoading: boolean = true;
  @Input() set isLoading(value: string | boolean) {
    this._isLoading = coerceBooleanProperty(value);
  }

  constructor() {
  }
}
