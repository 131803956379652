/*
* Componente per la paginazione
*/
import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormComponent } from '../../shared/components/base-form.component';
import { DateUtil } from '../../../cm2-commonclasses';

@Component({
  selector: 'timepicker',
  templateUrl: './timepicker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // per le validazioni con Reactive forms da aggiungere in ogni custom components di input
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimepickerComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => TimepickerComponent), multi: true }
  ]
})
export class TimepickerComponent extends BaseFormComponent implements OnInit {

  @Input() id: string;
  @Input() isWebinarCreation: boolean;
  @Input() label: string;
  @Input() reviewMode: boolean;
  @Input() editSwitchFlag: boolean;
  @Input() showEditableToggle: boolean;
  @Input() required: boolean;
  @Input() disabled: boolean;

  // RANGE or SINGLE
  @Input() mode: string;

  @Input() startTime: any;
  @Input() endTime: any;

  @Output() toggleEditable = new EventEmitter();
  @Output() editSwitchFlagChange = new EventEmitter();
  @Input() utcMode: boolean;
  showEditSwitch: boolean;

  @Output() startTimeChange: any = new EventEmitter<any>();
  @Output() endTimeChange: any = new EventEmitter<any>();

  constructor(
    private __translate: TranslateService,
    private __renderer: Renderer2) {

    super(__translate, __renderer);
  }

  ngOnInit(): void {
    // se non specificato lavoro in UTC tenendo a modello il valore in UTC e a livello di view il formato del Locale
    this.utcMode = this.utcMode === undefined ? true : this.readBooleanInput(this.utcMode);
    this.reviewMode = this.readBooleanInput(this.reviewMode);
    this.editSwitchFlag = this.readBooleanInput(this.editSwitchFlag);
    this.showEditableToggle = this.readBooleanInput(this.showEditableToggle);
    this.showEditSwitch = !this.reviewMode && this.showEditableToggle;

    this.markAsDirty = this.reviewMode ? true : this.markAsDirty;

    this.value = this.value || '';
    this.placeholder = this.placeholder || '';

    if (this.markAsDirty) {
      this._markAsDirty();
    }
  }

  emitToggleEditable(event): void {
    this.editSwitchFlag = !this.editSwitchFlag;
    this.editSwitchFlagChange.emit(this.editSwitchFlag);
    this.toggleEditable.emit(this.editSwitchFlag);
  }

  updateValue(time) {
    this.value = this.utcMode && time ? DateUtil.convertModelToUTC(time.value) : time.value;
    this.valueChange.emit(this.value);
  }

  updateStartTime(time) {
    this.startTime = this.utcMode && time ? DateUtil.convertModelToUTC(time.value) : time.value;
    this.startTimeChange.emit(this.startTime);
  }

  updateEndTime(time) {
    this.endTime = this.utcMode && time ? DateUtil.convertModelToUTC(time.value) : time.value;
    this.endTimeChange.emit(this.endTime);
  }

  readInputTextValue(time) {
    if (time && time.value
      && /([0-1][0-9]|2[0-3]):[0-5][0-9]/.test(time.value)) {
      let _newDate = new Date();
      _newDate = new Date(_newDate.setHours(time.value.split(':')[0], time.value.split(':')[1]));
      return this.utcMode ? DateUtil.convertModelToUTC(_newDate) : _newDate;
    }
    return null;
  }

  updateStartTimeFromInputText(time) {
    const parsedValue = this.readInputTextValue(time);
    if (parsedValue) {
      this.startTime = parsedValue;
      this.startTimeChange.emit(this.startTime);
    }
  }

  updateValueFromInputText(time) {
    const parsedValue = this.readInputTextValue(time);
    if (parsedValue) {
      this.value = parsedValue;
      this.valueChange.emit(this.value);
    }
  }

  updateEndTimeFromInputText(time) {
    const parsedValue = this.readInputTextValue(time);
    if (parsedValue) {
      this.endTime = parsedValue;
      this.endTimeChange.emit(this.endTime);
    }
  }
}
