/*
* Componente relativo alla modale di conferma
*/

import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent implements OnInit {
  constructor() { }
  @Input() modalId: string;
  @Input() modalTitle: string;
  @Input() modalBody: string;
  @Input() modalBody_1?: string; //aggiunto testo poichè se aggiunta a stringa <\br>,<\n>... non vengono letti come comandi ma come stringhe
  @Input() cancelBtnLabel: string;
  @Input() confirmBtnLabel: string;
  @Input() confirmSecondBtnLabel: string;
  @Input() containerClass?: string;
  @Input() innerHtml?: boolean;

  @Output() confirmation = new EventEmitter<any>();
  @Output() confirmAction = new EventEmitter<any>();
  @Output() secondConfirmation = new EventEmitter<any>();
  @Output() confirmSecondAction = new EventEmitter<any>();
  @Output() dismissAction = new EventEmitter<any>();

  ngOnInit() {
    if (!this.containerClass) {
      this.containerClass = 'informative-modal';
    }
  }

  emitConfirmation() {
    if (this.confirmation instanceof EventEmitter) {
      this.confirmation.emit();
    }
    if (this.confirmAction instanceof EventEmitter) {
      this.confirmAction.emit();
    }
  }

  emitSecondConfirmation() {
    if (this.secondConfirmation instanceof EventEmitter) {
      this.secondConfirmation.emit();
    }
    if (this.confirmSecondAction instanceof EventEmitter) {
      this.confirmSecondAction.emit();
    }
  }

  emitDismissAction() {
    if (this.dismissAction instanceof EventEmitter) {
      this.dismissAction.emit();
    }
  }
}
