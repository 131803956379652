<pagination-template #p="paginationApi" [id]="id" (pageChange)="onPageChanged($event)"
  class="table-pagination-container">
  <div class="pagination" fxLayoutGap="6px">
    <a [class.disabled]="p.isFirstPage() || forceDisabled" class="arrow text-decoration-none" (click)="p.setCurrent(1)">
      <ul>
        <li>
          <svg-icon fxLayoutAlign="center center" src="assets/icons/arrow_back_pagination.svg"></svg-icon>
        </li>
      </ul>
    </a>
    <a [class.disabled]="p.isFirstPage() || forceDisabled" class="arrow text-decoration-none" (click)="p.previous()">
      <ul>
        <li>
          <svg-icon fxLayoutAlign="center center" src="assets/icons/keyboard_arrow_left_for_pagination.svg"></svg-icon>
        </li>
      </ul>
    </a>
    <a class="text-decoration-none" *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value"
      [class.disabled]="forceDisabled" >
      <ul attr.aria-label="Ti trovi a pagina {{page.label}}" >
        <li (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">{{ page.label }}</li>
        <li *ngIf="p.getCurrent() === page.value" >{{ page.label }}</li>
      </ul>
    </a>
    <a [class.disabled]="p.isLastPage() || forceDisabled" class="arrow text-decoration-none" (click)="p.next()">
      <ul>
        <li>
          <svg-icon fxLayoutAlign="center center" src="assets/icons/keyboard_arrow_right_for_pagination.svg"></svg-icon>
        </li>
      </ul>
    </a>
    <a [class.disabled]="p.isLastPage() || forceDisabled" class="arrow text-decoration-none"
      (click)="p.setCurrent(p.getLastPage())">
      <ul>
        <li>
          <svg-icon fxLayoutAlign="center center" src="assets/icons/arrow_forward_pagination.svg"></svg-icon>
        </li>
      </ul>
    </a>
  </div>
</pagination-template>