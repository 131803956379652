<div class="generali-input">
  <!-- Switch editabilità -->
  <div class="title-input" *ngIf="label">
    <div fxLayout="row">
      <h5 class="full-width" translate="{{ label }}"></h5>

      <input *ngIf="showEditSwitch" type="checkbox" [ngClass]="{'switch-disabled': isDisabled}"
        [(ngModel)]="editSwitchFlag" [ngModelOptions]="{standalone: true}" class="material-switch">
    </div>
  </div>

  <ckeditor #ckeditor debounce="500" [(ngModel)]="value" (ngModelChange)="updateModel(this, $event);onUpdateEditModel()"
    [readonly]="isDisabled" [config]="editorConfig">
  </ckeditor>
  
  <div fxLayout="row" *ngIf="fControl && fControl.invalid" class="alert alert-danger">
    <div [hidden]="!fControl.errors.required">
      {{ errors.required | translate }}
    </div>
    <div [hidden]="!fControl.errors.minlength">
      {{ errors.minlength | translate }}
    </div>
    <div [hidden]="!fControl.errors.maxlength">
      {{ errors.maxlength | translate }}
    </div>
    <div [hidden]="!fControl.errors.forbiddenName">
      {{ errors.forbidden | translate }}
    </div>
  </div>

</div>