import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AutocomplaceOptions } from '../../directive/ngx-autocom-place.directive';

@Component({
  selector: 'maps-input',
  templateUrl: 'maps-input.component.html',
  styleUrls: ['./maps-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapsInputComponent implements OnInit, OnDestroy {

  _id?: string;
  @Input() id?: string;

  @Input() attrName?: string;

  @Input() attrAriaLabel?: string;

  _tabIndex: string = "0";
  @Input() set tabIndexValue(value: string) {
    if (value) {
      this._tabIndex = value;
      if (this._id) {
        const element = document.getElementById(this._id);
        if (element) {
          element.setAttribute("tabindex", value);
        }
      }
    }
  };

  _inputValue: FormControl = new FormControl();
  @Input() set inputValue(value: FormControl | undefined) {
    if (value) {
      this._inputValue = value;
      this._inputValue.valueChanges
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe(value => {
          this.onModelChanged.emit(value);
        });
    }
  };

  @Input() set bindValue(value: string | undefined) {
    this._inputValue.setValue(value);
    this._inputValue.valueChanges
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(value => {
        this.onModelChanged.emit(value);
      });
  }

  /**
   * Works only with bindValue - otherwise the disabled need to be put inside the FormControl
   */
  @Input() set disabled(value: boolean) {
    if (value) {
      this._inputValue.disable();
    } else {
      this._inputValue.enable();
    }
  }

  get bindValue(): string | undefined {
    return this._inputValue.value;
  }

  @Input() title: string = '';

  @Input() placeholder: string = '';

  _type: string = 'text';
  @Input() set type(value: string) {
    this._type = value || 'text';
  }

  @Input() mapsOptions?: AutocomplaceOptions = {
    types: ['address'] // 'establishment' / 'address' / 'geocode'
  };

  @Input() debounceTime: number = 650;

  /**
   * To avoid build error is any -> google.maps.places.PlaceResult
   */
  @Output('selectedPlace') onSelectedPlace: EventEmitter<any> = new EventEmitter();
  @Output('onChange') onModelChanged: EventEmitter<any> = new EventEmitter();

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor() { }

  ngOnInit() {
    if (!this.id) {
      this._id = "maps-input-" + Math.floor((Math.random() * 10000) + 1);
    } else {
      this._id = this.id;
    }
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  /**
   *
   * @param {google.maps.places.PlaceResult} place
   */
  placeChangedCallback(place: any) {
    if (this.onSelectedPlace && place) {
      this.onSelectedPlace.emit(place);
    }
  }

}
