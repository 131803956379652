/*
* Componente che crea una singola Tab
*/

import { Component, Input } from '@angular/core';

@Component({
  selector: 'tab',
  template: '<ng-content *ngIf="isVisible"></ng-content>',
})
export class TabComponent {
  @Input() tabId: number;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() isVisible = false;
  // [COMPLETED, PENDING, WITH_ERRORS, WITH_WARNINGS, OPTIONAL]
  @Input() tabProgress: string;
}