import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BitmovinPlayerService } from './bitmovin-player.service';
import { parseBoolean, VideoSubtitle } from '../../../cm2-commonclasses';
import { UrlService } from "../../shared/services/url.service";
declare let bitmovin: any;

@Component({
  selector: 'bitmovin-player',
  templateUrl: './bitmovin-player.component.html',
  styleUrls: ['./bitmovin-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class BitmovinPlayerComponent implements OnInit {
  @Input() itemId: string;
  @Input() playerVisible: boolean;
  @Input() seekTo: number;
  @Input() mimeType: string;
  @Input() objectUrl: string;
  @Input() fullScreen: boolean;
  @Input() adminMode: boolean;
  @Input() subtitles: VideoSubtitle[];
  @Input() preventUserControl: boolean;
  lastInProgressUpdate: number;
  watchInterval;
  bitmovinMediaPlayer;
  @ViewChild("contentPlayer") contentPlayerElement: ElementRef;
  @Output() itemInProgress = new EventEmitter<any>();
  @Output() itemConsumed = new EventEmitter<any>();
  @Output() playerStatusChange = new EventEmitter<any>();

  constructor(protected _sanitizer: DomSanitizer,
    private playerService: BitmovinPlayerService,
    private urlService: UrlService,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.playerVisible = parseBoolean(this.playerVisible);
    this.adminMode = parseBoolean(this.adminMode);
    if (!this.objectUrl) {
      this.playerVisible = false;
    }
    if (this.playerVisible) {
      this.initVideoPlayer();
    }
  }

  createWatchInterval() {
    this.watchInterval = setInterval(() => {
      let now = new Date().getTime();
      // Se sono passati più di 5 secondi dall'ultima volta che sono passato per questo evento, invio lo stato di avanzamento al sistema
      let totalTime = this.bitmovinMediaPlayer.getDuration();
      let currentTime = this.bitmovinMediaPlayer.getCurrentTime();
      // Quando arriva alla fine l'oggetto torna in paused con currentTime prossimo o superiore a totalTime, quindi come area tengo l'1% max 1 secondo
      let safeLimit = (totalTime * 0.01) < 1 ? totalTime * 0.99 : totalTime - 1;
      // Evito di salvarmi le informazione di avanzamento quando sono troppo prossimo alla fine per evitare conflitti con il completato
      if ((!this.lastInProgressUpdate || this.lastInProgressUpdate + 5000 < now) && currentTime < safeLimit) {
        let viewedSeconds = 0;
        if (this.lastInProgressUpdate) {
          viewedSeconds = (now - this.lastInProgressUpdate) / 1000;
        }
        this.lastInProgressUpdate = now;
        // Non mi interessa l'esito di questa operazione (se va male, amen)
        this.playerService.updatePlayerStatus(this.itemId, "viewed", totalTime, currentTime, viewedSeconds).subscribe();
      }
    }, 500);
  }

  disposeWatchInterval = () => {
    if (this.watchInterval) {
      clearInterval(this.watchInterval);
      this.watchInterval = null;
    }
  }

  initVideoPlayer() {
    let _this = this;
    var conf = {
      /* https://cdn.bitmovin.com/player/web/8/docs/interfaces/Core.PlayerConfig.html */
      key: "a64efbde-c020-4b5b-855d-c9a0e81ec524", // Biitmovin Licence Key (abbinata a determinati siti specifici, per cambiarli accedere a https://dashboard.bitmovin.com/player/licenses)
      playback: {
        autoplay: false,
        muted: false,
        seeking: !_this.preventUserControl
      },
      style: {
        aspectratio: "16/9",
      },
      analytics: false
    };

    // E chiamo il metodo che registra gli hooks e inizializza il contenuto multimediale
    this.bitmovinMediaPlayer = new bitmovin.player.Player(document.getElementById("contentPlayer"), conf);

    /*
    Altri possibili eventi da gestire: https://cdn.bitmovin.com/player/web/8/docs/enums/Core.PlayerEvent.html
    */

    if (_this.itemId) {
      if(!_this.adminMode) {
      let ignoreFirstSeek = false;
      let ignoreNextSeek = false;
      let lastKnownCurrentTime: number;


      // Aggiungo gli Event Listener per tracciare i vari eventi
      this.bitmovinMediaPlayer.on("sourceloaded", () => {
        // Se devo quindi riprendo da dov'ero, ma se sono vicino alla fine del filmato, non riporto il dipendente lì
        let totalTime = _this.bitmovinMediaPlayer.getDuration();
        if (_this.seekTo && (_this.seekTo < totalTime - 2 /* sec */)) {
          ignoreFirstSeek = true;
          lastKnownCurrentTime = _this.seekTo;
          _this.bitmovinMediaPlayer.seek(_this.seekTo);
          _this.seekTo = null;
        }
      });

      this.bitmovinMediaPlayer.on("paused", () => {
        _this.disposeWatchInterval();
        let now = new Date().getTime();
        let totalTime = _this.bitmovinMediaPlayer.getDuration();
        let currentTime = _this.bitmovinMediaPlayer.getCurrentTime();
        let viewedSeconds = 0;
        if (_this.lastInProgressUpdate) {
          viewedSeconds = (now - _this.lastInProgressUpdate) / 1000;
        }
        // Quando arriva alla fine l'oggetto torna in paused con currentTime prossimo o superiore a totalTime, quindi come area tengo l'1% max 1 secondo
        let safeLimit = (totalTime * 0.01) < 1 ? totalTime * 0.99 : totalTime - 1;
        // Quando arriva alla fine l'oggetto torna in paused con currentTime prossimo o superiore a totalTime, quindi questa combinazione la ignoro
        if (!(currentTime >= safeLimit)) { // Il player di Bitmovin gestisce molto male la fine del filmato, quindi tengo un lasco grande
          // Nel caso in cui questa pausa sia dovuta al termine del video non azzero il tempo, sennò lo perderei dall'evento end
          _this.lastInProgressUpdate = 0; // Forzo a 0 così l'eventuale seek da pausa non genera tempo consumato
          // Non mi interessa l'esito di questa operazione (se va male, amen)
          _this.playerService.updatePlayerStatus(_this.itemId, "paused", totalTime, currentTime, viewedSeconds).subscribe();

          _this.itemInProgress.emit({ currentTime: currentTime, totalTime: totalTime });
        }
      });

      this.bitmovinMediaPlayer.on("playing", () => {
        if (!_this.watchInterval) {
          let now = new Date().getTime();
          let totalTime = _this.bitmovinMediaPlayer.getDuration();
          let currentTime = _this.bitmovinMediaPlayer.getCurrentTime();
          let viewedSeconds = 0;
          if (_this.lastInProgressUpdate) {
            viewedSeconds = (now - _this.lastInProgressUpdate) / 1000;
          }
          _this.lastInProgressUpdate = now;
          _this.playerService.updatePlayerStatus(_this.itemId, "playing", totalTime, currentTime, viewedSeconds).subscribe();
          _this.itemInProgress.emit({ currentTime: currentTime, totalTime: totalTime });
          _this.createWatchInterval();
        }
      });

      this.bitmovinMediaPlayer.on("timechanged", () => {
        // Aggiorna la posizione corrente memorizzata alla posizione corrente del video
        // Questo assicura che il valore di currentPosition sia sempre aggiornato
        // (solo se non sto saltando programmaticamente ad un nuovo punto del video)
        if (!ignoreNextSeek && !ignoreFirstSeek) {
          lastKnownCurrentTime = _this.bitmovinMediaPlayer.getCurrentTime();
        }
      });

      this.bitmovinMediaPlayer.on("playbackfinished", () => {
        // Per evitare che usi lo slidere per arrivare alla fine nel caso non possa muoversi
        // non gesisco l'evento end così da permettere al player di tornare alla posizione precedente
        if (!ignoreNextSeek) {
          _this.disposeWatchInterval();
          let now = new Date().getTime();
          // Segnalo il termine della fruizione
          _this.itemConsumed.emit(true);
          let totalTime = _this.bitmovinMediaPlayer.getDuration();
          let currentTime = _this.bitmovinMediaPlayer.getCurrentTime();
          let viewedSeconds = 0;
          if (_this.lastInProgressUpdate) {
            viewedSeconds = (now - _this.lastInProgressUpdate) / 1000;
          }
          _this.lastInProgressUpdate = 0; // Ho finito di vederlo, fino al prossimo play non devo tracciare niente
          // Non mi interessa l'esito di questa operazione (se va male, amen)
          _this.playerService.updatePlayerStatus(_this.itemId, "end", totalTime, currentTime, viewedSeconds).subscribe();
        }
      });

      this.bitmovinMediaPlayer.on("seek", () => {
        if (_this.preventUserControl) {
          // Se l'utente non può muoversi nel video indico anche che dovrò ignorare il prossimo
          // evento di seek per evitare un loop infinto e per non salvare il tempo della posizione attuale
          ignoreNextSeek = true;
        }
      });

      this.bitmovinMediaPlayer.on("seeked", () => {
        ignoreNextSeek = false;
        if (ignoreFirstSeek) {
          ignoreFirstSeek = false;
        } else {
          let currTime = _this.bitmovinMediaPlayer.getCurrentTime();
          if (_this.preventUserControl && lastKnownCurrentTime != currTime) {
            // Se il delta è davvero piccolo, evito di fare il salto perché a volte per questo va in loop infinito
            if (Math.abs(currTime - lastKnownCurrentTime) >= 0.1) {
              // Se l'utente non può muoversi nel video, ritorno all'ultima posizione nota
              _this.bitmovinMediaPlayer.seek(lastKnownCurrentTime);
            }
          } else {
            let now = new Date().getTime();
            let totalTime = _this.bitmovinMediaPlayer.getDuration();
            let currentTime = _this.bitmovinMediaPlayer.getCurrentTime();
            let viewedSeconds = 0;
            if (_this.lastInProgressUpdate) {
              viewedSeconds = (now - _this.lastInProgressUpdate) / 1000;
            }
            // Dato che non so il media è in play o meno e che se l'utente' facesse un seek, poi aspettasse ore e poi un altro seek il tutto in pausa
            // verrebbero tracciate le ore, quindi forzo a zero. So che così l'dipendente perderà il tempo visto tra un seek e l'altro se lo fa entro 5 secondi
            // ma tutto sommato è un tempo sacrificabile e ci può stare: premiamo chi vede di fila, non ci salta di continuo.
            _this.lastInProgressUpdate = 0;
            // Non mi interessa l'esito di questa operazione (se va male, amen)
            _this.playerService.updatePlayerStatus(_this.itemId, "seeked", totalTime, currentTime, viewedSeconds).subscribe();
          }
        }
      });
    }

      this.bitmovinMediaPlayer.on("destroy", () => {
        _this.disposeWatchInterval();
      });

      // Ora aggiungo l'URL dell'oggetto multimediale
      var source = {
        title: "",
        dash: _this.objectUrl,
      };
      this.bitmovinMediaPlayer.load(source)
        .then(() => {
          // Se devo ci aggiungo anche i sottotitoli
          let subtitleUrls = null;
          if (_this.subtitles && _this.subtitles.length) {
            let count = 0;
            subtitleUrls = _this.subtitles.map(s => {
              count++;
              return {
                enabled: false,
                forced: false,
                id: s.lang + "_" + count,
                kind: "subtitle",
                label: s.title,
                lang: s.lang,
                url: _this.urlService.getApplicationUrl().baseUrl + "rest-api/corporateacademy-mediator/proxy-url/" + s.lang + ".vtt?requestUrl=" + encodeURIComponent(s.url)
              };
            });
          }
          // E infine imposto i dati nel player così da caricarli
          if (subtitleUrls && subtitleUrls.length) {
            subtitleUrls.forEach(s => {
              this.bitmovinMediaPlayer.subtitles.add(s);
            })
          }
        });
    }

  }

  ngOnDestroy() {
    this.disposeWatchInterval();
    // Elimino l'itemId dallo scope, così da impedire l'invio di notifiche multiple in casi di listener rimasti appesi.
    this.itemId = null;
  }
}