/*
* Componente che crea un set di Tab con le ultime iniziative
*/

import { Component, Input, ContentChildren, QueryList, AfterContentInit, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { TabComponent } from '../tab/tab.component';
import { Observable, Subscription } from 'rxjs';
import * as CoreActions from '../ngrx/core.actions';
import * as fromCore from '../ngrx/core.reducers';
import * as fromApp from '../../ngrx/app.reducers';

@Component({
  selector: 'tabset',
  styleUrls: ['./tabset.component.scss'],
  templateUrl: './tabset.component.html'
})

export class TabSetComponent implements OnInit, AfterContentInit, OnDestroy {
  constructor(private store: Store<fromCore.CoreState>) { }

  // Nome del set di tab
  @Input() tabsetName: string;
  // Lista delle tab
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  // Observable che wrappa il numero della tab attiva
  activeTabId$: Observable<number>;

  @Output() onActivateTab: EventEmitter<number> = new EventEmitter();

  // Esegue il dispatch dell'azione che cambia la tab correntemente attiva
  activateTab(tabId: number) {
    this.store.dispatch(new CoreActions.ActivateTab({
      tabsetName: this.getTabsetName(),
      activeTabId: tabId
    }));
    this.onActivateTab.emit(tabId);
  }

  isPending(tab: TabComponent) {
    return tab.tabProgress === 'PENDING';
  }

  hasErrors(tab: TabComponent) {
    return tab.tabProgress === 'WITH_ERRORS';
  }

  isCompleted(tab: TabComponent) {
    return tab.tabProgress === 'COMPLETED';
  }

  isOptional(tab: TabComponent) {
    return tab.tabProgress === 'OPTIONAL';
  }

  ngOnInit() {
    // Recupero la tab attiva del set corrente
    this.activeTabId$ = this.store.select(fromApp.getActiveTab(this.getTabsetName()));
  }

  // Verifica se una tab è attiva
  isActive(tab: TabComponent, activeTabId: number = 0) {
    return tab.tabId === activeTabId;
  }

  ngAfterContentInit() {
    // Quando il contenuto della pagina è pronto, e quindi anche le tab, effettuo un subscribe per sapere quando una nuova tab è stata selezionata
    this.tabs.forEach((tab: TabComponent, idx: number) => tab.tabId = idx);
    this.activatedTabSubscription = this.activeTabId$.subscribe(
      (activeTabId: number) => {
        // Aggiorno la tab nella View
        this.updateActiveTab(activeTabId || 0);
      }
    )
  }

  ngOnDestroy() {
    if (this.activatedTabSubscription) {
      this.activatedTabSubscription.unsubscribe();
    }
  }

  // Torna il nome del set di tab ricevuto in Input dal componente padre
  private getTabsetName() {
    return this.tabsetName;
  }

  // Aggiorna graficamente nella UI la tab selezionata
  private updateActiveTab = (activeTabId: number): void => {
    this.tabs.forEach((tab: TabComponent) =>
      tab.isVisible = (tab.tabId === activeTabId));
  }

  private activatedTabSubscription: Subscription;
}
