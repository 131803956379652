/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante il profilo dell'utente loggato
*/

import * as ProfileActions from './profile.actions';
export class UserCredits {
  ivass: number;
  efaa: number;
  efab: number;
  efp: number;
  mifid: number;
};

export class BienniumData {
  currentBiennium: string;
  currentBienniumRequiredCredits: string;
}

export interface ProfileState {
  loggedUser: JwtPayload;
  editedUser: User;
  langs: Lang[]
  userCredits: UserCredits,
  bienniumData: BienniumData
}

const initialState: ProfileState = {
  loggedUser: null,
  editedUser: null,
  langs: null,
  userCredits: {
    ivass: 0,
    efaa: 0,
    efab: 0,
    efp: 0,
    mifid: 0
  },
  bienniumData: {
    currentBiennium: '',
    currentBienniumRequiredCredits: '-'
  }
};

// Importo il componente per decodificare il token JWT
//const jwtDecode = require('jwt-decode');
import * as jwtDecode from 'jwt-decode';
import { JwtPayload, Lang, User } from 'atfcore-commonclasses';

export function profileReducer(state = initialState, action: ProfileActions.ProfileActions) {
  switch (action.type) {
    case ProfileActions.UPDATE_USER:
      const oldUserInfo = state.loggedUser;
      const updatedUser = {
        ...oldUserInfo,
        ...action.payload.loggedUser
      };
      const user = updatedUser;
      return {
        ...state,
        loggedUser: user,
        editedUser: null
      };
    case (ProfileActions.CANCEL_LOGGED_USER):
      // Cancello le informazioni sull'utente loggato
      return {
        ...state,
        loggedUser: null
      };
    case (ProfileActions.SAVE_AVAILABLE_LANGS):
      // Salva le lingue disponibili a sistema recuperate tramite servizi
      return {
        ...state,
        langs: action.payload
      };
    case (ProfileActions.SET_USER_CREDITS):
      return {
        ...state,
        userCredits: action.payload
      };
    case (ProfileActions.SET_BIENNIUM_DATA):
      return {
        ...state,
        bienniumData: action.payload
      };
    case (ProfileActions.DECODE_TOKEN):
      // Decodifico il token ricevuto dopo il login e lo salvo, questo mi identificherà l'oggetto rappresentante l'utente loggato
      const decodedJwt = {
        ...state.loggedUser,
        ...jwtDecode(action.payload)
      };
      state.loggedUser = decodedJwt;
      return {
        ...state,
        loggedUser: state.loggedUser
      };
    case ProfileActions.START_EDIT:
      // Inizia l'edit del profilo dell'utente loggato
      const editedUser = { ...state.editedUser };
      return {
        ...state,
        editedUser: editedUser
      };
    case ProfileActions.STOP_EDIT:
      // Ferma l'edit del profilo dell'utente loggato
      return {
        ...state,
        editedUser: null
      };
    default:
      return state;
  }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getLoggedUser = (state: ProfileState) => state.loggedUser;
export const getUserCredits = (state: ProfileState) => state.userCredits;
export const getBienniumData = (state: ProfileState) => state.bienniumData;
export const getAvailableLangs = (state: ProfileState) => state.langs;