<div class="img-wrapper">
  <img *ngIf="certificateObject.imageSrc && certificateObject.imageSrc.indexOf('png') >= 0" class="img-certificate"
    [src]="certificateObject.imageSrc">
  <svg-icon *ngIf="certificateObject.imageSrc && certificateObject.imageSrc.indexOf('png') < 0"
    class="img-certificate svgIcon" [src]="certificateObject.imageSrc"></svg-icon>
</div>
<div class="info-certificate">
  <h3 tabindex="0" class="title">{{ certificateObject.title }}</h3>
  <p tabindex="0" *ngIf="certificateObject.entity" class="corporation">{{ certificateObject.entity }}</p>
  <div class="duration">
    <svg-icon src="assets/icons/passport-clock.svg" *ngIf="!_adminMode"></svg-icon>
    <p tabindex="0">{{ _adminMode ? (certificateObject.level || '-') : certificateObject.duration }}</p>
  </div>
  <div class="time-wrapper">
    <div class="date">
      <div fxLayoutAlign="center center">
        <svg-icon fxLayoutAlign="center center" src="assets/icons/icon-calendar-certificate.svg"></svg-icon>
      </div>
      <p tabindex="0">{{ certificateObject.date | date: 'dd.MM.yyyy' }}</p>
      <p tabindex="0">{{ certificateObject.dateEnd | date: 'dd.MM.yyyy' }}</p>
    </div>
  </div>
  <div class="tags-title" *ngIf="certificateObject.level && !_adminMode">
    <p tabindex="0">{{ certificateObject.level }}</p>
  </div>
  <div class="tags-title" *ngIf="certificateObject.competenceSource">
    <p tabindex="0">{{ certificateObject.competenceSource }}</p>
  </div>
  <div class="crud-btn-wrapper">
    <button fxLayoutAlign="center center" class="crud-cert-btn margin-right10" *ngIf="certificateObject.tipology == 'C'"
      (click)="emitOnDownloadClicked(certificateObject.engagementId)">
      <div fxLayoutAlign="center center">
        <svg-icon fxLayoutAlign="center center" src="assets/icons/icon-download.svg"></svg-icon>
      </div>
      <p tabindex="0" translate="teamPassport.DOWNLOAD"></p>
    </button>
    <button fxLayoutAlign="center center" class="crud-cert-btn margin-right10" *ngIf="certificateObject.deletable"
      (click)="emitOnDeleteClicked(certificateObject)">
      <div fxLayoutAlign="center center">
        <svg-icon fxLayoutAlign="center center" src="assets/icons/icon-delete-certificate.svg"></svg-icon>
      </div>
      <p tabindex="0" translate="generic.DELETE"></p>
    </button>
    <button fxLayoutAlign="center center" class="crud-cert-btn" *ngIf="certificateObject.editable"
      (click)="emitOnEditClicked(certificateObject)">
      <div fxLayoutAlign="center center">
        <svg-icon fxLayoutAlign="center center" src="assets/icons/icon-edit-certificate.svg"></svg-icon>
      </div>
      <p tabindex="0" translate="generic.EDIT"></p>
    </button>
  </div>
</div>
