/*
*    Modulo principale dell'applicazione, utilizzato per le cose più importanti e condivise.
*    Esso infatti esegue il bundle degli import e dei providers. Questi ultimi non si potrebbero inserire nello SharedModule o nei Feature Module in quanto
*    mentre i servizi forniti dal Core Module utilizzano tutti la stessa istanza, i Feature Module, e in particolare quelli in lazy load, utilizzano il Child
*    Injector e puntano ad nuova istanza dei servizi forniti.
*/

import { NgModule, Injectable } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { SharedModule } from '../shared/shared.module';
import { UrlService } from '../shared/services/url.service';
import { RedirectService } from '../shared/services/redirect.service';
import { AuthService } from '../auth/services/auth.service';
import { NewsService } from '../news/services/news.service';
import { AuthGuard } from '../auth/services/auth-guard.service';
import { CompatibilityGuard } from '../shared/services/compatibility-guard.service';
import { LoginGuard } from '../shared/services/login-guard.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CompatibilityErrorComponent } from './compatibility-error/compatibility-error.component';
import { LangsService } from './services/langs.service';
import { TagService } from './services/tag.service';
import { LastInitiativesComponent } from './tables/last-initiatives/last-initiatives.component';
import { ItemsService } from './services/items.service';
import { OwlDateTimeIntl, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlMomentDateTimeModule } from '@busacca/ng-pick-datetime';
import { CourseEditionService } from '../initiatives/services/course-edition.service';
import { ErrorPageComponent } from './error/error-page.component';
import { AgmCoreModule } from '@agm/core';
import { TakerGuard } from '../auth/services/taker-guard.service';
import { PageNotAuthorizedComponent } from './page-not-authorized/page-not-authorized.component';
import { CourseAdminGuard } from '../auth/services/course-admin-guard.service';
import { ItemsAdminGuard } from '../auth/services/items-admin-guard.service';
import { TemplatesAdminGuard } from '../auth/services/templates-admin-guard.service';
import { GroupsAdminGuard } from '../auth/services/groups-admin-guard.service';
import { UsersAdminGuard } from '../auth/services/users-admin-guard.service';
import { DashboardAdminGuard } from '../auth/services/dashboard-admin-guard.service';
import { EngagementNavigationGuard } from '../auth/services/engagement-navigation-guard.service';
import { LocationsAdminGuard } from '../auth/services/locations-admin-guard.service';
import { CalendarAdminGuard } from '../auth/services/calendar-admin-guard.service';
import { AdminGuard } from '../auth/services/admin-guard.service';
import { SupplierService } from '../suppliers/services/supplier.service';
import { NewsAdminGuard } from '../auth/services/news-admin-guard.service';
import { NewsUserGuard } from '../auth/services/news-user-guard.service';
import { SuppliersAdminGuard } from '../auth/services/suppliers-admin-guard.service';
import { ManageTeachersAccountingAdminGuard } from '../auth/services/manage-teachers-accounting-admin-guard.service';
import { TeachersGuard } from '../auth/services/teachers-guard.service';
import { TeacherLoginGuard } from '../shared/services/teacher-login-guard.service';
import { UserProfileGuard } from '../auth/services/user-profile-guard.service';
import { ProfileDetailsComponent } from '../profile/profileDetails/profileDetails.component';
import { ForceResetPasswordComponent } from '../profile/forceResetPassword/forceResetPassword.component';
import { ForceResetPasswordGuard } from '../auth/services/force-reset-password-guard.service';
import { CatalogUserGuard } from '../auth/services/catalog-user-guard.service';
import { CatalogAdminGuard } from '../auth/services/catalog-admin-guard.service';
import { ManagerGuard } from '../auth/services/manager-guard.service';
import { ManageScheduleModelsAdminGuard } from '../auth/services/manage-schedule-models-admin-guard.service';
import { ManageTagsAdminGuard } from '../auth/services/manage-tags-admin-guard.service';
import { HomeAdminComponent } from './admin-home/admin-home.component';
import { ImportExportAdminGuard } from '../auth/services/import-export-admin-guard.service';
import { ManageCompetencesAdminGuard } from '../auth/services/manage-competences-admin-guard.service';
import { ApprovationPreregistrationsAdminGuard } from '../auth/services/approvation-preregistrations-admin-guard.service';
import { ApprovationInitiativesAdminGuard } from '../auth/services/approvation-initiatives-admin-guard.service';
import { ImpersonificationAdminGuard } from '../auth/services/impersonification-admin-guard.service';
import { BancaFooterComponent } from './banca-footer/banca-footer.component';

export const MY_NATIVE_FORMATS = {
    parseInput: 'DD/MM/YYYY',
    fullPickerInput: 'DD/MM/YYYY HH:mm',
    datePickerInput: 'DD/MM/YYYY',
    timePickerInput: 'HH:mm',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
};
@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
    upSecondLabel = 'Aggiungi un secondo';
    downSecondLabel = 'Un secondo in meno';
    upMinuteLabel = 'Aggiungi un minuto';
    downMinuteLabel = 'Un minuto in meno';
    upHourLabel = 'Aggiungi un\'ora';
    downHourLabel = 'Un\'ora in meno';
    prevMonthLabel = 'Mese precedente';
    nextMonthLabel = 'Prossimo mese';
    prevYearLabel = 'Anno precedente';
    nextYearLabel = 'Prossimo anno';
    prevMultiYearLabel = '21 anni precedenti';
    nextMultiYearLabel = 'Prossimi 21 anni';
    switchToMonthViewLabel = 'Cambia visuale';
    switchToMultiYearViewLabel = 'Scegli mese e anno';
    cancelBtnLabel = 'Annulla';
    setBtnLabel = 'Conferma';
    rangeFromLabel = 'Da';
    rangeToLabel = 'A';
    hour12AMLabel = 'AM';
    hour12PMLabel = 'PM';
}

@NgModule({
    declarations: [
        HeaderComponent,
        HomeComponent,
        PageNotFoundComponent,
        PageNotAuthorizedComponent,
        HomeAdminComponent,
        CompatibilityErrorComponent,
        LastInitiativesComponent,
        ProfileDetailsComponent,
        ErrorPageComponent,
        ForceResetPasswordComponent,
        BancaFooterComponent,
    ],
    imports: [
        SharedModule,
        AgmCoreModule,
        OwlMomentDateTimeModule
    ],
    exports: [
        HeaderComponent,
        ProfileDetailsComponent,
        LastInitiativesComponent,
        ErrorPageComponent,
        OwlMomentDateTimeModule,
        ForceResetPasswordComponent,
        BancaFooterComponent,
    ],
    providers: [
        UrlService,
        RedirectService,
        AuthService,
        NewsService,
        AuthGuard,
        CourseAdminGuard,
        DashboardAdminGuard,
        TemplatesAdminGuard,
        LocationsAdminGuard,
        CalendarAdminGuard,
        AdminGuard,
        UsersAdminGuard,
        GroupsAdminGuard,
        ManageTeachersAccountingAdminGuard,
        TeachersGuard,
        ItemsAdminGuard,
        ImpersonificationAdminGuard,
        TakerGuard,
        EngagementNavigationGuard,
        CompatibilityGuard,
        LoginGuard,
        TeacherLoginGuard,
        ForceResetPasswordGuard,
        NewsAdminGuard,
        ManageCompetencesAdminGuard,
        ApprovationPreregistrationsAdminGuard,
        ApprovationInitiativesAdminGuard,
        ImportExportAdminGuard,
        NewsUserGuard,
        CatalogUserGuard,
        CatalogAdminGuard,
        SuppliersAdminGuard,
        UserProfileGuard,
        LangsService,
        TagService,
        ItemsService,
        CourseEditionService,
        SupplierService,
        ManageTagsAdminGuard,
        ManagerGuard,
        ManageScheduleModelsAdminGuard,
        { provide: OWL_DATE_TIME_LOCALE, useValue: 'it' },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS },
        { provide: OwlDateTimeIntl, useClass: DefaultIntl }
    ]
})
export class CoreModule { }
