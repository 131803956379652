<div class="content">
  <div fxLayout="column" class="theme-primary" fxFlexFill fxLayoutAlign="start start" role="tablist">
    <div class="space40"></div>
    <!-- Intestazione -->
    <div fxLayout="row" fxFlexFill role="tablist">
      <!-- Cambia il filtro per la visualizzazione delle iniziative (fra iniziative in sospeso e iniziative in corso) -->
      <div class="full-width">
        <div class="title-input">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayout.sm="column" fxLayout.xs="column">
            <div fxLayout="row" fxLayoutAlign="start center" class="view-for-label">
              <p class="full-width p-for-h3" translate="generic.VIEW_FOR"></p>
            </div>
            <div fxLayout="row wrap">
              <!-- Iniziative in sospeso -->
              <lib-alloy-button importance="bordless" dimension="btn-regular"
                text="{{ 'home.PENDING_INITIATIVES' | translate }}" [disabled]="initiativesLoading"
                [isActive]="showInitiativeFor && showInitiativeFor.suspendedTabOpened"
                (onButtonClicked)="onInitiativeStatusFilterChanged('suspendedTabOpened')">
              </lib-alloy-button>
              <!-- Iniziative in corso -->
              <lib-alloy-button importance="bordless" dimension="btn-regular"
                text="{{ 'home.IN_PROGRESS_INITIATIVES' | translate }}" [disabled]="initiativesLoading"
                [isActive]="showInitiativeFor && showInitiativeFor.inProgressTabOpened"
                (onButtonClicked)="onInitiativeStatusFilterChanged('inProgressTabOpened')">
              </lib-alloy-button>
              <!-- Iniziative archiviate -->
              <lib-alloy-button importance="bordless" dimension="btn-regular"
                text="{{ 'home.ARCHIVED_INITIATIVES' | translate }}" [disabled]="initiativesLoading"
                [isActive]="showInitiativeFor && showInitiativeFor.archivedTabOpened"
                (onButtonClicked)="onInitiativeStatusFilterChanged('archivedTabOpened')">
              </lib-alloy-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="space20"></div>

    <!-- Tabella con le ultime iniziative -->
    <div *ngIf="!initiativesLoading; else loadingInitiatives" fxLayout="row" fxFlexFill>
      <last-initiatives [lastInitiatives]="lastInitiatives"
        (openRemoveInitiativeConfirmModal)="openRemoveInitiativeConfirmModal($event)"
        (goToEdit)="goToEditInitiative($event)" (goToPreview)="goToPreview($event)"
        *ngIf="lastInitiatives && lastInitiatives.length" fxFlexFill>
      </last-initiatives>

      <div *ngIf="!lastInitiatives || !lastInitiatives.length" fxFlexFill fxLayout="row" fxLayoutAlign="center center">
        <p translate="generic.NO_DATA_FOUND"></p>
      </div>
    </div>

    <!-- Loader delle ultime iniziative -->
    <ng-template #loadingInitiatives>
      <pending-initiatives-placeholder *ngFor="let number of [0,1,2,3,4,5,6]"></pending-initiatives-placeholder>
    </ng-template>

    <div class="space40"></div>
    <!-- Titolo della sezione per la creazione dei corsi -->
    <div fxLayout="row" fxFlexFill>
      <p translate="home.CREATE_AN_INITIATIVE_FROM" class="p-for-h3-home"></p>
    </div>
    <div class="space20"></div>
    <!-- Card con le possibilità di creazione -->
    <div fxLayout="row" fxLayout.sm="column" fxLayoutAlign="start center" fxLayout.xs="column" fxFill role="radiogroup">
      <new-syllabus-card fxFlex.gt-sm="33" (toggledChange)="toggledChange($event)"
        [(selectedCreationPossibility)]="selectedCreationPossibility" role="radio"
        attr.aria-checked="{{selectedCreationPossibility && selectedCreationPossibility.options && selectedCreationPossibility.options === '1' ? true : false}}">
      </new-syllabus-card>
      <new-concept-card fxFlex.gt-sm="33" (toggledChange)="toggledChange($event)"
        [(selectedCreationPossibility)]="selectedCreationPossibility" role="radio"
        attr.aria-checked="{{selectedCreationPossibility && selectedCreationPossibility.options && selectedCreationPossibility.options === '2' ? true : false}}">
      </new-concept-card>
      <new-item-card *ngIf="canViewLibraryCard()" fxFlex.gt-sm="33" (toggledChange)="toggledChange($event)"
        [(selectedCreationPossibility)]="selectedCreationPossibility" role="radio"
        attr.aria-checked="{{selectedCreationPossibility && selectedCreationPossibility.options && selectedCreationPossibility.options === '3' ? true : false}}">
      </new-item-card>
    </div>
    <!-- Pulsante che conferma la selezione e porta alla pagina di creazione -->
    <div class="margin-top25 margin-bottom50" fxLayout="row" fxLayout.sm="column" fxLayoutAlign="center center"
      fxLayout.xs="column" fxFlexFill>
      <lib-alloy-button importance="primary" dimension="btn-regular" [text]="'home.START' | translate"
        (onButtonClicked)="goToCreateInitiative()"></lib-alloy-button>
    </div>
  </div>
</div>

<!-- Modale per scegliere la tipologia di oggetto atomico da creare -->
<lib-alloy-modal class="informative-modal" id="select-new-item-type">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h1 class="custom-modal-title" translate="items.itemList.NEW_ITEM"></h1>
    <svg-icon class="cursor-pointer" (click)="closeChooseNewItemTypeModal()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <lib-alloy-select labelForId="selectItemsToCreateNgSelect" [bindModel]="selectedTypeOfNewItem"
    (onSelectionChanged)="chooseNewItemTypeModal($event)" [items]="selectedItemTypesForItemCreationOptions"
    [clearOnBackspace]="false" [searchable]="false" [multiple]="false" bindLabel="name" [addTag]="false"
    formTitle="{{ 'items.itemList.ITEM_TYPE' | translate }}" [required]="true"
    placeholderLabel="{{ 'generic.SEARCH_TYPOLOGY' | translate }}"
    startSearchLabel="{{ 'generic.ngSelect.START_SEARCH' | translate }}"
    noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
    attrAriaLabel="{{ 'generic.SEARCH_TYPOLOGY' | translate }}"
    loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="small-dimension"
    [virtualScroll]="false"></lib-alloy-select>
  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- Annulla l'operazione -->
    <lib-alloy-button importance="bordless" dimension="btn-small" [text]="'generic.CANCEL' | translate"
      (onButtonClicked)="closeChooseNewItemTypeModal()"></lib-alloy-button>
    <!-- Conferma l'operazione -->
    <lib-alloy-button importance="primary" [disabled]="!selectedTypeOfNewItem" dimension="btn-small"
      [text]="'generic.CONFIRM' | translate" (onButtonClicked)="closeChooseNewItemTypeModal(true)"></lib-alloy-button>
  </div>
</lib-alloy-modal>

<!-- Componente di conferma sulla rimozione della iniziativa selezionata -->
<confirm-modal [modalId]="'deleteInitiativeConfirmModal'" containerClass="destructive-modal"
  modalTitle="{{ 'initiatives.REMOVE_INITIATIVE_MODAL.TITLE' | translate }}"
  modalBody="{{ 'initiatives.REMOVE_SYLLABUS_OF_INITIATIVE_MODAL.BODY' | translate }}"
  confirmBtnLabel="{{ 'initiatives.REMOVE_SYLLABUS_OF_INITIATIVE_MODAL.DELETE_WITHOUTH_SYLLABUS' | translate }}"
  confirmSecondBtnLabel="{{ 'initiatives.REMOVE_SYLLABUS_OF_INITIATIVE_MODAL.DELETE_WITH_SYLLABUS' | translate }}"
  cancelBtnLabel="{{ 'modals.CANCEL_BTN' | translate }}" (secondConfirmation)="secondConfirmation()"
  (confirmation)="confimation()" (dismissAction)="dismissAction()"></confirm-modal>