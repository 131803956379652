/*
* Componente che crea un placeholder per la card della creazione di una iniziativa
*/

import { Component } from '@angular/core';
@Component({
  selector: 'create-initiative-card-placeholder',
  templateUrl: './create-initiative-card-placeholder.component.html',
  styleUrls: ['./create-initiative-card-placeholder.component.scss']
})

export class CreateInitiativeCardPlaceholderComponent {
  constructor() {
  }
}