/*
*  Lista di azioni riguardante l'anagrafica degli utenti
*/

import { Action } from '@ngrx/store';
import { User } from 'src/cm2-commonclasses';

// Salva la lista di utenti recuperati
export const SET_USERS = 'SET_USERS';

// Cancella un utente
export const DELETE_USER = 'DELETE_USER';

// Recupera i utenti da remoto
export const GET_USERS = 'GET_USERS';

// Setta il contatore dei utenti recuperati da remoto
export const SET_USERS_COUNT = 'SET_USERS_COUNT';

// Setta la paginazione
export const SET_PAGINATION_USERS = 'SET_PAGINATION_USERS';

// Setta la pagina corrente
export const SET_PAGE_USERS = 'SET_PAGE_USERS';

// Setta il testo (searchedText)
export const SET_USERS_TEXT_FILTER = 'SET_USERS_TEXT_FILTER';

// Setta la società (socDistacco)
export const SOC_DISTACCO = 'SOC_DISTACCO';

// Setta il tipo di anagrafica (dipendente)
export const EXTERNAL_ONLY = 'EXTERNAL_ONLY';

// Setta il tipo di anagrafica (esterno)
export const NOT_EXTERNAL_ONLY = 'NOT_EXTERNAL_ONLY';

// Recupera gli utenti filtrati da remoto
export const GET_FILTERED_USERS = 'GET_FILTERED_USERS';

// Salva la lista di utenti filtrati recuperati
export const SET_FILTERED_USERS = 'SET_FILTERED_USERS';

// Setta il contatore dei utenti filtrati recuperati da remoto
export const SET_FILTERED_USERS_COUNT = 'SET_FILTERED_USERS_COUNT';

// Setta la ricerca per utenti confermati o no
export const SET_TO_BE_CONFIRMED = 'SET_TO_BE_CONFIRMED';

export class SetUsers implements Action {
  readonly type = SET_USERS;

  constructor(public payload: User[]) { }
}

export class DeleteUser implements Action {
  readonly type = DELETE_USER;

  constructor(public payload: string) { }
}

export class GetUsers implements Action {
  readonly type = GET_USERS;

  constructor(public payload?: any) { }
}

export class SetUsersCount implements Action {
  readonly type = SET_USERS_COUNT;

  constructor(public payload: number) { }
}

export class SetPageUsers implements Action {
  readonly type = SET_PAGE_USERS;

  constructor(public payload: number) { }
}

export class SetPaginationUsers implements Action {
  readonly type = SET_PAGINATION_USERS;

  constructor(public payload: { fromRecord: number, numRecords: number }) { }
}
export class SetUsersTextFilter implements Action {
  readonly type = SET_USERS_TEXT_FILTER;

  constructor(public payload: string) { }
}

export class SocDistacco implements Action {
  readonly type = SOC_DISTACCO;

  constructor(public payload: string) { }
}

export class ExternalOnly implements Action {
  readonly type = EXTERNAL_ONLY;

  constructor(public payload: boolean) { }
}
export class NotExternalOnly implements Action {
  readonly type = NOT_EXTERNAL_ONLY;

  constructor(public payload: boolean) { }
}

export class GetFilteredUsers implements Action {
  readonly type = GET_FILTERED_USERS;
  constructor(public payload?: any) { }
}

export class SetFilteredUsers implements Action {
  readonly type = SET_FILTERED_USERS;
  constructor(public payload: User[]) { }
}

export class SetFilteredUsersCount implements Action {
  readonly type = SET_FILTERED_USERS_COUNT;

  constructor(public payload: number) { }
}

export class SetToBeConfirmed implements Action {
  readonly type = SET_TO_BE_CONFIRMED;

  constructor(public payload: boolean) { }
}

export type UsersAdminActions =
  SetUsers |
  DeleteUser |
  SetUsersCount |
  SetPaginationUsers |
  SetPageUsers |
  SetUsersTextFilter |
  SocDistacco |
  ExternalOnly |
  NotExternalOnly |
  GetUsers |
  GetFilteredUsers |
  SetFilteredUsers |
  SetFilteredUsersCount |
  SetToBeConfirmed;