<div class="timeline-item no-overflow"
  [ngClass]="containerClass + (_hasGreyBackground ? ' grey-background' : '') + (_isLoading ? '': ' stop-loading')">
  <div class="animated-background" fxLayout="column">

    <div class="header-container" fxLayout="row" fxLayoutAlign="start center">
      <div class="background-masker" fxFlex></div>
      <div class="background-masker header-divider"></div>
      <div class="background-masker" fxFlex></div>
      <div class="background-masker header-divider"></div>
      <div class="background-masker" fxFlex></div>
      <div class="background-masker header-divider"></div>
      <div class="background-masker" fxFlex></div>
      <div class="background-masker header-divider"></div>
      <div class="background-masker" fxFlex></div>
      <div class="background-masker header-divider"></div>
      <div class="background-masker" fxFlex></div>
    </div>

    <div class="header-container-divider"></div>

    <div class="background-masker body"></div>

    <div class="body-container-divider" fxLayout="row" fxLayoutAlign="center center">
      <span *ngIf="!_isLoading">Nessun dato disponibile</span>
      <span *ngIf="_isLoading">Caricamento dati</span>
    </div>

    <div class="background-masker body"></div>

    <div class="footer-container-divider"></div>

    <div class="footer-container" fxLayout="row">
      <div class="background-masker circle-side" fxFlex></div>
      <div class="background-masker circle"></div>
      <div class="background-masker circle-divider"></div>
      <div class="background-masker circle"></div>
      <div class="background-masker circle-divider"></div>
      <div class="background-masker circle"></div>
      <div class="background-masker circle-divider"></div>
      <div class="background-masker circle"></div>
      <div class="background-masker circle-divider"></div>
      <div class="background-masker circle"></div>
      <div class="background-masker circle-side" fxFlex></div>
    </div>
  </div>
</div>
