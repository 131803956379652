<div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">

  <ng-container *ngFor="let credit of creditList">

    <div fxFlex class="credit-wrapper h-100" fxLayout="row" fxLayout="start start">
      <div class="credit-column-container">
        <h3 class="emphasis">{{ credit.type }}</h3>
        <div class="italic">
          <strong> {{credit.currentCredits}} {{ credit.requiredCredits ? ('/ ' + credit.requiredCredits) : '' }}
          </strong>
          <!--span>
            {{ credit.description }} {{ currentYear}}
          </span-->
        </div>
      </div>
    </div>

  </ng-container>

</div>