/*
* Componente che crea un placeholder per la lista delle iniziative pendenti
*/

import { Component, Input } from '@angular/core';
@Component({
  selector: 'news-card-placeholder',
  templateUrl: './news-card-placeholder.component.html',
  styleUrls: ['./news-card-placeholder.component.scss']
})

export class NewsCardPlaceholderComponent {
  @Input() containerClass: string;
  constructor() {
  }
}