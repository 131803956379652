/*
*  Lista di azioni riguardanti i template mail
*/

import { Action } from '@ngrx/store';
import { Item } from '../../../cm2-commonclasses';
import { LocationItem } from '../../shared/models/course.model';

// Salva la lista di template recuperati
export const SET_LOCATIONS = 'SET_LOCATIONS';

// Aggiunge un nuovo template
export const ADD_LOCATION = 'ADD_LOCATION';

// Aggiorna un template
export const UPDATE_LOCATION = 'UPDATE_LOCATION';

// Cancella un template
export const DELETE_LOCATION = 'DELETE_LOCATION';

// Salva il template
export const STORE_LOCATION = 'STORE_LOCATION';

// Recupera i template da remoto
export const GET_LOCATIONS = 'GET_LOCATIONS';

// Setta il filtro per la tipologia
export const SET_LOCATIONS_TYPE = 'SET_LOCATIONS_TYPE';

// Setta il contatore dei template recuperato da remoto
export const SET_LOCATIONS_COUNT = 'SET_LOCATIONS_COUNT';

// Setta la paginazione
export const SET_PAGINATION = 'SET_PAGINATION';

// Setta la pagina corrente
export const SET_PAGE = 'SET_PAGE';

// Setta il testo di ricerca
export const SET_TEXT_FILTER = 'SET_TEXT_FILTER'

export class SetLocations implements Action {
  readonly type = SET_LOCATIONS;

  constructor(public payload: LocationItem[]) { }
}

export class AddLocation implements Action {
  readonly type = ADD_LOCATION;

  constructor(public payload: Item) { }
}

export class UpdateLocation implements Action {
  readonly type = UPDATE_LOCATION;

  constructor(public payload: { index: number, updatedLocation: Item }) { }
}

export class DeleteLocation implements Action {
  readonly type = DELETE_LOCATION;

  constructor(public payload: string) { }
}

export class StoreLocation implements Action {
  readonly type = STORE_LOCATION;
}

export class GetLocations implements Action {
  readonly type = GET_LOCATIONS;

  constructor(public payload?: boolean) { }
}

export class SetLocationsCount implements Action {
  readonly type = SET_LOCATIONS_COUNT;

  constructor(public payload: number) { }
}

export class SetLocationsType implements Action {
  readonly type = SET_LOCATIONS_TYPE;

  constructor(public payload: string) { }
}

export class SetPage implements Action {
  readonly type = SET_PAGE;

  constructor(public payload: number) { }
}

export class SetPagination implements Action {
  readonly type = SET_PAGINATION;

  constructor(public payload: { fromRecord: number, numRecords: number }) { }
}

export class SetFilterType implements Action {
  readonly type = SET_TEXT_FILTER;

  constructor(public payload: string) { }
}

export type LocationActions = SetLocations |
  AddLocation |
  UpdateLocation |
  DeleteLocation |
  StoreLocation |
  SetLocationsCount |
  SetPagination |
  SetPage |
  SetLocationsType |
  SetFilterType |
  GetLocations;