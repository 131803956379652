/*
 * Gestione dei dispatch che devono eseguire qualcosa di asincrono e che non alterano lo State (i così chiamati Effect). Struttura molto simile ai Reducer,
 * che verificano il tipo di azione e concatenano una serie di operatori degli Observable per fare qualcosa. L'unica differenza, appunto, è che qui non
 * andiamo a modificare lo State, gestiamo solo lo Side Effect
*/

import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, mergeMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as CoreActions from '../../core/ngrx/core.actions';
import * as NewsActions from '../../news/ngrx/news.actions';
import { Store } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import * as fromApp from '../../ngrx/app.reducers';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SenecaResponse } from "../../../cm2-commonclasses";
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import * as jwtDecode from 'jwt-decode';

export function getDefaultRedirectUrl(authService, redirectUrl?: string, isTaker?: boolean, isTeacher?: boolean) {
  let redirectUrlAfterFirstPolling = null;
  if (redirectUrl) {
    redirectUrlAfterFirstPolling = redirectUrl;
  } else if (isTeacher) {
    redirectUrlAfterFirstPolling = '/teachers/home';
  } else if (!isTaker && environment.canAccessAdminMode) {
    if (environment.canAccessManageInitiatives) {
      redirectUrlAfterFirstPolling = '/home';
    } else if (environment.canAccessManageLibrary) {
      redirectUrlAfterFirstPolling = '/itemsAdmin/itemList';
    } else if (environment.canAccessAdminCalendar) {
      redirectUrlAfterFirstPolling = '/calendar';
    } else if (environment.canAccessAdminDashboard) {
      redirectUrlAfterFirstPolling = '/dashboard';
    } else if (environment.canAccessAdminNews) {
      redirectUrlAfterFirstPolling = '/news';
    } else if (environment.canAccessAdminSuppliers) {
      redirectUrlAfterFirstPolling = '/suppliers';
    } else if (environment.canAccessManageGroups) {
      redirectUrlAfterFirstPolling = '/groups/listGroups';
    } else if (environment.canAccessManageUsers) {
      redirectUrlAfterFirstPolling = '/usersAdmin/listAdminUsers';
    } else if (environment.canAccessImpersonification) {
      redirectUrlAfterFirstPolling = '/impersonificationAdmin/home/1/1/false/true';
    } else if (environment.canAccessManageLocations) {
      redirectUrlAfterFirstPolling = '/locations';
    } else if (environment.canAccessManageTemplates) {
      redirectUrlAfterFirstPolling = '/templates';
    } else if (environment.canAccessManageTeachersAccounting) {
      redirectUrlAfterFirstPolling = '/manageTeachersAccounting';
    } else if (environment.canManageAdminPreregWorkflow) {
      redirectUrlAfterFirstPolling = '/approvationPreregistrationsAdmin';
    } else if (environment.canManageAdminInitiativesWorkflow) {
      redirectUrlAfterFirstPolling = '/approvationInitiativesAdmin';
    } else {
      redirectUrlAfterFirstPolling = '/403';
    }
  } else if (isTaker) {
    redirectUrlAfterFirstPolling = authService.getRedirectUrlAfterFirstPollingForTaker();
  } else {
    redirectUrlAfterFirstPolling = '/403';
  }

  return redirectUrlAfterFirstPolling;
}

// Injectable perché abbiamo bisogno di importare le action e il routing
@Injectable()
export class AuthEffects {
  // actions$ col dollaro per marcare il fatto che è un Observable
  constructor(
    private store: Store<fromApp.AppState>,
    private actions$: Actions,
    private authService: AuthService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private deviceService: DeviceDetectorService) {
    let redirectUrl$: Observable<string> = this.store.select(fromApp.getRedirectUrl);
    redirectUrl$.subscribe(
      (redirectUrl) => {
        this.redirectUrl = redirectUrl;
      });
  }

  // Token intero recuperato dai servizi
  token: string;
  // Tiny recuperato dai servizi
  tinyToken: string;
  // Eventuale url richiesto
  redirectUrl: string;
  // Verifica se è un taler
  isTaker: boolean;
  // Verifica se è un docente
  isTeacher: boolean;

  // Proprietà dell'Effect di cui NgRX starà in watch eseguendo il codice che gli assegnamo sulla destra.
  // Quindi, per prima cosa, si accede alle action dello Store applicativo (che abbiamo iniettato nel costruttore)
  authLogin = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.DoLogin)
    , switchMap((authData) => {
      // Salvo il fatto se è un taker che sta eseguendo il login
      this.isTaker = authData.isTaker;
      // Verifico se l'applicazione prevede l'accesso alla parte utente
      if (!environment.canAccessUserMode) {
        this.isTaker = false;
      }

      if (this.isTaker) {
        sessionStorage.setItem('isTaker', "true");
      }
      // Prima di chiamare il servizio per il login, identifico l'userAgent e il tipo di device dell'utente
      const deviceInfo = this.deviceService.getDeviceInfo();
      const userAgent = deviceInfo && deviceInfo.userAgent;
      let deviceType;
      if (this.deviceService.isMobile()) {
        // Salvo il fatto che è uno smartphone
        deviceType = 'P';
      } else if (this.deviceService.isTablet()) {
        // Salvo il fatto che è un tablet
        deviceType = 'T';
      } else if (this.deviceService.isDesktop()) {
        // Salvo il fatto che è un computer desktop
        deviceType = 'D';
      }
      return from(this.authService.login(authData.email, authData.password, deviceType, userAgent));
    })// , take(1) DA NON USARE NELL'EFFECT! Essendo quest'ultimo un singleton, una volta fatto l'unsubscribe tramite il take(1), non farà più il subscribe. Pertanto, se si provasse a fare il dispatch intercettato da questo Effect, non produrrebbe più, appunto, nessun effect e non entrerebbe nel metodo
    , mergeMap(
      (tinyTokenObj: SenecaResponse<any>) => {
        let actionsContainer = [];
        if (tinyTokenObj.error) {
          throw (new Error(tinyTokenObj.error));
        }
        if (!tinyTokenObj.error && !tinyTokenObj.response) {
          throw (new Error("USER_NOT_FOUND"));
        }

        const redirectUrlAfterFirstPolling = getDefaultRedirectUrl(this.authService, this.redirectUrl, this.isTaker);

        actionsContainer.push(
          AuthActions.SetToken({ payload: tinyTokenObj.response }),
          AuthActions.SetUserAuthenticated({ isTaker: this.isTaker }),
          {
            type: CoreActions.REMOVE_REDIRECT_URL
          },
          {
            type: CoreActions.START_RENEW_TOKEN_POLLING,
            payload: { redirectUrl: redirectUrlAfterFirstPolling }
          }
        );

        if (this.isTaker) {
          actionsContainer.push(
            {
              type: CoreActions.START_COUNT_NOTIFICATIONS
            });
          if (environment.canAccessUserNews) {
            actionsContainer.push(
              {
                type: NewsActions.START_COUNT_NOTIFICATIONS_NEWS
              });
          }
        }

        return actionsContainer;
      })
    , catchError((err, caught) => {
      if (err && err.message) {
        this.toastr.error(this.translate.instant('errors.' + err.message));
      }
      // Quindi, alla fine, torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
      return caught;
    })
  ));


  teacherAuthLogin = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.DoTeacherLogin)
    , switchMap((authData) => {
      // Verifico se l'applicazione prevede l'accesso alla parte utente
      // this.isTeacher = environment.canAccessManageTeachersAccounting;

      // Prima di chiamare il servizio per il login, identifico l'userAgent e il tipo di device dell'utente
      const deviceInfo = this.deviceService.getDeviceInfo();
      const userAgent = deviceInfo && deviceInfo.userAgent;
      let deviceType;
      if (this.deviceService.isMobile()) {
        // Salvo il fatto che è uno smartphone
        deviceType = 'P';
      } else if (this.deviceService.isTablet()) {
        // Salvo il fatto che è un tablet
        deviceType = 'T';
      } else if (this.deviceService.isDesktop()) {
        // Salvo il fatto che è un computer desktop
        deviceType = 'D';
      }
      return from(this.authService.loginSupplierPerson(authData.email, authData.password, deviceType, userAgent));
    })// , take(1) DA NON USARE NELL'EFFECT! Essendo quest'ultimo un singleton, una volta fatto l'unsubscribe tramite il take(1), non farà più il subscribe. Pertanto, se si provasse a fare il dispatch intercettato da questo Effect, non produrrebbe più, appunto, nessun effect e non entrerebbe nel metodo
    , mergeMap(
      (tinyTokenObj: SenecaResponse<any>) => {

        const jwtDecoded = jwtDecode(tinyTokenObj.response);

        let actionsContainer = [];
        if (tinyTokenObj.error) {
          if (tinyTokenObj.error.indexOf("USER_NOT_FOUND") !== -1) {
            throw (new Error("WRONG_USERNAME_OR_PSW"));
          } else {
            throw (new Error(tinyTokenObj.error));
          }
        }
        if (!tinyTokenObj.error && !tinyTokenObj.response) {
          throw (new Error("WRONG_USERNAME_OR_PSW"));
        }
        this.isTeacher = this.authService.isUserAuthorized('CORPORATEACADEMY_SUPPLIER_PERSON', jwtDecoded?.auths || []);

        if (this.isTeacher) {
          sessionStorage.setItem('isTeacher', "true");
        }

        let redirectUrlAfterFirstPolling = null;
        if (this.redirectUrl) {
          redirectUrlAfterFirstPolling = this.redirectUrl;
        } else if (this.isTeacher) {
          redirectUrlAfterFirstPolling = '/teachers/home';
        } else {
          redirectUrlAfterFirstPolling = '/403';
        }

        actionsContainer.push(
          AuthActions.SetToken({ payload: tinyTokenObj.response }),
          AuthActions.SetUserAuthenticated({ isTeacher: true }),
          {
            type: CoreActions.REMOVE_REDIRECT_URL
          },
          {
            type: CoreActions.START_RENEW_TOKEN_POLLING,
            payload: { redirectUrl: redirectUrlAfterFirstPolling }
          }
        );

        return actionsContainer;
      })
    , catchError((err, caught) => {
      if (err && err.message) {
        this.toastr.error(this.translate.instant('errors.' + err.message));
      }
      // Quindi, alla fine, torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
      return caught;
    })
  ));
}
