import { AttributeTypes, ItemAttributeInitiativeSubTypes, ItemAttributeObjectTypes, ItemAttributeTypes } from 'src/cm2-commonclasses';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // Lista di crediti disponibili
  creditTypeList: [AttributeTypes.IVASS_TYPE, AttributeTypes.EFA_A_TYPE, AttributeTypes.EFA_B_TYPE, AttributeTypes.EFP_TYPE, AttributeTypes.MIFID_II_TYPE],
  // Accesso generico alla parte utente
  canAccessUserMode: true,
  //Visibilià per banca
  isBanca: true,
  //Visibilità della sezione crediti
  hideCredits: false,
  // Variabile legata alla preiscrizione in un iniziativa
  canAskCatalogAsSimpleMail: false,
  // Accesso generico alla parte amministrativa
  canAccessAdminMode: true,
  // Accesso generico alla parte manager
  canAccessManagerMode: false, // MMTODO 07/06/2023 Spento momentaneamente fino a presentazione funzioni
  // Lista dei singoli moduli
  // Libretto formativo
  canAccessUserTrainingBooklet: true,
  // Library lato utente
  canAccessUserLibrary: true,
  // Home page lato utente (Corporate)
  canAccessUserHome: true,
  // Profilo utente
  canAccessUserProfile: true,
  // Notifiche utente
  canAccessUserNotifications: true,
  // Calendario utente
  canAccessUserCalendar: false, // Temporaneo per la prima fase di facile
  // News utente
  canAccessUserNews: false, // Temporaneo per la prima fase di facile
  // Catalogo utente
  canAccessUserCatalog: true,
  // Catalogo Libreria utente
  canAccessUserCatalogLibrary: false,
  // Catalogo Corsi utente
  canAccessUserCatalogCourses: true,
  // Gestione oggetti Library
  canAccessManageLibrary: true,
  // Gestione prereg workflow admin
  canManageAdminPreregWorkflow: true,
  // Gestione approvazione workflow iniziative admin
  canManageAdminInitiativesWorkflow: true,
  // Gestione approvazione workflow edizioni admin
  canManageAdminEditionsWorkflow: false,
  // Gestione scadenze
  canManageInitiativesDeadline: true,
  // Gestione template
  canAccessManageTemplates: true,
  // Gestione corsi (iniziative)
  canAccessManageInitiatives: true,
  // Gestione tags
  canAccessManageTags: true,
  // Gestione modelli orari
  canAccessManageScheduleModels: true,
  // Gestione competenze
  canAccessManageCompetences: true,
  // Gestione import/export
  canAccessManageImportExport: true,
  // Gestione luogo
  canAccessManageLocations: true,
  // Gestione gruppi
  canAccessManageGroups: true,
  // Gestione utenti
  canAccessManageUsers: true,
  // Sezione impersonificazione
  canAccessImpersonification: true,
  // Calendario lato admin
  canAccessAdminCalendar: true,
  // Dashboard admin
  canAccessAdminDashboard: true,
  // Gestione fornitori lato admin
  canAccessAdminSuppliers: true,
  // Gestione news lato admin
  canAccessAdminNews: true,
  // Gestione contabilità docenti lato admin
  canAccessManageTeachersAccounting: false,
  // accesso generico alla sezione docente
  canAccessTeacherMode: false,
  // Calendario lato docente
  canAccessTeacherCalendar: true,
  // Gestione catalogo lato admin
  canAccessManageCatalog: true,
  // Visibile lo step materiali amministrativi
  canAccessManageAdministrativeDocuments: true,
  // Abilita la condivisione linkedin dell'attestato del corso
  canShareCertificationOnLinkedin: true,
  // Gestione filtri dashboard admin
  // Chief
  adminDashboardFilterChief: true,
  // socDistacco
  adminDashboardFilterSocDistacco: true,
  // Dipartimento
  adminDashboardFilterDipartimento: true,
  // Servizio
  adminDashboardFilterServizio: true,
  // Divisione
  adminDashboardFilterDivisione: true,
  // AreaRepartoSettore
  adminDashboardFilterNucleo: true,
  // Unità Segretariale
  adminDashboardFilterSegretariale: true,
  // Unità Organizzativa
  adminDashboardFilterOrganizzativa: true,
  // Crediti
  adminDashboardFilterCrediti: true,
  // Abilita la gestione delle approvazioni pre-iscrizione
  canAccessManagerApprovals: true,
  // Abilita la gestione delle deleghe
  canAccessManagerDelegations: true,
  // Abilita il calendario dei manager
  canAccessManagerCalendar: true,
  // Abilita la dashboard dei manager
  canAccessManagerDashboard: true,
  // Abilita FAQ
  canAccessFaq: false,
  // Link di accesso a coursera
  canAccessCoursera: false,
  // Tipologie di iniziativa abilitate
  initiativeTypes: [
    'COURSE_CLASS_STAGE',
    'EXTERNAL_COURSE_CLASS_STAGE',
    'COURSE_ONLINE_STAGE',
    'EXTERNAL_ONLINE_STAGE',
    'EVENT_CLASS_STAGE',
    'EVENT_ONLINE_STAGE',
    'BLENDED_STAGE',
    'ASSESSMENT',
    'WEBINAR',
  ],
  //Tipologie di partecipazione
  partecipationTypes: [
    "ONLINE",
    "PRESENCE",
    "BLENDED"
  ],
  //status di un iniziativa
  initiativeStatuses: [
    "DRAFT",
    "CONFIRMED",
    "CANCELLED",
    "NEGATED_BY_SUPPLIER",
    "APPROVED_BY_SUPPLIER",
    "APPROVAL_PENDING",
    "NOT_APPROVED",
    "WAITING_SUPPLIER_CONF",
    "REPORTED",
    "ACTIVE",
    "INACTIVE"
  ],

  // Tipologie di iniziativa in deroga abilitate
  initiativeSubTypes: [
    ItemAttributeInitiativeSubTypes.WEBINAR,
    ItemAttributeInitiativeSubTypes.ASSESSMENT,
    ItemAttributeInitiativeSubTypes.AV,
    ItemAttributeInitiativeSubTypes.BASIC_TRAINING_MEETINGS,
    ItemAttributeInitiativeSubTypes.BASIC_TRAINING_ONLINE,
    ItemAttributeInitiativeSubTypes.BASIC_TRAINING_ONLINE_WORK_EXPERIENCE,
    ItemAttributeInitiativeSubTypes.BASIC_TRAINING_WORK_EXPERIENCE,
    ItemAttributeInitiativeSubTypes.BLENDED_PATH,
    ItemAttributeInitiativeSubTypes.COLLAB_ONE_TO_ONE,
    ItemAttributeInitiativeSubTypes.CONFERENCE_CONGRESS,
    ItemAttributeInitiativeSubTypes.COURSE,
    ItemAttributeInitiativeSubTypes.EXHIBITION_FAIR,
    ItemAttributeInitiativeSubTypes.GROUP_COACHING,
    ItemAttributeInitiativeSubTypes.INDIVIDUAL_COACHING,
    ItemAttributeInitiativeSubTypes.ITINERARY,
    ItemAttributeInitiativeSubTypes.MENTORING,
    ItemAttributeInitiativeSubTypes.ONLINE_EXECUTIVE_PHD,
    ItemAttributeInitiativeSubTypes.ONLINE_GROUP_COACHING,
    ItemAttributeInitiativeSubTypes.ONLINE_INDIVIDUAL_COACHING,
    ItemAttributeInitiativeSubTypes.ONLINE_MENTORING,
    ItemAttributeInitiativeSubTypes.ONLINE_MASTER,
    ItemAttributeInitiativeSubTypes.ONLINE_COURSE,
    ItemAttributeInitiativeSubTypes.ONLINE_TEST,
    ItemAttributeInitiativeSubTypes.PRESENCE_EXECUTIVE_PHD,
    ItemAttributeInitiativeSubTypes.PRESENCE_MASTER,
    ItemAttributeInitiativeSubTypes.PROJECT_WORK,
    ItemAttributeInitiativeSubTypes.SECOND_LEVEL_ONLINE_MASTER,
    ItemAttributeInitiativeSubTypes.SECOND_LEVEL_PRESENCE_MASTER,
    ItemAttributeInitiativeSubTypes.SELF_STUDY,
    ItemAttributeInitiativeSubTypes.SELF_STUDY_FREE_ENROLLMENT,
    ItemAttributeInitiativeSubTypes.SEMINAR_CONFERENCE,
    ItemAttributeInitiativeSubTypes.STAGE,
    ItemAttributeInitiativeSubTypes.STUDY_VACATION,
    ItemAttributeInitiativeSubTypes.WEB_SEMINAR,
    ItemAttributeInitiativeSubTypes.WORK_EXPERIENCE,
    ItemAttributeInitiativeSubTypes.WORK_EXPERIENCE_ONLINE_STAGE,
    ItemAttributeInitiativeSubTypes.WORKSHOP,
    ItemAttributeInitiativeSubTypes.HACKATHON,
    ItemAttributeInitiativeSubTypes.SURVEY,
    ItemAttributeInitiativeSubTypes.SATISFACTION_TEST,
    ItemAttributeInitiativeSubTypes.LEARNING_ASSESSMENT_TEST,
    ItemAttributeInitiativeSubTypes.COMPLIANCE,
    ItemAttributeInitiativeSubTypes.ONBOARDING_ACADEMY,
    ItemAttributeInitiativeSubTypes.TECH,
    ItemAttributeInitiativeSubTypes.BEHAVIOURAL,
    ItemAttributeInitiativeSubTypes.MANAGEMENT,
    ItemAttributeInitiativeSubTypes.COACHING,
  ],
  // Lista di finanziamenti disponibili per i corsi
  fundingList: [
    'FBA',
    'FONDIR',
    'COMPANY_FUNDS',
    'TRAINING_4_0'
  ],
  // Tipologie di oggetti Library abilitati
  libraryItemTypes: [
    'LEARNING_PLAN',
    'BLENDED_CONTAINER',
    'PROJECT',
    'PODCAST',
    'GRAPH',
    'DOCUMENT',
    'ELEARNING',
    'VIDEO',
    'IMAGE',
    'SURVEY',
    'EBOOK',
    'EXTERNAL_ITEM'
  ],
  // elementi che popolano i filtri della libreria
  libraryItemElements: [
    // { code: ItemTypes.CERTIFICATE_ITEM, desc: <string>null },
    { code: ItemAttributeObjectTypes.DOCUMENT, desc: <string>null },
    //{ code: ItemAttributeObjectTypes.DVD, desc: <string>null },
    { code: ItemAttributeObjectTypes.EBOOK, desc: <string>null },
    { code: ItemAttributeObjectTypes.ELEARNING, desc: <string>null },
    { code: ItemAttributeObjectTypes.IMAGE, desc: <string>null },
    { code: ItemAttributeObjectTypes.PROJECT, desc: <string>null },
    //{ code: ItemAttributeObjectTypes.BOOK, desc: <string>null },
    { code: ItemAttributeObjectTypes.LEARNING_PLAN, desc: <string>null },
    { code: ItemAttributeObjectTypes.PODCAST, desc: <string>null },
    //{ code: ItemAttributeObjectTypes.SURVEY, desc: <string>null },
    // { code: ItemAttributeObjectTypes.MAGAZINE, desc: <string>null },
    { code: ItemAttributeObjectTypes.GRAPH, desc: <string>null },
    { code: ItemAttributeObjectTypes.VIDEO, desc: <string>null },
    { code: ItemAttributeObjectTypes.EXTERNAL_ITEM, desc: <string>null }
  ],
  reports: [
    { code: 'COURSES_SUMMARY', desc: <string>null },
    { code: 'FRUITION_REPORT_DETAIL', desc: <string>null },
    { code: 'FRUITION_REPORT_PROGRESS', desc: <string>null },
    { code: 'DURATION_LEARNING_PLAN', desc: <string>null },
    { code: 'FRUITION_REPORT_PROGRESS_FILTERED', desc: <string>null }
  ],
  mandatoryTags: [
    ItemAttributeTypes.TITLE,
    'SUBTITLE',
    ItemAttributeTypes.AGENDA,
    ItemAttributeTypes.AIMS,
    ItemAttributeTypes.PREREQS
  ],
  usableTags: [],
  // Questo parametro controlla se l'invio delle mail dei calendar per i docenti e tutor è attivo o meno. 
  // È una via veloce per gestire anche l'invio in fase di cancellazione edizione dove al momento non abbiamo una modale di richiesta e quindi dove le mail sarebbero spedite sempre
  calendarsToTeachersIsEnabled: true
};
