/*
 * Servizio che permette le chiamate rest relative all'autenticazione
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { UrlService } from "../../shared/services/url.service";
import { UserOptions, NotificationQueue, RequiredAuth, Engagement, SenecaResponse } from '../../../cm2-commonclasses';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Observable } from "rxjs";

@Injectable()
export class AuthService {
  applicationContext: string;
  constructor(private urlService: UrlService,
    private router: Router,
    private http: HttpClient) {
    this.applicationContext = this.urlService.getApplicationUrl().baseUrl;
  }

  isAdminMode() {
    // Verifica se sono nella parte amministrativa
    if (this.router.url &&
      (this.router.url.indexOf('initiatives') !== -1
        || this.router.url.indexOf('templates') !== -1
        || this.router.url.indexOf('itemsAdmin') !== -1
        || this.router.url.indexOf('dashboard') !== -1
        || this.router.url.indexOf('locations') !== -1
        || this.router.url === '/calendar'
        || this.router.url.indexOf('/newNews') !== -1
        || this.router.url.indexOf('/editNews') !== -1
        || this.router.url.indexOf('/newsPreview') !== -1
        || this.router.url === '/news'
        || this.router.url.indexOf('suppliers') !== -1
        || this.router.url.indexOf('tags') !== -1
        || this.router.url.indexOf('scheduleModels') !== -1
        || this.router.url.indexOf('competences') !== -1
        || this.router.url === '/home'
        || this.router.url === '/adminHome'
        || this.router.url.indexOf('groups') !== -1
        || this.router.url.indexOf('impersonificationAdmin') !== -1
        || this.router.url.indexOf('manageTeachersAccounting') !== -1
        || this.router.url.indexOf('usersAdmin') !== -1
        || this.router.url.indexOf('importExport/manageImportExport') !== -1
        || this.router.url.indexOf('/manageFaq') !== -1
        || this.router.url.indexOf('/manageFaq/newFaq') !== -1
        || this.router.url.indexOf('/manageFaq/editFaq') !== -1
        || this.router.url.indexOf('/approvationPreregistrationsAdmin') !== -1
        || this.router.url.indexOf('/approvationInitiativesAdmin') !== -1
        || this.router.url.indexOf('/competenceProfiles') !== -1
      )) {
      return true;
    } else {
      return false;
    }
  }

  getRedirectUrlAfterFirstPollingForTaker = () => {
    let redirectUrlAfterFirstPolling = '';
    if (environment.canAccessUserHome) {
      redirectUrlAfterFirstPolling = '/takers/home';
    } else if (environment.canAccessUserLibrary) {
      redirectUrlAfterFirstPolling = '/takers/library/0';
    } else if (environment.canAccessUserTrainingBooklet) {
      redirectUrlAfterFirstPolling = '/takers/trainingBooklet';
    } else if (environment.canAccessUserCalendar) {
      redirectUrlAfterFirstPolling = '/takers/calendar';
    } else if (environment.canAccessUserNews) {
      redirectUrlAfterFirstPolling = '/takers/news';
    } else if (environment.canAccessUserCatalog) {
      redirectUrlAfterFirstPolling = '/takers/catalog/0';
    } else if (environment.canAccessUserNotifications) {
      redirectUrlAfterFirstPolling = '/takers/notifications';
    } else if (environment.canAccessUserProfile) {
      redirectUrlAfterFirstPolling = '/takers/profile';
    } else {
      redirectUrlAfterFirstPolling = '/403';
    }
    return redirectUrlAfterFirstPolling;
  }

  isTeacherMode(authCheck?: boolean) {
    if (authCheck) { // Check auth visualizzazione sezioni
      return (sessionStorage.getItem('isTeacher') && sessionStorage.getItem('isTeacher') === "true");
    } else { // Check redirect url
      return window.location.href.indexOf("/teachers/") > 0
    }
  }

  loginRemoteTokenWithCheck = (tenant: string, remoteToken: string, remoteCheck: string, deviceType: string, userAgent: string) => {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('tenant', tenant);
    httpParams = httpParams.append('remoteToken', remoteToken);
    httpParams = httpParams.append('remoteCheck', remoteCheck);
    httpParams = httpParams.append('deviceType', deviceType);
    httpParams = httpParams.append('userAgent', userAgent);
    return this.http.post<SenecaResponse<string>>(this.applicationContext + 'rest-api/corporateacademy-mediator/login-remote-token-with-check', {
      tenant: tenant,
      remoteToken: remoteToken,
      remoteCheck: remoteCheck,
      deviceType: deviceType,
      userAgent: userAgent
    });
  }

  // Verifica se l'utente loggato può accedere alla gestione degli item
  canAccessManageItem(loggedUser) {
    let canView: boolean = false;
    if (loggedUser && loggedUser.auths && loggedUser.auths.length) {
      for (let i = 0, authsLength = loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = loggedUser.auths[i];
        if (currentAuth === RequiredAuth.CORPORATEACADEMY_MANAGE_ITEM) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  // Verifica se l'utente loggato può accedere alla gestione dei learning plan
  canManageLearningPlan(loggedUser) {
    let canView: boolean = false;
    if (loggedUser && loggedUser.auths && loggedUser.auths.length) {
      for (let i = 0, authsLength = loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = loggedUser.auths[i];
        if (currentAuth === RequiredAuth.CORPORATEACADEMY_MANAGE_LEARNING_PLAN) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  // Servizio che tenta il login
  login(email: string, password: string, device?: string, userAgent?: string): any {
    return this.http.post<SenecaResponse<string>>(this.applicationContext + 'rest-api/corporateacademy-mediator/login-local-password', { email: email, password: password, langCode: 'it', deviceType: device, userAgent: userAgent });
  }

  // Servizio che tenta il login per il docente
  loginSupplierPerson(email: string, password: string, device?: string, userAgent?: string): any {
    return this.http.post<SenecaResponse<string>>(this.applicationContext + 'rest-api/corporateacademy-mediator/login-supplier-person', { username: email, password: password, langCode: 'it', deviceType: device, userAgent: userAgent });
  }

  // Servizio che setta una nuova password
  setNewPassword(oldPassword: string, newPassword: string): any {
    return this.http.post<SenecaResponse<string>>(this.applicationContext + 'rest-api/corporateacademy-mediator/set-new-password', { oldPassword: oldPassword, newPassword: newPassword });
  }

  // Servizio che setta una nuova password
  selfUpdateSupplierPersonPassword(oldPassword: string, newPassword: string): any {
    return this.http.post<SenecaResponse<string>>(this.applicationContext + 'rest-api/corporateacademy-mediator/self-update-supplier-person-password', { oldPassword: oldPassword, newPassword: newPassword });
  }

  // Marca tutte le notifiche come lette
  markAllNotificationsAsRead(): any {
    return this.http.post<SenecaResponse<any>>(this.applicationContext + 'rest-api/corporateacademy-mediator/mark-all-notification-as-read', {});
  }

  // Marca la notifica come letta
  markNotificationAsRead(notificationQueueId: string): any {
    return this.http.post<SenecaResponse<any>>(this.applicationContext + 'rest-api/corporateacademy-mediator/mark-notification-as-read', { notificationQueueId: notificationQueueId });
  }

  // Metodi per la gestione dei diritti e autenticazione
  isUserAuthorized(requiredAuthId: string, auths: string[]) {
    let isAuthorized: boolean = false;

    for (let i = 0, authsLength = auths.length; i < authsLength; i++) {
      let currentAuth = auths[i];
      if (currentAuth === requiredAuthId) {
        isAuthorized = true;
        break;
      }
    }

    return isAuthorized;
  }

  // Crea un engagement per il tracciamento della navigazione
  createNavigationEngagement(newEngagement: Engagement) {
    return this.http.post<SenecaResponse<any>>(this.applicationContext + 'rest-api/corporateacademy-mediator/create-navigation-engagement', {
      newEngagement: newEngagement
    });
  }

  // Servizio che recupera un token nuovo
  confirmUserEmail(userId: string, validationToken: string, intiPasswordRecovery?: boolean): any {
    return this.http.post<SenecaResponse<string>>(this.applicationContext + 'rest-api/corporateacademy-mediator/confirm-user-email',
      {
        userId: userId,
        validationToken: validationToken,
        intiPasswordRecovery: intiPasswordRecovery
      }
    );
  }

  // Servizio recupera la password dell'utente
  recoverUserPassword(newPassword: string, userId: string, validationToken: string): any {
    return this.http.post<SenecaResponse<string>>(this.applicationContext + 'rest-api/corporateacademy-mediator/recover-user-password', {
      newPassword: newPassword,
      userId: userId,
      validationToken: validationToken
    });
  }

  // Servizio che inizia il ripristino della password
  initPasswordRecovery(email: string): any {
    return this.http.post<SenecaResponse<string>>(this.applicationContext + 'rest-api/corporateacademy-mediator/init-password-recovery', {
      email: email,
      isCandidateJourney: true
    });
  }

  // Servizio che tenta il login per i taker
  loginTaker(searchedText: string, device?: string, userAgent?: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText);
    httpParams = httpParams.append('device', device);
    httpParams = httpParams.append('userAgent', userAgent);
    return this.http.get<SenecaResponse<any>>(this.applicationContext + 'rest-api/corporateacademy-mediator/search-user-for-public', {
      params: httpParams
    });
  }

  updateUserOptions(userOptions: UserOptions) {
    return this.http.post<SenecaResponse<string>>(this.applicationContext + 'rest-api/corporateacademy-mediator/update-userOptions', {
      userOptions: userOptions
    });
  }

  uploadAvatar(uploadObj) {
    let formData = new FormData();
    formData.append('file', uploadObj.file);
    formData.append('filename', 'newAvatar.png');
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationContext + 'rest-api/corporateacademy-mediator/upload-avatar', formData, options);
    return this.http.request(req);
  }

  // Servizio che recupera il JWT token dato un tiny token
  getJWTToken(): any {
    return this.http.get<SenecaResponse<string>>(this.applicationContext + 'rest-api/corporateacademy-mediator/get-full-jwt-token');
  }

  // Recupera una lista di notifiche
  getNotificationList(fromRecord: number, numRecords: number, getOnlyUnread?: boolean, getOnlyRead?: boolean): any {
    let from = fromRecord && fromRecord.toString();
    let to = numRecords && numRecords.toString();
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    if (getOnlyUnread) {
      httpParams = httpParams.append('getOnlyUnread', getOnlyUnread.toString());
    }
    if (getOnlyRead) {
      httpParams = httpParams.append('getOnlyRead', getOnlyRead.toString());
    }
    httpParams = httpParams.append('fromRecord', from);
    httpParams = httpParams.append('numRecords', to);
    return this.http.get<SenecaResponse<NotificationQueue[]>>(this.applicationContext + 'rest-api/corporateacademy-mediator/get-notification-list/' + from + '/' + to, {
      params: httpParams
    });
  }

  // Recupera il count degli item in bozza
  countNotifications(searchedText?: string, getOnlyUnread?: boolean, getOnlyRead?: boolean): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText || '');
    if (getOnlyUnread) {
      httpParams = httpParams.append('getOnlyUnread', getOnlyUnread.toString());
    }
    if (getOnlyRead) {
      httpParams = httpParams.append('getOnlyRead', getOnlyRead.toString());
    }
    return this.http.get<SenecaResponse<number>>(this.applicationContext + 'rest-api/corporateacademy-mediator/count-notifications', {
      params: httpParams
    });
  }

  // Servizio che recupera un token nuovo
  renewToken(sessionStorageToken: string, forceRefreshUser?: boolean): any {
    return this.http.post<SenecaResponse<string>>(this.applicationContext + 'rest-api/corporateacademy-mediator/renew-token', { forceRefreshUser: forceRefreshUser }, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + sessionStorageToken) });
  }

  // Servizio che conrolla se l'utente ha dei sottoposti
  checkIfUserHasSubordinates() {
    return this.http.get<SenecaResponse<string>>(this.applicationContext + 'rest-api/corporateacademy-mediator/get-manager-role-type');
  }

  /**
   * Function that returns the list of all assignable authorization profiles along with the ones that are enable to the passed user.
   * @param {string} userId - Required: The user to analyze
   * @return {{profileId: string, active: boolean}[]} The list of all assignable profiles with the evidence if they are assigned to the user
   */
  getUserProfiles(userId: string): Observable<SenecaResponse<{ profileId: string, active: boolean }[]>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('userId', userId);

    return this.http.get<SenecaResponse<{ profileId: string, active: boolean }[]>>(this.applicationContext + 'rest-api/corporateacademy-mediator/get-user-profiles', {
      params: httpParams
    });
  }

  /**
   * Function that saves authorization profiles of a user.
   * @param {string} userId - Required: The user to analyze
   * @param {{profileId: string, active: boolean}[]} profiles - Required: The list of all profiles with a boolean set if the user has that profile
   * @return {{profileId: string, active: boolean}[]} The list of all assignable profiles with the evidence if they are assigned to the user
   */
  saveUserProfiles(userId: string, profiles: { profileId: string, active: boolean }[]): Observable<SenecaResponse<{ profileId: string, active: boolean }[]>> {
    return this.http.post<SenecaResponse<{ profileId: string, active: boolean }[]>>(this.applicationContext + 'rest-api/corporateacademy-mediator/save-user-profiles', {
      userId,
      profiles
    });
  }

  // Crea in base al token l'ssorqtp
  crateRetrieveTokenAfterLogin(): any {
    return this.http.post<SenecaResponse<any>>(this.applicationContext + 'rest-api/corporateacademy-mediator/create-retrieve-token-after-login', null);
  }

  // Recupera i ruoli da supplier dell'utente
  retrieveSupplierRoles() {
    return this.http.get<SenecaResponse<string[]>>(this.applicationContext + 'rest-api/corporateacademy-mediator/get-supplier-person-type');
  }

  // Ritrova il token dall'ssortkqp
  retrieveTokenAfterLogin(retrieveKey: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('retrieveKey', retrieveKey);
    return this.http.get<SenecaResponse<any>>(this.applicationContext + 'rest-api/corporateacademy-mediator/retrieve-token-after-login', {
      params: httpParams
    });
  }

  getTenantAnagConfig() {
    return this.http.get<SenecaResponse<string[]>>(this.applicationContext + 'rest-api/corporateacademy-mediator/get-tenant-anag-config');
  }
}
