import { Component, Input } from '@angular/core';

@Component({
  selector: 'vertical-widget-placeholder',
  templateUrl: './vertical-widget-placeholder.component.html',
  styleUrls: ['./vertical-widget-placeholder.component.scss']
})
export class VerticalWidgetPlaceholderComponent {
  @Input() numberOfData: number;

  constructor() {
  }
}
