// Guard per tracciare la navigazione dell'utente loggato

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Engagement, EngagementDetail, EngagementDetailKeys } from 'src/cm2-commonclasses';
import { UrlService } from 'src/app/shared/services/url.service';

@Injectable()
export class EngagementNavigationGuard implements CanActivate {

  constructor(private authService: AuthService,
    private urlService: UrlService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let fullUrl = state.url;
    let url = state.url;
    let urlParams: string[];

    if (url.includes("?")) {
      url = url.substr(0, url.indexOf('?'));
    } else {
      urlParams = route.url.toString().split(';')[0].split(',');

      if (urlParams.length > 1) {
        urlParams.shift(); // Remove first element which is page name

        // Get entire splitting on each param
        let fullUrlSegments: string[] = state.url.split('/');
        // Remove number of params from full URL
        fullUrlSegments = fullUrlSegments.slice(0, fullUrlSegments.length - urlParams.length);

        url = fullUrlSegments.join('/');
      }
    }
    let navigationSessionId = sessionStorage.getItem('NAVIGATION_SESSION_ID');
    if (url && navigationSessionId) {
      let newEngagement = new Engagement();
      let engagementDetails = [];

      // Aggiungo i dettagli
      let newNavigationSessionIdEngagementDetail = new EngagementDetail();
      newNavigationSessionIdEngagementDetail.detailKey = EngagementDetailKeys.NAVIGATION_SESSION_ID;
      newNavigationSessionIdEngagementDetail.detailValue = navigationSessionId;
      let newNavigationDomainEngagementDetail = new EngagementDetail();
      newNavigationDomainEngagementDetail.detailKey = EngagementDetailKeys.NAVIGATION_SESSION_DOMAIN;
      newNavigationDomainEngagementDetail.detailValue = this.urlService.getApplicationUrl().domain;
      let newNavigationDestinationCompleteUrlEngagementDetail = new EngagementDetail();
      newNavigationDestinationCompleteUrlEngagementDetail.detailKey = EngagementDetailKeys.NAVIGATION_SESSION_COMPLETE_URL;
      fullUrl = '/#/' + fullUrl;
      newNavigationDestinationCompleteUrlEngagementDetail.detailValue = fullUrl;
      let newNavigationDestinationShortUrlEngagementDetail = new EngagementDetail();
      newNavigationDestinationShortUrlEngagementDetail.detailKey = EngagementDetailKeys.NAVIGATION_SESSION_SHORT_URL;
      url = '/#/' + url;
      newNavigationDestinationShortUrlEngagementDetail.detailValue = url;
      engagementDetails.push(newNavigationSessionIdEngagementDetail, newNavigationDomainEngagementDetail, newNavigationDestinationCompleteUrlEngagementDetail, newNavigationDestinationShortUrlEngagementDetail);
      newEngagement.engagementDetails = engagementDetails;

      this.authService.createNavigationEngagement(newEngagement)
        .subscribe(data => {
          return true;
        })
    }
    return true;
  }
}
