import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import * as fromApp from '../../ngrx/app.reducers';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TeachersGuard implements CanActivate {

  constructor(private store: Store<fromApp.AppState>,
    private authService: AuthService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return combineLatest(
      [this.store.pipe(select(fromApp.getLoggedUser)),
      this.store.pipe(select(fromApp.isFetchingLangs)),
      this.store.pipe(select(fromApp.getApplicationLang))]
    )
      .pipe(
        map(([loggedUser, isFetchingLangs, applicationLang]) => {
          if (
            loggedUser && loggedUser.auths && loggedUser.auths.length
            &&
            (
              (environment.canAccessManageTeachersAccounting || environment.canAccessTeacherMode)
              // && this.authService.isUserAuthorized(RequiredAuth.CORPORATEACADEMY_SUPPLIER_PERSON, loggedUser.auths)
              && this.authService.isUserAuthorized('CORPORATEACADEMY_SUPPLIER_PERSON', loggedUser.auths)
            )
          ) {
            return true;
          } else {
            if (isFetchingLangs || !loggedUser || !applicationLang) {
              return false;
            }
            this.router.navigate(['/403']);
            return false;
          }
        })
      );
  }
}
