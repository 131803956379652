<div [hidden]="!isLoaded" class="cursor-pointer" (click)="changeValue('1')">
  <div class="card-image" fxLayoutFill>
    <img src="assets/img/test_card_1.jpeg" (load)="imgRendered();"
      alt="{{'generic.NEW_F'|translate}} {{'generic.INITIATIVE'|translate}}"
      title="{{'generic.NEW_F'|translate}} {{'generic.INITIATIVE'|translate}}">
    <div class="card-content">
      <h1 class="card-header">
        <span translate="generic.NEW_F"></span><br>
        <span translate="generic.INITIATIVE"></span>
      </h1>
    </div>
    <div class="card-description">
      <p translate="createInitiativeCard.fromSyllabus.FOOTER"></p>
    </div>
  </div>
  <div class="md-radio text-center">
    <input type="radio" name="options" role="radio" [ngModel]="selectedCreationPossibility.options" value="1">
    <label></label>
  </div>
</div>

<div [hidden]="isLoaded">
  <create-initiative-card-placeholder></create-initiative-card-placeholder>
</div>