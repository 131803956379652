/*
* Componente che crea una linea di progressione
*/

import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {
  @Input() value: number = 0;
  constructor() {
  }
}