import { environment } from "src/environments/environment";
import { ItemAttributeTypes } from "../../../cm2-commonclasses";
import { ExtendedTag } from "./extended-tag.model";
/*
* Modello che definisce un Syllabus. Alcuni parametri sono scritti in maiuscolo poiché si identificano con i tipi di attributi o Tag salvati negli Item.
* Questo rendere meno complesso gestire il recupero dei dati (vedi ad esempio il metodo formatSyllabus() nel componente initiative-edit)
*/


export class Syllabus {
  public TITLE: SyllabusItem;
  public SUBTITLE: SyllabusItem;
  public AGENDA: SyllabusItem; // Programma
  public AIMS: SyllabusItem; // Obiettivi
  public PREREQS: SyllabusItem;
  public APPROACH: SyllabusItem;
  public DURATION: SyllabusItem;
  public TUTOR_CONTACT: SyllabusItem;
  public TEACHER_CONTACT: SyllabusItem;
  public SUPPORT_CONTACT: SyllabusItem;
  public CUSTOM_ARGUMENT: SyllabusItem; // Argomenti attestato
  public TECH_COMPETENCES: SyllabusItem;
  public SOFT_COMPETENCES: SyllabusItem;
  public PRICE: SyllabusItem;
  public LEVELS: SyllabusItem;
  public LANG: SyllabusItem;
  public ARGUMENTS: SyllabusItem;
  public FUNCTIONAL_AREAS: SyllabusItem;
  public CLUSTERS: SyllabusItem;
  public STREAMS: SyllabusItem;
  public ADMIN_TAGS: SyllabusItem;
  public PROTOCOL_NUMBER: SyllabusItem;
  public PROTOCOL_DATE: SyllabusItem;
  public MANDATORY_COURSE: SyllabusItem;
  public USE_SUPPLIER_PARTNER: SyllabusItem;
  public PARTNER: SyllabusItem;
  public SUPPLIER_PARTNER: SyllabusItem;
  public COMPETENCE_LEVEL_REQUIRED: SyllabusItem;
  public SAP_CODICE_TIPO_EVENTO: SyllabusItem;
  

  constructor(
    TITLE: SyllabusItem,
    SUBTITLE: SyllabusItem,
    AGENDA: SyllabusItem,
    AIMS: SyllabusItem,
    PREREQS: SyllabusItem,
    APPROACH: SyllabusItem,
    PRICE: SyllabusItem,
    TECH_COMPETENCES: SyllabusItem,
    SOFT_COMPETENCES: SyllabusItem,
    TUTOR_CONTACT: SyllabusItem,
    TEACHER_CONTACT: SyllabusItem,
    SUPPORT_CONTACT: SyllabusItem,
    CUSTOM_ARGUMENT: SyllabusItem,
    DURATION: SyllabusItem,
    LEVELS: SyllabusItem,
    LANG: SyllabusItem,
    ARGUMENTS: SyllabusItem,
    FUNCTIONAL_AREAS: SyllabusItem,
    CLUSTERS: SyllabusItem,
    STREAMS: SyllabusItem,
    ADMIN_TAGS: SyllabusItem,
    PROTOCOL_NUMBER: SyllabusItem,
    PROTOCOL_DATE: SyllabusItem,
    MANDATORY_COURSE: SyllabusItem,
    USE_SUPPLIER_PARTNER: SyllabusItem,
    PARTNER: SyllabusItem,
    SUPPLIER_PARTNER: SyllabusItem,
    COMPETENCE_LEVEL_REQUIRED: SyllabusItem,
    SAP_CODICE_TIPO_EVENTO: SyllabusItem
  ) {
    this.TITLE = TITLE;
    this.SUBTITLE = SUBTITLE;
    this.AGENDA = AGENDA;
    this.AIMS = AIMS;
    this.PREREQS = PREREQS;
    this.APPROACH = APPROACH;
    this.DURATION = DURATION;
    this.TECH_COMPETENCES = TECH_COMPETENCES;
    this.SOFT_COMPETENCES = SOFT_COMPETENCES;
    this.TUTOR_CONTACT = TUTOR_CONTACT;
    this.TEACHER_CONTACT = TEACHER_CONTACT;
    this.SUPPORT_CONTACT = SUPPORT_CONTACT;
    this.CUSTOM_ARGUMENT = CUSTOM_ARGUMENT;
    this.LEVELS = LEVELS;
    this.LANG = LANG;
    this.ARGUMENTS = ARGUMENTS;
    this.FUNCTIONAL_AREAS = FUNCTIONAL_AREAS;
    this.CLUSTERS = CLUSTERS;
    this.STREAMS = STREAMS;
    this.PRICE = PRICE;
    this.ADMIN_TAGS = ADMIN_TAGS;
    this.PROTOCOL_NUMBER = PROTOCOL_NUMBER;
    this.PROTOCOL_DATE = PROTOCOL_DATE;
    this.MANDATORY_COURSE = MANDATORY_COURSE;
    this.USE_SUPPLIER_PARTNER = USE_SUPPLIER_PARTNER;
    this.PARTNER = PARTNER;
    this.SUPPLIER_PARTNER = SUPPLIER_PARTNER;
    this.COMPETENCE_LEVEL_REQUIRED = COMPETENCE_LEVEL_REQUIRED;
    this.SAP_CODICE_TIPO_EVENTO = SAP_CODICE_TIPO_EVENTO;
  }
}

/*
* Modello che definisce un oggetto dentro al Syllabus
*/
export class SyllabusItem {
  public data: any;
  public editable: boolean;
  public active?: boolean;
  constructor(data: any, editable: any, active?: boolean) {
    this.data = data;
    this.editable = editable;
    this.active = active;
  }
}

export function newSyllabusForFE() {
  let tmp: Partial<Syllabus> = {};
  for (let i = 0; i < SyllabusUtil.CONSTRUCTOR_TAG.length; i++) {
    let currentTag = SyllabusUtil.CONSTRUCTOR_TAG[i];
    tmp[currentTag] = new SyllabusItem({}, false, false);
    if (environment.usableTags.find((tag: string) => tag == currentTag)?.length) {
      tmp[currentTag].active = true;
    }
  }
  return <Syllabus>tmp;

  // return new Syllabus({ data: '', editable: false }, { data: '', editable: false }, { data: null, editable: false }, { data: null, editable: false }, { data: null, editable: false }, { data: null, editable: false }, { data: null, editable: false }, { data: null, editable: false }, { data: [], editable: false }, { data: [], editable: false }, { data: null, editable: false }, { data: null, editable: false }, { data: [], editable: false }, { data: null, editable: false }, { data: null, editable: false }, { data: null, editable: false }, { data: [], editable: false }, { data: null, editable: false }, { data: null, editable: false }, { data: null, editable: false }, { data: null, editable: false }, { data: [], editable: false }, { data: null, editable: false }, { data: null, editable: false }, { data: null, editable: false }, { data: null, editable: false }, { data: null, editable: false }, { data: null, editable: false }, { data: [], editable: false });
}

/*
* Syllabus utility
*/
export class SyllabusUtil {
  static CONSTRUCTOR_TAG = [
    'TITLE',
    'SUBTITLE',
    'AGENDA',
    'AIMS',
    'PREREQS',
    'APPROACH',
    'DURATION',
    'TUTOR_CONTACT',
    'TEACHER_CONTACT',
    'SUPPORT_CONTACT',
    'CUSTOM_ARGUMENT',
    'TECH_COMPETENCES',
    'SOFT_COMPETENCES',
    'PRICE',
    'LEVELS',
    'LANG',
    'REQUIRED_COMPETENCES',
    'ARGUMENTS',
    'FUNCTIONAL_AREAS',
    'CLUSTERS',
    'STREAMS',
    'ADMIN_TAGS',
    'PROTOCOL_NUMBER',
    'PROTOCOL_DATE',
    'MANDATORY_COURSE',
    'USE_SUPPLIER_PARTNER',
    'PARTNER',
    'SUPPLIER_PARTNER',
    'COURSE_CATEGORY',
    'COMPETENCE_LEVEL_REQUIRED',
    'SAP_CODICE_TIPO_EVENTO'
  ]

  static ATTRIBUTES_WITH_LANG = {
    'AGENDA': 1,
    'AIMS': 1,
    'PREREQS': 1,
    'APPROACH': 1,
    'DURATION': 1,
    'PARTNER': 1,
    'TUTOR_CONTACT': 1,
    'TEACHER_CONTACT': 1,
    'SUPPORT_CONTACT': 1,
    'PRICE': 1,
    'PROTOCOL_NUMBER': 1,
    'PROTOCOL_DATE': 1,
    'MANDATORY_COURSE': 1,
    'USE_SUPPLIER_PARTNER': 1,
    'SUPPLIER_PARTNER': 1
  };

  static tagsFieldsMapping = [
    ItemAttributeTypes.TECH_COMPETENCES, ItemAttributeTypes.SOFT_COMPETENCES, ItemAttributeTypes.CLUSTERS,
    ItemAttributeTypes.LEVELS, ItemAttributeTypes.LANG, ItemAttributeTypes.ARGUMENTS, ItemAttributeTypes.FUNCTIONAL_AREAS,
    ItemAttributeTypes.STREAMS, ItemAttributeTypes.ADMIN_TAGS, ItemAttributeTypes.COURSE_CATEGORY
  ];

  static isAttributeWithLang(key: string) {
    return !!SyllabusUtil.ATTRIBUTES_WITH_LANG[key];
  }

  // Pulisce il syllabus da ogni suo riferimento all'itemId
  static clearSyllabusFromItemId(syllabus) {
    if (syllabus) {
      syllabus.itemId = null;
      if (syllabus.itemAttributes && syllabus.itemAttributes.length) {
        for (let i = 0, attributesLength = syllabus.itemAttributes.length; i < attributesLength; i++) {
          let currentAttribute = syllabus.itemAttributes[i];
          currentAttribute.itemId = null;
          if (currentAttribute.attributeLangs) {
            for (let j = 0, attributesLangsLength = currentAttribute.attributeLangs.length; j < attributesLangsLength; j++) {
              let currentAttributeLang = currentAttribute.attributeLangs[j];
              currentAttributeLang.itemId = null;
            }
          }
        }
      }
      if (syllabus.itemLangs && syllabus.itemLangs.length) {
        for (let m = 0, langsLength = syllabus.itemLangs.length; m < langsLength; m++) {
          let currentLang = syllabus.itemLangs[m];
          currentLang.itemId = null;
        }
      }
    }
  }

  // Formatta il syllabus
  static formatSyllabus(syllabus, frontEndSyllabus, lang) {
    if (syllabus && syllabus.itemAttributes && syllabus.itemAttributes.length) {
      for (let i = 0, attrsLength = syllabus.itemAttributes.length; i < attrsLength; i++) {
        const currentAttribute = syllabus.itemAttributes[i];
        if (currentAttribute.attributeType === ItemAttributeTypes.TECH_COMPETENCES || currentAttribute.attributeType === ItemAttributeTypes.SOFT_COMPETENCES || currentAttribute.attributeType === ItemAttributeTypes.CLUSTERS
          || currentAttribute.attributeType === ItemAttributeTypes.LEVELS || currentAttribute.attributeType === ItemAttributeTypes.LANG
          || currentAttribute.attributeType === ItemAttributeTypes.FUNCTIONAL_AREAS || currentAttribute.attributeType === ItemAttributeTypes.COURSE_CATEGORY
          || currentAttribute.attributeType === ItemAttributeTypes.ADMIN_TAGS || currentAttribute.attributeType === ItemAttributeTypes.SAP_CODICE_TIPO_EVENTO) {
          // Technial/Soft Skill: onde evitare una chiama inutile ai servizi per recuperare il dato del Tag,
          //  lo ricostruisco manualmente e lo aggiungo ai Tag selezionati
          const newTagObj: ExtendedTag = <ExtendedTag>{
            active: true,
            indexed: true,
            tagAttributes: [],
            tagChildren: [],
            attributeWeight: currentAttribute.attributeWeight && currentAttribute.attributeWeight.toString(),
            tagId: currentAttribute.referenceId,
            tagLangs: currentAttribute.crossReferenceObject && currentAttribute.crossReferenceObject.tagLangs,
            tagTenants: currentAttribute.crossReferenceObject && currentAttribute.crossReferenceObject.tagTenants,
            tagType: currentAttribute.attributeType,
            title: currentAttribute.crossReferenceObject && currentAttribute.crossReferenceObject.title,
          };

          if (currentAttribute.attributeType === ItemAttributeTypes.SAP_CODICE_TIPO_EVENTO) {
            frontEndSyllabus[currentAttribute.attributeType] = newTagObj;
          }
          
          if (currentAttribute.attributeType === ItemAttributeTypes.LEVELS || currentAttribute.attributeType === ItemAttributeTypes.FUNCTIONAL_AREAS
            || currentAttribute.attributeType === ItemAttributeTypes.CLUSTERS || currentAttribute.attributeType === ItemAttributeTypes.COURSE_CATEGORY) {
          } else
            // se non trovo il tag lo aggiungo
            if (!frontEndSyllabus[currentAttribute.attributeType].data?.length || !frontEndSyllabus[currentAttribute.attributeType].data.find((tag: ExtendedTag) => {
              return currentAttribute.referenceId === tag.tagId;
            })) {
              if (!frontEndSyllabus[currentAttribute.attributeType].data) {
                frontEndSyllabus[currentAttribute.attributeType].data = [];
              }
              frontEndSyllabus[currentAttribute.attributeType].data.push(newTagObj);
            }
        } else if (currentAttribute.attributeType == ItemAttributeTypes.STREAMS) {
          frontEndSyllabus.STREAMS.data = currentAttribute.crossReferenceObject;
        } else if (currentAttribute.attributeType == ItemAttributeTypes.ARGUMENTS) {
          frontEndSyllabus.ARGUMENTS.data = currentAttribute.crossReferenceObject;
        } else if (currentAttribute.attributeType == ItemAttributeTypes.COURSE_CATEGORY) {
          frontEndSyllabus.COURSE_CATEGORY.data = currentAttribute.crossReferenceObject;
        } else if (currentAttribute.attributeType == ItemAttributeTypes.COMPETENCE_LEVEL_REQUIRED) {
          let crossReferenceObject = currentAttribute.crossReferenceObject;

          const langCode = (lang && lang.langCode) || lang || 'it';
          let currentAttributeLang;
          if (crossReferenceObject) {
            for (let k = 0, langsLength = crossReferenceObject.tagLangs.length; k < langsLength; k++) {
              if (crossReferenceObject.tagLangs[k].langCode === langCode) {
                currentAttributeLang = {
                  attributeOrder: currentAttribute.attributeOrder,
                  ...crossReferenceObject.tagLangs[k]
                };
                break;
              }
            }
          }

          if (!frontEndSyllabus[currentAttribute.attributeType].data) {
            frontEndSyllabus[currentAttribute.attributeType].data = [];
          }

          // Se non trovo l'attributo lo aggiungo
          if (frontEndSyllabus[currentAttribute.attributeType].data &&
            (!frontEndSyllabus[currentAttribute.attributeType].data.length || !frontEndSyllabus[currentAttribute.attributeType].data.find((attrLang) => {
              return attrLang && attrLang.attributeId && currentAttribute.attributeId === attrLang.attributeId;
            }))) {
            if (!Array.isArray(frontEndSyllabus[currentAttribute.attributeType].data)) {
              frontEndSyllabus[currentAttribute.attributeType].data = [];
            }
            frontEndSyllabus[currentAttribute.attributeType].data.push(currentAttributeLang);
          }
        } else if (currentAttribute.attributeType === ItemAttributeTypes.CUSTOM_ARGUMENT
          && currentAttribute.attributeLangs && currentAttribute.attributeLangs.length) {
          const langCode = (lang && lang.langCode) || lang || 'it';
          let currentAttributeLang;

          for (let k = 0, langsLength = currentAttribute.attributeLangs.length; k < langsLength; k++) {
            if (currentAttribute.attributeLangs[k].langCode === langCode) {
              currentAttributeLang = {
                attributeOrder: currentAttribute.attributeOrder,
                ...currentAttribute.attributeLangs[k]
              };
              break;
            }
          }
          if (!frontEndSyllabus[currentAttribute.attributeType].data) {
            frontEndSyllabus[currentAttribute.attributeType].data = [];
          }
          // Se non trovo l'attributo lo aggiungo
          if (frontEndSyllabus[currentAttribute.attributeType].data &&
            (!frontEndSyllabus[currentAttribute.attributeType].data.length || !frontEndSyllabus[currentAttribute.attributeType].data.find((attrLang) => {
              return attrLang && attrLang.attributeId && currentAttribute.attributeId === attrLang.attributeId;
            }))) {
            if (!Array.isArray(frontEndSyllabus[currentAttribute.attributeType].data)) {
              frontEndSyllabus[currentAttribute.attributeType].data = [];
            }
            frontEndSyllabus[currentAttribute.attributeType].data.push(currentAttributeLang);
          }
        }
      }
    }
  }
}
