import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromApp from "../../ngrx/app.reducers";
import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import { SenecaResponse, Tag } from "src/cm2-commonclasses";

export interface Category {
  availableParents: Pick<Tag, 'title' | 'tagId'>[];
  mandatoryParent: boolean;
  parentTag?: Tag;
  tag: Tag & { notEditable: boolean };
  noParentAllowed:boolean
}

@Injectable()
export class CategoryService {
  applicationData: GlobalApplicationData;

  constructor(
    private store: Store<fromApp.AppState>,
    private http: HttpClient
  ) {
    let globalApplicationData$: Observable<GlobalApplicationData> =
      this.store.select(fromApp.getGlobalApplicationData);
    globalApplicationData$.subscribe((globalApplicationData) => {
      this.applicationData = globalApplicationData;
    });
  }

  getEditableTagTypes() {
    return this.http.get<SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/corporateacademy-mediator/get-all-editable-tagtypes"
    );
  }

  searchCategories(
    title: string,
    tagType: string,
    fromRecord: number,
    numRecords: number,
    allData: boolean
  ) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("title", title || "");
    httpParams = httpParams.append("type", tagType);
    httpParams = httpParams.append(
      "fromRecord",
      fromRecord && fromRecord.toString()
    );
    httpParams = httpParams.append(
      "numRecords",
      numRecords && numRecords.toString()
    );
    httpParams = httpParams.append("allData", allData.toString());
    return this.http.get<SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/corporateacademy-mediator/search-Categories",
      {
        params: httpParams,
      }
    );
  }

  countCategories(title: string, type: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("title", title || "");
    httpParams = httpParams.append("type", type[0]);
    return this.http.get<SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/corporateacademy-mediator/count-Categories",
      {
        params: httpParams,
      }
    );
  }

  /**
   *
   * @param tagType
   * @param tagName
   * @param parentTagId For some tagType the parentTagId is mandatory
   * @returns
   */
  createCategory(
    tagType: string,
    tagName: string,
    parentTagId?: string | null
  ) {
    let payload = { tagType, title: tagName };
    if (parentTagId) {
      payload["parentTagId"] = parentTagId;
    }
    return this.http.post<SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/corporateacademy-mediator/create-category",
      payload
    );
  }

  getCategoryParentsAndRequirements(tagType: string) {
    return this.http.get<
      SenecaResponse<{ availableParents: any[]; mandatoryParent: boolean; noParentAllowed: boolean }>
    >(
      `${this.applicationData.applicationContext}rest-api/corporateacademy-mediator/get-available-parents-for-category`,
      {
        params: {
          tagType,
        },
      }
    );
  }

  /**
   *
   * @param tagId
   * @param tagName
   * @param parentTagId pass null if you want to remove parent tag
   * @returns
   */
  updateCategory(tagId: string, tagName?: string, parentTagId?: string | null) {
    let payload = { tagId };
    if (tagName) {
      payload["title"] = tagName;
    }
    if (parentTagId) {
      payload["parentTagId"] = parentTagId;
    }
    return this.http.post<SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/corporateacademy-mediator/update-category",
      payload
    );
  }

  getCategoryById(tagId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("tagId", tagId);
    return this.http.get<SenecaResponse<Category>>(
      this.applicationData.applicationContext +
        "rest-api/corporateacademy-mediator/get-category-by-id",
      {
        params: httpParams,
      }
    );
  }

  deleteCategory(tagId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("tagId", tagId);
    return this.http.delete<SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/corporateacademy-mediator/delete-category",
      {
        params: httpParams,
      }
    );
  }
}
