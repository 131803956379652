import { Injectable } from "@angular/core";

declare const google: any;

@Injectable()
export class NgxAutocomPlaceService {

  autocompleteService = new google.maps.places.AutocompleteService();
  placeService = new google.maps.places.PlacesService(document.createElement('div'));
  sessionToken?: any; // google.maps.places.AutocompleteSessionToken

  constructor() { }

  /**
   *
   * @param value
   * @param options
   * @return {google.maps.places.AutocompletePrediction[] | google.maps.places.PlacesServiceStatus}
   */
  getPredictions(value: any, options: any): Promise<any[] | null> {
    if (!this.sessionToken) {
      this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    }
    const request = {
      input: value,
      sessionToken: this.sessionToken,
      ...options
    };
    return new Promise((resolve, reject) => {
      this.autocompleteService.getPlacePredictions(request,
        (results: any[] | null, status: any) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK && status !== google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
            reject(status);
            return;
          } else {
            resolve(results);
          }
        })
    });
  }

  /**
   *
   * @param placeId
   * @return {google.maps.places.PlaceResult}
   */
  getPlaceDetails(placeId: string): Promise<any | null> {
    return new Promise((resolve, reject) => {
      this.placeService.getDetails({
        placeId,
        sessionToken: this.sessionToken,
      }, (result: any | null, status: any) => {
        this.sessionToken = undefined;
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          reject(status);
          return;
        }
        resolve(result);
      });
    });
  }
}
