/*
 * Reducer per il Core Module, triggherate ogniqualvolta viene eseguito il dispatch di un'action, per l'update dello state
 *
*/

import * as CoreActions from './core.actions';
import { GlobalApplicationData } from '../../shared/models/global-application-data.model';
import { Tabset } from '../../shared/models/tabset.model';

export interface CoreState {
  globalApplicationData: GlobalApplicationData,
  isFetchingLangs: boolean,
  showApplicationLoader: boolean,
  isMainMenuSidebarOpened: boolean,
  isProfileMenuSidebarOpened: boolean,
  redirectUrl: string,
  applicationLang: string,
  defaultLang: string,
  createSyllabusTab: Tabset,
  communicationKitTab: Tabset,
  disableInitiativeSteps: boolean;
  notificationsCounter: number;
  isImpersonification: boolean;
  defaultDebounceTime: number;
  availableCreditTypes: string[] | null;
}

// State iniziale
const initialState: CoreState = {
  globalApplicationData: null,
  isFetchingLangs: false,
  showApplicationLoader: false,
  isMainMenuSidebarOpened: false,
  isProfileMenuSidebarOpened: false,
  redirectUrl: null,
  applicationLang: null,
  defaultLang: null,
  createSyllabusTab: {
    tabsetName: 'createSyllabusTab',
    activeTabId: 0
  },
  communicationKitTab: {
    tabsetName: 'communicationKitTab',
    activeTabId: 0
  },
  disableInitiativeSteps: false,
  notificationsCounter: 0,
  isImpersonification: false,
  defaultDebounceTime: 600, //millisecondi
  availableCreditTypes: null,
};

// E' necessario utilizzare una funzione normale, le Arrow function di ES6 in questo caso non funzionano. Lo state è passato automaticamente da Ngrx, e ne approfittiamo per dargli un valore iniziale.
// Alla fine la funzione tornerà il nuovo state, una nuova copia aggiornata. Infatti, grazie all'immutabilità dello state, in ogni "case" viene sempre tornato un nuovo oggetto.
export function coreReducer(state = initialState, action: CoreActions.CoreActions) {
  // Tramite lo switch, identifico di quale azione è stato fatto il dispatch
  switch (action.type) {
    case (CoreActions.SAVE_REDIRECT_URL):
      // Salvo l'url richiesto prima che l'applicazione faccia il redirect alla pagina di login a causa della mancata autenticazione dell'utente
      return {
        ...state,
        redirectUrl: action.payload
      };
    case (CoreActions.SET_AVAILABLE_CREDIT_TYPES):
      return {
        ...state,
        availableCreditTypes: action.payload
      };
    case (CoreActions.REMOVE_REDIRECT_URL):
      // Cancella l'url per il redirect dopo il login
      return {
        ...state,
        redirectUrl: null
      };
    case (CoreActions.SET_CORE_APPLICATION_DATA):
      return {
        ...state,
        globalApplicationData: action.payload
      };
    case (CoreActions.SET_NOTIFICATIONS_COUNTER):
      return {
        ...state,
        notificationsCounter: action.payload
      };
    case (CoreActions.SET_APPLICATION_LANG):
      return {
        ...state,
        applicationLang: action.payload
      };
    case (CoreActions.REMOVE_CORE_APPLICATION_DATA):
      return {
        ...state,
        globalApplicationData: null
      };
    case (CoreActions.REMOVE_APPLICATION_LANG):
      return {
        ...state,
        applicationLang: null
      };
    case (CoreActions.ACTIVATE_TAB):
      let tabName: string = action.payload.tabsetName;
      state[tabName] = {
        activeTabId: action.payload.activeTabId
      }
      return {
        ...state
      };
    case (CoreActions.GET_AVAILABLE_LANGS):
      return {
        ...state,
        isFetchingLangs: true
      };
    case (CoreActions.ENABLE_INITIATIVE_STEPS):
      return {
        ...state,
        disableInitiativeSteps: false
      };
    case (CoreActions.DISABLE_INITIATIVE_STEPS):
      return {
        ...state,
        disableInitiativeSteps: true
      };
    case (CoreActions.GET_AVAILABLE_LANGS_FINISHED):
      return {
        ...state,
        isFetchingLangs: false
      };
    case (CoreActions.SET_DEFAULT_LANG):
      return {
        ...state,
        defaultLang: action.payload
      };
    case (CoreActions.SHOW_APPLICATION_LOADER):
      return {
        ...state,
        showApplicationLoader: true
      };
    case (CoreActions.HIDE_APPLICATION_LOADER):
      return {
        ...state,
        showApplicationLoader: false
      };
    case (CoreActions.OPEN_MAIN_MENU_SIDEBAR):
      return {
        ...state,
        isMainMenuSidebarOpened: true
      };
    case (CoreActions.CLOSE_MAIN_MENU_SIDEBAR):
      return {
        ...state,
        isMainMenuSidebarOpened: false
      };
    case (CoreActions.OPEN_PROFILE_MENU_SIDEBAR):
      return {
        ...state,
        isProfileMenuSidebarOpened: true
      };
    case (CoreActions.CLOSE_PROFILE_MENU_SIDEBAR):
      return {
        ...state,
        isProfileMenuSidebarOpened: false
      };
    case (CoreActions.SET_IMPERSONIFICATION_BANNER):
      sessionStorage.setItem('isImpersonification', (action.payload && action.payload.toString()))
      return {
        ...state,
        isImpersonification: action.payload
      };
    default:
      return state;
  }
}

export const getGlobalApplicationData = (state: CoreState) => state.globalApplicationData;
export const isFetchingLangs = (state: CoreState) => state.isFetchingLangs;
export const showApplicationLoader = (state: CoreState) => state.showApplicationLoader;
export const isMainMenuSidebarOpened = (state: CoreState) => state.isMainMenuSidebarOpened;
export const isProfileMenuSidebarOpened = (state: CoreState) => state.isProfileMenuSidebarOpened;
export const getRedirectUrl = (state: CoreState) => state.redirectUrl;
export const getNotificationsCounter = (state: CoreState) => state.notificationsCounter;
export const getApplicationContext = (state: CoreState) => state.globalApplicationData.applicationContext;
export const getApplicationLang = (state: CoreState) => state.applicationLang;
export const getDefaultLang = (state: CoreState) => state.defaultLang;
export const getActiveTab = (state: CoreState, tabName: string) => state[tabName];
export const areInitiativeStepsDisabled = (state: CoreState) => state.disableInitiativeSteps;
export const getIsImpersonification = (state: CoreState) => state.isImpersonification || (sessionStorage.getItem("isImpersonification") && sessionStorage.getItem("isImpersonification") === "true");
export const getDebounceTime = (state: CoreState) => state.defaultDebounceTime
export const getAvailableCreditTypes = (state: CoreState) => state.availableCreditTypes;