/*
 * Servizio che permette le chiamate rest relative alla dashboard
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { GlobalApplicationData } from '../../shared/models/global-application-data.model';
import * as CommonClasses from "../../../cm2-commonclasses";
import { environment } from 'src/environments/environment';
import { KeyValue, SenecaResponse } from '../../../cm2-commonclasses';
import { ComparisonCondition, User, UserOrderBy, WorkflowModelTypes } from 'atfcore-commonclasses';

interface CourseCreditsAnalyticsRowResponse {
  rows: {
    award: {
      type: string;
      value: number;
    }
  }[]
}


@Injectable()
export class DashboardService {


  applicationData: GlobalApplicationData;
  result$: Subscription;
  constructor(
    private store: Store<fromApp.AppState>,
    private http: HttpClient
  ) {
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    const combinedSelectes$ = combineLatest(globalApplicationData$);
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData]) => {
        this.applicationData = globalApplicationData;
      });
  }

  getUserByUserId(userId) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/user-by-userId/' + userId, {
      params: httpParams
    });
  }

  getCourseInvitesAnalytics(fromDate, toDate, chief?, socDistacco?, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }

    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-course-invites-analytics', {
      params: httpParams
    });
  }

  getCourseEnrollmentAnalytics(fromDate, toDate, chief?, socDistacco?, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }

    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-course-enrollment-analytics', {
      params: httpParams
    });
  }

  getSocialActivitiesAnalytics(fromDate, toDate, chief?, socDistacco?, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }

    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/social-activities-analytics', {
      params: httpParams
    });
  }

  getSocialActivitiesAnalyticsByTimePeriod(fromDate, toDate, chief?, socDistacco?, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }

    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/social-activities-analytics-by-time-period', {
      params: httpParams
    });
  }

  getDistinctNetworkCounters(fromDate, toDate, chief?, socDistacco?, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-distinct-network-counters', {
      params: httpParams
    });
  }

  getCatalogRequestsCounter(title: string, fromDate, toDate, chief?, socDistacco?, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    if (title) {
      httpParams = httpParams.append('searchedText', title);
    }
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }


    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-standalone-catalog-items-and-courses-requests', {
      params: httpParams
    });
  }

  getTrainedUsers(textFilter: string, fromRecord: number, numRecords: number, fromDate, toDate, chief?, socDistacco?, retrieveTotalRowsCount?: boolean, sort?: string, desc?: boolean, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
    httpParams = httpParams.append('desc', desc ? 'true' : 'false');
    httpParams = httpParams.append('textFilter', textFilter || '');
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('retrieveTotalRowsCount', retrieveTotalRowsCount ? 'true' : 'false');
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-trained-users/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }
  getTeacherTeachingsAnalytics(secondDetailId: string, fromRecord: number, numRecords: number, fromDate: any, toDate: any, retrieveTotalRowsCount: boolean, textFilter: string, userFilters: any) {

    let httpParams = new HttpParams();

    httpParams = httpParams.append('supplierPersonId', secondDetailId);
    httpParams = httpParams.append('retrieveTotalRowsCount', retrieveTotalRowsCount ? 'true' : 'false');
    httpParams = httpParams.append('title', textFilter || '');
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-course-teaching-teachers', {
      params: httpParams
    });
  }
  getStandaloneCatalogItemsAndCoursesRequests(title: string, fromRecord: number, numRecords: number, fromDate, toDate, chief?, socDistacco?, retrieveTotalRowsCount?: boolean, sort?: string, desc?: boolean, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    if (title) {
      httpParams = httpParams.append('searchedText', title);
    }
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
    httpParams = httpParams.append('desc', desc ? 'true' : 'false');
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }

    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    httpParams = httpParams.append('retrieveTotalRowsCount', retrieveTotalRowsCount ? 'true' : 'false');
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-standalone-catalog-items-and-courses-requests', {
      params: httpParams
    });
  }

  getTopConsumedObjectsAnalytics(fromRecord: number, numRecords: number, fromDate, toDate, chief?, socDistacco?, retrieveTotalRowsCount?: boolean, sort?: string, desc?: boolean, textFilter?: string, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('retrieveTotalRowsCount', retrieveTotalRowsCount ? 'true' : 'false');
    httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
    httpParams = httpParams.append('desc', desc ? 'true' : 'false');
    httpParams = httpParams.append('title', textFilter || '');
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }

    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/top-consumed-objects-analytics/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  getCourseInvitesUsers(fromRecord: number, numRecords: number, fromDate, toDate, chief?, socDistacco?, retrieveTotalRowsCount?: boolean, sort?: string, desc?: boolean, textFilter?: string, eventName?: string, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    httpParams = httpParams.append('retrieveTotalRowsCount', retrieveTotalRowsCount ? 'true' : 'false');
    httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
    httpParams = httpParams.append('desc', desc ? 'true' : 'false');
    httpParams = httpParams.append('textFilter', textFilter || '');
    httpParams = httpParams.append('eventName', eventName || '');
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-course-invites-users/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  getCourseEnrollmentUsers(fromRecord: number, numRecords: number, fromDate, toDate, chief?, socDistacco?, retrieveTotalRowsCount?: boolean, sort?: string, desc?: boolean, textFilter?: string, eventName?: string, userFilters?, isManager?: boolean, isStructure?: boolean, statusType?: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    httpParams = httpParams.append('retrieveTotalRowsCount', retrieveTotalRowsCount ? 'true' : 'false');
    httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
    httpParams = httpParams.append('desc', desc ? 'true' : 'false');
    httpParams = httpParams.append('textFilter', textFilter || '');
    httpParams = httpParams.append('eventName', eventName || '');
    httpParams = httpParams.append('statusType', statusType || '');
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-course-enrollment-users', {
      params: httpParams
    });
  }

  socialActivitiesUsersData(fromRecord: number, numRecords: number, fromDate, toDate, chief?, socDistacco?, retrieveTotalRowsCount?: boolean, sort?: string, desc?: boolean, textFilter?: string, eventName?: string, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    httpParams = httpParams.append('retrieveTotalRowsCount', retrieveTotalRowsCount ? 'true' : 'false');
    httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
    httpParams = httpParams.append('desc', desc ? 'true' : 'false');
    httpParams = httpParams.append('textFilter', textFilter || '');
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (eventName && eventName.length) {
      httpParams = httpParams.append('eventName', eventName && typeof eventName == 'string' ? eventName : JSON.stringify(eventName));
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/social-activities-users-data/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  getTopActiveUsersAnalytics(fromRecord: number, numRecords: number, fromDate, toDate, retrieveTotalRowsCount?: boolean, chief?, socDistacco?, sort?: string, desc?: boolean, textFilter?: string, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
    httpParams = httpParams.append('desc', desc ? 'true' : 'false');
    httpParams = httpParams.append('textFilter', textFilter || '');
    httpParams = httpParams.append('retrieveTotalRowsCount', retrieveTotalRowsCount ? 'true' : 'false');
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/top-active-users-analytics/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  getTopActiveTeachersAnalytics(fromRecord: number, numRecords: number, fromDate, toDate, retrieveTotalRowsCount?: boolean, chief?, socDistacco?, textFilter?: string, userFilters?, isManager?: boolean, isStructure?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    httpParams = httpParams.append('textFilter', textFilter || '');
    //httpParams = httpParams.append('retrieveTotalRowsCount', retrieveTotalRowsCount ? 'true' : 'false');
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    // httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    //httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/top-active-teachers-analytics/', {
      params: httpParams
    });
  }



  // Recupera il count analytics per secondo dettaglio utenti piu attivi
  countAnalyticsMoreActiveUsers(userIdForDashboard: string, startDate?, endDate?, courseUserStatuses?: string | string[], courseType?: string, past?: boolean, future?: boolean, description?: string, asConsumableItem?: boolean, chief?, socDistacco?, userFilters?, isManager?: boolean, isStructure?: boolean): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('forDashboard', 'true');
    httpParams = httpParams.append('startDate', (startDate && startDate.toString()) || '');
    httpParams = httpParams.append('endDate', (endDate && endDate.toString()) || '');
    httpParams = httpParams.append('courseType', courseType || '');
    httpParams = httpParams.append('userIdForDashboard', userIdForDashboard || '');
    httpParams = httpParams.append('description', (description && description.toString()) || '');
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (past) {
      httpParams = httpParams.append('past', (past && past.toString()) || '');
    }
    if (future) {
      httpParams = httpParams.append('future', (future && future.toString()) || '');
    }
    if (asConsumableItem) {
      httpParams = httpParams.append('asConsumableItem', (asConsumableItem && asConsumableItem.toString()) || '');
    }
    if (courseUserStatuses && courseUserStatuses.length) {
      httpParams = httpParams.append('courseUserStatuses', courseUserStatuses && typeof courseUserStatuses == 'string' ? courseUserStatuses : JSON.stringify(courseUserStatuses));
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-all-items-of-user-analytics', {
      params: httpParams
    });
  }

  // Recupera analytics per secondo dettaglio utenti piu attivi
  getAnalyticsMoreActiveUsers(userIdForDashboard: string, fromRecord: any, numRecords: any, fromDate?, toDate?, courseUserStatuses?: string | string[], courseType?: string, allData?: boolean, past?: boolean, future?: boolean, description?: string, sort?: string, desc?: boolean, asConsumableItem?: boolean, withUserCatalogActions?: boolean, chief?, socDistacco?, userFilters?, isManager?: boolean, isStructure?: boolean): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('forDashboard', 'true');
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    httpParams = httpParams.append('startDate', (fromDate && fromDate.toString()) || '');
    httpParams = httpParams.append('endDate', (toDate && toDate.toString()) || '');
    httpParams = httpParams.append('courseType', courseType || '');
    httpParams = httpParams.append('userIdForDashboard', userIdForDashboard || '');
    httpParams = httpParams.append('allData', (allData && allData.toString()) || '');
    httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
    httpParams = httpParams.append('description', (description && description.toString()) || '');
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (past) {
      httpParams = httpParams.append('past', (past && past.toString()) || '');
    }
    if (future) {
      httpParams = httpParams.append('future', (future && future.toString()) || '');
    }
    if (asConsumableItem) {
      httpParams = httpParams.append('asConsumableItem', (asConsumableItem && asConsumableItem.toString()) || '');
    }
    if (withUserCatalogActions) {
      httpParams = httpParams.append('withUserCatalogActions', (withUserCatalogActions && withUserCatalogActions.toString()) || '');
    }
    httpParams = httpParams.append('desc', desc && desc.toString() || '');
    if (courseUserStatuses && courseUserStatuses.length) {
      httpParams = httpParams.append('courseUserStatuses', courseUserStatuses && typeof courseUserStatuses == 'string' ? courseUserStatuses : JSON.stringify(courseUserStatuses));
    }
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-all-items-of-user-analytics', {
      params: httpParams
    });
  }

  listDistinctUsersField(fromRecord: number, numRecords: number, distinctField: string, usingKeyField?: string, textFilter?: string, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('distinctField', distinctField);
    // httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    // httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (usingKeyField) {
      httpParams = httpParams.append('usingKeyField', usingKeyField);
    }
    if (textFilter && textFilter.length) {
      httpParams = httpParams.append('textFilter', textFilter);
    }
    if (isManager) {
      httpParams = httpParams.append('isManager', 'true');
    }
    return this.http.get<SenecaResponse<Array<string | KeyValue<string>>>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-distinct-users-field', {
      params: httpParams
    });
  }

  getMostDetectedDevicesAnalytics(fromRecord: number, numRecords: number, fromDate, toDate, chief?, socDistacco?, retrieveTotalRowsCount?: boolean, textFilter?: string, byDevices?: boolean, byUsers?: boolean, sort?: string, desc?: boolean, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString() || '0');
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString() || '');
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('textFilter', textFilter || '');
    httpParams = httpParams.append('retrieveTotalRowsCount', retrieveTotalRowsCount ? 'true' : 'false');
    httpParams = httpParams.append('byDevices', byDevices ? 'true' : 'false');
    httpParams = httpParams.append('byUsers', byUsers ? 'true' : 'false');
    httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
    httpParams = httpParams.append('desc', desc ? 'true' : 'false');
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/most-detected-devices-analytics/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  getCourseCreditsAnalytics(fromRecord: number, numRecords: number, fromDate, toDate, creditType?, chief?, socDistacco?, retrieveTotalRowsCount?: boolean, textFilter?: string, groupByCredits?: boolean, sort?: string, desc?: boolean, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString() || '0');
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString() || '');
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('textFilter', textFilter || '');
    httpParams = httpParams.append('retrieveTotalRowsCount', retrieveTotalRowsCount ? 'true' : 'false');
    httpParams = httpParams.append('groupByCredits', groupByCredits ? 'true' : 'false');
    httpParams = httpParams.append('sorting', (sort && sort.toString()) || '');
    httpParams = httpParams.append('desc', desc ? 'true' : 'false');
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }

    if (creditType && creditType.length) {
      creditType.forEach(credit => {
        httpParams = httpParams.append('creditType', credit);
      });
    }

    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<CourseCreditsAnalyticsRowResponse>>(
      this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/course-credits-analytics',
      { params: httpParams }
    );
  }

  getTrainingHoursAnalyticsByTimePeriod(fromDate, toDate, chief?, socDistacco?, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/training-hours-analytics-by-time-period', {
      params: httpParams
    });
  }

  // Recupera i counters per l'indicatore dei suggeriti (Library)
  getSuggestedObjectsAnalytics(fromDate, toDate, chief?, socDistacco?, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    httpParams = httpParams.append('gaiDataOnly', 'true');
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-course-invites-analytics', {
      params: httpParams
    });
  }

  // Recupera le analytics per l'indicatore degli oggetti più visti
  getMoreViewsObjectsAnalytics(fromRecord: number, numRecords: number, fromDate, toDate, retrieveTotalRowsCount?: string, chief?, socDistacco?, textFilter?: string, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    httpParams = httpParams.append('retrieveTotalRowsCount', retrieveTotalRowsCount);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (textFilter) {
      httpParams = httpParams.append('title', textFilter);
    }
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/top-consumed-objects-analytics/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  getConsumedItemUsers(itemId: string, fromRecord: number, numRecords: number, fromDate, toDate, chief?, socDistacco?, textFilter?: string, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (textFilter) {
      httpParams = httpParams.append('textFilter', textFilter);
    }
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-consumed-item-users/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  // Recupera il titolo da un itemId
  getItemTitleById(itemId: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-item-title-by-id/' + itemId, {
      params: httpParams
    });
  }

  // Recupera i due counts per indicatore oggetti suggeriti in Home Dashboard
  suggestedItemAnalytics(fromDate, toDate, retrieveTotalRowsCount?: string, chief?, socDistacco?, textFilter?: string, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    httpParams = httpParams.append('retrieveTotalRowsCount', retrieveTotalRowsCount);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (textFilter) {
      httpParams = httpParams.append('title', textFilter);
    }
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/suggested-items-analytics/', {
      params: httpParams
    });
  }

  // Recupera conteggio totale oggetti suggeriti per la tabella dettaglio
  countSuggestedObjects(fromDate, toDate, chief?, socDistacco?, itemTextFilter?: string, userTextFilter?: string, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (userTextFilter) {
      httpParams = httpParams.append('suggestedUserSearchedText', userTextFilter);
    }
    if (itemTextFilter) {
      httpParams = httpParams.append('itemTitleSearchedText', itemTextFilter);
    }
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-dashboard-suggested-items/', {
      params: httpParams
    });
  }

  // Recupera gli oggetti suggeriti per la tabella dettaglio
  getSuggestedObjects(fromRecord: number, numRecords: number, fromDate, toDate, chief?, socDistacco?, itemTextFilter?: string, userTextFilter?: string, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (userTextFilter) {
      httpParams = httpParams.append('suggestedUserSearchedText', userTextFilter);
    }
    if (itemTextFilter) {
      httpParams = httpParams.append('itemTitleSearchedText', itemTextFilter);
    }
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-dashboard-suggested-items/', {
      params: httpParams
    });
  }

  // Recupera conteggio totale persone dettaglio oggetti suggeriti per la tabella dettaglio
  countPersonsSuggestedObjects(itemId, fromDate, toDate, chief?, socDistacco?, itemTitleSearchedText?: string, suggesterSearchedText?: string, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (itemTitleSearchedText) {
      httpParams = httpParams.append('itemTitleSearchedText', itemTitleSearchedText);
    }
    if (suggesterSearchedText) {
      httpParams = httpParams.append('suggesterSearchedText', suggesterSearchedText);
    }
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }

    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-item-suggester-users/', {
      params: httpParams
    });
  }

  // Recupera gli oggetti suggeriti per la tabella dettaglio
  getPersonsSuggestedObjects(itemId, fromRecord: number, numRecords: number, fromDate, toDate, chief?, socDistacco?, searchedText?: string, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (searchedText) {
      httpParams = httpParams.append('searchedText', searchedText);
    }
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }

    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-item-suggester-users/', {
      params: httpParams
    });
  }

  listDashboardFilterByFieldCode(fromRecord: number, numRecords: number, fieldCodeName: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fieldCodeName', fieldCodeName);
    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-dashboard-filters-by-field-code-name/', {
      params: httpParams
    });

  }

  getWorkflowsIndicatorCounter(fromDate, toDate, userFilters?, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    httpParams = httpParams.append('gaiDataOnly', 'true');
    httpParams = httpParams.append('workflowType', WorkflowModelTypes.PREREG_APPROVE);

    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-workflows-analytics/', {
      params: httpParams
    });
  }

  getWorkflowsUsers(fromRecord: number, numRecords: number, fromDate, toDate, textFilter, eventName?: string, userFilters?, isStructure?: boolean, stageId?: string[] | string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    httpParams = httpParams.append('textFilter', textFilter);
    httpParams = httpParams.append('status', eventName || '');
    httpParams = httpParams.append('workflowType', WorkflowModelTypes.PREREG_APPROVE);
    httpParams = httpParams.append('gaiDataOnly', 'true');

    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    if (stageId && stageId.length) {
      if (Array.isArray(stageId)) {
        if (stageId && stageId.length) {
          stageId.forEach(sid => {
            httpParams = httpParams.append('stageId', sid);
          });
        }
      } else {
        httpParams = httpParams.append('stageId', stageId);
      }
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-workflows-users/', {
      params: httpParams
    });
  }

  // Analytycs per indicatore questionari di gradimento
  getQuestionnaireAnalytics(fromDate, toDate, userFilters?, managerDataOnly?: boolean, surveyType?: string, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (managerDataOnly) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('surveyType', surveyType);

    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-surveys-analytics/', {
      params: httpParams
    });
  }

  // Analytycs per indicatore nel monte ore del biennio
  getTotalHoursOverTwoYearsPeriodAnalytics(fromDate, toDate, userFilters?, managerDataOnly?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (managerDataOnly) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }

    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-biennium-hours-stats', {
      params: httpParams
    });
  }

  // Dettaglio per indicatore questionari di gradimento
  getSurveyUsers(fromRecord: number, numRecords: number, fromDate, toDate, stageId?, surveyType?: string, userFilters?, surveyStatus?, isManagerDataOnly?: boolean, textFilter?: string, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);

    if (stageId && stageId.length > 0) {
      stageId.forEach(stage => {
        httpParams = httpParams.append('stageId', stage);
      });
    }
    if (surveyStatus && surveyStatus.length) {
      httpParams = httpParams.append('surveyStatus', surveyStatus && typeof surveyStatus == 'string' ? surveyStatus : JSON.stringify(surveyStatus));
    }
    httpParams = httpParams.append('surveyType', surveyType);

    if (isManagerDataOnly) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }

    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (textFilter && textFilter.length) {
      httpParams = httpParams.append('textFilter', textFilter);
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-surveys-users/', {
      params: httpParams
    });
  }

  getBienniumTrainedUsers(fromRecord: number, numRecords: number, textFilter?: string, sort?: string) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-biennium-trained-users' +
      `/${fromRecord}/${numRecords}?fromRecord=${fromRecord}&numRecords=${numRecords}&sort=${sort}&desc=false&textFilter=${textFilter}&gaiDataOnly=true&retrieveTotalRowsCount=true`);
  }

  getAmountOfHours(fromRecord: number, numRecords: number, textFilter?: string, sort?: string) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-biennium-training-hours-editions' +
      `/${fromRecord}/${numRecords}?fromRecord=${fromRecord}&numRecords=${numRecords}&sort=${sort}&desc=false&textFilter=${textFilter}&gaiDataOnly=true&retrieveTotalRowsCount=true`);
  }

  getMostAcquiredCompetences(fromRecord, numRecords, fromDate?, toDate?, isManager?, isStructure?) {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/most-acquired-competences-analytics/0/5', {
      params: httpParams
    });
  }

  getMostDesiredCompetences(fromRecord: number, numRecords: number, fromDate: any, toDate: any, isManager?: boolean, isStructure?) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }
    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/most-desired-competences-analytics/${fromRecord}/${numRecords}`, {
      params: httpParams
    });
  }

  getAcquiredCompetencesAnalytics(fromRecord: number, numRecords: number, fromDate, toDate, retrieveTotalRowsCount?: string, chief?, socDistacco?, textFilter?: string, userFilters?, isManager?: boolean, isStructure?: boolean): any {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    httpParams = httpParams.append('retrieveTotalRowsCount', retrieveTotalRowsCount);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (textFilter) {
      httpParams = httpParams.append('textFilter', textFilter);
    }
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {

      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/get-acquired-competences-analytics` + '/' + `${fromRecord}` + '/' + `${numRecords}`, {
      params: httpParams
    });
  }


  getDesiredCompetencesAnalytics(fromRecord: number, numRecords: number, fromDate, toDate, retrieveTotalRowsCount?: string, chief?, socDistacco?, textFilter?: string, userFilters?, isManager?: boolean, isStructure?: boolean): any {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    httpParams = httpParams.append('retrieveTotalRowsCount', retrieveTotalRowsCount);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (textFilter) {
      httpParams = httpParams.append('textFilter', textFilter);
    }
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }
    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }
    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/get-desired-competences-analytics` + '/' + `${fromRecord}` + '/' + `${numRecords}`, {
      params: httpParams
    });
  }

  getAcquiredCompetenceUsers(tagId: string, fromRecord: number, numRecords: number, fromDate, toDate, chief?, socDistacco?, retrieveTotalRowsCount?: string, textFilter?: string, userFilters?, isManager?: boolean, isStructure?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('tagId', tagId);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    httpParams = httpParams.append('retrieveTotalRowsCount', retrieveTotalRowsCount);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (textFilter) {
      httpParams = httpParams.append('textFilter', textFilter);
    }
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-acquired-competence-users' + '/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  getDesiredCompetenceUsers(tagId: string, fromRecord: number, numRecords: number, fromDate, toDate, chief?, socDistacco?, retrieveTotalRowsCount?: string, textFilter?: string, userFilters?, isManager?: boolean, isStructure?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('tagId', tagId);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    httpParams = httpParams.append('retrieveTotalRowsCount', retrieveTotalRowsCount);

    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }

    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());
    if (textFilter) {
      httpParams = httpParams.append('textFilter', textFilter);
    }
    if (chief && chief.length) {
      chief.forEach(chief => {
        httpParams = httpParams.append('chief', chief);
      });
    }
    if (socDistacco && socDistacco.length) {
      socDistacco.forEach(socDistacco => {
        httpParams = httpParams.append('socDistacco', socDistacco);
      });
    }
    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-desired-competence-users' + '/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  getFilteredUserList(fromRecord?: number, numRecords?: number, searchedText?: string, includeMe?: boolean, confirmedUsers?: boolean, notConfirmedUsers?: boolean, socDistacco?: string, externalOnly?: boolean, notExternalOnly?: boolean, orderBy?: string) {
    let from = fromRecord && fromRecord.toString();
    let to = numRecords && numRecords.toString();
    let orderUsersBy = orderBy || UserOrderBy.SURNAME_ASC;
    let userFilters = {};
    let propertiesFilters: ComparisonCondition[] = [];
    let searched = searchedText

    if (socDistacco) {
      userFilters['socDistacco'] = socDistacco;
    }

    if (externalOnly) {
      propertiesFilters.push(<ComparisonCondition>{ key: 'isExternal', value: true, notExists: false })
    }
    if (notExternalOnly) {
      propertiesFilters.push(<ComparisonCondition>{ key: 'isExternal', notExists: true })
    }

    return this.http.post<SenecaResponse<User[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/find-users-by-any-field/', {
      fromRecord: from,
      numRecords: to,
      textFilter: searched,
      orderBy: orderUsersBy,
      userFilters: userFilters,
      propertiesFilters: propertiesFilters
    });
  }

  // Recupera le iniziative per la select
  getLastLastInitiatives(fromRecord?: number, numRecords?: number, title?: string, allTypes?: boolean): any {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('title', title);
    httpParams = httpParams.append('allTypes', `${allTypes}`);
    httpParams = httpParams.append('suspended', 'true');
    httpParams = httpParams.append('archived', 'true');
    httpParams = httpParams.append('withSyllabusParams', 'false');

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-latest-opened-initiatives/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }


  getEditionIndicator(fromDate: string, toDate: string, userFilters?: any, managerDataOnly?: boolean, adminDataOnly?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);

    if (managerDataOnly) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    }
    if (adminDataOnly) {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }

    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-course-editions-analytics', {
      params: httpParams
    });
  }

  getEditionsDetails(fromRecord: number, numRecords: number, fromDate: string, toDate: string, title: string, userFilters?: any, isManager?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (title && title.length) {
      httpParams = httpParams.append('title', title);
    }

    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }

    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-course-editions/' + fromRecord.toString() + '/' + numRecords.toString() + '/', {
      params: httpParams
    });
  }

  exportCourseEditionReport(fromDate: string, toDate: string, title: string, userFilters?: any, managerDataOnly?: boolean, adminDataOnly?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (title && title.length) {
      httpParams = httpParams.append('title', title);
    }

    if (managerDataOnly) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    }
    if (adminDataOnly) {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }

    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/export-course-editions/', {
      params: httpParams
    });
  }

  getAverageTrainingHours(fromDate, toDate, userFilters?, isManager?: boolean, isStructure?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromDate', fromDate);
    httpParams = httpParams.append('toDate', toDate);
    if (isManager) {
      httpParams = httpParams.append('managerDataOnly', 'true');
    } else {
      httpParams = httpParams.append('gaiDataOnly', 'true');
    }
    httpParams = httpParams.append('library', environment.canAccessUserLibrary.toString());
    httpParams = httpParams.append('corporate', environment.canAccessUserHome.toString());

    if (userFilters) {
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }

    if (isStructure) {
      httpParams = httpParams.append('managerType', 'S');
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-training-hours-analytics', {
      params: httpParams
    });
  }
}
