/*
*  Lista di azioni riguardante la gestione utente-docente
*/

import { Action } from '@ngrx/store';

// Salva la lista di docenti recuperati
export const SET_ACTIVITIES = 'SET_ACTIVITIES';

// Cancella un docente
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';

// Recupera i docenti da remoto
export const GET_ACTIVITIES = 'GET_ACTIVITIES';

// Setta il contatore dei docenti recuperati da remoto
export const SET_ACTIVITIES_COUNT = 'SET_ACTIVITIES_COUNT';

// Setta la paginazione
export const SET_PAGINATION_ACTIVITIES = 'SET_PAGINATION_ACTIVITIES';

// Setta la pagina corrente
export const SET_PAGE_ACTIVITIES = 'SET_PAGE_ACTIVITIES';

// Setta la tab corrente
export const SET_TAB_ACTIVITIES = 'SET_TAB_ACTIVITIES';

// Setta il testo (searchedText)
export const SET_ACTIVITIES_TEXT_FILTER = 'SET_ACTIVITIES_TEXT_FILTER';

// Setta la direzione di ordinamento
export const SET_SORTING_DIR = 'SET_ORDER_DIR';

// Setta la colonna di ordinamento
export const SET_SORTING_COL = 'SET_SORTING_COL';

// Setta la data di inizio di ordinamento
export const SET_ACTIVITIES_START_DATE_TIME = 'SET_ACTIVITIES_START_DATE_TIME';

// Setta la data di fine di ordinamento
export const SET_ACTIVITIES_END_DATE_TIME = 'SET_ACTIVITIES_END_DATE_TIME';

export class SetActivities implements Action {
  readonly type = SET_ACTIVITIES;

  constructor(public payload: any[]) { }
}

export class SetActivitiesStartDateTime implements Action {
  readonly type = SET_ACTIVITIES_START_DATE_TIME;

  constructor(public payload) { }
}

export class SetActivitiesEndDateTime implements Action {
  readonly type = SET_ACTIVITIES_END_DATE_TIME;

  constructor(public payload) { }
}

export class DeleteActivities implements Action {
  readonly type = DELETE_ACTIVITY;

  constructor(public payload: string) { }
}

export class GetActivities implements Action {
  readonly type = GET_ACTIVITIES;

  constructor(public payload?: boolean) { }
}

export class SetSortingCol implements Action {
  readonly type = SET_SORTING_COL;

  constructor(public payload: string) { }
}

export class SetSortingDir implements Action {
  readonly type = SET_SORTING_DIR;

  constructor(public payload: string) { }
}

export class SetActivitiesCount implements Action {
  readonly type = SET_ACTIVITIES_COUNT;

  constructor(public payload: number) { }
}

export class SetPageActivities implements Action {
  readonly type = SET_PAGE_ACTIVITIES;

  constructor(public payload: number) { }
}

export class SetTabActivities implements Action {
  readonly type = SET_TAB_ACTIVITIES;

  constructor(public payload: number) { }
}

export class SetPaginationActivities implements Action {
  readonly type = SET_PAGINATION_ACTIVITIES;

  constructor(public payload: { fromRecord: number, numRecords: number }) { }
}
export class SetActivitiesTextFilter implements Action {
  readonly type = SET_ACTIVITIES_TEXT_FILTER;

  constructor(public payload: string) { }
}

export type ActivitiesUsersActions = SetActivities |
  DeleteActivities |
  SetActivitiesCount |
  SetPaginationActivities |
  SetActivitiesStartDateTime |
  SetActivitiesEndDateTime |
  SetPageActivities |
  SetTabActivities |
  SetActivitiesTextFilter |
  SetSortingDir |
  SetSortingCol |
  GetActivities;