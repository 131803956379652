/*
* Card per il redirect alla creazione di un item
*/

import { Component, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'new-item-card',
  templateUrl: './new-item-card.component.html',
  styleUrls: ['./new-item-card.component.scss']
})

export class NewItemCardComponent {
  @Input() selectedCreationPossibility: { options: number };
  @Output() toggledChange: EventEmitter<string> = new EventEmitter<string>();
  isLoaded: boolean;
  constructor() {
  }

  // Emette l'evento al componente padre, che cambia la selezione
  changeValue(newValue: string) {
    this.toggledChange.emit(newValue);
  }

  // Renderinig dell'immagine finito
  imgRendered() {
    this.isLoaded = true;
  }
}
