/*
 * Servizio che permette le chiamate rest relative ai gruppi formazione obbligatoria
*/
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from "rxjs";
import { GlobalApplicationData } from 'src/app/shared/models/global-application-data.model';
import { SenecaResponse, Upload } from "src/cm2-commonclasses";
import * as fromApp from '../../ngrx/app.reducers';
import * as NewsActions from '../ngrx/news.actions';
import { Announcement } from 'atfcore-commonclasses';
import * as CommonClasses from "../../../cm2-commonclasses";
import { AuthService } from 'src/app/auth/services/auth.service';


@Injectable()
export class NewsService {
  applicationData: GlobalApplicationData;
  fromRecord: number;
  numRecords: number;
  result$: Subscription;
  page: number;
  news: Announcement[];
  newsTextFilter: string;
  sortDir: string;
  sortCol: string;
  adminMode: boolean;
  alreadyRead: string[];

  constructor(private store: Store<fromApp.AppState>, private http: HttpClient, private authService: AuthService) {
    // Dallo Store applicativo, ricavo l'url per le chiamate rest e anche la pagina corrente in cui mi trovo nella visualizzazione dei gruppi
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    let news$: Observable<Announcement[]> = this.store.select(fromApp.getNews);
    let fromRecord$: Observable<number> = this.store.select(fromApp.getNewsFromRecord);
    let numRecords$: Observable<number> = this.store.select(fromApp.getNewsNumRecords);
    let page$: Observable<number> = this.store.select(fromApp.getNewsPage);
    let newsTextFilter$: Observable<string> = this.store.select(fromApp.getNewsTextFilter);
    let sortingDir$: Observable<string> = this.store.select(fromApp.getSortingDir);
    let sortingCol$: Observable<string> = this.store.select(fromApp.getSortingCol);
    let alreadyRead$: Observable<string[]> = this.store.select(fromApp.getOldUnread);

    const combinedSelectes$ = combineLatest(globalApplicationData$, fromRecord$, numRecords$, page$, news$, newsTextFilter$, sortingDir$, sortingCol$, alreadyRead$);
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData, fromRecord, numRecords, page, news, newsTextFilter, sortingDir, sortingCol, alreadyRead]) => {
        this.applicationData = globalApplicationData;
        this.fromRecord = fromRecord;
        this.numRecords = numRecords;
        this.page = page;
        this.news = news;
        this.newsTextFilter = newsTextFilter;
        this.sortDir = sortingDir;
        this.sortCol = sortingCol;
        this.adminMode = this.authService.isAdminMode();
        this.alreadyRead = alreadyRead
      });
  }

  // Converte la pagina in una paginazione
  paginate(): { fromRecord: number, numRecords: number } {
    let fromRecord: number = 0;
    // Offeset e ResultLimit sono due parametri passati automaticamente dal componente della tabella. La prima volta, non esisteranno. Le altre sì, e mi serviranno per sapere se sto procedendo o retrocedendo nella ricerca
    if (this.page && this.numRecords) {
      fromRecord = (this.page - 1) * this.numRecords;
    } else {
      fromRecord = 0;
    }

    let pagination = {
      fromRecord: fromRecord,
      numRecords: this.numRecords
    }

    // Eseguo il dispatch dell'azione che salva la paginazione
    this.store.dispatch(new NewsActions.SetPaginationNews(pagination));

    return pagination;
  }

  // Ritorna la lista delle news
  getNewsList(adminList?: boolean) {
    // Preparo i parametri per la richiesta http
    let pagination = this.paginate();
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', pagination.fromRecord.toString());
    httpParams = httpParams.append('numRecords', pagination.numRecords.toString());
    httpParams = httpParams.append('searchedText', this.newsTextFilter || "");
    if (adminList || this.adminMode) {
      httpParams = httpParams.append('ignoreVisibility', "true");
    } else {
      httpParams = httpParams.append('ignoreVisibility', "false");
      httpParams = httpParams.append('status', Announcement.STATUS_PUBLISHED);
    }
    httpParams = httpParams.append('sortCols', this.sortCol);
    httpParams = httpParams.append('sortDirs', this.sortDir);
    return this.http.get<SenecaResponse<Announcement[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-all-announcements/', {
      params: httpParams
    });
  }

  // Ritorna il count totale delle news
  countNews(adminList?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', this.newsTextFilter || "");
    if (adminList || this.adminMode) {
      httpParams = httpParams.append('ignoreVisibility', "true");
    } else {
      httpParams = httpParams.append('ignoreVisibility', "false");
      httpParams = httpParams.append('status', Announcement.STATUS_PUBLISHED);
    }
    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-announcements/', {
      params: httpParams
    });
  }

  // Crea una nuova news
  createNews(uploadObj) {
    let formData = new FormData();
    formData.append('title', uploadObj.title);
    formData.append('publicVisibility', uploadObj.publicVisibility);
    formData.append('onTop', uploadObj.topVisibility);
    if (uploadObj.description) {
      formData.append('description', uploadObj.description);
    }
    if (uploadObj.link) {
      formData.append('link', uploadObj.link);
    }
    if (uploadObj.uploadId) {
      formData.append('uploadId', uploadObj.uploadId);
    }
    if (uploadObj.userIds) {
      formData.append('userIds', uploadObj.userIds);
    }
    if (uploadObj.groupIds) {
      formData.append('groupIds', uploadObj.groupIds);
    }

    let params = new HttpParams();
    const options = {
      params: params
    };
    return this.http.post(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-announcement', formData);
  }

  // Aggiorna una news
  updateNews(uploadObj) {
    let formData = new FormData();
    formData.append('announcementId', uploadObj.newsId);
    formData.append('title', uploadObj.title);
    formData.append('publicVisibility', uploadObj.publicVisibility);
    formData.append('onTop', uploadObj.topVisibility);
    if (uploadObj.description) {
      formData.append('description', uploadObj.description);
    }
    if (uploadObj.link) {
      formData.append('link', uploadObj.link);
    }
    if (uploadObj.uploadId) {
      formData.append('uploadId', uploadObj.uploadId);
    }
    if (uploadObj.userIds) {
      formData.append('userIds', uploadObj.userIds);
    }
    if (uploadObj.groupIds) {
      formData.append('groupIds', uploadObj.groupIds);
    }
    if (uploadObj.deleteImageAttr) {
      formData.append('deleteImageAttr', uploadObj.deleteImageAttr);
    }
    let params = new HttpParams();
    const options = {
      params: params
    };
    return this.http.post(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/update-announcement', formData);
  }

  // Recupera news da id
  getNews(id: string) {
    let httpParams = new HttpParams();
    if (this.adminMode) {
      httpParams = httpParams.append('ignoreVisibility', "true");
    } else {
      httpParams = httpParams.append('ignoreVisibility', "false");
    }
    return this.http.get<SenecaResponse<Announcement>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-announcement-details/' + id, {
      params: httpParams
    });
  }

  // Servizio che cancella una news
  deleteNews(newsId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-announcement/' + newsId, {});
  }

  // Recupera la sola news in evidenza
  getTopNews() {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('topOnly', "true");
    httpParams = httpParams.append('allData', "true");
    if (this.adminMode) {
      httpParams = httpParams.append('ignoreVisibility', "true");
    } else {
      httpParams = httpParams.append('ignoreVisibility', "false");
    }
    return this.http.get<SenecaResponse<Announcement[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-all-announcements/', {
      params: httpParams
    });
  }
  // Mette in evidenza la news togliendo tutte le altre del tenant
  setTopNews(announcementId: string, onTop: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('announcementId', announcementId);
    httpParams = httpParams.append('onTop', onTop.toString());
    httpParams = httpParams.append('revertOthers', "true");
    let options = {
      params: httpParams
    }
    return this.http.post<SenecaResponse<Announcement>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/set-announcement-on-top', {}, options);
  }

  // Crea Upload dell'immagine 
  uploadImage(uploadObj) {
    let formData = new FormData();
    formData.append('file', uploadObj.file.file.rawFile);
    formData.append('title', uploadObj.title || '');
    formData.append('originApplicationName', uploadObj.originApplicationName || '');
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/upload-any-file', formData, options);
    return this.http.request(req);
  }

  // Recupera l'oggetto upload collegato all'immagine
  getUploadedImage(uploadId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('uploadId', uploadId);
    return this.http.get<CommonClasses.SenecaResponse<Upload>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-upload-by-id', {
      params: httpParams
    });
  }

  // Cancella l'oggetto Upload dell'immagine
  deleteUploadedImage(uploadId: string) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-upload-file', {
      uploadId: uploadId
    });
  }
  // Ritorna un array con gli id delle news pubblicate dopo l'ultimo login dell'utente
  unreadNews(notIgnoreVisibility?: boolean) {
    let httpParams = new HttpParams();
    if (notIgnoreVisibility) {
      httpParams = httpParams.append('ignoreVisibility', "false");
    } else {
      if (this.adminMode) {
        httpParams = httpParams.append('ignoreVisibility', "true");
      } else {
        httpParams = httpParams.append('ignoreVisibility', "false");
      }
    }
    httpParams = httpParams.append('alreadyRead', this.alreadyRead.length ? this.alreadyRead.join(',') : "");
    return this.http.get<SenecaResponse<string[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/unread-announcements-ids/', {
      params: httpParams
    });
  }

}