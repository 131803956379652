<div fxLayout="column" class="big-widget-container">
  <div fxLayout="row" class="widget-content">
    <div fxLayout="column" class="icon-cards" fxLayoutAlign="start center">
      <div fxLayout="column">
        <img class="cursor-pointer" alt="image" src="assets/icons/icon-cards2.png">
      </div>
      <div fxLayout="column" class="margin-top30">
        <svg-icon class="cursor-pointer" (click)="emitOnInfoClick()" src="assets/icons/icon-info-circle-line.svg">
        </svg-icon>
      </div>
    </div>
    <div class="full-width" fxLayout="column" fxLayoutAlign="start start">
      <div fxLayout="column" class="title-wrapper">
        <!-- Titolo -->
        <p class="title">{{ indicator.title }}</p>
        <!-- Sottotitolo -->
        <p class="subtitle" *ngIf="indicator.subtitle">{{ indicator.subtitle }}</p>
      </div>

      <div fxLayout="column" fxFlex
        [ngClass]="{ 'margin-top20' : indicator.subtitle, 'margin-top40' : !indicator.subtitle }" class="full-width">
        <div *ngIf="areUsersList || areObjectsList" fxFlex="33" fxLayout="column" fxLayoutAlign="start start"
          class="number-container first">
          <div class="indicator-number" fxLayout="row" fxLayoutAlign="center center">
            <p>
              {{ indicator.firstIndicatorNumber | numberAbbreviation:1 }}
            </p>
          </div>
          <div class="indicator-number-hint" fxLayout="row" fxLayoutAlign="center center">
            <p>{{ indicator.firstIndicatorNumberHint }}</p>
          </div>
        </div>
          
        <div fxFlex="66" fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign="start start"
          [ngClass]="calculateClassSecondContainerDiv()">
          <div class="full-width" fxFlex *ngIf="!areUsersList && !areObjectsList">
            <div class="itemList-container full-width"
              *ngFor="let result of indicator.itemList | slice:0:6; let i = index" fxLayout="row"
              fxLayoutAlign="space-between center">
              <div class="full-width" fxLayout="row">
                <p class="text-row">{{result.text}}</p>
              </div>
              <div class="itemNumber" [ngStyle]="{ opacity: 1 - (i/6) }" fxLayout="row">
                <p>{{result.count}}</p>
              </div>
            </div>
          </div>

          <div class="full-width" fxFlex *ngIf="areUsersList && indicator.id==='RANK_MORE_ACTIVE_TEACHERS'  ">
            <div *ngFor="let result of indicator.itemList | slice:0:3; let i = index" fxLayout="row">
              <div class="img-wrapper" fxLayout="row" fxLayoutAlign="center center">
                <img *ngIf="result.teacher" [src]="getAvatarUrl(result.teacher)" alt="avatar" />
              </div>
              <div *ngIf="result.teacher" fxLayout="column" fxLayoutAlign="center start">
                <h4 class="bold-text">{{result.teacher.surname + " " + result.teacher.forename}}</h4>
                <p>{{("generic.TEACHINGS_TUTORINGS" | translate) +": " + result.data.total}}</p>
              </div>
            </div>
          </div>
          <div *ngIf="areUsersList && indicator.id==='RANK_MORE_ACTIVE_TEACHERS' && indicator.itemList && indicator.itemList.length && indicator.itemList.length > 3"
            fxFlex>
            <div *ngFor="let result of indicator.itemList | slice:3:6; let i = index" fxLayout="row">
              <div class="img-wrapper" fxLayout="row" fxLayoutAlign="center center">
                <img *ngIf="result.teacher" [src]="getAvatarUrl(result.teacher)" alt="avatar" />
              </div>
              <div *ngIf="result.teacher" fxLayout="column" fxLayoutAlign="center start">
                <h4 class="bold-text">{{result.teacher.surname + " " + result.teacher.forename}}</h4>
                <p>{{("generic.TEACHINGS_TUTORINGS" | translate) +": " + result.data.total}}</p>
              </div>
            </div>
          </div>

          <div class="full-width" fxFlex *ngIf="areUsersList && indicator.id==='RANK_MORE_ACTIVE_USERS'">
            <div *ngFor="let result of indicator.itemList | slice:0:3; let i = index" fxLayout="row">
              <div class="img-wrapper" fxLayout="row" fxLayoutAlign="center center">
                <img *ngIf="result.user" [src]="getAvatarUrl(result.user)" alt="avatar" />
              </div>
              <div *ngIf="result.user" fxLayout="column" fxLayoutAlign="center start">
                <h4 class="bold-text">{{result.user.surname + ' ' + result.user.forename}}</h4>
                <p>{{ ('generic.LOGINS' | translate) + ': ' + ((result.data.loginsCount | numberAbbreviation:1) || 0) +
                  ', ' + ('dashboard.LIKES_LICT' | translate) + ': ' + ((result.data.likesCount | numberAbbreviation:1)
                  || 0) }}
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="areUsersList && indicator.id === 'RANK_MORE_ACTIVE_USERS' && indicator.itemList && indicator.itemList.length && indicator.itemList.length > 3"
            fxFlex>
            <div *ngFor="let result of indicator.itemList | slice:3:6; let i = index" fxLayout="row">
              <div class="img-wrapper" fxLayout="row" fxLayoutAlign="center center">
                <img *ngIf="result.user" [src]="getAvatarUrl(result.user)" alt="avatar" />
              </div>
              <div *ngIf="result.user" fxLayout="column" fxLayoutAlign="center start">
                <h4 class="bold-text">{{result.user.surname + ' ' + result.user.forename}}</h4>
                <p>{{ ('generic.LOGINS' | translate) + ': ' + ((result.data.loginsCount | numberAbbreviation:1) || 0) +
                  ', ' + ('dashboard.LIKES_LICT' | translate) + ': ' + ((result.data.likesCount | numberAbbreviation:1)
                  || 0) }}
                </p>
              </div>
            </div>
          </div>

          <div class="full-width" fxFlex *ngIf="areObjectsList">
            <div *ngFor="let result of indicator.itemList | slice:0:3; let i = index" fxLayout="row">
              <div *ngIf="result.item" style="padding-bottom: 5px" fxLayout="column" fxLayoutAlign="center start">
                <h4 class="bold-text">{{result.item.title }} ({{('generic.itemTypes.' + (result && result.item &&
                  result.item.itemAttributes[0] && result.item.itemAttributes[0].attributeValue) | translate)}})</h4>
                <p>{{ ('generic.VIEWS' | translate) + ': ' + ((result.engagementsCount | numberAbbreviation:1) || 0) }}
                </p>
              </div>
            </div>
          </div>
          <div class="full-width"  *ngIf="acquiredCompetencesList || desiredCompetencesList">
            <div *ngFor="let result of indicator.itemList | slice:0:5; let i = index" fxLayout="row">
              <div *ngIf="result" style="padding-bottom: 5px" fxLayout="column" fxLayoutAlign="left ">
                <h4 class="bold-text">{{result.competenceName }} </h4>
                <p>{{ ('generic.LEVEL'| translate ) + ': ' + result.levelName + ' - '+ ('generic.DIFFUSION'| translate ) + ': ' + result.diffusion + "%"}}</p>
              </div>
            </div>
          </div>
          <!-- <div class="full-width"  *ngIf="desiredCompetencesList">
            <div *ngFor="let result of indicator.itemList | slice:0:5; let i = index" fxLayout="row">
              <div *ngIf="result" style="padding-bottom: 5px" fxLayout="column" fxLayoutAlign="left ">
                <h4 class="bold-text">{{result.competenceName }} </h4>
                <p>{{ ('generic.LEVEL'| translate ) + ': ' + result.levelName + ' - '+ ('generic.DIFFUSION'| translate ) + ': ' + result.diffusion + "%"}}</p>
              </div>
            </div>
          </div> -->
          <div
            *ngIf="areObjectsList && indicator.itemList && indicator.itemList.length && indicator.itemList.length > 3"
            fxFlex>
            <div *ngFor="let result of indicator.itemList | slice:3:6; let i = index" fxLayout="row">
              <div *ngIf="result.item" style="padding-bottom: 5px" fxLayout="column" fxLayoutAlign="center start">
                <h4 class="bold-text">
                  {{result.item.title }} ({{('generic.itemTypes.' + (result && result.item &&
                  result.item.itemAttributes[0] && result.item.itemAttributes[0].attributeValue) | translate)}})</h4>
                <p>{{ ('generic.VIEWS' | translate) + ': ' + ((result.engagementsCount | numberAbbreviation:1) || 0) }}
                </p>
              </div>
            </div>
          </div>
        </div>



      </div>
    </div>
  </div>
  <div fxLayout="row" class="widget-footer" fxLayoutAlign="end center">
    <p class="cursor-pointer" (click)="emitOnMainButtonClick()">{{ indicator.buttonText }} </p>
    <svg-icon src="assets/icons/icon-angle-right.svg"></svg-icon>
  </div>

  

</div>