/*
*  Lista di azioni riguardanti gli item
*/

import { createAction, props } from '@ngrx/store';
import { Item } from 'src/cm2-commonclasses';


// Torna un item
export const GetItem = createAction(
  '[items] get item',
  props<{ payload: string }>()
);

// Cancella un item
export const DeleteItem = createAction(
  '[items] delete item',
  props<{ payload: string }>()
);

// Setta il fatto se escludere o meno gli item contenitori nella ricerca
export const SetExcludeContainerInSearch = createAction(
  '[items] set exclude container in search',
  props<{ hasToExcludeContainer: boolean }>()
);

// Setta la tab da visualizzare (definitivi/bozze)
export const SetItemListShowFor = createAction(
  '[items] set item list show for',
  props<{ showFor: string }>()
);

// Setta il filtro per la ricerca testuale
export const SetItemListTextFilter = createAction(
  '[items] set item list text filter',
  props<{ itemListTextFilter: string }>()
);

// Setta il contatore degli item recuperati da remoto
export const SetItemListCount = createAction(
  '[items] set item list count',
  props<{ counter: number }>()
);

// Setta la pagina corrente
export const SetPage = createAction(
  '[items] set page',
  props<{ pageNumber: number }>()
);

// Salva la lista di item recuperati
export const SetItemList = createAction(
  '[items] set item list',
  props<{ items: Item[] }>()
);

// Setta il filtro per la tipologia
export const SetItemListFilterType = createAction(
  '[items] set item list filter type',
  props<{ filterType: string }>()
);

// Setta la paginazione
export const SetPagination = createAction(
  '[items] set pagination',
  props<{ payload: { fromRecord: number, numRecords: number } }>()
);

// Recupera la lista di item
export const GetItemList = createAction(
  '[items] get item list',
  props<{ payload?: { searchInitiativesForBlendedLibraryItems?: boolean, searchBlendedInitiatives?: boolean, searchBlendedProject?: boolean, isBlendedSection?: boolean } }>()
);