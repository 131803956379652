/*
* Componente che crea una singola Tab
*/

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AzurePlayerService } from './azure-player.service';
import { parseBoolean, VideoSubtitle } from '../../../cm2-commonclasses';
import { UrlService } from "../../shared/services/url.service";
declare let amp: any;

@Component({
  selector: 'azure-player',
  templateUrl: './azure-player.component.html',
  styleUrls: ['./azure-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AzurePlayerComponent implements OnInit {
  @ViewChild('video') videoPlayer;
  @Input() isDisabled: boolean;
  @Input() itemId: string;
  @Input() playerVisible: boolean;
  @Input() seekTo: number;
  @Input() mimeType: string;
  @Input() objectUrl: string;
  @Input() fullScreen: boolean;
  @Input() adminMode: boolean;
  @Input() subtitles: VideoSubtitle[];
  @Input() preventUserControl: boolean;
  lastInProgressUpdate: number;
  watchInterval;
  azureMediaPlayer;
  @ViewChild("contentPlayer") contentPlayerElement: ElementRef;
  @Output() itemInProgress = new EventEmitter<any>();
  @Output() itemConsumed = new EventEmitter<any>();
  @Output() playerStatusChange = new EventEmitter<any>();

  constructor(protected _sanitizer: DomSanitizer,
    private playerService: AzurePlayerService,
    private urlService: UrlService,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.playerVisible = parseBoolean(this.playerVisible);
    this.adminMode = parseBoolean(this.adminMode);
    if (!this.objectUrl) {
      this.playerVisible = false;
    }
    if (this.playerVisible) {
      this.initVideoPlayer();
    }
  }

  createWatchInterval() {
    this.watchInterval = setInterval(() => {
      let now = new Date().getTime();
      // Se sono passati più di 5 secondi dall'ultima volta che sono passato per questo evento, invio lo stato di avanzamento al sistema
      let totalTime = this.azureMediaPlayer.duration();
      let currentTime = this.azureMediaPlayer.currentTime();
      // Quando arriva alla fine l'oggetto torna in paused con currentTime prossimo o superiore a totalTime, quindi come area tengo l'1% max 1 secondo
      let safeLimit = (totalTime * 0.01) < 1 ? totalTime * 0.99 : totalTime - 1;
      // Evito di salvarmi le informazione di avanzamento quando sono troppo prossimo alla fine per evitare conflitti con il completato
      if ((!this.lastInProgressUpdate || this.lastInProgressUpdate + 5000 < now) && currentTime < safeLimit) {
        let viewedSeconds = 0;
        if (this.lastInProgressUpdate) {
          viewedSeconds = (now - this.lastInProgressUpdate) / 1000;
        }
        this.lastInProgressUpdate = now;
        // Non mi interessa l'esito di questa operazione (se va male, amen)
        this.playerService.updatePlayerStatus(this.itemId, "viewed", totalTime, currentTime, viewedSeconds).subscribe();
      }
    }, 500);
  }

  disposeWatchInterval = () => {
    if (this.watchInterval) {
      clearInterval(this.watchInterval);
      this.watchInterval = null;
    }
  }
  /*
      ngOnDestroy() {
          this.disposeWatchInterval();
          // Dato che Azure sembra non eliminare i listener correttamente, elimino l'itemId dallo scope, così da impedire l'invio di notifiche multiple.
          this.itemId = null;
          // Distruggo la widget di Azure
          if (this.azureMediaPlayer) {
              this.azureMediaPlayer.dispose();
          }
      } */

  initVideoPlayer() {
    let _this = this;
    // E chiamo il metodo che registra gli hooks e inizializza il contenuto multimediale
    this.azureMediaPlayer = amp('contentPlayer', {
      /* Options */
      techOrder: ["azureHtml5JS", "flashSS", "html5FairPlayHLS", "silverlightSS", "html5"],
      nativeControlsForTouch: false,
      autoplay: false,
      controls: true,
      fluid: true,
      logo: { enabled: false }
      /*
      width: "640",
      height: "400",
      poster: ""
      */
    }, function () {
      /*
      Altri possibili eventi da gestire: http://amp.azure.net/libs/amp/latest/docs/index.html#amp.eventname
      */

      if (_this.itemId && !_this.adminMode) {
        let ignoreFirstSeek = false;
        let ignoreNextSeek = false;
        let lastKnownCurrentTime: number;


        // Aggiungo gli Event Listener per tracciare i vari eventi
        this.addEventListener('loadeddata', () => {
          // Se devo quindi riprendo da dov'ero, ma dato che il player di Azure gestisce molto male la fine del filmato, se ci sono vicino, non riporto l'dipendente lì
          let totalTime = _this.azureMediaPlayer.duration();
          if (_this.seekTo && (_this.seekTo < totalTime - 3 /* sec */)) {
            ignoreFirstSeek = true;
            lastKnownCurrentTime = _this.seekTo;
            _this.azureMediaPlayer.currentTime(_this.seekTo);
            _this.seekTo = null;
          }
        });

        this.addEventListener('pause', () => {
          _this.disposeWatchInterval();
          let now = new Date().getTime();
          let totalTime = _this.azureMediaPlayer.duration();
          let currentTime = _this.azureMediaPlayer.currentTime();
          let viewedSeconds = 0;
          if (_this.lastInProgressUpdate) {
            viewedSeconds = (now - _this.lastInProgressUpdate) / 1000;
          }
          // Quando arriva alla fine l'oggetto torna in paused con currentTime prossimo o superiore a totalTime, quindi come area tengo l'1% max 1 secondo
          let safeLimit = (totalTime * 0.01) < 1 ? totalTime * 0.99 : totalTime - 1;
          // Quando arriva alla fine l'oggetto torna in paused con currentTime prossimo o superiore a totalTime, quindi questa combinazione la ignoro
          if (!(currentTime >= safeLimit)) { // Il player di Azure gestisce molto male la fine del filmato, quindi tengo un lasco grande
            // Nel caso in cui questa pausa sia dovuta al termine del video non azzero il tempo, sennò lo perderei dall'evento end
            _this.lastInProgressUpdate = 0; // Forzo a 0 così l'eventuale seek da pausa non genera tempo consumato
            // Non mi interessa l'esito di questa operazione (se va male, amen)
            _this.playerService.updatePlayerStatus(_this.itemId, "paused", totalTime, currentTime, viewedSeconds).subscribe();

            _this.itemInProgress.emit({ currentTime: currentTime, totalTime: totalTime });
          }
        });

        this.addEventListener('playing', () => {
          if (!_this.watchInterval) {
            let now = new Date().getTime();
            let totalTime = _this.azureMediaPlayer.duration();
            let currentTime = _this.azureMediaPlayer.currentTime();
            let viewedSeconds = 0;
            if (_this.lastInProgressUpdate) {
              viewedSeconds = (now - _this.lastInProgressUpdate) / 1000;
            }
            _this.lastInProgressUpdate = now;
            _this.playerService.updatePlayerStatus(_this.itemId, "playing", totalTime, currentTime, viewedSeconds).subscribe();
            _this.itemInProgress.emit({ currentTime: currentTime, totalTime: totalTime });
            _this.createWatchInterval();
          }
        });

        this.addEventListener('timeupdate', () => {
          // Aggiorna la posizione corrente memorizzata alla posizione corrente del video
          // Questo assicura che il valore di currentPosition sia sempre aggiornato
          // (solo se non sto saltando programmaticamente ad un nuovo punto del video)
          if (!ignoreNextSeek && !ignoreFirstSeek) {
            lastKnownCurrentTime = _this.azureMediaPlayer.currentTime();
          }
        });

        this.addEventListener('ended', () => {
          // Per evitare che usi lo slidere per arrivare alla fine nel caso non possa muoversi
          // non gesisco l'evento end così da permettere al player di tornare alla posizione precedente
          if (!ignoreNextSeek) {
            _this.disposeWatchInterval();
            let now = new Date().getTime();
            // Segnalo il termine della fruizione
            _this.itemConsumed.emit(true);
            let totalTime = _this.azureMediaPlayer.duration();
            let currentTime = _this.azureMediaPlayer.currentTime();
            let viewedSeconds = 0;
            if (_this.lastInProgressUpdate) {
              viewedSeconds = (now - _this.lastInProgressUpdate) / 1000;
            }
            _this.lastInProgressUpdate = 0; // Ho finito di vederlo, fino al prossimo play non devo tracciare niente
            // Non mi interessa l'esito di questa operazione (se va male, amen)
            _this.playerService.updatePlayerStatus(_this.itemId, "end", totalTime, currentTime, viewedSeconds).subscribe();
          }
        });

        this.addEventListener('rewind', () => {
          if (_this.preventUserControl) {
            // Se l'utente non può muoversi nel video indico anche che dovrò ignorare il prossimo
            // evento di seek per evitare un loop infinto e per non salvare il tempo della posizione attuale
            ignoreNextSeek = true;
          }
        });

        this.addEventListener('seeking', () => {
          if (_this.preventUserControl) {
            // Se l'utente non può muoversi nel video indico anche che dovrò ignorare il prossimo
            // evento di seek per evitare un loop infinto e per non salvare il tempo della posizione attuale
            ignoreNextSeek = true;
          }
        });

        this.addEventListener('seeked', () => {
          ignoreNextSeek = false;
          if (ignoreFirstSeek) {
            ignoreFirstSeek = false;
          } else {
            let currTime = _this.azureMediaPlayer.currentTime();
            if (_this.preventUserControl && lastKnownCurrentTime != currTime) {
              // Se il delta è davvero piccolo, evito di fare il salto perché a volte per questo va in loop infinito
              if (Math.abs(currTime - lastKnownCurrentTime) >= 0.1) {
                // Se l'utente non può muoversi nel video, ritorno all'ultima posizione nota
                _this.azureMediaPlayer.currentTime(lastKnownCurrentTime);
              }
            } else {
              let now = new Date().getTime();
              let totalTime = _this.azureMediaPlayer.duration();
              let currentTime = _this.azureMediaPlayer.currentTime();
              let viewedSeconds = 0;
              if (_this.lastInProgressUpdate) {
                viewedSeconds = (now - _this.lastInProgressUpdate) / 1000;
              }
              // Dato che non so il media è in play o meno e che se l'utente' facesse un seek, poi aspettasse ore e poi un altro seek il tutto in pausa
              // verrebbero tracciate le ore, quindi forzo a zero. So che così l'dipendente perderà il tempo visto tra un seek e l'altro se lo fa entro 5 secondi
              // ma tutto sommato è un tempo sacrificabile e ci può stare: premiamo chi vede di fila, non ci salta di continuo.
              _this.lastInProgressUpdate = 0;
              // Non mi interessa l'esito di questa operazione (se va male, amen)
              _this.playerService.updatePlayerStatus(_this.itemId, "seeked", totalTime, currentTime, viewedSeconds).subscribe();
            }
          }
        });

        this.addEventListener('disposing', () => {
          _this.disposeWatchInterval();
        });

        // Se l'utente non può muoversi nel video, blocco le funzioni di tastiera
        if (_this.preventUserControl) {
          // Disabilita azioni specifiche della tastiera
          this.addEventListener('keydown', function (event) {
            event.preventDefault();
          });
        }
      }

      // Ora aggiungo l'URL dell'oggetto multimediale
      let mediaUrl = [{
        src: _this.objectUrl,
        type: _this.mimeType || "application/vnd.ms-sstr+xml"
      }];
      // Se devo ci aggiungo anche i sottotitoli
      let subtitleUrls = null;
      if (_this.subtitles && _this.subtitles.length) {
        subtitleUrls = _this.subtitles.map(s => {
          return {
            src: _this.urlService.getApplicationUrl().baseUrl + "rest-api/corporateacademy-mediator/proxy-url/" + s.lang + ".vtt?requestUrl=" + encodeURIComponent(s.url),
            srclang: s.lang,
            kind: "subtitles",
            label: s.title
          };
        });
      }
      // E infine imposto i dati nel player così da caricarli
      if (subtitleUrls) {
        _this.azureMediaPlayer.src(mediaUrl, subtitleUrls);
      }
      else {
        _this.azureMediaPlayer.src(mediaUrl);
      }
    });
  }

  ngOnDestroy() {
    this.disposeWatchInterval();
    // Dato che Azure sembra non eliminare i listener correttamente, elimino l'itemId dallo scope, così da impedire l'invio di notifiche multiple.
    this.itemId = null;
    // Distruggo la widget di Azure
    if (this.azureMediaPlayer) {
      this.azureMediaPlayer.dispose();
    }
  }
}