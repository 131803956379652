/*
*  Reducer che gestiscono e modificano lo State applicativo riguardanti i gruppi
*/

import * as UsersAdminActions from './usersAdmin.actions';
import * as fromApp from '../../ngrx/app.reducers';
import { User } from '../../../cm2-commonclasses';

// I gruppi sono una feature dell'applicazione, infatti è un modulo caricato in maniera lazy (e quindi anche lo Store).
// Creo un'interfaccia, FeatureState, in cui mappo i gruppi
// Estendo fromApp.AppState così per ereditare tutte le proprietà dello Store principale
export interface FeatureState extends fromApp.AppState {
  usersAdmin: UsersAdminState
}

// Interfaccia dello Store
export interface UsersAdminState {
  usersAdmin: User[];
  count: number;
  fromRecord: number;
  numRecords: number;
  page: number;
  isFetchingUsersAdmin: boolean;
  usersAdminTextFilter: string;
  socDistacco: string;
  externalOnly: boolean;
  notExternalOnly: boolean;
  toBeConfirmed: boolean;
}

// Store iniziale
const initialUsersAdminState: UsersAdminState = {
  usersAdmin: [],
  count: 0, // contatore totale dei template
  fromRecord: 0, // numero da cui recuperare gli elementi
  numRecords: 20, // elementi da recuperare
  page: 1, // pagina della tabella visualizzata
  isFetchingUsersAdmin: false, // verifica se sto eseguendo una chiamata per recuperare i template
  usersAdminTextFilter: null, // searchedText input
  socDistacco: null,
  externalOnly: null,
  notExternalOnly: true,
  toBeConfirmed: false
};

export function UsersAdminReducer(state = initialUsersAdminState, action: UsersAdminActions.UsersAdminActions) {
  switch (action.type) {
    case UsersAdminActions.SET_USERS:
      return {
        ...state,
        isFetchingUsersAdmin: false,
        usersAdmin: [...action.payload]
      };
    case UsersAdminActions.SET_PAGINATION_USERS:
      return {
        ...state,
        fromRecord: action.payload.fromRecord,
        numRecords: action.payload.numRecords
      };
    case UsersAdminActions.GET_USERS:
      return {
        ...state,
        isFetchingUsersAdmin: true
      };
    case UsersAdminActions.SET_PAGE_USERS:
      return {
        ...state,
        page: action.payload
      };
    case UsersAdminActions.SET_USERS_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    case UsersAdminActions.SET_USERS_TEXT_FILTER:
      return {
        ...state,
        usersAdminTextFilter: action.payload
      };
    case UsersAdminActions.SOC_DISTACCO:
      return {
        ...state,
        socDistacco: action.payload
      }
    case UsersAdminActions.EXTERNAL_ONLY:
      return {
        ...state,
        externalOnly: action.payload
      }
    case UsersAdminActions.NOT_EXTERNAL_ONLY:
      return {
        ...state,
        notExternalOnly: action.payload
      };
    case UsersAdminActions.GET_FILTERED_USERS:
      return {
        ...state,
        isFetchingUsersAdmin: true
      };
    case UsersAdminActions.SET_FILTERED_USERS:
      return {
        ...state,
        isFetchingUsersAdmin: false,
        usersAdmin: [...action.payload]
      };
    case UsersAdminActions.SET_FILTERED_USERS_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    case UsersAdminActions.SET_TO_BE_CONFIRMED:
      return {
        ...state,
        toBeConfirmed: action.payload,
      };
    default:
      return state;
  }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getUsersAdmin = (state: UsersAdminState) => (state && state.usersAdmin) || [];
export const getUsersAdminCount = (state: UsersAdminState) => state.count;
export const getUsersAdminFromRecord = (state: UsersAdminState) => (state && state.fromRecord) || 0;
export const getUsersAdminNumRecords = (state: UsersAdminState) => (state && state.numRecords) || 20;
export const getUsersAdminPage = (state: UsersAdminState) => (state && state.page) || 0;
export const isFetchingUsersAdmin = (state: UsersAdminState) => state.isFetchingUsersAdmin;
export const getUsersAdminTextFilter = (state: UsersAdminState) => (state && state.usersAdminTextFilter) || null;
export const socDistacco = (state: UsersAdminState) => (state && state.socDistacco) || null;
export const externalOnly = (state: UsersAdminState) => (state && state.externalOnly) || null;
export const notExternalOnly = (state: UsersAdminState) => (state && state.externalOnly) || null;
export const getFilteredUserAdmin = (state: UsersAdminState) => (state && state.usersAdmin) || [];
export const getFilteredUsersAdminCount = (state: UsersAdminState) => state.count;
export const getToBeConfirmed = (state: UsersAdminState) => state.toBeConfirmed;
export const getIsExternal = (state: UsersAdminState) => state?.externalOnly;
export const getIsNotExternal = (state: UsersAdminState) => state.notExternalOnly;
export const getSocDistacco = (state: UsersAdminState) => state.socDistacco;

