/*
 * Servizio che verifica dallo State, unica fonte di verità, se l'utente è correttamente loggato e autenticato
*/

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { take, map } from 'rxjs/operators';
// Import dello State dell'applicativo
import * as fromApp from '../../ngrx/app.reducers';
// Import del tipo di informazione che estrapolerò dallo State
// Import delle azioni dell'auth
import * as AuthActions from '../ngrx/auth.actions';
// Import delle azioni del core
import * as CoreActions from '../../core/ngrx/core.actions';
import { Observable, combineLatest } from 'rxjs';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

function removeURLParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split('?');
  if (urlparts.length >= 2) {

    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0;) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private toastr: ToastrService, private translate: TranslateService, private store: Store<fromApp.AppState>, private route: ActivatedRoute, private router: Router, private authService: AuthService) {
  }

  // Ritorna un Observable che, risolvendo alla fine un boolean, possiamo mapparlo
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest(this.store.pipe(select(fromApp.getAuthAppState)),
      this.store.pipe(select(fromApp.getProfileAppState))
    ).pipe(
      map(([authState, profileState]) => {
        let url = state.url;
        let isTakerUrl = url && url.indexOf('takers') !== -1;
        let isTeacherUrl = url && url.indexOf('teachers/') !== -1;
        let urlWithoutToken = null;
        let skipSso: boolean;

        if (authState.authenticated) {
          // Utente correttamente loggato e può accedere alla risorsa
          return true;
        }

        // Potrebbe essere stato fatto un refresh, di conseguenza non c'è più il dato all'interno dello State. Guardo quindi se ho il token in sessione, perché se ce l'avessi significa che l'utente è già autenticato
        let sessionStorageToken: string = sessionStorage.getItem('token');

        let localStorageToken: string = localStorage.getItem("token");
        if (!sessionStorageToken && localStorageToken) {
          sessionStorage.setItem("token", localStorageToken);
          sessionStorageToken = localStorageToken;
        }

        if (!urlWithoutToken) {
          urlWithoutToken = url;
        }

        const query =
          window.location.search.substring(1) ||
          window.location.hash.substring(1);
        const vars = query.split("&");
        let skipSsoParam;
        let ssortkqpParam;
        let urlToken: string = null;
        if (vars && vars.length) {
          for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            if (
              decodeURIComponent(pair[0]) == "skipSso" ||
              pair[0].includes("skipSso")
            ) {
              skipSsoParam = decodeURIComponent(pair[1]);
              break;
            }
            if (
              decodeURIComponent(pair[0]) == "token" ||
              pair[0].includes("token")
            ) {
              urlToken = decodeURIComponent(pair[1]);
              sessionStorage.setItem("token", urlToken);
              sessionStorageToken = urlToken;
              break;
            } else if (
              decodeURIComponent(pair[0]) == "ssortkqp" ||
              pair[0].includes("ssortkqp")
            ) {
              ssortkqpParam = decodeURIComponent(pair[1]);
              break;
            }
          }
        }

        if (skipSsoParam) {
          skipSso = true;
          url = removeURLParameter(url, "skipSso");
        }

        // Salvataggio se dell'url di redirect se questo è tra gli entrypoint consentiti
        if (url) {
          if (ssortkqpParam) {
            url = removeURLParameter(url, "ssortkqp");
          }
          this.store.dispatch(new CoreActions.SaveRedirectUrl(url));
          sessionStorage.setItem("redirectUrl", url);
        }

        if (ssortkqpParam && !urlToken) {
          let isTaker = (sessionStorage.getItem('isTaker') && sessionStorage.getItem('isTaker') == "true") || (url && url.indexOf('takers') !== -1);
          let isTeacher = (sessionStorage.getItem('isTeacher') && sessionStorage.getItem('isTeacher') == "true") || (url && url.indexOf('teachers/') !== -1);
          const getTokenFromKeyPromise = this.getTokenFromSsortkqp(ssortkqpParam);
          getTokenFromKeyPromise.then((token: string) => {
            if (token && token.length) {
              sessionStorage.setItem("token", token);
              this.store.dispatch(AuthActions.SetUserAuthenticated({ isTaker: isTaker, isTeacher: isTeacher }));
              this.store.dispatch(new CoreActions.StartRenewTokenPolling({ redirectUrl: url }));
              return true;
            } else {
              let url = state.url;
              if (url) {
                if (skipSsoParam) {
                  skipSso = true;
                  url = removeURLParameter(url, "skipSso");
                }
                if (ssortkqpParam) {
                  url = removeURLParameter(url, "ssortkqp");
                }
                this.store.dispatch(new CoreActions.SaveRedirectUrl(url));
              }
              if (skipSso) {
                if (isTakerUrl) {
                  isTaker = true;
                  this.router.navigate(['/localLogin']);
                }
                if (isTeacherUrl) {
                  isTeacher = true;
                  this.router.navigate(['/teacherLogin']);
                }
              } else {
                this.router.navigate(["/login"]);
              }
            }
          })
            .catch((e) => {
              this.toastr.error(this.translate.instant('errors.' + e));
              // Se l'errore riguarda le auth allora vado nella pagina 403
              if (e && e.indexOf('AUTH') !== -1) {
                this.router.navigate(["/403"]);
              }
            })
        } else {
          let isTaker = (sessionStorage.getItem('isTaker') && sessionStorage.getItem('isTaker') == "true") || (url && url.indexOf('takers') !== -1);
          let isTeacher = (sessionStorage.getItem('isTeacher') && sessionStorage.getItem('isTeacher') == "true") || (url && url.indexOf('teachers/') !== -1);
          if (sessionStorageToken) {
            // Non è ancora autenticato l'utente ma avevo il token
            // Setto l'utente come autenticato
            this.store.dispatch(AuthActions.SetUserAuthenticated({ isTaker: isTaker, isTeacher: isTeacher }));
            // Ri inizio il polling per il token. L'effect si occuperà anche di settare il nuovo token e di decodificarlo

            this.store.dispatch(new CoreActions.StartRenewTokenPolling({ redirectUrl: urlWithoutToken }));
            return true;
          } else {
            // Utente non loggato, quindi redirect alla pagina di Login. Prima però salvo nello Store l'url corrente affinché, una volta eseguito correttamente il login, si esegua il redirect nella pagina richiesta
            let isTaker = false;
            let isTeacher = false;


            if (urlWithoutToken) {
              this.store.dispatch(new CoreActions.SaveRedirectUrl(urlWithoutToken));
              if (isTakerUrl) {
                isTaker = true;
                this.router.navigate(['/login']);
              }
              if (isTeacherUrl) {
                isTeacher = true;
                this.router.navigate(['/teacherLogin']);
              }
            }
            if (!isTaker && !isTeacher) {
              this.router.navigate(['/login']);
            }
          }
        }
        return false;
      }));
  }

  // Recupera token dalla chiave dell'url
  getTokenFromSsortkqp(key: string) {
    return new Promise((resolve, reject) => {
      this.authService.retrieveTokenAfterLogin(key).subscribe((senecaResponse) => {
        if (senecaResponse.error) {
          reject(senecaResponse.error);
        } else {
          if (senecaResponse && senecaResponse.response) {
            resolve(senecaResponse.response);
          } else {
            resolve(null);
          }
        }
      },
        (err) => {
          reject(err);
        })
    })
  }
}
