/*
*  Componente che gestisce l'home page dell'applicazione
*/

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { Item, PaginationWrapper, SenecaResponse, ItemAttributeObjectTypes, ItemAttributeTypes, ItemTypes, JwtPayload } from "../../../cm2-commonclasses";
import * as InitiativeActions from '../../initiatives/ngrx/initiative.actions';
import * as fromApp from '../../ngrx/app.reducers';
import { BaseSubscriberComponent } from '../../shared/components/base-subscriber.component';
import { CourseEditionUtil } from '../../shared/models/course.model';
import { ExtendedItem } from '../../shared/models/extended-item.model';
import { RedirectService } from '../../shared/services/redirect.service';
import { ItemsService } from '../services/items.service';
import { LangsService } from '../services/langs.service';
import { ItemUtil } from '../../shared/models/item.model';
import { ModalService } from 'library-alloy/dist/library-alloy';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Title } from '@angular/platform-browser';
import { CourseEditionService } from 'src/app/initiatives/services/course-edition.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseSubscriberComponent implements OnDestroy, OnInit {
  getLastInitiatives$: Subscription;
  lastInitiatives: Item[];
  initiativesLoading: boolean;
  radioPossibilities = [1, 2, 3];
  selectedCreationPossibility = { options: '1' };
  result$: Subscription;
  applicationLang: string;
  initiativeToDelete: Item;
  showInitiativeFor;
  isMenuVisible: boolean[];
  dateFormat: string;
  selectedTypeOfNewItem;
  selectedItemTypesOptions;
  selectedItemTypesForItemCreationOptions: any[];
  canAccessManageLibrary: boolean;
  loggedUser: JwtPayload;

  constructor(private itemsService: ItemsService,
    private translate: TranslateService,
    private router: Router,
    private redirectService: RedirectService,
    private langsService: LangsService,
    private modalService: ModalService,
    private store: Store<fromApp.AppState>,
    private authService: AuthService,
    private titleService: Title,
    private courseEditionService: CourseEditionService,
    private toastr: ToastrService) {
    super();
    // titolo necessario per jaws
    titleService.setTitle("Home");
    this.canAccessManageLibrary = environment.canAccessManageLibrary;
  }

  ngOnInit() {
    let translationsToRetrieve = ['generic.ALL', 'generic.DATE_FORMAT_FULL'];
    for (let a = 0, typesLength = environment.libraryItemTypes.length; a < typesLength; a++) {
      translationsToRetrieve.push("generic.itemTypes." + environment.libraryItemTypes[a])
    }

    // Costruisco la la lista delle tipologie dell'iniziativa
    this.translate.get(
      translationsToRetrieve).subscribe(translations => {
        this.dateFormat = translations['generic.DATE_FORMAT_FULL'];

        this.selectedItemTypesOptions = [
          { name: translations['generic.ALL'], code: 'ALL' }];

        for (let b = 0, typesLength = environment.libraryItemTypes.length; b < typesLength; b++) {
          const translatedItem = translations["generic.itemTypes." + environment.libraryItemTypes[b]];
          this.selectedItemTypesOptions.push(
            { name: translatedItem, code: environment.libraryItemTypes[b] }
          );
        }

        this.selectedItemTypesForItemCreationOptions = [...this.selectedItemTypesOptions];
        this.selectedItemTypesForItemCreationOptions.splice(0, 1);
      });


    if (!this.lastInitiatives) {
      this.initiativesLoading = true;
    }
    this.showInitiativeFor = {
      inProgressTabOpened: sessionStorage.getItem('inProgressTabOpened') && (sessionStorage.getItem('inProgressTabOpened') == 'true'),
      suspendedTabOpened: sessionStorage.getItem('suspendedTabOpened') && (sessionStorage.getItem('suspendedTabOpened') == 'true'),
      archivedTabOpened: sessionStorage.getItem('archivedTabOpened') && (sessionStorage.getItem('archivedTabOpened') == 'true'),
    };
    if (!this.showInitiativeFor.suspendedTabOpened && !this.showInitiativeFor.inProgressTabOpened && !this.showInitiativeFor.archivedTabOpened) {
      this.showInitiativeFor.suspendedTabOpened = true;
    }

    // Utente loggato
    let loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    // Lingua dell'applicazione
    let getApplicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang);

    const combinedSelectes$ = combineLatest(loggedUser$, getApplicationLang$);
    this.result$ = combinedSelectes$.subscribe(
      ([loggedUser, applicationLang]) => {
        this.loggedUser = loggedUser;
        this.applicationLang = applicationLang;
        if (this.applicationLang && !this.lastInitiatives && !this.getLastInitiatives$) {
          if (this.showInitiativeFor.inProgressTabOpened) {
            this.onInitiativeStatusFilterChanged('inProgressTabOpened');
          } else if (this.showInitiativeFor.suspendedTabOpened) {
            this.onInitiativeStatusFilterChanged('suspendedTabOpened');
          } else if (this.showInitiativeFor.archivedTabOpened) {
            this.onInitiativeStatusFilterChanged('archivedTabOpened');
          }
        };
        this.isMenuVisible = [];
      })
  }

  chooseNewItemTypeModal(selectedType: any) {
    // Salva la selezione del tipo di item da creare
    this.selectedTypeOfNewItem = selectedType;
  }

  // Effettua il toggle sulla selezione del modo con cui creare l'iniziativa
  toggledChange(newValue: string): void {
    this.selectedCreationPossibility.options = newValue;
  }

  toggleMenu(index: number) {
    this.isMenuVisible[index] = !this.isMenuVisible[index];
  }

  // Pulisce dalla memoria la selezione del template scelto per la rimozione
  clearInitiativeToDelete() {
    // Pulisco le iniziative
    this.initiativeToDelete = null;
  }

  // Annulla l'operazione di cancellare l'iniziativa, chiudendo la mdoale
  cancelDeleteInitiative() {
    return;
  }

  // Apre la modale per scegliere la tipologia di item da creare
  openChooseNewItemTypeModal() {
    this.modalService.open('select-new-item-type');
  }

  // Chiude la modale per scegliere la tipologia di item da creare, andando nella corrispettiva pagina di creazione
  closeChooseNewItemTypeModal(confirm?: boolean) {
    // Chiudo la modale
    this.modalService.close('select-new-item-type');
    if (confirm) {
      if (this.selectedTypeOfNewItem) {
        if (this.selectedTypeOfNewItem.code === ItemAttributeObjectTypes.LEARNING_PLAN) {
          this.redirectService.goToCreateNewLearningPlan();
        } else if (this.selectedTypeOfNewItem.code === ItemAttributeObjectTypes.PROJECT) {
          this.redirectService.goToCreateNewProject();
        } else if (this.selectedTypeOfNewItem.code === ItemTypes.BLENDED_CONTAINER) {
          this.redirectService.goToCreateNewBlendedProject();
        } else {
          this.redirectService.goToCreateNewItem(this.selectedTypeOfNewItem.code);
        }
      }
    }
    else {
      this.selectedTypeOfNewItem = null;
    }
  }

  // Apre una modale per chiedere conferma sulla rimozione della iniziativa selezionate
  openRemoveInitiativeConfirmModal(initiative: any) {
    // Archivio l'iniziativa che mi è stata passata
    this.initiativeToDelete = initiative;
    this.modalService.open('deleteInitiativeConfirmModal');
  }

  secondConfirmation() {
    this.modalService.close('deleteInitiativeConfirmModal');
    if (this.initiativeToDelete && this.initiativeToDelete.itemId) {
      this.getStageFromIdForAdmin(this.initiativeToDelete.itemId, true);
    }
  }

  confimation() {
    this.modalService.close('deleteInitiativeConfirmModal');
    if (this.initiativeToDelete && this.initiativeToDelete.itemId) {
      this.getStageFromIdForAdmin(this.initiativeToDelete.itemId);
    }
  }

  async getStageFromIdForAdmin(stageId, removeSyllabus?) {
    try {
      let stage = await this.itemsService.getStageFromIdForAdmin(stageId).toPromise();
      if (stage.response) {
        let editions = stage.response.itemChilds && stage.response.itemChilds[0]?.childObject.itemChilds;
        let editionIds = [];

        if (editions && editions.length) {
          editions.forEach(element => {
            editionIds.push(element.childObject.itemId)
          });
        }


        if (editionIds.length > 0) {
          let initiativesToDelete = 0;
          editionIds.forEach((ed) => {

            initiativesToDelete++
            this.deleteEditions(ed, initiativesToDelete, editionIds, removeSyllabus);

          });
        } else {
          if (removeSyllabus) {
            this.deleteInitiativeNext(removeSyllabus);
          } else {
            this.deleteInitiativeNext();
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  deleteInitiativeNext(removeSyllabus?) {
    this.initiativesLoading = true;
    if (removeSyllabus) {
      this.deleteInitiative(true);
    } else {
      this.deleteInitiative();
    }
  }

  async deleteEditions(courseEditionId, initiativesToDelete, editionIds, removeSyllabus) {
    try {
      let res = await this.courseEditionService.deleteCourseEdition(courseEditionId).toPromise();
      if (res.response && initiativesToDelete === editionIds.length) {
        if (removeSyllabus) {
          this.deleteInitiativeNext(removeSyllabus);
        } else {
          this.deleteInitiativeNext();
        }
      }
    } catch (error) {
      console.log(error)
    }
  }


  dismissAction() {
    this.modalService.close('deleteInitiativeConfirmModal');
    // Pulisco l'iniziativa
    this.clearInitiativeToDelete();
    return;
  }

  // Cancella l'iniziativa selezionata
  deleteInitiative(removeSyllabus?: boolean) {
    this.itemsService.deleteCourseItem(this.initiativeToDelete.itemId, removeSyllabus)
      .subscribe(data => {
        if (data.error) {
          // Mostro il toaster di errore
          this.toastr.error(data.error);
          // Pulisco l'iniziativa
          this.clearInitiativeToDelete();
        } else {
          this.store.dispatch(new InitiativeActions.DeleteInitiative(this.initiativeToDelete.itemId));
          this.toastr.success(this.translate.instant('initiatives.INITIATIVE_DELETED'));
          // Recupero la lista delle iniziative
          this.getLastInitiatives();
          // Pulisco le iniziative
          this.clearInitiativeToDelete();
        }
      },
        (err) => {
          this.toastr.error(err.message);
          // Pulisco le iniziative
          this.clearInitiativeToDelete();
        })
  }

  // Funzione che esegue lo switch tra le iniziative in sospeso e in corso
  onInitiativeStatusFilterChanged(tab: string) {
    for (let key in this.showInitiativeFor) {
      if (this.showInitiativeFor.hasOwnProperty(key)) {
        if (key === tab) {
          this.showInitiativeFor[tab] = true;
          sessionStorage.setItem(tab, "true");
        } else {
          this.showInitiativeFor[key] = false;
          sessionStorage.setItem(key, "false");
        }
      }
    }
    // Avvio una nuova ricerca
    this.getLastInitiatives(tab);
  }

  // Recupera le ultime 6 iniziative (o proposte, qualora fossi loggato come fornitore)
  getLastInitiatives(newShowInitiativeFor?: string) {
    this.initiativesLoading = true;

    // Se necessario (ad esempio quando cambio la modalità iniziative in corso/sospese) resetto i dati
    if (newShowInitiativeFor && this.lastInitiatives && this.lastInitiatives.length) {
      this.lastInitiatives.length = 0;
    };

    // All'inizializzazione del componente, recupero la lista delle ultime dieci iniziative
    this.getLastInitiatives$ = this.itemsService.getLatestOpenedSuspendedInitiatives('0', '6', this.showInitiativeFor)
      .subscribe(
        (data: SenecaResponse<PaginationWrapper<ExtendedItem>>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + data.error));
          } else {
            if (data.response && data.response.rows) {
              for (let i = 0, initiativesLength = data.response.rows.length; i < initiativesLength; i++) {
                let currentInitiative = data.response.rows[i];
                // Setto la lingua corrente dell'Item
                currentInitiative.currentItemLang = ItemUtil.getItemLang(this.langsService, this.applicationLang, currentInitiative);
                // Traduco la tipologia
                // currentInitiative.itemTypeLabel = CourseEditionUtil.getCourseTypologyLabel(currentInitiative, this.translate);
                // Traduco los tato
                currentInitiative.itemStatusLabel = CourseEditionUtil.getCourseStatusLabel(currentInitiative, this.translate);

                // Traduco la tipologia
                let subtype = null;
                let itemType = currentInitiative.itemType;
                if (currentInitiative.itemAttributes) {
                  let itemAttributes = currentInitiative.itemAttributes;
                  for (let k = 0; k < itemAttributes.length; k++) {
                    // Verifico il tipo di oggetto
                    if (itemAttributes[k].attributeType === ItemAttributeTypes.OBJECT_TYPE) {
                      itemType = itemAttributes[k].attributeValue;
                    }
                    // Verifico se ha un sottotipo
                    if (itemAttributes[k].attributeType === ItemAttributeTypes.OBJECT_TYPE_SPECIALIZATION) {
                      subtype = itemAttributes[k].attributeValue;
                    }
                  }

                  // Se non ho trovato un tipo di oggetto in deroga, lo setto automaticamente prendendo il valore dell'itemType
                  if (!subtype) {
                    subtype = itemType;
                  }
                }

                if (subtype) {
                  currentInitiative.itemTypeLabel = this.translate.instant('generic.initiativeTypes.' + subtype);
                } else {
                  currentInitiative.itemTypeLabel = '-';
                }
              }
            }
            this.lastInitiatives = data.response && data.response.rows;
            for (let i = 0; i < this.lastInitiatives.length; i++) {
              this.isMenuVisible.push(false);
            }
          }
          this.initiativesLoading = false;
        }
        , (err) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
          this.initiativesLoading = false;
        }
      );
  }

  // Porta alla pagina di modifica di una iniziativa
  goToEditInitiative(initiative: Item): void {
    this.router.navigate(['initiatives/editInitiative', initiative.itemId]);
  }

  // Porta alla pagina di anteprima di una iniziativa
  goToPreview(initiative: Item): void {
    this.router.navigate(['initiatives/itemPreviewFromHome/itemDetails', initiative.itemId]);
  }

  // Porta alla pagina di creazione dell'iniziativa, in base alla card scelta
  goToCreateInitiative() {
    if (this.selectedCreationPossibility.options === '1') {
      // Da nuovo syllabus
      this.redirectService.goToCreateInitiativeNewSyllabus();
    } else if (this.selectedCreationPossibility.options === '2') {
      // Da syllabus esistente
      this.redirectService.goToCreateInitiativeFromSyllabus();
    } else if (this.selectedCreationPossibility.options === '3') {
      // Scelta della tipologia di oggetto Library da creare
      this.openChooseNewItemTypeModal();
    }
  }

  canViewLibraryCard() {
    return this.authService.isAdminMode() && this.authService.canAccessManageItem(this.loggedUser) && this.canAccessManageLibrary;
  }

  ngOnDestroy() {
  }

}
