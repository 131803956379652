<!-- Il form di login si vede solamente se ci sono lingue disponibili a sistema -->
<div>
  <div class="login-page" *ngIf="langs && langs.length" fxLayout="row" fxLayoutAlign="center center" fxFill>
    <div class="margin-top60" fxLayout="column" fxLayoutAlign="center center" fxFill>
      <div *ngIf="isEmailConfirmation" class="login-column">
        <h2 class="text-center bold-text to-uppercase" translate="login.CONFIRM_SUBSCRIPTION"></h2>
      </div>

      <!-- Pannello di accesso -->
      <div *ngIf="!isEmailConfirmation && !token && !userId && !subscriptionFormVisible && !resetPasswordFormVisible"
        class="login-column" fxLayout="column" fxLayoutAlign="center center">

        <div class="margin-bottom20" fxLayout="row" fxLayoutAlign="center center">
          <img height="30" width="auto" class="margin-botom24 margin-right10" src="assets/icons/logo.svg"
            alt="{{'accessibilityAttributes.LOGO' | translate}}" title="{{'accessibilityAttributes.LOGO' | translate}}">
          <p class="teacher-title" translate="generic.TEACHERS" tabindex="0"></p>
        </div>

        <form class="login-wrapper margin-top40" (ngSubmit)="onLogin(loginForm)" #loginForm="ngForm">
          <!-- Username -->
          <div fxLayout="row" fxLayoutAlign="center center">
            <div class="input-wrapper">
              <div class="title-input full-width" fxLayout="row" fxLayoutAlign="start start">
                <h5 translate="generic.USERNAME"></h5>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <!-- Placeholder di caricamento -->
                <input-placeholder *ngIf="showLoader"></input-placeholder>
                <input [hidden]="showLoader" placeholder="{{'accessibilityAttributes.INSERT_USERNAME' | translate}}"
                  attr.aria-label="{{'accessibilityAttributes.INSERT_USERNAME' | translate}}" type="string" id="email"
                  name="email" #email="ngModel" ngModel required>
              </div>
              <!-- Errore di email non valida
							<div *ngIf="!email.valid && email.touched" class="input-error-message" fxLayout="row"
								fxLayoutAlign="start center">
								<span translate="errors.EMAIL_NOT_VALID"></span>
							</div> -->
            </div>
          </div>

          <div class="space15"></div>

          <!-- Password -->
          <div fxLayoutAlign="center center">
            <div class="input-wrapper full-width">
              <div class="title-inpu full-widtht" fxLayout="row" fxLayoutAlign="start start">
                <h5 translate="generic.PASSWORD"></h5>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <!-- Placeholder di caricamento -->
                <input-placeholder *ngIf="showLoader"></input-placeholder>
                <input [hidden]="showLoader" placeholder="{{'accessibilityAttributes.INSERT_PASSWORD' | translate}}"
                  attr.aria-label="{{'accessibilityAttributes.INSERT_PASSWORD' | translate}}" class="full-width"
                  type="password" id="password" name="password" ngModel #password="ngModel" required>
              </div>

              <!-- Password non inserita -->
              <div *ngIf="!password.valid && password.touched" class="input-error-message" fxLayout="row"
                fxLayoutAlign="start center">
                <span translate="errors.TYPE_PASSWORD"></span>
              </div>
            </div>
          </div>

          <!-- <p><a class="margin-top25 display-block text-center"
							(click)="toggleResetPasswordFormVisible()">Password
							dimenticata?</a></p> -->

          <!-- Pulsante per il login -->
          <div class="margin-top40 margin-bottom30" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
            <div fxHide.gt-xs class="space20"></div>
            <lib-alloy-button importance="primary" dimension="btn-regular" type="submit"
              [disabled]="loginForm && !loginForm.valid" text="{{ 'login.SIGN_IN' | translate }}">
            </lib-alloy-button>
          </div>
        </form>
      </div>

      <!-- Pannello di recupero password (invio dell'email) -->
      <div *ngIf="!isEmailConfirmation && !token && !userId && resetPasswordFormVisible" class="login-column"
        fxLayout="column" fxLayoutAlign="center center">
        <h3 class="bold-text" translate="login.RESET_YOUR_PSW"></h3>
        <h5 class="full-dimension" translate="login.RESET_PSW_INFO"></h5>
        <div class="space30"></div>
        <form class="login-wrapper full-width" (ngSubmit)="onRecoverPassword(resetPasswordForm)"
          #resetPasswordForm="ngForm">
          <!-- Email -->
          <div fxLayout="row" fxLayoutAlign="center center">
            <div class="input-wrapper full-width">
              <div class="title-input full-width" fxLayout="row" fxLayoutAlign="start start">
                <h5 translate="generic.EMAIL"></h5>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <!-- Placeholder di caricamento -->
                <input-placeholder *ngIf="showLoader"></input-placeholder>
                <input [hidden]="showLoader" class="full-width" attr.aria-label="{{'generic.TYPE_MAIL' | translate}}"
                  type="email" id="resetPswEmail" name="resetPswEmail" placeholder="{{'generic.TYPE_MAIL' | translate}}"
                  #email="ngModel" ngModel required email>
              </div>
              <!-- Errore di email non valida -->
              <div *ngIf="resetPswEmail && !resetPswEmail.valid && resetPswEmail.touched" class="input-error-message"
                fxLayout="row" fxLayoutAlign="start center">
                <span translate="errors.EMAIL_NOT_VALID"></span>
              </div>
            </div>
          </div>

          <div class="margin-top40 margin-bottom30 full-width" fxLayout="row" fxLayout.xs="column"
            fxLayoutAlign="space-between center">
            <lib-alloy-button importance="primary" dimension="btn-regular" text="{{ 'generic.CANCEL' | translate }}"
              (click)="toggleResetPasswordFormVisible()">
            </lib-alloy-button>
            <div fxHide.gt-xs class="space20"></div>
            <lib-alloy-button importance="primary" dimension="btn-regular" type="submit"
              [disabled]="resetPasswordForm && !resetPasswordForm.valid" text="{{ 'login.CONFIRM' | translate }}">
            </lib-alloy-button>
          </div>
        </form>
      </div>

      <!-- Pannello di recupero password (sto immettendo una mail e psw nuova) -->
      <div *ngIf="!isEmailConfirmation && token && userId" class="login-column" fxLayout="column"
        fxLayoutAlign="center center">
        <h3 class="bold-text" translate="login.NEW_PASSWORD"></h3>
        <h5 class="full-dimension" translate="generic.TYPE_NEW_CREDENTIALS"></h5>
        <div class="space30"></div>
        <form class="login-wrapper full-width" (ngSubmit)="onRecoverPassword(newPswForm)" #newPswForm="ngForm">
          <!-- Password -->
          <div fxLayoutAlign="center center">
            <div class="input-wrapper full-width">
              <div class="title-input full-width" fxLayout="row" fxLayoutAlign="start start">
                <h5 translate="login.NEW_PASSWORD"></h5>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <!-- Placeholder di caricamento -->
                <input-placeholder *ngIf="showLoader"></input-placeholder>
                <input [hidden]="showLoader" class="full-width" [pattern]="passwordPattern"
                  attr.aria-label="{{'errors.TYPE_PASSWORD' | translate}}" type="password" id="newPsw" name="newPsw"
                  placeholder="{{'errors.TYPE_PASSWORD' | translate}}" ngModel #newPsw="ngModel" required>
              </div>

              <!-- Errori form -->
              <div
                *ngIf="(!arePswMatchingPattern(newPsw && newPsw.value) || !newPsw.valid) && (newPsw.touched || newPsw.dirty)"
                class="input-error-message" fxLayout="row" fxLayoutAlign="start center">
                <div *ngIf="newPsw.errors.required">
                  <span translate="errors.TYPE_PASSWORD"></span>
                </div>
                <div *ngIf="newPsw.errors.pattern">
                  <span translate="login.errors.PATTERN"></span>
                </div>
              </div>

              <div class="space30"></div>

              <!-- Spazio aggiuntivo se presente l'errore del pattern nel form-->
              <div
                *ngIf="!newPsw.valid && (newPsw.touched || newPsw.dirty) && newPsw && newPsw.errors && newPsw.errors.pattern"
                class="space50"></div>

              <div class="title-input full-width" fxLayout="row" fxLayoutAlign="start start">
                <h5 translate="login.REPEAT_PASSWORD"></h5>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <!-- Placeholder di caricamento -->
                <input-placeholder *ngIf="showLoader"></input-placeholder>
                <input [hidden]="showLoader" class="full-width"
                  attr.aria-label="{{'login.REPEAT_PASSWORD' | translate}}" type="password" id="repeatPassword"
                  [pattern]="passwordPattern" placeholder="{{'login.REPEAT_PASSWORD' | translate}}"
                  name="repeatPassword" ngModel #repeatPassword="ngModel" required>
              </div>

              <!-- Errori form -->
              <div
                *ngIf="(!arePswMatchingPattern(repeatPassword && repeatPassword.value) || !repeatPassword.valid) && (repeatPassword.touched || repeatPassword.dirty)"
                class="input-error-message" fxLayout="row" fxLayoutAlign="start center">
                <div *ngIf="repeatPassword.errors.required">
                  <span translate="errors.TYPE_PASSWORD"></span>
                </div>
                <div *ngIf="repeatPassword.errors.pattern">
                  <span translate="login.errors.PATTERN"></span>
                </div>
              </div>

              <!-- Spazio aggiuntivo se presente l'errore del pattern nel form-->
              <div
                *ngIf="!repeatPassword.valid && (repeatPassword.touched || repeatPassword.dirty) && repeatPassword && repeatPassword.errors && repeatPassword.errors.pattern"
                class="space50"></div>

              <!-- Password non corrisponde -->
              <div *ngIf="repeatPassword.valid && repeatPassword.touched && !areNewPswSame(newPswForm)"
                class="input-error-message" fxLayout="row" fxLayoutAlign="start center">
                <span translate="login.PASSWORD_DONT_MATCH"></span>
              </div>
            </div>
          </div>

          <div class="margin-top40 margin-bottom30 full-width" fxLayout="row" fxLayout.xs="column"
            fxLayoutAlign="space-between center">
            <div fxHide.gt-xs class="space20"></div>
            <lib-alloy-button importance="primary" dimension="btn-regular" type="submit"
              [disabled]="(newPswForm && !newPswForm.valid) || !areNewPswSame(newPswForm)"
              text="{{ 'login.CONFIRM' | translate }}"></lib-alloy-button>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>

<!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
  <p translate="errors.NO_AVAILABLE_LANG"></p>
</div>

<!-- Modale generica di conferma -->
<lib-alloy-modal class="informative-modal" id="successModal">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h1 class="custom-modal-title" *ngIf="translatedModalTitle">{{translatedModalTitle}}</h1>
    <h1 class="custom-modal-title" *ngIf="!translatedModalTitle" translate="generic.modals.USER_NOT_FOUND"></h1>
    <svg-icon class="cursor-pointer" (click)="closeSuccessModal()" src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <div class="content-modal" fxLayout="row" fxLayoutAlign="start start">
    <h5 *ngIf="translatedModalText" [innerHTML]="translatedModalText | safeHtml: 'html'"></h5>
    <h5 *ngIf="!translatedModalText"
      [innerHTML]="('generic.modals.USER_NOT_FOUND_BODY' | translate) | safeHtml: 'html'">
    </h5>
  </div>
  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <div></div>
    <!-- Conferma l'operazione -->
    <lib-alloy-button (click)="closeSuccessModal();" importance="primary" dimension="btn-regular" type="submit"
      text="{{ 'generic.OK' | translate }}">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>