import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UrlService } from '../../shared/services/url.service';
import { Lang } from 'atfcore-commonclasses';

@Component({
  selector: 'app-login-sso',
  templateUrl: './login-sso.component.html',
  styleUrls: ['./login-sso.component.scss']
})
export class LoginSSOComponent implements OnInit, OnDestroy {
  isFetchingLangs: boolean;
  langs: Lang[];

  redirectUrl;

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private deviceService: DeviceDetectorService,
    private urlService: UrlService
  ) {
    let redirectUrl$: any = this.store.select(
      fromApp.getRedirectUrl
    );
    redirectUrl$.subscribe(redirectUrl => {
      this.redirectUrl = redirectUrl;
    });

    // Prima di chiamare il servizio per il login, identifico l'userAgent e il tipo di device dell'utente
    const deviceInfo = this.deviceService.getDeviceInfo();
    const userAgent = deviceInfo && deviceInfo.userAgent;
    let deviceType;
    if (this.deviceService.isMobile()) {
      // Salvo il fatto che è uno smartphone
      deviceType = 'P';
    } else if (this.deviceService.isTablet()) {
      // Salvo il fatto che è un tablet
      deviceType = 'T';
    } else if (this.deviceService.isDesktop()) {
      // Salvo il fatto che è un computer desktop
      deviceType = 'D';
    }

    let storageRedirectUrl = sessionStorage.getItem('redirectUrl');
    let redirectUrl;
    if (storageRedirectUrl) {
      redirectUrl = this.urlService.getSSOUrl(deviceType, userAgent, '/#/' + storageRedirectUrl);
    } else {
      redirectUrl = this.urlService.getSSOUrl(deviceType, userAgent, '/#/takers/home');
    }
    window.location.href = redirectUrl;
  }
  ngOnInit() {
  }
  ngOnDestroy() {
  }
}
