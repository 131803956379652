/*
 * Guard per il componente di Login. Il servizio verifica dallo State, unica fonte di verità, se l'utente è correttamente autenticato e, in questo caso,
 * salta la procedura di Login andando nella pagina di default, l'home page.
*/

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, map } from 'rxjs/operators';

// Import dello State dell'applicativo
import * as fromApp from '../../ngrx/app.reducers';

@Injectable()
export class TeacherLoginGuard implements CanActivate {
  constructor(private store: Store<fromApp.AppState>, private router: Router) {
  }

  // Ritorna un Observable che, risolvendo alla fine un boolean, possiamo mapparlo
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(fromApp.isAuthenticated)
      // Con il select() eseguiamo una subscription allo State, e quindi si trigghera ogni volta che ci sono delle modifiche; per
      // ovviare a tale comportamento sfruttiamo il take(1). (Il metodo pipe() invece è richiesto da RxJS6+)
      .pipe(take(1),
        map(isAuthenticated => {
          if (isAuthenticated) {
            // Utente correttamente loggato. quindi vado nell'ultimo pagina visitata
            let lastVisitedPage = this.router.routerState && this.router.routerState.snapshot && this.router.routerState.snapshot.url || '/teachers/home';
            this.router.navigate([lastVisitedPage]);
            return false;
          } else {
            // Altrimenti proseguo alla pagina di login
            return true;
          }
        })
      )
  }
}