<div class="lp-section" fxLayoutAlign="start center" fxLayout="row wrap">
  <div class="section-wrapper content" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxLayoutAlign="center start" fxFill>
      <div fxLayout="row" fxLayoutAlign="start center">
        <p class="section-number">1</p>
        <div class="timeline-item no-overflow">
          <div class="animated-background half-width">
          </div>
        </div>
      </div>
      <img class="gen-divider" title="{{'generic.DIVIDER' | translate}}" src="../../../assets/icons/gen-divider.svg">
      <div class="timeline-item no-overflow">
        <div class="animated-background half-width">
        </div>
      </div>
      <div class="info-wrapper margin-bottom15" fxLayout="column">
        <h5 class="margin-bottom4"><strong translate="sectionProject.COMPLETION"></strong></h5>
        <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayoutAlign="start start">
          <div class="timeline-item margin-bottom5 no-overflow">
            <div class="animated-background half-width">
              <div class="background-masker header-top"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout.sm="row" fxLayout.xs="row" class="margin-bottom25 item-section-wrapper">
    <div *ngFor="let number of [0,1,2,3,4]" class="margin-bottom15 center-item">
      <item-in-learning-plan-placeholder></item-in-learning-plan-placeholder>
    </div>
  </div>
  <hr>
</div>