import { Store } from "@ngrx/store";
import { GlobalApplicationData } from "../../shared/models/global-application-data.model";
import { Subscription, combineLatest, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import * as fromApp from '../../ngrx/app.reducers';
import { Injectable } from "@angular/core";
import { SenecaResponse, JwtPayload } from "../../../cm2-commonclasses";

@Injectable()
export class AzurePlayerService {
  applicationData: GlobalApplicationData;
  result$: Subscription;
  loggedUser: JwtPayload;

  constructor(private store: Store<fromApp.AppState>,
    private http: HttpClient) {
    // Dallo store recupero l'oggetto principale contenente generici dati, e la lingua dell'applicazione
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    // Collegamento all'utente loggato
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);

    const combinedSelectes$ = combineLatest(globalApplicationData$, loggedUser$);
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData, loggedUser]) => {
        this.loggedUser = loggedUser;
        this.applicationData = globalApplicationData;
      });
  }

  updatePlayerStatus(itemId: string, event: string, totalTime: number, currentTime: number, viewedSeconds: number) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/update-player-status/${itemId}/${event}/${totalTime}/${currentTime}`, {
      viewedSeconds: viewedSeconds
    });
  }
}