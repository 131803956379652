<div>
  <!--Crediti Card IVASS-->
  <div class="credit-card-wrapper-container" fxLayout="column" fxLayoutAlign="center center">
  </div>
  <!-- Immagine di copertina -->
  <div class="img-background-course-card-wrapper"
    [ngStyle]="{ 'background-image': cardCover ? 'url(' + cardCover + ')' : '' }">
  </div>
  <!-- Titolo -->
  <div class="title-wrapper">
    <div class="line width220 height24"></div>
    <div class="line width220 height24"></div>
  </div>
  <!-- Azione -->
  <div class="call-to-actions">
    <div class="line h32 width105 height32"></div>
    <!--lib-alloy-button importance="bordless" dimension="btn-small" [text]="'generic.SHOW_DETAILS' | translate"
      [attr.aria-label]="'generic.SHOW_DETAILS' | translate">
    </lib-alloy-button-->
  </div>
</div>