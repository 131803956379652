<div class="fixHeader">
  <div class="content">
    <div class="main-menu-header" aria-level="1" fxLayout="row" role="heading" fxFill>
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="row" role="navigation">
          <lib-alloy-button (click)="toggleMainMenuSidebar()" importance="menu" dimension="btn-square"
            icon="assets/icons/icon-hamburgher-white.svg" text="Menu"
            attr.aria-label="{{ isMainMenuSidebarOpened ? ('header.CLOSE_MAIN_MENU' | translate ) : ('header.OPEN_MAIN_MENU' | translate ) }}"
            id="menu-button" tabindex="0">
          </lib-alloy-button>
        </div>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="center center">
        <div attr.aria-label="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}">
          <img alt="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}" height="45px" class="cursor-pointer"
            title="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}" src="assets/icons/logo-white.svg"
            (click)="goToHome()">
        </div>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="end center">
        <!-- Profilo, se non sono nella parte admin -->
        <!--lib-alloy-button *ngIf="!authService.isAdminMode() && canAccessUserMode && canAccessUserProfile"
          (click)="redirectService.goToProfile(!authService.isAdminMode() && !authService.isTeacherMode(), authService.isTeacherMode())"
          importance="menu" dimension="btn-square" icon="../../assets/icons/icon-user.svg"
          text="{{ 'header.PROFILE' | translate }}" fxShow.xs="false" fxShow.sm="false" attr.aria-label="false">
        </lib-alloy-button-->
        <!-- Notifiche -->

        <div
          *ngIf="!authService.isAdminMode() && !authService.isTeacherMode() && canAccessUserMode && canAccessUserNotifications"
          class="relative-position margin-right16" fxShow.xs="false" fxShow.sm="false">
          <lib-alloy-button (click)="goToNotifications()" importance="menu" dimension="btn-square"
            icon="assets/icons/bell-white.svg" text="{{ 'header.NOTIFICATIONS' | translate }}"
            attr.aria-label="{{ 'header.NOTIFICATIONS' | translate }}" id="n-menu-button" tabindex="0">
          </lib-alloy-button>
          <span class="badge-notify">{{notificationsCounter || 0}}</span>
        </div>
        <!-- Switch lato utente/admin -->
        <div class="profile-changer-container" (click)="toggleProfileMenu()">
          <div #profileChangerButton class="current" fxLayoutGap="10px">
            <img src="assets/icons/icon-myspace-orange.svg">
            <p translate="header.modules.{{currentModule}}"></p>
            <svg-icon class="caret" src="/assets/icons/icon-caret-bottom.svg"></svg-icon>
          </div>
          <div #headerMenu *ngIf="isProfileMenuOpen" class="menu-container">

            <ng-container *ngIf="moreThanOneProfile()">
              <!-- Accesso utente -->
              <div class="option-container" *ngIf="canAccessUserMode" (click)="redirect('takers')">
                <svg-icon src="/assets/icons/icon-community.svg"></svg-icon>
                <p translate="header.LOG_AS_USER"></p>
              </div>
              <!-- Accesso admin -->
              <div class="option-container" *ngIf="canAccessAdminMode && isAdmin" (click)="redirect('admin')">
                <svg-icon src="/assets/icons/icon-community.svg"></svg-icon>
                <p translate="header.LOG_AS_ADMIN"></p>
              </div>
              <!-- Accesso manager -->
              <div class="option-container" *ngIf="canAccessManager()" (click)="redirect('manager')">
                <svg-icon src="/assets/icons/icon-community.svg"></svg-icon>
                <p translate="header.LOG_AS_MANAGER"></p>
              </div>
              <!-- Accesso docente -->
              <div class="option-container" *ngIf="canAccessTeacherMode && isTeacher" (click)="redirect('teacher')">
                <svg-icon src="/assets/icons/icon-community.svg"></svg-icon>
                <p translate="header.LOG_AS_TEACHER"></p>
              </div>
            </ng-container>

            <!-- Profilo utente -->
            <div class="option-container" *ngIf="canAccessUserProfile" (click)="goToProfile()">
              <svg-icon src="assets/icons/icon-myspace-orange.svg"></svg-icon>
              <p translate="header.PERSONAL_PROFILE"></p>
            </div>
            <div class="option-container" (click)="onLogout()">
              <svg-icon src="/assets/icons/icon-logout.svg"></svg-icon>
              <p translate="header.EXIT"></p>
            </div>
          </div>
        </div>

        <!--lib-alloy-button *ngIf="!authService.isTeacherMode() && isAdmin && canAccessUserMode && canAccessAdminMode"
          fxShow.xs="false" fxShow.sm="false" (click)="switchAdminMode()" importance="menu" dimension="btn-square"
          icon="../../assets/icons/icon-myspace.svg"
          text="{{ authService.isAdminMode() ? ('home.USER' | translate) : ('home.ADMIN' | translate) }}"
          attr.aria-label="{{ authService.isAdminMode() ? ('home.USER' | translate) : ('home.ADMIN' | translate) }}">
        </lib-alloy-button-->
      </div>
    </div>
  </div>
</div>