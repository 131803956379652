import * as ItemsActions from './items.actions';
import * as fromApp from '../../ngrx/app.reducers';
import { Item } from "../../../cm2-commonclasses";
import { createReducer, on, Action } from '@ngrx/store';

export interface FeatureState extends fromApp.AppState {
  itemsAdmin: ItemsState;
}

// Interfaccia dello Store
export interface ItemsState {
  itemList: Item[];
  itemListCount: number;
  fromRecord: number;
  numRecords: number;
  page: number;
  isFetchingItemList: boolean;
  selectedItemListFilterType: string | string[];
  itemListTextFilter: string;
  addPublicVisibilityOnCreation: boolean;
  excludeContainerInSearch: boolean;
  itemListShowFor: string;
  cardCovers: { [key: string]: { itemId: number, url: string, title: string, fromPredefiniteSet: boolean }[] }
}

// Store iniziale
const initialState: ItemsState = {
  itemList: [],
  itemListCount: 0,
  fromRecord: 0,
  numRecords: 20,
  page: 1,
  isFetchingItemList: false,
  selectedItemListFilterType: null,
  itemListTextFilter: null,
  addPublicVisibilityOnCreation: false,
  excludeContainerInSearch: false,
  itemListShowFor: null,
  cardCovers: {
    BOOK_COVERS: [
      {
        itemId: 1,
        url: 'assets/img/img-card/book/book-1.png',
        title: 'cover-book1',
        fromPredefiniteSet: true
      },
      {
        itemId: 2,
        url: 'assets/img/img-card/book/book-2.png',
        title: 'cover-book2',
        fromPredefiniteSet: true
      },
      {
        itemId: 3,
        url: 'assets/img/img-card/book/book-3.png',
        title: 'cover-book3',
        fromPredefiniteSet: true
      },
      {
        itemId: 4,
        url: 'assets/img/img-card/book/book-4.png',
        title: 'cover-book4',
        fromPredefiniteSet: true
      }
    ],
    DOCUMENT_COVERS: [
      {
        itemId: 5,
        url: 'assets/img/img-card/documento/documento-1.png',
        title: 'cover-documento1',
        fromPredefiniteSet: true
      },
      {
        itemId: 6,
        url: 'assets/img/img-card/documento/documento-2.png',
        title: 'cover-documento2',
        fromPredefiniteSet: true
      },
      {
        itemId: 7,
        url: 'assets/img/img-card/documento/documento-3.png',
        title: 'cover-documento3',
        fromPredefiniteSet: true
      },
      {
        itemId: 8,
        url: 'assets/img/img-card/documento/documento-4.png',
        title: 'cover-documento4',
        fromPredefiniteSet: true
      }
    ],
    DVD_COVERS: [
      {
        itemId: 9,
        url: 'assets/img/img-card/dvd/dvd-1.png',
        title: 'cover-dvd1',
        fromPredefiniteSet: true
      },
      {
        itemId: 10,
        url: 'assets/img/img-card/dvd/dvd-2.png',
        title: 'cover-dvd2',
        fromPredefiniteSet: true
      }
    ],
    EBOOK_COVERS: [
      {
        itemId: 11,
        url: 'assets/img/img-card/e-book/e-book-1.png',
        title: 'cover-e-book1',
        fromPredefiniteSet: true
      }
    ],
    ELEARNING_COVERS: [
      {
        itemId: 12,
        url: 'assets/img/img-card/e-learning/e-learning-1.png',
        title: 'cover-e-learning1',
        fromPredefiniteSet: true
      },
      {
        itemId: 13,
        url: 'assets/img/img-card/e-learning/e-learning-2.png',
        title: 'cover-e-learning2',
        fromPredefiniteSet: true
      },
      {
        itemId: 14,
        url: 'assets/img/img-card/e-learning/e-learning-3.png',
        title: 'cover-e-learning3',
        fromPredefiniteSet: true
      },
      {
        itemId: 15,
        url: 'assets/img/img-card/e-learning/e-learning-4.png',
        title: 'cover-e-learning4',
        fromPredefiniteSet: true
      },
      {
        itemId: 16,
        url: 'assets/img/img-card/e-learning/e-learning-5.png',
        title: 'cover-e-learning5',
        fromPredefiniteSet: true
      },
      {
        itemId: 17,
        url: 'assets/img/img-card/e-learning/e-learning-6.png',
        title: 'cover-e-learning6',
        fromPredefiniteSet: true
      }
    ],
    IMAGE_COVERS: [
      {
        itemId: 18,
        url: 'assets/img/img-card/image/image-1.png',
        title: 'cover-image1',
        fromPredefiniteSet: true
      },
      {
        itemId: 19,
        url: 'assets/img/img-card/image/image-2.png',
        title: 'cover-image2',
        fromPredefiniteSet: true
      },
      {
        itemId: 20,
        url: 'assets/img/img-card/image/image-3.png',
        title: 'cover-image3',
        fromPredefiniteSet: true
      }
    ],
    LP_COVERS: [
      {
        itemId: 21,
        url: 'assets/img/img-card/learning-plan/learning-plan-1.png',
        title: 'cover-lp1',
        fromPredefiniteSet: true
      },
      {
        itemId: 22,
        url: 'assets/img/img-card/learning-plan/learning-plan-2.png',
        title: 'cover-lp2',
        fromPredefiniteSet: true
      },
      {
        itemId: 23,
        url: 'assets/img/img-card/learning-plan/learning-plan-3.png',
        title: 'cover-lp3',
        fromPredefiniteSet: true
      },
      {
        itemId: 24,
        url: 'assets/img/img-card/learning-plan/learning-plan-4.png',
        title: 'cover-lp4',
        fromPredefiniteSet: true
      }
    ],
    MAGAZINE_COVERS: [
      {
        itemId: 25,
        url: 'assets/img/img-card/magazine/magazine-1.png',
        title: 'cover-magazine1',
        fromPredefiniteSet: true
      },
      {
        itemId: 26,
        url: 'assets/img/img-card/magazine/magazine-2.png',
        title: 'cover-magazine2',
        fromPredefiniteSet: true
      }
    ],
    PL_COVERS: [
      {
        itemId: 27,
        url: 'assets/img/img-card/playlist/playlist-1.png',
        title: 'cover-playlist1',
        fromPredefiniteSet: true
      },
      {
        itemId: 28,
        url: 'assets/img/img-card/playlist/playlist-2.png',
        title: 'cover-playlist2',
        fromPredefiniteSet: true
      },
      {
        itemId: 29,
        url: 'assets/img/img-card/playlist/playlist-3.png',
        title: 'cover-playlist3',
        fromPredefiniteSet: true
      },
      {
        itemId: 30,
        url: 'assets/img/img-card/playlist/playlist-4.png',
        title: 'cover-playlist4',
        fromPredefiniteSet: true
      },
      {
        itemId: 31,
        url: 'assets/img/img-card/playlist/playlist-5.png',
        title: 'cover-playlist5',
        fromPredefiniteSet: true
      },
      {
        itemId: 32,
        url: 'assets/img/img-card/playlist/playlist-6.png',
        title: 'cover-playlist6',
        fromPredefiniteSet: true
      }
    ],
    PODCAST_COVERS: [
      {
        itemId: 33,
        url: 'assets/img/img-card/podcast/podcast-1.png',
        title: 'cover-podcast1',
        fromPredefiniteSet: true
      },
      {
        itemId: 34,
        url: 'assets/img/img-card/podcast/podcast-2.png',
        title: 'cover-podcast2',
        fromPredefiniteSet: true
      }
    ],
    SITOGRAPHY_COVERS: [
      {
        itemId: 35,
        url: 'assets/img/img-card/sitography/sitography-1.png',
        title: 'cover-sitography1',
        fromPredefiniteSet: true
      },
      {
        itemId: 36,
        url: 'assets/img/img-card/sitography/sitography-2.png',
        title: 'cover-sitography2',
        fromPredefiniteSet: true
      }
    ],
    VIDEO_COVERS: [
      {
        itemId: 37,
        url: 'assets/img/img-card/video/video-1.png',
        title: 'cover-video1',
        fromPredefiniteSet: true
      },
      {
        itemId: 38,
        url: 'assets/img/img-card/video/video-2.png',
        title: 'cover-video2',
        fromPredefiniteSet: true
      },
      {
        itemId: 39,
        url: 'assets/img/img-card/video/video-3.png',
        title: 'cover-video3',
        fromPredefiniteSet: true
      },
      {
        itemId: 40,
        url: 'assets/img/img-card/video/video-4.png',
        title: 'cover-video4',
        fromPredefiniteSet: true
      },
      {
        itemId: 41,
        url: 'assets/img/img-card/video/video-5.png',
        title: 'cover-video5',
        fromPredefiniteSet: true
      },
      {
        itemId: 42,
        url: 'assets/img/img-card/video/video-6.png',
        title: 'cover-video6',
        fromPredefiniteSet: true
      }
    ],
    CERTIFICATE_COVERS: [
      {
        itemId: 43,
        url: 'assets/img/img-card/certificates/certificates-1.png',
        title: 'cover-attestato1',
        fromPredefiniteSet: true
      },
      {
        itemId: 44,
        url: 'assets/img/img-card/certificates/certificates-2.png',
        title: 'cover-attestato2',
        fromPredefiniteSet: true
      }
    ],
    SURVEY_COVERS: [
      {
        itemId: 45,
        url: 'assets/img/img-card/survey/survey-1.png',
        title: 'cover-questionario1',
        fromPredefiniteSet: true
      },
      {
        itemId: 46,
        url: 'assets/img/img-card/survey/survey-2.png',
        title: 'cover-questionario2',
        fromPredefiniteSet: true
      },
      {
        itemId: 47,
        url: 'assets/img/img-card/survey/survey-3.png',
        title: 'cover-questionario3',
        fromPredefiniteSet: true
      }
    ],
    EXTERNAL_ITEM_COVERS: [
      {
        itemId: 48,
        url: 'assets/img/img-card/external_item/link-1.png',
        title: 'cover-link1',
        fromPredefiniteSet: true
      },
      {
        itemId: 49,
        url: 'assets/img/img-card/external_item/link-2.png',
        title: 'cover-link2',
        fromPredefiniteSet: true
      },
      {
        itemId: 50,
        url: 'assets/img/img-card/external_item/link-3.png',
        title: 'cover-link3',
        fromPredefiniteSet: true
      }
    ]
  }
};

export const itemsReducer = createReducer(
  initialState,
  on(ItemsActions.SetItemListTextFilter, (state, action) => {
    return { ...state, itemListTextFilter: action.itemListTextFilter }
  }),
  on(ItemsActions.SetExcludeContainerInSearch, (state, action) => {
    return { ...state, excludeContainerInSearch: action.hasToExcludeContainer }
  }),
  on(ItemsActions.GetItemList, (state, action) => {
    return { ...state, isFetchingItemList: true }
  }),
  on(ItemsActions.DeleteItem, (state, action) => {
    const oldItemList = [...state.itemList];
    for (let i = 0, itemListLength = oldItemList.length; i < itemListLength; i++) {
      const currentItem = oldItemList[i];
      if (currentItem.itemId === action.payload) {
        oldItemList.splice(i, 1);
        break;
      }
    }
    return {
      ...state,
      itemList: oldItemList
    };
  }),
  on(ItemsActions.SetItemListCount, (state, action) => {
    return { ...state, itemListCount: action.counter }
  }),
  on(ItemsActions.SetItemListShowFor, (state, action) => {
    return { ...state, itemListShowFor: action.showFor }
  }),
  on(ItemsActions.SetPage, (state, action) => {
    return { ...state, page: action.pageNumber }
  }),
  on(ItemsActions.SetItemListFilterType, (state, action) => {
    return { ...state, selectedItemListFilterType: action.filterType }
  }),
  on(ItemsActions.SetPagination, (state, action) => {
    return { ...state, fromRecord: action.payload.fromRecord, numRecords: action.payload.numRecords }
  }),
  on(ItemsActions.SetItemList, (state, action) => {
    return {
      ...state, isFetchingItemList: false, itemList: action.items
    }
  })
);

export function reducer(state: ItemsState | undefined, action: Action) {
  return itemsReducer(state, action);
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getItemList = (state: ItemsState) => (state && state.itemList) || [];
export const getCardCovers = (state: ItemsState) => (state && state.cardCovers) || {};
export const getItemListCount = (state: ItemsState) => state && state.itemListCount;
export const getItemListFromRecord = (state: ItemsState) => state && state.fromRecord;
export const getItemListNumRecords = (state: ItemsState) => state && state.numRecords;
export const getItemListPage = (state: ItemsState) => state && state.page;
export const isFetchingItemList = (state: ItemsState) => state && state.isFetchingItemList;
export const getItemListFilterType = (state: ItemsState) => (state && state.selectedItemListFilterType) || null;
export const getItemListTextFilter = (state: ItemsState) => (state && state.itemListTextFilter) || null;
export const getAddPublicVisibilityOnCreation = (state: ItemsState) => (state && state.addPublicVisibilityOnCreation) || false;
export const excludeContainerInSearch = (state: ItemsState) => (state && state.excludeContainerInSearch) || false;
export const getItemListShowFor = (state: ItemsState) => (state && state.itemListShowFor) || null;