import { Component, Output, EventEmitter, ChangeDetectorRef, Input, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemChild } from 'node_modules/atfcore-commonclasses/bin/classes/item.js';
import { IDataItem } from '../../models/item.model';

interface TrackerItemChild extends ItemChild {
  propedeuticSectionReferenceId?: string;
  available?: boolean;
}

@Component({
  selector: 'learning-plan-tracker',
  templateUrl: './learning-plan-tracker.component.html',
  styleUrls: ['./learning-plan-tracker.component.scss']
})
export class LearningPlanTrackerComponent implements OnChanges {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) { }

  _completitionRate: number;
  _isVisible: boolean = false;
  _isEverithingComplete: boolean = false;
  _mergedCourse: TrackerItemChild[] = [];
  _canGoNextStep: boolean = false;
  _completed: number = 0;
  _total: number = 0;
  
  @Input() itemDetails: IDataItem;
  @Input() isExternal: boolean;
  @Input() isLoading: boolean;
  /** Redirect URL al prossimo componente */
  @Output() onPress = new EventEmitter<string>()

  ngOnChanges(): void { if (this.itemDetails) this.initializeCourseData(this.itemDetails) }

  /**
   * Carica i dati dal servizio in base all'URL corrente e aggiorna lo stato del componente.
   */
  private initializeCourseData(item: IDataItem) {
    // Verifica se la pagina fa parte della sezione playlist
    const isPlaylist = !this.router.url.includes("itemDetailSec");
    // ID del genitore (progetto o LP) recuperato dai parametri dell'URL.
    const parentId = this.route.snapshot.paramMap.get("projectId") || this.route.snapshot.paramMap.get("lpId");
    // ID dell'elemento corrente recuperato dai parametri dell'URL.
    const itemId = this.route.snapshot.paramMap.get("itemId");
    
    // Costruisci l'array degli elementi figlio con informazioni rilevanti.
    this._mergedCourse = this.buildMergedCourse(item, isPlaylist, parentId);
    // Ottieni l'elemento corrente nella lista.
    const currentCourse = this.getCurrentCourse(itemId);
    // Ottieni il prossimo elemento disponibile nella lista.
    const retriveNextCourse = this.getNextAvailableCourseOnList(itemId);
    
    // Verifica se l'ID del genitore è valido.
    if (!parentId) return;
    
    // Aggiorna la visibilità e forza il ricalcolo del componente
    this._isVisible = true;
    this.cdr.detectChanges();
    
    // Calcola lo stato di completamento e il tasso di completamento.
    this._total = this.calcCompletitionStatus("total");
    this._completed = this.calcCompletitionStatus("completed");
    this._isEverithingComplete = this._total === this._completed;
    // Calcola la percentuale di completamento dell'item
    this._completitionRate = this.calcCompletitionRate(currentCourse);
    // Abilita/disabilita il bottone per andare al prossimo item
    this._canGoNextStep = retriveNextCourse ? true : false;
  }

  /**
   * Costruisce un array contenente le informazioni necessarie al componente.
   * Questo metodo è utilizzato per mappare e organizzare gli elementi figlio in base al tipo di dati fornito,
   * restituendo un array contenente solo le informazioni rilevanti per l'utilizzo nel componente.
   * 
   * @param items - L'oggetto dati padre contenente gli elementi figlio.
   * @param isPlaylist - Indica se l'oggetto dati rappresenta una playlist.
   * @param parentId - L'ID del genitore (progetto o LP).
   * @returns Un array di elementi figlio con le informazioni necessarie per il componente.
   */
  private buildMergedCourse({ itemChilds, itemId }: IDataItem, isPlaylist: boolean, parentId: string): TrackerItemChild[] {
    let mergedCourse: TrackerItemChild[] = [];

    if (!itemChilds) return mergedCourse;

    if (isPlaylist) {
      mergedCourse = [...itemChilds];

      for (const course of mergedCourse) {
        course.available = true;
        
        if (course?.propedeuticReferenceId) {
          const getPropItem = mergedCourse.find(child => child.referenceId === course.propedeuticReferenceId);
          const checkCompletion = this.isCompleted(getPropItem);
    
          course.available = checkCompletion;
        }
      }

      return mergedCourse;
    }

    const mappedItem = this.courseMapping(itemChilds);
    const key = this.isExternal ? itemId : parentId;

    for (const project in mappedItem?.[key]) {
      mergedCourse.push(...Object.values(mappedItem?.[key][project] as ItemChild));
    }

    for (const course of mergedCourse) {
      const findReference = itemChilds.find(child => child.referenceId === course.itemId);
      course.propedeuticSectionReferenceId = findReference.propedeuticReferenceId;
      course.available = true;
      
      if (course?.propedeuticReferenceId) {
        const getPropItem = mergedCourse.find(child => child.referenceId === course.propedeuticReferenceId);
        const checkCompletion = this.isCompleted(getPropItem);
  
        course.available = checkCompletion;
      }
  
      if (course?.propedeuticSectionReferenceId) {
        const sectionIsCompleted = mergedCourse
          .filter(child => child.itemId === course.propedeuticSectionReferenceId)
          .every(child => this.isCompleted(child));
        
        course.available = sectionIsCompleted;
      }
    }
    
    return mergedCourse;
  }

  /**
   * Mappa e struttura i dati relativi ai corsi, fornendo un'organizzazione gerarchica,
   * basata sugli identificatori univoci degli elementi coinvolti.
   * 
   * @param itemChilds Array di oggetti contenente informazioni sui corsi e i loro oggetti.
   * @returns Oggetto mappato con una struttura gerarchica basata sugli identificatori degli elementi coinvolti.
   */
  private courseMapping(itemChilds: ItemChild[]) {
    const mappedItem = {};
    for (const item of itemChilds) {
      mappedItem[item?.itemId] = mappedItem[item?.itemId] ?? {};
      mappedItem[item.itemId][item.referenceId] = mappedItem[item.itemId][item.referenceId] ?? {};
      
      for (const obj of item.childObject.itemChilds) {
        mappedItem[item.itemId][item.referenceId][obj.referenceId] = obj;
      }
    }

    return mappedItem as Record<string, any>;
  }
  
  /**
   * Calcola il tasso di completamento del corso.
   * 
   * @param course - L'oggetto del corso.
   * @returns Il tasso di completamento come numero intero.
   */
  private calcCompletitionRate(course: TrackerItemChild) {
    const completitionRate: string = (course?.childObject as any)?.completionPercentage || "0";
    return parseInt(completitionRate, 10);
  }

  /**
   * Calcola lo stato di completamento del corso in base allo status specificato.
   * 
   * @param status - Lo status desiderato ("total" per il totale, "completed" per i completati).
   * @returns Il numero totale o completato degli elementi nel corso.
   */
  private calcCompletitionStatus(status: "total" | "completed") {
    const total = this._mergedCourse.length;
    const completed = this._mergedCourse
      .filter(child => this.isCompleted(child)).length;
  
    return status === "completed" ? completed : total;
  }

  /**
   * Ottiene l'elemento corrente nella lista del corso in base all'ID fornito.
   * 
   * @param currentCourseId - L'ID dell'elemento corrente.
   * @returns L'elemento corrente nella lista del corso o undefined se non trovato.
   */
  private getCurrentCourse(currentCourseId: string): ItemChild | undefined {
    return this._mergedCourse.find(child => child.referenceId === currentCourseId);
  }

  /**
   * Ottiene il corso successivo disponibile nella lista
   * 
   * @param currentCourseId L'ID del corso corrente.
   * @returns Restituisce il corso successivo disponibile, o undefined se non ce ne sono.
   */
  private getNextAvailableCourseOnList(currentCourseId: string) {
    const availableCourses = this._mergedCourse.filter(child => child.available && !this.isCompleted(child));
    const indexOfCurrentAvailableCourse = availableCourses.findIndex(child => child.referenceId === currentCourseId);
    const currentCourseAndNextCourseAreSame = availableCourses[0] === availableCourses[indexOfCurrentAvailableCourse];

    if (availableCourses?.[indexOfCurrentAvailableCourse + 1]) return availableCourses?.[indexOfCurrentAvailableCourse + 1];
    
    // In caso il primo 
    if (currentCourseAndNextCourseAreSame) return undefined;
    else return availableCourses?.[0];
    
  }

  /**
   * Verifica se un corso è completato al 100%.
   * 
   * @param course Il corso da verificare.
   * @returns Restituisce true se il corso è completato al 100%, altrimenti false.
   */
    private isCompleted(course: TrackerItemChild) {
      return parseInt((course?.childObject as any)?.completionPercentage, 10) === 100
    }

  /**
   * Naviga all'elemento successivo nella lista e ricarica i dati del corso.
   */
  public async goToNextElement() {
    if (this._isEverithingComplete) {
      return this.onNavBack();
    }
    // ID dell'elemento corrente recuperato dai parametri dell'URL.
    const itemId = this.route.snapshot.paramMap.get("itemId");
    
    const retriveNextCourse = this.getNextAvailableCourseOnList(itemId);
    this._canGoNextStep = retriveNextCourse ? true : false;

    if (!this._canGoNextStep) return;
    await this.router.navigate(["../../", retriveNextCourse.itemId, retriveNextCourse.referenceId], { relativeTo: this.route });
    const urlTree = this.router.createUrlTree(["../../", retriveNextCourse.itemId, retriveNextCourse.referenceId]);
    this.onPress.emit(this.router.serializeUrl(urlTree));

    this.initializeCourseData(this.itemDetails);
  }

  public onNavBack() {
    // ID del genitore (progetto o LP) recuperato dai parametri dell'URL.
    const parentId = this.route.snapshot.paramMap.get("projectId") || this.route.snapshot.paramMap.get("lpId");

    this.router.navigate(["takers/library/itemDetails", parentId])    
  }
}