<div class="section-card" fxLayout.gt-sm="row" fxLayout="column">
  <div class="img-item">
    <img class="active" title="{{'home.CUP' | translate}}" src="../../../assets/icons/Cup-white.svg">
    <img class="video" title="Video" src="../../../assets/icons/Video.svg" title="Video">
  </div>

  <div class="description-item" fxLayout="column">
    <!-- Titolo -->
    <div>
      <h2 class="text-uppercase no-margin title-list-object">
        <div class="timeline-item no-overflow">
          <div class="animated-background half-width">
          </div>
        </div>
      </h2>
    </div>

    <div>
      <img class="gen-divider" title="{{'generic.DIVIDER' | translate}}" src="../../../assets/icons/gen-divider.svg">
    </div>
    <div fxLayout="column">
      <!-- Sottotitolo -->
      <h4 class="margin-bottom10">
        <div class="timeline-item no-overflow">
          <div class="animated-background half-width">
          </div>
        </div>
      </h4>
      <!-- Descrizione -->
      <div class="timeline-item margin-bottom5 no-overflow">
        <div class="animated-background">
          <div class="background-masker header-top"></div>
        </div>
      </div>
      <div class="timeline-item margin-bottom5 no-overflow">
        <div class="animated-background half-width">
          <div class="background-masker header-top"></div>
        </div>
      </div>
      <div class="timeline-item margin-bottom5 no-overflow">
        <div class="animated-background width70">
          <div class="background-masker header-top"></div>
        </div>
      </div>
      <div class="timeline-item margin-bottom5 no-overflow">
        <div class="animated-background width25">
          <div class="background-masker header-top"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="call-to-action" fxLayout="column" fxLayoutAlign="space-between center">
    <div class="fullWidth" fxLayout="column" fxLayoutAlign="center center">
      <div class="infoItem" fxLayout="row wrap" fxLayoutAlign="center center">
        <div class="wrapper" fxLayout="row" fxLayoutAlign="start center">
          <svg-icon class="icon" src="assets/icons/Durata.svg"></svg-icon>
          <div class="timeline-item no-overflow">
            <div class="animated-background half-width">
            </div>
          </div>
        </div>
        <!-- Indicatore della percentuale di avanzamento -->
        <div class="wrapper" fxLayout="row" fxLayoutAlign="start center">
          <svg-icon class="icon" src="assets/icons/Avanzamento.svg"></svg-icon>
          <div class="timeline-item no-overflow">
            <div class="animated-background half-width">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Porta alla pagina di dettaglio dell'oggetto -->
    <div fxLayout="column" fxLayoutAlign="center center">
      <button class="btn-primary-1">
        <span translate="card.ACCESS"></span>
      </button>
    </div>
  </div>
</div>