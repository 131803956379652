/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante la contabilità dei docenti
*/

import * as TeachersActions from './teachers-accounting.actions';
import * as fromApp from '../../ngrx/app.reducers';

export interface FeatureState extends fromApp.AppState {
  manageTeachersAccounting: ManageTeacherAccountingState
}

// Interfaccia dello Store
export interface ManageTeacherAccountingState {
  teachers: any[];
  count: number;
  fromRecord: number;
  numRecords: number;
  page: number;
  sortingDir: string;
  sortingCol: string;
  isFetchingTeachers: boolean;
  teachersTextFilter: string
}

// Store iniziale
const initialManageTeacherAccountingState: ManageTeacherAccountingState = {
  teachers: [],
  count: 0,
  fromRecord: 0,
  numRecords: 20,
  sortingDir: 'DESC',
  sortingCol: 'name',
  page: 1,
  isFetchingTeachers: false,
  teachersTextFilter: null
};

export function TeachersReducer(state = initialManageTeacherAccountingState, action: TeachersActions.TeachersActions) {
  switch (action.type) {
    case TeachersActions.SET_TEACHERS:
      return {
        ...state,
        isFetchingTeachers: false,
        teachers: [...action.payload]
      };
    case TeachersActions.SET_PAGINATION_TEACHERS:
      return {
        ...state,
        fromRecord: action.payload.fromRecord,
        numRecords: action.payload.numRecords
      };
    case TeachersActions.GET_TEACHERS:
      return {
        ...state,
        isFetchingTeachers: true
      };
    case TeachersActions.SET_SORTING_DIR:
      return {
        ...state,
        sortingDir: action.payload
      };
    case TeachersActions.SET_SORTING_COL:
      return {
        ...state,
        sortingCol: action.payload
      };
    case TeachersActions.SET_PAGE_TEACHERS:
      return {
        ...state,
        page: action.payload
      };
    case TeachersActions.SET_TEACHERS_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    case TeachersActions.SET_TEACHERS_TEXT_FILTER:
      return {
        ...state,
        teachersTextFilter: action.payload
      };

    default:
      return state;
  }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getTeachers = (state: ManageTeacherAccountingState) => (state && state.teachers) || [];
export const getTeachersCount = (state: ManageTeacherAccountingState) => (state && state.count) || 0;
export const getTeachersFromRecord = (state: ManageTeacherAccountingState) => (state && state.fromRecord) || 0;
export const getTeachersNumRecords = (state: ManageTeacherAccountingState) => (state && state.numRecords) || 20;
export const getTeachersPage = (state: ManageTeacherAccountingState) => (state && state.page) || 0;
export const getSortingDir = (state: ManageTeacherAccountingState) => (state && state.sortingDir) || 'DESC';
export const getSortingCol = (state: ManageTeacherAccountingState) => (state && state.sortingCol) || 'name'
export const isFetchingTeachers = (state: ManageTeacherAccountingState) => (state && state.isFetchingTeachers) || false;
export const getTeachersTextFilter = (state: ManageTeacherAccountingState) => (state && state.teachersTextFilter) || null;