/*
* Componente che crea la tabella con le ultime iniziative
*/

import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter, ViewChildren, HostListener } from "@angular/core"
import { Item } from "../../../../cm2-commonclasses";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: 'last-initiatives',
  templateUrl: './last-initiatives.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LastInitiativesComponent implements OnInit {
  @Input() lastInitiatives: Item[];
  dateFormat: string;
  openedMenu: boolean[];
  @Output() openRemoveInitiativeConfirmModal = new EventEmitter<any>();
  @Output() goToEdit = new EventEmitter<any>();
  @Output() goToPreview = new EventEmitter<any>();
  @ViewChildren('menu') menu;
  constructor(private translate: TranslateService) {
  }


  ngOnInit(): void {
    this.openedMenu = [];
    // Recupero il formato per la data
    this.dateFormat = this.translate.instant('generic.DATE_FORMAT_FULL');
    for (let i = 0; i < this.lastInitiatives.length; i++) {
      this.openedMenu.push(false);
    }
  }

  // Evento che cancella l'iniziativa
  onDelete(initiative: Item): void {
    this.openRemoveInitiativeConfirmModal.emit(initiative);
  }

  // Riguardo al click del mouse fuori dal menu azioni
  @HostListener('mousedown', ['$event']) onClick(e) {
    if (this.menu._results.filter((x: any) => x.nativeElement.style.display == "block").length && e.target.nodeName != "LI" && e.target.className != "item") {
      let openedMenus = this.menu._results.filter((x: any) => x.nativeElement.style.display == "block");
      for (let i = 0; i < openedMenus.length; i++) {
        openedMenus[i].nativeElement.style.display = "none";
      }
    }
  }

  // Apre il menu della tabella
  toggleMenu(index: number) {
    if (this.menu._results[index].nativeElement.style.display == "none") {
      this.menu._results[index].nativeElement.style.display = "block";
    } else {
      this.menu._results[index].nativeElement.style.display = "none";
    }
  }

  // Evento che porta alla pagina di modifica dell'iniziativa
  onEdit(initiative: Item): void {
    this.goToEdit.emit(initiative);
  }

  // Evento che porta alla pagina di anteprima dell'iniziativa
  onPreview(initiative: Item): void {
    this.goToPreview.emit(initiative);
  }
}