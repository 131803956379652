<div class="tabset">
  <div class="button-bar">
    <button>
    </button>
    <button>
    </button>
    <button>
    </button>
    <button>
    </button>
  </div>

  <div class="content">
    <div class="section-in-card">
      <div class="section-content padding-top0">
        <div class="margin-top0 generali-input small" fxFlexFill>
          <div class="title-input">
            <div fxLayout="row">
              <div class="line width250"></div>
            </div>
          </div>
          <div class="line width310 ngSelect"></div>
          <div class="line width130 ngSelect"></div>
        </div>
      </div>
    </div>
    <div class="section-in-card margin-top38">
      <div class="section-content padding-top0">
        <div class="margin-top0 generali-input small" fxFlexFill>
          <div class="title-input">
            <div fxLayout="row">
              <div class="line width250"></div>
            </div>
          </div>
          <div class="line width310 ngSelect"></div>
          <div class="line width130 ngSelect"></div>
        </div>
      </div>
    </div>
    <div class="section-in-card margin-top38">
      <div class="section-content padding-top0">
        <div class="margin-top0 generali-input small" fxFlexFill>
          <div class="title-input">
            <div fxLayout="row">
              <div class="line width250"></div>
            </div>
          </div>
          <div class="line width310 ngSelect"></div>
          <div class="line width130 ngSelect"></div>
        </div>
      </div>
    </div>
    <div class="section-in-card margin-top38">
      <div class="section-content padding-top0">
        <div class="margin-top0 generali-input small" fxFlexFill>
          <div class="title-input">
            <div fxLayout="row">
              <div class="line width250"></div>
            </div>
          </div>
          <div class="line width310 ngSelect"></div>
          <div class="line width130 ngSelect"></div>
        </div>
      </div>
    </div>
  </div>
</div>