<!--  Modale di conferma -->
<lib-alloy-modal [ngClass]="containerClass" id="{{ modalId }}" role="dialog" aria-modal="true" aria-labelled-by="modal-title">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h1 class="custom-modal-title" id="modal-title">{{ modalTitle }}</h1>
    <svg-icon class="cursor-pointer" *ngIf="dismissAction" (click)="emitDismissAction()"
      src="assets/icons/icon-times.svg">
    </svg-icon>
  </div>
  <div class="content-modal" fxLayout="row" fxLayoutAlign="start start">
    <div class="md-dialog-content">
      <div *ngIf="modalBody_1 && modalBody_1.length">
        <br>
        <br>
        <span>{{ modalBody_1 }}</span>
      </div>
      <span *ngIf="!innerHtml">{{ modalBody }}</span>
      <span *ngIf="innerHtml" [innerHTML]="modalBody"></span>
    </div>
  </div>
  <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- Annulla -->
    <lib-alloy-button *ngIf="cancelBtnLabel" importance="bordless" dimension="btn-regular" [text]="cancelBtnLabel"
      (onButtonClicked)="emitDismissAction()">
    </lib-alloy-button>

    <!-- Conferma -->
    <lib-alloy-button importance="primary" dimension="btn-regular" [text]="confirmBtnLabel"
      (onButtonClicked)="emitConfirmation()">
    </lib-alloy-button>

    <!-- Secondo tasto di Conferma (opzionale) -->
    <lib-alloy-button *ngIf="confirmSecondBtnLabel" importance="bordless" dimension="btn-regular"
      [text]="confirmSecondBtnLabel" (onButtonClicked)="emitSecondConfirmation();">
    </lib-alloy-button>
  </div>
</lib-alloy-modal>