/*
 * Servizio che permette le chiamate rest relative ai taker (invitati ad un corso)
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ItemAttributeTypes, SenecaResponse, ItemFrontEndWrapper, CourseManagerItem, EnrichedItemTaker, Survey, Item } from "../../../cm2-commonclasses";
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { GlobalApplicationData } from '../../shared/models/global-application-data.model';
import { ExtendedItem } from "../../shared/models/extended-item.model";
import { ExtendedSurvey } from '../../shared/models/extended-survey.model';

@Injectable()
export class TakerService {
  applicationData: GlobalApplicationData;
  result$: Subscription;
  constructor(private store: Store<fromApp.AppState>,
    private http: HttpClient) {
    // Dallo store recupero l'oggetto principale contenente generici dati, e la lingua dell'applicazione
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    const combinedSelectes$ = combineLatest(globalApplicationData$);
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData]) => {
        this.applicationData = globalApplicationData;
      });
  }

  // Torna uno Stage dato il suo Id
  getStageFromIdForPublic(userId: string, stageItemId: string, isLibraryItem?: boolean): any {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('stageItemId', stageItemId);
    httpParams = httpParams.append('isLibraryItem', isLibraryItem && isLibraryItem.toString() || '');
    httpParams = httpParams.append('withFullAttributesLoaded', 'true');
    httpParams = httpParams.append('withFullChildrenAttributesLoaded', 'true');
    httpParams = httpParams.append('itemAttributeTypesToResolve',
      JSON.stringify([ItemAttributeTypes.LOCATION, ItemAttributeTypes.TUTOR, ItemAttributeTypes.TEACHER,
      ItemAttributeTypes.PERSON_IN_CHARGE
        /* TEMPLATES ItemAttributeTypes.INVITATION_MAIL, ItemAttributeTypes.SELF_CANCEL_MAIL, ItemAttributeTypes.PREREGISTRATION_MAIL,
        ItemAttributeTypes.CONFIRMATION_MAIL, ItemAttributeTypes.OB_PROMOTION_MAIL, ItemAttributeTypes.PRESENCE_REGISTERED_MAIL,
        ItemAttributeTypes.COURSE_REMINDER_MAIL */
      ]));

    return this.http.get<ExtendedItem>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-stage-from-id-for-public/' + stageItemId, {
      params: httpParams
    });
  }

  // Recupera un upload
  getUploadByIdForPublic(userId?: string, uploadId?: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('uploadId', uploadId || '');
    httpParams = httpParams.append('userId', userId || '');
    return this.http.get<SenecaResponse<Survey>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-upload-by-id-for-public', {
      params: httpParams
    });
  }

  // Recupera una firma
  getMailSignatureTextByIdForPublic(userId: string, textTemplateId: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('textTemplateId', textTemplateId || '');
    httpParams = httpParams.append('userId', userId || '');
    return this.http.get<SenecaResponse<Survey>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-mail-signature-text-by-id-for-public', {
      params: httpParams
    });
  }

  // Imposta le notifiche come lette
  markNotificationAsReadByReference(referenceId: string, referenceType: string, referenceApplicationName: string): any {
    return this.http.post<SenecaResponse<ExtendedSurvey>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/mark-notification-as-read-by-reference', {
      referenceApplicationName: referenceApplicationName,
      referenceType: referenceType,
      referenceId: referenceId
    });
  }

  // Recupera tutte le Survey dell'utente loggato
  getSurveys(userId: string, referenceId: string, itemId?: string, startIfNotStarted?: boolean): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('referenceId', referenceId || '');
    httpParams = httpParams.append('userId', userId || '');
    httpParams = httpParams.append('itemId', itemId || '');
    httpParams = httpParams.append('startIfNotStarted', startIfNotStarted && startIfNotStarted.toString() || '');
    return this.http.get<SenecaResponse<Survey>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-surveys/' + referenceId, {
      params: httpParams
    });
  }

  // Recupera tutte le Survey dell'utente loggato
  getFlatSurveyBySurveyId(userId: string, surveyId: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('surveyId', surveyId || '');
    httpParams = httpParams.append('userId', userId || '');
    return this.http.get<SenecaResponse<Survey>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-flat-survey-by-surveyId/' + surveyId, {
      params: httpParams
    });
  }

  // Recupera una lista di rilevazioni di un'edizione specifica
  getEditionAssessmentsForPublic(userId?: string, editionItemId?: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId || '');
    httpParams = httpParams.append('editionItemId', editionItemId || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-edition-assessments-for-public', {
      params: httpParams
    });
  }

  searchTags(type: string, allData?: boolean, usedInItemsOnly?: boolean): any {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('type', type || '');
    httpParams = httpParams.append('allData', allData && allData.toString() || '');
    if (usedInItemsOnly) {
      httpParams = httpParams.append('usedInItemsOnly', 'true');
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/search-tags', {
      params: httpParams
    });
  }

  // Recupera una specifica survey
  getSurveyBySurveyId(userId: string, surveyId: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('surveyId', surveyId || '');
    httpParams = httpParams.append('userId', userId || '');
    return this.http.get<SenecaResponse<Survey>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-survey-by-surveyId/' + surveyId, {
      params: httpParams
    });
  }

  // Servizio che recupera un Item dato il suo id
  listUserTakers(data: CourseManagerItem.ListUserTakersForPublic) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', data.userId);
    httpParams = httpParams.append('editionItemIds', JSON.stringify(data.editionItemIds));
    httpParams = !!data.stageItemId ? httpParams.append('stageItemId', data.stageItemId) : httpParams;
    httpParams = httpParams.append('allData', '' + data.allData);
    return this.http.get<SenecaResponse<Array<EnrichedItemTaker>>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-user-takers-for-public', {
      params: httpParams
    });
  }

  // Servizio che verifica se un'edizione richiede il check sulla privacy
  isCoursePrivacyToAccept(userId: string, editionItemId: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('editionItemId', editionItemId);
    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/is-course-privacy-to-accept', {
      params: httpParams
    });
  }

  // Servizio che recupera la descrizione di un attributo
  getItemAttributeDescriptionForPublic(attributeType: string, initiativeId: string, userId: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('attributeType', attributeType);
    httpParams = httpParams.append('itemId', initiativeId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-item-attribute-description-for-public', {
      params: httpParams
    });
  }

  // Servizio che recupera il nome di un item (per i taker)
  getItemTitleByIdForPublic(userId: string, itemId: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('itemId', itemId);
    return this.http.get<SenecaResponse<string>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-item-title-by-id-for-public', {
      params: httpParams
    });
  }

  userHasRequiredCompetences(userId, stage) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/user-has-required-competences', {
      userId: userId,
      stageItemId: stage
    });
  }

  userSelfConfirm(userId: string, editionItemId: string, sendEmail?: boolean, usedTakes?: number, coursePrivacyAccepted?: boolean) {
    return this.http.post<SenecaResponse<ItemFrontEndWrapper>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/user-self-confirm', {
      userId: userId,
      editionItemId: editionItemId,
      sendEmail: sendEmail,
      usedTakes: usedTakes,
      coursePrivacyAccepted: coursePrivacyAccepted
    });
  }

  updateSurvey(userId: string, survey: Survey, closeSurvey: boolean, itemId: string) {
    return this.http.post<SenecaResponse<ExtendedSurvey>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/update-survey', {
      userId: userId,
      survey: survey,
      closeSurvey: closeSurvey,
      itemId: itemId
    });
  }

  userSelfCancel(userId: string, editionItemId: string, statusNote: string, sendEmail?: boolean, stageId?: string) {
    return this.http.post<SenecaResponse<ItemFrontEndWrapper>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/user-self-cancel', {
      userId: userId,
      editionItemId: editionItemId,
      sendEmail: sendEmail,
      statusNote: statusNote,
      stageItemId: stageId
    });
  }

  listUserMaterialsForParent(userId: string, fromRecord: number, numRecords: number, parentIds: string[], materialTypes: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    httpParams = httpParams.append('parentId', JSON.stringify(parentIds));
    httpParams = httpParams.append('materialType', JSON.stringify(materialTypes));
    return this.http.get<SenecaResponse<Item[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-user-materials-for-parent', {
      params: httpParams
    });
  }

  countUserMaterialsForParent(userId: string, parentIds: string[], materialTypes: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('parentId', JSON.stringify(parentIds));
    httpParams = httpParams.append('materialType', JSON.stringify(materialTypes));
    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-user-materials-for-parent', {
      params: httpParams
    });
  }

  updateStatusFromPlayer(userId: string, itemId: string, eventName: string, totalTime: number, currentTime: number) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/update-player-status/${itemId}/${eventName}/${totalTime}/${currentTime}`, {
      userId: userId
    });
  }

  retriveAttachmentUrl(userId: string, item: Item, attachmentId: string, adminMode: boolean, isItemOtherType: boolean) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/get-attachment-url/${attachmentId}`, {
      userId: userId,
      item: item,
      adminMode: adminMode,
      isItemOtherType: isItemOtherType
    });
  }

  countUsedTakesForPublic(userId: string, editionItemIds: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('editionItemIds', JSON.stringify(editionItemIds));

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/count-used-takes-for-public`, {
      params: httpParams
    });
  }

  tryLibraryItemTakerEnroll(itemId: string, stageItemId?: string, editionId?: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/enroll-library-item-taker`, {
      itemId: itemId,
      stageId: stageItemId,
      editionId: editionId
    });
  }

  // Preiscrizione su uno stage/edizione
  preregisterOnCourse(stageItemId: string, editionItemId?: string, sendEmail?: boolean, coursePrivacyAccepted?: boolean) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/user-self-preregistration-confirm`, {
      editionItemId: editionItemId,
      stageItemId: stageItemId,
      sendEmail: sendEmail,
      coursePrivacyAccepted: coursePrivacyAccepted
    });
  }

  userSelfConfirmExternal(editionItemId: string, newStatus: string, usedTakes?: number) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/user-self-confirm-external', {
      editionItemId: editionItemId,
      usedTakes: usedTakes,
      newStatus: newStatus
    });
  }

  availabilityCheckVPL(stageItemId: string, days: string[], userIds: string[], dayIdToIgnore: string[]) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/search-concurrent-training-courses-for-takers', {
      stageItemId: stageItemId,
      days: days,
      // userIds: userIds,
      dayIdToIgnore: dayIdToIgnore
    });
  }
}
