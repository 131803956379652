import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'vertical-widget',
  templateUrl: './vertical-widget.component.html',
  styleUrls: ['./vertical-widget.component.scss']
})
export class VerticalWidgetComponent {
  @Input() indicator;
  @Output() onMainButtonClick = new EventEmitter<any>();
  @Output() onInfoClick = new EventEmitter<any>();

  constructor() {
  }

  // Emette l'evento al click sul pulsante principale
  emitOnMainButtonClick() {
    this.onMainButtonClick.emit(this.indicator.id);
  }

  // Emette l'evento al click sull'icona delle info
  emitOnInfoClick() {
    this.onInfoClick.emit(this.indicator.id);
  }
}
