/*
 * Servizio che permette le chiamate rest relative alle iniziative (corsi)
*/
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from "rxjs";
import * as CommonClasses from "../../../cm2-commonclasses";
import { User, UserGroup, SenecaResponse } from "../../../cm2-commonclasses";
import * as fromApp from '../../ngrx/app.reducers';
import { GlobalApplicationData } from "../models/global-application-data.model";
import * as ProfileActions from '../../profile/ngrx/profile.actions';
import { UserCredits, BienniumData } from 'src/app/profile/ngrx/profile.reducers';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class UserService {

  applicationData: GlobalApplicationData;

  constructor(private store: Store<fromApp.AppState>,
    private toastr: ToastrService,
    private translate: TranslateService,
    private http: HttpClient) {
    // Dallo Store applicativo, ricavo l'url per le chiamate rest e anche la pagina corrente in cui mi trovo nella visualizzazione delle iniziative
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    const combinedSelectes$ = combineLatest(globalApplicationData$);
    combinedSelectes$.subscribe(
      ([globalApplicationData]) => {
        this.applicationData = globalApplicationData;
      });
  }

  // Recupera il contatore delle offerte create da un determinato fornitore
  getCreditsCounterSplitted(userId?: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();

    if (userId)
      httpParams = httpParams.append("userId", userId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-credits-counter-by-type-and-userId-splitted', {
      params: httpParams
    });
  }

  // Effettua il conteggio degli elementi trovati con la ricerca
  getCreditsCounter() {
    this.getCreditsCounterSplitted()
      .subscribe(
        (data: SenecaResponse<any>) => {
          if (data && !data.error) {
            let creditsData: UserCredits = {
              ivass: data.response.ivass || 0,
              efaa: data.response.efaa || 0,
              efab: data.response.efab || 0,
              efp: data.response.efp || 0,
              mifid: data.response.mifid || 0
            }
            this.store.dispatch(new ProfileActions.SetUserCredits(creditsData));
          }
        }
        , (err) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
        }
      );
  }

  // Effettua il conteggio degli elementi trovati con la ricerca
  getCurrentBienniumForIvass() {
    this.getCurrentBiennium()
      .subscribe(
        (data: SenecaResponse<any[]>) => {
          if (data.response && data.response.length && data.response.length > 0) {
            let bienniumData: BienniumData = {
              currentBiennium: data.response[0],
              currentBienniumRequiredCredits: data.response[1] || "-"
            }
            this.store.dispatch(new ProfileActions.SetBienniumData(bienniumData));
          }
        }
        , (err) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
        }
      );
  }

  /**
   * Ritorna il count totale dei gruppi
   */
  countGroups(groupsTextFilter?: string, customGroupsOnly?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', groupsTextFilter || "");

    if (customGroupsOnly) {
      httpParams = httpParams.append('customGroupsOnly', "true");
    }
    return this.http.get<CommonClasses.SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-groups-by-tenant/', {
      params: httpParams
    });
  }

  getGroupList(fromRecord: any, numRecords: any, groupsTextFilter?: string, customGroupsOnly?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    httpParams = httpParams.append('searchedText', groupsTextFilter || "");
    httpParams = httpParams.append('withUsersCount', "true");

    if (customGroupsOnly) {
      httpParams = httpParams.append('customGroupsOnly', "true");
    }
    return this.http.get<CommonClasses.SenecaResponse<UserGroup[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-groups-by-tenant/', {
      params: httpParams
    });
  }

  getCurrentBiennium(): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-current-biennium', {
      params: httpParams
    });
  }

  // Recupera il counter totale persone all'interno del proprio Tenant
  countPersonsInTenant(searchedText?: string, includeMe?: boolean, value?: string, type?: string, confirmedUsers?: boolean, notConfirmedUsers?: boolean): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText);
    }
    if (includeMe) {
      httpParams = httpParams.append('includeMe', includeMe.toString());
    }
    if (type && type == 'CID') {
      httpParams = httpParams.append('cid', value);
    }
    if (type && type == 'EMAIL') {
      httpParams = httpParams.append('email', value);
    }

    if (confirmedUsers) {
      httpParams = httpParams.append('onlyConfirmed', confirmedUsers.toString());
    }
    if (notConfirmedUsers) {
      httpParams = httpParams.append('onlyNotConfirmed', notConfirmedUsers.toString());
    }

    return this.http.get<CommonClasses.SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-persons-in-tenant', {
      params: httpParams
    });
  }

  //Recupera il counter totale persone all'interno del proprio Tenant come Manager
  countPersonsInTenantForManager(searchedText?: string, includeMe?: boolean, value?: string, type?: string, confirmedUsers?: boolean) {
    let httpParams = new HttpParams();
    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText);
    }
    if (includeMe) {
      httpParams = httpParams.append('includeMe', includeMe.toString());
    }
    if (type && type == 'CID') {
      httpParams = httpParams.append('cid', value);
    }
    if (type && type == 'EMAIL') {
      httpParams = httpParams.append('email', value);
    }

    if (confirmedUsers) {
      httpParams = httpParams.append('onlyConfirmed', confirmedUsers.toString());
    }
    return this.http.get<CommonClasses.SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-persons-in-tenant-for-manager', {
      params: httpParams
    });
  }

  // Recupera una lista di persone all'interno del proprio Tenant
  getPersonsInTenant(fromRecord: number, numRecords: number, searchedText?: string, includeMe?: boolean, confirmedUsers?: boolean, notConfirmedUsers?: boolean, isExternal?: boolean, internalAndExternal?: boolean): any {
    let from = fromRecord && fromRecord.toString();
    let to = numRecords && numRecords.toString();
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', from);
    httpParams = httpParams.append('numRecords', to);
    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText);
    }
    if (includeMe) {
      httpParams = httpParams.append('includeMe', includeMe.toString());
    }
    if (confirmedUsers) {
      httpParams = httpParams.append('onlyConfirmed', confirmedUsers.toString());
    }
    if (notConfirmedUsers) {
      httpParams = httpParams.append('onlyNotConfirmed', notConfirmedUsers.toString());
    }
    if (isExternal) {
      httpParams = httpParams.append('isExternal', isExternal.toString());
    }
    if (internalAndExternal) {
      httpParams = httpParams.append('internalAndExternal', internalAndExternal.toString());
    }

    return this.http.get<CommonClasses.SenecaResponse<User>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-persons-in-tenant/' + from + '/' + to, {
      params: httpParams
    });
  }

  //// Recupera una lista di persone all'interno del proprio Tenant come Manager
  getPersonsInTenantForManager(fromRecord: number, numRecords: number, searchedText?: string, includeMe?: boolean, confirmedUsers?: boolean) {
    let from = fromRecord && fromRecord.toString();
    let to = numRecords && numRecords.toString();
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', from);
    httpParams = httpParams.append('numRecords', to);
    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText);
    }
    if (includeMe) {
      httpParams = httpParams.append('includeMe', includeMe.toString());
    }
    if (confirmedUsers) {
      httpParams = httpParams.append('onlyConfirmed', confirmedUsers.toString());
    }
    return this.http.get<CommonClasses.SenecaResponse<User>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-persons-in-tenant-for-manager/' + from + '/' + to, {
      params: httpParams
    });
  }

  // Servizio che recupera una lista di utenti dati i loro userId
  getUserByUserIds(userIds: string[]) {
    return this.http.post<CommonClasses.SenecaResponse<User[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/users-by-userIds', {
      userIds: userIds
    });
  }
}
