import { Component, Output, EventEmitter, Input, ViewChild, ElementRef, Renderer2, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import * as AuthActions from '../../auth/ngrx/auth.actions';
import * as CoreActions from '../ngrx/core.actions';
import * as ProfileActions from '../../profile/ngrx/profile.actions';
import * as DashboardActions from '../../dashboard/ngrx/dashboard.actions';
import { Router } from '@angular/router';
import { JwtPayload, RequiredAuth, SenecaResponse, ManagerRoleTypes } from "../../../cm2-commonclasses";
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { RedirectService } from '../../shared/services/redirect.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { environment } from 'src/environments/environment';
import * as NewsActions from '../../news/ngrx/news.actions';
import { ToastrService } from 'ngx-toastr';


const sectionStates = Object.freeze({ "entered": 1, "wentToTakers": 1, "wentToAdmin": 2, "wentToManager": 3, "wentToTeacher": 4 })

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  loggedUser: JwtPayload;
  avatar: string;
  isMainMenuSidebarOpened: boolean;
  isProfileMenuSidebarOpened: boolean;
  result$: Subscription;
  @Output() activateAnimation = new EventEmitter();
  notificationsCounter: number;
  notificationsPollingStarted: boolean;
  isAdmin: boolean;
  isTeacher: boolean;
  canAccessUserMode: boolean;
  canAccessAdminMode: boolean;
  canAccessUserNotifications: boolean;
  canAccessUserProfile: boolean;
  notificationsPollingNewsStarted: boolean;
  @Input() currentModule: string;
  isProfileMenuOpen: boolean = false;
  canAccessManagerMode: boolean;
  canAccessTeacherMode: boolean;
  hasSubordinates: string;
  currentSection: number = sectionStates.entered;
  isInternalBDI: boolean;

  managerWithSubordinates: boolean;
  managerWithSubordinatesIsDelegated: boolean;

  @ViewChild('headerMenu') headerMenu: ElementRef;
  @ViewChild('profileChangerButton') profileChangerButton: ElementRef;

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    private renderer: Renderer2,
    private toastr: ToastrService,
    public translate: TranslateService,
    public redirectService: RedirectService,
    public authService: AuthService) {
    // Recupero le informazioni sull'utente loggato dallo Store applicativo
    let loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    // Recupero dallo Store applicativo la variabile che mi indica che la sidenav di sinistra col menu principale è aperta
    let isMainMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isMainMenuSidebarOpened);
    // Recupero dallo Store applicativo la variabile che mi indica che la sidenav di destra col menu utente è aperta
    let isProfileMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isProfileMenuSidebarOpened);
    let notificationsCounter$: Observable<number> = this.store.select(fromApp.getNotificationsCounter);

    setTimeout(() => {
      let menu = document.getElementById("menu-button");
      let notifications = document.getElementById("n-menu-button");
      menu.getElementsByTagName("p")[0].style.fontWeight = "bold";
      if (notifications && notifications.getElementsByTagName("p")[0]) {
        notifications.getElementsByTagName("p")[0].style.fontWeight = "bold";
      }
    }, 300)

    // Sto in ascolto dei vari cambiamenti
    const combinedSelectes$ = combineLatest([loggedUser$, isMainMenuSidebarOpened$, isProfileMenuSidebarOpened$, notificationsCounter$]);
    this.result$ = combinedSelectes$.subscribe(
      ([loggedUser, isOpened, isProfileMenuSidebarOpened, notificationsCounter]) => {
        this.loggedUser = loggedUser;
        this.isMainMenuSidebarOpened = isOpened;
        this.notificationsCounter = notificationsCounter;
        this.isProfileMenuSidebarOpened = isProfileMenuSidebarOpened;
        if (this.loggedUser) {
          this.checkIfUserHasSubordinates();
          // Recupero l'avatar dell'utente loggato dal suo payload, con una foto di default qualora mancasse
          if (!this.authService.isTeacherMode(true)) {
            if (!this.notificationsPollingStarted) {
              this.store.dispatch(new CoreActions.StartCountNotificationsPolling());
              this.notificationsPollingStarted = true;
            }
            if (environment.canAccessUserNews && !this.notificationsPollingNewsStarted) {
              this.store.dispatch(new NewsActions.StartCountNotificationsNewsPolling());
              this.notificationsPollingNewsStarted = true;
            }

            this.isAdmin = loggedUser.auths && loggedUser.auths.length
              && this.authService.isUserAuthorized(RequiredAuth.CORPORATEACADEMY_IS_ADMIN, loggedUser.auths);

            this.isTeacher = loggedUser.auths && loggedUser.auths.length
              && this.authService.isUserAuthorized('CORPORATEACADEMY_SUPPLIER_PERSON', loggedUser.auths);

            this.avatar = (this.loggedUser.user && this.loggedUser.user.userOptions && this.loggedUser.user.userOptions.avatarImage)
              || (this.loggedUser && this.loggedUser.user && (!this.loggedUser.user.chiaveSesso || this.loggedUser.user.chiaveSesso == "M") ? 'assets/img/placeholder_male.svg' : 'assets/img/placeholder_female.svg');

          }
          if (this.loggedUser?.user.socDistacco == "Banca d'Italia") {
            this.isInternalBDI = true;
          }
        }
      });

    this.canAccessUserMode = environment.canAccessUserMode;
    this.canAccessAdminMode = environment.canAccessAdminMode;
    this.canAccessUserNotifications = environment.canAccessUserNotifications;
    this.canAccessUserProfile = environment.canAccessUserProfile;
    this.canAccessManagerMode = environment.canAccessManagerMode;
    this.canAccessTeacherMode = environment.canAccessTeacherMode;

    this.renderer.listen('window', 'click', (e: Event) => {
      if ((this.profileChangerButton && this.profileChangerButton.nativeElement && !this.profileChangerButton.nativeElement.contains(e.target)) && (this.headerMenu && this.headerMenu.nativeElement && !this.headerMenu.nativeElement.contains(e.target))) {
        this.isProfileMenuOpen = false;
      }
    });
  }

  ngOnInit() {

    setTimeout(() => {
      let menu = document.getElementById("menu-button");
      let notifications = document.getElementById("n-menu-button");
      menu.getElementsByTagName("p")[0].style.fontWeight = "bold";
      if (notifications && notifications.getElementsByTagName("p")[0]) {
        notifications.getElementsByTagName("p")[0].style.fontWeight = "bold";
      }
    }, 300)
  }

  // La sidenav si può chiudere anche cliccando sul backdrop. In tal caso, eseguo il dispatch dell'azione che setta come chiusa la sidenav nello Store
  closeMainMenuSidenav(): void {
    this.store.dispatch(new CoreActions.CloseMainMenuSidebar());
  }

  // Esegue lo switch utente/admin
  switchAdminMode() {
    this.redirectService.goToHome(this.authService.isAdminMode());
  }

  // La sidenav si può chiudere anche cliccando sul backdrop. In tal caso, eseguo il dispatch dell'azione che setta come chiusa la sidenav nello Store
  closeProfileMenuSidenav(): void {
    this.store.dispatch(new CoreActions.CloseProfileMenuSidebar());
  }

  // Porta alla home page
  goToHome(): void {
    this.redirectService.goToHome(!this.authService.isAdminMode() && !this.authService.isTeacherMode(), this.authService.isTeacherMode(), window.location.href.indexOf("/manager/") > 0);
  }

  // Porta alla pagina delle notifiche
  goToNotifications(): void {
    let href = window.location.href;
    if (href.includes('manager')) {
      this.router.navigate(['/manager/notifications']);
    }
    else if (href.includes('takers')) {
      this.router.navigate(['/takers/notifications']);
    }

  }

  // Porta alla pagina del profiloo
  goToProfile(): void {
    this.redirectService.goToProfile(!this.authService.isTeacherMode(), this.authService.isTeacherMode());
  }

  // Dispatch dell'azione che esegue il toggle sulla sidebar di sinistra col menu principale
  toggleMainMenuSidebar(): void {
    // Alza l'evento per il componente padre, che attive le animazioni sulle sidenav
    this.activateAnimation.emit();
    if (!this.isMainMenuSidebarOpened) {
      this.store.dispatch(new CoreActions.OpenMainMenuSidebar());
    } else {
      this.closeMainMenuSidenav();
    }
  }

  // Dispatch dell'azione che esegue il toggle sulla sidebar di sinistra col profilo
  toggleProfileMenuSidebar(): void {
    // Alza l'evento per il componente padre, che attiva le animazioni sulle sidenav
    this.activateAnimation.emit();
    if (!this.isProfileMenuSidebarOpened) {
      this.store.dispatch(new CoreActions.OpenProfileMenuSidebar());
    } else {
      this.closeProfileMenuSidenav();
    }
  }

  // Logout dall'applicazione
  onLogout() {
    const nameId = this.loggedUser && this.loggedUser.params && this.loggedUser.params.SAML_NAME_ID;
    const sessionIndex = this.loggedUser && this.loggedUser.params && this.loggedUser.params.SAML_SESSION_INDEX;
    const teacherMode = this.authService.isTeacherMode();
    this.store.dispatch(new ProfileActions.CancelLoggedUser());

    // Al click sul logout, eseguo il dispatch dell'action che disautentica l'utente, cancella il token (sia dal session storage che dallo State), e cancella il Payload (dato dalla decodifica del Token) dentro lo State
    this.store.dispatch(AuthActions.Logout());
    this.store.dispatch(new CoreActions.RemoveApplicationLang());
    this.store.dispatch(new CoreActions.RemoveRedirectUrl());
    this.store.dispatch(new DashboardActions.DeleteIndicators());
    let sessionStorageFilters = sessionStorage.getItem('dashboardFilters');
    if (sessionStorageFilters) {
      sessionStorage.removeItem('dashboardFilters');
    }
    const filters = sessionStorage.getItem("SEARCHES");
    if (filters) {
      sessionStorage.removeItem("SEARCHES");
    }
    const catalogFilters = sessionStorage.getItem("CATALOG_SEARCHES");
    if (catalogFilters) {
      sessionStorage.removeItem("CATALOG_SEARCHES");
    }
    if (this.isMainMenuSidebarOpened) {
      this.redirectService.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.redirectService.closeSidenavProfileMenu();
    }

    // Redirect alla pagina di login
    if (this.isInternalBDI) {
      if (nameId && sessionIndex) {
        window.location.href = "https://performanceprod.alloy.it/ssofacileit/logout" + "?SAML_NAME_ID=" + encodeURIComponent(nameId) + "&SAML_SESSION_INDEX=" + encodeURIComponent(sessionIndex);
      } else {
        window.location.href = "https://performanceprod.alloy.it/ssofacileit/logout";
      }
    } else if (teacherMode) {
      this.router.navigate(['/teacherLogin']);
    } else {
      this.router.navigate(['/localLogin']);
    }
  }

  canAccessManager() {
    return this.canAccessManagerMode && (this.managerWithSubordinates || this.managerWithSubordinatesIsDelegated);
  }

  toggleProfileMenu() {
    this.isProfileMenuOpen = !this.isProfileMenuOpen;
  }

  // Controlla se l'utente loggato ha dei subordinati, mostrando quindi la sezione manager (NB deve essere abilitata anche dall'environment)
  checkIfUserHasSubordinates() {
    if (this.loggedUser && this.loggedUser.user) {
      this.authService.checkIfUserHasSubordinates()
        .subscribe((data: SenecaResponse<string>) => {
          if (data.error) {
            this.toastr.error(this.translate.instant('errors.' + data.error));
          } else {
            this.hasSubordinates = data.response;
            if (this.hasSubordinates && this.hasSubordinates.length) {
              for (let i = 0; i < this.hasSubordinates.length; i++) {
                if (this.hasSubordinates[i] == ManagerRoleTypes.DIRECT || this.hasSubordinates[i] == ManagerRoleTypes.STRUCTURE) {
                  this.managerWithSubordinates = true;
                } else if (this.hasSubordinates[i] == ManagerRoleTypes.DELEGATED) {
                  this.managerWithSubordinatesIsDelegated = true;
                }
              }
            }
          }
        })
    }
  }

  redirect(whereTo: string) {
    switch (whereTo) {
      case 'takers':
        this.currentSection = sectionStates.wentToTakers;
        if (environment.canAccessTeacherMode) {
          this.store.dispatch(AuthActions.SetUserAuthenticated({ isTaker: true }));
        }
        this.redirectService.goToHome(true);
        break;
      case 'admin':
        this.redirectService.goToHome();
        break;
      case 'teacher':
        this.currentSection = sectionStates.wentToTeacher;
        this.store.dispatch(AuthActions.SetUserAuthenticated({ isTeacher: true }));
        this.redirectService.goToHome(false, true);
        break;
      case 'manager':
        this.redirectService.goToHome(false, false, true);
        break;
      default:
        this.router.navigate(['/404']);
    }
  }

  moreThanOneProfile() {
    let profiles = 0;
    // è un utente
    if (this.canAccessUserMode) {
      profiles++;
    }
    // è un admin
    if (this.canAccessAdminMode && this.isAdmin) {
      profiles++;
    }
    // è un manager
    if (this.canAccessManager()) {
      profiles++;
    }
    // è un docente
    if (this.canAccessTeacherMode && this.isTeacher) {
      profiles++;
    }
    return profiles > 1;
  }

  ngOnDestroy(): void {
    if (this.result$) {
      this.result$.unsubscribe();
    }
  }
}
