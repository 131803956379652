import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CreditWidgetIndicator } from 'src/cm2-commonclasses';

type CreditWidgetIndicatorId = CreditWidgetIndicator['id'];

@Component({
  selector: 'credit-widget',
  templateUrl: './credit-widget.component.html',
  styleUrls: ['./credit-widget.component.scss']
})
export class CreditWidgetComponent {

  @Input() indicator: CreditWidgetIndicator;

  @Output() onMainButtonClick = new EventEmitter<CreditWidgetIndicatorId>();
  @Output() onInfoClick = new EventEmitter<CreditWidgetIndicatorId>();

  constructor() { }

  // Emette l'evento al click sul pulsante principale
  emitOnMainButtonClick() {
    this.onMainButtonClick.emit(this.indicator.id);
  }

  // Emette l'evento al click sull'icona delle info
  emitOnInfoClick() {
    this.onInfoClick.emit(this.indicator.id);
  }


}
