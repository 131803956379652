/*
*  Lista di azioni riguardanti il profilo dell'utente loggato
*/

import { Action } from '@ngrx/store';
import { Lang, User } from 'atfcore-commonclasses';
import { UserCredits, BienniumData } from './profile.reducers';

export const UPDATE_USER = 'UPDATE_USER';
export const START_EDIT = 'START_EDIT';
export const STOP_EDIT = 'STOP_EDIT';
export const CANCEL_LOGGED_USER = 'CANCEL_LOGGED_USER';
export const SET_USER_CREDITS = 'SET_USER_CREDITS';
export const SET_BIENNIUM_DATA = 'SET_BIENNIUM_DATA';

// Decodifica il Token salvandolo nel Global Application Data
export const DECODE_TOKEN = 'DECODE_TOKEN';

// Salva le lingue disponibili, recuperate tramite servizi
export const SAVE_AVAILABLE_LANGS = 'SAVE_AVAILABLE_LANGS';

export class UpdateUser implements Action {
  readonly type = UPDATE_USER;

  constructor(public payload: { loggedUser: User }) { }
}

export class StartEdit implements Action {
  readonly type = START_EDIT;

  constructor(public payload: number) { }
}

export class SaveAvailableLangs implements Action {
  readonly type = SAVE_AVAILABLE_LANGS;

  constructor(public payload: Lang[]) { }
}

export class SetUserCredits implements Action {
  readonly type = SET_USER_CREDITS;

  constructor(public payload: UserCredits) { }
}

export class SetBienniumData implements Action {
  readonly type = SET_BIENNIUM_DATA;

  constructor(public payload: BienniumData) { }
}

export class CancelLoggedUser implements Action {
  readonly type = CANCEL_LOGGED_USER;
}

export class DecodeToken implements Action {
  readonly type = DECODE_TOKEN;

  // Payload dell'azione, che è il token
  constructor(public payload: string) { }
}

export class StopEdit implements Action {
  readonly type = STOP_EDIT;
}

export type ProfileActions = UpdateUser | StartEdit | StopEdit | DecodeToken | SetBienniumData | CancelLoggedUser | SaveAvailableLangs | SetUserCredits;