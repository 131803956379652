import { Injectable } from "@angular/core";
import { CommonConstants } from 'atfcore-commonclasses';

/*
*    Servizio che costruisce e tornag li URL delle varie applicazioni
*/
@Injectable()
export class UrlService {

  private development: boolean;
  private staging: boolean;
  private production: boolean;

  constructor() {
    this.checkHostName();
  }

  checkHostName() {
    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      this.development = true;
    } else if ((window.location.hostname.indexOf("atf") == 0 && firstDot > 0)) {
      this.development = true;
    } else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      this.staging = true;
    } else {
      this.production = true;
    }
  }

  // Ritorna l'URL dell'applicazione
  getApplicationUrl() {
    let applicationUrl: any = {
      url: null,
      baseUrl: null,
      protocol: '',
      domain: ''
    }

    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    }

    const isLocalhost = window.location.hostname == "localhost";
    if (isLocalhost) {
      // aggiornato per puntare a sviluppo di facile flumind
      // NON USARE PIU' applicationUrl.domain = "atfccfold.alloy.it";
      applicationUrl.domain = "atfccf.alloy.it";
      this.development = true;
    } else {
      let url = window.location.hostname.substring(0, firstDot);
      if (url) {
        url = url + ".";
      }
      applicationUrl.domain = url + secondLevelDomain;

      if (((window.location.hostname.indexOf("atf") == 0 && firstDot > 0))
        || ((window.location.hostname.indexOf("oswwwsrvdb1") == 0 && firstDot > 0))) {
        this.development = true;
      } else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
        this.staging = true;
      } else {
        this.production = true;
      }
    }

    // Calcolo il dominio di appartenenza per chiamare i servizi Rest
    applicationUrl.protocol = window.location.protocol === "http:" ? "http" : "https";

    // Forzo https nel caso di localhost per evitare problemi di CORS
    if (isLocalhost && secondLevelDomain === "localhost") {
      applicationUrl.protocol = "https";
    }

    applicationUrl.baseUrl = applicationUrl.protocol + "://" + applicationUrl.domain + "/";
    applicationUrl.url = applicationUrl.protocol + "://" + applicationUrl.domain;
    applicationUrl.domain = applicationUrl.domain;

    // //  Collegamento a TESTBDI Chiedere prima di usare
    // applicationUrl.baseUrl = "https://testbdi.flumind.com/";
    // applicationUrl.url = "https://testbdi.flumind.com";
    // applicationUrl.domain = "testbdi.flumind.com";

    return applicationUrl;
  }

  // Ritorna l'URL dell'applicazione
  getSSOUrl(deviceType: string, userAgent: string, urlExtension?: string) {
    let ssoUrl: string = null;
    let ssoReturnPage: string = null;
    // Calcolo il dominio di appartenenza
    const protocol = window.location.protocol === "http:" ? "http" : "https";

    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    const lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    /* if (window.location.hostname.indexOf("localhost") == 0) {
        domain = "localhosts.alloy.it";
    }
    else if ((window.location.hostname.indexOf("atf") == 0 && firstDot > 0)) {
        domain = "atftf.alloy.it";
    }
    else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
        domain = "testsso." + secondLevelDomain;
    }
    else {
        domain = "sso." + secondLevelDomain;
    } */
    ssoReturnPage = this.getApplicationUrl().url;

    if (urlExtension && urlExtension.length) {
      ssoReturnPage = ssoReturnPage + urlExtension;
    };

    if (window.location.hostname.indexOf('test') !== -1) {
      ssoUrl = protocol + "://staging.flumind.com/ssofacileit/login?originApplicationName=" + encodeURIComponent(CommonConstants.APPLICATION_CORPORATE_ACADEMY) + "&applicationUrl=" + encodeURIComponent(ssoReturnPage) + "&deviceType=" + encodeURIComponent(deviceType) + "&userAgent=" + encodeURIComponent(userAgent);
    } else {
      ssoUrl = protocol + "://performanceprod.alloy.it/ssofacileit/login?originApplicationName=" + encodeURIComponent(CommonConstants.APPLICATION_CORPORATE_ACADEMY) + "&applicationUrl=" + encodeURIComponent(ssoReturnPage) + "&deviceType=" + encodeURIComponent(deviceType) + "&userAgent=" + encodeURIComponent(userAgent);
    }

    return ssoUrl;
  }

  isDevelopment() {
    return this.development;
  }

  isStaging() {
    return this.staging;
  }

  isProduction() {
    return this.production;
  }

  // Metodo che ritorna l'URL per della GLP
  getGLPURL(addDefaultDestinationPage?: boolean) {
    let protocol = "";
    let secondLevelDomain = "";
    let thirdLevelDomain = "";
    let url: string = null;
    // Calcolo il dominio di appartenenza per chiamare i servizi Rest
    let port = window.location.protocol === "http:" ? 80 : 443;
    protocol = window.location.protocol === "http:" ? "http" : "https";
    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      thirdLevelDomain = "localhost.";
    } else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0) {
      thirdLevelDomain = "atf.";
    } else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      thirdLevelDomain = "test.";
    } else {
      thirdLevelDomain = "www.";
    }

    url = protocol + "://" + thirdLevelDomain + secondLevelDomain;

    /* Il routing di Angular inserisce in coda all'url il nome della pagina di default, mettendolo dopo anche eventuali parametri in Query string. Pertanto se, ad esempio, sto facendo un semplice redirect
     * alla Library appenendoci il Token dell'utente (es http://localhost.alloy.it?token=DFGH39ODS), Angular lo trasformerà in http://localhostl.alloy.it?token=DFGH39ODS/#/app/login. Per prevenire tale
     * comportamento è necessario quindi specificare una pagina precisa prima degli eventuali parametri in query string. L'URL definitivo sarà, ad esempio, http://localhostl.alloy.it/#/app/login?token=DFGH39ODS
    */
    if (addDefaultDestinationPage) {
      url += "/#/app/login";
    }
    return url;
  };

  // Metodo che ritorna l'URL per della Library
  getLibraryURL(addDefaultDestinationPage?: boolean) {
    let protocol = "";
    let secondLevelDomain = "";
    let thirdLevelDomain = "";
    let url: string = null;
    // Calcolo il dominio di appartenenza per chiamare i servizi Rest
    let port = window.location.protocol === "http:" ? 80 : 443;
    protocol = window.location.protocol === "http:" ? "http" : "https";
    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      thirdLevelDomain = "localhostl.";
    } else if (window.location.hostname.indexOf("atf") == 0 && firstDot) {
      thirdLevelDomain = "atfl.";
    } else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      thirdLevelDomain = "testlibraryonline.";
    } else { // prima era www.
      thirdLevelDomain = "libraryonline."
    }

    url = protocol + "://" + thirdLevelDomain + secondLevelDomain;

    /* Il routing di Angular inserisce in coda all'url il nome della pagina di default, mettendolo dopo anche eventuali parametri in Query string. Pertanto se, ad esempio, sto facendo un semplice redirect
     * alla Library appenendoci il Token dell'utente (es http://localhostl.alloy.it?token=DFGH39ODS), Angular lo trasformerà in http://localhostl.alloy.it?token=DFGH39ODS/#/app/login. Per prevenire tale
     * comportamento è necessario quindi specificare una pagina precisa prima degli eventuali parametri in query string. L'URL definitivo sarà, ad esempio, http://localhostl.alloy.it/#/app/login?token=DFGH39ODS
    */
    if (addDefaultDestinationPage) {
      url += "/#/app/login";
    }
    return url;
  };

  // Metodo che ritorna l'URL per della Library di Alleanza
  getLibraryAlleanzaURL(addDefaultDestinationPage?: boolean) {
    let protocol = "";
    let secondLevelDomain = "";
    let thirdLevelDomain = "";
    let url: string = null;
    // Calcolo il dominio di appartenenza per chiamare i servizi Rest
    let port = window.location.protocol === "http:" ? 80 : 443;
    protocol = window.location.protocol === "http:" ? "http" : "https";
    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      thirdLevelDomain = "localhostla.";
    } else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0) {
      thirdLevelDomain = "atfl.";
    } else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      thirdLevelDomain = "testalleanza.";
    } else { // prima era www.
      thirdLevelDomain = "alleanza."
    }

    url = protocol + "://" + thirdLevelDomain + secondLevelDomain;

    /* Il routing di Angular inserisce in coda all'url il nome della pagina di default, mettendolo dopo anche eventuali parametri in Query string. Pertanto se, ad esempio, sto facendo un semplice redirect
     * alla Library appenendoci il Token dell'utente (es http://localhostl.alloy.it?token=DFGH39ODS), Angular lo trasformerà in http://localhostl.alloy.it?token=DFGH39ODS/#/app/login. Per prevenire tale
     * comportamento è necessario quindi specificare una pagina precisa prima degli eventuali parametri in query string. L'URL definitivo sarà, ad esempio, http://localhostl.alloy.it/#/app/login?token=DFGH39ODS
    */
    if (addDefaultDestinationPage) {
      url += "/#/app/login";
    }
    return url;
  };

  // Metodo che ritorna l'URL per della Library di DAS
  getLibraryDasURL(addDefaultDestinationPage?: boolean, isLocalLogin?: boolean) {
    let protocol = "";
    let secondLevelDomain = "";
    let thirdLevelDomain = "";
    let url: string = null;
    // Calcolo il dominio di appartenenza per chiamare i servizi Rest
    let port = window.location.protocol === "http:" ? 80 : 443;
    protocol = window.location.protocol === "http:" ? "http" : "https";
    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      thirdLevelDomain = "localhostl.";
    } else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0) {
      thirdLevelDomain = "atfl.";
    } else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      thirdLevelDomain = "testlibrarydas.";
    } else { // prima era www.
      thirdLevelDomain = "librarydas."
    }

    url = protocol + "://" + thirdLevelDomain + secondLevelDomain;

    /* Il routing di Angular inserisce in coda all'url il nome della pagina di default, mettendolo dopo anche eventuali parametri in Query string. Pertanto se, ad esempio, sto facendo un semplice redirect
     * alla Library appenendoci il Token dell'utente (es http://localhostl.alloy.it?token=DFGH39ODS), Angular lo trasformerà in http://localhostl.alloy.it?token=DFGH39ODS/#/app/login. Per prevenire tale
     * comportamento è necessario quindi specificare una pagina precisa prima degli eventuali parametri in query string. L'URL definitivo sarà, ad esempio, http://localhostl.alloy.it/#/app/login?token=DFGH39ODS
    */
    if (addDefaultDestinationPage) {
      if (isLocalLogin) {
        url += "/#/app/localLogin";
      } else {
        url += "/#/app/login";
      }
    }
    return url;
  };

  // Proxa l'url di un'immagine
  getExternalResourceName = (requestUrl: string, requestName: string) => {
    return this.getApplicationUrl().baseUrl + 'rest-api/corporateacademy-mediator/proxy-url/' + encodeURIComponent(requestName) + '?requestUrl=' + encodeURIComponent(requestUrl);
  }

}
