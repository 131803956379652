<div fxLayout="column" class="small-widget-container">
    <div fxLayout="row" class="widget-content">
      <div fxLayout="column" class="icon-cards" fxLayoutAlign="start center">
        <div fxLayout="column">
          <img class="cursor-pointer" alt="image" src="assets/icons/icon-cards2.png">
        </div>
        <div fxLayout="column" class="margin-top35">
          <svg-icon class="cursor-pointer" (click)="emitOnInfoClick()" src="assets/icons/icon-info-circle-line.svg">
          </svg-icon>
        </div>
      </div>
      <div class="full-width" fxLayout="column" fxLayoutAlign="start start">
        <div fxLayout="column" class="title-wrapper">
          <!-- Titolo -->
          <p class="title">{{ indicator.title }}</p>
          <!-- Sottotitolo -->
          <p class="subtitle" *ngIf="indicator.subtitle">{{ indicator.subtitle }}</p>
        </div>
  
        <div fxFlex fxLayout.gt-xs="row" fxLayout.xs="column"
          [ngClass]="{ 'margin-top20' : indicator.subtitle, 'margin-top40' : !indicator.subtitle }"
          class="number-wrapper full-width">
  
          <!-- Indicatore Gauge -->
          <div *ngIf="indicator && indicator.showGauge" fxFlex fxLayout="column" fxLayoutAlign="start start"
            class="number-container">
            <dx-circular-gauge id="gaugeOnIndicator" [value]="indicator.gaugeValue">
              <dxo-size [height]="95"></dxo-size>
              <dxo-geometry [startAngle]="180" [endAngle]="0"> </dxo-geometry>
              <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="50">
              </dxo-scale>
              <dxo-range-container>
                <dxi-range [startValue]="0" [endValue]="50" color="#E4002B"></dxi-range>
                <dxi-range [startValue]="50" [endValue]="100" color="#25BB48"></dxi-range>
              </dxo-range-container>
            </dx-circular-gauge>
          </div>
  
          <ng-container *ngFor="let indicator of indicator.indicators">

              <div fxFlex fxLayout="column" fxLayoutAlign="start start"
                class="number-container first">
                <div class="indicator-number full-width" fxLayout="row" fxLayoutAlign="space-between center">
                  <p>
                    {{ indicator.indicatorNumber | numberAbbreviation:1 }}
                  </p>
                  <div *ngIf="indicator.numberIcon" fxLayout="row" fxLayoutAlign="center center">
                    <svg-icon [src]="indicator.numberIcon"></svg-icon>
                  </div>
                </div>
                <div *ngIf="indicator.numberHint" class="indicator-number-hint full-width" fxLayout="row">
                  <p>{{ indicator.numberHint }}</p>
                </div>
              </div>

          </ng-container>
          

        </div>
      </div>
    </div>
    <div fxLayout="row" class="widget-footer" fxLayoutAlign="end center">
      <p class="cursor-pointer" (click)="emitOnMainButtonClick()">{{ indicator.buttonText }}</p>
      <svg-icon src="assets/icons/icon-angle-right.svg"></svg-icon>
    </div>
  </div>