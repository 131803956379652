/*
* Componente principale dell'applicazione
*/

import { AfterContentChecked, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { JwtPayload, RequiredAuth, SenecaResponse, ManagerRoleTypes } from "../cm2-commonclasses";
import * as AuthActions from './auth/ngrx/auth.actions';
import * as CoreActions from './core/ngrx/core.actions';
import * as DashboardActions from './dashboard/ngrx/dashboard.actions';
import * as fromApp from './ngrx/app.reducers';
import * as ProfileActions from './profile/ngrx/profile.actions';
import { RedirectService } from './shared/services/redirect.service';
import { AuthService } from './auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AvailableCreditTypesService } from './core/services/available-credit-types.service';

export const OwlDateTranslations = {
  it: {
    upSecondLabel: 'Aggiungi un secondo',
    downSecondLabel: 'Un secondo in meno',
    upMinuteLabel: 'Aggiungi un minuto',
    downMinuteLabel: 'Un minuto in meno',
    upHourLabel: 'Aggiungi un\'ora',
    downHourLabel: 'Un\'ora in meno',
    prevMonthLabel: 'Mese precedente',
    nextMonthLabel: 'Prossimo mese',
    prevYearLabel: 'Anno precedente',
    nextYearLabel: 'Prossimo anno',
    prevMultiYearLabel: '21 anni precedenti',
    nextMultiYearLabel: 'Prossimi 21 anni',
    switchToMonthViewLabel: 'Cambia visuale',
    switchToMultiYearViewLabel: 'Scegli mese e anno',
    cancelBtnLabel: 'Annulla',
    setBtnLabel: 'Conferma',
    rangeFromLabel: 'Da',
    rangeToLabel: 'A',
    hour12AMLabel: 'AM',
    hour12PMLabel: 'PM'
  },
  en: {
    upSecondLabel: 'Add a second',
    downSecondLabel: 'One second less',
    upMinuteLabel: 'Add a minute',
    downMinuteLabel: 'One minute less',
    upHourLabel: 'Add an hour',
    downHourLabel: 'One hour less',
    prevMonthLabel: 'Previous month',
    nextMonthLabel: 'Next month',
    prevYearLabel: 'Previous year',
    nextYearLabel: 'Next year',
    prevMultiYearLabel: '21 previous years',
    nextMultiYearLabel: 'Next 21 years',
    switchToMonthViewLabel: 'Change View',
    switchToMultiYearViewLabel: 'Choose month and year',
    cancelBtnLabel: 'Cancel',
    setBtnLabel: 'Confirm',
    rangeFromLabel: 'From',
    rangeToLabel: 'A',
    hour12AMLabel: 'AM',
    hour12PMLabel: 'PM',
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChild('sidebarLeftNav') sidebarLeftNav;
  showApplicationLoader: boolean;
  isFetchingLangs$: Observable<boolean>;
  isAuthenticated: boolean;
  result$: Subscription;
  routerEvents: Subscription;
  isMainMenuSidebarOpened: boolean;
  isProfileMenuSidebarOpened: boolean;
  notificationList: any[] = [];
  avatar: string;
  loggedUser: JwtPayload;
  // E' necessario settare l'animazione delle sidenav di default a false, perché altrimenti a causa di problemi css del componente appena si carica l'applicazione per qualche millisecondo esse sono aperte, per poi chiudersi subito.
  // Per evitare questo effetto non piacevole alla vista, setto l'animazione di default a false, che diventerà a true dopo la prima apertura
  initialAnimateEnabled: boolean = false;
  isTeacher: boolean;
  isTaker: boolean;
  isAdmin: boolean;
  canAccessUserMode: boolean;
  canAccessAdminMode: boolean;
  canAccessTeacherMode: boolean;
  canAccessUserLibrary: boolean;
  canAccessManageLibrary: boolean;
  canManageAdminPreregWorkflow: boolean;
  canManageAdminInitiativesWorkflow: boolean;
  canAccessAdminCalendar: boolean;
  canAccessAdminNews: boolean;
  canAccessUserNews: boolean;
  canAccessUserCatalog: boolean;
  canAccessManageCatalog: boolean;
  canAccessUserCalendar: boolean;
  canAccessUserHome: boolean;
  canAccessUserTrainingBooklet: boolean;
  canAccessManageInitiatives: boolean;
  canAccessManageTags: boolean;
  canAccessManageScheduleModels: boolean;
  canAccessManageImportExport: boolean;
  canAccessManageCompetences: boolean;
  canAccessAdminDashboard: boolean;
  canAccessUserNotifications: boolean;
  canAccessUserProfile: boolean;
  canAccessManageGroups: boolean;
  canAccessManageLocations: boolean;
  canAccessManageTemplates: boolean;
  canAccessManageUsers: boolean;
  canAccessImpersonification: boolean;
  canAccessManageTeachersAccounting: boolean;
  canAccessAdminSuppliers: boolean;
  canAccessManagerMode: boolean;
  canAccessManagerApprovals: boolean;
  canAccessManagerDelegations: boolean;

  hasSubordinates: string;
  activeModule: string;
  canAccessManagerCalendar: boolean;
  canAccessManagerDashboard: boolean;

  isAdminCanManageInitiatives: boolean = false;

  managerWithSubordinates: boolean;
  managerWithSubordinatesIsDelegated: boolean;

  isImpersonificationBanner: boolean;

  constructor(private store: Store<fromApp.AppState>,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    public authService: AuthService,
    public redirect: RedirectService,
    private availableCreditTypesSvc: AvailableCreditTypesService) {
    // Recupero le informazioni sull'utente loggato dallo Store applicativo
    let loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    // Mostro il loader mentre sto caricando le lingue
    this.isFetchingLangs$ = this.store.select(fromApp.isFetchingLangs);
    // Mostro il loader se esplicitato nello Store
    let showApplicationLoader$ = this.store.select(fromApp.showApplicationLoader);
    // Recupero dallo Store applicativo la variabile che mi indica che la sidenav di sinistra contente il menu principale è aperta
    let isMainMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isMainMenuSidebarOpened);
    // Recupero dallo Store applicativo la variabile che mi indica che la sidenav di destra contente il menu utente è aperta
    let isProfileMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isProfileMenuSidebarOpened);
    // Quando il componente è stato inizializzato, recupero la parte relativa all'autorizzazione dallo state applicativo e l'oggetto globale "globalApplicationData"
    let isAuthenticated$ = this.store.select(fromApp.isAuthenticated);
    let isTaker$ = this.store.select(fromApp.isTaker);
    let isTeacher$ = this.store.select(fromApp.isTeacher);
    let isImpersonificationBanner$: Observable<boolean> = this.store.select(fromApp.getIsImpersonification);

    // Combito tutti gli observable
    const combinedSelectes$ = combineLatest(loggedUser$, isAuthenticated$, showApplicationLoader$, isMainMenuSidebarOpened$, isProfileMenuSidebarOpened$, isTaker$, isTeacher$, isImpersonificationBanner$);
    this.result$ = combinedSelectes$.subscribe(
      ([loggedUser, isAuthenticated, showApplicationLoader, isMainMenuSidebarOpened, isProfileMenuSidebarOpened, isTaker, isTeacher, isImpersonificationBanner]) => {
        this.loggedUser = loggedUser;
        this.isImpersonificationBanner = isImpersonificationBanner;
        this.isAuthenticated = isAuthenticated;
        this.showApplicationLoader = showApplicationLoader;
        this.isMainMenuSidebarOpened = isMainMenuSidebarOpened;
        if (this.isMainMenuSidebarOpened) {
          setTimeout(() => {
            this.sidebarLeftNav.nativeElement.focus();
          }, 500)
        }
        this.isProfileMenuSidebarOpened = isProfileMenuSidebarOpened;
        this.isAdmin = false;
        this.isTeacher = false;
        if (loggedUser) {
          this.isAdmin = loggedUser.auths && loggedUser.auths.length && this.authService.isUserAuthorized(RequiredAuth.CORPORATEACADEMY_IS_ADMIN, loggedUser.auths);

          this.isAdminCanManageInitiatives = loggedUser.auths && loggedUser.auths.length && this.authService.isUserAuthorized(RequiredAuth.CORPORATEACADEMY_MANAGE_COURSES, loggedUser.auths);

          this.isTeacher = loggedUser.auths && loggedUser.auths.length && this.authService.isUserAuthorized('CORPORATEACADEMY_SUPPLIER_PERSON', loggedUser.auths);

          if (loggedUser.user) {
            this.checkIfUserHasSubordinates();
          }
        }
        this.isTaker = isTaker || (sessionStorage.getItem('isTaker') && sessionStorage.getItem('isTaker') == "true");
        this.canAccessUserMode = environment.canAccessUserMode;
        this.canAccessAdminMode = environment.canAccessAdminMode;
        this.canAccessTeacherMode = environment.canAccessTeacherMode;
        this.canAccessUserLibrary = environment.canAccessUserLibrary;
        this.canAccessUserCalendar = environment.canAccessUserCalendar;
        this.canAccessAdminCalendar = environment.canAccessAdminCalendar;
        this.canAccessAdminNews = environment.canAccessAdminNews;
        this.canAccessUserNews = environment.canAccessUserNews;
        this.canAccessUserCatalog = environment.canAccessUserCatalog;
        this.canAccessManageCatalog = environment.canAccessManageCatalog;
        this.canAccessUserHome = environment.canAccessUserHome;
        this.canAccessUserTrainingBooklet = environment.canAccessUserTrainingBooklet;
        this.canAccessManageInitiatives = environment.canAccessManageInitiatives;
        this.canAccessManageTags = environment.canAccessManageTags;
        this.canAccessManageScheduleModels = environment.canAccessManageScheduleModels;
        this.canAccessManageImportExport = environment.canAccessManageImportExport;
        this.canAccessManageCompetences = environment.canAccessManageCompetences;
        this.canAccessAdminDashboard = environment.canAccessAdminDashboard;
        this.canAccessUserNotifications = environment.canAccessUserNotifications;
        this.canAccessManageLibrary = environment.canAccessManageLibrary;
        this.canManageAdminPreregWorkflow = environment.canManageAdminPreregWorkflow;
        this.canManageAdminInitiativesWorkflow = environment.canManageAdminInitiativesWorkflow;
        this.canAccessUserProfile = environment.canAccessUserProfile;
        this.canAccessManageGroups = environment.canAccessManageGroups;
        this.canAccessManageLocations = environment.canAccessManageLocations;
        this.canAccessManageTemplates = environment.canAccessManageTemplates;
        this.canAccessManageUsers = environment.canAccessManageUsers;
        this.canAccessImpersonification = environment.canAccessImpersonification;
        this.canAccessManageTeachersAccounting = environment.canAccessManageTeachersAccounting;
        this.canAccessAdminSuppliers = environment.canAccessAdminSuppliers;
        this.canAccessManagerMode = environment.canAccessManagerMode;
        this.canAccessManagerApprovals = environment.canAccessManagerApprovals;
        this.canAccessManagerDelegations = environment.canAccessManagerDelegations;
        this.canAccessManagerCalendar = environment.canAccessManagerCalendar;
        this.canAccessManagerDashboard = environment.canAccessManagerDashboard;

      });

    // Recupero l'avatar dell'utente loggato dal suo payload, con una foto di default qualora mancasse
    this.avatar = (this.loggedUser && this.loggedUser.user && this.loggedUser.user.userOptions && this.loggedUser.user.userOptions.avatarImage) || (this.loggedUser && this.loggedUser.user && (!this.loggedUser.user.chiaveSesso || this.loggedUser.user.chiaveSesso == "M") ? 'assets/img/placeholder_male.svg' : 'assets/img/placeholder_female.svg');
  }

  // Attiva l'animazione dell'apertura/chiusura delle sidenav
  activateAnimation(): void {
    if (!this.initialAnimateEnabled) {
      this.initialAnimateEnabled = true;
    }
  }

  // Esegue lo switch utente/admin
  switchAdminMode() {
    this.redirect.goToHome(this.authService.isAdminMode());
  }

  showSettingsTitle() {
    return this.authService.isAdminMode() && this.canAccessAdminMode &&
      (this.showGoToGroups() || this.showGoToLocations() || this.showGoToTemplates() || this.showGoToUsers() || this.showGoToSuppliers() || this.showGoToManagePreregWorkflow() || this.showGoToManageInitiativesWorkflow());
  }

  showGoToGroups() {
    return this.canAccessGroups() && this.canAccessAdminMode && this.canAccessManageGroups;
  }

  showGoToManageTeachersAccounting() {
    return this.canAccessManageSuppliersAccounting() && this.canAccessAdminMode && this.canAccessManageTeachersAccounting;
  }

  showGoToLocations() {
    return this.canManageLocations() && this.canAccessAdminMode && this.canAccessManageLocations;
  }

  showGoToTemplates() {
    return this.canManageTemplates() && this.canAccessAdminMode && this.canAccessManageTemplates;
  }

  showGoToImportExport() {
    return this.canAccessAdminMode && this.canManageImportExport() && this.canAccessManageImportExport;
  }

  showGoToFaq() {
    return environment.canAccessFaq && this.canManageFAQ();
  }

  showGoToUsers() {
    return this.canManageUsers() && this.canAccessManageUsers && this.authService.isAdminMode();
  }

  showGoToImpersonification() {
    return this.canImpersonificateAnyUser() && this.canAccessAdminMode && this.canAccessImpersonification;
  }

  showGoToSuppliers() {
    return this.canManageSuppliers() && this.canAccessAdminMode && this.canAccessAdminSuppliers;
  }

  showGoToTags() {
    return this.canAccessAdminMode && this.canManageTags() && this.canAccessManageTags;
  }

  showGoToScheduleModels() {
    return this.canAccessAdminMode && this.canManageScheduleModels() && this.canAccessManageScheduleModels;
  }

  showGoToCompetences() {
    return this.canAccessAdminMode && this.canManageCompetences() && this.canAccessManageCompetences;
  }

  showGoToCompetencesProfile() {
    return this.canAccessAdminMode && this.canManageCompetencesProfiles() && this.authService.isAdminMode();
  }

  showGoToNews() {
    return !this.authService.isTeacherMode() && ((!this.authService.isAdminMode() && this.canAccessUserMode && this.canAccessUserNews) || (this.authService.isAdminMode() && this.canAccessAdminMode && this.canAccessAdminNews && this.canManageNews()));
  }

  showGoToCatalog() {
    return !this.authService.isTeacherMode() && ((!this.authService.isAdminMode() && this.canAccessUserMode && this.canAccessUserCatalog));
  }

  showGoToManagePreregWorkflow() {
    return this.authService.isAdminMode() && this.canManagePreregWorkflow() && this.canAccessAdminMode && this.canManageAdminPreregWorkflow;
  }

  showGoToManageInitiativesWorkflow() {
    return this.authService.isAdminMode() && this.canManageInitiativesWorkflow() && this.canAccessAdminMode && this.canManageAdminInitiativesWorkflow;
  }

  // Verifica se l'utente può impersonificare qualcuno
  canImpersonificateAnyUser(): boolean {
    let canView: boolean = false;
    if (this.authService.isAdminMode() && this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.CORPORATEACADEMY_IMPERSONATE_ANY_USER) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  canManageSuppliers() {
    let canView: boolean = false;
    if (this.authService.isAdminMode() && this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.CORPORATEACADEMY_MANAGE_SUPPLIERS) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  canManageNews() {
    let canView: boolean = false;
    if (this.authService.isAdminMode() && this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];

        if (currentAuth === RequiredAuth.CORPORATEACADEMY_MANAGE_ANNOUNCEMENT) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  canChangeRole() {
    return this.isTeacher && this.canAccessUserMode && this.canAccessAdminMode;
  }

  showPagesTitle() {
    return (this.canAccessUserMode || this.canAccessAdminMode) &&
      (this.showGoToHome() || this.showGoToLibrary() || this.showGoToCalendar() || this.showGoToDashboard() || this.showGoToNotifications() || this.showGoToLibraryItems() || this.showGoToProfile() || this.showGoToAllInitiatives());
  }

  showGoToHome() {
    return (!this.authService.isAdminMode() && this.canAccessUserMode && this.canAccessUserHome) || (this.authService.isAdminMode() && this.canAccessAdminMode && this.canAccessManageInitiatives) || (this.authService.isTeacherMode() && this.canAccessManageTeachersAccounting);
  }

  showgoToTrainingBookletHome() {
    return !this.authService.isAdminMode() && !this.authService.isTeacherMode() && this.canAccessUserMode && this.canAccessUserTrainingBooklet;
  }

  showGoToLibrary() {
    return !this.authService.isAdminMode() && !this.authService.isTeacherMode() && this.canAccessUserMode && this.canAccessUserLibrary;
  }

  showGoToCalendar() {
    return ((!this.authService.isAdminMode() && !this.authService.isTeacherMode() && this.canAccessUserMode && this.canAccessUserCalendar) || (this.authService.isAdminMode() && this.canAccessAdminMode && this.canAccessAdminCalendar && this.canManageCourses()));
  }

  showGoToDashboard() {
    return this.canAccessDashboard() && this.authService.isAdminMode() && this.canAccessAdminDashboard;
  }

  showGoToNotifications() {
    return !this.authService.isAdminMode() && !this.authService.isTeacherMode() && this.canAccessUserMode && this.canAccessUserNotifications;
  }

  showGoToLibraryItems() {
    return this.authService.isAdminMode() && this.canAccessManageItem() && this.canAccessAdminMode && this.canAccessManageLibrary;
  }

  showGoToProfile() {
    return (!this.authService.isAdminMode() && this.canAccessUserMode && this.canAccessUserProfile) || (this.authService.isTeacherMode() && this.canAccessManageTeachersAccounting);
  }

  showUserFaq() {
    return !this.authService.isAdminMode() && !this.authService.isTeacherMode() && environment.canAccessFaq;
  }

  showGoToAllInitiatives() {
    return this.canManageCourses() && this.canAccessAdminMode && this.canAccessManageInitiatives;
  }

  // Verifica se l'utente può gestire i corsi
  canManageCourses(): boolean {
    let canView: boolean = false;
    if (this.authService.isAdminMode() && this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.CORPORATEACADEMY_MANAGE_COURSES) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  // Verifica se l'utente può gestire i template
  canManageTemplates(): boolean {
    let canView: boolean = false;
    if (this.authService.isAdminMode() && this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.CORPORATEACADEMY_MANAGE_TEMPLATE) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  // Verifica se l'utente può gestire gli utenti
  canManageUsers(): boolean {
    let canView: boolean = false;
    if (this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.CORPORATEACADEMY_MANAGE_USER_PROFILES || currentAuth === RequiredAuth.CORPORATEACADEMY_MODIFY_EVERY_USER_INFO) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  // verifica se l'utente può gestire le FAQ
  canManageFAQ(): boolean {
    let canView: boolean = false;
    if ((this.authService.isAdminMode()) && this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.CORPORATEACADEMY_MANAGE_FAQ) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  // verifica se l'utente può gestire i luoghi
  canManageLocations() {
    let canView: boolean = false;
    if ((this.authService.isAdminMode()) && this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.CORPORATEACADEMY_MANAGE_PLACES) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  // verifica se l'utente può gestire la sezione di import/export
  canManageImportExport(): boolean {
    let canView: boolean = false;
    if ((this.authService.isAdminMode()) && this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.CORPORATEACADEMY_MANAGE_IMPORT_EXPORT) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  // verifica se l'utente può gestire la sezione di dati di dominio
  canManageTags(): boolean {
    let canView: boolean = false;
    if ((this.authService.isAdminMode()) && this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.CORPORATEACADEMY_MANAGE_TAGS) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  // verifica se l'utente può gestire la sezione delle competenze
  canManageCompetences(): boolean {
    let canView: boolean = false;
    if ((this.authService.isAdminMode()) && this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.CORPORATEACADEMY_ADMIN_EDIT_COMPETENCE_PROFILES) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  canManageScheduleModels(): boolean {
    let canView: boolean = false;
    if ((this.authService.isAdminMode()) && this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.CORPORATEACADEMY_MANAGE_SCHEDULE_MODELS) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  // Verifica se l'utente loggato può accedere alla gestione dei gruppi
  canAccessGroups(): boolean {
    let canView: boolean = false;
    if (this.authService.isAdminMode() && this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.CORPORATEACADEMY_MANAGE_GROUPS) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  // verifica se l'utente loggato può accedere alla gestione di profili e competenze
  canManageCompetencesProfiles() {
    let canView: boolean = false;
    if (this.authService.isAdminMode() && this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.CORPORATEACADEMY_ADMIN_EDIT_COMPETENCE_PROFILES) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  // Verifica se l'utente loggato può accedere alla gestione della contabilità
  canAccessManageSuppliersAccounting(): boolean {
    let canView: boolean = false;
    if (this.authService.isAdminMode() && this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.CORPORATEACADEMY_MANAGE_SUPPLIERS_ACCOUNTING) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  // Verifica se l'utente loggato può accedere alla gestione della dashboard
  canAccessDashboard(): boolean {
    let canView: boolean = false;
    if (this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.CORPORATEACADEMY_DASHBOARD) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  // Verifica se l'utente loggato può accedere all'approvazione lato admin
  canManagePreregWorkflow(): boolean {
    let canView: boolean = false;
    if (this.authService.isAdminMode() && this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.CORPORATEACADEMY_MANAGE_PREREG_WORKFLOW) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  canManageInitiativesWorkflow(): boolean {
    let canView: boolean = false;
    if (this.authService.isAdminMode() && this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.CORPORATEACADEMY_MANAGE_EDITION_APPROVAL_WORKFLOW) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  // Verifica se l'utente loggato può accedere alla gestione degli item
  canAccessManageItem() {
    return this.authService.canAccessManageItem(this.loggedUser);
  }

  // Verifica in quale pagina sono
  isThisCurrentPage(page: string) {
    if (page == 'competences')
      if (page && this.router && this.router.url && this.router.url.indexOf(page) !== -1) {
        return true;
      }

    return false;
  }

  // La sidenav si può chiudere anche cliccando sul backdrop. In tal caso, eseguo il dispatch dell'azione che setta come chiusa la sidenav (col menu principale) nello Store
  onMainMenuSidenavClose(): void {
    this.store.dispatch(new CoreActions.CloseMainMenuSidebar());
  }

  // La sidenav si può chiudere anche cliccando sul backdrop. In tal caso, eseguo il dispatch dell'azione che setta come chiusa la sidenav (col menu principale) nello Store
  onProfileMenuSidenavClose(): void {
    this.store.dispatch(new CoreActions.CloseProfileMenuSidebar());
  }

  // Quando il componente è distrutto, eseguo anche l'unsubscribe del cambio di route
  ngOnDestroy() {
    if (this.routerEvents) {
      this.routerEvents.unsubscribe();
    }
    if (this.result$) {
      this.result$.unsubscribe();
    }
  }

  removeSessionStorageLibrarySearches() {
    const filters = sessionStorage.getItem("SEARCHES");
    if (filters) {
      sessionStorage.removeItem("SEARCHES");
    }
  }

  removeSessionStorageCatalogSearches() {
    const filters = sessionStorage.getItem("CATALOG_SEARCHES");
    if (filters) {
      sessionStorage.removeItem("CATALOG_SEARCHES");
    }
  }

  // Logout dall'applicazione
  onLogout() {
    const nameId = this.loggedUser && this.loggedUser.params && this.loggedUser.params.SAML_NAME_ID;
    const sessionIndex = this.loggedUser && this.loggedUser.params && this.loggedUser.params.SAML_SESSION_INDEX;

    const teacherMode = this.authService.isTeacherMode();
    let isInternalBDI;
    if (this.loggedUser?.user.socDistacco == "Banca d'Italia") {
      isInternalBDI = true;
    }
    this.store.dispatch(new ProfileActions.CancelLoggedUser());
    // Al click sul logout, eseguo il dispatch dell'action che disautentica l'utente, cancella il token (sia dal session storage che dallo State), e cancella il Payload (dato dalla decodifica del Token) dentro lo State
    this.store.dispatch(AuthActions.Logout());
    this.store.dispatch(new CoreActions.RemoveApplicationLang());
    this.store.dispatch(new CoreActions.RemoveRedirectUrl());
    this.store.dispatch(new DashboardActions.DeleteIndicators());

    this.removeSessionStorageLibrarySearches();
    this.removeSessionStorageCatalogSearches();

    let sessionStorageFilters = sessionStorage.getItem('dashboardFilters');
    if (sessionStorageFilters) {
      sessionStorage.removeItem('dashboardFilters');
    }
    if (this.isMainMenuSidebarOpened) {
      this.redirect.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.redirect.closeSidenavProfileMenu();
    }

    // Redirect alla pagina di login
    if (isInternalBDI) {
      if (nameId && sessionIndex) {
        window.location.href = "https://performanceprod.alloy.it/ssofacileit/logout" + "?SAML_NAME_ID=" + encodeURIComponent(nameId) + "&SAML_SESSION_INDEX=" + encodeURIComponent(sessionIndex);
      } else {
        window.location.href = "https://performanceprod.alloy.it/ssofacileit/logout";
      }
    } else if (teacherMode) {
      this.router.navigate(['/teacherLogin']);
    } else {
      this.router.navigate(['/localLogin']);
    }
  }

  // Controlla se l'utente loggato ha dei subordinati, mostrando quindi la sezione manager (NB deve essere abilitata anche dall'environment)
  checkIfUserHasSubordinates() {
    if (this.loggedUser && this.loggedUser.user) {
      this.authService.checkIfUserHasSubordinates()
        .subscribe((data: SenecaResponse<string>) => {
          if (data.error) {
            this.toastr.error(this.translate.instant('errors.' + data.error));
          } else {
            this.hasSubordinates = data.response;
            if (this.hasSubordinates && this.hasSubordinates.length) {
              for (let i = 0; i < this.hasSubordinates.length; i++) {
                if (this.hasSubordinates[i] == ManagerRoleTypes.DIRECT || this.hasSubordinates[i] == ManagerRoleTypes.STRUCTURE) {
                  this.managerWithSubordinates = true;
                } else if (this.hasSubordinates[i] == ManagerRoleTypes.DELEGATED) {
                  this.managerWithSubordinatesIsDelegated = true;
                }
              }
            }
          }
        })
    }
  }

  // Esegue lo switch profilo
  switchProfileMode(moduleName: string): void {
    this.activeModule == moduleName;
    if (moduleName == 'admin') {
      this.redirect.goToHome(false);
    } else if (moduleName == 'manager') {
      this.redirect.goToHome(false, false, true);
    } else if (moduleName == 'teacher') {
      this.redirect.goToHome(false, true);
    } else if (moduleName == 'takers') {
      this.redirect.goToHome(true);
    }
  }

  isManager() {
    return this.canAccessManagerMode && (this.managerWithSubordinates || this.managerWithSubordinatesIsDelegated);
  }

  // check permessi redirect pagine manager
  // Home e pagina delle approvazioni pre iscrizione

  showGoToApprovals() {
    return this.isManager() && this.canAccessManagerApprovals;
  }

  // check permessi redirect pagine manager
  showGoToDelegations() {
    return this.isManager() && this.canAccessManagerDelegations;
  }

  showGoToManagerCalendar() {
    return this.isManager() && this.canAccessManagerCalendar && this.managerWithSubordinates;
  }

  showGoToManagerDashboard() {
    return this.isManager() && this.canAccessManagerDashboard && this.managerWithSubordinates;
  }

  ngAfterContentChecked() {
    this.activeModule = this.redirect.getModuleName();
  }

  goToReservedArea() {
    window.open("https://ac.utenze.bankit.it/sites/PGR/formazione/areeriservate/SitePages/Home.aspx");
  }

  // Quando il componente si è inizializzato ed è pronto, devo settare i parametri applicativi come il base URL dell'applicazione. Di questo se ne occuperà il side effect una volta lanciata l'action per recuperare le lingue disponibili a sistema.
  ngOnInit() {
    // Recupero le lingue disponibili
    this.store.dispatch(new CoreActions.GetAvailableLangs());
    // Recupero i tag utilizzabili 
    this.availableCreditTypesSvc.getOnlyUsableTags().subscribe((data: any) => {
      environment.usableTags = data;
    })

    this.availableCreditTypesSvc.getAvailableCreditTypes().subscribe(availableCreditTypes => {
      this.store.dispatch(new CoreActions.SetAvailableCreditTypes(availableCreditTypes));
    })

  }

}