/*
 * Gestione dei dispatch che devono eseguire qualcosa di asincrono e che non alterano lo State (i così chiamati Effect). Struttura molto simile ai Reducer,
 * che verificano il tipo di azione e concatenano una serie di operatori degli Observable per fare qualcosa. L'unica differenza, appunto, è che qui non 
 * andiamo a modificare lo State, gestiamo solo lo Side Effect
*/

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { map, switchMap, withLatestFrom, takeWhile } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromCore from './core.reducers';
import * as fromApp from '../../ngrx/app.reducers';
import { UrlService } from '../../shared/services/url.service';
import { AppUrl } from '../../shared/models/url.model';
import * as CoreActions from './core.actions';
import * as ProfileActions from '../../profile/ngrx/profile.actions';
import { LangsService } from '../services/langs.service';
import { ToastrService } from 'ngx-toastr';
import { Lang, SenecaResponse } from "../../../cm2-commonclasses";
import { GlobalApplicationData } from '../../shared/models/global-application-data.model';
import { TranslateService } from '@ngx-translate/core';
import { timer, from } from 'rxjs';
import * as AuthActions from '../../auth/ngrx/auth.actions';
import { AuthService } from '../../auth/services/auth.service';
import * as NewsActions from '../../news/ngrx/news.actions';
import { NewsService } from '../../news/services/news.service';
import { environment } from 'src/environments/environment';

// Injectable perché abbiamo bisogno di importare le action e altri servizi
@Injectable()
export class CoreEffects {
  // actions$ col dollaro per marcare il fatto che è un Observable
  constructor(private actions$: Actions,
    private authService: AuthService,
    private langsService: LangsService,
    private toastr: ToastrService,
    private urlService: UrlService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<fromCore.CoreState>,
    private newsService: NewsService,) {
  }

  // Url dell'applicazione
  applicationUrl: AppUrl;
  // Di default, inserisco la lingua del browser dell'utente, recupera grazie ad una funzione 
  defaultLang: string = this.langsService.getBrowserLang();
  // Verifica se l'utente è autenticato
  isAuthenticated: boolean;
  // Tiny token
  tinyToken: string;
  // Token intero
  tokenObj;
  redirectUrl?: string;
  forceRefreshUser?: boolean;

  // Effects che crea un observable (timer) che ogni ora recupera un nuovo token ed esegue il dispatch delle action che lo aggiornano anche nello store
  @Effect()
  startRenewTokenPolling$ = this.actions$
    .pipe(
      ofType(CoreActions.START_RENEW_TOKEN_POLLING)
      // Ho bisogno del payload dell'action, e lo estraggo con il map()
      , map((action: CoreActions.StartRenewTokenPolling) => {
        this.redirectUrl = action && action.payload && action.payload.redirectUrl || '';
        this.forceRefreshUser = action && action.payload && action.payload.forceRefreshUser || false;
        // Grazie al map, il payload sarà wrappato in un nuovo Observable, così da poter far in seguito la concatenazione di altri operatori
        return action.payload;
      })
      , switchMap(
        (actionPayload) => timer(0, 3600000) // Il primo parametro è il delay iniziale. Nel nostro caso voglio che venga fatto subito, poiché potrebbe essere che l'utente sia già autenticato ma che abbia eseguito un refresh della pagina
          .pipe(
            withLatestFrom(this.store.select(fromApp.isAuthenticated)) // combina l'azione con il valore di un altro observable, mi serve per recuperare la parte "core" dello Store per vedere se l'utente è loggato
            , map(([action, isAuthenticated]) => {
              this.isAuthenticated = isAuthenticated;
            })
            , takeWhile(() => this.isAuthenticated), // Continuo con il loop del timer solamente finché l'utente è autenticato. Qualora eseguisse il logout, l'observable si distruggerebbe
            switchMap(() => {
              // L'effect, alla fine, si aspetta sempre di tornare un Observable; per questo convertiamo la Promise in un Observable grazie al metodo from()
              let sessionStorageToken: string = sessionStorage.getItem('token');
              if (sessionStorageToken) {
                sessionStorage.removeItem('token');
                return from(this.authService.renewToken(sessionStorageToken, this.forceRefreshUser));
              } else {
                throw (new Error('TOKEN_NOT_FOUND'));
              }
            })
            , switchMap(
              (tinyTokenObj: SenecaResponse<string>) => {
                if (tinyTokenObj.error) {
                  throw (new Error(tinyTokenObj.error));
                } else {
                  // Salvo il tiny token. Salvo questo perché è quello che mi servirà nelle chiamate rest, e che utilizzerà quindi l'interceptor
                  this.tinyToken = tinyTokenObj.response;
                  this.store.dispatch(AuthActions.SetToken({ payload: this.tinyToken }));
                  // Se non ho ricevuto nessun errore nella risposta, procedo con il convertire il tiny token
                  return from(this.authService.getJWTToken());
                }
              })
            , map((tokenObj: SenecaResponse<string>) => {
              if (tokenObj.error) {
                // Catturo l'errore del servizio del renew del token
                throw (new Error(tokenObj.error));
              } else {
                this.tokenObj = tokenObj.response;
                return this.store.dispatch(new ProfileActions.DecodeToken(this.tokenObj));
              }
            })
            , withLatestFrom(this.store.select(fromApp.getLoggedUser))
            , switchMap(([action, loggedUser]) => {
              // Controllo nel token se fa parte dei gruppi automatici con l'accesso a coursera
              let courseraGroups = ["BZ", "IT", "MKT", "STAFF", "PRODUCT"];
              if (loggedUser?.user?.chief && courseraGroups.includes(loggedUser.user.chief)) {
                environment.canAccessCoursera = true;
              }
              if (this.redirectUrl) {
                this.router.navigate([this.redirectUrl]);
              } else {
                // Se non ce l'ho nemmeno nel session storage, allora lo setto per evitare il redirect automatico in home page
                let sessionStorageRedirectUrl: string = sessionStorage.getItem('redirectUrl');
                if (!sessionStorageRedirectUrl) {
                  let url = this.router.url;
                  if (url) {
                    sessionStorage.setItem('redirectUrl', url);
                  }
                }
              }

              // Se ho settato l'utente loggato (e quindi decodificato il token) posso settare la lingua di sistema con quella scelta dall'utente
              let langToUse = this.langsService.getUserLang(loggedUser.user);
              // Prima di salvare la lingua dello store applicativo, la imposto anche per il componente che si occupa delle traduzioni
              this.langsService.useLanguage(langToUse);
              let actionsContainer = [{
                type: CoreActions.SET_APPLICATION_LANG,
                payload: langToUse
              }];
              this.redirectUrl = null;
              return actionsContainer;
            })
          )
      )
      , catchError((err, caught) => {
        // L'errore è una condizione terminale che porrebbe fine all'Observable. Questo interromperebbe il flusso dell'Effect. In lato pratico, significa che se capita un errore, lo stream si interrompe e 
        // se cercassi di fare il dispatch dell'azione con l'effect, quest'ultimo non si avvia. Quindi l'utente continua a premere, per esempio, un pulsante e questo non trigghera nessun Effect.
        // Fortunatamente, il catchError() consente di emettere valori personalizzati invece di incrementare l'observer con la callback di errore.
        // La cattura non viene fatta nello stream principale, ma nel flusso interno (quello dello switchMap), quindi l'observable interrotto è quello interno. Quello principale, invece, continua
        // con il valore tornato dal catch.
        // In alternativa, si potrebbe gestire l'errore direttamente all'interno dello switchMap:
        /*
        switchMap(campaign => 
            this.advertService.startAd(campaign.name).pipe(
                map(nameOfUploadedFile => new AdvertActions.StartAdDone(nameOfUploadedFile)),
                catchError(err => of(new AdvertActions.StartAdFailed(err))
        ) */
        this.translate.setDefaultLang(this.defaultLang);
        if (err && err.message) {
          // TODO-Alloy: da capire perché non traduce in lingua qua!
          if (err.message == "OLD_TOKEN_NOT_FOUND") {
            this.toastr.error("Sessione scaduta");
          } else {
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
        }
        // Quindi, alla fine, torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
        return caught;
      })
    )

  // Effects che crea un observable (timer) che recupera il counter delle notifiche
  @Effect()
  startCountNotificationsPolling$ = this.actions$
    .pipe(
      ofType(CoreActions.START_COUNT_NOTIFICATIONS)
      , switchMap(
        () => timer(0, 30000) // Il primo parametro è il delay iniziale. Nel nostro caso voglio che venga fatto subito, poiché potrebbe essere che l'utente sia già autenticato ma che abbia eseguito un refresh della pagina
          .pipe(
            withLatestFrom(this.store.select(fromApp.isAuthenticated)) // combina l'azione con il valore di un altro observable, mi serve per recuperare la parte "core" dello Store per vedere se l'utente è loggato
            , map(([action, isAuthenticated]) => {
              this.isAuthenticated = isAuthenticated;
            })
            , takeWhile(() => this.isAuthenticated), // Continuo con il loop del timer solamente finché l'utente è autenticato. Qualora eseguisse il logout, l'observable si distruggerebbe
            switchMap(() => {
              return from(this.authService.countNotifications(null, true));
            })
            , switchMap(
              (notificationsCounter: SenecaResponse<number>) => {
                if (notificationsCounter.error) {
                  throw (new Error(notificationsCounter.error));
                } else {
                  let actionsContainer = [{
                    type: CoreActions.SET_NOTIFICATIONS_COUNTER,
                    payload: notificationsCounter.response
                  }];
                  return actionsContainer;
                }
              })
          )
      )
      , catchError((err, caught) => {
        // L'errore è una condizione terminale che porrebbe fine all'Observable. Questo interromperebbe il flusso dell'Effect. In lato pratico, significa che se capita un errore, lo stream si interrompe e 
        // se cercassi di fare il dispatch dell'azione con l'effect, quest'ultimo non si avvia. Quindi l'utente continua a premere, per esempio, un pulsante e questo non trigghera nessun Effect.
        // Fortunatamente, il catchError() consente di emettere valori personalizzati invece di incrementare l'observer con la callback di errore.
        // La cattura non viene fatta nello stream principale, ma nel flusso interno (quello dello switchMap), quindi l'observable interrotto è quello interno. Quello principale, invece, continua
        // con il valore tornato dal catch.
        // In alternativa, si potrebbe gestire l'errore direttamente all'interno dello switchMap:
        /*
        switchMap(campaign => 
            this.advertService.startAd(campaign.name).pipe(
                map(nameOfUploadedFile => new AdvertActions.StartAdDone(nameOfUploadedFile)),
                catchError(err => of(new AdvertActions.StartAdFailed(err))
        ) */
        this.translate.setDefaultLang(this.defaultLang);
        if (err && err.message) {
          // TODO-Alloy: da capire perché non traduce in lingua qua!
          if (err.message == "OLD_TOKEN_NOT_FOUND") {
            this.toastr.error("Sessione scaduta");
          } else {
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
        }
        // Quindi, alla fine, torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
        return caught;
      })
    )

  // Effects che crea un observable (timer) che recupera il counter delle news
  @Effect()
  startCountNotificationsNewsPolling$ = this.actions$
    .pipe(
      ofType(NewsActions.START_COUNT_NOTIFICATIONS_NEWS)
      , switchMap(
        () => timer(0, 30000) // Il primo parametro è il delay iniziale. Nel nostro caso voglio che venga fatto subito, poiché potrebbe essere che l'utente sia già autenticato ma che abbia eseguito un refresh della pagina
          .pipe(
            withLatestFrom(this.store.select(fromApp.isAuthenticated)) // combina l'azione con il valore di un altro observable, mi serve per recuperare la parte "core" dello Store per vedere se l'utente è loggato
            , map(([action, isAuthenticated]) => {
              this.isAuthenticated = isAuthenticated;
            })
            , takeWhile(() => this.isAuthenticated), // Continuo con il loop del timer solamente finché l'utente è autenticato. Qualora eseguisse il logout, l'observable si distruggerebbe
            switchMap(() => {
              return from(this.newsService.unreadNews(true));
            })
            , switchMap(
              (notificationsCounter: SenecaResponse<any>) => {
                if (notificationsCounter.error) {
                  throw (new Error(notificationsCounter.error));
                } else {
                  let actionsContainer = [{
                    type: NewsActions.SET_BANNER_UNREAD,
                    payload: notificationsCounter.response.length
                  }];
                  return actionsContainer;
                }
              })
          )
      )
      , catchError((err, caught) => {
        if (err && err.message) {
          // TODO-Alloy: da capire perché non traduce in lingua qua!
          if (err.message == "OLD_TOKEN_NOT_FOUND") {
            this.toastr.error("Sessione scaduta");
          } else {
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
        }
        // Quindi, alla fine, torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
        return caught;
      })
    )

  @Effect()
  // Proprietà dell'Effect di cui NgRX starà in watch eseguendo il codice che gli assegnamo sulla destra.
  // Quindi, per prima cosa, si accede alle action dello Store applicativo (che abbiamo iniettato nel costruttore)
  coreActions$ = this.actions$
    .pipe(
      ofType(CoreActions.GET_AVAILABLE_LANGS)
      // In questo caso non c'è nessun payload
      , withLatestFrom(this.store.select(fromApp.getAvailableLangs)) // combina l'azione con il valore di un altro observable, mi serve per recuperare la parte "core" dello state per vedere se devo eseguire un redirect dopo il login
      , switchMap(([action, storeLangs]) => {
        // Se ho già le lingue, eseguo il dispatch dell'azione che indica la fine del caricamento delle stesse
        if (storeLangs && storeLangs.length) {
          this.store.dispatch(new CoreActions.GetAvailableLangsFinished());
        } else {
          // Lingue non disponibili nello Store applicativo, quindi chiamo il servizio per recuperarle
          // L'effect, alla fine, si aspetta sempre di tornare un Observable; per questo convertiamo la Promise in un Observable grazie al metodo from()
          return this.langsService.getAvailableLangs();
        }
      })
      , map(
        (senecaResponse: SenecaResponse<Lang[]>) => {
          if (senecaResponse.response) {
            // Qualora abbia ricevuto le lingue in risposta significa che non le ho ancora salvate nello Store applicativo, quindi rimedio.
            // Prima del dispatch dell'action prendo la prima lingua disponibile, che sarà quella utilizzata come fallback qualora le traduzioni non siano presenti o non disponibili
            for (let i = 0, langsLength = senecaResponse.response.length; i < langsLength; i++) {
              if (senecaResponse.response[i] && senecaResponse.response[i].mandatory && senecaResponse.response[i].langCode) {
                this.defaultLang = senecaResponse.response[i].langCode.substring(0, 2);
                break;
              }
            }

            return this.store.dispatch(new ProfileActions.SaveAvailableLangs(senecaResponse.response));
          }
        }
      )
      , withLatestFrom(this.store.select(fromApp.getGlobalApplicationData)) // recupero dello Store l'oggetto principale GlobalApplicationData
      // , take(1) DA NON USARE NELL'EFFECT! Essendo quest'ultimo un singleton, una volta fatto l'unsubscribe tramite il take(1), non farà più il subscribe. Pertanto, se si provasse a fare il dispatch intercettato da questo Effect, non produrrebbe più, appunto, nessun effect e non entrerebbe nel metodo
      , switchMap(([action, savedGlobalApplicationData]) => {
        // Di default, utilizzo la lingua italiana. Questo comunque è un linguaggio di fallback qualora mancasse e non fosse trovata la lingua settata
        this.translate.setDefaultLang(this.defaultLang);
        // Pertanto, setto la lingua. In ogni caso, quando l'utente segue il login, recupero la sua lingua, settandola come default all'applicazione
        return this.translate.use(this.defaultLang).pipe(
          map(() => savedGlobalApplicationData)
        );
      }),
      switchMap((savedGlobalApplicationData) => {
        // Qualora non avessi il globalApplicationData, lo costruisco e lo salvo nello Store applicativo
        if (!savedGlobalApplicationData) {
          // Recupero l'url dell'applicazione, in quanto uno dei parametri dell'oggetto
          this.applicationUrl = this.urlService.getApplicationUrl();

          // Ora che ho l'url posso passare alla valorizzazione del GlobalApplicationData
          let newGlobalApplicationData = new GlobalApplicationData(
            this.applicationUrl.baseUrl,
            '../index.html',
            '../isMaintenance.xml',
            'eTicketing-user/?#/app/eTicketUserApp/eTicketing',
            null,
            false,
            false,
            [],
            [],
            false,
            null,
            null
          );

          // Torno due azioni, una per salvare nello State applicativo il GlobalApplicationData e una per annunciare la fine del recupero delle lingue
          return [{
            type: CoreActions.SET_CORE_APPLICATION_DATA,
            payload: newGlobalApplicationData
          }, {
            type: CoreActions.GET_AVAILABLE_LANGS_FINISHED
          }, {
            type: CoreActions.SET_DEFAULT_LANG,
            payload: this.defaultLang
          }
          ]
        } else {
          return [{
            type: CoreActions.GET_AVAILABLE_LANGS_FINISHED
          }];
        }
      })
      , catchError((err, caught) => {
        // L'errore è una condizione terminale che porrebbe fine all'Observable. Questo interromperebbe il flusso dell'Effect. In lato pratico, significa che se capita un errore, lo stream si interrompe e 
        // se cercassi di fare il dispatch dell'azione con l'effect, quest'ultimo non si avvia. Quindi l'utente continua a premere, per esempio, un pulsante e questo non trigghera nessun Effect.
        // Fortunatamente, il catchError() consente di emettere valori personalizzati invece di incrementare l'observer con la callback di errore.
        // La cattura non viene fatta nello stream principale, ma nel flusso interno (quello dello switchMap), quindi l'observable interrotto è quello interno. Quello principale, invece, continua
        // con il valore tornato dal catch.
        // In alternativa, si potrebbe gestire l'errore direttamente all'interno dello switchMap:
        /*
        switchMap(campaign => 
            this.advertService.startAd(campaign.name).pipe(
                map(nameOfUploadedFile => new AdvertActions.StartAdDone(nameOfUploadedFile)),
                catchError(err => of(new AdvertActions.StartAdFailed(err))
        ) */
        // Di default, utilizzo la lingua del browser dell'utente. Questo comunque è un linguaggio di fallback qualora mancasse e non fosse trovata la lingua settata, che è il nostro caso visto che
        // siamo nel catch dell'errore delle lingue
        this.translate.setDefaultLang(this.defaultLang);
        if (err && err.message) {
          this.toastr.error(this.translate.instant('errors.' + err.message));
        }
        // Segnalo che il servizio delle lingue è finito
        this.store.dispatch(new CoreActions.GetAvailableLangsFinished());
        // Quindi, alla fine, torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
        return caught;
      })
    )
}