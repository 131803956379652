/*
 * Servizio che si occupa della gestione del Session Storage. Nativamente, l'oggetto Session Storage espone i seguenti metodi:
 * getItem(key), setItem(key, value), removeItem(key), key(position), clear(), length()
*/

export class SessionStorageService {
  SEARCHES = "SEARCHES";

  constructor() {
  }

  // Aggiunge un elemento chiave - valore
  set(itemKey: string, value: Array<string>) {
    sessionStorage[itemKey] = JSON.stringify(value);
  };
  // Recupera un elemento chiave-valore
  get(itemKey: string): Array<string> {
    return sessionStorage[itemKey] && JSON.parse(sessionStorage[itemKey]);
  };
  // Conta gli elementi salvati
  count() {
    let searches: Array<string> = this.get(this.SEARCHES);
    return (searches && searches.length) || 0;
  };
  // Rimuove un elemento
  remove(itemKey: number) {
    let searches: Array<string> = this.get(this.SEARCHES);
    if (searches && searches.length > itemKey) {
      searches.splice(itemKey, 1);
    }
    // Salvo l'array in sessione
    this.set(this.SEARCHES, searches)
  };
  // Pulisce tutti gli elementi
  empty(key: string) {
    sessionStorage[key] = null;
  };
  // Pulisce tutti gli elementi partendo da un indice specifico
  clearFrom(deleteFrom: number, deleteTo: number) {
    // DeleteFrom è l'indice attuale (parametro searchId dello state), quindi lo incremento affinchè elimini i dati dal risultato successivo in poi
    // deleteTo--;
    while (deleteTo >= deleteFrom) {
      this.remove(deleteTo);
      deleteTo--;
    }
  };
  // Salva un elemento chiave - oggetto
  saveObject(itemKey: string, obj: any) {
    let idx = parseInt(itemKey, 10);
    if (typeof obj === 'object' && !isNaN(idx) && idx >= 0) {
      // Recupero l'array dalla sessione
      let searches: Array<string> = this.get(this.SEARCHES);
      // Se non esiste, lo creo
      if (!searches) {
        searches = [];
      }
      // Aggiungo l'elemento
      searches[idx > 0 ? idx : 0] = JSON.stringify(obj); // Inserisco il valore -1 perché l'array è 0-based
      // Salvo l'array in sessione
      this.set(this.SEARCHES, searches)
    }
  };
  // Recupera un elemento chiave - oggetto
  getObject(itemKey: number) {
    let searches: Array<string> = this.get(this.SEARCHES);
    let idx = parseInt(itemKey.toString(), 10);
    if (searches && searches.length && !isNaN(idx) && idx >= 0 && searches.length > idx) {
      return JSON.parse(searches[idx]); // Recupero il valore -1 perché l'array è 0-based
    }
  }
}