/*
* Componente che crea il placeholder di una sezione
*/

import { Component } from "@angular/core"

@Component({
  selector: 'section-placeholder',
  templateUrl: './section-placeholder.component.html'
})
export class SectionPlaceholderComponent {
  constructor() {
  }
}