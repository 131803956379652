import { Component, Input } from '@angular/core';

export interface CreditRow {
  currentCredits: number;
  requiredCredits?: number | string;
  type: string;
  description: string;
}


@Component({
  selector: 'app-credit-list',
  templateUrl: './credit-list.component.html',
  styleUrls: ['./credit-list.component.scss']
})
export class CreditListComponent {
  currentYear = new Date().getFullYear();

  @Input() creditList: CreditRow[] = [];

  constructor() { }

}
