/*
*  Routing dell'applicazione
*/

import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './core/home/home.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { CompatibilityErrorComponent } from './core/compatibility-error/compatibility-error.component';
import { AuthGuard } from './auth/services/auth-guard.service';
import { LoginGuard } from './shared/services/login-guard.service';
import { CompatibilityGuard } from './shared/services/compatibility-guard.service';
import { ErrorPageComponent } from './core/error/error-page.component';
import { TakerGuard } from './auth/services/taker-guard.service';
import { PageNotAuthorizedComponent } from './core/page-not-authorized/page-not-authorized.component';
import { LoginComponent } from './auth/login/login.component';
import { CourseAdminGuard } from './auth/services/course-admin-guard.service';
import { TemplatesAdminGuard } from './auth/services/templates-admin-guard.service';
import { ItemsAdminGuard } from './auth/services/items-admin-guard.service';
import { GroupsAdminGuard } from './auth/services/groups-admin-guard.service';
import { UsersAdminGuard } from './auth/services/users-admin-guard.service';
import { DashboardAdminGuard } from './auth/services/dashboard-admin-guard.service';
import { EngagementNavigationGuard } from './auth/services/engagement-navigation-guard.service';
import { LocationsAdminGuard } from './auth/services/locations-admin-guard.service';
import { CalendarAdminGuard } from './auth/services/calendar-admin-guard.service';
import { AdminGuard } from './auth/services/admin-guard.service';
import { NewsAdminGuard } from './auth/services/news-admin-guard.service';
import { SuppliersAdminGuard } from './auth/services/suppliers-admin-guard.service';
import { ManageTeachersAccountingAdminGuard } from './auth/services/manage-teachers-accounting-admin-guard.service';
import { TeachersGuard } from './auth/services/teachers-guard.service';
import { TeacherLoginGuard } from './shared/services/teacher-login-guard.service';
import { TeacherLoginComponent } from './auth/teacherLogin/teacherLogin.component';
import { ForceResetPasswordComponent } from './profile/forceResetPassword/forceResetPassword.component';
import { ForceResetPasswordGuard } from './auth/services/force-reset-password-guard.service';
import { ManageTagsAdminGuard } from './auth/services/manage-tags-admin-guard.service';
import { ManageScheduleModelsAdminGuard } from './auth/services/manage-schedule-models-admin-guard.service';
import { ManagerGuard } from './auth/services/manager-guard.service';
import { HomeAdminComponent } from './core/admin-home/admin-home.component';
import { ImportExportAdminGuard } from './auth/services/import-export-admin-guard.service';
import { ManageCompetencesAdminGuard } from './auth/services/manage-competences-admin-guard.service';
import { ApprovationPreregistrationsAdminGuard } from './auth/services/approvation-preregistrations-admin-guard.service';
import { LoginSSOComponent } from './auth/login/login-sso.component';
import { LoginSamlErrorComponent } from './auth/login/login-saml-error.component';
import { LogoutSsoCompletedComponent } from './auth/login/logout-sso-completed.component';
import { ImpersonificationAdminGuard } from './auth/services/impersonification-admin-guard.service';
import { ApprovationInitiativesAdminGuard } from './auth/services/approvation-initiatives-admin-guard.service';

// Lista delle route
const appRoutes: Routes = [
  { path: 'adminHome', component: HomeAdminComponent, canActivate: [AuthGuard, AdminGuard, CompatibilityGuard, EngagementNavigationGuard, ForceResetPasswordGuard] },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard, AdminGuard, CourseAdminGuard, CompatibilityGuard, EngagementNavigationGuard, ForceResetPasswordGuard] },
  { path: 'localLogin', component: LoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'login', component: LoginSSOComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'loginSamlError', component: LoginSamlErrorComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'logoutSsoCompleted', component: LogoutSsoCompletedComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'recoveryUserPassword/:userId/:token', component: LoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'emailConfirmation/:userId/:token', component: LoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'teacherLogin', component: TeacherLoginComponent, canActivate: [TeacherLoginGuard, CompatibilityGuard] },
  { path: 'teacherUser/recoveryUserPassword/:userId/:token', component: TeacherLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'teacherUser/emailConfirmation/:userId/:token', component: TeacherLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'importExport', loadChildren: () => import('./import-export/import-export.module').then(m => m.ImportExportModule), canActivate: [AuthGuard, AdminGuard, ImportExportAdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'faq', loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule), canActivate: [AuthGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'templates', loadChildren: () => import('./templates/templates.module').then(m => m.TemplatesModule), canActivate: [AuthGuard, AdminGuard, TemplatesAdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'initiatives', loadChildren: () => import('./initiatives/initiatives.module').then(m => m.InitiativesModule), canActivate: [AuthGuard, AdminGuard, CourseAdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'itemsAdmin', loadChildren: () => import('./itemsAdmin/items-admin.module').then(m => m.ItemsAdminModule), canActivate: [AuthGuard, AdminGuard, ItemsAdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'locations', loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule), canActivate: [AuthGuard, AdminGuard, LocationsAdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'calendar', loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule), canActivate: [AuthGuard, AdminGuard, CalendarAdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'tags', loadChildren: () => import('./manageTags/manage-tags.module').then(m => m.ManageTagsModule), canActivate: [AuthGuard, AdminGuard, ManageTagsAdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'scheduleModels', loadChildren: () => import('./manageScheduleModels/manage-schedule-models.module').then(m => m.ManageScheduleModelsModule), canActivate: [AuthGuard, AdminGuard, ManageScheduleModelsAdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'competences', loadChildren: () => import('./manageCompetences/manage-competences.module').then(m => m.ManageCompetencesModule), canActivate: [AuthGuard, AdminGuard, ManageCompetencesAdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'competenceProfiles', loadChildren: () => import('./competences/competences.module').then(m => m.CompetencesModule), canActivate: [AuthGuard, AdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard, AdminGuard, DashboardAdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'groups', loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule), canActivate: [AuthGuard, GroupsAdminGuard, AdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'manageTeachersAccounting', loadChildren: () => import('./manageTeachersAccounting/manage-teachers-accounting.module').then(m => m.ManageTeachersAccountingModule), canActivate: [AuthGuard, ManageTeachersAccountingAdminGuard, AdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  //modificato da ./teachers/teachers.module a ./teachers/teachings.module
  { path: 'teachers', loadChildren: () => import('./teachers/teachers.module').then(m => m.TeachersModule), canActivate: [AuthGuard, TeachersGuard, AdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'usersAdmin', loadChildren: () => import('./usersAdmin/usersAdmin.module').then(m => m.UsersAdminModule), canActivate: [AuthGuard, AdminGuard, UsersAdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'impersonificationAdmin', loadChildren: () => import('./impersonificationAdmin/impersonificationAdmin.module').then(m => m.ImpersonificationAdminModule), canActivate: [AuthGuard, AdminGuard, ImpersonificationAdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'takers', loadChildren: () => import('./takers/takers.module').then(m => m.TakersModule), canActivate: [AuthGuard, TakerGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'suppliers', loadChildren: () => import('./suppliers/suppliers.module').then(m => m.SuppliersModule), canActivate: [AuthGuard, AdminGuard, SuppliersAdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'news', loadChildren: () => import('./news/news.module').then(m => m.NewsModule), canActivate: [AuthGuard, AdminGuard, NewsAdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'manager', loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule), canActivate: [AuthGuard, ManagerGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'approvationPreregistrationsAdmin', loadChildren: () => import('./approvationPreregistrationsAdmin/approvationPreregistrationsAdmin.module').then(m => m.ApprovationPreregistrationsAdminModule), canActivate: [AuthGuard, AdminGuard, ApprovationPreregistrationsAdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'approvationInitiativesAdmin', loadChildren: () => import('./approvationInitiativesAdmin/approvationInitiativesAdmin.module').then(m => m.ApprovationInitiativesAdminModule), canActivate: [AuthGuard, AdminGuard, ApprovationInitiativesAdminGuard, CompatibilityGuard, ForceResetPasswordGuard] },
  { path: 'resetPassword', component: ForceResetPasswordComponent, canActivate: [AuthGuard] },
  { path: 'compatibilityError', component: CompatibilityErrorComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: '403', component: PageNotAuthorizedComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { useHash: true, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
