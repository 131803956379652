/*
* Componente che crea un placeholder per la lista delle iniziative pendenti
*/

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';
@Component({
  selector: 'pending-initiatives-placeholder',
  templateUrl: './pending-initiatives-placeholder.component.html',
  styleUrls: ['./pending-initiatives-placeholder.component.scss']
})

export class PendingInitiativesPlaceholderComponent {
  @Input() containerClass: string;

  _hasGreyBackground: boolean = false;
  @Input() set hasGreyBackground(value: string | boolean) {
    this._hasGreyBackground = coerceBooleanProperty(value);
  }

  constructor() {
  }
}
