/*
 * Servizio che permette le chiamate rest relative alle persone
*/
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from "rxjs";
import { GlobalApplicationData } from 'src/app/shared/models/global-application-data.model';
import { SenecaResponse, UserGroup, ComparisonCondition, UserOrderBy } from "src/cm2-commonclasses";
import { PaginationWrapper, User } from '../../../cm2-commonclasses';
import * as fromApp from '../../ngrx/app.reducers';
import * as UsersAdminActions from '../ngrx/usersAdmin.actions';


@Injectable()
export class UsersAdminService {
  applicationData: GlobalApplicationData;
  fromRecord: number;
  numRecords: number;
  result$: Subscription;
  page: number;
  usersAdmin: User[];
  usersAdminTextFilter: string;
  toBeConfirmed: boolean;

  constructor(private store: Store<fromApp.AppState>, private http: HttpClient) {
    // Dallo Store applicativo, ricavo l'url per le chiamate rest e anche la pagina corrente in cui mi trovo nella visualizzazione dei gruppi
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    let usersAdmin$: Observable<User[]> = this.store.select(fromApp.getUsersAdmin);
    let fromRecord$: Observable<number> = this.store.select(fromApp.getUsersAdminFromRecord);
    let numRecords$: Observable<number> = this.store.select(fromApp.getUsersAdminNumRecords);
    let page$: Observable<number> = this.store.select(fromApp.getUsersAdminPage);
    let usersAdminTextFilter$: Observable<string> = this.store.select(fromApp.getUsersAdminTextFilter);
    let toBeConfirmed$: Observable<boolean> = this.store.select(fromApp.getToBeConfirmed);
    const combinedSelectes$ = combineLatest(globalApplicationData$, fromRecord$, numRecords$, page$, usersAdmin$, usersAdminTextFilter$, toBeConfirmed$);
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData, fromRecord, numRecords, page, usersAdmin, usersAdminTextFilter, toBeConfirmed]) => {
        this.applicationData = globalApplicationData;
        this.fromRecord = fromRecord;
        this.numRecords = numRecords;
        this.page = page;
        this.usersAdmin = usersAdmin;
        this.usersAdminTextFilter = usersAdminTextFilter;
        this.toBeConfirmed = toBeConfirmed;
      });
  }

  getDownloadTempFileUrl(filename: string) {
    return this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/download-temp-file?filename=' + filename;
  }

  // Converte la pagina in una paginazione
  paginate(): { fromRecord: number, numRecords: number } {
    let fromRecord: number = 0;
    // Offeset e ResultLimit sono due parametri passati automaticamente dal componente della tabella. La prima volta, non esisteranno. Le altre sì, e mi serviranno per sapere se sto procedendo o retrocedendo nella ricerca
    if (this.page && this.numRecords) {
      fromRecord = (this.page - 1) * this.numRecords;
    } else {
      fromRecord = 0;
    }

    let pagination = {
      fromRecord: fromRecord,
      numRecords: this.numRecords
    }

    // Eseguo il dispatch dell'azione che salva la paginazione
    this.store.dispatch(new UsersAdminActions.SetPaginationUsers(pagination));

    return pagination;
  }

  listDistinctUsersField(fromRecord: number, numRecords: number, distinctField: string, textFilter?: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('distinctField', distinctField);
    httpParams = httpParams.append('textFilter', textFilter || '');

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-distinct-users-field', {
      params: httpParams
    });
  }

  // Aggiunge una lista di utenti tramite un file
  importUsersFromFile(uploadObj) {
    let formData = new FormData();
    formData.append('importFile', uploadObj.file && uploadObj.file.file && uploadObj.file.file.rawFile);
    formData.append('hasHeadersToSkip', uploadObj.hasHeadersToSkip || false);
    formData.append('setPasswordForNewUser', uploadObj.setPasswordForNewUser || false);
    formData.append('forcedPasswordForNewUser', uploadObj.forcedPasswordForNewUser || '');
    formData.append('quote', uploadObj.quote || '');
    formData.append('separator', uploadObj.separator);
    formData.append('isExternal', uploadObj.isExternal)
    formData.append('incrementalImport', uploadObj.totalImport ? 'false' : 'true');
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/import-anag-from-file', formData, options);
    return this.http.request(req);
  }

  // Recupera il counter totale persone all'interno del proprio Tenant
  countPersonsInTenant(searchedText?: string, includeMe?: boolean, confirmedUsers?: boolean, notConfirmedUsers?: boolean, socDistacco?: string, externalOnly?: boolean, notExternalOnly?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    if ((searchedText && searchedText.length) || (this.usersAdminTextFilter && this.usersAdminTextFilter.length)) {
      httpParams = httpParams.append('searchedText', searchedText || this.usersAdminTextFilter);
    }
    if (includeMe) {
      httpParams = httpParams.append('includeMe', includeMe.toString());
    }
    if (confirmedUsers) {
      httpParams = httpParams.append('onlyConfirmed', confirmedUsers.toString());
    } else {
      httpParams = httpParams.append('onlyConfirmed', 'false');
    }
    if (notConfirmedUsers) {
      httpParams = httpParams.append('onlyNotConfirmed', notConfirmedUsers.toString());
    } else {
      httpParams = httpParams.append('onlyNotConfirmed', 'false');
    }
    if (socDistacco) {
      httpParams = httpParams.append('socDistacco', socDistacco);
    }
    if (externalOnly) {
      httpParams = httpParams.append('externalOnly', 'true');
    }
    if (notExternalOnly) {
      httpParams = httpParams.append('notExternalOnly', 'true');
    }
    
    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-persons-in-tenant', {
      params: httpParams
    });
  }

  getCompaniesList() {
    return this.http.get<SenecaResponse<string[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-companies');
  }

  getTenantAnagConfig() {
    return this.http.get<SenecaResponse<string[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-tenant-anag-config');
  }
  
  // Recupera il counter totale persone all'interno del proprio Tenant
  countFilteredUser(searchedText?: string, includeMe?: boolean, confirmedUsers?: boolean, notConfirmedUsers?: boolean, socDistacco?: string, externalOnly?: boolean, notExternalOnly?: boolean) {
    // Preparo i parametri per la richiesta http
    let propertiesFilters: ComparisonCondition[] = [];
    let userFilters = {};
    let searched = searchedText && searchedText.length ? searchedText || (this.usersAdminTextFilter ? this.usersAdminTextFilter : "") : "";

    if (!this.toBeConfirmed) {
      propertiesFilters.push(<ComparisonCondition>{ key: 'toBeConfirmed', notExists: true })
    }
    if (this.toBeConfirmed) {
      propertiesFilters.push(<ComparisonCondition>{ key: 'toBeConfirmed', notExists: false })
    }

    if (socDistacco) {
      userFilters['socDistacco'] = socDistacco;
    }
    if (externalOnly) {
      propertiesFilters.push(<ComparisonCondition>{ key: 'isExternal', value: true, notExists: false })
    }
    if (notExternalOnly) {
      propertiesFilters.push(<ComparisonCondition>{ key: 'isExternal', notExists: true })
    }

    return this.http.post<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-users-by-any-field/', {
      userFilters: userFilters,
      searchedText: searched,
      useOptimizedSearchField: true,
      propertiesFilters: propertiesFilters
    });
  }

  getFilteredUserList(fromRecord?: number, numRecords?: number, searchedText?: string, includeMe?: boolean, confirmedUsers?: boolean, notConfirmedUsers?: boolean, socDistacco?: string, externalOnly?: boolean, notExternalOnly?: boolean, orderBy?: string) {
    let pagination = this.paginate();
    let from = (fromRecord && fromRecord.toString()) || (pagination && pagination.fromRecord && pagination.fromRecord.toString());
    let to = (numRecords && numRecords.toString()) || (pagination && pagination.numRecords && pagination.numRecords.toString());
    let orderUsersBy = orderBy || UserOrderBy.SURNAME_ASC;
    let userFilters = {};
    let propertiesFilters: ComparisonCondition[] = [];
    let searched = searchedText && searchedText.length ? searchedText || (this.usersAdminTextFilter ? this.usersAdminTextFilter : "") : "";

    if (!this.toBeConfirmed) {
      propertiesFilters.push(<ComparisonCondition>{ key: 'toBeConfirmed', notExists: true })
    }
    if (this.toBeConfirmed) {
      propertiesFilters.push(<ComparisonCondition>{ key: 'toBeConfirmed', notExists: false })
    }

    if (socDistacco) {
      userFilters['socDistacco'] = socDistacco;
    }
    if (externalOnly) {
      propertiesFilters.push(<ComparisonCondition>{ key: 'isExternal', value: true, notExists: false })
    }
    if (notExternalOnly) {
      propertiesFilters.push(<ComparisonCondition>{ key: 'isExternal', notExists: true })
    }

    return this.http.post<SenecaResponse<User[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/find-users-by-any-field/', {
      fromRecord: from,
      numRecords: to,
      textFilter: searched,
      orderBy: orderUsersBy,
      userFilters: userFilters,
      propertiesFilters: propertiesFilters
    });
  }

  downloadUserList(searchedText?: string, socDistacco?: string, externalOnly?: boolean, notExternalOnly?: boolean, exportGeneratedPassword?: boolean, getFullPath?: boolean, exportName?: string) {
    let httpParams = new HttpParams();

    let userFilters = {};
    let propertiesFilters: ComparisonCondition[] = [];

    if (!this.toBeConfirmed) {
      propertiesFilters.push(<ComparisonCondition>{ key: 'toBeConfirmed', notExists: true })
    }
    if (this.toBeConfirmed) {
      propertiesFilters.push(<ComparisonCondition>{ key: 'toBeConfirmed', notExists: false })
    }

    if (socDistacco) {
      userFilters['socDistacco'] = socDistacco;
      httpParams = httpParams.append('userFilters', JSON.stringify(userFilters));
    }
    if (externalOnly) {
      propertiesFilters.push(<ComparisonCondition>{ key: 'isExternal', value: true, notExists: false })
    }
    if (notExternalOnly) {
      propertiesFilters.push(<ComparisonCondition>{ key: 'isExternal', notExists: true })
    }

    httpParams = httpParams.append('textFilter', searchedText && searchedText.toString() || '');

    if (propertiesFilters && propertiesFilters.length) {
      httpParams = httpParams.append('propertiesFilters', JSON.stringify(propertiesFilters));
    }

    if (exportGeneratedPassword) {
      httpParams = httpParams.append('exportGeneratedPassword', 'true');
    }
    if (exportName && exportName.length) {
      httpParams = httpParams.append('exportName', exportName);
    }
    if (getFullPath) {
      httpParams = httpParams.append('getFullPath', 'true');
    }

    return this.http.get<SenecaResponse<string>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/export-users-by-any-field`, {
      params: httpParams
    });
  }

  // Recupera una lista di persone all'interno del proprio Tenant
  getPersonsInTenant(fromRecord?: number, numRecords?: number, searchedText?: string, includeMe?: boolean, confirmedUsers?: boolean, notConfirmedUsers?: boolean, socDistacco?: string, externalOnly?: boolean, notExternalOnly?: boolean) {
    let pagination = this.paginate();

    let from = (fromRecord && fromRecord.toString()) || (pagination && pagination.fromRecord && pagination.fromRecord.toString());
    let to = (numRecords && numRecords.toString()) || (pagination && pagination.numRecords && pagination.numRecords.toString());

    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', from);
    httpParams = httpParams.append('numRecords', to);
    if ((searchedText && searchedText.length) || (this.usersAdminTextFilter && this.usersAdminTextFilter.length)) {
      httpParams = httpParams.append('searchedText', searchedText || this.usersAdminTextFilter);
    }
    if (includeMe) {
      httpParams = httpParams.append('includeMe', includeMe.toString());
    }
    if (!this.toBeConfirmed) {
      httpParams = httpParams.append('onlyConfirmed', confirmedUsers.toString());
    } else {
      httpParams = httpParams.append('onlyConfirmed', 'false');
    }
    if (this.toBeConfirmed) {
      httpParams = httpParams.append('onlyNotConfirmed', notConfirmedUsers.toString());
    } else {
      httpParams = httpParams.append('onlyNotConfirmed', 'false');
    }

    if (socDistacco) {
      httpParams = httpParams.append('socDistacco', socDistacco);
    }
    if (externalOnly) {
      httpParams = httpParams.append('externalOnly', 'true');
    }
    if (notExternalOnly) {
      httpParams = httpParams.append('notExternalOnly', 'true');
    }
    return this.http.get<SenecaResponse<User[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-persons-in-tenant/' + from + '/' + to, {
      params: httpParams
    });
  }

  // Servizio che cancella un gruppo
  deleteGroup(groupId: string) {
    return this.http.post<SenecaResponse<UserGroup>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/delete-group/`, {
      groupId: groupId
    });
  }

  // Servizio che manda l'email di conferma di validazione utenza
  sendConfirmationEmail(email: string) {
    return this.http.post<SenecaResponse<UserGroup>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/send-confirmation-email`, {
      email: email
    });
  }

  // Crea/aggiorna un utente
  createOrUpdateUser(user: User, setPassword?: boolean, forcedPassword?: string) {
    // se sto aggiornando, altrimenti prende dati non necessari e non interpretabili da neo4j
    if (user.userOptions || user.userTenant) {
      delete user.userOptions;
      delete user.userTenant;
    }
    return this.http.post<SenecaResponse<User>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/create-or-update-user`, {
      user: user,
      forcedPassword: forcedPassword || '',
      setPassword: setPassword
    });
  }

  // Aggiunge una lista di utenti al gruppo
  addUsersToGroup(groupId: string, userIds: any) {
    return this.http.post<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/add-users-to-group', {
      groupId: groupId,
      userIds: userIds
    });
  }

  // Ottiene il gruppo dato un groupId
  getGroupById(groupId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('groupId', groupId);
    return this.http.get<SenecaResponse<UserGroup>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-group/', {
      params: httpParams
    });
  }

  // Ottiene l'utente dato uno userId
  getUserById(userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<User>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/user-by-userId/' + userId, {
      params: httpParams
    });
  }

  // Aggiorna il titolo di un gruppo
  updateGroup(groupId: string, title: string) {
    return this.http.post<SenecaResponse<UserGroup>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/update-group/`, {
      groupId: groupId,
      title: title
    });
  }

  // Importa gli utenti per inserirli o meno dentro al gruppo (simulate)
  importPersonsAsUsers(groupId: string, cidOrEmailList: string, simulate: boolean) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/add-users-to-group/', {
      groupId: groupId,
      cidOrEmailList: cidOrEmailList,
      simulate: simulate
    });
  }

  // Crea un nuovo gruppo
  createGroup(groupName: string) {
    return this.http.post<SenecaResponse<UserGroup>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/create-group/`, {
      title: groupName
    });
  }

  // Restituisce gli utenti che appartengono ad un determinato gruppo
  getGroupUsers(groupId: string, fromRecord: number, numRecords: number, searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('groupId', groupId);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('searchedText', searchedText);
    return this.http.get<SenecaResponse<PaginationWrapper<User>>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/get-group-users/`, {
      params: httpParams
    });
  }

  // Elimina un utente
  deleteUser(userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<PaginationWrapper<User>>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/delete-user`, {
      params: httpParams
    });
  }

  // Rimuove gli utenti da un dato gruppo
  removeUsersFromGroup(groupId: string, userIds: string[]) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/remove-users-from-group/`, {
      groupId: groupId,
      userIds: userIds
    });
  }


}
