<div fxLayout="column" class="vertical-widget-container">
  <div fxLayout="row" class="widget-content">
    <div fxLayout="column" class="icon-cards" fxLayoutAlign="start center">
      <div fxLayout="column">
        <img class="cursor-pointer" alt="image" src="assets/icons/icon-cards2.png">
      </div>
      <div fxLayout="column" class="margin-top30">
        <svg-icon class="cursor-pointer" (click)="emitOnInfoClick()" src="assets/icons/icon-info-circle-line.svg">
        </svg-icon>
      </div>
    </div>
    <div class="full-width" fxLayout="column" fxLayoutAlign="start start">
      <div fxLayout="column" class="title-wrapper">
        <!-- Titolo -->
        <p class="title">{{ indicator.title }}</p>
        <!-- Sottotitolo -->
        <p class="subtitle" *ngIf="indicator.subtitle">{{ indicator.subtitle }}</p>
      </div>

      <div *ngIf="indicator.itemList && indicator.itemList.length" fxLayout="column" fxFlex
        [ngClass]="{ 'margin-top20' : indicator.subtitle, 'margin-top25' : !indicator.subtitle }"
        class="number-wrapper">
        <div fxFlex="33" fxLayout="column" fxLayoutAlign="start start" class="number-container first">
          <div class="indicator-number" fxLayout="row" fxLayoutAlign="start center">
            <p>
              {{ indicator.indicatorNumber | numberAbbreviation:1 }}
            </p>
          </div>
          <div class="indicator-number-hint" fxLayout="row" fxLayoutAlign="start center">
            <p>{{ indicator.indicatorNumberHint }}</p>
          </div>
        </div>
        <div fxFlex="66" class="number-wrapper">
          <div *ngFor="let result of indicator.itemList | slice:0:5" fxLayout="row" fxLayoutAlign="space-between center"
            class="star-wrapper">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div fxLayout="row" class="margin-right5" fxLayoutAlign="center center">
                <svg-icon *ngIf="result.value - 1 >= 0" src="assets/icons/icon-star.svg"></svg-icon>
                <svg-icon *ngIf="result.value - 2 >= 0" src="assets/icons/icon-star.svg"></svg-icon>
                <svg-icon *ngIf="result.value - 3 >= 0" src="assets/icons/icon-star.svg"></svg-icon>
                <svg-icon *ngIf="result.value - 4 >= 0" src="assets/icons/icon-star.svg"></svg-icon>
                <svg-icon *ngIf="result.value - 5 >= 0" src="assets/icons/icon-star.svg"></svg-icon>
                <svg-icon *ngIf="result.value - 5 >= 0" src="assets/icons/icon-star.svg"></svg-icon>
                <svg-icon *ngIf="result.value - 1 < 0" src="assets/icons/icon-star-o.svg"></svg-icon>
                <svg-icon *ngIf="result.value - 2 < 0" src="assets/icons/icon-star-o.svg"></svg-icon>
                <svg-icon *ngIf="result.value - 3 < 0" src="assets/icons/icon-star-o.svg"></svg-icon>
                <svg-icon *ngIf="result.value - 4 < 0" src="assets/icons/icon-star-o.svg"></svg-icon>
                <svg-icon *ngIf="result.value - 5 < 0" src="assets/icons/icon-star-o.svg"></svg-icon>
              </div>
              <p fxHide style="text-transform: uppercase; color:#bbb; font-size: 12px; ">
                {{result.starsLabel}}
              </p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" class="bold-text margin-right15">
              {{result.count}}
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!indicator.itemList" fxFlex fxLayout="column"
        [ngClass]="{ 'margin-top20' : indicator.subtitle, 'margin-top40' : !indicator.subtitle }"
        class="number-wrapper">
        <div *ngIf="indicator.firstIndicatorNumberHint" fxFlex fxLayout="column" fxLayoutAlign="start start"
          class="number-container first">
          <div class="indicator-number" fxLayout="row" fxLayoutAlign="center center">
            <p>
              {{ indicator.firstIndicatorNumber | numberAbbreviation:1 }}
            </p>
          </div>
          <div class="indicator-number-hint" fxLayout="row" fxLayoutAlign="center center">
            <p>{{ indicator.firstIndicatorNumberHint }}</p>
          </div>
        </div>
        <div *ngIf="indicator.secondIndicatorNumberHint" fxFlex fxLayout="column" fxLayoutAlign="start start"
          class="number-container second">
          <div class="indicator-number" fxLayout="row" fxLayoutAlign="center center">
            <p>
              {{ indicator.secondIndicatorNumber | numberAbbreviation:1 }}
            </p>
          </div>
          <div class="indicator-number-hint" fxLayout="row" fxLayoutAlign="center center">
            <p>{{ indicator.secondIndicatorNumberHint }}</p>
          </div>
        </div>
        <div *ngIf="indicator.thirdIndicatorNumberHint" fxFlex fxLayout="column" fxLayoutAlign="start start"
          class="number-container third">
          <div class="indicator-number" fxLayout="row" fxLayoutAlign="center center">
            <p>
              {{ indicator.thirdIndicatorNumber | numberAbbreviation:1 }}
            </p>
          </div>
          <div class="indicator-number-hint" fxLayout="row" fxLayoutAlign="center center">
            <p>{{ indicator.thirdIndicatorNumberHint }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row" class="widget-footer" fxLayoutAlign="end center">
    <p class="cursor-pointer" (click)="emitOnMainButtonClick()">{{ indicator.buttonText }}</p>
    <svg-icon src="assets/icons/icon-angle-right.svg"></svg-icon>
  </div>
</div>