/*
*  Lista di azioni riguardanti i gruppi
*/

import { Action } from '@ngrx/store';
import { UserGroup } from 'src/cm2-commonclasses';

// Salva la lista di gruppi recuperati
export const SET_GROUPS = 'SET_GROUPS';

// Cancella un gruppo
export const DELETE_GROUP = 'DELETE_GROUP';

// Recupera i gruppi da remoto
export const GET_GROUPS = 'GET_GROUPS';

// Setta il contatore dei gruppi recuperati da remoto
export const SET_GROUPS_COUNT = 'SET_GROUPS_COUNT';

// Setta la paginazione
export const SET_PAGINATION_GROUPS = 'SET_PAGINATION_GROUPS';

// Setta la pagina corrente
export const SET_PAGE_GROUPS = 'SET_PAGE_GROUPS';

// Setta il testo (searchedText)
export const SET_GROUPS_TEXT_FILTER = 'SET_GROUPS_TEXT_FILTER';

export class SetGroups implements Action {
  readonly type = SET_GROUPS;

  constructor(public payload: UserGroup[]) { }
}

export class DeleteGroup implements Action {
  readonly type = DELETE_GROUP;

  constructor(public payload: string) { }
}

export class GetGroups implements Action {
  readonly type = GET_GROUPS;

  constructor(public payload?: boolean) { }
}

export class SetGroupsCount implements Action {
  readonly type = SET_GROUPS_COUNT;

  constructor(public payload: number) { }
}

export class SetPageGroups implements Action {
  readonly type = SET_PAGE_GROUPS;

  constructor(public payload: number) { }
}

export class SetPaginationGroups implements Action {
  readonly type = SET_PAGINATION_GROUPS;

  constructor(public payload: { fromRecord: number, numRecords: number }) { }
}
export class SetGroupsTextFilter implements Action {
  readonly type = SET_GROUPS_TEXT_FILTER;

  constructor(public payload: string) { }
}

export type GroupsActions = SetGroups |
  DeleteGroup |
  SetGroupsCount |
  SetPaginationGroups |
  SetPageGroups |
  SetGroupsTextFilter |
  GetGroups;