import * as moment from 'moment';
import { MapById } from '../../node_modules/atfcore-commonclasses/bin/classes/common';
import { Subscriber } from '../../node_modules/rxjs';

/**
 * Questa modulo contiene le commonclasses realmente utilizzate dal cm2
 */
export * from "atfcore-commonclasses/bin/classes/anag";
export * from "atfcore-commonclasses/bin/classes/assessment";
export * from "atfcore-commonclasses/bin/classes/attribute";
export * from "atfcore-commonclasses/bin/classes/auth";
export * from "atfcore-commonclasses/bin/classes/graph";
export * from "atfcore-commonclasses/bin/classes/common";
export * from "atfcore-commonclasses/bin/classes/coursemanager";
export * from "atfcore-commonclasses/bin/classes/engagement";
export * from "atfcore-commonclasses/bin/classes/errors";
export * from "atfcore-commonclasses/bin/classes/import";
export * from "atfcore-commonclasses/bin/classes/item";
export * from "atfcore-commonclasses/bin/classes/like";
export * from "atfcore-commonclasses/bin/classes/mail";
export * from "atfcore-commonclasses/bin/classes/network";
export * from "atfcore-commonclasses/bin/classes/note";
export * from "atfcore-commonclasses/bin/classes/notification";
export * from "atfcore-commonclasses/bin/classes/question";
export * from "atfcore-commonclasses/bin/classes/rent";
export * from "atfcore-commonclasses/bin/classes/scorm";
export * from "atfcore-commonclasses/bin/classes/supplier";
export * from "atfcore-commonclasses/bin/classes/survey";
export * from "atfcore-commonclasses/bin/classes/tag";
export * from "atfcore-commonclasses/bin/classes/course";
export * from "atfcore-commonclasses/bin/classes/text-template";
export * from "atfcore-commonclasses/bin/classes/upload";
export * from "atfcore-commonclasses/bin/classes/dam";
export * from "atfcore-commonclasses/bin/classes/samba-live";

export type SortRule<T> = { fieldName?: string, fieldValue?: any, fieldExtractor?: (source: T) => any };

/**
 * Comparison function to use within sort functions (ie: Array.sort()). A custom set of sorting rules can be defined, forcing a comparison on specific field and value pairs.
 * If a value is defined in a rule, only matching values will be accepted (a standard comparison won't be performed).
 * Otherwise, the result will come from a standard comparison (x >|=|< y) of the values of the field defined in the rule.
 * @param {any} x - Required: Left-hand comparison element
 * @param {any} y - Required: Right-hand comparison element
 * @param {boolean} reverse - Optional: If true, a reverse order will be performed
 * @param {SortRule | SortRule[]} sortRules - Optional: Rules to use to sort the values. The weight of every rule is defined by its position in the array. Sort rules can only be applied on objects/maps comparison, as they require a field to compare
 * @return {number} -1 if x < y, 0 if x = y, 1 if x > y. If reversed sort is requested, the result will be multiplied by -1.
 */
export function sortByRules<T>(x: T, y: T, reverse?: boolean, sortRule?: SortRule<T> | SortRule<T>[]): number {
    let sortRules: SortRule<T>[] = parseArray(sortRule);
    // Se richiesto l'ordinamento inverso, moltiplico il risultato finale per -1 in modo da invertire i confronti
    let resultModifier: number = !!reverse ? -1 : 1;
    // Se presenti, procedo con l'applicazione delle regole di ordinamento
    // Queste regole funzionano solo su oggetti/mappe, non su tipi primitivi
    // Il peso delle regole è definito in base all'ordine con cui sono scritte nell'array
    if (sortRules && sortRules.length) {
        // Applico sempre la prima regola definita nell'array in modo da dare un peso specifico in base all'ordine con cui sono state definite le regole
        let sortRuleToApply: SortRule<T> = sortRules[0];
        let xValue: any = sortRuleToApply.fieldExtractor ? sortRuleToApply.fieldExtractor(x) : x[sortRuleToApply.fieldName];
        let yValue: any = sortRuleToApply.fieldExtractor ? sortRuleToApply.fieldExtractor(y) : y[sortRuleToApply.fieldName];
        // Se è stato specificato un valore specifico per la regola, devo comparare i corrispondenti valori degli oggetti che sto ordinando
        if (sortRuleToApply.fieldValue) {
            if (xValue == sortRuleToApply.fieldValue && (!yValue || yValue != sortRuleToApply.fieldValue)) {
                return -1 * resultModifier;
            } else if ((!xValue || xValue != sortRuleToApply.fieldValue) && yValue == sortRuleToApply.fieldValue) {
                return 1 * resultModifier;
            } else if (xValue != yValue) {
                // Se i due valori sono diversi, li comparo senza dover scendere di livello nella ricorsione
                return (xValue < yValue ? -1 : 1) * resultModifier;
            } else {
                // Se i due valori sono uguali lascio scorrere l'esecuzione della funzione, che arriverà fino al controllo finale
            }
        }
        // Rimuovo dall'array la regola appena applicata
        let remainingRules: SortRule<T>[] = sortRules.slice(1);
        // Se i due valori sono uguali e ci sono ancora delle regole applicabili (di importanza minore), procedo con la ricorsione
        if (xValue == yValue && remainingRules && remainingRules.length) {
            return sortByRules(x, y, reverse, remainingRules);
        } else {
            // Confronto di default tra i valori del campo specificato nella regola
            return xValue == yValue ? 0 : (xValue < yValue ? -1 : 1) * resultModifier;
        }
    } else {
        // Confronto di default tra i valori passati alla funzione
        return x == y ? 0 : (x < y ? -1 : 1) * resultModifier;
    }
}


/**
 * Utilities for the parsing of the parameters/values/etc
 */
/**
 * Array parsing utility
 * @param {string | T | T[]} value - Value to parse: it can be a single value, an array of values or a stringified JSON of an array
 * @return {T[]} Array with the parsed values. If no values are present, "null" will be returned (and not an empty array).
 */
export function parseArray<T>(value: string | T | T[]): T[] {
    let array: T[] = value && ((typeof value == "string" && value.includes("[")) ? JSON.parse(<string>value) : [].concat(value));
    return array && array.length ? array : null;
}

/**
 * Boolean parsing utility
 * @param {string | boolean} value - Value to parse: it can be a boolean or a thruthy value
 * @return {boolean} The parsed boolean value
 */
export function parseBoolean(val: string | boolean | number): boolean {
    return !!(val && (val === true || val === "true" || val === "1" || val === 1));
}

/**
 * Integer parsing utility. The number will be parsed in base 10.
 * @param {string | number} value - Value to parse: If it is a string, it will be parsed as an integer. If it is a number, it will be forced to an integer.
 * @return {boolean} The parsed integer value (or NaN, if not a number)
 */
export function parseInt(value: string | number): number {
    return typeof value === "number" ? Math.floor(<number>value) : Number.parseInt(<string>value, 10);
}

/**
 * Indica il tempo da sottrarre alla data inizio o fine dell'edizione per mostrare i materiali
 */
export var MATERIAL_VISIBILITY_DELTA_HOURS = 2;

export class CachedResult {
    searchString: string;
    response: any;
}

// Classe che rappresenta un singolo indicatore piccolo
export class SmallWidgetIndicator {
    id: string;
    title: string;
    subtitle?: string;
    firstIndicatorNumber: number;
    firstIndicatorNumberHint: string;
    firstIndicatorNumberIcon?: string;
    secondIndicatorNumber?: number;
    secondIndicatorNumberHint?: string;
    secondIndicatorNumberIcon?: string;
    thirdIndicatorNumber?: number;
    thirdIndicatorNumberHint?: string;
    thirdIndicatorNumberIcon?: string;
    fourthIndicatorNumber?: number;
    fourthIndicatorNumberHint?: string;
    fourthIndicatorNumberIcon?: string;
    buttonText: string;
    showGauge?: boolean;
    gaugeValue?: number;
}

export interface CreditWidgetIndicator {
    id: string;
    title: string;
    subtitle?: string;
    indicators: {
      indicatorNumber: number;
      numberHint: string;
      numberIcon?: string;
    }[];
    buttonText: string;
    showGauge?: boolean;
    gaugeValue?: number;
  }


// Classe che rappresenta un singolo indicatore grande
export class BigWidgetIndicator {
    id: string;
    title: string;
    subtitle?: string;
    firstIndicatorNumber: number;
    firstIndicatorNumberHint: string;
    secondIndicatorNumber: number;
    secondIndicatorNumberHint: string;
    thirdIndicatorNumber?: number;
    thirdIndicatorNumberHint?: string;
    buttonText: string;
}

export function buildAutocompleteServiceKey(srvName: string, allData?: string | boolean, fromRecord?: string | number,
    numRecords?: string | number, type?: string, searchedText?: string, useCache?: boolean) {
    // caceh disabilitata
    // return null;

    // da commentare per disabilitare la cache su tutti i servizi gestiti
    if (!parseBoolean(useCache) || !srvName) {
        return null;
    }
    const _type = (type && type.replace(/[^a-zA-Z0-9-_]|\-/g, '')) || '';

    let serviceKey = srvName + _type;
    if (parseBoolean(allData)) {
        serviceKey += '_allData';
    } else if (!isNaN(<number>fromRecord) || !isNaN(<number>numRecords)) {
        serviceKey += '_from_' + (!isNaN(<number>fromRecord) ? fromRecord : '0')
            + '_numRecords_' + (!isNaN(<number>numRecords) ? numRecords : '0');
    }
    return serviceKey;
}

export function findCachedResult(cache: any, key: string, searchedText?: string) {
    if (cache && key && cache[key]) {
        const cachedResults = <CachedResult[]>cache[key];
        return cachedResults && cachedResults.length && cachedResults.find((result: CachedResult) => {
            return result.searchString === searchedText;
        });
    }
    return null;
}

export function storeCachedResult(cache: any, key: string, response: any, searchedText?: string) {
    // TODO-MC aggiungere limite di risultati in cache in modo da non rischiare di occupare troppa memoria
    if (cache && key) {
        const newResult = <CachedResult>{
            searchString: searchedText,
            response: response
        };
        if (!cache[key]) {
            cache[key] = [newResult];
        } else if (!findCachedResult(cache, key, searchedText)) {
            const cachedResults = <CachedResult[]>cache[key];
            if (cachedResults && cachedResults.length) {
                cachedResults.push(newResult);
            }
        }
    }
}

export function defaultTo(value: any, _def: any) {
    return (!!value && value) || _def;
}
// @dynamic
export class DateUtil {

    static now = function (): Date {
        return moment.utc().toDate();
    };

    static dateToUtc = function (date: Date): Date {
        if (!date) {
            return null;
        }
        try {
            return moment.utc(date).toDate()
        }
        catch (error) {
            return null;
        }
    }

    static stringToUtc = function (date: string): Date {
        if (!date) {
            return null;
        }
        try {
            return this.dateToUtc(new Date(date));
        }
        catch (error) {
            return null;
        }
    }

    static getDateWithoutSeconds(value): any {
        if (!value) {
            return null;
        }
        if (typeof value === 'string') {
            return new Date(new Date(value).setSeconds(0, 0));
        }
        if (value instanceof Date) {
            return new Date(value.setSeconds(0, 0));
        }
        if (moment.isMoment(value)) {
            const newDate = moment(value).set('second', 0).set('millisecond', 0);
            return newDate;
        }
    }

    static getDateWithoutTime(value): any {
        if (!value) {
            return null;
        }
        if (typeof value === 'string') {
            return new Date(new Date(value).setHours(0, 0, 0, 0));
        }
        if (value instanceof Date) {
            return new Date(new Date(value).setHours(0, 0, 0, 0));
        }
        if (moment.isMoment(value)) {
            const newDate = moment(value).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
            return newDate;
        }
    }

    static convertModelToUTC(value): string {
        const _date = DateUtil.getDateWithoutSeconds(value);
        if (_date && _date instanceof Date) {
            return new Date(_date.toUTCString()).toISOString();
        }
        if (_date && moment.isMoment(_date)) {
            let newDateVar = _date.utc().format();
            return new Date(newDateVar).toISOString();
        }
        return null;
    }

    static getDayDateFromUTCDate(value): string {
        // trasformo nella data localizzata
        // e azzero ore minuti e secondi e converto in UTC
        const _localizedDate = DateUtil.getDateWithoutTime(value);
        return DateUtil.convertModelToUTC(_localizedDate);
    }

    static calculateDateTimeByDay(dayDate: string, time: string) {
        let resultFromMoment = null;
        if (dayDate && time) {
            resultFromMoment = moment(dayDate).add(new Date(time).getHours(), 'hours').toISOString();
            resultFromMoment = moment(resultFromMoment).add(new Date(time).getMinutes(), 'minutes').toISOString();
        } else {
            resultFromMoment = time && moment(time).toISOString();
        }
        return resultFromMoment;
    }
}

/**
 * Arrays manipulation utilities
 */
export namespace ArrayUtils {

    /**
     * Transforms an array to a map using a mapping function to extract the value to use as key.
     * NB: In case of equal keys, the key-value pair will be overridden with the latest value. To group multiple values using the same key @see {ArrayUtils.groupByKey}.
     * @param {T[]} array - Required: Array to transform
     * @param {(T) => string} keyExtractor - Required: Function to use to extract the key value of the map
     * @return {MapById<T>} Map with the extracted key as key and the respective array object as value
     */
    export function toMap<T>(array: T[], keyExtractor: (obj: T) => string): MapById<T> {
        if (!array || !keyExtractor) {
            return {};
        }
        return array.reduce((map: MapById<T>, value: T) => {
            let key: string = keyExtractor(value);
            map[key] = value;
            return map;
        }, {}) || {};
    }

    /**
     * Transforms an array to a map of arrays using a mapping function to extract the value to use as key
     * @param {T[]} array - Required: Array to transform
     * @param {(T) => string} keyExtractor - Required: Function to use to extract the key value of the map
     * @param {(T) => R} valueExtractor - Required: Function to use to extract the value of every single entry that will be put in the map. By default the return type will be equal to the type of the array's objects (R = T).
     * @return {MapById<(R = T)[]>} Map with the extracted key as key and the respective array objects (or their values mapped through the valueExtractor function) as value
     */
    export function groupByKey<T, R = T>(array: T[], keyExtractor: (obj: T) => string, valueExtractor?: (obj: T) => R): MapById<R[]> {
        if (!array || !keyExtractor) {
            return {};
        }
        return array.reduce((map: MapById<R[]>, value: T) => {
            let key: string = keyExtractor(value);
            if (!map[key]) {
                map[key] = [];
            }
            map[key].push(!!valueExtractor ? valueExtractor(value) : <any>value);
            return map;
        }, {}) || {};
    }
}

export function genericResponseSubscriber<T>(__this: any, successCallback?: (param: T) => void, errorCallback?: (param: T) => void) {
    if (!__this.toastr || !__this.translate) {
        throw new Error('Error the component dependencies ToastrService or TranslateService aren\'t resolved');
    }

    return <Subscriber<T>>{
        next: (responseData: T) => {
            const _responseData: any = responseData;
            if (_responseData.error) {
                if (errorCallback) {
                    errorCallback(responseData);
                } else {
                    __this.toastr.error(__this.translate.instant('errors.' + _responseData.error));
                }
            } else {
                if (successCallback) {
                    successCallback(responseData);
                }
            }
        },
        error: (err) => {
            if (errorCallback) {
                errorCallback(err);
            } else {
                __this.toastr.error(__this.translate.instant('errors.' + err.message));
            }
        }
    };
}


