/*
 * Azioni per l'autenticazione. Il LOGIN setta semplicemente l'utente come loggato; il DO_LOGIN invece chiamerà i servizi per l'autenticazione a back-end
 *
*/

import { createAction, props } from '@ngrx/store';

export const DoLogin = createAction(
  '[auth] do login',
  props<{ email: string, password: string, isTaker?: boolean }>()
);

export const DoTeacherLogin = createAction(
  '[auth] do teacher login',
  props<{ email: string, password: string }>()
);

export const Login = createAction(
  '[auth] login'
);

export const Logout = createAction(
  '[auth] logout'
);

export const SetIsTaker = createAction(
  '[auth] set is taker'
);

export const SetIsTeacher = createAction(
  '[auth] set is teacher'
);

export const SetToken = createAction(
  '[auth] set token',
  props<{ payload: string }>()
);

export const SetUserAuthenticated = createAction(
  '[auth] set user authenticated',
  props<{ isTaker?: boolean, isTeacher?: boolean }>()
);
