import { Injectable } from '@angular/core';
import { UrlService } from './shared/services/url.service';

@Injectable()
export class SocialLoginConfig {
  constructor(private urlService: UrlService) {
  }

  getSocialLoginConfg() {
    // config di dev/default
    let config = {
      facebook: {
        clientId: "1225772800925403"
      },
      google: {
        clientId: "426387533671-630skuta58m22fcir0n0lf47tsklf776.apps.googleusercontent.com"
      },
      linkedIn: {
        clientId: "860o5snhxdrr9g",
        authorize: true,
        lang: 'en_US',
        scope: "r_liteprofile r_emailaddress w_member_social",
        callBackUrl: "linkedIn.html"
      },
      twitter: {
        consumerKey: "9t9bmJ6LCxFEiZrM9jkysulkU",
        callBackUrl: "twitter.html"
      }
    };
    if (this.urlService.isStaging()) {
      config = {
        ...config,
        facebook: {
          clientId: "1663292757147526"
        },
        google: {
          clientId: "245340040125-aq6o0t0nlnm88thldolrhc601e1bltfd.apps.googleusercontent.com"
        },
        linkedIn: {
          ...config.linkedIn,
          clientId: "86yqq82ua79815",
        },
        twitter: {
          consumerKey: "CNXJVY0GWocievbFgpyC0h2pG",
          callBackUrl: "twitter.html"
        }
      }
    }
    else if (this.urlService.isProduction()) {
      config = {
        ...config,
        facebook: {
          clientId: "343276282859257"
        },
        google: {
          clientId: "309193715389-v6ht50v3n2cteflipl9hhdm875hr0ruo.apps.googleusercontent.com"
        },
        linkedIn: {
          ...config.linkedIn,
          clientId: "86uxcjwalevnay"
        },
        twitter: {
          consumerKey: "VMkc0wcizJoH7oXGvUFu9JZUT",
          callBackUrl: "twitter.html"
        }
      }
    }
    return config;
  }


}