/*
 * Servizio che permette le chiamate rest relative alle iniziative (corsi)
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as InitiativeActions from '../ngrx/initiative.actions';
import * as fromApp from '../../ngrx/app.reducers';
import { GlobalApplicationData } from "../../shared/models/global-application-data.model";
import { Observable, Subscription, of } from "rxjs";
import { combineLatest } from "rxjs";
import { Item, User, Supplier_new, SupplierTask, Note, MailQueue, ItemAttributeTypes, ItemTakerEnrollStatusTypes, MapById, CertificateList, Upload, SenecaResponse, MailSender, Tag, parseArray, TextTemplate, CourseManagerItem } from "../../../cm2-commonclasses";
import { ExtendedTag } from "../../shared/models/extended-tag.model";
import { ImportPersonsAsTakersResponse } from "../../shared/models/importpersons-as-takers-response.model";
import { ExtendedItem } from "../../shared/models/extended-item.model";
import { filter } from "rxjs/operators";

@Injectable()
export class InitiativeService {
  applicationData: GlobalApplicationData;
  fromRecord: number;
  numRecords: number;
  result$: Subscription;
  currentPage: number;
  initiatives: any[];
  initiativeType: string[];
  initiativeTextFilter: string;
  initiativeLocationsIds: string[];
  initiativeArgumentsIds: string[];
  initiativeStreamsIds: string[];
  initiativeAdminIds: string[];
  searchedSapId: any;

  constructor(private store: Store<fromApp.AppState>,
    private http: HttpClient) {
    // Dallo Store applicativo, ricavo l'url per le chiamate rest e anche la pagina corrente in cui mi trovo nella visualizzazione delle iniziative
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    let numRecords$: Observable<number> = this.store.select(fromApp.getInitiativesNumRecords);
    let fromRecord$: Observable<number> = this.store.select(fromApp.getInitiativesFromRecord);
    let currentPage$: Observable<number> = this.store.select(fromApp.getInitiativesPage);
    let initiativeType$: Observable<string> = this.store.select(fromApp.getInitiativeFilterType);
    let initiativeTextFilter$: Observable<string> = this.store.select(fromApp.getInitiativesTextFilter);
    let initiativeLocationsIds$: Observable<string[]> = this.store.select(fromApp.getInitiativesLocations);
    let initiativeArgumentsIds$: Observable<string[]> = this.store.select(fromApp.getInitiativesArguments);
    let initiativeStreamsIds$: Observable<string[]> = this.store.select(fromApp.getInitiativesStreams);
    let initiativeAdminIds$: Observable<string[]> = this.store.select(fromApp.getInitiativesAdmin);
    let initiativeSapCode$: Observable<string> = this.store.select(fromApp.getInitiativesSapCode);

    const combinedSelectes$ = combineLatest(globalApplicationData$, fromRecord$, numRecords$, currentPage$, initiativeType$, initiativeTextFilter$, initiativeLocationsIds$, initiativeArgumentsIds$, initiativeStreamsIds$, initiativeAdminIds$, initiativeSapCode$);
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData, fromRecord, numRecords, currentPage, initiativeType, initiativeTextFilter, initiativeLocationsIds, initiativeArgumentsIds, initiativeStreamsIds, initiativeAdminIds, initiativeSapCode]) => {
        this.applicationData = globalApplicationData;
        this.fromRecord = fromRecord;
        this.numRecords = numRecords;
        this.currentPage = currentPage;
        this.initiativeTextFilter = initiativeTextFilter;
        this.initiativeLocationsIds = initiativeLocationsIds;
        this.initiativeArgumentsIds = initiativeArgumentsIds;
        this.initiativeStreamsIds = initiativeStreamsIds;
        this.initiativeAdminIds = initiativeAdminIds;
        if (initiativeType && initiativeType.length) {
          this.initiativeType = [initiativeType];
        } else {
          this.initiativeType = null;
        }
        this.searchedSapId = initiativeSapCode;
      });
  }


  // Servizio che verifica la disponibilità di un'utente in un determinato periodo
  listCoursesInSameConflictByTaker(editionIdToIgnore, days) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-courses-in-same-conflict-time-by-taker', {
      editionIdToIgnore: editionIdToIgnore,
      days: days
    });
  }

  // Servizio che verifica la disponibilità di un docente in un determinato periodo
  listCoursesInSameConflictByTeacher(personId, days, stageItemId, dayIdToIgnore?) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/search-concurrent-training-courses-for-teachers', {
      personIds: personId,
      days: days,
      dayIdToIgnore: dayIdToIgnore,
      stageItemId: stageItemId
    });
  }

  // Servizio che torna le supplierPerson in base ad un userId
  listSupplierPersonByUserId(userId?) {
    let fromRecord;
    let numRecords;
    let httpParams = new HttpParams();

    if (userId) {
      userId.forEach(element => {
        httpParams = httpParams.append('userId', element);
      });
    }

    httpParams = httpParams.append('allData', 'true');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-supplier-persons-by-userId/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  // Servizio che torna gli utenti in base ad un supplierPersonId
  listUsersBySupplierPersonId(supplierPersonId?) {
    let fromRecord;
    let numRecords;
    let httpParams = new HttpParams();

    if (supplierPersonId) {
      supplierPersonId.forEach(element => {
        httpParams = httpParams.append('supplierPersonId', element);
      });
    }

    httpParams = httpParams.append('allData', 'true');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-users-by-supplier-person-id/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  // Servizio che verifica la disponibilità di un'aula fisica/virtuale in un determinato periodo
  listCoursesInSameConflictByTime(data, dayIdToIgnore) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-rooms-in-same-conflict-time', {
      daysData: data,
      dayIdToIgnore: dayIdToIgnore
    });
  }

  getWebinarDaysLinks(itemIds: string | string[]) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemIds', itemIds && typeof itemIds == 'string' ? itemIds : JSON.stringify(itemIds));
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-webinar-days-links', {
      params: httpParams
    });
  }

  // Servizio che recupera gli upload
  getSambaLiveRecordingsByIds(uploadIds: string[]) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('uploadIds', JSON.stringify(uploadIds));
    return this.http.get<SenecaResponse<Upload[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-sambaLive-recording-uploads-by-ids', {
      params: httpParams
    });
  }

  // Recupera una iniziative potenzialmente aggiungibili come tappa ad un percorso
  listAvailableInitiativesForPath(fromRecord?: string | number, numRecords?: string | number, title?: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    httpParams = httpParams.append('title', title || '');

    return this.http.get<SenecaResponse<Item[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-available-initiatives-for-path/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  // Recupera una lista di offerte eseguite da un fornitore
  listConfirmedProposalOfCourseSupplier(fromRecord?: string | number, numRecords?: string | number, supplierId?: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    httpParams = httpParams.append('supplierId', supplierId);
    return this.http.get<SenecaResponse<Tag[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-confirmed-proposal-of-course-supplier/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  // Recupera il contatore delle offerte create da un determinato fornitore
  countConfirmedProposalOfCourseSupplier(supplierId: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('supplierId', supplierId);
    return this.http.get<SenecaResponse<ExtendedTag[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-confirmed-proposal-of-course-supplier', {
      params: httpParams
    });
  }

  setTakerEnrollAttribute(takerIds: string[], editionItemIds: string[], ItemTakerEnrollAttribute: object) {
    return this.http.post<SenecaResponse<ExtendedItem>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/set-item-taker-enroll-attribute', {
      takerIds: takerIds,
      editionItemIds: editionItemIds,
      attribute: ItemTakerEnrollAttribute
    });
  }

  removeTakerEnrollAttribute(takerEnrollId: string, attributeId: string) {
    return this.http.post<SenecaResponse<ExtendedItem>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/remove-item-taker-enroll-attribute', {
      takerEnrollId: takerEnrollId,
      attributeId: attributeId
    });
  }

  // Servizio che recupera una lista di iniziative
  getInitiatives() {
    return this.http.get<any>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-text-template-list/');
  }

  // Servizio che recupera i link della giornata
  getWebinarDayLink(itemId: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-webinar-day-link', {
      params: httpParams
    });
  }

  // Servizio che verifica la disponibilità di un'aula in un determinato periodo
  listWebinarRoomsInSameConflictTime(sambaLiveAccountId: string, startTime: string, endTime: string, dayIdToIgnore?: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('sambaLiveAccountId', sambaLiveAccountId);
    httpParams = httpParams.append('startTime', startTime);
    httpParams = httpParams.append('endTime', endTime);
    httpParams = dayIdToIgnore ? httpParams.append('dayIdToIgnore', dayIdToIgnore) : httpParams;
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-webinar-rooms-in-same-conflict-time', {
      params: httpParams
    });
  }

  // Converte la pagina in una paginazione
  paginate(): { fromRecord: number, numRecords: number } {
    let fromRecord: number = 0;
    // Offeset e ResultLimit sono due parametri passati automaticamente dal componente della tabella. La prima volta, non esisteranno. Le altre sì, e mi serviranno per sapere se sto procedendo o retrocedendo nella ricerca
    if (this.currentPage && this.numRecords) {
      fromRecord = (this.currentPage - 1) * this.numRecords;
    } else {
      fromRecord = 0;
    }

    let pagination = {
      fromRecord: fromRecord,
      numRecords: this.numRecords
    }

    // Eseguo il dispatch dell'azione che salva la paginazione
    this.store.dispatch(new InitiativeActions.SetPagination(pagination));

    return pagination;
  }

  // Servizio che recupera il counter dei fornitori
  countSuppliers(supplierName?: string, supplierType?: string[]) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    if (supplierType && supplierType.length) {
      supplierType.forEach(supplierType => {
        httpParams = httpParams.append('supplierType', supplierType);
      });
    }
    httpParams = httpParams.append('supplierName', supplierName || '');
    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-suppliers-by-any', {
      params: httpParams
    });
  }

  // Recupera una lista di fornitori
  getSuppliers(fromRecord?: string | number, numRecords?: string | number, supplierName?: string, supplierType?: string[]): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    httpParams = httpParams.append('supplierName', supplierName || '');
    if (supplierType && supplierType.length) {
      supplierType.forEach(supplierType => {
        httpParams = httpParams.append('supplierType', supplierType);
      });
    }
    return this.http.get<SenecaResponse<Tag[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-suppliers-by-any/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  // Recupera il count delle iniziative potenzialmente aggiungibili come tappa ad un percorso
  countAvailableInitiativesForPath(title?: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('title', title || '');

    return this.http.get<SenecaResponse<Tag[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-available-initiatives-for-path', {
      params: httpParams
    });
  }

  // Recupera una lista di offerte eseguite da un fornitore
  getSupplierProposalMailRecipients(supplierId: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('supplierId', supplierId);
    return this.http.get<SenecaResponse<MailQueue[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-supplier-proposal-mail-recipients/' + supplierId, {
      params: httpParams
    });
  }

  // Recupera una lista di firme
  listAvailableMailSignatures(): any {
    return this.http.get<SenecaResponse<MailSender[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-available-mail-signatures', {
    });
  }

  // Recupera una lista di mail sender
  listAvailableMailSenders(): Observable<SenecaResponse<MailSender[]>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('allData', "true");
    return this.http.get<SenecaResponse<MailSender[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-mail-senders', {
      params: httpParams
    });
  }

  // Recupera le ultime iniziative disponibili
  getLastLastInitiatives(fromRecord?: string, numRecords?: string, itemTypes?: string[], title?: string,
    suspended?: boolean, archived?: boolean, withSyllabusParams?: boolean, syllabusAttributeRefTypes?: string[],
    syllabusAttributeRefIds?: string[], withLocationParams?: boolean, locationAttributeRefTypes?: string[],
    locationAttributeRefIds?: string[]): any {
    let pagination = this.paginate();
    let from = fromRecord || pagination.fromRecord.toString();
    let to = numRecords || pagination.numRecords.toString();
    // Preparo i parametri per la richiesta http

    let locationParams = false
    if (this.initiativeLocationsIds != null && this.initiativeLocationsIds[0] != null) {
      locationParams = true
    }
    let locationIds = this.initiativeLocationsIds;

    let tagIds = []
    let filteredInitiativeArgumentsIds: string[] = null;
    if (this.initiativeArgumentsIds != null) {
      filteredInitiativeArgumentsIds = this.initiativeArgumentsIds?.filter(x => x != null && x != "null");
    }
    let filteredInitiativeStreamsIds: string[] = null;
    if (this.initiativeStreamsIds != null) {
      filteredInitiativeStreamsIds = this.initiativeStreamsIds?.filter(x => x != null && x != "null");
    }
    if (filteredInitiativeArgumentsIds != null && filteredInitiativeArgumentsIds.length) {
      tagIds.push(...filteredInitiativeArgumentsIds);
    }
    if (filteredInitiativeStreamsIds != null && filteredInitiativeStreamsIds.length) {
      tagIds.push(...filteredInitiativeStreamsIds);
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', from);
    httpParams = httpParams.append('numRecords', to);
    httpParams = httpParams.append('title', title || this.initiativeTextFilter || '');
    httpParams = httpParams.append('suspended', `${suspended}`);
    httpParams = httpParams.append('archived', `${archived}`);
    httpParams = httpParams.append('withSyllabusParams', `${!!tagIds.length}`);
    httpParams = httpParams.append('syllabusAttributeRefTypes', JSON.stringify(syllabusAttributeRefTypes));
    httpParams = httpParams.append('syllabusAttributeRefIds', JSON.stringify(tagIds));
    httpParams = httpParams.append('withLocationParams', `${locationParams}`);
    httpParams = httpParams.append('locationAttributeRefTypes', JSON.stringify(locationAttributeRefTypes));
    httpParams = httpParams.append('locationAttributeRefIds', JSON.stringify(locationIds));

    let attributeFilters = [];
    if (this.initiativeAdminIds && this.initiativeAdminIds.length) {
      attributeFilters.push({
        key: ItemAttributeTypes.ADMIN_IN_CHARGE,
        value: this.initiativeAdminIds
      })
      // httpParams = httpParams.append('adminInChargeIds', JSON.stringify(this.initiativeAdminIds));
    }

    if (this.searchedSapId) {
      attributeFilters.push({
        key: ItemAttributeTypes.SIMPLE_ID,
        value: [this.searchedSapId]
      })
    }

    if (attributeFilters && attributeFilters.length) {
      httpParams = httpParams.append('attributeFilters', JSON.stringify(attributeFilters));
    }

    if ((itemTypes && itemTypes.length) || (this.initiativeType && this.initiativeType.length)) {
      let types = itemTypes || this.initiativeType;
      types.forEach(itemTypes => {
        httpParams = httpParams.append('itemTypes', itemTypes);
      });
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-latest-opened-initiatives/' + from + '/' + to, {
      params: httpParams
    });
  }

  // Recupera le ultime iniziative sospese
  getLatestOpenedInProgressInitiatives(fromRecord?: string, numRecords?: string, itemTypes?: string[], title?: string): any {
    let pagination = this.paginate();
    let from = fromRecord || pagination.fromRecord.toString();
    let to = numRecords || pagination.numRecords.toString();
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', from);
    httpParams = httpParams.append('numRecords', to);
    httpParams = httpParams.append('title', title || this.initiativeTextFilter || '');
    if ((itemTypes && itemTypes.length) || (this.initiativeType && this.initiativeType.length)) {
      let types = itemTypes || this.initiativeType;
      types.forEach(itemTypes => {
        httpParams = httpParams.append('itemTypes', itemTypes);
      });
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-latest-opened-in-progress-initiatives/' + from + '/' + to, {
      params: httpParams
    });
  }

  // Verifica se esiste già un Supplier agganciato all'utente
  getSupplierFromFacultyMember(facultyMemberUserId: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('facultyMemberUserId', facultyMemberUserId);
    return this.http.get<SenecaResponse<Supplier_new>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-supplier-from-faculty-member/' + facultyMemberUserId, {
      params: httpParams
    });
  }

  // Recupera l'ultima email di invito inviata ad una specifica persona
  getSentMailFromReference(userId: string, mailType: string, referenceType: string, referenceId: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId || '');
    httpParams = httpParams.append('mailType', mailType || '');
    httpParams = httpParams.append('referenceType', referenceType || '');
    httpParams = httpParams.append('referenceId', referenceId || '');
    return this.http.get<SenecaResponse<MailQueue>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-sent-mail-from-reference', {
      params: httpParams
    });
  }

  // Recupera l'ultima email di invito inviata ad una specifica persona
  listTakerStatusesWithCount(stageItemId: string, editionItemId: string | string[]): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = !!stageItemId ? httpParams.append('stageItemId', stageItemId) : httpParams;
    httpParams = httpParams.append('editionItemId', editionItemId && typeof editionItemId == 'string' ? editionItemId : JSON.stringify(editionItemId));
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-taker-statuses-with-count', {
      params: httpParams
    });
  }

  // Lista docenti e tutor unici per edizione
  listTeachersByEditionId(editionItemId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('editionItemId', editionItemId);
    // httpParams = httpParams.append('fromRecord', '0');
    // httpParams = httpParams.append('numRecords', '1');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-teachers-by-edition-id', {
      params: httpParams
    });
  }

  // Count docenti e tutor per edizione
  countTeachersByEditionId(editionItemId) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('editionItemId', editionItemId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-teachers-by-edition-id', {
      params: httpParams
    });
  }

  // Setta i minuti di presenza di un supplierPerson
  setTeacherMinutesForEdition(editionItemId: string, supplierPersonIds: string[], minutes: number) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/set-teacher-minutes-for-edition', {
      editionItemId: editionItemId,
      supplierPersonIds: supplierPersonIds,
      minutes: minutes
    });
  }

  // Recupera tutti gli id delle persone in un determinato stato
  getTakerIdsForStatus(stageItemId: string, editionItemId: string | string[], takerStatus?: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = !!stageItemId ? httpParams.append('stageItemId', stageItemId) : httpParams;
    httpParams = httpParams.append('editionItemId', editionItemId && typeof editionItemId == 'string' ? editionItemId : JSON.stringify(editionItemId));
    httpParams = takerStatus && takerStatus !== 'ALL' ? httpParams.append('takerStatus', takerStatus) : httpParams;
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-takerIds-for-status', {
      params: httpParams
    });
  }

  // Recupera l'ultima proposta richiesta ad un fornitore
  getLastProposalRequestMail(supplierTaskId: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('supplierTaskId', supplierTaskId);
    return this.http.get<SenecaResponse<MailQueue>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-last-proposal-request-mail/' + supplierTaskId, {
      params: httpParams
    });
  }

  // Recupera l'ultima proposta richiesta ad un fornitore
  reenqueueProposalRequestMail(mailQueueId: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('mailQueueId', mailQueueId);
    return this.http.get<SenecaResponse<MailQueue>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/reenqueue-proposal-request-mail/' + mailQueueId, {
      params: httpParams
    });
  }

  // Recupera una lista di persone all'interno del proprio Tenant
  listSupplierTasksBySupplierId(params: {
    supplierIds: string[],
    referenceId?: string,
    supplierTaskType?: string[],
    allData?: boolean,
    fromRecord?: string,
    numRecords?: string,
    withAttributesLoaded?: boolean,
    withSupplierLoaded?: boolean,
    withStatusesLoaded?: boolean,
    withClosedStatusesLoaded?: boolean,
    supplierTaskId?: string,
    statusType?: string | string[];
    searchClosedStatesToo: boolean;
  }): any {
    let from = params && params.fromRecord;
    let to = params && params.numRecords;
    let referenceId = params && params.referenceId;
    let supplierIds = params && params.supplierIds;
    let supplierTaskId = params && params.supplierTaskId;
    let supplierTaskType = params && params.supplierTaskType;
    let withSupplierLoaded = params && params.withSupplierLoaded;
    let withStatusesLoaded = params && params.withStatusesLoaded;
    let withClosedStatusesLoaded = params && params.withClosedStatusesLoaded;
    let allData = params && params.allData;
    let withAttributesLoaded = params && params.withAttributesLoaded;
    let statusTypes = parseArray(params && params.statusType);
    let searchClosedStatesToo = params && params.searchClosedStatesToo;

    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', from && from.toString());
    httpParams = httpParams.append('numRecords', to && to.toString());
    httpParams = httpParams.append('referenceId', referenceId || '');
    httpParams = httpParams.append('supplierTaskId', supplierTaskId || '');
    httpParams = httpParams.append('withSupplierLoaded', withSupplierLoaded && withSupplierLoaded.toString());
    httpParams = httpParams.append('withStatusesLoaded', withStatusesLoaded && withStatusesLoaded.toString());
    httpParams = httpParams.append('withClosedStatusesLoaded', withClosedStatusesLoaded && withClosedStatusesLoaded.toString());
    httpParams = httpParams.append('allData', allData && allData.toString());
    httpParams = httpParams.append('withAttributesLoaded', withAttributesLoaded && withAttributesLoaded.toString());
    httpParams = httpParams.append('searchClosedStatesToo', searchClosedStatesToo && searchClosedStatesToo.toString());
    if (Array.isArray(supplierIds)) {
      if (supplierIds && supplierIds.length) {
        supplierIds.forEach(supplierId => {
          httpParams = httpParams.append('supplierId', supplierId);
        });
      }
    } else {
      httpParams = httpParams.append('supplierId', supplierIds);
    }
    if (supplierTaskType && supplierTaskType.length) {
      supplierTaskType.forEach(taskType => {
        httpParams = httpParams.append('taskType', taskType);
      });
    }
    // In caso di admin se non specificato recupero i task nello stato "pending" per esso
    // statusTypes = statusTypes && statusTypes.length ? statusTypes : [SupplierTaskStatusTypes.CLIENT_EVALUATING];
    if (statusTypes && statusTypes.length) {
      statusTypes.forEach(statusType => {
        httpParams = httpParams.append('statusType', statusType);
      });
    } else { }
    return this.http.get<SenecaResponse<TextTemplate[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-supplier-tasks-by-supplierId/' + from + '/' + to, {
      params: httpParams
    });
  }

  // Recupera le note associate al task di un supplier
  listSupplierProposalNotes(supplierTaskId: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('supplierTaskId', supplierTaskId);
    return this.http.get<SenecaResponse<Note[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-supplier-proposal-notes/' + supplierTaskId, {
      params: httpParams
    });
  }

  // Recupera il count delle note associate al task di un supplier
  countSupplierProposalNotes(supplierTaskId: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('supplierTaskId', supplierTaskId);
    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-supplier-proposal-notes/' + supplierTaskId, {
      params: httpParams
    });
  }

  // Servizio che recupera il counter delle iniziative
  countInitiatives() {
    return this.http.get<any>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-text-template-list/');
  }

  // Servizio che crea un nuovo Syllabus
  createSyllabus(syllabus: Item, isClone?: boolean) {
    return this.http.post<SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-syllabus', <CourseManagerItem.CreateSyllabus>{
      syllabus: syllabus,
      isClone: isClone
    });
  }

  // Servizio che crea un nuovo Syllabus
  confirmProposalRequest(supplierTaskId: string) {
    return this.http.post<SenecaResponse<SupplierTask>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/confirm-proposal-request', {
      supplierTaskId: supplierTaskId
    });
  }

  // Servizio che rifiuta l'offerta
  refuseProposalRequest(supplierTaskId: string[], notifySupplier?: boolean) {
    return this.http.post<SenecaResponse<SupplierTask>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/refuse-proposal-request', {
      supplierTaskId: supplierTaskId,
      notifySupplier: notifySupplier
    });
  }

  // Servizio che importa una lista di persone di un Item come taker
  importPersonsAsCourseTakers(cidOrEmailList: string[], itemId: string, masterItemId: string, simulate: boolean, syllabusId?: string, isSupplier?: boolean, groupId?: string, inputRows?: string[], webinarUserRole?) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/import-persons-as-course-takers', {
      cidOrEmailList: cidOrEmailList,
      inputRows: inputRows,
      itemId: itemId,
      masterItemId: masterItemId,
      simulate: simulate,
      groupId: groupId,
      webinarUserRole: (webinarUserRole && webinarUserRole.code) || ''
    });
  }

  // Crea un nuovo documento amministrativo multimediale
  newAdministrativeDocument(uploadObj) {
    let formData = new FormData();
    formData.append('file', uploadObj.file.file.rawFile);
    if (uploadObj.editionItemId) {
      formData.append('editionItemId', uploadObj.editionItemId || '');
    }
    if (uploadObj.stageItemId) {
      formData.append('stageItemId', uploadObj.stageItemId || '');
    }
    formData.append('title', uploadObj.title || '');
    if (uploadObj.tagTitles && uploadObj.tagTitles.length) {
      uploadObj.tagTitles.forEach(tagTitle => {
        formData.append('tagTitles', tagTitle);
      });
    }
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-administrative-documents', formData, options);
    return this.http.request(req);
  }

  // Count della lista dei documenti amministrativi
  countAdministrativeDocuments(initiativeId: string, editionId: string, title?: string, tagTitles?: string) {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('initiativeId', initiativeId || null);
    if (editionId) {
      httpParams = httpParams.append('editionId', editionId);
    }
    if (title && title.length) {
      httpParams = httpParams.append('title', title || '');
    }
    if (tagTitles && tagTitles.length) {
      httpParams = httpParams.append('tagTitles', tagTitles);
    }
    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-administrative-documents', {
      params: httpParams
    });
  }

  // Get della lista dei documenti amministrativi
  getAdministrativeDocuments(fromRecord: string, numRecords: string, initiativeId: string, editionId: string, title?: string, tagTitles?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    httpParams = httpParams.append('initiativeId', initiativeId || null);
    if (editionId) {
      httpParams = httpParams.append('editionId', editionId);
    }
    httpParams = httpParams.append('title', title || '');
    if (tagTitles && tagTitles.length) {
      httpParams = httpParams.append('tagTitles', tagTitles);
    }
    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-administrative-documents', {
      params: httpParams
    });
  }

  // Elimina un documento amministrativo
  deleteAdministrativeDocument(itemId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-administrative-document', {
      params: httpParams
    });
  }

  // Aggiorna un documento amministrativo
  updateAdministrativeDocument(itemId: string, title: string, tagTitles: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    httpParams = httpParams.append('title', title);
    if (tagTitles && tagTitles.length) {
      tagTitles.forEach(tagTitle => {
        httpParams = httpParams.append('tagTitles', tagTitle);
      });
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/update-administrative-document', {
      params: httpParams
    });
  }

  // Crea un nuovo oggetto multimediale
  newMaterialCourse(uploadObj) {
    let formData = new FormData();
    formData.append('file', uploadObj.file.file.rawFile);
    formData.append('fileName', uploadObj.fileName || '');
    formData.append('itemType', uploadObj.itemType || '');
    formData.append('title', uploadObj.title || '');
    formData.append('fileType', uploadObj.fileType);
    // Il parentId è da usare quanto di vuole allegare il DAM_ITEM creato come itemChild al parent Item
    formData.append('parentId', uploadObj.itemId || '');
    formData.append('objectType', uploadObj.objectType || '');
    formData.append('materialType', uploadObj.materialType);
    formData.append('draftOnly', uploadObj.draftOnly);
    if (uploadObj.useCoreUpload) {
      formData.append('useCoreUpload', 'true');
    }
    formData.append('originApplicationName', uploadObj.originApplicationName || '');
    formData.append('adminMode', uploadObj.adminMode);
    // Per risolvere i problemi legati ai materiali caricati nell'iniziativa che non vengono visualizzati dal taker, se questo è aggiunto solo a livello di edizione,
    // abbiamo deciso di aggiungere la visibilità pubblica, in questo modo se un utente è aggiunto ad un'edizione, è in grado di vedere tutti i materiali, anche quelli dell'iniziativa
    // Le visibilità degli oggetti aggiunti all'edizione è già gestita dalla funzione che gestisce e propaga l'upward visibility, che però è verticale e quindi non viene propagata
    // a figli e fratelli del parent, volutamente, altrimenti es delle iniziative legate allo stesso syllabus sarebbero tutte visibili ad un utente iscritto alla sola edizione. 
    // Quindi, solo nel caso in cui stia caricando un materiale a livello di iniziativa, allora devo aggiungere la visibilità pubblica e non indicizzare l'oggetto.
    formData.append('addPublicVisibility', uploadObj.addPublicVisibility || 'false');
    // l'oggetto non viene indicizzato perchè non viene mai cercato in nessun ambiente
    formData.append('indexItem', uploadObj.indexItem || 'true');
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-multimedia-item', formData, options);
    return this.http.request(req);
  }

  // Esegue l'aggiornamento di un materiale
  updateMaterial(uploadObj) {
    let formData = new FormData();
    formData.append('file', uploadObj.file && uploadObj.file.file && uploadObj.file.file.rawFile);
    formData.append('fileName', uploadObj.fileName);
    formData.append('itemType', uploadObj.itemType);
    formData.append('title', uploadObj.title);
    formData.append('fileType', uploadObj.fileType);
    formData.append('objectType', uploadObj.objectType);
    formData.append('materialType', uploadObj.materialType);
    formData.append('parentId', uploadObj.parentId);
    formData.append('itemId', uploadObj.itemId);
    formData.append('useCoreUpload', uploadObj.useCoreUpload);
    let params = new HttpParams();
    const options = {
      params: params
    };
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/update-multimedia-item',
      formData, options
    );
    /* const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/update-multimedia-item', formData, options);
    return this.http.request(req); */
  }

  // Servizio che importa una lista di persone da un item ad un assessment
  copyTakersToItem(sourceItemId: string, destinationItemId: string, sourceTakerStatus?: string[]) {
    return this.http.post<SenecaResponse<ImportPersonsAsTakersResponse>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/copy-takers-to-item', {
      sourceItemId: sourceItemId,
      destinationItemId: destinationItemId,
      sourceTakerStatus: sourceTakerStatus
    });
  }

  // Servizio che elimina la richiesta di proposta
  revokeProposalRequest(supplierTaskId: string, notifySupplier?: boolean) {
    return this.http.post<SenecaResponse<SupplierTask>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/revoke-proposal-request', {
      supplierTaskId: supplierTaskId,
      notifySupplier: notifySupplier
    });
  }

  // Modifica uno specifico attributo di un supplier Task
  changeAttributeOfSupplierProposal(supplierTaskId: string, attributeType: string, attributeValue?: string, attributeOrder?: string, removeAttribute?: boolean) {
    return this.http.post<SenecaResponse<SupplierTask>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/change-attribute-of-supplier-proposal', {
      supplierTaskId: supplierTaskId,
      attributeType: attributeType,
      attributeValue: attributeValue,
      attributeOrder: attributeOrder,
      removeAttribute: removeAttribute
    });
  }

  // Servizio che cancella una nota
  deleteSupplierProposalNote(noteId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-supplier-proposal-note/' + noteId, {
      noteId: noteId
    });
  }

  // Servizio che aggiunge una nuova nota ad un supplierTask
  createSupplierProposalNote(supplierTaskId: string, noteText: string) {
    return this.http.post<SenecaResponse<Note>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-supplier-proposal-note/' + supplierTaskId, {
      noteText: noteText
    });
  }

  // Servizio che crea un nuovo Syllabus
  createSupplierForFacultyMember(facultyMemberUserId: string) {
    return this.http.post<SenecaResponse<Supplier_new>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-supplier-for-faculty-member', {
      facultyMemberUserId: facultyMemberUserId
    });
  }

  // Servizio che aggancia l'offerta all'iniziativa
  assignProposal(initiativeId: string, noProposal?: boolean, courseModuleId?: string, proposalId?: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/assign-proposal', {
      courseModuleId: courseModuleId,
      proposalId: proposalId,
      initiativeId: initiativeId,
      noProposal: noProposal
    });
  }

  // Invia la proposta ad un fornitore scelto
  requestProposalToSupplier(initiativeId: string, supplierId: string, mailSenderId: string, signatureId: string, messageSubject: string, messageBody: string, expectedCloseDate: Date, mailCC: string, mailBCC: string, courseModuleId: string) {
    return this.http.post<SenecaResponse<SupplierTask>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/request-proposal-to-supplier', {
      initiativeId: initiativeId,
      supplierId: supplierId,
      mailSenderId: mailSenderId,
      signatureId: signatureId,
      messageSubject: messageSubject,
      messageBody: messageBody,
      expectedCloseDate: expectedCloseDate,
      mailCC: mailCC,
      mailBCC: mailBCC,
      courseModuleId: courseModuleId
    });
  }

  // Cambia lo stato di un taker di una edizione (con possibilità di inviare anche la mail)
  changeTakersEnrollStatus(newStatus: string, takerIds: string[], syllabusItemId: string,
    stageItemId: string, editionItemId: string | string[],
    mailSenderId: string, signatureId: string, mailCC: string, mailBCC: string, messageBodyAddOn: string,
    textTemplateId: string, testOnly: boolean, sendEmails: boolean, isSupplier?: boolean) {
    return this.http.post<SenecaResponse<SupplierTask>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/change-course-takers-enroll-status', {
      newStatus: newStatus,
      takerIds: takerIds,
      stageItemId: stageItemId,
      editionItemId: editionItemId,
      syllabusItemId: syllabusItemId,
      mailSenderId: mailSenderId,
      signatureId: signatureId,
      messageBodyAddOn: messageBodyAddOn,
      mailCC: mailCC,
      mailBCC: mailBCC,
      sendEmails: sendEmails,
      textTemplateId: textTemplateId,
      testOnly: testOnly
    });
  }

  availabilityCheckVPL(stageItemId: string, days: string[], userIds: string[], dayIdToIgnore: string[]) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/search-concurrent-training-courses-for-takers', {
      stageItemId: stageItemId,
      days: days,
      userIds: userIds,
      dayIdToIgnore: dayIdToIgnore
    });
  }


  availabilityCheckVPLUpdateEdition(stageItemId: string, days: string[], userIds: string[], dayIdToIgnore: string[], checkForUpdateEdition: boolean) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/search-concurrent-training-courses-for-takers', {
      stageItemId: stageItemId,
      days: days,
      userIds: userIds,
      dayIdToIgnore: dayIdToIgnore,
      checkForUpdateEdition: checkForUpdateEdition
    });
  }

  // Importa i taker nelle iniziative a partire da un oggetto/stage blended
  importTakersFromBlendedParentItem(stageId: string, parentIds, importAllUsers: boolean) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/import-takers-from-blended-parent-item', {
      stageId: stageId,
      parentId: parentIds,
      importAllUsers: importAllUsers
    });
  }

  // Servizio che recupera una lista di utenti dati i loro userId
  getUserByUserIds(userIds: string[]) {
    return this.http.post<SenecaResponse<User[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/users-by-userIds', {
      userIds: userIds
    });
  }

  // Servizio che crea una nuova iniziativa (corso)
  createStage(stage: Item, pathItemId?: string, askForApproval?: boolean) {
    return this.http.post<SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-stage', {
      pathItemId: pathItemId,
      stage: stage,
      askForApproval: askForApproval
    });
  }

  // Servizio che crea una nuova iniziativa (percorso)
  createPath(path: Item, askForApproval?: boolean) {
    return this.http.post<SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-path', {
      path: path,
      askForApproval: askForApproval
    });
  }

  // Servizio che aggiorna una iniziativa (percorso)
  updatePath(path: Item, askForApproval?: boolean) {
    return this.http.post<SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/update-path', {
      path: path,
      askForApproval: askForApproval
    });
  }

  // Servizio che assegna una tappa ad un percorso
  assignStageToPath(stageItemId: string, pathItemId: string) {
    return this.http.post<SenecaResponse<ExtendedItem>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/assign-stage-to-path', {
      stageItemId: stageItemId,
      pathItemId: pathItemId
    });
  }

  // Servizio che rimuove una tappa da un percorso
  removeStageFromPath(stageItemId: string, pathItemId: string) {
    return this.http.post<SenecaResponse<ExtendedItem>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/remove-stage-from-path', {
      stageItemId: stageItemId,
      pathItemId: pathItemId
    });
  }

  // Servizio che cancella i taker
  deleteCourseTakers(takerIds: string[]) {
    return this.http.post<SenecaResponse<ExtendedItem>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-course-takers', {
      takerIds: takerIds
    });
  }

  // Servizio che cancella alcuni taker da una specifica edizione
  deleteTakerEnrolls(takerEnrollIds: string[]) {
    return this.http.post<SenecaResponse<ExtendedItem>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-taker-enrolls', {
      takerEnrollIds: takerEnrollIds
    });
  }

  // Servizio che aggiorna una iniziativa (corso)
  updateStage(stage: Item, itemAttributeTypesToResolve?: string[], withFullChildrenAttributesLoaded?: boolean, withFullAttributesLoaded?: boolean, askForApproval?: boolean, forceStatusChange?: boolean) {
    return this.http.post<SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/update-stage', {
      stage: stage,
      withFullChildrenAttributesLoaded: withFullChildrenAttributesLoaded,
      withFullAttributesLoaded: withFullAttributesLoaded,
      itemAttributeTypesToResolve,
      askForApproval: askForApproval,
      forceStatusChange: forceStatusChange
    });
  }

  // Servizio che aggiorna un Syllabus
  updateSyllabus(syllabus: Item) {
    return this.http.post<SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/update-syllabus', {
      syllabus: syllabus
    });
  }

  // Servizio che recupera il conteggio dei taker
  countCourseTakers(stageItemId: string, editionItemIds: string[], groupByItemId?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('stageItemId', stageItemId || '');
    if (editionItemIds && editionItemIds.length) {
      editionItemIds.forEach(editionItemIds => {
        httpParams = httpParams.append('editionItemIds', editionItemIds);
      });
    }
    httpParams = groupByItemId ? httpParams.append('groupByItemId', 'true') : httpParams;

    return this.http.get<SenecaResponse<MapById<number>>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-course-takers', {
      params: httpParams
    });
  }

  // Servizio che recupera il conteggio dei syllabus disponibili
  countAvailableSyllabuses(title?: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('title', title || '');
    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-available-syllabuses', {
      params: httpParams
    });
  }

  // Servizio che recupera i dati sulle tappe di un percorso
  getStageAnalytics(pathItemId: string, stageItemId?: string[]) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('pathItemId', pathItemId);
    if (stageItemId && stageItemId.length) {
      httpParams = httpParams.append('stageItemId',
        JSON.stringify(stageItemId));
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-stage-analytics', {
      params: httpParams
    });
  }

  // Load a list of Tags from their parent ids, but with a flat structure
  getFlatTagsByParentIds(tagIds?: string[]) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    if (tagIds && tagIds.length) {
      tagIds.forEach(tagIds => {
        httpParams = httpParams.append('tagIds', tagIds);
      });
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-flat-tags-by-parent-ids', {
      params: httpParams
    });
  }

  // Recupera una lista di taker
  listCourseTakers(stageItemId: string, editionItemIds: string[], allData?: boolean, fromRecord?: number, numRecords?: string, searchedText?: string, searchedUserIds?: string[]): any {
    let from = fromRecord;
    let to = numRecords;
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', from && from.toString());
    httpParams = httpParams.append('numRecords', to && to.toString());
    httpParams = httpParams.append('allData', allData && allData.toString());
    // httpParams = !!withAllStatusesLoaded ? httpParams.append('withAllStatusesLoaded', 'true') : httpParams;
    httpParams = httpParams.append('stageItemId', stageItemId || '');
    if (editionItemIds && editionItemIds.length) {
      editionItemIds.forEach(editionItemIds => {
        httpParams = httpParams.append('editionItemIds', editionItemIds);
      });
    }

    httpParams = httpParams.append('searchedText', searchedText || '');

    if (searchedUserIds && searchedUserIds.length) {
      searchedUserIds.forEach(id => {
        httpParams = httpParams.append('searchedUserIds', id);
      });
    }

    return this.http.get<SenecaResponse<TextTemplate[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-course-takers/' + from + '/' + to, {
      params: httpParams
    });
  }

  // Recupera una lista di syllabus disponibili per l'aggiunta
  listAvailableSyllabuses(title?: string, allData?: boolean, fromRecord?: number, numRecords?: number): any {
    let from = fromRecord;
    let to = numRecords;
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', from && from.toString());
    httpParams = httpParams.append('numRecords', to && to.toString());
    httpParams = httpParams.append('allData', allData && allData.toString());
    httpParams = httpParams.append('title', title || '');
    httpParams = httpParams.append('withFullAttributesLoaded', 'true');

    httpParams = httpParams.append('itemAttributeTypesToResolve',
      JSON.stringify([
        'TITLE', 'SUBTITLE',
        ItemAttributeTypes.ARGUMENTS,
        ItemAttributeTypes.AIMS, ItemAttributeTypes.AGENDA,
        ItemAttributeTypes.DURATION, ItemAttributeTypes.PRICE, ItemAttributeTypes.PREREQS,
        ItemAttributeTypes.APPROACH, ItemAttributeTypes.PARTNER,
        ItemAttributeTypes.TECH_COMPETENCES, ItemAttributeTypes.SOFT_COMPETENCES,
        ItemAttributeTypes.TUTOR_CONTACT,
        ItemAttributeTypes.TEACHER_CONTACT,
        ItemAttributeTypes.SUPPORT_CONTACT,
        ItemAttributeTypes.CUSTOM_ARGUMENT,
        ItemAttributeTypes.SYLLABUS_MODEL]));
    return this.http.get<SenecaResponse<Item[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-available-syllabuses', {
      params: httpParams
    });
  }

  // Reinvia la mail di invito
  reenqueueInviteMail(mailQueueId: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('mailQueueId', mailQueueId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/reenqueue-invite-mail/' + mailQueueId, {
      params: httpParams
    });
  }

  // Recupera una lista di id dei taker non ancora invitati
  listNotInvitedTakers(stageItemId: string, editionItemIds: string[]): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('stageItemId', stageItemId || '');
    if (editionItemIds && editionItemIds.length) {
      editionItemIds.forEach(editionItemIds => {
        httpParams = httpParams.append('editionItemIds', editionItemIds);
      });
    }
    return this.http.get<SenecaResponse<string[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-not-invited-takers', {
      params: httpParams
    });
  }

  // Servizio che salve una iniziativa
  saveInitiative(initiative: any) {
    return this.http.get<any>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-text-template-list/');
  }

  // Servizio che crea una nuova edizione
  createCourseModule(courseModule: Item) {
    return this.http.post<SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-course-module', {
      courseModule: courseModule
    });
  }

  // Servizio che manda una mail con l'export contente gli utenti
  exportUsersToTrainInCurrentYearForSupplier(syllabusId: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('syllabusId', syllabusId || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/export-users-to-train-in-current-year-for-supplier', {
      params: httpParams
    });
  }

  exportStageAutoInvitationPreview(stageId) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('stageId', stageId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/export-stage-auto-invitation-preview', {
      params: httpParams
    });
  }

  // Servizio che invia una mail di invito
  inviteTakers(syllabusItemId: string, stageItemId: string, takerIds: string[], mailSenderId: string, mailCC: string, mailBCC: string, signatureId: string, messageBodyAddOn: string, textTemplateId: string, testOnly: boolean) {
    return this.http.post<SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/invite-takers', {
      syllabusItemId: syllabusItemId,
      stageItemId: stageItemId,
      takerIds: takerIds,
      mailSenderId: mailSenderId,
      mailCC: mailCC,
      mailBCC: mailBCC,
      signatureId: signatureId,
      messageBodyAddOn: messageBodyAddOn,
      textTemplateId: textTemplateId,
      testOnly: testOnly
    });
  }

  recalcUsersAwardsByCourse(itemId: string, startDate: string, creditType: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/recalc-users-awards-by-course', {
      stageId: itemId,
      startDate: startDate,
      creditType: creditType
    });
  }

  selectInitiative(initiativeId: string) {
    return this.store.dispatch(new InitiativeActions.SelectInitiative(initiativeId));
  }

  deleteSelectInitiative() {
    return this.store.dispatch(new InitiativeActions.DeleteSelectedInitativeData());
  }

  getSelectedInitiative() {
    return this.store.select(fromApp.getSelectedInitiativeItem).pipe(
      // emetto un valore soltanto esiste l'iniziativa selezionata è nello store
      filter((selectedInitiative) => {
        return !!selectedInitiative;
      })
    );
  }

  updateStoreInitiative(initiative: any): any {
    this.store.dispatch(new InitiativeActions.UpdateInitiative({ index: 0, updatedInitiative: initiative }));
  }

  getCurrenWizardStep() {
    return this.store.select(fromApp.getWizardStepOfSelectedInitiative);
  }

  setCurrentWizardStep(stepId: string) {
    this.store.dispatch(new InitiativeActions.SetCurrentWizardStep(stepId));
  }

  closeInitiative(stageItemId): any {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/close-initiative-for-admin', {
      stageItemId: stageItemId,
      markAbsentTakers: true,
      statusList: [ItemTakerEnrollStatusTypes.USER_STATUS_INVITED, ItemTakerEnrollStatusTypes.USER_STATUS_CONFIRMED]
    });
  }

  listProposalSupplierForInitiative(taskId: string, supplierId: string, fromRecord: number, numRecords: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('parentSupplierTaskId', taskId);
    httpParams = httpParams.append('supplierId', supplierId);
    httpParams = httpParams.append('allData', 'true');

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-supplier-proposals-by-parent/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  getCertificatesTemplateList(): any {
    let httpParams = new HttpParams();

    return this.http.get<SenecaResponse<CertificateList[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-certificates-by-tenant', {
      params: httpParams
    });
  }

  listExistingGroupedReportTagAttributes(allData: string, fromRecord?: string, numRecord?: string, searchedText?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('allData', allData);
    httpParams = httpParams.append('fromRecord', fromRecord || '0');
    if (numRecord) {
      httpParams = httpParams.append('numRecords', numRecord);
    }
    if (searchedText) {
      httpParams = httpParams.append('searchedText', searchedText);
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-existing-grouped-administrative-tag-attributes/', {
      params: httpParams
    });
  }

  getPreiscritionWorkflow(fromRecord?: number, numRecord?: string, searchedText?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString() || '0');
    if (numRecord) {
      httpParams = httpParams.append('numRecords', numRecord);
    }
    if (searchedText) {
      httpParams = httpParams.append('textFilter', searchedText);
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-preregistration-workflow-models/', {
      params: httpParams
    });
  }

  getOnlyUsableTags() {
    return this.http.get<SenecaResponse<string[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-all-searchable-tagtypes', {});
  }

  getAllCompetences(allData, fromRecord?, numRecords?) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('allData', allData);
    if (fromRecord) {
      httpParams = httpParams.append('fromRecord', fromRecord);
    }
    if (numRecords) {
      httpParams = httpParams.append('numRecords', numRecords);
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-competence-award-names', {
      params: httpParams
    });
  }

  getLevels(competenceId) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('competenceAwardId', competenceId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-competence-scale-levels-by-award-id', {
      params: httpParams
    });
  }


  listDefaultSurveyTemplate(attributeSurveyTypes: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('attributeSurveyTypes', attributeSurveyTypes);
    return this.http.get<SenecaResponse<Upload[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-default-survey-template-by-survey-type', {
      params: httpParams
    });
  }

  addSurveyRandomGroupQuestionsLimit(surveyId: string, groupsQuestionLimit: any) {
    return this.http.post<SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/add-or-update-survey-random-group-questions-limit', {
      surveyId: surveyId,
      groupsQuestionLimit: groupsQuestionLimit
    });
  }

  uploadDefaultSurveyTemplate(referenceId: string, referenceType: string, surveyType: string, useCustomGroups: boolean, isSurveyNotRepeatable: boolean, attributeSurveyTypes: string, isSurveyCompletableMultipleTimes?: boolean) {
    return this.http.post<any>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/upload-default-survey-template-by-survey-type',
      {
        referenceId: referenceId,
        referenceType: referenceType,
        surveyType: surveyType,
        useCustomGroups: useCustomGroups,
        isSurveyNotRepeatable: isSurveyNotRepeatable,
        attributeSurveyTypes: attributeSurveyTypes,
        isSurveyCompletableMultipleTimes: isSurveyCompletableMultipleTimes
      });
  }

  getDownloadSurveyUrl(templateType: string, ssorqtp: string) {
    return this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/download-survey-template-file?templateType=' + templateType + '&ssortkqp=' + ssorqtp;
  }

  usePresetTemplate(referenceId: string, referenceType: string, surveyType: string, useCustomGroups: boolean, isSurveyNotRepeatable: boolean, attributeTemplateClassName: string, isSurveyCompletableMultipleTimes?: boolean) {
    return this.http.post<any>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/upload-preset-survey-template-template-name',
      {
        referenceId: referenceId,
        referenceType: referenceType,
        surveyType: surveyType,
        useCustomGroups: useCustomGroups,
        isSurveyNotRepeatable: isSurveyNotRepeatable,
        attributeTemplateClassName: attributeTemplateClassName,
        isSurveyCompletableMultipleTimes: isSurveyCompletableMultipleTimes
      });
  }

  sendManualPresenceRegisteredMail(editionItemIds: string) {
    return this.http.post<any>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/send-manual-presence-registered-mail-for-online-courses',
      {
        editionItemIds: editionItemIds
      });
  }

  listDistinctUsersField(fromRecord: number, numRecords: number, searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('includeNull', 'false');
    httpParams = httpParams.append('includeEmpty', 'false');
    httpParams = httpParams.append('distinctField', 'nomeLivelloGerarchico');
    httpParams = httpParams.append('usingKeyField', 'codiceLivelloGerarchico');
    httpParams = httpParams.append('textFilter', searchedText);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-distinct-users-field/', {
      params: httpParams
    });
  }

  countDistinctUsersField(searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('includeNull', 'false');
    httpParams = httpParams.append('includeEmpty', 'false');
    httpParams = httpParams.append('distinctField', 'nomeLivelloGerarchico');
    httpParams = httpParams.append('usingKeyField', 'codiceLivelloGerarchico');
    httpParams = httpParams.append('textFilter', searchedText);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-distinct-users-field/', {
      params: httpParams
    });
  }

}
