<div class="item-details-content">
  <div class="bg-item" fxFill>
    <div class="itemDetails" fxLayout="column">
      <div class="content min">
        <!-- Breadcrumbs -->
        <div class="padding-top45 padding-bottom15 more-z-index" fxLayout="row" layout-xs="column">
          <div fxLayout="row" fxLayoutAlign="start start">
            <ol class="breadcrumb-alloy text-uppercase">
              <li>
                <a (click)="goToHome()"
                  translate="routing.HOME"></a>
              </li>
              <li>
                <a class="current" translate="header.PROFILE"></a>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="more-z-index" fxLayout="row" fxFill>
        <div [ngStyle]="{ 'background-image': avatar ? 'url(' + avatar + ')' : '' }" class="avatar-profile-detail">
        </div>
      </div>

      <div *ngIf="loggedUser" class="more-z-index" fxLayout="column" fxFill fxLayoutAlign="center center">
        <h3 class="margin-top15">{{ loggedUser.user.forename + ' ' + loggedUser.user.surname }}</h3>
        <p class="margin0">{{ loggedUser.user.email }}</p>
        <p class="text-uppercase">{{ loggedUser.user.cid }}</p>
        <lib-alloy-button *ngIf="!uploadAvatarVisible" importance="secondary" noMargin="true"
          (click)="expandUploadAvatar()" dimension="btn-small" text="{{'profile.UPLOAD_AVATAR' | translate}}"
          attr.aria-label="{{'profile.UPLOAD_AVATAR' | translate}}">
        </lib-alloy-button>
      </div>
    </div>
  </div>
  <div class="itemDetails" fxLayout="column">

    <!-- Scelta/ritaglio dell'immagine-->
    <div *ngIf="uploadAvatarVisible" class="margin-top35 margin-bottom35" fxLayout="row" fxFill>

      <div fxLayout="column" class="item-list" fxLayoutAlign="center center" fxFill>
        <div *ngIf="avatarImg && !showCropper" class="upload-new-file-form" fxLayout="column"
          fxLayoutAlign="center center" fxFill>
          <div fxLayout="column" fxLayoutAlign="center center" fxFill class="generali-input small skill-select">
            <input (change)="fileChangeEvent($event)" class="supplier-input-file margin-bottom15" name="new-avatar-file"
              id="new-avatar-file" type="file" ng2FileSelect [uploader]="avatarImg.uploader" required />
            <!--Nome del file aggiunto-->
            <label for="new-avatar-file" class="selected-file-name-preview full-width">{{
              avatarImg
              &&
              avatarImg.file && avatarImg.file && avatarImg.file.file &&
              avatarImg.file.file.name || ( 'generic.SELECT_FILE' |
              translate ) }}</label>
            <!-- Errore del file scelto -->
            <div *ngIf="avatarImgErrorText" class="upload-image-error margin-top10">
              <span>{{ avatarImgErrorText }}</span>
            </div>
            <label for="new-avatar-file" class="new-proposal-file-label margin-top10">
              <span translate="generic.FIND_FILE_IN_DIRECTORY"></span>
            </label>
          </div>
        </div>

        <!-- Rotazione immagine -->
        <div *ngIf="avatarImg && avatarImg.file" fxLayout="row">
          <lib-alloy-button importance="secondary" (click)="rotateLeft()" dimension="btn-small"
            text="{{'generic.ROTATE_LEFT' | translate}}" attr.aria-label="{{'generic.ROTATE_LEFT' | translate}}">
          </lib-alloy-button>
          <lib-alloy-button importance="secondary" (click)="rotateRight()" dimension="btn-small"
            text="{{'generic.ROTATE_RIGHT' | translate}}" attr.aria-label="{{'generic.ROTATE_RIGHT' | translate}}">
          </lib-alloy-button>
          <lib-alloy-button importance="secondary" (click)="flipHorizontal()" dimension="btn-small"
            text="{{'generic.FLIP_HORIZONTAL' | translate}}"
            attr.aria-label="{{'generic.FLIP_HORIZONTAL' | translate}}">
          </lib-alloy-button>
          <lib-alloy-button importance="secondary" (click)="flipVertical()" dimension="btn-small"
            text="{{'generic.FLIP_VERTICAL' | translate}}" attr.aria-label="{{'generic.FLIP_VERTICAL' | translate}}">
          </lib-alloy-button>
        </div>

        <div *ngIf="avatarImg && avatarImg.file" fxLayout="column">
          <div class="cropArea">
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [resizeToWidth]="128"
              [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="true" format="png" outputType="base64"
              (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"
              [style.display]="showCropper ? null : 'none'" [alignImage]="'left'"></image-cropper>
          </div>
          <div class="space"></div>
          <p class="bold-text text-primary-1 margin-right-10 text-uppercase center">
            <span translate="generic.PREVIEW"></span>
          </p>
          <div class="center">
            <img class="avatar-profile-detail" [src]="croppedImage" />
          </div>
        </div>
        <div class="space25"></div>
        <div class="content" fxLayout="row" fxLayoutAlign="space-between" fxFill>
          <lib-alloy-button importance="bordless" (click)="expandUploadAvatar()" dimension="btn-small"
            text="{{'generic.CANCEL' | translate}}" attr.aria-label="{{'generic.CANCEL' | translate}}">
          </lib-alloy-button>
          <lib-alloy-button importance="secondary" (click)="expandUploadAvatar(true)" [disabled]="!avatarImg.file"
            dimension="btn-small" text="{{'generic.SAVE' | translate}}"
            attr.aria-label="{{'generic.SAVE' | translate}}">
          </lib-alloy-button>
        </div>
      </div>
    </div>

    <div class="al-divider" *ngIf="false"></div>

    <!-- Crediti formativi -->
    <div *ngIf="!authService.isTeacherMode() && false" fxLayout="column" fxFlexAlign="start start">
      <h3 class="funding-title" translate="generic.YOUR_CREDITS"></h3>
      <div class="custom-select">

        <!-- Crediti -->
        <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayout="space-around center">
          <div fxFlex class="credit-wrapper" fxLayout="row" fxLayout="center center">
            <div fxLayout="column" fxLayoutAlign="center start">
              <h4><strong>IVASS</strong></h4>
              <h4>{{ ivassCredit }}/
                <span><strong>{{ currentBienniumRequiredCredits }}</strong>{{ ' ' +
                  ('generic.IVASS_OBTAINED_IN' | translate ) + ' ' + currentYear }}</span>
              </h4>
            </div>
          </div>
          <div fxFlex class="credit-wrapper" fxLayout="row" fxLayout="center center">
            <div fxLayout="column" fxLayoutAlign="center start">
              <h4><strong>MiFID II</strong></h4>
              <h4>{{ mifidCredit }}<span><strong>/30</strong>{{ ' ' + ('generic.MIFID_ACCUMULTAED_IN' |
                  translate ) + ' ' + currentYear }}</span>
              </h4>
            </div>
          </div>
          <div fxFlex class="credit-wrapper" fxLayout="row" fxLayout="center center">
            <div fxLayout="column" fxLayoutAlign="center start">
              <h4><strong>EFPA</strong></h4>
              <h4>{{ efaaCredit + efabCredit }}
                <span>{{ ' ' + ('generic.EFPA_OBTAINED_IN' | translate ) + ' ' + currentYear }}</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!authService.isTeacherMode()" class="al-divider"></div>

    <!-- Lingua piattaforma -->
    <div fxLayout="column">
      <h3 class="funding-title" translate="generic.SETTINGS"></h3>
      <lib-alloy-select [bindModel]="selectedLang" (onSelectionChanged)="newLangSelected($event)"
        [items]="availableLangs" [disabled]="loaderActive" [bindLabel]="'langDesc'"
        [parameterNameOfSelectedItem]="'langDesc'" formTitle="{{ 'generic.APP_LANG' | translate }}"
        placeholderLabel="{{ 'generic.SELECT_APP_LANG' | translate }}"
        startSearchLabel="{{ 'generic.ngSelect.START_SEARCH' | translate }}"
        noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
        loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="regular-dimension"
        [virtualScroll]="false" [multiple]="false" attrAriaLabel="{{ 'generic.SELECT_APP_LANG' | translate }}"
        #availableLangsNgSelect>
      </lib-alloy-select>
    </div>

    <div class="space40"></div>

    <!-- Timezone -->
    <div *ngIf="timezoneList && timezoneList.length" fxLayout="column">
      <lib-alloy-select [bindModel]="userTimezone" (onSelectionChanged)="newTimezoneSelected($event)"
        [items]="timezoneList" [disabled]="loaderActive" formTitle="{{ 'generic.TIMEZONE' | translate }}"
        placeholderLabel="{{ 'generic.SELECT_TIMEZONE' | translate }}"
        startSearchLabel="{{ 'generic.ngSelect.START_SEARCH' | translate }}"
        noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
        loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="small-dimension"
        [virtualScroll]="false" [multiple]="false" attrAriaLabel="{{ 'generic.SELECT_TIMEZONE' | translate }}">
      </lib-alloy-select>
    </div>

    <div class="al-divider"></div>

    <!-- Cambio password -->
     <!--Cambio password non disponibile per i dipendenti di banca -->
    <div *ngIf="isExternal || !isInternalBDI" class="item-list cursor-default" fxLayout="column" fxFlexAlign="start start">
      <h3 class="funding-title" translate="generic.CHANGE_PASSWORD"></h3>
      <div class="contact-new-supplier-sidebar custom-select generali-input small skill-select">
        <!-- Password attuale -->
        <lib-alloy-input id="oldPassword" [type]="'password'" [bindValue]="oldPassword"
          (onModelChanged)="onOldPasswordChanged($event)" [attrName]="'oldPassword'" [required]="oldPassword"
          [placeholder]="'generic.TYPE_PASSWORD' | translate" name="oldPassword"
          [attrAriaLabel]="'generic.TYPE_PASSWORD' | translate" inputContainerClass="small-dimension"
          errorRequiredText="{{ 'errors.forms.generic.ERROR_FIELD_REQUIRED' | translate }}" [required]="true"
          title="{{ 'generic.OLD_PSW' | translate }}" autocompleteText="old-password"></lib-alloy-input>
        <div class="space30"></div>
        <!-- Nuova password -->
        <lib-alloy-input id="newPassword" [type]="'password'" [bindValue]="newPassword" [pattern]="passwordPattern"
          (onModelChanged)="onNewPasswordChanged($event)" [attrName]="'newPassword'" [required]="newPassword"
          [placeholder]="'generic.TYPE_PASSWORD' | translate"
          errorPatternText="{{ 'login.errors.PATTERN' | translate }}"
          [attrAriaLabel]="'generic.TYPE_PASSWORD' | translate" inputContainerClass="small-dimension"
          errorRequiredText="{{ 'errors.forms.generic.ERROR_FIELD_REQUIRED' | translate }}" [required]="true"
          title="{{ 'generic.NEW_PSW' | translate }}" autocompleteText="new-password"></lib-alloy-input>
        <div class="space30"></div>
        <!-- Conferma nuova password -->
        <lib-alloy-input id="confirmPassword" [type]="'password'" [bindValue]="confirmPassword"
          [pattern]="passwordPattern" (onModelChanged)="onConfirmPasswordChanged($event)" [attrName]="'confirmPassword'"
          [required]="confirmPassword" [placeholder]="'generic.TYPE_PASSWORD' | translate"
          errorPatternText="{{ 'login.errors.PATTERN' | translate }}"
          [attrAriaLabel]="'generic.TYPE_PASSWORD' | translate" inputContainerClass="small-dimension"
          errorRequiredText="{{ 'errors.forms.generic.ERROR_FIELD_REQUIRED' | translate }}" [required]="true"
          title="{{ 'generic.CONFIRM_PSW' | translate }}" autocompleteText="renew-password">
        </lib-alloy-input>
        <div class="space30"></div>
        <!-- Conferma cambio password -->
        <lib-alloy-button importance="secondary" [disabled]="!arePswMatchingPattern() || !oldPassword || !newPassword || !confirmPassword ||
                (newPassword && confirmPassword && newPassword !== confirmPassword)" dimension="btn-regular"
          text="{{ 'generic.CONFIRM_NEW_PSW' | translate }}" (onButtonClicked)="submitNewPassword()">
        </lib-alloy-button>
      </div>
    </div>

    <div class="al-divider"></div>

  </div>
</div>
