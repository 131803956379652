/*
*  Reducer che gestiscono e modificano lo State applicativo riguardanti i gruppi
*/

import * as NewsActions from './news.actions';
import * as fromApp from '../../ngrx/app.reducers';
import { Announcement } from 'atfcore-commonclasses';


// I gruppi sono una feature dell'applicazione, infatti è un modulo caricato in maniera lazy (e quindi anche lo Store).
// Creo un'interfaccia, FeatureState, in cui mappo i gruppi
// Estendo fromApp.AppState così per ereditare tutte le proprietà dello Store principale
export interface FeatureState extends fromApp.AppState {
  news: NewsState
}

// Interfaccia dello Store
export interface NewsState {
  news: Announcement[];
  count: number;
  fromRecord: number;
  numRecords: number;
  page: number;
  isFetchingNews: boolean;
  newsTextFilter: string;
  sortingDir: string;
  sortingCol: string;
  unreadCount: number;
  totalCount: number;
  oldUnread: string[];
}

// Store iniziale
const initialNewsState: NewsState = {
  news: [],
  count: 0, // contatore totale delle news
  fromRecord: 0, // numero da cui recuperare gli elementi
  numRecords: 20, // elementi da recuperare
  page: 1, // pagina della tabella visualizzata
  isFetchingNews: false, // verifica se sto eseguendo una chiamata
  newsTextFilter: null, // searchedText input
  sortingDir: 'DESC',
  sortingCol: 'startDate',
  unreadCount: 0,
  totalCount: null,
  oldUnread: []
};

export function NewsReducer(state = initialNewsState, action: NewsActions.NewsActions) {
  switch (action.type) {
    case NewsActions.SET_NEWS:
      return {
        ...state,
        isFetchingNews: false,
        news: [...action.payload]
      };
    case NewsActions.SET_PAGINATION_NEWS:
      return {
        ...state,
        fromRecord: action.payload.fromRecord,
        numRecords: action.payload.numRecords
      };
    case NewsActions.GET_NEWS:
      return {
        ...state,
        isFetchingNews: true
      };
    case NewsActions.SET_PAGE_NEWS:
      return {
        ...state,
        page: action.payload
      };
    case NewsActions.SET_NEWS_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    case NewsActions.SET_NEWS_TEXT_FILTER:
      return {
        ...state,
        newsTextFilter: action.payload
      };
    case NewsActions.SET_SORTING_DIR:
      return {
        ...state,
        sortingDir: action.payload
      };
    case NewsActions.SET_SORTING_COL:
      return {
        ...state,
        sortingCol: action.payload
      };
    case NewsActions.SET_NEWS_TOTAL:
      return {
        ...state,
        totalCount: action.payload
      }
    case NewsActions.SET_BANNER_UNREAD:
      return {
        ...state,
        unreadCount: action.payload
      }
    case NewsActions.SET_OLD_UNREAD:
      return {
        ...state,
        oldUnread: action.payload
      }
    default:
      return state;
  }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getNews = (state: NewsState) => (state && state.news) || [];
export const getNewsCount = (state: NewsState) => state.count || 0;
export const getNewsFromRecord = (state: NewsState) => (state && state.fromRecord) || 0;
export const getNewsNumRecords = (state: NewsState) => (state && state.numRecords) || 20;
export const getNewsPage = (state: NewsState) => (state && state.page) || 0;
export const isFetchingNews = (state: NewsState) => state.isFetchingNews || false;
export const getNewsTextFilter = (state: NewsState) => (state && state.newsTextFilter) || null;
export const getSortingDir = (state: NewsState) => (state && state.sortingDir) || 'DESC';
export const getSortingCol = (state: NewsState) => (state && state.sortingCol) || 'startDate'
export const getNewsTotal = (state: NewsState) => (state && state.totalCount) || 0;
export const getBannerUnreadCount = (state: NewsState) => (state && state.unreadCount) || 0;
export const getOldUnread = (state: NewsState) => (state && state.oldUnread) || [];
