<table role="grid" fxFlexFill class="theme-primary-table">
  <tbody>
    <tr>
      <th tabindex="-1" translate="generic.INITIATIVE"></th>
      <th tabindex="-1" translate="generic.CREATION_DATE"></th>
      <th tabindex="-1" translate="generic.TYPE"></th>
      <th tabindex="-1" translate="generic.STATUS"></th>
      <th tabindex="-1" translate="generic.ACTIONS"></th>
    </tr>
    <tr *ngFor="let item of lastInitiatives;index as i">
      <td tabindex="-1">{{ item.title }}</td>
      <td tabindex="-1">{{ item.creationDate | amLocale:'it' | amUtc | amDateFormat: dateFormat }}</td>
      <td tabindex="-1">{{ item.itemTypeLabel }}</td>
      <td tabindex="-1">{{ item.itemStatusLabel }}</td>
      <td class="relative-position accessibilityTable">
        <lib-alloy-button (click)="toggleMenu(i)" importance="bordless left-icon" dimension="btn-small"
          text="{{ 'generic.ACTIONS' | translate }}" icon="./assets/icons/icon-hamburger-menu.svg"
          attr.aria-controls="{{ 'menu'+ i }}" tabindex="-1" attr.aria-haspopup="true"
          attr.aria-label="{{ 'menu'+ i }}">
        </lib-alloy-button>

        <ul role="menu" #menu style="display:none">
          <li (click)="onPreview(item)" class="item" translate="generic.PREVIEW" role="menuitem"></li>
          <li (click)="onEdit(item)" class="item" translate="generic.EDIT" role="menuitem"></li>
          <li (click)="onDelete(item)" class="item" translate="generic.REMOVE" role="menuitem"></li>
        </ul>
      </td>
    </tr>
  </tbody>
</table>