/*
 * Servizio che gestisce le chiamate ai servizi relative ai Tag
*/
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';
import { Tag, Item, buildAutocompleteServiceKey, findCachedResult, storeCachedResult, SenecaResponse } from "../../../cm2-commonclasses";
import * as fromApp from '../../ngrx/app.reducers';
import { GlobalApplicationData } from '../../shared/models/global-application-data.model';
import { of } from 'rxjs';
import { LocationItem } from 'src/app/shared/models/course.model';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  applicationData: GlobalApplicationData;
  cacheMap: any;
  createFolderObj;

  constructor(private store: Store<fromApp.AppState>,
    private http: HttpClient) {
    // Recupero l'oggetto globale, così da avere il baseURL per le chiamate rest
    store.select(store => store.core.globalApplicationData)
      .subscribe(
        (value) => this.applicationData = value
      );
    this.cacheMap = {};
    this.createFolderObj = { tagId: "CREATE_FOLDER", tagType: "CREATE_FOLDER", icon: '../../../../assets/img/gear-Integrazioni_contrattuali@.svg', data: [], customIconClass: 'add-folder-card', customTitleClass: 'add-folder-card' };
  }

  getFoldersList(topmostOnly: string, allData: string, fromRecord: number, numRecords: number, type: string[], creationUserId: string, title?: string, description?: string, parentId?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('topmostOnly', topmostOnly);
    httpParams = httpParams.append('allData', allData);
    if (fromRecord) {
      httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    }
    if (numRecords) {
      httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    }
    if (type && type.length) {
      type.forEach(type => {
        httpParams = httpParams.append('type', type);
      });
    }
    httpParams = httpParams.append('creationUserId', creationUserId);
    if (title) {
      httpParams = httpParams.append('title', title);
    }
    if (description) {
      httpParams = httpParams.append('description', description);
    }
    if (parentId) {
      httpParams = httpParams.append('parentId', parentId);
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/search-tags`, {
      params: httpParams
    });

  }

  // Recupera una lista di Tag
  findTags(allData?: string, fromRecord?: string | number, numRecords?: string | number, type?: string, title?: string, useCache?: boolean) {
    const serviceCacheKey = buildAutocompleteServiceKey('findTags', false, fromRecord, numRecords, type, title, useCache);

    if (serviceCacheKey) {
      const cachedResult = findCachedResult(this.cacheMap, serviceCacheKey, title);
      if (cachedResult) {
        return of(cachedResult.response);
      }
    }

    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    httpParams = httpParams.append('allData', allData);
    httpParams = httpParams.append('type', type);
    httpParams = httpParams.append('title', title || '');
    return this.http.get<SenecaResponse<Tag[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/find-tags', {
      params: httpParams
    }).pipe(map((senecaResponse) => {
      if (serviceCacheKey && senecaResponse
        && !senecaResponse.error && senecaResponse.response) {
        storeCachedResult(this.cacheMap, serviceCacheKey, senecaResponse, title);
      }
      return senecaResponse;
    }));
  }

  // Servizio che recupera la lista dei template
  getAllLocations() {

    let httpParams = new HttpParams();
    // Limito temporaneamente i danni finché la situazione non viene corretta
    //httpParams = httpParams.append('allData', "true");
    httpParams = httpParams.append('fromRecord', "0");
    httpParams = httpParams.append('numRecords', "10");

    return this.http.get<SenecaResponse<Item[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-locations', {
      params: httpParams
    }).pipe(switchMap((senecaResponse: SenecaResponse<Item[]>) => {
      if (senecaResponse && senecaResponse.response && senecaResponse.response.length) {
        return of(new SenecaResponse(null, senecaResponse.response.map((item) => {
          if (item) {
            let locationItem = new LocationItem(item);
            return locationItem;
          }
          return item;
        })));
      }
      return of(senecaResponse);
    }));
  }

}
