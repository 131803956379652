/*
* Componente che crea la carde per la creazione di un'iniziativa Concept, che parte quindi da un syllabus esistente
*/

import { Component, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'new-concept-card',
  templateUrl: './new-concept-card.component.html',
  styleUrls: ['./new-concept-card.component.scss']
})

export class NewConceptCardComponent {
  @Input() selectedCreationPossibility: { options: number };
  @Output() toggledChange: EventEmitter<string> = new EventEmitter<string>();
  isLoaded: boolean;
  constructor() {
  }

  // Emette l'evento al componente padre, che cambia la selezione
  changeValue(newValue: string) {
    this.toggledChange.emit(newValue);
  }

  // Renderinig dell'immagine finito
  imgRendered() {
    this.isLoaded = true;
  }
}