<div class="footer-wrapper">
    
    <div class="content">
        <!-- <p fxLayout="row" fxLayoutAlign="end left"><a (click)="scrollToTop()">⌃ Torna su</a></p>  -->
        <div fxLayout="row" fxLayout.xs="column" class="text-wrapper-footer" fxLayoutAlign="start start" role="region">
            <div *ngIf="!isBancaBbp" class="margin-right25" fxFlex>
                <h2 attr.aria-label="{{ leftColumnTitle }}" tabindex="0">{{ leftColumnTitle }}</h2>
                <div>
                    <p class="box0">
                        <a attr.aria-label="{{ leftColumnFirstHint }}" target="_blank" href="{{ supportUrl1 }}">{{ leftColumnFirstHint }}</a>
                        <br><a attr.aria-label="{{ leftColumnSecondHint }}" target="_blank" href="{{ supportUrl2 }}">{{ leftColumnSecondHint }}</a>
                    </p>     
                </div>
                
                <div >
                    <p attr.aria-label="{{ supportUrlHint3 }}"><a target="_blank" href="{{ supportUrl3 }}">{{ supportUrlHint3 }}</a></p>    
                    <p attr.aria-label="{{ supportUrlHint4 }}"><a target="_blank" href="{{ supportUrl4 }}">{{ supportUrlHint4 }}</a></p>
                    <p attr.aria-label="{{ supportUrlHint5 }}"><a target="_blank" href="{{ supportUrl5 }}">{{ supportUrlHint5 }}</a></p>
                    <p attr.aria-label="{{ supportUrlHint6 }}"><a target="_blank" href="{{ supportUrl6 }}">{{ supportUrlHint6 }}</a></p>
                    <p attr.aria-label="{{ supportUrlHint7 }}"><a target="_blank" href="{{ supportUrl7 }}">{{ supportUrlHint7 }}</a></p>   
                </div>
            </div>

            
            
            <div *ngIf="!isBancaBbp" class="right-column margin-right25" fxFlex>
                <h2 attr.aria-label="{{ rightColumnTitle }}" tabindex="0">{{ rightColumnTitle }}</h2>
                <div>
                    <p *ngIf="!isPostepayFooter" attr.aria-label="{{ rightColumnFirstHint }}" tabindex="0">{{ rightColumnFirstHint }}</p>
                    <ul>
                        <li><p attr.aria-label="{{ mail }}{{ supportMail }}" tabindex="0">{{ mail }}<a target="_blank" href="mailto:{{ supportMail }}">{{ supportMail }}</a></p></li>    
                        <li><p attr.aria-label="{{ tSupport }}{{ supportUrlHint8 }}" tabindex="0">{{ tSupport }} <a target="_blank" href="{{ supportUrl8 }}">{{ supportUrlHint8 }}</a></p></li>
                        <li><p attr.aria-label="{{ supportUrlHint9 }}" tabindex="0" >{{ supportUrlHint9 }}</p></li>   
                    </ul> 
                </div>

                <div>
                    <p *ngIf="!isPostepayFooter" attr.aria-label="{{ rightColumnSecondHint }}" tabindex="0">{{ rightColumnSecondHint }}</p>
                    <ul>
                        <li><p attr.aria-label="{{ support1 }}{{ support1B }}" tabindex="0">{{ support1 }}<b>{{ support1B }}</b></p></li>    
                        <li><p attr.aria-label="{{ support2 }}" tabindex="0">{{ support2 }}</p></li>   
                    </ul> 
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showCompanyRow" class="bottom-footer" fxLayout="row" fxLayoutAlign="center center">
    <img [style.height.px]="companyLogoHeight" [title]="companyLogoTitle" [src]="companyLogoSrc"
        (click)="emitOnLogoClicked($event)">
</div>