/*
* Componente per una sezione di un learning plan
*/

import { Component } from "@angular/core"

@Component({
  selector: 'item-in-learning-plan-placeholder',
  templateUrl: './item-in-learning-plan-placeholder.component.html'
})
export class ItemInLearningPlanPlaceholderComponent {
  constructor() {
  }
}