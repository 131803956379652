<!-- Filtri attiviti e toggle -->
<div fxLayout="row" fxLayoutAlign="end center">
  <div fxLayout="column" fxLayoutAlign="center start" style="margin-right: 10px;">
    <p translate="dashboard.ACTIVE_FILTERS"></p>
  </div>
  <!-- Filtri attivi -->
  <div fxLayout="row" fxLayoutAlign="center start">
    <!-- Periodo temporale-->
    <!-- <active-filters-button *ngIf="!workflowFilters && dashboardFilters && dashboardFilters.selectedItemsDateRanges"
      [disabled]="false" text="{{ dashboardFilters.selectedItemsDateRanges.nameForSummary }}" filterType="dateRange"
      (onIconClicked)="clearFilter($event)"
      [icon]="dashboardFilters.selectedItemsDateRanges.code !== 6 ? 'assets/icons/icon-times.svg' : null"
      attr.aria-label="{{ dashboardFilters.selectedItemsDateRanges.nameForSummary }}">
    </active-filters-button> -->
    <!-- Funzione -->
    <active-filters-button
      *ngIf="dashboardFilters && dashboardFilters.selectedFunctions && dashboardFilters.selectedFunctions.length"
      filterType="function" icon="assets/icons/icon-times.svg" [disabled]="false" (onIconClicked)="clearFilter($event)"
      [text]="getSelectedFunctionsFilterText()" attr.aria-label="{{ getSelectedFunctionsFilterText() }}">
    </active-filters-button>
    <!-- Azienda -->
    <active-filters-button
      *ngIf="dashboardFilters && dashboardFilters.selectedCompanies && dashboardFilters.selectedCompanies.length"
      filterType="company" icon="assets/icons/icon-times.svg" [disabled]="false" (onIconClicked)="clearFilter($event)"
      [text]="getSelectedCompaniesFilterText()" attr.aria-label="{{ getSelectedCompaniesFilterText() }}">
    </active-filters-button>
    <!-- Dipartimento -->
    <active-filters-button
      *ngIf="dashboardFilters && dashboardFilters.selectedDipartimento && dashboardFilters.selectedDipartimento.length"
      filterType="dipartimento" icon="assets/icons/icon-times.svg" [disabled]="false"
      (onIconClicked)="clearFilter($event)" [text]="getSelectedDipartimentoFilterText()"
      attr.aria-label="{{ getSelectedDipartimentoFilterText() }}">
    </active-filters-button>
    <!-- Servizio -->
    <ng-container *ngIf="canFilterByServizio">
      <active-filters-button
        *ngIf="dashboardFilters && dashboardFilters.selectedServizio && dashboardFilters.selectedServizio.length"
        filterType="servizio" icon="assets/icons/icon-times.svg" [disabled]="false"
        (onIconClicked)="clearFilter($event)" [text]="getSelectedServizioFilterText()"
        attr.aria-label="{{ getSelectedServizioFilterText() }}">
      </active-filters-button>
    </ng-container>
    <!-- Divisione -->
    <ng-container *ngIf="canFilterByDivisione">
      <active-filters-button
        *ngIf="dashboardFilters && dashboardFilters.selectedDivisione && dashboardFilters.selectedDivisione.length"
        filterType="divisione" icon="assets/icons/icon-times.svg" [disabled]="false"
        (onIconClicked)="clearFilter($event)" [text]="getSelectedDivisioneFilterText()"
        attr.aria-label="{{ getSelectedDivisioneFilterText() }}">
      </active-filters-button>
    </ng-container>

    <!-- Team -->
    <active-filters-button
      *ngIf="dashboardFilters && dashboardFilters.selectedTeam && dashboardFilters.selectedTeam.length"
      filterType="team" icon="assets/icons/icon-times.svg" [disabled]="false"
      (onIconClicked)="clearFilter($event)" [text]="getSelectedTeamFilterText()"
      attr.aria-label="{{ getSelectedTeamFilterText() }}">
    </active-filters-button>

    <!-- Contractual Framework / tipologia contratto -->
    <active-filters-button
      *ngIf="dashboardFilters && dashboardFilters.selectedTipologiaContratto && dashboardFilters.selectedTipologiaContratto.length"
      filterType="tipologiaContratto" icon="assets/icons/icon-times.svg" [disabled]="false"
      (onIconClicked)="clearFilter($event)" [text]="getSelectedTipologiaContrattoFilterText()"
      attr.aria-label="{{ getSelectedTipologiaContrattoFilterText() }}">
    </active-filters-button>

    <!-- Industry -->
    <active-filters-button
      *ngIf="dashboardFilters && dashboardFilters.selectedIndustry && dashboardFilters.selectedIndustry.length"
      filterType="industry" icon="assets/icons/icon-times.svg" [disabled]="false"
      (onIconClicked)="clearFilter($event)" [text]="getSelectedIndustryFilterText()"
      attr.aria-label="{{ getSelectedIndustryFilterText() }}">
    </active-filters-button>
    <!-- Nucleo -->
    <!-- <active-filters-button
      *ngIf="dashboardFilters && dashboardFilters.selectedNucleo && dashboardFilters.selectedNucleo.length"
      filterType="nucleo" icon="assets/icons/icon-times.svg" [disabled]="false" (onIconClicked)="clearFilter($event)"
      [text]="getSelectedNucleoFilterText()" attr.aria-label="{{ getSelectedNucleoFilterText() }}">
    </active-filters-button> -->
    <!-- Segretariale -->
    <!-- <active-filters-button
      *ngIf="dashboardFilters && dashboardFilters.selectedSegreteriale && dashboardFilters.selectedSegreteriale.length"
      filterType="segretariale" icon="assets/icons/icon-times.svg" [disabled]="false"
      (onIconClicked)="clearFilter($event)" [text]="getSelectedSegreterialeFilterText()"
      attr.aria-label="{{ getSelectedSegreterialeFilterText() }}">
    </active-filters-button> -->
    <!-- Organizzativa -->
    <!-- <active-filters-button
      *ngIf="dashboardFilters && dashboardFilters.selectedOrganizzativa && dashboardFilters.selectedOrganizzativa.length"
      filterType="organizzativa" icon="assets/icons/icon-times.svg" [disabled]="false"
      (onIconClicked)="clearFilter($event)" [text]="getSelectedOrganizzativaFilterText()"
      attr.aria-label="{{ getSelectedOrganizzativaFilterText() }}">
    </active-filters-button> -->

    <!-- FIltri per la workflow dashboard -->
    <ng-container *ngIf="workflowFilters">
      <!-- Ricerca per utente -->
      <active-filters-button
        *ngIf="dashboardFilters && dashboardFilters.selectedUser && dashboardFilters.selectedUser.length"
        filterType="userfilter" icon="assets/icons/icon-times.svg" [disabled]="false"
        (onIconClicked)="clearFilter($event)" [text]="getSelectedUserFilterText()"
        attr.aria-label="{{ getSelectedUserFilterText() }}">
      </active-filters-button>
      <!-- Ricerca per iniziativa -->
      <active-filters-button
        *ngIf="dashboardFilters && dashboardFilters.selectedInitiatives && dashboardFilters.selectedInitiatives.length"
        filterType="initiatives" icon="assets/icons/icon-times.svg" [disabled]="false"
        (onIconClicked)="clearFilter($event)" [text]="getSelectedInitiativeFilterText()"
        attr.aria-label="{{ getSelectedInitiativeFilterText() }}">
      </active-filters-button>
    </ng-container>

    <ng-container *ngIf="surveyFilters">
      <!-- Ricerca per iniziativa -->
      <active-filters-button
        *ngIf="dashboardFilters && dashboardFilters.selectedInitiatives && dashboardFilters.selectedInitiatives.length"
        filterType="initiatives" icon="assets/icons/icon-times.svg" [disabled]="false"
        (onIconClicked)="clearFilter($event)" [text]="getSelectedInitiativeFilterText()"
        attr.aria-label="{{ getSelectedInitiativeFilterText() }}">
      </active-filters-button>
    </ng-container>
  </div>
  <!-- Toggle dei filtri -->
  <div fxLayout="column" fxLayoutAlign="center start">
    <lib-alloy-button importance="bordeless left-icon" dimension="btn-small" [disabled]="false"
      icon="../../assets/icons/toggle_filters.svg"
      text="{{ isFiltersSectionVisible ? ('dashboard.CLOSE_FILTERS' | translate) : ('dashboard.OPEN_FILTERS' | translate) }}"
      (onButtonClicked)="emitToggleFiltersSection()"
      attr.aria-label="{{ isFiltersSectionVisible ? ('dashboard.CLOSE_FILTERS' | translate) : ('dashboard.OPEN_FILTERS' | translate) }}">
    </lib-alloy-button>
  </div>
</div>

<!-- Filtri -->
<div *ngIf="isFiltersSectionVisible" fxLayout="row" fxLayoutAlign="start start" class="filters-section">
  <div fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
    <div fxLayout="row" fxLayoutAlign="start start">
      <h2 translate="dashboard.SEARCH_FILTERS"></h2>
    </div>
    <div class="space20"></div>
    <div class="filters-row-container">
      <!-- data inizio e data fine -->
      <datepicker placeholder="dashboard.FROM" class="full-width" label="dashboard.FROM"
        [(value)]="dashboardFiltersTmp.customStartDate" pickerType="calendar"
        (valueChange)="emitOnStartDateChange($event)">
      </datepicker>

      <datepicker placeholder="dashboard.TO" class="full-width" label="dashboard.TO"
        [(value)]="dashboardFiltersTmp.customEndDate" pickerType="calendar" (valueChange)="emitOnEndDateChange($event)">
      </datepicker>
      <!-- Periodo -->
      <!-- <lib-alloy-select *ngIf="!workflowFilters" [bindModel]="dashboardFiltersTmp.selectedItemsDateRanges"
        (onSelectionChanged)="emitOnItemsDateRangeChanged($event)" [items]="doneItemsDateRanges" labelForId="dateRanges"
        bindValue="code" bindLabel="name" formTitle="{{ 'dashboard.SHOW_PERIOD' | translate }}"
        placeholderLabel="{{ 'generic.SELECT_DATE_RANGE' | translate }}"
        startSearchLabel="{{ 'generic.ngSelect.START_SEARCH' | translate }}"
        noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
        attrAriaLabel="{{ 'dashboard.SHOW_PERIOD' | translate }}"
        loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="small-dimension"
        [virtualScroll]="false" [multiple]="false"></lib-alloy-select> -->
      <!-- Funzione / Area of reference -->
      <ng-container *ngIf="canFilterByChief">
        <lib-alloy-select [bindModel]="dashboardFiltersTmp.selectedFunctions" [loading]="isLoadingFunctionList"
          (onSelectionChanged)="emitOnFunctionSelected($event)" [items]="functionList" [multiple]="true"
          [searchable]="false" (scrollToEnd)="fetchMoreFunctions($event)"
          formTitle="{{ 'dashboard.FUNCTION' | translate }}" placeholderLabel="{{ 'dashboard.FUNCTION' | translate }}"
          startSearchLabel="{{ 'dashboard.TYPE_FUNCTION' | translate }}"
          noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="small-dimension"
          [typeahead]="functionsSrvSubject" attrAriaLabel="{{ 'dashboard.TYPE_FUNCTION' | translate }}">
        </lib-alloy-select>
      </ng-container>
      <!-- Azienda - SocDistacco -->
      <ng-container *ngIf="canFilterBySocDistacco">
        <lib-alloy-select 
          [bindModel]="dashboardFiltersTmp.selectedCompanies"
          formTitle="{{ 'dashboard.COMPANY' | translate }}"
          placeholderLabel="{{ 'dashboard.COMPANY' | translate }}"
          startSearchLabel="{{ 'dashboard.TYPE_COMPANY' | translate }}"
          noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}"
          containerClass="small-dimension"
          attrAriaLabel="{{ 'dashboard.TYPE_COMPANY' | translate }}"
          [loading]="isLoadingCompanyList"
          [typeahead]="companiesSrvSubject"
          [items]="companyList"
          [multiple]="true"
          [searchable]="false"
          (scrollToEnd)="fetchMoreCompanies($event)"
          (onSelectionChanged)="emitOnCompanySelected($event)">
        </lib-alloy-select>
      </ng-container>
      <!-- Dipartimento -->
      <ng-container *ngIf="canFilterByDipartimento">
        <lib-alloy-select [bindModel]="dashboardFiltersTmp.selectedDipartimento" [loading]="isLoadingDepartmentList"
          (onSelectionChanged)="emitOnDepartmentSelected($event)" [items]="departmentList" [multiple]="true"
          [searchable]="true" (scrollToEnd)="fetchMoreDepartments($event)"
          formTitle="{{ 'dashboard.DEPARTMENT' | translate }}"
          placeholderLabel="{{ 'dashboard.DEPARTMENT' | translate }}"
          startSearchLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="small-dimension"
          [typeahead]="departmentsSrvSubject" attrAriaLabel="{{ 'dashboard.TYPE_DEPARTMENT' | translate }}">
        </lib-alloy-select>
      </ng-container>
      <!-- Servizio -->
      <ng-container *ngIf="canFilterByServizio">
        <lib-alloy-select [bindModel]="dashboardFiltersTmp.selectedServizio" [loading]="isLoadingServiziList"
          (onSelectionChanged)="emitOnServizioSelected($event)" [items]="serviziList" [multiple]="true"
          [searchable]="true" (scrollToEnd)="fetchMoreServizi($event)"
          formTitle="{{ 'dashboard.SERVICE_BRANCH' | translate }}"
          placeholderLabel="{{ 'dashboard.SERVICE' | translate }}"
          startSearchLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="small-dimension"
          [typeahead]="serviziSrvSubject" attrAriaLabel="{{ 'dashboard.TYPE_SERVICE' | translate }}" bindLabel="key"
          bindValue="value" parameterNameOfSelectedItem="value">
        </lib-alloy-select>
      </ng-container>
      <!-- Divisione -->
      <ng-container *ngIf="canFilterByDivisione">
        <lib-alloy-select [bindModel]="dashboardFiltersTmp.selectedDivisione" [loading]="isLoadingDivisionList"
          (onSelectionChanged)="emitOnDivisionSelected($event)" [items]="divisionList" [multiple]="true"
          [searchable]="true" (scrollToEnd)="fetchMoreDivisions($event)"
          formTitle="{{ 'dashboard.DIVISION' | translate }}" placeholderLabel="{{ 'dashboard.DIVISION' | translate }}"
          startSearchLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="small-dimension"
          [typeahead]="divisionSrvSubject" attrAriaLabel="{{ 'dashboard.TYPE_DIVISION' | translate }}" bindLabel="key"
          bindValue="value" parameterNameOfSelectedItem="value">
        </lib-alloy-select>
      </ng-container>
      <!-- Team -->
      <lib-alloy-select [bindModel]="dashboardFiltersTmp.selectedTeam" [loading]="isLoadingTeamList"
        (onSelectionChanged)="emitOnTeamSelected($event)" [items]="teamList" [multiple]="true" [searchable]="true"
        (scrollToEnd)="fetchMoreTeams($event)" formTitle="{{ 'dashboard.TEAM' | translate }}"
        placeholderLabel="{{ 'dashboard.TEAM' | translate }}"
        startSearchLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
        noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
        loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="small-dimension"
        [typeahead]="teamSrvSubject" attrAriaLabel="{{ 'dashboard.TYPE_TEAM' | translate }}">
      </lib-alloy-select>
      <!-- Contractual framework / Tipologia Contratto -->
      <lib-alloy-select [bindModel]="dashboardFiltersTmp.selectedTipologiaContratto"
        [loading]="isLoadingTipologiaContrattoList" (onSelectionChanged)="emitOnTipologiaContrattoSelected($event)"
        [items]="tipologiaContrattoList" [multiple]="true" [searchable]="true"
        (scrollToEnd)="fetchMoreTipologiaContratto($event)"
        formTitle="{{ 'dashboard.CONTRACTUAL_FRAMEWORK' | translate }}"
        placeholderLabel="{{ 'dashboard.CONTRACTUAL_FRAMEWORK' | translate }}"
        startSearchLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
        noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
        loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="small-dimension"
        [typeahead]="tipologiaContrattoSrvSubject"
        attrAriaLabel="{{ 'dashboard.TYPE_CONTRACTUAL_FRAMEWORK' | translate }}">
      </lib-alloy-select>
      <!-- Industry -->
      <lib-alloy-select [bindModel]="dashboardFiltersTmp.selectedIndustry" [loading]="isLoadingIndustryList"
        (onSelectionChanged)="emitOnIndustrySelected($event)" [items]="industryList" [multiple]="true"
        [searchable]="true" (scrollToEnd)="fetchMoreIndustry($event)" formTitle="{{ 'dashboard.INDUSTRY' | translate }}"
        placeholderLabel="{{ 'dashboard.INDUSTRY' | translate }}"
        startSearchLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
        noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
        loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="small-dimension"
        [typeahead]="industrySrvSubject" attrAriaLabel="{{ 'dashboard.TYPE_INDUSTRY' | translate }}">
      </lib-alloy-select>

      <!-- Nucleo -->
      <!-- <ng-container *ngIf="canFilterByNucleo">
        <lib-alloy-select [bindModel]="dashboardFiltersTmp.selectedNucleo" [loading]="isLoadingNucleoList"
          (onSelectionChanged)="emitOnNucleoSelected($event)" [items]="nucleoList" [multiple]="true" [searchable]="true"
          (scrollToEnd)="fetchMoreNucleos($event)" formTitle="{{ 'dashboard.CORE' | translate }}"
          placeholderLabel="{{ 'dashboard.CORE' | translate }}"
          startSearchLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="small-dimension"
          [typeahead]="nucleoSrvSubject" attrAriaLabel="{{ 'dashboard.TYPE_CORE' | translate }}" bindLabel="key"
          bindValue="value" parameterNameOfSelectedItem="value">
        </lib-alloy-select>
      </ng-container> -->
      <!-- Segretariale -->
      <!-- <ng-container *ngIf="canFilterByUnitaSegretariale">
        <lib-alloy-select [bindModel]="dashboardFiltersTmp.selectedSegreteriale" [loading]="isLoadingSegretarialeList"
          (onSelectionChanged)="emitOnSegretarialeSelected($event)" [items]="segretarialeList" [multiple]="true"
          [searchable]="true" (scrollToEnd)="fetchMoreSegretariale($event)"
          formTitle="{{ 'dashboard.SECTOR' | translate }}" placeholderLabel="{{ 'dashboard.SECTOR' | translate }}"
          startSearchLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="small-dimension"
          [typeahead]="segretarialeSrvSubject" attrAriaLabel="{{ 'dashboard.TYPE_SECTOR' | translate }}" bindLabel="key"
          bindValue="value" parameterNameOfSelectedItem="value">
        </lib-alloy-select>
      </ng-container> -->
      <!-- Organizzativa -->
      <!-- <ng-container *ngIf="canFilterByUnitaOrganizzativa">
        <lib-alloy-select [bindModel]="dashboardFiltersTmp.selectedOrganizzativa" [loading]="isLoadingOrganizzativa"
          (onSelectionChanged)="emitOnOrganizzativaSelected($event)" [items]="orgenizzativaList" [multiple]="true"
          [searchable]="true" (scrollToEnd)="fetchMoreOrganizzativa($event)"
          formTitle="{{ 'dashboard.U_ORGAN' | translate }}" placeholderLabel="{{ 'dashboard.U_ORGAN' | translate }}"
          startSearchLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="small-dimension"
          [typeahead]="organizzativaSrvSubject" attrAriaLabel="{{ 'dashboard.U_ORGAN' | translate }}" bindLabel="key"
          bindValue="value" parameterNameOfSelectedItem="value">
        </lib-alloy-select>
      </ng-container> -->

      <!-- Crediti -->
      <ng-container *ngIf="canFilterByCrediti && creditsFilters">
        <lib-alloy-select [bindModel]="dashboardFiltersTmp.selectedCredits" [loading]="isLoadingCredits"
          (onSelectionChanged)="emitOnCreditsSelected($event)" [items]="creditsList" [multiple]="true"
          [searchable]="true" formTitle="{{ 'dashboard.CREDITS' | translate }}"
          placeholderLabel="{{ 'dashboard.CREDITS' | translate }}"
          startSearchLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="small-dimension"
          attrAriaLabel="{{ 'dashboard.CREDITS' | translate }}" bindLabel="label" bindValue="name"
          parameterNameOfSelectedItem="label">
        </lib-alloy-select>
      </ng-container>

      <!-- Filtri dashboard -->
      <ng-container *ngIf="workflowFilters">
        <!-- filtro per utenti -->
        <lib-alloy-select [bindModel]="dashboardFiltersTmp.selectedUser" [loading]="isLoadingUserList"
          (onSelectionChanged)="emitOnUserSelected($event)" [items]="userList" [multiple]="true" [searchable]="true"
          (scrollToEnd)="fetchMoreUsers($event)" formTitle="{{ 'dashboard.USER_FILTER' | translate }}"
          placeholderLabel="{{ 'dashboard.USER_FILTER' | translate }}"
          startSearchLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="small-dimension"
          [typeahead]="userSrvSubject" attrAriaLabel="{{ 'dashboard.USER_FILTER' | translate }}" bindLabel="key"
          bindValue="value" parameterNameOfSelectedItem="value">
        </lib-alloy-select>

        <!-- filtro per iniziativa -->
        <lib-alloy-select [bindModel]="dashboardFiltersTmp.selectedInitiatives" [loading]="isLoadingInitiativeList"
          (onSelectionChanged)="emitOnInitiativeSelected($event)" [items]="initiativeList" [multiple]="true"
          [searchable]="true" (scrollToEnd)="fetchMoreInitiatives($event)"
          formTitle="{{ 'dashboard.INITIATIVE' | translate }}"
          placeholderLabel="{{ 'dashboard.INITIATIVE' | translate }}"
          startSearchLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="small-dimension"
          [typeahead]="initiativeSrvSubject" attrAriaLabel="{{ 'dashboard.INITIATIVE' | translate }}" bindLabel="key"
          bindValue="value" parameterNameOfSelectedItem="value">
        </lib-alloy-select>
      </ng-container>

      <ng-container *ngIf="surveyFilters">
        <!-- filtro per iniziativa -->
        <lib-alloy-select [bindModel]="dashboardFiltersTmp.selectedInitiatives" [loading]="isLoadingInitiativeList"
          (onSelectionChanged)="emitOnInitiativeSelected($event)" [items]="initiativeList" [multiple]="true"
          [searchable]="true" (scrollToEnd)="fetchMoreInitiatives($event)"
          formTitle="{{ 'dashboard.INITIATIVE' | translate }}"
          placeholderLabel="{{ 'dashboard.INITIATIVE' | translate }}"
          startSearchLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          noDataFoundLabel="{{ 'generic.ngSelect.NO_DATA_FOUND' | translate }}"
          loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}" containerClass="small-dimension"
          [typeahead]="initiativeSrvSubject" attrAriaLabel="{{ 'dashboard.INITIATIVE' | translate }}" bindLabel="key"
          bindValue="value" parameterNameOfSelectedItem="value">
        </lib-alloy-select>
      </ng-container>
    </div>
    <div class="space20"></div>
    <!-- Pulsante applica filtri -->
    <div fxLayout="column" fxFlexFill fxLayoutAlign="center end" fxLayout.sm="row" fxLayout.xs="row"
      fxLayoutAlign.xs="center start" fxLayoutAlign.sm="center start">
      <lib-alloy-button importance="primary" dimension="btn-regular" text="{{'dashboard.APPLY_FILTERS' | translate}}"
        (onButtonClicked)="emitToggleFiltersSection(true)">
      </lib-alloy-button>
    </div>
  </div>
</div>