/*
 * Servizio che gestisce le chiamate ai servizi relative agli item
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { GlobalApplicationData } from "../../shared/models/global-application-data.model";
import { Observable, combineLatest, Subscription } from "rxjs";
import { ItemAttributeTypes, Item, ItemAttributeObjectTypes, ItemTypes, CommonConstants, ConsumableItem, ItemChild, User, SenecaResponse, Note, TextTemplate } from "../../../cm2-commonclasses";
import { TranslateService } from "@ngx-translate/core";
import { ItemUtil, IDataItem } from 'src/app/shared/models/item.model';
import { take, map } from 'rxjs/operators';

export const SEARCHABLE_ITEM_TYPES: string[] = [
  ItemTypes.ITEM,
  ItemTypes.CONTAINER,
  ItemTypes.ITEMS_COLLECTION,
  ItemTypes.DAM_ITEM,
  ItemTypes.COURSE_SYLLABUS,
  ItemTypes.SCORM_FREE,
  ItemTypes.SCORM_INVITE
];
export const LIBRARY_ITEM_TYPES: string[] = [
  ItemTypes.ITEM,
  ItemTypes.CONTAINER,
  ItemTypes.ITEMS_COLLECTION,
  ItemTypes.DAM_ITEM,
  ItemTypes.SCORM_FREE,
  ItemTypes.SCORM_INVITE
];
export const LIBRARY_ITEM_BLENDED_TYPES: string[] = [
  ItemTypes.BLENDED_CONTAINER
];
export const LIBRARY_CONTAINERS_ITEM_TYPES: string[] = [
  ItemTypes.CONTAINER
];
export const LIBRARY_ITEM_OBJECT_TYPE_SPECIALIZATIONS: string[] = [
  ItemAttributeObjectTypes.PODCAST,
  ItemAttributeObjectTypes.GRAPH,
  ItemAttributeObjectTypes.DOCUMENT,
  ItemAttributeObjectTypes.ELEARNING,
  "SCORM",
  ItemAttributeObjectTypes.VIDEO,
  ItemAttributeObjectTypes.IMAGE,
  ItemAttributeObjectTypes.PROJECT,
  ItemAttributeObjectTypes.BLENDED_PROJECT,
  ItemAttributeObjectTypes.LEARNING_PLAN,
  ItemAttributeObjectTypes.EBOOK,
  ItemTypes.CERTIFICATE_ITEM,
  ItemAttributeObjectTypes.SURVEY,
  ItemAttributeObjectTypes.EXTERNAL_ITEM,
  ItemAttributeObjectTypes.LINK,

];
export const LIBRARY_ITEM_OBJECT_TYPE_SPECIALIZATIONS_WITHOUTH_CONTAINRS: string[] = [
  ItemAttributeObjectTypes.PODCAST,
  ItemAttributeObjectTypes.GRAPH,
  ItemAttributeObjectTypes.DOCUMENT,
  ItemAttributeObjectTypes.ELEARNING,
  ItemTypes.CERTIFICATE_ITEM,
  "SCORM",
  ItemAttributeObjectTypes.VIDEO,
  ItemAttributeObjectTypes.IMAGE,
  ItemAttributeObjectTypes.EBOOK,
  ItemAttributeObjectTypes.SURVEY,
  ItemAttributeObjectTypes.EXTERNAL_ITEM,
  ItemAttributeObjectTypes.PROJECT,
  ItemAttributeObjectTypes.BLENDED_PROJECT,
  ItemAttributeObjectTypes.LEARNING_PLAN,
  'BLENDED_STAGE',
  ItemAttributeObjectTypes.LINK,
];
export const LIBRARY_ITEM_OBJECT_TYPE_SPECIALIZATIONS_FOR_EDIT_LP_BLENDED_SECTION: string[] = [
  ItemAttributeObjectTypes.PODCAST,
  ItemAttributeObjectTypes.GRAPH,
  ItemAttributeObjectTypes.DOCUMENT,
  ItemAttributeObjectTypes.ELEARNING,
  ItemTypes.CERTIFICATE_ITEM,
  "SCORM",
  ItemAttributeObjectTypes.VIDEO,
  ItemAttributeObjectTypes.IMAGE,
  ItemAttributeObjectTypes.EBOOK,
  ItemAttributeObjectTypes.SURVEY,
  ItemAttributeObjectTypes.EXTERNAL_ITEM,
  ItemAttributeObjectTypes.BLENDED_PROJECT,
];
@Injectable()
export class ItemsService {
  applicationData: GlobalApplicationData;
  result$: Subscription;

  constructor(private store: Store<fromApp.AppState>,
    private translate: TranslateService,
    private http: HttpClient) {
    // Dallo store recupero l'oggetto principale contenente generici dati, e la lingua dell'applicazione
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    const combinedSelectes$ = combineLatest(globalApplicationData$);
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData]) => {
        this.applicationData = globalApplicationData;
      });
  }

  // Imposta l'etichetta in traduzione degli item library
  setItemTypologyTranslations = (itemList) => {
    if (itemList && itemList.length) {
      for (let i = 0, itemsLength = itemList.length; i < itemsLength; i++) {
        let currentItem = itemList[i];
        // Tipologia di ogggetto
        let typology: string = null;

        if (currentItem.itemType && (currentItem.itemType == ItemTypes.SCORM_FREE || currentItem.itemType == ItemTypes.SCORM_INVITE)) {
          currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.ELEARNING');
          currentItem.typology = currentItem.itemType;
        }

        if (!currentItem.typeTranslation && currentItem.itemAttributes && currentItem.itemAttributes.length) {
          for (let k = 0; k < currentItem.itemAttributes.length; k++) {
            let currentAttribute = currentItem.itemAttributes[k];
            if (currentAttribute.attributeType == ItemAttributeTypes.OBJECT_TYPE) {
              typology = currentAttribute.attributeValue;
              break;
            }
          }

          if (typology) {
            currentItem.typology = typology;
            if (typology == ItemAttributeObjectTypes.PODCAST) {
              currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.PODCAST');
            } else if (typology == ItemAttributeObjectTypes.GRAPH) {
              currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.GRAPH');
            } else if (typology == ItemAttributeObjectTypes.DOCUMENT) {
              currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.DOCUMENT');
            } else if (typology == ItemAttributeObjectTypes.EBOOK) {
              currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.EBOOK');
            } else if (typology == ItemAttributeObjectTypes.VIDEO) {
              currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.VIDEO');
            } else if (typology == ItemAttributeObjectTypes.IMAGE) {
              currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.IMAGE');
            }
          }
        }
      }
    }
  }


  getSuggestedCatalogCourses(params: any, itemId?: string, fromRecord?, numRecords?, searchedText?: string, startDate?, endDate?, courseUserStatuses?: string | string[], courseType?: string, past?: boolean, future?: boolean,
    description?: string, asConsumableItem?: boolean, techSkillsOnly?: boolean, softSkillsOnly?: boolean, itemTypes?: string | string[],
    objectTypeSpecializations?: string[] | string, techCompetences?: string[], softCompetences?: string[], clusters?: string[], functionalAreas?: string[],
    adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[], argumentsTags?: string[], courseCategoryTag?: string[]) {

    let httpParams = new HttpParams();

    httpParams = httpParams.append('courseId', itemId);
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);

    httpParams = httpParams.append('startDate', (startDate && startDate.toString()) || '');
    httpParams = httpParams.append('endDate', (endDate && endDate.toString()) || '');
    httpParams = httpParams.append('courseType', courseType || '');
    httpParams = httpParams.append('description', (description && description.toString()) || '');
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    if (past) {
      httpParams = httpParams.append('past', (past && past.toString()) || '');
    }
    if (future) {
      httpParams = httpParams.append('future', (future && future.toString()) || '');
    }
    if (asConsumableItem) {
      httpParams = httpParams.append('asConsumableItem', (asConsumableItem && asConsumableItem.toString()) || '');
    }
    if (courseUserStatuses && courseUserStatuses.length) {
      httpParams = httpParams.append('courseUserStatuses', courseUserStatuses && typeof courseUserStatuses == 'string' ? courseUserStatuses : JSON.stringify(courseUserStatuses));
    }

    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      if (objectTypeSpecializations && typeof objectTypeSpecializations == 'string') {
        httpParams = httpParams.append('objectType', objectTypeSpecializations)
      } else {
        for (let i = 0; i < objectTypeSpecializations.length; i++) {
          httpParams = httpParams.append('objectType', objectTypeSpecializations[i]);
        }
      }
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (courseCategoryTag && courseCategoryTag.length) {
      httpParams = httpParams.append('courseCategoryTag', courseCategoryTag && typeof courseCategoryTag == 'string' ? courseCategoryTag : JSON.stringify(courseCategoryTag));
    }
    httpParams = httpParams.append('withUserCatalogActions', 'true');
    httpParams = httpParams.append('withAvgLikingScore', 'false');

    return this.http.get<SenecaResponse<any[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-suggested-catalog-courses/', {
      params: httpParams
    });
  }

  searchAllAvailableItems(params, translate) {
    let searchedText = params && params.searchedText && params.searchedText.trim() || null;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', params.itemId || '');
    httpParams = httpParams.append('status', params.status || "A");
    httpParams = !!params.sorting ? httpParams.append('sorting', params.sorting) : httpParams;
    httpParams = params.openedFromDate ? httpParams.append('openedFromDate', params.openedFromDate) : httpParams;
    httpParams = params.openedToDate ? httpParams.append('openedToDate', params.openedToDate) : httpParams;
    httpParams = params.itemsPoolAttributeFilters ? httpParams.append('itemsPoolAttributeFilters', JSON.stringify([params.itemsPoolAttributeFilters])) : httpParams;
    httpParams = !!searchedText && httpParams.append('searchedText', searchedText) || httpParams;
    httpParams = httpParams.append('fromRecord', `${params.fromRecord || 0}`);
    httpParams = httpParams.append('numRecords', `${params.numRecords || 20}`);
    httpParams = params.withCompletionPercentage ? httpParams.append('withCompletionPercentage', 'true') : httpParams;
    httpParams = params.withChildrenCount ? httpParams.append('withChildrenCount', 'true') : httpParams;
    httpParams = params.suggestedItems ? httpParams.append('suggestedItems', 'true') : httpParams;
    httpParams = params.invited ? httpParams.append('invited', 'true') : httpParams;
    httpParams = params.certified ? httpParams.append('certified', 'true') : httpParams;
    httpParams = httpParams.append('withChildsLoaded', params && params.withChildsLoaded ? "true" : "false");
    httpParams = httpParams.append('myOnly', params && params.myOnly ? "true" : "false");
    httpParams = httpParams.append('useRentService', params && params.useRentService ? "true" : "false");
    httpParams = httpParams.append('useGetMyActivitiesItems', params && params.useGetMyActivitiesItems ? "true" : "false");
    httpParams = httpParams.append('onlyForMe', params && params.onlyForMe ? "true" : "false");
    httpParams = httpParams.append('openedOnly', params && params.openedOnly ? "true" : "false");
    httpParams = httpParams.append('allData', params && params.allData ? "true" : "false");
    httpParams = httpParams.append('withStartDate', params && params.withStartDate ? "true" : "false");

    httpParams = httpParams.append('useListItemsByAny', params && params.useListItemsByAny ? "true" : "false");
    httpParams = httpParams.append('suggested', params && params.suggested ? "true" : "false");

    params && params.objectTypeSpecializations && params.objectTypeSpecializations.forEach(type => {
      httpParams = httpParams.append('objectTypeSpecializations', type);
    });

    params && params.originApplicationNames && params.originApplicationNames.forEach(type => {
      httpParams = httpParams.append('originApplicationName', type);
    });

    params && params.attributeTypes && params.attributeTypes.forEach(type => {
      httpParams = httpParams.append('attributeTypes', type);
    });
    params && params.attributeRefTypes && params.attributeRefTypes.forEach(type => {
      httpParams = httpParams.append('attributeRefTypes', type);
    });
    params && params.attributeRefIds && params.attributeRefIds.forEach(type => {
      httpParams = httpParams.append('attributeRefIds', type);
    });

    httpParams = params.referenceApplicationName && httpParams.append('referenceApplicationNames', params.referenceApplicationName) || httpParams;

    params && params.itemIds && params.itemIds.forEach(type => {
      httpParams = httpParams.append('itemIds', type);
    });

    let itemTypes = params && params.itemTypes || SEARCHABLE_ITEM_TYPES;

    itemTypes.forEach(type => {
      httpParams = httpParams.append('itemTypes', type);
    });

    httpParams = params && params.suggestedItems ? httpParams.append('suggestedItems', `true`) : httpParams;

    return this.http.get<SenecaResponse<ConsumableItem[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/search-all-available-items', {
      params: httpParams
    })
      .pipe(
        take(1),
        map((senecaResponse) => {
          if (senecaResponse && senecaResponse.error) {
            throw senecaResponse.error;
          } else {
            let items = senecaResponse.response || [];
            let mappedItems = [];
            for (let i = 0, itemsLength = items.length; i < itemsLength; i++) {
              let currentItem = items[i];
              mappedItems.push(this.mapConsumableItemToIDataItem(currentItem, translate));
            }
            return mappedItems;
          }
        })
      );
  }

  getCourseEditions(itemId: string, stageItemId: string, withCatalogItemRequestDate?: boolean, dateAsUTC?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('courseId', itemId);
    httpParams = httpParams.append('stageItemId', stageItemId);
    httpParams = httpParams.append('createEngagement', 'false');
    httpParams = httpParams.append('withBookmarkStatus', 'false');
    httpParams = httpParams.append('skipActions', 'false');
    httpParams = httpParams.append('getOnlyEditions', 'true');
    httpParams = httpParams.append('withCourseDaysLoaded', 'true');
    httpParams = httpParams.append('withCatalogItemRequestDate', withCatalogItemRequestDate ? "true" : "false");

    if (dateAsUTC !== undefined) {
      httpParams = httpParams.append('dateAsUtc', 'true');
    }

    return this.http.get<SenecaResponse<any[]>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/get-catalog-course-by-courseId/${itemId}`, {
      params: httpParams
    });
  }

  // Verifica lo status dell'upload dell'oggetto SCORM
  changeCourseOpenBehavior(scormId: string, openMode: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('scormId', scormId || '');
    httpParams = httpParams.append('openMode', openMode || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/change-course-open-behavior', {
      params: httpParams
    });
  }

  // Recupera il count degli item in bozza
  countDrafts(searchedText?: string, sorting?: string, originApplicationName?: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('sorting', sorting || '');
    httpParams = httpParams.append('originApplicationName', originApplicationName || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-drafts', {
      params: httpParams
    });
  }

  countInitiativesForBlendedLibraryItems(searchedText?: string, sorting?: string, originApplicationName?: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('sorting', sorting || '');
    httpParams = httpParams.append('originApplicationName', originApplicationName || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-initiatives-for-blended-library-items', {
      params: httpParams
    });
  }

  // Recupera le ultime iniziative disponibili
  getLastLastInitiatives(fromRecord?, numRecords?, itemTypes?: string[], title?: string, suspended?: boolean, archived?: boolean): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    httpParams = httpParams.append('title', title || '');
    httpParams = httpParams.append('suspended', `${suspended}`);
    httpParams = httpParams.append('archived', `${archived}`);
    if (itemTypes && itemTypes.length) {
      itemTypes.forEach(itemTypes => {
        httpParams = httpParams.append('itemTypes', itemTypes);
      });
    }
    return this.http.get<SenecaResponse<any[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-latest-opened-initiatives/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }


  getCreditsItemForUser(currentItem) {
    currentItem.credits = '-';
  }

  formatItemForUser(currentItem) {
    let itemType = null;
    let subtype = null;
    if (currentItem.itemAttributes) {
      let itemAttributes = currentItem.itemAttributes;
      for (let k = 0; k < itemAttributes.length; k++) {
        // Verifico il tipo di oggetto
        if (itemAttributes[k].attributeType === ItemAttributeTypes.OBJECT_TYPE) {
          itemType = itemAttributes[k].attributeValue;
        }
        // Verifico se ha un sottotipo
        if (itemAttributes[k].attributeType === ItemAttributeTypes.OBJECT_TYPE_SPECIALIZATION) {
          subtype = itemAttributes[k].attributeValue;
        }
      }

      // Se non ho trovato un tipo di oggetto in deroga, lo setto automaticamente prendendo il valore dell'itemType
      if (!subtype && itemType) {
        subtype = itemType;
      }

      let cardCoverAttr = ItemUtil.getAttributeByKey(currentItem, ItemTypes.CARD_COVER);
      currentItem.cardCover = cardCoverAttr && cardCoverAttr.attributeValue;
    }

    currentItem.subtype = subtype;

    if (subtype) {
      currentItem.itemTypeLabel = this.translate.instant('generic.itemTypes.' + subtype);
    } else {
      currentItem.itemTypeLabel = '-';
    }
  }

  // Recupera le ultime iniziative (o proposte, qualora fossi loggato come fornitore)
  getSuggestedFields(searchedText: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText || '');
    return this.http.get<SenecaResponse<string[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/search-field-suggester-for-item/10', {
      params: httpParams
    });
  }

  searchFieldSuggesterForStandaloneCatalogItem(searchedText: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText || '');
    return this.http.get<SenecaResponse<string[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/search-field-suggester-for-standalone-catalog-item/10', {
      params: httpParams
    });
  }

  searchFieldSuggesterForStandaloneCatalogCourse(searchedText: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText || '');
    return this.http.get<SenecaResponse<string[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/search-field-suggester-for-standalone-catalog-course/10', {
      params: httpParams
    });
  }

  // Recupera il counter totale persone a cui suggerire l'oggetto
  countPersonsToSuggestItem(itemId: string, searchedText?: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('textFilter', searchedText);
    }
    httpParams = httpParams.append('itemId', itemId);
    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-users-which-can-suggest-item', {
      params: httpParams
    });
  }

  // Recupera una lista di persone a cui suggerire l'oggetto
  getPersonsToSuggestItem(itemId: string, searchedText?: string, pageNum?: number, numRecords?: number) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);

    let from = pageNum;
    let to = numRecords;
    httpParams = httpParams.append('pageNum', from && from.toString());
    httpParams = httpParams.append('numRecords', to && to.toString());

    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('textFilter', searchedText);
    }

    return this.http.get<SenecaResponse<User[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-users-which-can-suggest-item', {
      params: httpParams
    });
  }

  // Recupera le ultime iniziative (o proposte, qualora fossi loggato come fornitore)
  getLatestOpenedSuspendedInitiatives(fromRecord: string, numRecords: string, tabs): any {
    if (tabs && tabs.inProgressTabOpened) {
      return this.getLatestOpenedInProgressInitiatives(fromRecord, numRecords);
    } else if (tabs && tabs.archivedTabOpened) {
      return this.getLatestArchivedInitiatives(fromRecord, numRecords);
    } else {
      // Preparo i parametri per la richiesta http
      let httpParams = new HttpParams();
      httpParams = httpParams.append('fromRecord', fromRecord);
      httpParams = httpParams.append('numRecords', numRecords);
      return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-latest-opened-suspended-initiatives/' + fromRecord + '/' + numRecords, {
        params: httpParams
      });
    }
  }

  // Recupera le ultime iniziative sospese
  getLatestOpenedInProgressInitiatives(fromRecord: string, numRecords: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-latest-opened-in-progress-initiatives/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  // Recupera le ultime iniziative sospese
  getLatestArchivedInitiatives(fromRecord: string, numRecords: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-latest-opened-archived-initiative/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  // Verifica lo status dell'upload dell'oggetto SCORM
  checkUploadStatus(scormId: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('scormId', scormId || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/check-upload-status/' + scormId, {
      params: httpParams
    });
  }

  // Recupera gli item in bozza
  getDrafts(searchedText: string, fromRecord: number, numRecords: number, sorting?: string, originApplicationName?: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    let from = fromRecord;
    let to = numRecords;
    httpParams = httpParams.append('fromRecord', from && from.toString());
    httpParams = httpParams.append('numRecords', to && to.toString());
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('sorting', sorting || '');
    httpParams = httpParams.append('originApplicationName', originApplicationName || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-drafts/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  // Rimuove l'item dai preferiti
  removeCourseFromBookmarks(courseId: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('courseId', courseId || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/remove-course-from-bookmarks', {
      params: httpParams
    });
  }

  // Aggiunge l'item nei preferiti
  addCourseToBookmarks(courseId: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('courseId', courseId || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/add-course-to-bookmarks', {
      params: httpParams
    });
  }

  // Recupera il count degli item
  countItems(searchedText: string, itemTypes?: string | string[], ignoreVisibility?: boolean, status?: string, objectTypeSpecializations?: string[] | string,
    techSkillsOnly?: boolean, softSkillsOnly?: boolean, techCompetences?: string[], softCompetences?: string[], clusters?: string[], functionalAreas?: string[],
    adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[], argumentsTags?: string[], myOnly?: boolean, attributeTypes?: string,
    courseCategoryTags?: string[], modifyStartDate?: string, checkLibraryVisibility?: boolean): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('ignoreVisibility', (ignoreVisibility && ignoreVisibility.toString()) || '');
    httpParams = httpParams.append('status', status || "A");
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    httpParams = modifyStartDate ? httpParams.append('modifyStartDate', modifyStartDate) : httpParams;

    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectTypeSpecializations', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (myOnly) {
      httpParams = httpParams.append('myOnly', 'true');
    }
    if (attributeTypes && attributeTypes.length) {
      httpParams = httpParams.append('attributeTypes', attributeTypes && typeof attributeTypes == 'string' ? attributeTypes : JSON.stringify(attributeTypes));
    }


    if (checkLibraryVisibility) {
      httpParams = httpParams.append('checkLibraryVisibility', 'true');
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-items', {
      params: httpParams
    });
  }

  // Recupera il count degli item
  countStandaloneCatalogItems(searchedText: string, itemTypes?: string | string[], ignoreVisibility?: boolean, status?: string, objectTypeSpecializations?: string[] | string, techSkillsOnly?: boolean, softSkillsOnly?: boolean, techCompetences?: string[], softCompetences?: string[], clusters?: string[], functionalAreas?: string[], adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[], argumentsTags?: string[], courseCategoryTag?: string[]): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('ignoreVisibility', (ignoreVisibility && ignoreVisibility.toString()) || '');
    httpParams = httpParams.append('status', status || "A");
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectTypeSpecializations', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-standalone-catalog-items', {
      params: httpParams
    });
  }

  // Recupera i learning plan dell'utente loggato
  getAllMyLearningPlans(title: string, fromRecord: number, numRecords: number): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    let from = fromRecord;
    let to = numRecords;
    httpParams = httpParams.append('fromRecord', from && from.toString());
    httpParams = httpParams.append('numRecords', to && to.toString());
    httpParams = httpParams.append('title', title || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-all-my-learning-plans/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  // Recupera il count dei learning plan dell'utente loggato
  countAllMyLearningPlans(title: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('title', title || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-all-my-learning-plans', {
      params: httpParams
    });
  }

  // Recupera il count dei materiali
  countMaterialsForParent(parentId: string, materialType: string, title?: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('title', title || '');
    httpParams = httpParams.append('parentId', parentId || '');
    httpParams = httpParams.append('materialType', materialType || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-materials-for-parent', {
      params: httpParams
    });
  }

  // Recupera una lista di materiali
  listMaterialsForParent(fromRecord: number, numRecords: number, parentId: string, materialType: string, title?: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    let from = fromRecord;
    let to = numRecords;
    httpParams = httpParams.append('fromRecord', from && from.toString());
    httpParams = httpParams.append('numRecords', to && to.toString());
    httpParams = httpParams.append('title', title || '');
    httpParams = httpParams.append('parentId', parentId || '');
    httpParams = httpParams.append('materialType', materialType || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-materials-for-parent', {
      params: httpParams
    });
  }

  searchItems(searchedText: string, fromRecord: any, numRecords: any, status?: string, sorting?: string, itemTypes?: string | string[], originApplicationName?: string,
    ignoreVisibility?: boolean, withChildsLoaded?: boolean, createEngagement?: boolean, withRegistration?: boolean, objectTypeSpecializations?: string[] | string,
    skipVisibilitiesHandling?: boolean, techSkillsOnly?: boolean, softSkillsOnly?: boolean, techCompetences?: string[], softCompetences?: string[], clusters?: string[],
    functionalAreas?: string[], adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[], argumentsTags?: string[], myOnly?: boolean,
    attributeTypes?: string, courseCategoryTags?: string[], checkLibraryVisibility?: boolean): any {
    // Recupera una lista di item
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    let from = fromRecord;
    let to = numRecords;
    httpParams = httpParams.append('fromRecord', from && from.toString());
    httpParams = httpParams.append('numRecords', to && to.toString());
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('originApplicationName', originApplicationName || '');
    httpParams = httpParams.append('status', status || 'A');
    httpParams = httpParams.append('sorting', sorting || 'S');
    httpParams = httpParams.append('ignoreVisibility', (ignoreVisibility && ignoreVisibility.toString()) || '');
    httpParams = httpParams.append('withChildsLoaded', (withChildsLoaded && withChildsLoaded.toString()) || '');
    httpParams = httpParams.append('createEngagement', (createEngagement && createEngagement.toString()) || '');
    httpParams = httpParams.append('withRegistration', (withRegistration && withRegistration.toString()) || '');
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;

    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectTypeSpecializations', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (myOnly) {
      httpParams = httpParams.append('myOnly', 'true');
    }
    httpParams = skipVisibilitiesHandling ? httpParams.append('skipVisibilitiesHandling', 'true') : httpParams;
    if (attributeTypes && attributeTypes.length) {
      httpParams = httpParams.append('attributeTypes', attributeTypes && typeof attributeTypes == 'string' ? attributeTypes : JSON.stringify(attributeTypes));
    }


    if (checkLibraryVisibility) {
      httpParams = httpParams.append('checkLibraryVisibility', 'true');
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/search-items/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  // Recupera una lista di iniziative da aggiungere ad un oggetto library blended
  searchInitiativesForBlendedLibraryItems(searchedText: string, fromRecord: any, numRecords: any, status?: string, sorting?: string, itemTypes?: string | string[], originApplicationName?: string, ignoreVisibility?: boolean, withChildsLoaded?: boolean, createEngagement?: boolean, withRegistration?: boolean, objectTypeSpecializations?: string[] | string, skipVisibilitiesHandling?: boolean, techSkillsOnly?: boolean, softSkillsOnly?: boolean, techCompetences?: string[], softCompetences?: string[], clusters?: string[], functionalAreas?: string[], adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[], argumentsTags?: string[]): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    let from = fromRecord;
    let to = numRecords;
    httpParams = httpParams.append('fromRecord', from && from.toString());
    httpParams = httpParams.append('numRecords', to && to.toString());
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('originApplicationName', originApplicationName || '');
    httpParams = httpParams.append('status', status || 'A');
    httpParams = httpParams.append('sorting', sorting || 'S');
    httpParams = httpParams.append('ignoreVisibility', (ignoreVisibility && ignoreVisibility.toString()) || '');
    httpParams = httpParams.append('withChildsLoaded', (withChildsLoaded && withChildsLoaded.toString()) || '');
    httpParams = httpParams.append('createEngagement', (createEngagement && createEngagement.toString()) || '');
    httpParams = httpParams.append('withRegistration', (withRegistration && withRegistration.toString()) || '');
    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectTypeSpecializations', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    httpParams = skipVisibilitiesHandling ? httpParams.append('skipVisibilitiesHandling', 'true') : httpParams;
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/search-initiatives-for-blended-library-items', {
      params: httpParams
    });
  }

  // Recupera una lista di item
  searchStandaloneCatalogItems(searchedText: string, fromRecord: any, numRecords: any, status?: string, sorting?: string, itemTypes?: string | string[], originApplicationName?: string, ignoreVisibility?: boolean, withChildsLoaded?: boolean, createEngagement?: boolean, withRegistration?: boolean, objectTypeSpecializations?: string[] | string, skipVisibilitiesHandling?: boolean, techSkillsOnly?: boolean, softSkillsOnly?: boolean, techCompetences?: string[], softCompetences?: string[], clusters?: string[], functionalAreas?: string[], adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[], argumentsTags?: string[]): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    let from = fromRecord;
    let to = numRecords;
    httpParams = httpParams.append('fromRecord', from && from.toString());
    httpParams = httpParams.append('numRecords', to && to.toString());
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('originApplicationName', originApplicationName || '');
    httpParams = httpParams.append('status', status || 'A');
    httpParams = httpParams.append('sorting', sorting || 'S');
    httpParams = httpParams.append('ignoreVisibility', (ignoreVisibility && ignoreVisibility.toString()) || '');
    httpParams = httpParams.append('withChildsLoaded', (withChildsLoaded && withChildsLoaded.toString()) || '');
    httpParams = httpParams.append('createEngagement', (createEngagement && createEngagement.toString()) || '');
    httpParams = httpParams.append('withRegistration', (withRegistration && withRegistration.toString()) || '');
    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectTypeSpecializations', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    httpParams = skipVisibilitiesHandling ? httpParams.append('skipVisibilitiesHandling', 'true') : httpParams;
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/search-standalone-catalog-items/' + fromRecord + '/' + numRecords, {
      params: httpParams
    });
  }

  getSuggestedTags(numRecords, itemTypes?: string[], minItemsPerTag?: number, forCatalog?: boolean): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (minItemsPerTag) {
      httpParams = httpParams.append('minItemsPerTag', minItemsPerTag && minItemsPerTag.toString());
    }
    if (forCatalog) {
      httpParams = httpParams.append('forCatalog', 'true');
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/suggest-tags/' + numRecords, {
      params: httpParams
    });
  }

  // Recupera una lista di item
  suggestedItems(numRecords: any, withRegistration?: boolean, techSkillsOnly?: boolean, softSkillsOnly?: boolean): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    let to = numRecords;
    httpParams = httpParams.append('numRecords', to && to.toString());
    httpParams = httpParams.append('fromRecord', '0');
    httpParams = httpParams.append('withRegistration', (withRegistration && withRegistration.toString()) || '');
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-suggested-items', {
      params: httpParams
    });
  }

  // Recupera una lista di corsi dell'utente
  getCoursesListByUserId(fromRecord: any, numRecords: any, fromDate?, toDate?, courseUserStatuses?: string | string[], courseType?: string, allData?: boolean, past?: boolean, future?: boolean, searchedText?: string, sort?: string, desc?: boolean, asConsumableItem?: boolean, withUserCatalogActions?: boolean, searchOnEnrolls?: boolean, withEditionSingleDays?: boolean): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    httpParams = httpParams.append('startDate', (fromDate && fromDate.toString()) || '');
    httpParams = httpParams.append('endDate', (toDate && toDate.toString()) || '');
    httpParams = httpParams.append('courseType', courseType || '');
    httpParams = httpParams.append('allData', (allData && allData.toString()) || '');
    httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
    httpParams = httpParams.append('searchedText', (searchedText && searchedText.toString()) || '');
    if (past) {
      httpParams = httpParams.append('past', (past && past.toString()) || '');
    }
    if (future) {
      httpParams = httpParams.append('future', (future && future.toString()) || '');
    }
    if (asConsumableItem) {
      httpParams = httpParams.append('asConsumableItem', (asConsumableItem && asConsumableItem.toString()) || '');
    }
    if (withUserCatalogActions) {
      httpParams = httpParams.append('withUserCatalogActions', (withUserCatalogActions && withUserCatalogActions.toString()) || '');
    }
    httpParams = httpParams.append('desc', desc && desc.toString() || '');
    if (courseUserStatuses && courseUserStatuses.length) {
      httpParams = httpParams.append('courseUserStatuses', courseUserStatuses && typeof courseUserStatuses == 'string' ? courseUserStatuses : JSON.stringify(courseUserStatuses));
    }
    // Se true, andò ad utilizzare la ricerca sugli enrolls introdotta più di recente lato be
    if (searchOnEnrolls) {
      httpParams = httpParams.append('searchOnEnrolls', `${searchOnEnrolls}`);
    }

    if (withEditionSingleDays) {
      httpParams = httpParams.append('withEditionSingleDays', (withEditionSingleDays && withEditionSingleDays.toString()) || '');
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-courses-list-by-userId', {
      params: httpParams
    });
  }

  // Recupera una lista di corsi dell'utente
  getCoursesListByUserIdFromAttendance(fromRecord: any, numRecords: any, fromDate?, toDate?, courseUserStatuses?: string | string[], courseType?: string, allData?: boolean, past?: boolean, future?: boolean, description?: string, sort?: string, desc?: boolean, asConsumableItem?: boolean, withUserCatalogActions?: boolean): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    httpParams = httpParams.append('startDate', (fromDate && fromDate.toString()) || '');
    httpParams = httpParams.append('endDate', (toDate && toDate.toString()) || '');
    httpParams = httpParams.append('courseType', courseType || '');
    httpParams = httpParams.append('allData', (allData && allData.toString()) || '');
    httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
    httpParams = httpParams.append('searchedText', (description && description.toString()) || '');
    httpParams = httpParams.append('desc', desc && desc.toString() || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-courses-list-by-userId-from-attendance', {
      params: httpParams
    });
  }

  searchStandaloneCatalogCourses(searchedText: string, fromRecord: any, numRecords: any, fromDate?, toDate?, courseUserStatuses?: string | string[], courseType?: string,
    allData?: boolean, past?: boolean, future?: boolean, description?: string, sort?: string, desc?: boolean, asConsumableItem?: boolean, withUserCatalogActions?: boolean,
    techSkillsOnly?: boolean, softSkillsOnly?: boolean, itemTypes?: string | string[], objectTypeSpecializations?: string[] | string, techCompetences?: string[],
    softCompetences?: string[], clusters?: string[], functionalAreas?: string[], adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[],
    argumentsTags?: string[], courseCategoryTags?: Array<string>): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    httpParams = httpParams.append('startDate', (fromDate && fromDate.toString()) || '');
    httpParams = httpParams.append('endDate', (toDate && toDate.toString()) || '');
    httpParams = httpParams.append('courseType', courseType || '');
    httpParams = httpParams.append('allData', (allData && allData.toString()) || '');
    httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('description', (description && description.toString()) || '');
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    if (past) {
      httpParams = httpParams.append('past', (past && past.toString()) || '');
    }
    if (future) {
      httpParams = httpParams.append('future', (future && future.toString()) || '');
    }
    if (asConsumableItem) {
      httpParams = httpParams.append('asConsumableItem', (asConsumableItem && asConsumableItem.toString()) || '');
    }
    if (withUserCatalogActions) {
      httpParams = httpParams.append('withUserCatalogActions', (withUserCatalogActions && withUserCatalogActions.toString()) || '');
    }
    httpParams = httpParams.append('desc', desc && desc.toString() || '');
    if (courseUserStatuses && courseUserStatuses.length) {
      httpParams = httpParams.append('courseUserStatuses', courseUserStatuses && typeof courseUserStatuses == 'string' ? courseUserStatuses : JSON.stringify(courseUserStatuses));
    }

    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectType', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (courseCategoryTags && courseCategoryTags.length) {
      httpParams = httpParams.append('courseCategoryTag', courseCategoryTags && typeof courseCategoryTags == 'string' ? courseCategoryTags : JSON.stringify(courseCategoryTags));
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-standalone-catalog-courses', {
      params: httpParams
    });
  }

  // Recupera il count lista di corsi dell'utente
  countCourseListByUser(startDate?, endDate?, courseUserStatuses?: string | string[], courseType?: string, past?: boolean, future?: boolean, searchedText?: string, asConsumableItem?: boolean, searchOnEnrolls?: boolean): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startDate', (startDate && startDate.toString()) || '');
    httpParams = httpParams.append('endDate', (endDate && endDate.toString()) || '');
    httpParams = httpParams.append('courseType', courseType || '');
    httpParams = httpParams.append('searchedText', (searchedText && searchedText.toString()) || '');
    if (past) {
      httpParams = httpParams.append('past', (past && past.toString()) || '');
    }
    if (future) {
      httpParams = httpParams.append('future', (future && future.toString()) || '');
    }
    if (asConsumableItem) {
      httpParams = httpParams.append('asConsumableItem', (asConsumableItem && asConsumableItem.toString()) || '');
    }
    if (courseUserStatuses && courseUserStatuses.length) {
      httpParams = httpParams.append('courseUserStatuses', courseUserStatuses && typeof courseUserStatuses == 'string' ? courseUserStatuses : JSON.stringify(courseUserStatuses));
    }
    // Se true, andò ad utilizzare la ricerca sugli enrolls introdotta più di recente lato be
    if (searchOnEnrolls) {
      httpParams = httpParams.append('searchOnEnrolls', `${searchOnEnrolls}`);
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-course-list-by-user', {
      params: httpParams
    });
  }

  // Recupera il count lista di corsi dell'utente
  countCourseListByUserFromAttendance(startDate?, endDate?, courseUserStatuses?: string | string[], courseType?: string, past?: boolean, future?: boolean, description?: string, asConsumableItem?: boolean): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startDate', (startDate && startDate.toString()) || '');
    httpParams = httpParams.append('endDate', (endDate && endDate.toString()) || '');
    httpParams = httpParams.append('courseType', courseType || '');
    httpParams = httpParams.append('searchedText', (description && description.toString()) || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-course-list-by-user-from-attendance', {
      params: httpParams
    });
  }

  // Lista di corsi a catalogo
  countStandaloneCatalogCourses(searchedText: string, startDate?, endDate?, courseUserStatuses?: string | string[], courseType?: string, past?: boolean, future?: boolean,
    description?: string, asConsumableItem?: boolean, techSkillsOnly?: boolean, softSkillsOnly?: boolean, itemTypes?: string | string[], objectTypeSpecializations?: string[] | string,
    techCompetences?: string[], softCompetences?: string[], clusters?: string[], functionalAreas?: string[], adminTags?: string[], streamTags?: string[], levels?: string[],
    langs?: string[], argumentsTags?: string[], courseCategoryTags?: Array<string>): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    httpParams = httpParams.append('startDate', (startDate && startDate.toString()) || '');
    httpParams = httpParams.append('endDate', (endDate && endDate.toString()) || '');
    httpParams = httpParams.append('courseType', courseType || '');
    httpParams = httpParams.append('description', (description && description.toString()) || '');
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    if (past) {
      httpParams = httpParams.append('past', (past && past.toString()) || '');
    }
    if (future) {
      httpParams = httpParams.append('future', (future && future.toString()) || '');
    }
    if (asConsumableItem) {
      httpParams = httpParams.append('asConsumableItem', (asConsumableItem && asConsumableItem.toString()) || '');
    }
    if (courseUserStatuses && courseUserStatuses.length) {
      httpParams = httpParams.append('courseUserStatuses', courseUserStatuses && typeof courseUserStatuses == 'string' ? courseUserStatuses : JSON.stringify(courseUserStatuses));
    }

    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectType', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (courseCategoryTags && courseCategoryTags.length) {
      httpParams = httpParams.append('courseCategoryTag', courseCategoryTags && typeof courseCategoryTags == 'string' ? courseCategoryTags : JSON.stringify(courseCategoryTags));
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-standalone-catalog-courses', {
      params: httpParams
    });
  }



  getMediaDownloadUrl(attachemntId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-media-download-url/' + attachemntId, {
      entryId: attachemntId
    });
  }

  // Elimina la condivisione di un elemento nel dettaglio della cassetta degli attrezzi
  glpRemoveItemVisibility(itemId: string, visibilityId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/remove-item-visibility/', {
      itemId,
      visibilityId
    });
  }

  createNote(title: string, description: string, noteType: string, referenceId?: string, referenceType?: string, referenceApplicationName?: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-note', {
      title: title,
      description: description,
      noteType: noteType,
      referenceId: referenceId,
      referenceType: referenceType,
      referenceApplicationName: referenceApplicationName
    });
  }

  updateNote(noteId: string, title: string, description: string, noteType: string, referenceId?: string, referenceApplicationName?: string, referenceType?: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/update-note', {
      noteId: noteId,
      title: title,
      description: description,
      noteType: noteType,
      referenceId: referenceId,
      referenceApplicationName: referenceApplicationName,
      referenceType: referenceType
    });
  }

  // Recupera il counter totale persone all'interno del proprio Tenant
  countPersonsInTenant(searchedText?: string, includeMe?: boolean): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText);
    }
    if (includeMe) {
      httpParams = httpParams.append('includeMe', includeMe.toString());
    }
    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-persons-in-tenant', {
      params: httpParams
    });
  }

  createNewFolder(newTag: any): any {
    return this.http.post<SenecaResponse<any[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-user-folder', {
      tag: newTag
    });
  }

  deleteFolder(folderId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-user-folder', {
      tagId: folderId
    });
  }

  deleteItemForTrainingPassport(item: any, itemId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-item-for-training-passport', {
      item: item,
      itemId: itemId
    });
  }

  // Fa l'upload dell'elemento nel dettaglio della cassetta degli attrezzi
  uploadAttachment(uploadObj: any) {
    const formData = new FormData();
    formData.append('addCustomPlayerId', uploadObj.addCustomPlayerId);
    formData.append('uploadSection', uploadObj.uploadSection);
    formData.append('uploadType', uploadObj.uploadType);
    formData.append('referenceType', uploadObj.referenceType);
    formData.append('referenceId', uploadObj.referenceId);
    formData.append('fromAdmin', uploadObj.fromAdmin);
    formData.append('name', uploadObj.name);
    formData.append('fileName', uploadObj.fileName);
    formData.append('token', uploadObj.token);
    formData.append('file', uploadObj.file.file.rawFile);
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/upload-attachment', formData);
  }

  uploadAnyFile(uploadObj) {
    let formData = new FormData();
    formData.append('file', uploadObj.file.file.rawFile);
    // formData.append('entryId', uploadObj.entryId || '');
    formData.append('title', uploadObj.fileName || '');
    formData.append('createItem', uploadObj.createItem || '');
    formData.append('referenceId', uploadObj.referenceId);
    formData.append('referenceType', uploadObj.referenceType);
    formData.append('referenceApplicationName', uploadObj.referenceApplicationName);
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/upload-any-file', formData);
  }

  uploadAnyFileForToolbox(uploadObj) {
    let formData = new FormData();
    formData.append('file', uploadObj.file.file.rawFile);
    // formData.append('entryId', uploadObj.entryId || '');
    formData.append('title', uploadObj.fileName || '');
    formData.append('createItem', uploadObj.createItem || '');
    formData.append('referenceId', uploadObj.referenceId);
    formData.append('referenceType', uploadObj.referenceType);
    formData.append('referenceApplicationName', uploadObj.referenceApplicationName);
    formData.append('uploadType', uploadObj.uploadType);
    formData.append('uploadSection', uploadObj.uploadSection);
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/upload-any-file-for-toolbox', formData);
  }



  // Recupera una lista di persone all'interno del proprio Tenant
  getPersonsInTenant(fromRecord: number, numRecords: number, searchedText?: string, includeMe?: boolean): any {
    let from = fromRecord && fromRecord.toString();
    let to = numRecords && numRecords.toString() || '25';
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', from || '0');
    httpParams = httpParams.append('numRecords', to);
    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText || '');
    }
    if (includeMe) {
      httpParams = httpParams.append('includeMe', includeMe.toString());
    }
    return this.http.get<SenecaResponse<any[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-persons-in-tenant/' + from + '/' + to, {
      params: httpParams
    });
  }

  deleteNote(noteId: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('noteId', noteId || '');
    return this.http.delete<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-note/' + noteId, {
      params: httpParams
    });
  }

  // Condivide un elemento nel dettaglio della cassetta degli attrezzi
  addItemVisibilities(itemId: string, notifyShare: boolean, recipients: string[], customText: string, withAllVisibilitiesLoaded: boolean, loadInvitedInstead: boolean) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/share-user-file', {
      itemId,
      notifyShare,
      recipients,
      customText,
      withAllVisibilitiesLoaded,
      loadInvitedInstead,
      createEngagement: true
    });
  }

  findNotes(allData: boolean, notes: string, fromRecord?: number, numRecords?: number, referenceIds?: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('allData', allData && allData.toString());
    httpParams = httpParams.append('noteTypes', notes);

    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    if (referenceIds && referenceIds.length) {
      referenceIds.forEach(referenceIds => {
        httpParams = httpParams.append('referenceIds', referenceIds);
      });
    }

    return this.http.get<SenecaResponse<Note[]>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/find-notes`, {
      params: httpParams
    });
  }

  glpListItemsByAnyForTrainingPassport(allData: boolean, fromRecord: number, numRecords: number, itemTypes: string[], referenceId: string, referenceType: string, getUploadInfo: boolean, isUserUploadedContent: boolean, addCustomPlayerId: boolean, withAttributesLoaded: true, excludeMyItems: boolean, withAllVisibilitiesLoaded: boolean, loadInvitedInstead: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('allData', allData && allData.toString());
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    if (itemTypes && itemTypes.length) {
      itemTypes.forEach(itemTypes => {
        httpParams = httpParams.append('itemTypes', itemTypes);
      });
    }
    httpParams = httpParams.append('referenceId', referenceId);
    httpParams = httpParams.append('referenceType', referenceType);
    httpParams = httpParams.append('getUploadInfo', getUploadInfo && getUploadInfo.toString());
    httpParams = httpParams.append('isUserUploadedContent', true.toString());
    httpParams = httpParams.append('addCustomPlayerId', addCustomPlayerId && addCustomPlayerId.toString());
    httpParams = httpParams.append('withAttributesLoaded', true.toString());
    httpParams = httpParams.append('excludeMyItems', excludeMyItems && excludeMyItems.toString());
    httpParams = httpParams.append('withAllVisibilitiesLoaded', withAllVisibilitiesLoaded && withAllVisibilitiesLoaded.toString());
    httpParams = httpParams.append('loadInvitedInstead', loadInvitedInstead && loadInvitedInstead.toString());

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/list-items-by-any-for-training-passport`, {
      params: httpParams
    });
  }

  // Recupera il count della lista corsi a cui si è pre-iscritti
  countPreEnrolledCourseListByUser(startDate?: Date, endDate?: Date, past?: boolean, future?: boolean, sort?: String): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startDate', (startDate && startDate.toString()) || '');
    httpParams = httpParams.append('endDate', (endDate && endDate.toString()) || '');
    httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
    if (past) {
      httpParams = httpParams.append('past', (past && past.toString()) || '');
    }
    if (future) {
      httpParams = httpParams.append('future', (future && future.toString()) || '');
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-preregistered-courses-list-by-userId', {
      params: httpParams
    });
  }

  // Retrives the course in which the user is pre registered
  getPreEnrolledCoursesListByUserId(fromRecord: any, numRecords: any, fromDate?: number, toDate?: number, allData?: boolean, past?: boolean, future?: boolean, sort?: string): any {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    httpParams = httpParams.append('startDate', (fromDate && fromDate.toString()) || '');
    httpParams = httpParams.append('endDate', (toDate && toDate.toString()) || '');
    httpParams = httpParams.append('allData', (allData && allData.toString()) || '');
    httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
    if (past) {
      httpParams = httpParams.append('past', (past && past.toString()) || '');
    }
    if (future) {
      httpParams = httpParams.append('future', (future && future.toString()) || '');
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-preregistered-courses-list-by-userId/${fromRecord && fromRecord.toString()}/${numRecords && numRecords.toString()}', {
      params: httpParams
    });
  }

  // Recupera una lista di item
  listItemsByAny(searchedText?: string, fromRecord?: number, numRecords?: string, allData?: boolean, referenceId?: string[], referenceApplicationName?: string[], referenceType?: string[], originApplicationName?: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', (fromRecord && fromRecord.toString()) || '');
    httpParams = httpParams.append('numRecords', (numRecords && numRecords.toString()) || '');
    httpParams = httpParams.append('allData', (allData && allData.toString()) || '');
    httpParams = httpParams.append('originApplicationName', (originApplicationName && originApplicationName.toString()) || '');
    if (referenceId && referenceId.length) {
      referenceId.forEach(referenceId => {
        httpParams = httpParams.append('referenceId', referenceId);
      });
    }
    if (referenceApplicationName && referenceApplicationName.length) {
      referenceApplicationName.forEach(referenceApplicationName => {
        httpParams = httpParams.append('referenceApplicationName', referenceApplicationName);
      });
    }

    if (referenceType && referenceType.length) {
      referenceType.forEach(referenceType => {
        httpParams = httpParams.append('referenceType', referenceType);
      });
    }
    httpParams = httpParams.append('searchedText', searchedText || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-items-by-any', {
      params: httpParams
    });
  }

  // Torna il percorso di una tappa (utilizato anche per identificare se un oggetto corso è in realtà una tappa, ovvero è contenuto in un percorso)
  getPathFromStage(stageItemId: string): any {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('stageItemId', stageItemId);
    return this.http.get<SenecaResponse<TextTemplate[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-path-from-stage/' + stageItemId, {
      params: httpParams
    });
  }

  getCompetences(allData, fromRecord?, numRecords?) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('allData', allData);
    if (fromRecord) {
      httpParams = httpParams.append('fromRecord', fromRecord);
    }
    if (numRecords) {
      httpParams = httpParams.append('numRecords', numRecords);
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-acquired-competence-levels', {
      params: httpParams
    });
  }

  getDesiredCompetence(allData, fromRecord?, numRecords?) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('allData', allData);
    if (fromRecord) {
      httpParams = httpParams.append('fromRecord', fromRecord);
    }
    if (numRecords) {
      httpParams = httpParams.append('numRecords', numRecords);
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-desired-competence-levels', {
      params: httpParams
    });
  }

  getAllCompetences(allData, fromRecord?, numRecords?) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('allData', allData);
    if (fromRecord) {
      httpParams = httpParams.append('fromRecord', fromRecord);
    }
    if (numRecords) {
      httpParams = httpParams.append('numRecords', numRecords);
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-competence-award-names', {
      params: httpParams
    });
  }

  getLevels(competenceId) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('competenceAwardId', competenceId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-competence-scale-levels-by-award-id', {
      params: httpParams
    });
  }

  createDesiredCompetence(competenceAwardId, desiredLevelId, referenceDate) {
    return this.http.post<any>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-desired-competence', {
      competenceAwardId: competenceAwardId,
      desiredLevelId: desiredLevelId,
      referenceDate: referenceDate
    });
  }

  updateDesiredCompetence(competenceAwardId, desiredLevel, referenceDate, levelAttributeId) {
    return this.http.post<any>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/update-desired-competence', {
      competenceAwardId: competenceAwardId,
      desiredLevelId: desiredLevel,
      referenceDate: referenceDate,
      levelAttributeId: levelAttributeId
    });
  }

  deleteDesiredCompetence(levelAttributeId) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('levelAttributeId', levelAttributeId);
    return this.http.delete<any>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-desired-competence', {
      params: httpParams
    });
  }

  // Torna un percorso dato il suo Id
  getPathFromIdForAdmin(pathItemId: string): any {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('pathItemId', pathItemId);
    return this.http.get<SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-path-from-id-for-admin/' + pathItemId, {
      params: httpParams
    });
  }

  // Torna uno Stage dato il suo Id
  getStageFromIdForAdmin(stageItemId: string): any {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('stageItemId', stageItemId);
    httpParams = httpParams.append('withFullAttributesLoaded', 'true');
    httpParams = httpParams.append('withFullChildrenAttributesLoaded', 'true');
    httpParams = httpParams.append('itemAttributeTypesToResolve',
      JSON.stringify([ItemAttributeTypes.LOCATION, ItemAttributeTypes.TUTOR, ItemAttributeTypes.TEACHER]));
    return this.http.get<SenecaResponse<TextTemplate[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-stage-from-id-for-admin/' + stageItemId, {
      params: httpParams
    });
  }

  // Servizio che recupera un Item dato il suo id
  getItemByIdForAdmin(itemId: string, withChildsLoaded?: boolean) {
    return this.itemByIdForAdmin(itemId, withChildsLoaded);
  }

  // Servizio che recupera un Item dato il suo id
  getItemById(itemId: string, ignoreVisibility?: boolean, withChildsLoaded?: boolean, withRegistration?: boolean, createEngagement?: boolean, withAttributesLoaded?: boolean, withAllVisibiltiesLoaded?: boolean, withoutPlayerForImage?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    httpParams = httpParams.append('ignoreVisibility', (ignoreVisibility && ignoreVisibility.toString()) || '');
    httpParams = httpParams.append('withChildsLoaded', (withChildsLoaded && withChildsLoaded.toString()) || '');
    httpParams = httpParams.append('withRegistration', (withRegistration && withRegistration.toString()) || '');
    httpParams = httpParams.append('createEngagement', (createEngagement && createEngagement.toString()) || '');
    httpParams = httpParams.append('withoutPlayerForImage', (withoutPlayerForImage && withoutPlayerForImage.toString()) || '');
    httpParams = httpParams.append('withAllVisibiltiesLoaded', (withAllVisibiltiesLoaded && withAllVisibiltiesLoaded.toString()) || '');
    httpParams = httpParams.append('withAttributesLoaded', (withAttributesLoaded && withAttributesLoaded.toString()) || '');
    httpParams = httpParams.append('skipVisibilitiesHandling', 'true');
    httpParams = httpParams.append('originApplicationName', CommonConstants.APPLICATION_CORPORATE_ACADEMY);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-item-by-id/' + itemId, {
      params: httpParams
    });
  }

  getItemBlendedParentItems(itemId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-item-blended-parent-items', {
      params: httpParams
    });
  }

  mapConsumableItemToIDataItem(item: ConsumableItem, translate?) {
    if (item) {
      let itemAttributes = item.itemAttributes;
      let itemTenants = item.itemTenants;
      let certificationDate = item.certificationDate;
      let scormRegistration = item.scormRegistration;
      let stars = ItemUtil.getStars(item) || null;
      let wishlistEnabled = ItemUtil.isWishlistEnabled(item);
      let isInCatalog = ItemUtil.isInCatalog(item);
      let isActivePreiscrition = ItemUtil.isActivePreiscrition(item);
      let startDatePreiscrition = ItemUtil.startDatePreiscrition(item);
      let endDatePreiscrition = ItemUtil.endDatePreiscrition(item);
      let onWhatPreiscrition = ItemUtil.onWhatPreiscrition(item);
      let isCourseCertificationEnabled = ItemUtil.isCourseCertificationEnabled(item);
      let itemTypeLabel = ItemUtil.getItemTypeLabel(item, translate) || null;
      let cardCover = item.backgroundImgUrl || ItemUtil.getCardCover(item) || './assets/img/card-background.jpg';
      let hourValue = ItemUtil.getItemHourValue(item, translate) || null;
      let cardTypeLabel = ItemUtil.getItemCardType(item, translate) || null;
      let isAtomicItem = ItemUtil.isAtomicItem(item);
      let isVideo = ItemUtil.isVideo(item);
      let isImage = ItemUtil.isImage(item);
      let isProject = ItemUtil.isProject(item);
      let isCourseClassStage = ItemUtil.isCourseClassStage(item);
      let isExternalOnlineStage = ItemUtil.isExternalOnlineStage(item);
      let isExternalCourseStage = ItemUtil.isExternalCourseStage(item);
      let isEventOnlineStage = ItemUtil.isEventOnlineStage(item);
      let isEventClassStage = ItemUtil.isEventClassStage(item);
      let isAssessmentStage = ItemUtil.isAssessmentStage(item);
      let isWebinarStage = ItemUtil.isWebinarStage(item);
      let isBlendedProject = ItemUtil.isBlendedProject(item);
      let isItemOtherOrPhysicalType = ItemUtil.isPhysicalItem(item) || ItemUtil.isOtherTypeItem(item);
      let isLearningPlan = ItemUtil.isPlaylistPlan(item);
      let isLearningPlanBlended = ItemUtil.isLearningPlan(item);
      let isContainerItem = ItemUtil.isContainerItem(item);
      let subtype = ItemUtil.getItemObjectSubType(item);
      let isSyllabusCourse = ItemUtil.isSyllabusCourse(item);
      let isScorm = ItemUtil.isScormItem(item);
      let itemTakers = item.itemTakers;

      let completionPercentage = '';
      completionPercentage = (item?.completionPercentage || '0') + '%';
      // if (!isCourseClassStage && !isExternalOnlineStage && !isWebinarStage && !isEventOnlineStage && !isAssessmentStage && !isEventClassStage && !isExternalCourseStage) {}

      let _IDataItem = <IDataItem>{
        itemId: item.itemId,
        itemType: item.itemType,
        mimeType: item.mimeType,
        itemRegistration: item.itemRegistration,
        competences: (<any>item).competences,
        isCourseCertificationEnabled: isCourseCertificationEnabled,
        certificationDate: certificationDate,
        externalObjectId: item.externalObjectId,
        damObjectUrl: item.damObjectUrl,
        damVideoSubtitle: item.videoSubtitles,
        extendedItem: item.extendedItem,
        itemAttributes: itemAttributes,
        catalogItemRequestDate: item.catalogItemRequestDate,
        name: item.title && item.title.trim(),
        subTitle: item.subTitle && item.subTitle.trim(),
        description: item.description && item.description.trim(),
        imgBackground: (<any>item).backgroundImgUrl || null,
        azureImage: item && item.mimeType && item.mimeType.includes("image") ? item.damObjectUrl : null,
        itemTypeLabel: itemTypeLabel,
        subtype: subtype,
        isProject: isProject,
        isBlendedProject: isBlendedProject,
        itemLangs: item.itemLangs || [],
        parentBlendedItems: item.parentBlendedItems || [],
        parentBlendedStages: item.parentBlendedStages || [],
        userEnrolledToEditions: (<any>item).userEnrolledToEditions,
        availableEditionsCount: (<any>item).availableEditionsCount,
        isLearningPlan: isLearningPlan,
        isLearningPlanBlended: isLearningPlanBlended,
        isItemOtherOrPhysicalType: isItemOtherOrPhysicalType,
        isVideo: isVideo,
        isImage: isImage,
        scormRegistration: scormRegistration,
        cardCover: cardCover,
        hourValue: hourValue,
        cardTypeLabel: cardTypeLabel,
        completionPercentage: completionPercentage,
        isCertified: item.isCertified,
        isConsumed: item.isConsumed,
        isAtomicItem: isAtomicItem,
        isContainerItem: isContainerItem,
        isSyllabusCourse: isSyllabusCourse,
        isScorm: isScorm,
        itemTenants: itemTenants,
        itemChilds: item.itemChilds,
        childrenCount: item.childrenCount || 0,
        wishlistEnabled: wishlistEnabled,
        isBookmarked: item.isBookmarked,
        stars: stars,
        existsItemTakerVisibility: item && (item.existsItemTakerVisibility || (item.itemTakers && item.itemTakers.length)),
        haveTakerVisibility: item && item.haveTakerVisibility,
        itemTakers: itemTakers,
        isInCatalog: isInCatalog,
        isActivePreiscrition: isActivePreiscrition,
        startDatePreiscrition: startDatePreiscrition,
        endDatePreiscrition: endDatePreiscrition,
        onWhatPreiscrition: onWhatPreiscrition
      }
      let children: ItemChild[] = null;
      if (_IDataItem.itemChilds && _IDataItem.itemChilds) {
        children = _IDataItem.itemChilds.filter(ic => !!ic.childObject && ic.childObject.itemAttributes && ic.childObject.itemAttributes.length) || [];
      }
      // Se sono presenti dei figli ed è esplicitamente richiesto, carico ricorsivamente anche gli oggetti referenziati dai loro attributi
      if (children && children.length) {
        children.map((child) => {
          child.childObject = <any>this.mapConsumableItemToIDataItem(<any>child.childObject, translate)
        })
      }
      return _IDataItem;
    }
    return null;
  }

  // Servizio che recupera un Item dato il suo id
  getConsumableItemByIdForUser(translate, itemId: string, withChildsLoaded?: boolean, childsTreeDepth?: number, attributeTypesToRetrieve?: string[], createEngagement?: boolean, forPreview?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    if (childsTreeDepth) {
      httpParams = httpParams.append('childsTreeDepth', childsTreeDepth.toString());
    }
    httpParams = httpParams.append('withEnrolls', 'true');
    httpParams = httpParams.append('retrieveCompetences', 'true');
    httpParams = httpParams.append('withChildsLoaded', (withChildsLoaded && withChildsLoaded.toString()) || '');
    httpParams = httpParams.append('forPreview', (forPreview && forPreview.toString()) || '');
    httpParams = httpParams.append('createEngagement', createEngagement ? "true" : "false");
    httpParams = httpParams.append('originApplicationName', CommonConstants.APPLICATION_CORPORATE_ACADEMY);
    httpParams = httpParams.append('referenceApplicationName', CommonConstants.APPLICATION_CORPORATE_ACADEMY);
    if (attributeTypesToRetrieve && attributeTypesToRetrieve.length) {
      attributeTypesToRetrieve && attributeTypesToRetrieve.forEach(type => {
        httpParams = httpParams.append('attributeTypesToRetrieve', type);
      });
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-consumable-item-by-id-for-user', {
      params: httpParams
    })
      .pipe(
        take(1),
        // delay(1000), per test
        map((senecaResponse) => {
          if (senecaResponse && senecaResponse.error) {
            throw senecaResponse.error;
          } else {
            let item = senecaResponse.response || <ConsumableItem>{};

            return this.mapConsumableItemToIDataItem(item, translate);
          }
        })
      );
  }

  // Servizio che recupera un Item dato il suo id
  getConsumableItineraryAsPublic(translate, itemId: string, withChildsLoaded?: boolean, childsTreeDepth?: number, attributeTypesToRetrieve?: string[], createEngagement?: boolean, forPreview?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    if (childsTreeDepth) {
      httpParams = httpParams.append('childsTreeDepth', childsTreeDepth.toString());
    }
    httpParams = httpParams.append('withEnrolls', 'true');
    httpParams = httpParams.append('retrieveCompetences', 'true');
    httpParams = httpParams.append('withChildsLoaded', (withChildsLoaded && withChildsLoaded.toString()) || '');
    httpParams = httpParams.append('forPreview', (forPreview && forPreview.toString()) || '');
    httpParams = httpParams.append('createEngagement', createEngagement ? "true" : "false");
    httpParams = httpParams.append('originApplicationName', CommonConstants.APPLICATION_CORPORATE_ACADEMY);
    httpParams = httpParams.append('referenceApplicationName', CommonConstants.APPLICATION_CORPORATE_ACADEMY);
    if (attributeTypesToRetrieve && attributeTypesToRetrieve.length) {
      attributeTypesToRetrieve && attributeTypesToRetrieve.forEach(type => {
        httpParams = httpParams.append('attributeTypesToRetrieve', type);
      });
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-consumable-itinerary-as-public', {
      params: httpParams
    })
      .pipe(
        take(1),
        // delay(1000), per test
        map((senecaResponse) => {
          if (senecaResponse && senecaResponse.error) {
            throw senecaResponse.error;
          } else {
            let item = senecaResponse.response || <ConsumableItem>{};
            return this.mapConsumableItemToIDataItem(item, translate);
          }
        })
      );
  }

  // Servizio che recupera un Item dato il suo id
  getDraft(itemId: string, ignoreVisibility?: boolean, withChildsLoaded?: boolean, withRegistration?: boolean, createEngagement?: boolean, withAttributesLoaded?: boolean, withAllVisibiltiesLoaded?: boolean, loadInvitedInstead?: boolean, skipVisibilitiesHandling?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    httpParams = httpParams.append('ignoreVisibility', (ignoreVisibility && ignoreVisibility.toString()) || '');
    httpParams = httpParams.append('withChildsLoaded', (withChildsLoaded && withChildsLoaded.toString()) || '');
    httpParams = httpParams.append('withRegistration', (withRegistration && withRegistration.toString()) || '');
    httpParams = httpParams.append('createEngagement', (createEngagement && createEngagement.toString()) || '');
    httpParams = httpParams.append('withAllVisibiltiesLoaded', (withAllVisibiltiesLoaded && withAllVisibiltiesLoaded.toString()) || '');
    httpParams = httpParams.append('withAttributesLoaded', (withAttributesLoaded && withAttributesLoaded.toString()) || '');
    httpParams = httpParams.append('loadInvitedInstead', (loadInvitedInstead && loadInvitedInstead.toString()) || '');
    httpParams = skipVisibilitiesHandling ? httpParams.append('skipVisibilitiesHandling', 'true') : httpParams;
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-draft-by-id/' + itemId, {
      params: httpParams
    });
  }

  // Servizio che recupera un Item dato il suo id
  getItemTitle(itemId: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    return this.http.get<SenecaResponse<string>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-item-title-by-id/' + itemId, {
      params: httpParams
    });
  }

  // Servizio che recupera il certificato di un item
  getCertificate(itemId: string, asImage?: boolean, getFullPath?: boolean, isPreview?: boolean, onlinefilePath?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    httpParams = asImage ? httpParams.append('asImage', 'true') : httpParams;
    httpParams = httpParams.append('isPreview', (isPreview && isPreview.toString()) || '');
    httpParams = getFullPath ? httpParams.append('getFullPath', 'true') : httpParams;
    httpParams = onlinefilePath ? httpParams.append('onlinefilePath', 'true') : httpParams;
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-certificate', {
      params: httpParams
    });
  }

  // Servizio che recupera la lista di persone suggeritrici
  getItemPrompterList(itemId: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-item-prompter-list', {
      params: httpParams
    });
  }

  // Servizio che recupera i like di un item
  getLikesCountsByTypesAndReference(referenceId: string, referenceType: string, likeType: string, originApplicationName: string, referenceApplicationName: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('referenceId', referenceId);
    httpParams = httpParams.append('referenceType', referenceType);
    httpParams = httpParams.append('likeType', likeType);
    httpParams = httpParams.append('originApplicationName', originApplicationName);
    httpParams = httpParams.append('referenceApplicationName', referenceApplicationName);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-likes-counts-by-types-and-reference', {
      params: httpParams
    });
  }

  // Elimina un like
  deleteLike(likeId: string, originApplicationName: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('likeId', likeId || '');
    httpParams = httpParams.append('originApplicationName', originApplicationName || '');
    return this.http.delete<SenecaResponse<null>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-like-by-id/' + likeId, {
      params: httpParams
    });
  }

  // Servizio che recupera i like dell'utente loggato
  getMyLikeByTypeAndReference(referenceId: string, referenceType: string, likeType: string, originApplicationName: string, referenceApplicationName: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('referenceId', referenceId);
    httpParams = httpParams.append('referenceType', referenceType);
    httpParams = httpParams.append('likeType', likeType);
    httpParams = httpParams.append('originApplicationName', originApplicationName);
    httpParams = httpParams.append('referenceApplicationName', referenceApplicationName);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-my-like-by-type-and-reference', {
      params: httpParams
    });
  }

  // Servizio che recupera i tenant dell'utente loggato
  getTenantsByIds(tenantIds: string[]) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    if (tenantIds && tenantIds.length) {
      httpParams = httpParams.append('tenantIds', tenantIds && typeof tenantIds == 'string' ? tenantIds : JSON.stringify(tenantIds));
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-tenants-by-ids', {
      params: httpParams
    });
  }

  // Aggiunta una visibilità (suggerimento) ad un utente
  addSuggestion(destinationUser: any, itemId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/add-suggestion', {
      destinationUser: destinationUser,
      itemId: itemId
    });
  }

  // Recupera il link per scaricare un allegato
  getAttachmentUrl(attachmentId: string, adminMode: boolean, item: Item, forcedItemIdEngagement: string, forceCreateConsumedEngagement: boolean, isItemOtherType: boolean) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-attachment-url/' + attachmentId, {
      attachmentId: attachmentId,
      adminMode: adminMode,
      item: item,
      forcedItemIdEngagement: forcedItemIdEngagement,
      forceCreateConsumedEngagement: forceCreateConsumedEngagement,
      isItemOtherType: isItemOtherType
    });
  }

  // Crea un like
  createLike(like, referenceId: string, referenceType: string, originApplicationName: string, referenceApplicationName: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-like', {
      like: like,
      referenceId: referenceId,
      referenceType: referenceType,
      originApplicationName: originApplicationName,
      referenceApplicationName: referenceApplicationName
    });
  }

  // Aggiorna una bozza
  updateDraft(item: Item) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/update-draft', {
      item: item
    });
  }

  // Conferma una bozza
  confirmDraft(itemId: string, indexItem?: boolean, addPublicVisibility?: boolean, withChildsLoaded?: boolean) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/confirm-draft', {
      itemId: itemId,
      indexItem: indexItem,
      originApplicationName: CommonConstants.APPLICATION_CORPORATE_ACADEMY,
      addPublicVisibility: addPublicVisibility,
      withChildsLoaded: withChildsLoaded
    });
  }

  // Crea un learning plan [chiamato playlist]
  createLearningPlan(item: Item, adminMode: boolean, addPublicVisibility: boolean, originApplicationName: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-learning-plan', {
      item: item,
      adminMode: adminMode,
      addPublicVisibility: addPublicVisibility,
      originApplicationName: originApplicationName
    });
  }

  // Crea un progetto
  createProject(item: Item, adminMode: boolean, addPublicVisibility: boolean, originApplicationName: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-project', {
      item: item,
      adminMode: adminMode,
      addPublicVisibility: addPublicVisibility,
      originApplicationName: originApplicationName
    });
  }

  // Crea un progetto blended
  createBlendedProject(item: Item, adminMode: boolean, addPublicVisibility: boolean, originApplicationName: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-blended-project', {
      item: item,
      adminMode: adminMode,
      addPublicVisibility: addPublicVisibility,
      originApplicationName: originApplicationName
    });
  }

  // Crea un learning plan
  createSurveyTemplate(surveyTemplates, questionsPerGroupsOfOriginalTemplates, newTemplateQuestionsPerPage, titles, pageTitles, groupTitles,
    referenceType, referenceId, originApplicationName, referenceApplicationName) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-survey-template-from-multiple-templates', {
      surveyTemplates: surveyTemplates,
      questionsPerGroupsOfOriginalTemplates: questionsPerGroupsOfOriginalTemplates,
      newTemplateQuestionsPerPage: newTemplateQuestionsPerPage,
      titles: titles,
      pageTitles: pageTitles,
      groupTitles: groupTitles,
      referenceType: referenceType,
      referenceId: referenceId,
      referenceApplicationName: referenceApplicationName,
      originApplicationName: originApplicationName
    });
  }

  // Servizio che recupera il dettaglio di un progetto
  getProjectById(itemId: string, ignoreVisibility?: boolean, withChildsLoaded?: boolean, withRegistration?: boolean, createEngagement?: boolean, withAttributesLoaded?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);

    httpParams = ignoreVisibility ? httpParams.append('ignoreVisibility', 'true') : httpParams;
    httpParams = withChildsLoaded ? httpParams.append('withChildsLoaded', 'true') : httpParams;
    httpParams = withRegistration ? httpParams.append('withRegistration', 'true') : httpParams;
    httpParams = createEngagement ? httpParams.append('createEngagement', 'true') : httpParams;
    httpParams = withAttributesLoaded ? httpParams.append('withAttributesLoaded', 'true') : httpParams;
    httpParams = httpParams.append('skipVisibilitiesHandling', 'true');
    httpParams = httpParams.append('originApplicationName', CommonConstants.APPLICATION_CORPORATE_ACADEMY);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-item-by-id/' + itemId, {
      params: httpParams
    });
  }

  // Recupera il contatore delle immagini delle card
  countCardCovers(title?: string, ignoreVisibility?: boolean, originApplicationName?: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('title', title || '');
    httpParams = httpParams.append('originApplicationName', originApplicationName || '');
    httpParams = ignoreVisibility ? httpParams.append('ignoreVisibility', 'true') : httpParams;
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-card-covers', {
      params: httpParams
    });
  }

  // Crea un nuovo oggetto multimediale
  createMultimediaItem(uploadObj) {
    let formData = new FormData();
    formData.append('file', uploadObj.file.file.rawFile);
    formData.append('fileName', uploadObj.fileName || '');
    formData.append('itemType', uploadObj.itemType || '');
    formData.append('title', uploadObj.title || '');
    formData.append('fileType', uploadObj.fileType);
    formData.append('itemId', uploadObj.itemId || '');
    // formData.append('parentId', uploadObj.itemId || ''); da usare per i materiali dei corsi
    formData.append('objectType', uploadObj.objectType || '');
    formData.append('draftOnly', uploadObj.draftOnly);
    if (uploadObj.useCoreUpload) {
      formData.append('useCoreUpload', 'true');
    }
    formData.append('originApplicationName', uploadObj.originApplicationName || '');
    formData.append('addPublicVisibility', uploadObj.addPublicVisibility);
    formData.append('adminMode', uploadObj.adminMode);
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-multimedia-item', formData, options);
    return this.http.request(req);
  }

  // Crea un nuovo oggetto multimediale
  createSurveyItem(uploadObj) {
    let formData = new FormData();
    formData.append('itemType', uploadObj.itemType || '');
    formData.append('title', uploadObj.title || '');
    formData.append('objectType', uploadObj.objectType || '');
    formData.append('draftOnly', uploadObj.draftOnly);
    formData.append('originApplicationName', uploadObj.originApplicationName || '');
    formData.append('adminMode', uploadObj.adminMode);
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-survey-item', formData, options);
    return this.http.request(req);
  }

  // Crea un nuovo allegato
  createAttachment(uploadObj) {
    let formData = new FormData();
    formData.append('file', uploadObj.file.file.rawFile);
    formData.append('entryId', uploadObj.entryId || '');
    formData.append('title', uploadObj.title || '');
    formData.append('itemId', uploadObj.itemId || '');
    formData.append('originApplicationName', uploadObj.originApplicationName || '');
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-attachment', formData, options);
    return this.http.request(req);
  }

  // Crea un nuovo oggetto fisico
  createRentableItem(uploadObj) {
    let formData = new FormData();
    formData.append('file', uploadObj.file.file.rawFile);
    formData.append('fileName', uploadObj.fileName || '');
    formData.append('fileType', uploadObj.fileType);
    formData.append('title', uploadObj.title || '');
    formData.append('objectType', uploadObj.objectType || '');
    formData.append('originApplicationName', uploadObj.originApplicationName);
    formData.append('addPublicVisibility', uploadObj.addPublicVisibility);
    formData.append('adminMode', uploadObj.adminMode);
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-rentable-item', formData, options);
    return this.http.request(req);
  }

  // Crea un nuovo oggetto di tipo 'altro'
  createOtherItem(uploadObj) {
    let formData = new FormData();
    formData.append('file', uploadObj.file.file.rawFile);
    formData.append('fileName', uploadObj.fileName);
    formData.append('fileType', uploadObj.fileType);
    formData.append('title', uploadObj.title || '');
    formData.append('objectType', uploadObj.objectType || '');
    formData.append('originApplicationName', uploadObj.originApplicationName);
    formData.append('addPublicVisibility', uploadObj.addPublicVisibility);
    formData.append('adminMode', uploadObj.adminMode);
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-other-item', formData, options);
    return this.http.request(req);
  }

  createExternalItem(uploadObj) {
    let formData = new FormData();
    formData.append('itemType', uploadObj.itemType || '');
    formData.append('title', uploadObj.title || '');
    formData.append('objectType', uploadObj.objectType || '');
    formData.append('draftOnly', uploadObj.draftOnly);
    formData.append('originApplicationName', uploadObj.originApplicationName || '');
    formData.append('adminMode', uploadObj.adminMode);
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-external-item', formData, options);
    return this.http.request(req);


    /* return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-external-item', {
        itemType: uploadObj.itemType,
        title: uploadObj.title,
        objectType: uploadObj.objectType,
        draftOnly: uploadObj.draftOnly,
        originApplicationName: uploadObj.originApplicationName,
        adminMode: uploadObj.adminMode,
    }); */
  }

  // Aggiunge un nuovo survey template
  uploadSurveyTemplate(uploadObj) {
    let formData = new FormData();
    formData.append('file', uploadObj.file.file.rawFile);
    formData.append('surveyType', uploadObj.surveyType);
    formData.append('referenceType', uploadObj.referenceType);
    formData.append('referenceId', uploadObj.referenceId);
    formData.append('originApplicationName', uploadObj.originApplicationName);
    formData.append('referenceApplicationName', uploadObj.referenceApplicationName);
    formData.append('useCustomGroups', 'true');
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/upload-survey-template', formData, options);
    return this.http.request(req);
  }

  // Esegue l'upload di uno scorm
  uploadScorm(uploadObj: any) {
    let formData = new FormData();
    formData.append('file', uploadObj.file.file.rawFile);
    formData.append('courseName', uploadObj.courseName);
    formData.append('fileName', uploadObj.courseName);
    formData.append('title', uploadObj.courseName);
    formData.append('objectType', uploadObj.objectType);
    formData.append('itemType', uploadObj.scormType);
    formData.append('scormType', uploadObj.scormType);
    formData.append('originApplicationName', uploadObj.originApplicationName);
    formData.append('addPublicVisibility', uploadObj.addPublicVisibility);
    formData.append('adminMode', uploadObj.adminMode);
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-multimedia-item', formData);
  }

  // Recupera immagini delle card
  getCardCovers(from, to, title?: string, sorting?: string, itemTypes?: string | string[], ignoreVisibility?: boolean, originApplicationName?: string, skipImageSize?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('title', title || '');
    httpParams = httpParams.append('sorting', sorting || '');
    httpParams = httpParams.append('originApplicationName', originApplicationName || '');
    httpParams = ignoreVisibility ? httpParams.append('ignoreVisibility', 'true') : httpParams;
    httpParams = skipImageSize ? httpParams.append('skipImageSize', 'true') : httpParams;
    httpParams = httpParams.append('fromRecord', from && from.toString());
    httpParams = httpParams.append('numRecords', to && to.toString());
    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-card-covers/' + from + '/' + to, {
      params: httpParams
    });
  }

  // Cancella una visibilità
  removeVisibility(itemId: string, visibilityId: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId || '');
    httpParams = httpParams.append('visibilityId', visibilityId || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/remove-visibility/' + itemId + '/' + visibilityId, {
      params: httpParams
    });
  }

  // Cancella una visibilità dal catalogo
  removeItemVisibilitiesFromStandaloneCatalogIitem(itemId: string, userIds?: string, groupIds?: string, publicVisibility?: boolean) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/remove-item-visibilities-from-standalone-catalog-item', {
      publicVisibility: publicVisibility,
      itemId: itemId,
      userIds: userIds,
      groupIds: groupIds
    });
  }

  // Aggiunge una visibilità al catalogo
  addItemVisibilitiesFromStandaloneCatalogIitem(itemId: string, userIds?: string, groupIds?: string, publicVisibility?: boolean) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/add-item-visibilities-for-standalone-catalog-item', {
      publicVisibility: publicVisibility,
      itemId: itemId,
      userIds: userIds,
      groupIds: groupIds
    });
  }

  // Servizio che importa una lista di persone di un Item come taker
  importPersonsAsTakers(cidOrEmailList: string[], userIds: string[], itemId: string, simulate: boolean, webinarUserRole?) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/import-persons-as-takers', {
      cidOrEmailList: cidOrEmailList,
      itemId: itemId,
      userIds: userIds,
      simulate: simulate,
      webinarUserRole: (webinarUserRole && webinarUserRole.code) || ''
    });
  }

  // Servizio che importa una lista di gruppi di un Item
  importGroupsAsTakers(groupIds: string[], itemId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/import-groups-as-takers', {
      groupIds: groupIds,
      itemId: itemId
    });
  }

  // Servizio che cancella i taker
  deleteTakers(takerIds: string[]) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-takers', {
      takerIds: takerIds
    });
  }

  // Recupera una lista di taker
  listUserTakers(itemId: string, allData?: boolean, fromRecord?: number, numRecords?: string, searchedText?: string, retrievePublicTakersToo?: boolean): any {
    let from = fromRecord;
    let to = numRecords;
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', from && from.toString() || '');
    httpParams = httpParams.append('numRecords', to && to.toString() || '');
    httpParams = httpParams.append('allData', allData && allData.toString() || '');
    // httpParams = !!withAllStatusesLoaded ? httpParams.append('withAllStatusesLoaded', 'true') : httpParams;
    httpParams = httpParams.append('itemId', itemId || '');
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('retrievePublicTakersToo', retrievePublicTakersToo ? "true" : "false");
    return this.http.get<SenecaResponse<TextTemplate[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-takers/' + from + '/' + to, {
      params: httpParams
    });
  }

  // Aggiunge una visibilità
  addVisibility(itemId: string, visibilityType: string, fromAdmin: boolean, publicItem: boolean, ignoreVisibility: boolean, originApplicationName?: string, forceMailAndNotification?: boolean, destination?: any) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/add-visibility', {
      itemId: itemId,
      visibilityType: visibilityType,
      originApplicationName: originApplicationName,
      fromAdmin: fromAdmin,
      publicItem: publicItem,
      forceMailAndNotification: forceMailAndNotification,
      ignoreVisibility: ignoreVisibility,
      destination: destination
    });
  }

  // Aggiunge uno o più child ad un item
  addChilds(parentId: string, childsId: string[], isDraft: boolean, originApplicationName?: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/add-childs', {
      parentId: parentId,
      childsId: childsId,
      originApplicationName: originApplicationName,
      isDraft: isDraft
    });
  }

  // Crea una nuova sezione
  createSection(item: Item, originApplicationName?: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-section', {
      item: item,
      originApplicationName: originApplicationName
    });
  }

  addStandaloneCatalogItemRequest(itemId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/add-standalone-catalog-item-request', {
      itemId: itemId
    });
  }

  // Recupera tutte le Survey dell'utente loggato
  addSurveyRandomGroupQuestionsLimit(surveyId: string, groupsQuestionLimit: any) {
    return this.http.post<any>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/add-or-update-survey-random-group-questions-limit', {
      surveyId: surveyId,
      groupsQuestionLimit: groupsQuestionLimit
    });
  }

  // Servizio che recupera un Item dato il suo id per admin
  itemByIdForAdmin(itemId: string, withChildsLoaded?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    httpParams = withChildsLoaded ? httpParams.append('withChildsLoaded', 'true') : httpParams;
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-item-by-id-for-admin/' + itemId, {
      params: httpParams
    });
  }

  // Servizio che recupera un Item dato il suo id per supplier
  itemByIdForSupplier(itemId: string, withChildsLoaded?: boolean) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    httpParams = withChildsLoaded ? httpParams.append('withChildsLoaded', 'true') : httpParams;
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-item-by-id-for-supplier/' + itemId, {
      params: httpParams
    });
  }

  deleteCourseItem(itemId: string, removeSyllabus?: boolean) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-course-item/' + itemId, {
      itemId: itemId,
      removeSyllabus: removeSyllabus
    });
  }

  deleteLibraryItem(itemId: string, removeAtomicItemsToo?: boolean) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-library-item/' + itemId, {
      itemId: itemId,
      removeAtomicItemsToo: removeAtomicItemsToo
    });
  }

  // Servizio che cancella un multimedia item
  deleteMultimediaItem(itemId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/corporateacademy-mediator/delete-multimedia-item/${itemId}`, {
      itemId: itemId
    });
  }

  getUserGoals(fromRecord: any, numRecords: any, userId: string, allData?: boolean, fromDate?, toDate?): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    httpParams = httpParams.append('allData', (allData && allData.toString()) || '');
    httpParams = httpParams.append('startDate', (fromDate && fromDate.toString()) || '');
    httpParams = httpParams.append('endDate', (toDate && toDate.toString()) || '');
    httpParams = httpParams.append('userId', userId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-user-goals', {
      params: httpParams
    });
  }

  countUserGoals(userId: string, fromDate?, toDate?): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startDate', (fromDate && fromDate.toString()) || '');
    httpParams = httpParams.append('endDate', (toDate && toDate.toString()) || '');
    httpParams = httpParams.append('userId', userId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-user-goals', {
      params: httpParams
    });
  }

  getUserCompetences(fromRecord: any, numRecords: any, userId: string, allData?: boolean, fromDate?, toDate?): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    httpParams = httpParams.append('allData', (allData && allData.toString()) || '');
    httpParams = httpParams.append('startDate', (fromDate && fromDate.toString()) || '');
    httpParams = httpParams.append('endDate', (toDate && toDate.toString()) || '');
    httpParams = httpParams.append('userId', userId);

    /*    return new Observable(obsevable => {
           obsevable.next({
               error: null,
               response: [
                   {
                       id: 1,
                       title: "Prova",
                       tipology: "B",
                       uploadUrl: "https://gtpuploadarchive.blob.core.windows.net/avatar-staging/f6d70312-98bc-449a-a1cc-e9d9b4144cd9Edgar%20Morin.jpg",
                       duration: 60,
                       certificationDate: "2021-07-06T22:00:00.000Z",
                       hashtags: ["#TEST"]
                   },
                   {
                       id: 2,
                       title: "Prova",
                       tipology: "B",
                       uploadUrl: "https://gtpuploadarchive.blob.core.windows.net/avatar-staging/f6d70312-98bc-449a-a1cc-e9d9b4144cd9Edgar%20Morin.jpg",
                       duration: 60,
                       certificationDate: "2021-07-06T22:00:00.000Z",
                       hashtags: ["#TEST"]
                   },
                   {
                       id: 3,
                       title: "Prova",
                       tipology: "B",
                       uploadUrl: "https://gtpuploadarchive.blob.core.windows.net/avatar-staging/f6d70312-98bc-449a-a1cc-e9d9b4144cd9Edgar%20Morin.jpg",
                       duration: 60,
                       certificationDate: "2021-07-06T22:00:00.000Z",
                       hashtags: ["#TEST"]
                   },
                   {
                       id: 4,
                       title: "Prova",
                       tipology: "C",
                       uploadUrl: "https://gtpuploadarchive.blob.core.windows.net/avatar-staging/f6d70312-98bc-449a-a1cc-e9d9b4144cd9Edgar%20Morin.jpg",
                       duration: 60,
                       certificationDate: "2021-07-06T22:00:00.000Z",
                       hashtags: ["#TEST"]
                   },
                   {
                       id: 5,
                       title: "Nome della competenza che ricade in questa sezione",
                       tipology: "A",
                       uploadUrl: "https://gtpuploadarchive.blob.core.windows.net/avatar-staging/f6d70312-98bc-449a-a1cc-e9d9b4144cd9Edgar%20Morin.jpg",
                       duration: 60,
                       certificationDate: "2021-07-06T22:00:00.000Z",
                       hashtags: ["#TEST"]
                   },
                   {
                       id: 6,
                       title: "Prova",
                       tipology: "B",
                       uploadUrl: "https://gtpuploadarchive.blob.core.windows.net/avatar-staging/f6d70312-98bc-449a-a1cc-e9d9b4144cd9Edgar%20Morin.jpg",
                       duration: 60,
                       certificationDate: "2021-07-06T22:00:00.000Z",
                       hashtags: ["#TEST"]
                   },
                   {
                       id: 7,
                       title: "Prova",
                       tipology: "B",
                       uploadUrl: "https://gtpuploadarchive.blob.core.windows.net/avatar-staging/f6d70312-98bc-449a-a1cc-e9d9b4144cd9Edgar%20Morin.jpg",
                       duration: 60,
                       certificationDate: "2021-07-06T22:00:00.000Z",
                       hashtags: ["#TEST"]
                   },
                   {
                       id: 8,
                       title: "Prova",
                       tipology: "B",
                       uploadUrl: "https://gtpuploadarchive.blob.core.windows.net/avatar-staging/f6d70312-98bc-449a-a1cc-e9d9b4144cd9Edgar%20Morin.jpg",
                       duration: 60,
                       certificationDate: "2021-07-06T22:00:00.000Z",
                       hashtags: ["#TEST"]
                   },
                   {
                       id: 9,
                       title: "Prova",
                       tipology: "B",
                       uploadUrl: "https://gtpuploadarchive.blob.core.windows.net/avatar-staging/f6d70312-98bc-449a-a1cc-e9d9b4144cd9Edgar%20Morin.jpg",
                       duration: 60,
                       certificationDate: "2021-07-06T22:00:00.000Z",
                       hashtags: ["#TEST"]
                   },
                   {
                       id: 10,
                       title: "Prova",
                       tipology: "B",
                       uploadUrl: "https://gtpuploadarchive.blob.core.windows.net/avatar-staging/f6d70312-98bc-449a-a1cc-e9d9b4144cd9Edgar%20Morin.jpg",
                       duration: 60,
                       certificationDate: "2021-07-06T22:00:00.000Z",
                       hashtags: ["#TEST"]
                   },
                   {
                       id: 11,
                       title: "Prova",
                       tipology: "B",
                       uploadUrl: "https://gtpuploadarchive.blob.core.windows.net/avatar-staging/f6d70312-98bc-449a-a1cc-e9d9b4144cd9Edgar%20Morin.jpg",
                       duration: 60,
                       certificationDate: "2021-07-06T22:00:00.000Z",
                       hashtags: ["#TEST"]
                   },
                   {
                       id: 12,
                       title: "Prova",
                       tipology: "B",
                       uploadUrl: "https://gtpuploadarchive.blob.core.windows.net/avatar-staging/f6d70312-98bc-449a-a1cc-e9d9b4144cd9Edgar%20Morin.jpg",
                       duration: 60,
                       certificationDate: "2021-07-06T22:00:00.000Z",
                       hashtags: ["#TEST"]
                   },
                   {
                       id: 13,
                       title: "Prova",
                       tipology: "B",
                       uploadUrl: "https://gtpuploadarchive.blob.core.windows.net/avatar-staging/f6d70312-98bc-449a-a1cc-e9d9b4144cd9Edgar%20Morin.jpg",
                       duration: 60,
                       certificationDate: "2021-07-06T22:00:00.000Z",
                       hashtags: ["#TEST"]
                   },
                   {
                       id: 14,
                       title: "Prova",
                       tipology: "B",
                       uploadUrl: "https://gtpuploadarchive.blob.core.windows.net/avatar-staging/f6d70312-98bc-449a-a1cc-e9d9b4144cd9Edgar%20Morin.jpg",
                       duration: 60,
                       certificationDate: "2021-07-06T22:00:00.000Z",
                       hashtags: ["#TEST"]
                   },
                   {
                       id: 15,
                       title: "Prova",
                       tipology: "B",
                       uploadUrl: "https://gtpuploadarchive.blob.core.windows.net/avatar-staging/f6d70312-98bc-449a-a1cc-e9d9b4144cd9Edgar%20Morin.jpg",
                       duration: 60,
                       certificationDate: "2021-07-06T22:00:00.000Z",
                       hashtags: ["#TEST"]
                   },
                   {
                       id: 16,
                       title: "Prova",
                       tipology: "B",
                       uploadUrl: "https://gtpuploadarchive.blob.core.windows.net/avatar-staging/f6d70312-98bc-449a-a1cc-e9d9b4144cd9Edgar%20Morin.jpg",
                       duration: 60,
                       certificationDate: "2021-07-06T22:00:00.000Z",
                       hashtags: ["#TEST"]
                   },
                   {
                       id: 17,
                       title: "Prova",
                       tipology: "B",
                       uploadUrl: "https://gtpuploadarchive.blob.core.windows.net/avatar-staging/f6d70312-98bc-449a-a1cc-e9d9b4144cd9Edgar%20Morin.jpg",
                       duration: 60,
                       certificationDate: "2021-07-06T22:00:00.000Z",
                       hashtags: ["#TEST"]
                   },
                   {
                       id: 18,
                       title: "Prova",
                       tipology: "B",
                       uploadUrl: "https://gtpuploadarchive.blob.core.windows.net/avatar-staging/f6d70312-98bc-449a-a1cc-e9d9b4144cd9Edgar%20Morin.jpg",
                       duration: 60,
                       certificationDate: "2021-07-06T22:00:00.000Z",
                       hashtags: ["#TEST"]
                   }
               ]
           });
           obsevable.complete();
       });*/
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-user-competences', {
      params: httpParams
    });
  }

  countUserCompetences(userId: string, fromDate?, toDate?): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startDate', (fromDate && fromDate.toString()) || '');
    httpParams = httpParams.append('endDate', (toDate && toDate.toString()) || '');
    httpParams = httpParams.append('userId', userId);

    return new Observable(obsevable => {
      obsevable.next({
        error: null,
        response: 12
      });
      obsevable.complete();
    });
    /*  return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-user-competences', {
          params: httpParams
      });*/
  }

  listItemAttributeByIdAndType(itemId: string, itemAttributeTypes: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    httpParams = httpParams.append('itemAttributeType', itemAttributeTypes);
    httpParams = httpParams.append('allData', "true");
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-item-attributes', {
      params: httpParams
    });
  }

  hasUserCertificaAssigned(stageItemId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('stageItemId', stageItemId);

    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/has-user-a-certificated-assigned-engagement', {
      params: httpParams
    });
  }

  createItemTakerEnrollByExternalObjId(externalObjId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-item-taker-enroll-by-external-obj-id', {
      externalObjId: externalObjId
    });
  }

  consumeLibraryItemByItemId(itemId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/consume-item-by-itemId', {
      itemId: itemId
    });
  }

  getOnlyUsableTags() {
    return this.http.get<SenecaResponse<string[]>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-all-searchable-tagtypes', {});
  }

  //refresh cache playlist
  refreshCachePlaylist(userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/force-user-groups-refresh', {
      params: httpParams
    });
  }

  // Recupera gli assessment di un item
  listAssessmentOfItemByReferenceId(referenceId: string[], referenceType: string[], referenceApplicationName?: string[], originApplicationName?: string, fromRecord?: number, numRecords?: number, allData?: boolean): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();

    if (referenceId && referenceId.length) {
      referenceId.forEach(referenceId => {
        httpParams = httpParams.append('referenceId', referenceId);
      });
    }
    if (referenceType && referenceType.length) {
      referenceType.forEach(referenceType => {
        httpParams = httpParams.append('referenceType', referenceType);
      });
    }
    if (referenceApplicationName && referenceApplicationName.length) {
      referenceApplicationName.forEach(referenceApplicationName => {
        httpParams = httpParams.append('referenceApplicationName', referenceApplicationName);
      });
    }
    httpParams = httpParams.append('originApplicationName', (originApplicationName && originApplicationName.toString()) || '');
    httpParams = httpParams.append('fromRecord', (fromRecord && fromRecord.toString()) || '');
    httpParams = httpParams.append('numRecords', (numRecords && numRecords.toString()) || '');
    httpParams = httpParams.append('allData', (allData && allData.toString()) || '');

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/list-assessment-of-item-by-referenceId', {
      params: httpParams
    });
  }

  // Crea un nuovo sottotitolo
  createClosedCaption(uploadObj) {
    let formData = new FormData();
    formData.append('file', uploadObj.file.file.rawFile);
    //formData.append('entryId', uploadObj.entryId || '');
    formData.append('item', uploadObj.item);
    formData.append('title', uploadObj.title || '');
    formData.append('lang', uploadObj.lang || '');
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/create-video-subtitle', formData, options);
    return this.http.request(req);
  }

  deleteClosedCaption(closedCaptionId: string, item) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-video-subtitle', {
      uploadId: closedCaptionId,
      item: item
    });
  }

  /*deleteClosedCaption(uploadId, item) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('uploadId', uploadId);
    httpParams = httpParams.append('item', item);
    return this.http.delete<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/delete-video-subtitle', {
      params: httpParams
    });
  }*/

  countNewCatalogCourses(searchedText: string, startDate?, endDate?, courseUserStatuses?: string | string[], courseType?: string, past?: boolean, future?: boolean,
    description?: string, asConsumableItem?: boolean, techSkillsOnly?: boolean, softSkillsOnly?: boolean, itemTypes?: string | string[],
    objectTypeSpecializations?: string[] | string, techCompetences?: string[], softCompetences?: string[], clusters?: string[], functionalAreas?: string[],
    adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[], argumentsTags?: string[], courseCategoryTag?: string[], publicationStartDate?: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startDate', (startDate && startDate.toString()) || '');
    httpParams = httpParams.append('endDate', (endDate && endDate.toString()) || '');
    httpParams = httpParams.append('courseType', courseType || '');
    httpParams = httpParams.append('description', (description && description.toString()) || '');
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    httpParams = publicationStartDate ? httpParams.append('publicationStartDate', publicationStartDate) : httpParams;
    if (past) {
      httpParams = httpParams.append('past', (past && past.toString()) || '');
    }
    if (future) {
      httpParams = httpParams.append('future', (future && future.toString()) || '');
    }
    if (asConsumableItem) {
      httpParams = httpParams.append('asConsumableItem', (asConsumableItem && asConsumableItem.toString()) || '');
    }
    if (courseUserStatuses && courseUserStatuses.length) {
      httpParams = httpParams.append('courseUserStatuses', courseUserStatuses && typeof courseUserStatuses == 'string' ? courseUserStatuses : JSON.stringify(courseUserStatuses));
    }

    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectType', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (courseCategoryTag && courseCategoryTag.length) {
      httpParams = httpParams.append('courseCategoryTag', courseCategoryTag && typeof courseCategoryTag == 'string' ? courseCategoryTag : JSON.stringify(courseCategoryTag));
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-new-catalog-courses', {
      params: httpParams
    });
  }

  // Catalogo - Novità get
  getNewCatalogCourses(searchedText: string, fromRecord: any, numRecords: any, fromDate?, toDate?, courseUserStatuses?: string | string[], courseType?: string,
    allData?: boolean, past?: boolean, future?: boolean, description?: string, sort?: string, desc?: boolean, asConsumableItem?: boolean, withUserCatalogActions?: boolean,
    techSkillsOnly?: boolean, softSkillsOnly?: boolean, itemTypes?: string | string[], objectTypeSpecializations?: string[] | string, techCompetences?: string[],
    softCompetences?: string[], clusters?: string[], functionalAreas?: string[], adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[],
    argumentsTags?: string[], courseCategoryTag?: string[], publicationStartDate?: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    httpParams = httpParams.append('startDate', (fromDate && fromDate.toString()) || '');
    httpParams = httpParams.append('endDate', (toDate && toDate.toString()) || '');
    httpParams = httpParams.append('courseType', courseType || '');
    httpParams = httpParams.append('allData', (allData && allData.toString()) || '');
    httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('description', (description && description.toString()) || '');
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    httpParams = publicationStartDate ? httpParams.append('publicationStartDate', publicationStartDate) : httpParams;
    if (past) {
      httpParams = httpParams.append('past', (past && past.toString()) || '');
    }
    if (future) {
      httpParams = httpParams.append('future', (future && future.toString()) || '');
    }
    if (asConsumableItem) {
      httpParams = httpParams.append('asConsumableItem', (asConsumableItem && asConsumableItem.toString()) || '');
    }
    if (withUserCatalogActions) {
      httpParams = httpParams.append('withUserCatalogActions', (withUserCatalogActions && withUserCatalogActions.toString()) || '');
    }
    httpParams = httpParams.append('desc', desc && desc.toString() || '');
    if (courseUserStatuses && courseUserStatuses.length) {
      httpParams = httpParams.append('courseUserStatuses', courseUserStatuses && typeof courseUserStatuses == 'string' ? courseUserStatuses : JSON.stringify(courseUserStatuses));
    }

    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectType', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (courseCategoryTag && courseCategoryTag.length) {
      httpParams = httpParams.append('courseCategoryTag', courseCategoryTag && typeof courseCategoryTag == 'string' ? courseCategoryTag : JSON.stringify(courseCategoryTag));
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-new-catalog-courses', {
      params: httpParams
    });
  }


  countCatalogCoursesUpcomingEvents(searchedText: string, startDate?, endDate?, courseUserStatuses?: string | string[], courseType?: string, past?: boolean, future?: boolean,
    description?: string, asConsumableItem?: boolean, techSkillsOnly?: boolean, softSkillsOnly?: boolean, itemTypes?: string | string[],
    objectTypeSpecializations?: string[] | string, techCompetences?: string[], softCompetences?: string[], clusters?: string[], functionalAreas?: string[],
    adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[], argumentsTags?: string[], courseCategoryTag?: string[], publicationStartDate?: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startDate', (startDate && startDate.toString()) || '');
    httpParams = httpParams.append('endDate', (endDate && endDate.toString()) || '');
    httpParams = httpParams.append('courseType', courseType || '');
    httpParams = httpParams.append('description', (description && description.toString()) || '');
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    // httpParams = publicationStartDate ? httpParams.append('publicationStartDate', publicationStartDate) : httpParams;
    if (past) {
      httpParams = httpParams.append('past', (past && past.toString()) || '');
    }
    if (future) {
      httpParams = httpParams.append('future', (future && future.toString()) || '');
    }
    if (asConsumableItem) {
      httpParams = httpParams.append('asConsumableItem', (asConsumableItem && asConsumableItem.toString()) || '');
    }
    if (courseUserStatuses && courseUserStatuses.length) {
      httpParams = httpParams.append('courseUserStatuses', courseUserStatuses && typeof courseUserStatuses == 'string' ? courseUserStatuses : JSON.stringify(courseUserStatuses));
    }

    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectType', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (courseCategoryTag && courseCategoryTag.length) {
      httpParams = httpParams.append('courseCategoryTag', courseCategoryTag && typeof courseCategoryTag == 'string' ? courseCategoryTag : JSON.stringify(courseCategoryTag));
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-catalog-courses-upcoming-events', {
      params: httpParams
    });
  }

  getCatalogCoursesUpcomingEvents(searchedText: string, fromRecord: any, numRecords: any, fromDate?, toDate?, courseUserStatuses?: string | string[], courseType?: string,
    allData?: boolean, past?: boolean, future?: boolean, description?: string, sort?: string, desc?: boolean, asConsumableItem?: boolean, withUserCatalogActions?: boolean,
    techSkillsOnly?: boolean, softSkillsOnly?: boolean, itemTypes?: string | string[], objectTypeSpecializations?: string[] | string, techCompetences?: string[],
    softCompetences?: string[], clusters?: string[], functionalAreas?: string[], adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[],
    argumentsTags?: string[], courseCategoryTag?: string[], publicationStartDate?: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    // httpParams = httpParams.append('startDate', (fromDate && fromDate.toString()) || '');
    httpParams = httpParams.append('endDate', (toDate && toDate.toString()) || '');
    httpParams = httpParams.append('courseType', courseType || '');
    httpParams = httpParams.append('allData', (allData && allData.toString()) || '');
    httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('description', (description && description.toString()) || '');
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    // httpParams = publicationStartDate ? httpParams.append('publicationStartDate', publicationStartDate) : httpParams;
    if (past) {
      httpParams = httpParams.append('past', (past && past.toString()) || '');
    }
    if (future) {
      httpParams = httpParams.append('future', (future && future.toString()) || '');
    }
    if (asConsumableItem) {
      httpParams = httpParams.append('asConsumableItem', (asConsumableItem && asConsumableItem.toString()) || '');
    }
    if (withUserCatalogActions) {
      httpParams = httpParams.append('withUserCatalogActions', (withUserCatalogActions && withUserCatalogActions.toString()) || '');
    }
    httpParams = httpParams.append('desc', desc && desc.toString() || '');
    if (courseUserStatuses && courseUserStatuses.length) {
      httpParams = httpParams.append('courseUserStatuses', courseUserStatuses && typeof courseUserStatuses == 'string' ? courseUserStatuses : JSON.stringify(courseUserStatuses));
    }

    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectType', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (courseCategoryTag && courseCategoryTag.length) {
      httpParams = httpParams.append('courseCategoryTag', courseCategoryTag && typeof courseCategoryTag == 'string' ? courseCategoryTag : JSON.stringify(courseCategoryTag));
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-catalog-courses-upcoming-events', {
      params: httpParams
    });
  }

  countStructureCatalogCourses(searchedText: string, startDate?, endDate?, courseUserStatuses?: string | string[], courseType?: string, past?: boolean, future?: boolean,
    description?: string, asConsumableItem?: boolean, techSkillsOnly?: boolean, softSkillsOnly?: boolean, itemTypes?: string | string[],
    objectTypeSpecializations?: string[] | string, techCompetences?: string[], softCompetences?: string[], clusters?: string[], functionalAreas?: string[],
    adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[], argumentsTags?: string[], courseCategoryTag?: string[]): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    httpParams = httpParams.append('startDate', (startDate && startDate.toString()) || '');
    httpParams = httpParams.append('endDate', (endDate && endDate.toString()) || '');
    httpParams = httpParams.append('courseType', courseType || '');
    httpParams = httpParams.append('description', (description && description.toString()) || '');
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    if (past) {
      httpParams = httpParams.append('past', (past && past.toString()) || '');
    }
    if (future) {
      httpParams = httpParams.append('future', (future && future.toString()) || '');
    }
    if (asConsumableItem) {
      httpParams = httpParams.append('asConsumableItem', (asConsumableItem && asConsumableItem.toString()) || '');
    }
    if (courseUserStatuses && courseUserStatuses.length) {
      httpParams = httpParams.append('courseUserStatuses', courseUserStatuses && typeof courseUserStatuses == 'string' ? courseUserStatuses : JSON.stringify(courseUserStatuses));
    }

    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectType', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (courseCategoryTag && courseCategoryTag.length) {
      httpParams = httpParams.append('courseCategoryTag', courseCategoryTag && typeof courseCategoryTag == 'string' ? courseCategoryTag : JSON.stringify(courseCategoryTag));
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-my-structure-catalog-courses', {
      params: httpParams
    });
  }

  // Catalogo - Novità get
  getMyStructureCatalogCourses(searchedText: string, fromRecord: any, numRecords: any, fromDate?, toDate?, courseUserStatuses?: string | string[], courseType?: string,
    allData?: boolean, past?: boolean, future?: boolean, description?: string, sort?: string, desc?: boolean, asConsumableItem?: boolean, withUserCatalogActions?: boolean,
    techSkillsOnly?: boolean, softSkillsOnly?: boolean, itemTypes?: string | string[], objectTypeSpecializations?: string[] | string, techCompetences?: string[],
    softCompetences?: string[], clusters?: string[], functionalAreas?: string[], adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[],
    argumentsTags?: string[], courseCategoryTag?: string[]): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    httpParams = httpParams.append('startDate', (fromDate && fromDate.toString()) || '');
    httpParams = httpParams.append('endDate', (toDate && toDate.toString()) || '');
    httpParams = httpParams.append('courseType', courseType || '');
    httpParams = httpParams.append('allData', (allData && allData.toString()) || '');
    httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('description', (description && description.toString()) || '');
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    if (past) {
      httpParams = httpParams.append('past', (past && past.toString()) || '');
    }
    if (future) {
      httpParams = httpParams.append('future', (future && future.toString()) || '');
    }
    if (asConsumableItem) {
      httpParams = httpParams.append('asConsumableItem', (asConsumableItem && asConsumableItem.toString()) || '');
    }
    if (withUserCatalogActions) {
      httpParams = httpParams.append('withUserCatalogActions', (withUserCatalogActions && withUserCatalogActions.toString()) || '');
    }
    httpParams = httpParams.append('desc', desc && desc.toString() || '');
    if (courseUserStatuses && courseUserStatuses.length) {
      httpParams = httpParams.append('courseUserStatuses', courseUserStatuses && typeof courseUserStatuses == 'string' ? courseUserStatuses : JSON.stringify(courseUserStatuses));
    }

    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectType', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (courseCategoryTag && courseCategoryTag.length) {
      httpParams = httpParams.append('courseCategoryTag', courseCategoryTag && typeof courseCategoryTag == 'string' ? courseCategoryTag : JSON.stringify(courseCategoryTag));
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-my-structure-catalog-courses', {
      params: httpParams
    });
  }


  // Catalogo - Novità get
  getMostViewedCatalogCourses(searchedText: string, fromRecord: any, numRecords: any, fromDate?, toDate?, courseUserStatuses?: string | string[], courseType?: string,
    allData?: boolean, past?: boolean, future?: boolean, description?: string, sort?: string, desc?: boolean, asConsumableItem?: boolean, withUserCatalogActions?: boolean,
    techSkillsOnly?: boolean, softSkillsOnly?: boolean, itemTypes?: string | string[], objectTypeSpecializations?: string[] | string, techCompetences?: string[],
    softCompetences?: string[], clusters?: string[], functionalAreas?: string[], adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[],
    argumentsTags?: string[], courseCategoryTag?: string[]): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    httpParams = httpParams.append('startDate', (fromDate && fromDate.toString()) || '');
    httpParams = httpParams.append('endDate', (toDate && toDate.toString()) || '');
    httpParams = httpParams.append('courseType', courseType || '');
    httpParams = httpParams.append('allData', (allData && allData.toString()) || '');
    httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('description', (description && description.toString()) || '');
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    if (past) {
      httpParams = httpParams.append('past', (past && past.toString()) || '');
    }
    if (future) {
      httpParams = httpParams.append('future', (future && future.toString()) || '');
    }
    if (asConsumableItem) {
      httpParams = httpParams.append('asConsumableItem', (asConsumableItem && asConsumableItem.toString()) || '');
    }
    if (withUserCatalogActions) {
      httpParams = httpParams.append('withUserCatalogActions', (withUserCatalogActions && withUserCatalogActions.toString()) || '');
    }
    httpParams = httpParams.append('desc', desc && desc.toString() || '');
    if (courseUserStatuses && courseUserStatuses.length) {
      httpParams = httpParams.append('courseUserStatuses', courseUserStatuses && typeof courseUserStatuses == 'string' ? courseUserStatuses : JSON.stringify(courseUserStatuses));
    }

    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectType', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (courseCategoryTag && courseCategoryTag.length) {
      httpParams = httpParams.append('courseCategoryTag', courseCategoryTag && typeof courseCategoryTag == 'string' ? courseCategoryTag : JSON.stringify(courseCategoryTag));
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-most-viewed-catalog-courses', {
      params: httpParams
    });
  }


  countDesiredCatalogCourses(searchedText: string, startDate?, endDate?, courseUserStatuses?: string | string[], courseType?: string, past?: boolean, future?: boolean,
    description?: string, asConsumableItem?: boolean, techSkillsOnly?: boolean, softSkillsOnly?: boolean, itemTypes?: string | string[],
    objectTypeSpecializations?: string[] | string, techCompetences?: string[], softCompetences?: string[], clusters?: string[], functionalAreas?: string[],
    adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[], argumentsTags?: string[], courseCategoryTag?: string[]): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    httpParams = httpParams.append('startDate', (startDate && startDate.toString()) || '');
    httpParams = httpParams.append('endDate', (endDate && endDate.toString()) || '');
    httpParams = httpParams.append('courseType', courseType || '');
    httpParams = httpParams.append('description', (description && description.toString()) || '');
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    if (past) {
      httpParams = httpParams.append('past', (past && past.toString()) || '');
    }
    if (future) {
      httpParams = httpParams.append('future', (future && future.toString()) || '');
    }
    if (asConsumableItem) {
      httpParams = httpParams.append('asConsumableItem', (asConsumableItem && asConsumableItem.toString()) || '');
    }
    if (courseUserStatuses && courseUserStatuses.length) {
      httpParams = httpParams.append('courseUserStatuses', courseUserStatuses && typeof courseUserStatuses == 'string' ? courseUserStatuses : JSON.stringify(courseUserStatuses));
    }

    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectType', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (courseCategoryTag && courseCategoryTag.length) {
      httpParams = httpParams.append('courseCategoryTag', courseCategoryTag && typeof courseCategoryTag == 'string' ? courseCategoryTag : JSON.stringify(courseCategoryTag));
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-catalog-courses-for-desired-competences', {
      params: httpParams
    });
  }

  // Catalogo - Novità get
  getDesiredCatalogCourses(searchedText: string, fromRecord: any, numRecords: any, fromDate?, toDate?, courseUserStatuses?: string | string[], courseType?: string,
    allData?: boolean, past?: boolean, future?: boolean, description?: string, sort?: string, desc?: boolean, asConsumableItem?: boolean, withUserCatalogActions?: boolean,
    techSkillsOnly?: boolean, softSkillsOnly?: boolean, itemTypes?: string | string[], objectTypeSpecializations?: string[] | string, techCompetences?: string[],
    softCompetences?: string[], clusters?: string[], functionalAreas?: string[], adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[],
    argumentsTags?: string[], courseCategoryTag?: string[]): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    httpParams = httpParams.append('startDate', (fromDate && fromDate.toString()) || '');
    httpParams = httpParams.append('endDate', (toDate && toDate.toString()) || '');
    httpParams = httpParams.append('courseType', courseType || '');
    httpParams = httpParams.append('allData', (allData && allData.toString()) || '');
    httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('description', (description && description.toString()) || '');
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    if (past) {
      httpParams = httpParams.append('past', (past && past.toString()) || '');
    }
    if (future) {
      httpParams = httpParams.append('future', (future && future.toString()) || '');
    }
    if (asConsumableItem) {
      httpParams = httpParams.append('asConsumableItem', (asConsumableItem && asConsumableItem.toString()) || '');
    }
    if (withUserCatalogActions) {
      httpParams = httpParams.append('withUserCatalogActions', (withUserCatalogActions && withUserCatalogActions.toString()) || '');
    }
    httpParams = httpParams.append('desc', desc && desc.toString() || '');
    if (courseUserStatuses && courseUserStatuses.length) {
      httpParams = httpParams.append('courseUserStatuses', courseUserStatuses && typeof courseUserStatuses == 'string' ? courseUserStatuses : JSON.stringify(courseUserStatuses));
    }

    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectType', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (courseCategoryTag && courseCategoryTag.length) {
      httpParams = httpParams.append('courseCategoryTag', courseCategoryTag && typeof courseCategoryTag == 'string' ? courseCategoryTag : JSON.stringify(courseCategoryTag));
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-catalog-courses-for-desired-competences', {
      params: httpParams
    });
  }

  // Nuovi servizi library
  // Novità
  getNewLibraryItems(searchedText: string, fromRecord: any, numRecords: any, status?: string, sorting?: string, itemTypes?: string | string[], originApplicationName?: string,
    ignoreVisibility?: boolean, withChildsLoaded?: boolean, createEngagement?: boolean, withRegistration?: boolean, objectTypeSpecializations?: string[] | string,
    skipVisibilitiesHandling?: boolean, techSkillsOnly?: boolean, softSkillsOnly?: boolean, techCompetences?: string[], softCompetences?: string[], clusters?: string[],
    functionalAreas?: string[], adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[], argumentsTags?: string[], myOnly?: boolean,
    attributeTypes?: string, courseCategoryTags?: string[], modifyStartDate?: string, modifyEndDate?: string): any {
    // Recupera una lista di item
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    let from = fromRecord;
    let to = numRecords;
    httpParams = httpParams.append('fromRecord', from && from.toString());
    httpParams = httpParams.append('numRecords', to && to.toString());
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('originApplicationName', originApplicationName || '');
    httpParams = httpParams.append('status', status || 'A');
    httpParams = httpParams.append('sorting', sorting || 'S');
    httpParams = httpParams.append('ignoreVisibility', (ignoreVisibility && ignoreVisibility.toString()) || '');
    httpParams = httpParams.append('withChildsLoaded', (withChildsLoaded && withChildsLoaded.toString()) || '');
    httpParams = httpParams.append('createEngagement', (createEngagement && createEngagement.toString()) || '');
    httpParams = httpParams.append('withRegistration', (withRegistration && withRegistration.toString()) || '');
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    httpParams = modifyStartDate ? httpParams.append('modifyStartDate', modifyStartDate) : httpParams;
    httpParams = modifyEndDate ? httpParams.append('modifyEndDate', modifyEndDate) : httpParams;

    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectTypeSpecializations', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (myOnly) {
      httpParams = httpParams.append('myOnly', 'true');
    }
    httpParams = skipVisibilitiesHandling ? httpParams.append('skipVisibilitiesHandling', 'true') : httpParams;
    if (attributeTypes && attributeTypes.length) {
      httpParams = httpParams.append('attributeTypes', attributeTypes && typeof attributeTypes == 'string' ? attributeTypes : JSON.stringify(attributeTypes));
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-new-items', {
      params: httpParams
    });
  }

  // Più visti library
  getMostViewedLibraryItems(searchedText: string, fromRecord: any, numRecords: any, status?: string, sorting?: string, itemTypes?: string | string[], originApplicationName?: string,
    ignoreVisibility?: boolean, withChildsLoaded?: boolean, createEngagement?: boolean, withRegistration?: boolean, objectTypeSpecializations?: string[] | string,
    skipVisibilitiesHandling?: boolean, techSkillsOnly?: boolean, softSkillsOnly?: boolean, techCompetences?: string[], softCompetences?: string[], clusters?: string[],
    functionalAreas?: string[], adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[], argumentsTags?: string[], myOnly?: boolean,
    attributeTypes?: string, courseCategoryTags?: string[]): any {
    // Recupera una lista di item
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    let from = fromRecord;
    let to = numRecords;
    httpParams = httpParams.append('fromRecord', from && from.toString());
    httpParams = httpParams.append('numRecords', to && to.toString());
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('originApplicationName', originApplicationName || '');
    httpParams = httpParams.append('status', status || 'A');
    httpParams = httpParams.append('sorting', sorting || 'S');
    httpParams = httpParams.append('ignoreVisibility', (ignoreVisibility && ignoreVisibility.toString()) || '');
    httpParams = httpParams.append('withChildsLoaded', (withChildsLoaded && withChildsLoaded.toString()) || '');
    httpParams = httpParams.append('createEngagement', (createEngagement && createEngagement.toString()) || '');
    httpParams = httpParams.append('withRegistration', (withRegistration && withRegistration.toString()) || '');
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;

    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectTypeSpecializations', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (myOnly) {
      httpParams = httpParams.append('myOnly', 'true');
    }
    httpParams = skipVisibilitiesHandling ? httpParams.append('skipVisibilitiesHandling', 'true') : httpParams;
    if (attributeTypes && attributeTypes.length) {
      httpParams = httpParams.append('attributeTypes', attributeTypes && typeof attributeTypes == 'string' ? attributeTypes : JSON.stringify(attributeTypes));
    }

    httpParams = httpParams.append('checkLibraryVisibility', 'true');

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-most-viewed-items', {
      params: httpParams
    });
  }

  // Ritorna il count degli oggetti library in struttura
  countMyStructureLibraryItems(searchedText: string, itemTypes?: string | string[], ignoreVisibility?: boolean, status?: string, objectTypeSpecializations?: string[] | string,
    techSkillsOnly?: boolean, softSkillsOnly?: boolean, techCompetences?: string[], softCompetences?: string[], clusters?: string[], functionalAreas?: string[], adminTags?: string[],
    streamTags?: string[], levels?: string[], langs?: string[], argumentsTags?: string[], myOnly?: boolean, attributeTypes?: string, courseCategoryTags?: string[]): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('ignoreVisibility', (ignoreVisibility && ignoreVisibility.toString()) || '');
    httpParams = httpParams.append('status', status || "A");
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectTypeSpecializations', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (myOnly) {
      httpParams = httpParams.append('myOnly', 'true');
    }
    if (attributeTypes && attributeTypes.length) {
      httpParams = httpParams.append('attributeTypes', attributeTypes && typeof attributeTypes == 'string' ? attributeTypes : JSON.stringify(attributeTypes));
    }

    httpParams = httpParams.append('checkLibraryVisibility', 'true');

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-my-structure-items', {
      params: httpParams
    });
  }


  // Più visti library
  getMyStructureLibraryItems(searchedText: string, fromRecord: any, numRecords: any, status?: string, sorting?: string, itemTypes?: string | string[], originApplicationName?: string,
    ignoreVisibility?: boolean, withChildsLoaded?: boolean, createEngagement?: boolean, withRegistration?: boolean, objectTypeSpecializations?: string[] | string,
    skipVisibilitiesHandling?: boolean, techSkillsOnly?: boolean, softSkillsOnly?: boolean, techCompetences?: string[], softCompetences?: string[], clusters?: string[],
    functionalAreas?: string[], adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[], argumentsTags?: string[], myOnly?: boolean,
    attributeTypes?: string, courseCategoryTags?: string[]): any {
    // Recupera una lista di item
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    let from = fromRecord;
    let to = numRecords;
    httpParams = httpParams.append('fromRecord', from && from.toString());
    httpParams = httpParams.append('numRecords', to && to.toString());
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('originApplicationName', originApplicationName || '');
    httpParams = httpParams.append('status', status || 'A');
    httpParams = httpParams.append('sorting', sorting || 'S');
    httpParams = httpParams.append('ignoreVisibility', (ignoreVisibility && ignoreVisibility.toString()) || '');
    httpParams = httpParams.append('withChildsLoaded', (withChildsLoaded && withChildsLoaded.toString()) || '');
    httpParams = httpParams.append('createEngagement', (createEngagement && createEngagement.toString()) || '');
    httpParams = httpParams.append('withRegistration', (withRegistration && withRegistration.toString()) || '');
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;

    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectTypeSpecializations', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (myOnly) {
      httpParams = httpParams.append('myOnly', 'true');
    }
    httpParams = skipVisibilitiesHandling ? httpParams.append('skipVisibilitiesHandling', 'true') : httpParams;
    if (attributeTypes && attributeTypes.length) {
      httpParams = httpParams.append('attributeTypes', attributeTypes && typeof attributeTypes == 'string' ? attributeTypes : JSON.stringify(attributeTypes));
    }


    httpParams = httpParams.append('checkLibraryVisibility', 'true');

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-my-structure-items', {
      params: httpParams
    });
  }



  // Ritorna il count degli oggetti library in struttura
  countDesiredLibraryItems(searchedText: string, itemTypes?: string | string[], ignoreVisibility?: boolean, status?: string, objectTypeSpecializations?: string[] | string,
    techSkillsOnly?: boolean, softSkillsOnly?: boolean, techCompetences?: string[], softCompetences?: string[], clusters?: string[], functionalAreas?: string[], adminTags?: string[],
    streamTags?: string[], levels?: string[], langs?: string[], argumentsTags?: string[], myOnly?: boolean, attributeTypes?: string, courseCategoryTags?: string[]): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('ignoreVisibility', (ignoreVisibility && ignoreVisibility.toString()) || '');
    httpParams = httpParams.append('status', status || "A");
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectTypeSpecializations', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (myOnly) {
      httpParams = httpParams.append('myOnly', 'true');
    }
    if (attributeTypes && attributeTypes.length) {
      httpParams = httpParams.append('attributeTypes', attributeTypes && typeof attributeTypes == 'string' ? attributeTypes : JSON.stringify(attributeTypes));
    }


    httpParams = httpParams.append('checkLibraryVisibility', 'true');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/count-items-for-desired-competences', {
      params: httpParams
    });
  }


  // Più visti library
  getDesiredLibraryItems(searchedText: string, fromRecord: any, numRecords: any, status?: string, sorting?: string, itemTypes?: string | string[], originApplicationName?: string,
    ignoreVisibility?: boolean, withChildsLoaded?: boolean, createEngagement?: boolean, withRegistration?: boolean, objectTypeSpecializations?: string[] | string,
    skipVisibilitiesHandling?: boolean, techSkillsOnly?: boolean, softSkillsOnly?: boolean, techCompetences?: string[], softCompetences?: string[], clusters?: string[],
    functionalAreas?: string[], adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[], argumentsTags?: string[], myOnly?: boolean, attributeTypes?: string,
    courseCategoryTags?: string[]): any {
    // Recupera una lista di item
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    let from = fromRecord;
    let to = numRecords;
    httpParams = httpParams.append('fromRecord', from && from.toString());
    httpParams = httpParams.append('numRecords', to && to.toString());
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('originApplicationName', originApplicationName || '');
    httpParams = httpParams.append('status', status || 'A');
    httpParams = httpParams.append('sorting', sorting || 'S');
    httpParams = httpParams.append('ignoreVisibility', (ignoreVisibility && ignoreVisibility.toString()) || '');
    httpParams = httpParams.append('withChildsLoaded', (withChildsLoaded && withChildsLoaded.toString()) || '');
    httpParams = httpParams.append('createEngagement', (createEngagement && createEngagement.toString()) || '');
    httpParams = httpParams.append('withRegistration', (withRegistration && withRegistration.toString()) || '');
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;

    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectTypeSpecializations', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (myOnly) {
      httpParams = httpParams.append('myOnly', 'true');
    }
    httpParams = skipVisibilitiesHandling ? httpParams.append('skipVisibilitiesHandling', 'true') : httpParams;
    if (attributeTypes && attributeTypes.length) {
      httpParams = httpParams.append('attributeTypes', attributeTypes && typeof attributeTypes == 'string' ? attributeTypes : JSON.stringify(attributeTypes));
    }


    httpParams = httpParams.append('checkLibraryVisibility', 'true');

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-items-for-desired-competences', {
      params: httpParams
    });
  }

  getSuggestedLibraryItems(searchedText: string, fromRecord: any, numRecords: any, status?: string, sorting?: string, itemTypes?: string | string[], originApplicationName?: string,
    ignoreVisibility?: boolean, withChildsLoaded?: boolean, createEngagement?: boolean, withRegistration?: boolean, objectTypeSpecializations?: string[] | string,
    skipVisibilitiesHandling?: boolean, techSkillsOnly?: boolean, softSkillsOnly?: boolean, techCompetences?: string[], softCompetences?: string[], clusters?: string[],
    functionalAreas?: string[], adminTags?: string[], streamTags?: string[], levels?: string[], langs?: string[], argumentsTags?: string[], myOnly?: boolean,
    attributeTypes?: string, courseCategoryTags?: string[]): any {
    // Recupera una lista di item
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    let from = fromRecord;
    let to = numRecords;
    httpParams = httpParams.append('fromRecord', from && from.toString());
    httpParams = httpParams.append('numRecords', to && to.toString());
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('originApplicationName', originApplicationName || '');
    httpParams = httpParams.append('status', status || 'A');
    httpParams = httpParams.append('sorting', sorting || 'S');
    httpParams = httpParams.append('ignoreVisibility', (ignoreVisibility && ignoreVisibility.toString()) || '');
    httpParams = httpParams.append('withChildsLoaded', (withChildsLoaded && withChildsLoaded.toString()) || '');
    httpParams = httpParams.append('createEngagement', (createEngagement && createEngagement.toString()) || '');
    httpParams = httpParams.append('withRegistration', (withRegistration && withRegistration.toString()) || '');
    httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
    httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;

    if (itemTypes && itemTypes.length) {
      httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
    }
    if (objectTypeSpecializations && objectTypeSpecializations.length) {
      httpParams = httpParams.append('objectTypeSpecializations', objectTypeSpecializations && typeof objectTypeSpecializations == 'string' ? objectTypeSpecializations : JSON.stringify(objectTypeSpecializations));
    }
    if (techCompetences && techCompetences.length) {
      httpParams = httpParams.append('techCompetences', techCompetences && typeof techCompetences == 'string' ? techCompetences : JSON.stringify(techCompetences));
    }
    if (softCompetences && softCompetences.length) {
      httpParams = httpParams.append('softCompetences', softCompetences && typeof softCompetences == 'string' ? softCompetences : JSON.stringify(softCompetences));
    }
    if (adminTags && adminTags.length) {
      httpParams = httpParams.append('adminTags', adminTags && typeof adminTags == 'string' ? adminTags : JSON.stringify(adminTags));
    }
    if (argumentsTags && argumentsTags.length) {
      httpParams = httpParams.append('argumentsTags', argumentsTags && typeof argumentsTags == 'string' ? argumentsTags : JSON.stringify(argumentsTags));
    }
    if (langs && langs.length) {
      httpParams = httpParams.append('langs', langs && typeof langs == 'string' ? langs : JSON.stringify(langs));
    }
    if (levels && levels.length) {
      httpParams = httpParams.append('levels', levels && typeof levels == 'string' ? levels : JSON.stringify(levels));
    }
    if (streamTags && streamTags.length) {
      httpParams = httpParams.append('streamTags', streamTags && typeof streamTags == 'string' ? streamTags : JSON.stringify(streamTags));
    }
    if (functionalAreas && functionalAreas.length) {
      httpParams = httpParams.append('functionalAreas', functionalAreas && typeof functionalAreas == 'string' ? functionalAreas : JSON.stringify(functionalAreas));
    }
    if (clusters && clusters.length) {
      httpParams = httpParams.append('clusters', clusters && typeof clusters == 'string' ? clusters : JSON.stringify(clusters));
    }
    if (myOnly) {
      httpParams = httpParams.append('myOnly', 'true');
    }
    httpParams = skipVisibilitiesHandling ? httpParams.append('skipVisibilitiesHandling', 'true') : httpParams;
    if (attributeTypes && attributeTypes.length) {
      httpParams = httpParams.append('attributeTypes', attributeTypes && typeof attributeTypes == 'string' ? attributeTypes : JSON.stringify(attributeTypes));
    }



    httpParams = httpParams.append('checkLibraryVisibility', 'true');

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-suggested-items/', {
      params: httpParams
    });
  }

  // recupera la struttura dei learning plan blended in edit dell'iniziativa
  getLearningPlanBlendedStructure(itemId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-learning-plan-blended-structure-for-admin/', {
      params: httpParams
    });
  }

  exportSurveyAnswersAnalyticsBySurveyItemId(itemId: string): Observable<SenecaResponse<string>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);

    return this.http.get<SenecaResponse<string>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/export-survey-answers-analytics-by-survey-itemId', {
      params: httpParams
    });
  }

  getSurveyListReportBySurveyItemId(itemId: string): Observable<SenecaResponse<string>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);

    return this.http.get<SenecaResponse<string>>(this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/get-survey-list-report-by-survey-itemId', {
      params: httpParams
    });
  }

  getDownloadTempFileUrl(filename: string) {
    return this.applicationData.applicationContext + 'rest-api/corporateacademy-mediator/download-temp-file?filename=' + filename;
  }
}
